import React, { Component } from 'react';
import './style.css';
import styled from 'styled-components';
import CheckboxList from 'components/CheckboxList';
const CountriesContainer = styled.div`
  margin-top: 0px;
  height: 215px;
  margin-left: 0;
  margin-right: 0;
  overflow: auto;
  padding: 0px;
`;

class Countries extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSearchedInputChange = this.handleSearchedInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let updatedItems = { ...this.props.items };

    updatedItems[value] = target.checked;
    this.props.changeHandler(updatedItems);
  }

  handleSearchedInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let changedItems = [];
    let updatedItems = this.props.searched_countries;
    updatedItems[value] = target.checked;
    Object.entries(this.props.items).map(([key, value]) => {
      Object.entries(updatedItems).map(([k, v]) => {
        if (key == k && value != v) {
          changedItems[key] = v;
        } else if (!changedItems.hasOwnProperty(key)) {
          changedItems[key] = value;
        }
      });
    });

    this.props.changeHandler(changedItems);
  }

  render() {
    if (!this.props.items || this.props.items === undefined) return null;
    let countries_list = null;
    if (this.props.searching_mode) {
      countries_list = (
        <CheckboxList
          items={this.props.searched_countries}
          id={'region'}
          checkboxWidth={500}
          heightProp={215}
          handleItems={this.handleSearchedInputChange}
        />
      );
    } else if (!this.props.searching_mode) {
      countries_list = (
        <CheckboxList
          items={this.props.items}
          id={'region'}
          checkboxWidth={500}
          heightProp={215}
          handleItems={this.handleInputChange}
        />
      );
    }
    return [<CountriesContainer key={'CountriesKey'}>{countries_list}</CountriesContainer>];
  }
}

export default Countries;
