import React, { Component, PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';

class CheckboxList extends Component {
  constructor(props) {
    super(props);
    this.renderThumbVertical = this.renderThumbVertical.bind(this);
    this.renderTrackVertical = this.renderTrackVertical.bind(this);
    this.renderThumbHorizontal = this.renderThumbHorizontal.bind(this);
    this.renderTrackHorizontal = this.renderTrackHorizontal.bind(this);
  }
  /** Scrollbar plugin methods */
  //region Scrollbar methods
  renderThumbVertical({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: '#00D5A9',
      borderRadius: '10px'
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  renderTrackVertical({ style, ...props }) {
    const trackStyle = {
      position: 'absolute',
      width: '6px',
      right: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
      backgroundColor: '#242629'
    };
    return <div style={{ ...style, ...trackStyle }} {...props} />;
  }

  renderThumbHorizontal({ style, ...props }) {
    const thumbStyle = {
      display: 'none'
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  renderTrackHorizontal({ style, ...props }) {
    const trackStyle = {
      display: 'none'
    };
    return <div style={{ ...style, ...trackStyle }} {...props} />;
  }
  //endregion
  render() {
    let handleItem;
    if (this.props.handleParam) {
      handleItem = e => {
        this.props.handleItems(e, this.props.handleParam);
      };
    } else {
      handleItem = this.props.handleItems;
    }
    let items_list;
    let items = [];
    if (this.props.categories) {
      /** Items array should looks like [key: {name:'Name1', checked: true, id: 11},] */
      let propsItems = this.props.items;
      Object.entries(this.props.categories).map(([k, v]) => {
        items.push(<CategoryName key={k + this.props.id}>{` - ${k} - `}</CategoryName>);
        for (let key of v) {
          let elementKey = propsItems[key].name;
          items.push(
            <CheckboxItem
              key={propsItems[key].name + this.props.id}
              widthProp={this.props.checkboxWidth}
            >
              <input
                key={this.props.id + key}
                type="checkbox"
                name={propsItems[key].name}
                id={elementKey}
                value={propsItems[key].id}
                defaultChecked={propsItems[key].checked}
                onChange={handleItem}
              />
              <label htmlFor={elementKey}>{propsItems[key].name}</label>
            </CheckboxItem>
          );
        }
      });

      items_list = <Fieldset>{items}</Fieldset>;
    } else if (
      this.props.items !== null &&
      this.props.items !== undefined &&
      !this.props.withProperties
    ) {
      /** Array should look like [ key1:true, key2:false, key3:true] */
      items_list = (
        <Fragment>
          {Object.entries(this.props.items).map(([key, val]) => {
            let elementKey = key + '-' + this.props.id;

            return (
              <CheckboxItem widthProp={this.props.checkboxWidth} key={elementKey}>
                <input
                  name={key}
                  type="checkbox"
                  value={key}
                  checked={val}
                  onChange={handleItem}
                  id={elementKey}
                />
                <label htmlFor={elementKey}>{key}</label>
              </CheckboxItem>
            );
          })}
        </Fragment>
      );
    } else if (
      this.props.items !== null &&
      this.props.items !== undefined &&
      this.props.withProperties
    ) {
      /** Array should look like: [key1:{name:'name1', checked:true, slug:'name1'}, key2: {name:'name2', checked:false, slug:'name2'}]
       * checked - true or false and passed as checked field in input
       * name - displayed in label
       * key - as input name
       * slug - passed in value input field
       * */
      items_list = (
        <Fragment>
          {Object.entries(this.props.items).map(([key, val]) => {
            let elementKey = key + '-' + this.props.id;

            return (
              <CheckboxItem widthProp={this.props.checkboxWidth} key={elementKey}>
                <input
                  name={key}
                  type="checkbox"
                  value={val.slug}
                  checked={val.checked}
                  onChange={handleItem}
                  id={elementKey}
                />
                <label htmlFor={elementKey}>{val.name}</label>
              </CheckboxItem>
            );
          })}
        </Fragment>
      );
    }

    let content = null;
    if (this.props.placeholder !== undefined && this.props.placeholder !== '') {
      content = <PlaceholderText>{this.props.placeholder}</PlaceholderText>;
    } else {
      content = items_list;
    }
    return (
      <div>
        <CheckboxItemsListContainer heightProp={this.props.heightProp}>
          <ScrolledContainer heightProp={this.props.heightProp}>
            <Scrollbars
              renderTrackVertical={this.renderTrackVertical}
              renderThumbVertical={this.renderThumbVertical}
              renderTrackHorizontal={this.renderTrackHorizontal}
              renderThumbHorizontal={this.renderThumbHorizontal}
            >
              {content}
            </Scrollbars>
          </ScrolledContainer>
        </CheckboxItemsListContainer>
      </div>
    );
  }
}

export default CheckboxList;

/** CheckboxList Styles */
//region Styles
const CheckboxItemsListContainer = styled.div`
  width: 100%;
  overflow: auto;
  height: ${props => (props.heightProp ? `${props.heightProp}px` : '120px')};
  padding: 5px;
  border-radius: 3px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
`;

const ScrolledContainer = styled.div`
  font-family: 'Roboto Medium', sans-serif;
  color: #e1e1e5;
  overflow: auto;
  height: ${props => (props.heightProp ? `${props.heightProp - 10}px` : '110px')};
`;

const Fieldset = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
  display: block;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-before: 0em;
  -webkit-padding-start: 0em;
  -webkit-padding-end: 0em;
  -webkit-padding-after: 0em;
`;

const CheckboxItem = styled.div`
  padding: 2px 0px;
  font-size: 12px;
  word-wrap: break-word !important;
  margin: 0px !important;
  user-select: none;
  color: ${props => (props.colorProp ? props.colorProp : '#e1e1e5')};
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    word-wrap: break-word;
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: 22px;
    letter-spacing: 0.07rem;
    padding-top: 5px;
    width: ${props => (props.widthProp ? `${props.widthProp}px` : 'auto')};
    &::before {
      position: absolute;
      content: '';
      background-color: #282b30;
      display: inline-block;

      height: 16px;
      width: 16px;
      left: 0px;
      top: 4px;
      border: 0.1px solid #36393f;
    }
    &::after {
      position: absolute;
      content: '';
      background-color: #282b30;
      display: inline-block;

      height: 4px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: 4px;
      top: 8px;
    }
  }

  input[type='checkbox'] + label::after {
    content: none;
  }
  input[type='checkbox']:checked + label::after {
    content: '';
  }
`;

const CategoryName = styled.div`
  margin-top: 1rem;
  margin-right: 1rem;
  margin-left: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  letter-spacing: 0.075rem;
`;

const PlaceholderText = styled.div`
  font-size: 12px;
  color: rgb(52, 57, 64);
  padding: 5px;
  letter-spacing: 0.075rem;
`;
//endregion
