import React, { Component } from 'react';

class FinancialLedger extends Component {
  constructor() {
    super();

    this.state = {
      data: [
        {
          id: 1,
          icon: 'dsd',
          game: 'svt',
          revenue: '$100,000',
          receivable: '$30,000.00',
          velAdvanced: '$30,000.00',
          velAvailable: '$30,000.00',
          invAdvanced: '$30,000.00',
          invAvailable: '$30,000.00',
          buttons: true
        }
      ],
      expandedRows: [],
      markedRowId: 4
    };
  }

  render() {
    return <div />;
  }
}

export default FinancialLedger;
