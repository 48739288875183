import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import DropdownList from 'components/DropdownList';
import DropdownList2 from 'components/DropdownList2';
import add_btn from './assets/add_btn.png';
import x_red from './assets/x_red.png';
import Panel from 'components/Panel';
const PanelItem = props => {
  return (
    <Panel
      key={props.itemId}
      handleDelete={props.deletePanel}
      itemId={props.itemId}
      pHeight={'96px'}
    >
      <PanelContainer>
        <Permissions key={props.itemId + 'Permissions'}>
          <ColumnTitle> Permissions Group</ColumnTitle>
          {/*  <DropdownList
            items={props.groupsList}
            name={props.itemId + 'permGroup'}
            kvPairs={true}
            uppercase={false}
            currentOpt={props.item.permission.id}
            placeholder={props.item.permission.placeholder}
            handler={props.selectPermission}
            param={props.itemId}
            onFocusHandler={() => {
              props.onFocusHandler();
            }}
            onBlurHandler={() => {
              props.onBlurHandler();
            }}
            onChangeHandler={() => {
              props.onChangeHandler();
            }}
          /> */}

          <DropdownList2
            items={props.groupsList}
            name={props.itemId + 'permGroup'}
            currentOption={props.item.permission.id}
            placeholder={props.item.permission.placeholder}
            handler={props.selectPermission}
            param={props.itemId}
            onFocusHandler={() => {
              props.onFocusHandler();
            }}
            onBlurHandler={() => {
              props.onBlurHandler();
            }}
            onChangeHandler={() => {
              props.onChangeHandler();
            }}
          />
        </Permissions>
        <Games>
          {Object.entries(props.item.game_groups).map(([k, v]) => {
            return (
              <GameItem key={props.itemId + 'game' + k}>
                {k > 0 ? (
                  <DeleteGame
                    src={x_red}
                    onClick={() => {
                      props.deleteGameGroup(props.itemId, k);
                    }}
                  />
                ) : null}

                <ColumnTitle>Game Group</ColumnTitle>
                {/* <DropdownList
                  items={props.gamesGroupsList}
                  name={props.itemId + 'gameGroup' + k}
                  kvPairs={true}
                  uppercase={false}
                  currentOpt={v.id}
                  placeholder={v.placeholder}
                  param={[props.itemId, k]}
                  handler={props.selectGame}
                  onFocusHandler={() => {
                    props.onFocusHandler();
                  }}
                  onBlurHandler={() => {
                    props.onBlurHandler();
                  }}
                  onChangeHandler={() => {
                    props.onChangeHandler();
                  }}
                />*/}
                <DropdownList2
                  items={props.gamesGroupsList}
                  name={props.itemId + 'gameGroup' + k}
                  currentOption={v.id}
                  placeholder={v.placeholder}
                  param={[props.itemId, k]}
                  handler={props.selectGame}
                  onFocusHandler={() => {
                    props.onFocusHandler();
                  }}
                  onBlurHandler={() => {
                    props.onBlurHandler();
                  }}
                  onChangeHandler={() => {
                    props.onChangeHandler();
                  }}
                />
              </GameItem>
            );
          })}
          <AddButton src={add_btn} onClick={() => props.addGameGroup(props.itemId)} />
        </Games>
      </PanelContainer>
    </Panel>
  );
};

const PrivilegesPanel = props => {
  let sample_item = [
    {
      permission: { id: '7', value: 'Test', placeholder: 'Select Permission Group' },
      game_groups: [{ id: '8', value: 'Test2', placeholder: 'Select Game Group' }]
    }
  ];
  let privilegesItems = [];
  Object.entries(props.items).map(([key, val]) => {
    privilegesItems.push(
      <PanelItem
        key={key + 'panel'}
        item={val}
        itemId={key}
        gamesGroupsList={props.gamesGroupsList}
        groupsList={props.groupsList}
        {...props}
      />
    );
  });
  return privilegesItems;
};

export default PrivilegesPanel;

//region Styles
const PanelContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 28rem 28rem;
  grid-column-gap: 10rem;
`;

const Permissions = styled.div`
  grid-column: 1/2;
`;

const Games = styled.div`
  grid-column: 2/3;
  position: relative;
  margin-bottom: -20px;
`;

const ColumnTitle = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  color: #99999c;
  display: block;
`;

const GameItem = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const DeleteGame = styled.img`
  position: absolute;
  left: -15px;
  top: 30px;
  cursor: pointer;
`;
const AddButton = styled.img`
  position: absolute;
  right: -32px;
  bottom: 20px;
  cursor: pointer;
`;

//endregion
