import React, { Component } from 'react';
import styled, { injectGlobal, keyframes } from 'styled-components';
import currency from 'utils/currencyFormatter';
import numberFormatter from 'utils/numberFormatter';

class DataPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
    this.start = this.start.bind(this);
    this.next = this.next.bind(this);
    this.interval = null;
    this.mounted = false;
  }

  /**DataPanel Lifecycle Methods */
  //region DataPanel Lifecycle Methods

  componentDidMount() {
    this.mounted = true;
    this.start();
  }

  componentWillMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
    this.mounted = false;
  }
  //endregion

  start() {
    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(this.next, 15);
  }
  next() {
    if (!this.mounted) {
      if (this.interval) clearInterval(this.interval);
      clearInterval(this.interval);
    }

    if (parseInt(this.state.value) >= parseInt(this.props.value)) {
      clearInterval(this.interval);
      this.interval = null;
      this.setState({ value: this.props.value });
    } else {
      this.setState({
        value:
          this.state.value +
          1 +
          (this.props.value / 150 > 15000 ? 15000 : parseInt(this.props.value / 150))
      });
    }
  }

  render() {
    if (!this.mounted) return;
    const value =
      this.props.type === 'currency'
        ? `${currency(this.state.value, settings)}`
        : `${numberFormatter(this.state.value)}`;
    return (
      <Panel>
        <Value color={this.props.color}>{value}</Value>
        <Info>{this.props.name}</Info>
        <Period>{this.props.period}</Period>
      </Panel>
    );
  }
}

export default DataPanel;

/** DataPanel Styles*/
//region Styles

const fadein = keyframes`
 0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
const settings = {
  prepend: '$',
  thousandsSize: 3,
  thousandsDelimiter: ',',
  decimalSize: 0,
  decimalDelimiter: '.'
};

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 340px;
  min-width: 300px;
  height: 205px;
  border: 1px solid #36393f;
  border-radius: 3px;
  background-color: #24272b;
  animation: ${fadein} 0.3s ease-in-out;
`;

const Value = styled.div`
  font-size: 3rem;
  color: ${props => props.color};
  font-weight: 600;
  letter-spacing: 0.05rem;
  margin-bottom: 10px;
`;

const Info = styled.div`
  font-size: 2rem;
  color: #e6e9ee;
  font-weight: 400;
  letter-spacing: 0.075rem;
  margin-bottom: 10px;
`;

const Period = styled.div`
  font-size: 1.2rem;
  color: #4d525c;
  font-weight: 400;
  letter-spacing: 0.075rem;
`;
//endregion
