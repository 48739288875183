import React, { Component, Fragment } from 'react';

import CommonButton from 'components/CommonButton/index';
import AnimatedView from 'components/AnimatedView';
import storage from 'utils/storage';
import styled from 'styled-components';
import { Content } from 'views/App';
import LoadingIndicator from 'components/LoadingIndicator';
import GridView from '../GridView';
import DropdownList from 'components/DropdownList';
import DropdownList2 from 'components/DropdownList2';
import GameIcon from 'components/GameIcon';
import moment from 'moment';

const GamesDataPanel = props => {
  const Container = styled.div`
    height: 200px;
  `;
  const Panel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
    max-width: 128px;
    height: auto;
    border-radius: 3px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    border-radius: 25px 25px 25px 25px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.17);
      z-index: 100;
    }
  `;

  const Image = styled.img`
    width: ${props => props.size + 'px'};
    height: 128px;
    border-radius: 26px;

    z-index: 4;
  `;

  const Title = styled.div`
    font-size: 1.4rem;
    color: #e6e9ee;
    font-weight: 400;
    letter-spacing: 0.075rem;
    margin-top: 6px;
    margin-bottom: 2px;
    font-family: 'Titillium Web';
    text-align: center;
  `;

  const Info = styled.div`
    font-size: 1rem;
    font-family: 'Roboto';
    color: #888f9e;
    font-weight: 400;
    letter-spacing: 0.075rem;
    text-align: center;
  `;

  let overallLevel = 0;
  if (storage.GAMES_DATA[props.data.slug]) {
    storage.GAMES_DATA[props.data.slug].alerts.map(item => {
      if (item.level > overallLevel) overallLevel = item.level;
    });
  }
  let colorsTable = ['#009432', '#f79f1f', '#ea2027'];

  let info;

  if (props.data.status === false || props.data.status === undefined) {
    info = <div />;
  } else {
    info = <Info>{`${props.data.status.name}`}</Info>;
  }
  // <Image src={props.data.image} size={128} />
  return (
    <Container>
      <Panel onClick={() => props.handleClick(props.data, props.listData)}>
        <GameIcon
          slug={props.data.slug}
          src={props.data.image}
          size={128}
          indicatorSize={30}
          tooltip={{
            orientation: 'top',
            top: '50',
            left: '0',
            right: null,
            bottom: null,
            arrow: { top: 0, left: '49%', right: null, bottom: '100%' },
            origin: { x: '-44%', y: '0%' }
          }}
          color={colorsTable[overallLevel]}
        />
        <Title>{props.data.name}</Title>
        <Info>{props.data.developer.name}</Info>
        {info}
        <Info>{`${props.data.launch_date}`}</Info>
      </Panel>
    </Container>
  );
};

class GmsGames extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      markedItem: null,
      isFetching: false,
      currentSortItem: 'Name',
      sortList: [
        { key: 'Name', value: 'Name' },
        { key: 'Date Added', value: 'Date Added' },
        { key: 'Status', value: 'Status' }
      ],
      statusList: null,
      configurationProfileList: null,
      platformsListToDisplay: null
    };
    this.mounted = false;
    this.addButtonHandler = this.addButtonHandler.bind(this);
    this.openEditView = this.openEditView.bind(this);
  }
  /** Games Lifecycle Methods */
  //region Lifecycle Methods
  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.privilegesUpdated) this.fetchData();
    else
      this.setState({
        isFetching: false
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }
  //endregion
  /** Fetching and parsing data */
  //region Fetching and parsing data
  fetchData() {
    if (!this.mounted) return;
    this.setState({ isFetching: true });
    this.getData()
      .then(data => this.getStatusList(data))
      .then(data => this.getConfigurationProfilesList(data))
      .then(data => this.getPlatformsList(data))
      .then(data => this.onDataReceived(data));
  }

  getData() {
    if (!this.mounted) return;
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL =
      '/games/list/?data=platforms|developer|icon|game_status|configuration_profile|external_ids/';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.payload = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.payload = json.result;
        else data.payload = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }
  getStatusList(data) {
    if (!this.mounted) return;
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/game_status/list';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    data.game_status_list = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }

        return json;
      })
      .then(json => {
        if (json.result) data.game_status_list = json.result;
        else data.game_status_list = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  getConfigurationProfilesList(data) {
    if (!this.mounted) return;
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/configuration_profiles/list';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    data.configuration_profiles_list = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }

        return json;
      })
      .then(json => {
        if (json.result) data.configuration_profiles_list = json.result;
        else data.configuration_profiles_list = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  getPlatformsList(data) {
    if (!this.mounted) return;
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/platforms/list';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    data.platforms_list = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }

        return json;
      })
      .then(json => {
        if (json.result) data.platforms_list = json.result;
        else data.plaforms_list = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  onDataReceived(data) {
    if (!this.mounted) return;
    if (data.failed) {
      //if(receivedData.updatedToken==null)
      this.props.updatePrivCall(false);

      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }

    this.setState({
      isFetching: false,
      data: this.parseData(data.payload),
      listData: this.parseListData(data),
      showTooltip: null
    });

    // setTimeout(this.marItem, 500);
  }

  parseData(data) {
    let parsedData = [];
    parsedData = data.map(item =>
      /*parsedData[item.slug] = {
        slug: item.slug,
        name: item.name,
        platforms: item.platforms,
        status: item.game_status,
        developer: item.developer
      };*/

      ({
        image: item.icon,
        slug: item.slug,
        name: item.name,
        platforms: item.platforms,
        status: item.game_status,
        developer: item.developer,
        launch_date: item.launch_date,
        configuration_profile: item.configuration_profile,
        is_external: item.is_external
      })
    );

    return parsedData;
  }

  parseListData(data) {
    let configuration_profiles_list = {};
    let games_status_list = {};
    let platforms_list = {};

    Object.entries(data.configuration_profiles_list).map(([key, val]) => {
      configuration_profiles_list[val.slug] = val.name;
    });

    Object.entries(data.game_status_list).map(([key, val]) => {
      games_status_list[val.slug] = val.name;
    });

    Object.entries(data.platforms_list).map(([key, val]) => {
      platforms_list[val.slug] = {};
      platforms_list[val.slug]['name'] = val.name;
      platforms_list[val.slug]['checked'] = false;
      platforms_list[val.slug]['slug'] = val.slug;
    });
    return { games_status_list, platforms_list, configuration_profiles_list };
  }
  //endregion

  addButtonHandler() {
    this.props.history.push({
      pathname: '/gms/games/add'
    });
  }

  openEditView(data, listData) {
    let id = data.slug;
    this.props.history.push({
      pathname: `/gms/games/edit/${id}`,
      state: { id, listData }
    });
  }

  render() {
    if (!this.mounted) return;
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }
    let sort = 'name';
    switch (this.state.currentSortItem) {
      case 'Name':
        sort = 'name';
        break;
      case 'Status':
        sort = 'status';
        break;
      case 'Date Added':
        sort = 'launch_date';
        break;
    }
    let sortedData = [];
    if (this.state.data) {
      sortedData = this.state.data.slice();
      sortedData.sort((a, b) => {
        if (sort === 'status') {
          const order = ['early-access', 'soft-launch', 'live-games', 'catalogue-games', 'other'];
          if (order.indexOf(a[sort].slug) < order.indexOf(b[sort].slug)) return -1;
          if (order.indexOf(a[sort].slug) > order.indexOf(b[sort].slug)) return 1;
          if (order.indexOf(a[sort].slug) === order.indexOf(b[sort].slug)) {
            if (a['name'].toLowerCase() < b['name'].toLowerCase()) return -1;

            if (a['name'].toLowerCase() > b['name'].toLowerCase()) return 1;
          }
        } else if (sort === 'name') {
          if (a[sort].toLowerCase() < b[sort].toLowerCase()) return -1;

          if (a[sort].toLowerCase() > b[sort].toLowerCase()) return 1;
        } else if (sort === 'launch_date') {
          if (a[sort] == '' && b[sort] != '') {
            return 1;
          }
          if (a[sort] != '' && b[sort] == '') {
            return -1;
          }
          if (a[sort] == b[sort] || (a[sort] == '' && b[sort] == '')) {
            if (a['name'].toLowerCase() < b['name'].toLowerCase()) return -1;

            if (a['name'].toLowerCase() > b['name'].toLowerCase()) return 1;
          }
          if (a[sort] != '' && b[sort] != '') {
            let dateA = moment(a[sort], 'DD-MM-YYYY');
            let dateB = moment(b[sort], 'DD-MM-YYYY');
            dateA.format('MM-DD-YYYY');
            dateB.format('MM-DD-YYYY');
            return dateA - dateB;
          }
        }
        return 0;
      });
    }
    if (this.state.data === null) {
      return (
        <div className="fetchDataerrorMessage">
          <h3>Oops!</h3>
          <p>
            You don't have permissions to see this view or there is no data available at the moment.
            Please try Again!
          </p>
        </div>
      );
    }
    return (
      <AnimatedView>
        <ViewTile>{' Playstack Games'}</ViewTile>
        <PanelOptions>
          <LabelContainer>
            <Label>Sort by:</Label>
          </LabelContainer>
          <ButtonContainer>
            <CommonButton text="+ ADD GAME" onClick={this.addButtonHandler} />
          </ButtonContainer>
          <DropdownListContainer>
            {/* <DropdownList
              items={this.state.sortList}
              currentOpt={this.state.currentSortItem}
              uppercase={false}
              name={'gamesList'}
              handler={e => {
                this.setState({
                  currentSortItem: e.target.value
                });
              }}
            /> */}
            <DropdownList2
              items={this.state.sortList}
              currentOption={this.state.currentSortItem}
              name={'gamesList'}
              handler={e => {
                this.setState({
                  currentSortItem: e.target.value
                });
              }}
            />
          </DropdownListContainer>
        </PanelOptions>
        <GridView
          filter={'is_external'}
          filterVal={false}
          bottomMargin={'6rem'}
          data={sortedData}
          panel={GamesDataPanel}
          handleClick={this.openEditView}
          listData={this.state.listData}
          isFetching={this.state.isFetching}
        />
        <ViewTile>{'External Games'}</ViewTile>
        <GridView
          filter={'is_external'}
          filterVal={true}
          topMargin={'6rem'}
          bottomMargin={'18rem'}
          data={sortedData}
          panel={GamesDataPanel}
          handleClick={this.openEditView}
          listData={this.state.listData}
          isFetching={this.state.isFetching}
        />
      </AnimatedView>
    );
  }
}

export default GmsGames;

//region Styles
const ViewTile = styled.h1`
  width: 95%;
  color: #fff;
  padding: 0rem 0rem 1%;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 2rem;
  margin-right: 3%;
  margin-left: 2%;
  margin-bottom: 1rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;

const PanelOptions = styled.div`
  position: absolute;
  top: 170px;
  display: grid;
  margin-left: 9.7%;
  grid-template-columns: 15rem 18rem;
  grid-column-gap: 30px;
`;

const Label = styled.label`
  font-size: 12px;
  color: #99999c;
  display: block;
  margin-bottom: 5px;
`;

const LabelContainer = styled.div`
  grid-column: 2/3;
  grid-row: 1;
`;

const ButtonContainer = styled.div`
  grid-column: 1/2;
  grid-row: 2;
  height: 31px;
`;

const DropdownListContainer = styled.div`
  padding-top: 1px;
  grid-column: 2/3;
  grid-row: 2;
`;
//endregion
