import React, { Component, Fragment } from 'react';
import CommonButton from 'components/CommonButton/index';
import AnimatedView from 'components/AnimatedView';
import GridView from '../GridView';
import styled from 'styled-components';
import { Content } from 'views/App';
import storage from 'utils/storage';
import platform_icon from 'views/App/assets/games_management.svg';
import LoadingIndicator from 'components/LoadingIndicator';
import moment from 'moment/moment';

const DataPanel = props => {
  //region Styles
  const Panel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
    max-width: 128px;
    min-height: 220px;
    height: 220px;
    border-radius: 3px;
    cursor: pointer;
  `;

  const ImageBackground = styled.div`
    display: flex;
    width: 128px;
    height: 128px;
    background-color: ${props => props.color};
    border-radius: 26px;
    align-items: center;
    justify-content: center;
  `;
  const Image = styled.img`
    width: 64px;
    height: 64px;

    z-index: 4;
  `;

  const Title = styled.div`
    font-size: 1.4rem;
    color: #e6e9ee;
    font-weight: 400;
    letter-spacing: 0.075rem;
    margin-top: 6px;
    margin-bottom: 2px;
    font-family: 'Titillium Web';
    text-align: center;
  `;

  const Info = styled.div`
    font-size: 1rem;
    font-family: 'Roboto';
    color: #888f9e;
    font-weight: 400;
    letter-spacing: 0.075rem;
    text-align: center;
  `;
  //endregion
  let icon = props.data.image ? props.data.image : platform_icon;

  return (
    <Panel
      onClick={() => {
        props.handleClick(props.data);
      }}
    >
      <ImageBackground color={props.data.color}>
        <Image src={icon} />
      </ImageBackground>
      <Title>{props.data.name}</Title>
      <Info>{`${props.data.slug}`}</Info>
    </Panel>
  );
};

class GmsPlatforms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      markedItem: null,
      isFetching: false
    };
    this.mounted = false;
    this.addButtonHandler = this.addButtonHandler.bind(this);
    this.openEditView = this.openEditView.bind(this);
  }
  /**Mounting, fetching and parsing data */
  // region Fetching and parsing data
  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.privilegesUpdated) this.fetchData();
    else
      this.setState({
        isFetching: false
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  fetchData() {
    if (!this.mounted) return;
    this.setState({ isFetching: true });
    this.getData().then(data => this.onDataReceived(data));
  }

  getData() {
    if (!this.mounted) return;
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/platforms/list?data=icon';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.payload = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.payload = json.result;
        else data.payload = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  onDataReceived(data) {
    if (data.failed) {
      //if(receivedData.updatedToken==null)
      this.props.updatePrivCall(false);

      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }

    if (!this.mounted) return;

    this.setState({
      isFetching: false,
      data: this.parseData(data.payload),
      showTooltip: null
    });

    // setTimeout(this.marItem, 500);
  }

  parseData(data) {
    let parsedData = [];

    let sort = 'name';

    if (data) {
      data.sort((a, b) => {
        if (a[sort].toLowerCase() < b[sort].toLowerCase()) return -1;

        if (a[sort].toLowerCase() > b[sort].toLowerCase()) return 1;

        return 0;
      });
    }

    parsedData = data.map((item, index) =>
      /*parsedData[item.slug] = {
          slug: item.slug,
          name: item.name,
          platforms: item.platforms,
          status: item.game_status,
          developer: item.developer
        };*/

      ({
        image: item.icon,
        slug: item.slug,
        name: item.name,
        platforms: item.platforms,
        status: item.game_status,
        developer: item.developer,
        color: storage.COLORS[index]
      })
    );

    return parsedData;
  }
  //endregion

  addButtonHandler() {
    this.props.history.push({
      pathname: '/gms/platforms/add'
    });
  }

  openEditView(data) {
    let id = data.slug;
    this.props.history.push({
      pathname: `/gms/platforms/edit/${id}`,
      state: { id }
    });
  }

  render() {
    if (!this.mounted) return;
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }
    if (this.state.data === null) {
      return (
        <div className="fetchDataerrorMessage">
          <h3>Oops!</h3>
          <p>
            You don't have permissions to see this view or there is no data available at the moment.
            Please try Again!
          </p>
        </div>
      );
    }
    return (
      <AnimatedView>
        <ViewTile>{'Platforms'}</ViewTile>
        <PanelOptions>
          <CommonButton text="+ ADD PLATFORM" onClick={this.addButtonHandler} />
        </PanelOptions>
        <GridView
          data={this.state.data}
          panel={DataPanel}
          isFetching={this.state.isFetching}
          handleClick={this.openEditView}
        />
      </AnimatedView>
    );
  }
}

export default GmsPlatforms;
/** Platforms Styles */
//region Styles
const ViewTile = styled.h1`
  width: 95%;
  color: #fff;
  padding: 0rem 0rem 1%;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 4rem;
  margin-right: 3%;
  margin-left: 2%;
  margin-bottom: 1rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;

const PanelOptions = styled.div`
  position: absolute;
  top: 180px;
  display: grid;
  margin-left: 9.7%;
  grid-template-columns: 15rem;
  grid-column-gap: 30px;
  &:nth-child(1) {
    grid-column: 2/3;
  }
`;

//endregion
