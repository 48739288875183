import React, { Component, Fragment } from 'react';
import CommonButton, { Button } from 'components/CommonButton/index';
import Textfield from 'components/Textfield';
import DropdownList from 'components/DropdownList';
import DropdownList2 from 'components/DropdownList2';
import FilterList from 'components/FilterList';
import styled from 'styled-components';
import add_btn from '../assets/add_btn.png';
import { Content } from 'views/App';
import LoadingIndicator from 'components/LoadingIndicator';
import storage from 'utils/storage';

class GmsAddProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      profileName: '',
      dropdownPlaceholder: 'Add Data Source',
      dropdownCurrent: '',
      dataSourceTable: [],
      dataSourceCopyTable: [],
      dataList: [],
      dropdownData: [],
      needSave: false
    };
    this.mounted = false;
    this.setProfileName = this.setProfileName.bind(this);
    this.selectDataSourcePlatform = this.selectDataSourcePlatform.bind(this);
    this.addSourceToTable = this.addSourceToTable.bind(this);
    this.sourceDeleteFromTable = this.sourceDeleteFromTable.bind(this);
    this.sourceOpenCloseEditMode = this.sourceOpenCloseEditMode.bind(this);
    this.editSourceValues = this.editSourceValues.bind(this);
    this.confirmEditSource = this.confirmEditSource.bind(this);
    this.cancelEditSource = this.cancelEditSource.bind(this);
    this.addConfigurationProfile = this.addConfigurationProfile.bind(this);
    this.sortDropdownList = this.sortDropdownList.bind(this);
  }

  /** Add Profile Lifecycle Methods */
  //region Lifecycle Methods
  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.privilegesUpdated) this.fetchData();
    else
      this.setState({
        isFetching: false
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }
  //endregion
  /** Fetching and parsing data */
  //region Fetching and parsing data
  fetchData() {
    if (!this.mounted) return;
    this.setState({ isFetching: true });
    this.getData().then(data => this.onDataReceived(data));
  }

  getData() {
    if (!this.mounted) return;
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/data_sources/list?data=configuration_params`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.payload = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.payload = json.result;
        else data.payload = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  onDataReceived(data) {
    if (data.failed) {
      //if(receivedData.updatedToken==null)
      this.props.updatePrivCall(false);

      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }

    if (!this.mounted) return;

    this.setState(
      {
        isFetching: false
      },
      () => {
        this.parseData(data.payload);
      }
    );

    // setTimeout(this.marItem, 500);
  }

  parseData(data) {
    let data_list = [];
    let dropdown_data = [];
    Object.entries(data).map(([key, val]) => {
      let param_list = {};
      param_list['service'] = val.name;
      param_list['slug'] = val.slug;
      param_list['isEdited'] = true;
      param_list['params'] = [];
      let dropdown_dataI = {};
      dropdown_dataI['key'] = val.slug;
      dropdown_dataI['value'] = val.name;
      dropdown_data.push(dropdown_dataI);
      Object.entries(val.configuration_params).map(([k, v]) => {
        let param_item = {};
        param_item['name'] = v.name;
        param_item['value'] = '';
        param_item['slug'] = v.slug;
        param_list['params'].push(param_item);
      });

      data_list.push(param_list);
    });

    this.setState(
      {
        dataList: data_list,
        dropdownData: dropdown_data
      },
      () => {
        this.sortDropdownList();
      }
    );
  }
  //endregion
  setProfileName(event) {
    const target = event.target;
    let name = target.value;
    let update = false;
    if (name.length >= 1 && name.length < 50 && name != '') {
      update = true;
    }
    this.setState({
      profileName: target.value,
      needSave: update
    });
  }

  sortDropdownList() {
    let dropdown_list = this.state.dropdownData;
    dropdown_list.sort((a, b) => {
      if (a['value'].toLowerCase() < b['value'].toLowerCase()) return -1;

      if (a['value'].toLowerCase() > b['value'].toLowerCase()) return 1;

      return 0;
    });

    this.setState({ dropdownData: dropdown_list });
  }

  selectDataSourcePlatform(event) {
    const target = event.target;
    this.setState({
      dropdownPlaceholder: '',
      dropdownCurrent: target.value
    });
  }

  /** Data source management */
  //region Data source management

  addSourceToTable() {
    let currentSource = this.state.dropdownCurrent;
    let sourceTable = this.state.dataSourceTable;
    let copySourceTable = this.state.dataSourceCopyTable;
    let dropdownList = this.state.dropdownData;
    if (currentSource !== '') {
      Object.entries(this.state.dataList).map(([k, v]) => {
        if (v.slug === currentSource) {
          sourceTable.push(v);
          let newObject = JSON.parse(JSON.stringify(v));
          copySourceTable.push(newObject);
        }
      });
      Object.entries(this.state.dropdownData).map(([key, val]) => {
        if (val.key == currentSource) {
          delete dropdownList[key];
        }
      });
      this.setState({
        dataSourceTable: sourceTable,
        dataSourceCopyTable: copySourceTable,
        dropdownData: dropdownList,
        dropdownPlaceholder: 'Add Data Source',
        dropdownCurrent: ''
      });
    }
  }

  sourceDeleteFromTable(sourceId) {
    let sourceTable = this.state.dataSourceTable;
    let dropdownList = this.state.dropdownData;
    dropdownList.push({ key: sourceTable[sourceId].slug, value: sourceTable[sourceId].service });
    sourceTable.splice(sourceId, 1);
    let copySourceTable = this.state.dataSourceCopyTable;
    copySourceTable.splice(sourceId, 1);
    this.setState(
      {
        dataSourceTable: sourceTable,
        dropdownData: dropdownList,
        dataSourceCopyTable: copySourceTable
      },
      () => {
        this.sortDropdownList();
      }
    );
  }

  sourceOpenCloseEditMode(sourceId) {
    let sourceTable = this.state.dataSourceTable;
    let val = sourceTable[sourceId].isEdited;
    sourceTable[sourceId].isEdited = !val;
    this.setState({ dataSourceTable: sourceTable });
  }

  confirmEditSource(sourceId) {
    let sourceTable = this.state.dataSourceTable;
    let copySourceTable = this.state.dataSourceCopyTable;
    sourceTable[sourceId].isEdited = false;
    let objectToCopy = JSON.parse(JSON.stringify(sourceTable[sourceId]));
    copySourceTable[sourceId] = objectToCopy;
    this.setState({ dataSourceTable: sourceTable, dataSourceCopyTable: copySourceTable });
  }

  cancelEditSource(sourceId) {
    let sourceTable = this.state.dataSourceTable;
    let copySourceTable = this.state.dataSourceCopyTable;

    let objectToCopy = JSON.parse(JSON.stringify(copySourceTable[sourceId]));
    sourceTable[sourceId] = objectToCopy;
    sourceTable[sourceId].isEdited = false;
    this.setState({ dataSourceTable: sourceTable });
  }

  editSourceValues(event, sourceId, key) {
    const target = event.target;
    let sourceTable = this.state.dataSourceTable;

    sourceTable[sourceId].params[key].value = target.value;
    this.setState({ gameParameterTable: sourceTable });
  }
  //endregion

  addConfigurationProfile() {
    if (this.state.needSave === false) {
      return;
    }
    this.setState({ isFetching: true });
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/configuration_profiles/add';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let data_list = [];
    Object.entries(this.state.dataSourceTable).map(([key, val]) => {
      let data_item = {};
      data_item['slug'] = val.slug;
      let params = [];
      Object.entries(val.params).map(([k, v]) => {
        let params_item = {};
        params_item['slug'] = v.slug;
        params_item['value'] = v.value;
        params.push(params_item);
      });
      data_item['configuration_params'] = params;
      data_list.push(data_item);
    });
    let payload = {
      data: {
        name: this.state.profileName,
        data_sources: data_list
      }
    };

    return fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) storage.USER.token = token;
        if (status === 200) return json;
        else {
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        this.props.history.push({
          pathname: '/gms/profiles'
        });
        return json;
      })
      .catch(error => {
        this.setState({ isFetching: false });
        console.error(error);
      });
  }

  render() {
    if (!this.mounted) return;
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }

    return (
      <Fragment>
        <ViewTile>{'Add Profile'}</ViewTile>
        <AddProfileContainer>
          <TextLabel>
            <Textfield
              labelText="Name"
              id="addPlatformName"
              placeholder="Configuration Profile Name"
              onChange={this.setProfileName}
            />
          </TextLabel>
          <ListLabel>
            <LabelForm htmlFor="addProfileSource">Data Source</LabelForm>
            {/* <DropdownList
              items={this.state.dropdownData}
              name="addGamesDataSourceList"
              id="addGamesDataSource"
              placeholder={this.state.dropdownPlaceholder}
              uppercase={false}
              handler={this.selectDataSourcePlatform}
              currentOpt={this.state.dropdownCurrent}
              kvPairs={true}
            /> */}
            <DropdownList2
              items={this.state.dropdownData}
              name="addGamesDataSourceList"
              id="addGamesDataSource"
              placeholder={this.state.dropdownPlaceholder}
              uppercase={false}
              handler={this.selectDataSourcePlatform}
              currentOption={this.state.dropdownCurrent}
            />
          </ListLabel>
          <AddSource>
            <AddSourceImg src={add_btn} alt="add source" onClick={this.addSourceToTable} />
          </AddSource>
          <SourcesContainer>
            <FilterList
              items={this.state.dataSourceTable}
              deleteSource={this.sourceDeleteFromTable}
              openCloseEdit={this.sourceOpenCloseEditMode}
              editText={this.editSourceValues}
              confirmEdit={this.confirmEditSource}
              cancelEdit={this.cancelEditSource}
            />
          </SourcesContainer>
          <ButtonContainer>
            <Button needUpdate={this.state.needSave} onClick={this.addConfigurationProfile}>
              Add configuration profile
            </Button>
          </ButtonContainer>
        </AddProfileContainer>
      </Fragment>
    );
  }
}

export default GmsAddProfile;

//region Styles
const ViewTile = styled.h1`
  width: 95%;
  color: #fff;
  padding: 0rem 0rem 1%;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 4rem;
  margin-right: 3%;
  margin-left: 2%;
  margin-bottom: 1rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;

const AddSourceImg = styled.img`
  cursor: pointer;
  grid-row: 2;
  grid-column: 2/3;
`;

const LabelForm = styled.label`
  font-size: 12px;
  color: #99999c;
  display: block;
  margin-bottom: 5px;
`;

const AddProfileContainer = styled.div`
  display: grid;
  margin-top: 50px;
  grid-template-columns: 30rem 80rem;
  grid-row-gap: 20px;
  grid-column-gap: 10px;
  margin-left: 13%;
`;

const TextLabel = styled.div`
  grid-column: 1/2;
  grid-row: 1;
`;

const ListLabel = styled.div`
  grid-column: 1/2;
  grid-row: 2;
`;

const AddSource = styled.div`
  grid-column: 2/3;
  grid-row: 2;
  margin-top: 23px;
`;

const ButtonContainer = styled.div`
  grid-row: 4;
  grid-column: 1/2;
  height: 32px;
`;

const SourcesContainer = styled.div`
  grid-row: 3;
  grid-column: 1/3;
`;
//endregion
