import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import DataPanel from 'components/DataPanel';

class ColorfulDataBoxes extends Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.data != this.props.data;
  }

  render() {
    return (
      <DataBoxesContainer
        height={this.props.height}
        width={this.props.width}
        header={this.props.developer}
      >
        {this.props.developer ? <DevTitle>{this.props.developer}</DevTitle> : null}
        <PanelsContainer>
          {Object.entries(this.props.data).map(([k, v]) => (
            <PanelData key={k + v.name}>
              <DataPanel
                key={k}
                type={v.type}
                name={v.name}
                color={v.color}
                value={v.value}
                borderColor={v.color}
                height={160}
              />
            </PanelData>
          ))}
        </PanelsContainer>
      </DataBoxesContainer>
    );
  }
}

export default ColorfulDataBoxes;

const PanelsContainer = styled.div`
  margin-left: 50px;
  display: flex;
  // padding-top:5px;
  flex-direction: row;
`;

const PanelData = styled.div`
  margin-right: 50px;
`;

const DevTitle = styled.div`
  color: #fefefe;
  font-size: 1.8rem;
  line-height: 3.6rem;
  //font-weight: 600;
  padding-left: 8px;
  border-bottom: 1px solid #606064;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 54px;
  box-sizing: border-box;
`;

const DataBoxesContainer = styled.div`
  height: auto;
  background: #1e2022;
  width: ${props => (props.width ? props.width + 'rem' : '100%')};
  border: 0.5px solid #36393f;
  padding: 10px 20px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 54px;
  padding-top: ${props => (!props.header ? '50px' : '10px')};
`;
