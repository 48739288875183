import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

export let backgroundColorTable = [
  'rgba(0, 213, 169,0.4)',
  'rgba(80,170,238,0.4)',
  'rgba(219, 70, 185,0.4)',
  'rgba(243, 121, 39,0.4)',
  'rgba(255, 92, 54,0.4)',
  'rgba(198,255,0,0.4)',
  'rgba(255, 190, 139,0.4)',
  'rgba(112, 19, 50,0.4)',
  'rgba(32, 131, 140,0.4)',
  'rgba(123,31,162,0.4)',
  'rgba(182, 198, 195,0.4)',
  'rgba(221,44,0,0.4)',
  'rgba(24,255,255,0.4)'
];
export let borderColorTable = [
  'rgba(0, 213, 169,1)',
  'rgba(80,170,238,1)',
  'rgba(219, 70, 185,1)',
  'rgba(243, 121, 39,1)',
  'rgba(255, 92, 54,1)',
  'rgba(198,255,0,1)',
  'rgba(255, 190, 139,1)',
  'rgba(112, 19, 50,1)',
  'rgba(32, 131, 140,1)',
  'rgba(123,31,162,1)',
  'rgba(182, 198, 195,1)',
  'rgba(221,44,0,1)',
  'rgba(24,255,255,1)'
];

export let colorChartUAPalette = [
  'rgb(221,16,128)',
  'rgb(3,179,216)',
  'rgb(19,225,67)',
  'rgb(219,211,0)',
  'rgb(199,20,193)',
  'rgb(24,255,255)',
  'rgb(53,255,0)',
  'rgb(249,36,61)',
  'rgb(227,144,13)',
  'rgb(232,83,254)',
  'rgb(198,255,0)',
  'rgb(170,83,254)',
  'rgb(205,175,0)',
  'rgb(41,121,255)',
  'rgb(178,255,89)',
  'rgb(206,147,216)',
  'rgb(221,44,0)',
  'rgb(198,255,0)',
  'rgb(100,255,218)',
  'rgb(255,111,0)',
  'rgb(179,136,255)',
  'rgb(255,0,96)',
  'rgb(0,176,255)',
  'rgb(0,84,255)',
  'rgb(108,201,48)',
  'rgb(255,255,141)',
  'rgb(213,0,249)',
  'rgb(240,88,88)',
  'rgb(128,204,248)',
  'rgb(6,175,102)'
];

export let colorChartUABackground = [
  'rgba(221,16,128,0.45)',
  'rgba(3,179,216,0.45)',
  'rgba(19,225,67,0.45)',
  'rgba(219,211,0,0.45)',
  'rgba(199,20,193,0.45)',
  'rgba(24,255,255,0.45)',
  'rgba(53,255,0,0.45)',
  'rgba(249,36,61,0.45)',
  'rgba(227,144,13,0.45)',
  'rgba(232,83,254,0.45)',
  'rgba(198,255,0,0.45)',
  'rgba(170,83,254,0.45)',
  'rgba(205,175,0,0.45)',
  'rgba(41,121,255,0.45)',
  'rgba(178,255,89,0.45)',
  'rgba(206,147,216,0.45)',
  'rgba(221,44,0,0.45)',
  'rgba(198,255,0,0.45)',
  'rgba(100,255,218,0.45)',
  'rgba(255,111,0,0.45)',
  'rgba(179,136,255,0.45)',
  'rgba(255,0,96,0.45)',
  'rgba(0,176,255,0.45)',
  'rgba(0,84,255,0.45)',
  'rgba(108,201,48,0.45)',
  'rgba(255,255,141,0.45)',
  'rgba(213,0,249,0.45)',
  'rgba(240,88,88,0.45)',
  'rgba(128,204,248,0.45)',
  'rgba(6,175,102,0.45)'
];

export let colorChartUABackgroundHover = [
  'rgba(221,16,128,0.85)',
  'rgba(3,179,216,0.85)',
  'rgba(19,225,67,0.85)',
  'rgba(219,211,0,0.85)',
  'rgba(199,20,193,0.85)',
  'rgba(24,255,255,0.85)',
  'rgba(53,255,0,0.85)',
  'rgba(249,36,61,0.85)',
  'rgba(227,144,13,0.85)',
  'rgba(232,83,254,0.85)',
  'rgba(198,255,0,0.85)',
  'rgba(170,83,254,0.85)',
  'rgba(205,175,0,0.85)',
  'rgba(41,121,255,0.85)',
  'rgba(178,255,89,0.85)',
  'rgba(206,147,216,0.85)',
  'rgba(221,44,0,0.85)',
  'rgba(198,255,0,0.85)',
  'rgba(100,255,218,0.85)',
  'rgba(255,111,0,0.85)',
  'rgba(179,136,255,0.85)',
  'rgba(255,0,96,0.85)',
  'rgba(0,176,255,0.85)',
  'rgba(0,84,255,0.85)',
  'rgba(108,201,48,0.85)',
  'rgba(255,255,141,0.85)',
  'rgba(213,0,249,0.85)',
  'rgba(240,88,88,0.85)',
  'rgba(128,204,248,0.85)',
  'rgba(6,175,102,0.85)'
];

export const ColorRectangleItem = styled.div.attrs({
  style: props => ({
    background: props.pick
  })
})`
  width: 22px;
  height: 15px;
  margin: 1px;
  border-radius: 0px;
  float: left;

  cursor: pointer;
  &:hover {
    border: 1px solid white;
  }
  border: ${props => (props.active ? '1px solid red' : 'none')};
`;

const ColorPaletteContainer = styled.div`
  width: auto;
  height: auto;
  overflow: auto;
`;

const ColorPickerContainer = styled.div``;

let color_playstack = [
  'rgb(169, 62, 146)',
  'rgb(82, 144, 172)',
  'rgb(238, 119, 39)',
  'rgb(6, 191, 153)',
  'rgb(132, 146, 183)',
  'rgb(153, 153, 156)'
];

let color_red = [
  'rgb(213,0,0)',
  'rgb(255,23,68)',
  'rgb(255,82,82)',
  'rgb(255,138,128)',
  'rgb(255,205,210)',
  'rgb(239,154,154)',
  'rgb(229,115,115)',
  'rgb(239,83,80)',
  'rgb(244,67,54)',
  'rgb(229,57,53)',
  'rgb(211,47,47)',
  'rgb(198,40,40)',
  'rgb(183,28,28)'
];
let color_pink = [
  'rgb(197,17,98)',
  'rgb(245,0,87)',
  'rgb(255,64,129)',
  'rgb(255,128,171)',
  'rgb(248,187,208)',
  'rgb(244,143,177)',
  'rgb(240,98,146)',
  'rgb(236,64,122)',
  'rgb(233,30,99)',
  'rgb(216,27,96)',
  'rgb(194,24,91)',
  'rgb(173,20,87)',
  'rgb(136,14,79)'
];
let color_purple = [
  'rgb(170,0,255)',
  'rgb(213,0,249)',
  'rgb(224,64,251)',
  'rgb(234,128,252)',
  'rgb(225,190,231)',
  'rgb(206,147,216)',
  'rgb(186,104,200)',
  'rgb(171,71,188)',
  'rgb(156,39,176)',
  'rgb(142,36,170)',
  'rgb(123,31,162)',
  'rgb(106,27,154)',
  'rgb(74,20,140)'
];
let color_deep_purple = [
  'rgb(98,0,234)',
  'rgb(101,31,255)',
  'rgb(124,77,255)',
  'rgb(179,136,255)',
  'rgb(209,196,233)',
  'rgb(179,157,219)',
  'rgb(149,117,205)',
  'rgb(126,87,194)',
  'rgb(103,58,183)',
  'rgb(94,53,177)',
  'rgb(81,45,168)',
  'rgb(69,39,160)',
  'rgb(49,27,146)'
];
let color_indigo = [
  'rgb(48,79,254)',
  'rgb(61,90,254)',
  'rgb(83,109,254)',
  'rgb(140,158,255)',
  'rgb(197,202,233)',
  'rgb(159,168,218)',
  'rgb(121,134,203)',
  'rgb(92,107,192)',
  'rgb(63,81,181)',
  'rgb(57,73,171)',
  'rgb(48,63,159)',
  'rgb(40,53,147)',
  'rgb(26,35,126)'
];
let color_blue = [
  'rgb(41,98,255)',
  'rgb(41,121,255)',
  'rgb(68,138,255)',
  'rgb(130,177,255)',
  'rgb(187,222,251)',
  'rgb(144,202,249)',
  'rgb(100,181,246)',
  'rgb(66,165,245)',
  'rgb(33,150,243)',
  'rgb(30,136,229)',
  'rgb(25,118,210)',
  'rgb(21,101,192)',
  'rgb(13,71,161)'
];
let color_light_blue = [
  'rgb(0,145,234)',
  'rgb(0,176,255)',
  'rgb(64,196,255)',
  'rgb(128,216,255)',
  'rgb(179,229,252)',
  'rgb(129,212,250)',
  'rgb(79,195,247)',
  'rgb(41,182,246)',
  'rgb(3,169,244)',
  'rgb(3,155,229)',
  'rgb(2,136,209)',
  'rgb(2,119,189)',
  'rgb(1,87,155)'
];
let color_cyjan = [
  'rgb(0,184,212)',
  'rgb(0,229,255)',
  'rgb(24,255,255)',
  'rgb(132,255,255)',
  'rgb(178,235,242)',
  'rgb(128,222,234)',
  'rgb(77,208,225)',
  'rgb(38,198,218)',
  'rgb(0,188,212)',
  'rgb(0,172,193)',
  'rgb(0,151,167)',
  'rgb(0,131,143)',
  'rgb(0,96,100)'
];
let color_teal = [
  'rgb(0,191,165)',
  'rgb(29,233,182)',
  'rgb(100,255,218)',
  'rgb(167,255,235)',
  'rgb(178,223,219)',
  'rgb(128,203,196)',
  'rgb(77,182,172)',
  'rgb(38,166,154)',
  'rgb(0,150,136)',
  'rgb(0,137,123)',
  'rgb(0,121,107)',
  'rgb(0,105,92)',
  'rgb(0,77,64)'
];
let color_green = [
  'rgb(0,200,83)',
  'rgb(0,230,118)',
  'rgb(105,240,174)',
  'rgb(185,246,202)',
  'rgb(200,230,201)',
  'rgb(165,214,167)',
  'rgb(129,199,132)',
  'rgb(102,187,106)',
  'rgb(76,175,80)',
  'rgb(67,160,71)',
  'rgb(56,142,60)',
  'rgb(46,125,50)',
  'rgb(27,94,32)'
];
let color_light_green = [
  'rgb(100,221,23)',
  'rgb(118,255,3)',
  'rgb(178,255,89)',
  'rgb(204,255,144)',
  'rgb(220,237,200)',
  'rgb(197,225,165)',
  'rgb(174,213,129)',
  'rgb(156,204,101)',
  'rgb(139,195,74)',
  'rgb(124,179,66)',
  'rgb(104,159,56)',
  'rgb(85,139,47)',
  'rgb(51,105,30)'
];
let color_lime = [
  'rgb(174,234,0)',
  'rgb(198,255,0)',
  'rgb(238,255,65)',
  'rgb(244,255,129)',
  'rgb(240,244,195)',
  'rgb(230,238,156)',
  'rgb(220,231,117)',
  'rgb(212,225,87)',
  'rgb(205,220,57)',
  'rgb(192,202,51)',
  'rgb(175,180,43)',
  'rgb(158,157,36)',
  'rgb(130,119,23)'
];
let color_yellow = [
  'rgb(255,214,0)',
  'rgb(255,234,0)',
  'rgb(255,255,0)',
  'rgb(255,255,141)',
  'rgb(255,249,196)',
  'rgb(255,245,157)',
  'rgb(255,241,118)',
  'rgb(255,238,88)',
  'rgb(255,235,59)',
  'rgb(253,216,53)',
  'rgb(251,192,45)',
  'rgb(249,168,37)',
  'rgb(245,127,23)'
];
let color_amber = [
  'rgb(255,171,0)',
  'rgb(255,196,0)',
  'rgb(255,215,64)',
  'rgb(255,229,127)',
  'rgb(255,236,179)',
  'rgb(255,224,130)',
  'rgb(255,213,79)',
  'rgb(255,202,40)',
  'rgb(255,193,7)',
  'rgb(255,179,0)',
  'rgb(255,160,0)',
  'rgb(255,143,0)',
  'rgb(255,111,0)'
];
let color_orange = [
  'rgb(255,109,0)',
  'rgb(255,145,0)',
  'rgb(255,171,64)',
  'rgb(255,209,128)',
  'rgb(255,224,178)',
  'rgb(255,204,128)',
  'rgb(255,183,77)',
  'rgb(255,167,38)',
  'rgb(255,152,0)',
  'rgb(251,140,0)',
  'rgb(245,124,0)',
  'rgb(239,108,0)',
  'rgb(230,81,0)'
];
let color_deep_orange = [
  'rgb(221,44,0)',
  'rgb(255,61,0)',
  'rgb(255,110,64)',
  'rgb(255,158,128)',
  'rgb(255,204,188)',
  'rgb(255,171,145)',
  'rgb(255,138,101)',
  'rgb(255,112,67)',
  'rgb(255,87,34)',
  'rgb(244,81,30)',
  'rgb(230,74,25)',
  'rgb(216,67,21)',
  'rgb(191,54,12)'
];
let color_brown = [
  'rgb(215,204,200)',
  'rgb(188,170,164)',
  'rgb(161,136,127)',
  'rgb(141,110,99)',
  'rgb(121,85,72)',
  'rgb(109,76,65)',
  'rgb(93,64,55)',
  'rgb(78,52,46)',
  'rgb(62,39,35)'
];

let color_all = color_red
  .concat(color_pink)
  .concat(color_purple)
  .concat(color_deep_purple)
  .concat(color_indigo)
  .concat(color_blue)
  .concat(color_light_blue)
  .concat(color_cyjan)
  .concat(color_teal)
  .concat(color_green)
  .concat(color_light_green)
  .concat(color_lime)
  .concat(color_yellow)
  .concat(color_amber)
  .concat(color_orange)
  .concat(color_deep_orange)
  .concat(color_brown)
  .concat(color_playstack);

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let palette = [];
    if (this.props.color_palette == null) {
      palette = color_all;
    } else palette = this.props.color_palette;

    return (
      <Fragment>
        <ColorPickerContainer>
          <ColorPaletteContainer>
            {Object.entries(palette).map(([key, val]) => {
              if (this.props.active == val)
                return (
                  <ColorRectangleItem
                    key={val}
                    pick={val}
                    data-value={val}
                    onClick={this.props.handlePickColor}
                    active
                  />
                );
              return (
                <ColorRectangleItem
                  key={val}
                  pick={val}
                  data-value={val}
                  onClick={this.props.handlePickColor}
                />
              );
            })}
          </ColorPaletteContainer>
        </ColorPickerContainer>
      </Fragment>
    );
  }
}

export default ColorPicker;
