import React, { Component } from 'react';
import AnimatedView from 'components/AnimatedView';
import edit from './assets/edit.png';
import delete_btn from './assets/delete_btn.png';
import GroupsPopup from './GroupsPopup';
import LoadingIndicator from 'components/LoadingIndicator';
import { withRouter } from 'react-router-dom';
import storage from 'utils/storage';
import './style.css';
import CommonButton from 'components/CommonButton/index';
import styled from 'styled-components';
import { AdminContext } from 'views/App/Admin/';

class AdminGroups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editUser: null,
      deleteUser: null,
      usersData: null,
      groupsData: null,
      privilData: null,
      markedItem: null,
      isFetching: false,
      isMenuOpen: false,
      isEditingGroup: false,
      isAddingGroup: false,
      isDeletingGroup: false
    };
    this.mounted = false;
    this.onDataReceived = this.onDataReceived.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.showAddUser = this.showAddUser.bind(this);
    this.hideAddUser = this.hideAddUser.bind(this);
    this.addNewUser = this.addNewUser.bind(this);
    this.editUserData = this.editUserData.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.manageGroups = this.manageGroups.bind(this);
    // this.addUserSubmitHandler = this.addUserSubmitHandler.bind(this);
  }

  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.privilegesUpdated) {
      if (!storage.USER.privileges.includes('manage_groups')) {
        this.setState({
          isFetching: false
        });
      } else this.fetchData();
    } else
      this.setState({
        isFetching: false
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleGroupChange(group, value) {
    const state = {};
    state[group] = value;
    this.setState(state);
  }

  fetchData() {
    this.setState({ isFetching: true });
    //setTimeout(this.onDataReceived, 2000);

    const PATH_BASE = storage.ADMIN_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.240.58:5000';
    const PATH_CALL = '/groups/list';
    const PATH_CALL2 = '/roles/list';

    const url = `${PATH_BASE}${PATH_CALL}`;
    const url2 = `${PATH_BASE}${PATH_CALL2}`;

    this.setState({ isFetching: true });
    //this.getFinancialData(url).then(finData => this.getRevenueData(url2, finData)).then(frData => this.getExchangeRates(url3, frData)).then(freData => this.getAdvanceData(url4, freData));
    this.getGroupsData(url)
      .then(prevData => this.getPriviligesData(url2, prevData))
      .then(finalData => this.onDataReceived(finalData));
  }

  onDataReceived(receivedData) {
    if (receivedData.failed) {
      //if(receivedData.updatedToken==null)
      this.props.updatePrivCall(false);

      return;
    } else {
      if (receivedData.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }

    this.setState({
      isFetching: false
    });

    if (
      receivedData.groupsData.response_status &&
      receivedData.groupsData.response_status === 'SUCCESS'
    ) {
    } else {
      return;
    }
    const groupsData = new Array();

    receivedData.groupsData.response_message.groups.forEach(item => {
      groupsData[item.id] = item;
    });

    const privilegesList = {};
    const privilegesCategories = {};

    Object.entries(receivedData.priviligiesData.response_message.sections).map(([k, v]) => {
      privilegesCategories[k] = v;
    });

    receivedData.priviligiesData.response_message.roles.map(item => {
      privilegesList[item.id] = item;
    });

    let privilegesData = { privileges: privilegesList, categories: privilegesCategories };

    if (!this.mounted) return;

    this.setState({
      isFetching: false,
      privilData: privilegesData,
      groupsData: groupsData
    });
  }

  getGroupsData(url) {
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.groupsData = null;

    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();

        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        data.groupsData = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  getPriviligesData(url, prevData) {
    if (prevData.failed) {
      prevData.priviligiesData = null;
      return prevData;
    }

    let headers = {
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(json => {
        prevData.priviligiesData = json;
        return prevData;
      })
      .catch(error => {
        console.error(error);
      });
  }

  showAddUser() {
    this.setState({ isAddingGroup: true, isMenuOpen: true });
  }

  hideAddUser() {
    this.setState({
      isEditingGroup: false,
      isAddingGroup: false,
      isDeletingGroup: false,
      isMenuOpen: false
    });
  }

  addNewUser(data) {
    this.setState({ isFetching: true });
    const id = 'id' + Math.random() * 10;

    const PATH_BASE = storage.ADMIN_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.240.58:5000';
    const PATH_CALL = '/groups/add';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };

    let payload = {
      group_name: data.groupname,
      permissions: data.permissions,
      color: data.color
    };
    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        // console.log('add new group ',status, response);
        return response.json();
      })
      .then(data => {
        this.hideAddUser();
        this.fetchData();
        return data;
      })
      .catch(error => {
        this.setState({ isFetching: false });
        console.error(error);
        this.hideAddUser();
      });
  }

  deleteUser() {
    this.setState({ isFetching: true });
    const PATH_BASE = storage.ADMIN_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.240.58:5000';
    const PATH_CALL = '/groups/delete';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };

    let payload = {
      group_id: this.state.deleteUser.id
    };

    return fetch(url, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.hideAddUser();
        this.fetchData();
        return data;
      })
      .catch(error => {
        this.setState({ isFetching: false });
        console.error(error);
        this.hideAddUser();
      });
  }

  editUserData(data) {
    this.setState({ isFetching: true });
    const PATH_BASE = storage.ADMIN_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.240.58:5000';
    const PATH_CALL = '/groups/update';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };

    let add_permissions = [];
    let remove_permissions = [];
    let current_permissions = this.state.groupsData[data.id].permissions;

    data.permissions.forEach(item => {
      if (!current_permissions.includes(item)) add_permissions.push(item);
    });
    current_permissions.forEach(item => {
      if (!data.permissions.includes(item)) remove_permissions.push(item);
    });
    let payload = {
      group_id: data.id,
      add_permissions: add_permissions,
      remove_permissions: remove_permissions,
      new_name: data.groupname,
      color: data.color
    };

    return fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.hideAddUser();
        this.fetchData();
        return data;
      })
      .catch(error => {
        this.setState({ isFetching: false });
        console.error(error);
        this.hideAddUser();
      });
  }

  handleRowClick(item) {
    this.setState({ markedItem: item });
  }

  handleRowOver(item) {
    this.setState({ markedItem: item });
  }

  handleRowOut() {
    this.setState({ markedItem: null });
  }

  handleRowClick2(item) {
    this.setState({
      isDeletingGroup: true,
      isMenuOpen: true,
      deleteUser: item
    });
  }

  handleRowClick3(item) {
    this.setState({ isEditingGroup: true, isMenuOpen: true, editUser: item });
    // this.props.changeView('FinancialDashBoard', `AppStart >> DashBoard >> ${item.title}`);
  }

  manageGroups() {
    this.setState({ isAddingGroup: true, isMenuOpen: true });
  }

  renderItem(item) {
    const onMouseOverCallback = () => this.handleRowOver(item);
    const onMouseOutCallback = () => this.handleRowOut(item);
    const clickCallback2 = () => this.handleRowClick2(item);
    const clickCallback3 = () => this.handleRowClick3(item);

    //let marked = null;
    let buttons = null;
    let isMarked = '#282b30';
    if (this.state.markedItem != null && item.id === this.state.markedItem.id) {
      //marked = 'tableMarkedRow';
      isMarked = 'rgb(30, 32, 34)';
      if (item.buttons || 1)
        buttons = (
          <RowButtonsPanel>
            <RowButton onClick={clickCallback3}>
              <RowBtnTooltip>
                <BtnTooltipInfo>Edit group</BtnTooltipInfo>
              </RowBtnTooltip>
              <RowBtnImg src={edit} />
            </RowButton>
            <RowButton onClick={clickCallback2}>
              <RowBtnTooltip>
                <BtnTooltipInfo>Delete group</BtnTooltipInfo>
              </RowBtnTooltip>
              <RowBtnImg src={delete_btn} />
            </RowButton>
          </RowButtonsPanel>
        );
    }
    const items = [];
    // console.log('item',item)
    //let name = this.state.groupsData[item.id]'
    let color = item.color;
    item.permissions.forEach(item => {
      items.push(
        <PermissionsListItem key={`row-data-${item.id}-${item}`}>
          <CheckMark color={color}>&#x2714;</CheckMark>
          <ListText>{this.state.privilData.privileges[item].name}</ListText>
        </PermissionsListItem>
      );
    });

    const itemRows = [
      <RowTable
        //className={marked}
        isMarked={isMarked}
        key={`row-data-${item.id}`}
        onMouseEnter={onMouseOverCallback}
        onMouseLeave={onMouseOutCallback}
      >
        <td>
          <ColorGroup color={item.color}>{item.name}</ColorGroup>
        </td>
        <td>
          <PermissionsList>{items}</PermissionsList>
        </td>
        <td className={'financesInvCells'}>{buttons}</td>
      </RowTable>
    ];

    return itemRows;
  }

  renderGroupsList(groups) {
    const items = [];

    groups.forEach(group => {
      items.push(
        <li key={`row-data-${group}-${this.state.groupsData[group].groupName}`}>
          <span>
            <ColorGroup color={this.state.groupsData[group].color}>
              {this.state.groupsData[group].groupName}
            </ColorGroup>
          </span>
        </li>
      );
    });
    return items;
  }

  renderGroup(group) {
    const items = [];

    group.privileges.forEach(item => {
      items.push(
        <li key={`row-data-${group.id}-${item}`}>
          <span>{item}</span>
        </li>
      );
    });

    const rows = [
      <div key={`grp-data-${group.groupName}`}>
        <GroupTitle>
          <ColorGroup color={group.color}>{group.groupName}</ColorGroup>
        </GroupTitle>
        <ul>{items}</ul>
      </div>
    ];

    return rows;
  }

  render() {
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }

    if (!this.state.groupsData) {
      return (
        <div className="fetchDataerrorMessage">
          <h3>Oops!</h3>
          <p>
            You don't have permissions to see this view or there is no data available at the moment.
            Please try Again!
          </p>
        </div>
      );
    }

    let allUsersRows = [];
    let title = '';

    for (let key in this.state.groupsData) {
      let item = this.state.groupsData[key];
      const perItemRows = this.renderItem(item);
      allUsersRows = allUsersRows.concat(perItemRows);
      if (this.state.markedItem != null && item.id === this.state.markedItem.id) title = item.title;
    }

    let groupPopup = null;

    if (this.state.isAddingGroup)
      groupPopup = (
        <GroupsPopup
          key="g1"
          type={'add'}
          privileges={this.state.privilData.privileges}
          categories={this.state.privilData.categories}
          groupsData={this.state.groupsData}
          closeCallBack={this.hideAddUser}
          submitCallBack={this.addNewUser}
          visible={this.state.isMenuOpen}
        />
      );
    else if (this.state.isEditingGroup)
      groupPopup = (
        <GroupsPopup
          key="g2"
          type={'edit'}
          privileges={this.state.privilData.privileges}
          categories={this.state.privilData.categories}
          userData={this.state.editUser}
          closeCallBack={this.hideAddUser}
          submitCallBack={this.editUserData}
          visible={this.state.isMenuOpen}
        />
      );
    else if (this.state.isDeletingGroup)
      groupPopup = (
        <GroupsPopup
          key="g3"
          type={'delete'}
          userData={this.state.deleteUser}
          closeCallBack={this.hideAddUser}
          submitCallBack={this.deleteUser}
          visible={this.state.isMenuOpen}
        />
      );

    return (
      <AdminContext.Consumer>
        {AdminState => {
          return (
            <AnimatedView>
              <ViewTile>{'Permission Groups Management'}</ViewTile>
              <GroupsGrid>
                <NewGroupButton callBack={this.manageGroups} isMenuOpen={this.state.isMenuOpen} />
                <GroupsTable itemRows={allUsersRows} isMenuOpen={this.state.isMenuOpen} />
                {groupPopup}
              </GroupsGrid>
              <EmptyDiv />
            </AnimatedView>
          );
        }}
      </AdminContext.Consumer>
    );
  }
}

const NewGroupButton = ({ callBack, isMenuOpen }) => {
  const ButtonContainer = styled.div`
    box-sizing: border-box;
    grid-row: 1;
    grid-column: 2/3;
    top: 11rem;
    left: 4rem;
    width: 22rem;
  `;
  return (
    <ButtonContainer>
      <CommonButton text="+ ADD PERMISSION GROUP" onClick={callBack} />
    </ButtonContainer>
  );
};

const Clicker = ({ isMenuOpen, onClick }) => {
  const Click = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0rem;
    left: 0rem;
    background-color: #282b30;
    opacity: 0.7;
    z-index: 100;
    animation: fadein 0.4s;

    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 0.7;
      }
    }
  `;

  if (!isMenuOpen) return null;
  return <Click onClick={onClick} />;
};

const GroupsTable = ({ itemRows, isMenuOpen }) => {
  return (
    <GroupsManagementContent>
      <TableContent>
        <TableHeader>
          <tr>
            <td>Group</td>
            <td>Permissions</td>
            <FinancesInvCells />
          </tr>
        </TableHeader>
      </TableContent>
      <TableContent>
        <ScrollableTbody>{itemRows}</ScrollableTbody>
      </TableContent>
    </GroupsManagementContent>
  );
};

export default withRouter(AdminGroups);

//region Styles

const EmptyDiv = styled.div`
  height: 100px;
`;
const CheckMark = styled.div`
  grid-column: 1/2;
  display: inline-block;
  font-weight: bold;
  padding-right: 5px;
  color: ${props => props.color};
`;

const ListText = styled.span`
  grid-column: 2/3;
  display: inline-block;
`;

const PermissionsList = styled.ul`
  list-style-type: none;
  text-align: left;
  padding-left: 4rem;
`;
const PermissionsListItem = styled.li`
  display: grid;
  grid-template-columns: 2rem 29rem;
  padding: 0;
  font-size: 1.5rem;
  line-height: 1.8rem;
  text-align: left;
`;

const GroupsGrid = styled.div`
  margin-top: 100px;
  display: grid;
  grid-template-columns: 10rem 80rem 1fr;
  grid-row-gap: 20px;
`;

const ColorGroup = styled.div`
  color: ${props => props.color};
  font-weight: bold;
`;

const GroupTitle = styled.div`
  color: #99999c;
  padding: 0rem 0rem 0rem;
  line-height: 4rem;
  font-size: 1.6rem;
  font-weight: 600;
  border-top: 1px solid #606064;
  margin-top: 1rem;
  margin-right: 4rem;
  margin-left: 4rem;
  margin-bottom: 0rem;
`;

const ViewTile = styled.h1`
  width: 95%;
  color: #fff;
  padding: 0rem 0rem 1.2rem;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 4rem;
  margin-right: 4rem;
  margin-left: 4rem;
  margin-bottom: 1rem;
  z-index: 101;
`;

const TableHeader = styled.thead`
  background-color: #24272b;
  text-align: center;
  border: 2px solid #2f3238;
  font-size: 1.7rem;
  line-height: 4rem;
  color: #fff;
`;

const TableContent = styled.table`
  position: relative;
  background-color: #282b30;
  width: 100%;
  font-size: 1.7rem;
  text-align: center;
  vertical-align: middle;
  color: #afafaf;
  line-height: 4rem;
  table-layout: fixed;
  margin-top: -2px;
`;

const ScrollableTbody = styled.tbody`
  height: 100% !important;

  td:last-child {
    background-color: #2f3238;
  }
`;

const FinancesInvCells = styled.td`
  text-align: center;
  border: none;
  background-color: #2f3238;
  color: #afafaf;
  width: 6rem !important;
  cursor: pointer;
  font-size: 1.2rem;
  max-width: 3rem;
  td {
    height: 4rem;
    width: 6rem !important;
  }
`;

const GroupsManagementContent = styled.div`
  grid-column: 2/3;
  grid-row: 2;
  width: 80rem;
  top: 17rem;
  left: 4rem;
  color: #fff;
  table {
    border: 2px solid #2f3238;
    border-collapse: collapse;
  }
  table,
  td {
    border: 2px solid #35383e;
    border-collapse: collapse;
    table-layout: fixed !important;
    cursor: pointer;
    font-size: 1.5rem;
  }
  table,
  td:last-child {
    border: none;
    background: #2f3238;
  }
`;

const RowTable = styled.tr`
  transition: background-color 0.4s ease-in-out;
  background-color: ${props => (props.isMarked ? props.isMarked : '#282b30')};
`;

const NewGroupButtonContainer = styled.div`
  box-sizing: border-box;
  grid-row: 1;
  grid-column: 2/3;
  top: 11rem;
  left: 4rem;
  width: 14rem;
`;

const RowButtonsPanel = styled.div`
  display: flex;
  background-color: #2f3238;
  flex-direction: row;
  width: 3rem;
  position: relative;
`;
const RowBtnTooltip = styled.div`
  display: none;
  position: absolute;
  padding: 0px;
  width: 100px;
  height: 30px !important;
  margin: 0px;
  z-index: 1090;
  top: -31px;
  cursor: auto;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  text-align: center;
  background: #282b30;
  border-radius: 3px;
  border: #00d5a9 solid 0.5px;
  color: #00d5a9;
  opacity: 0;
`;

const RowButton = styled.div`
  display: inline-flex;
  float: left;
  height: 3rem;
  width: 3rem;
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
  &:hover ${RowBtnTooltip} {
    opacity: 1;
    display: block;
  }
`;

const RowBtnImg = styled.img`
  height: 3rem;
  width: 3rem;
  margin: auto;
  display: block;
  &:hover ${RowBtnTooltip} {
    opacity: 1;
    display: block;
  }
`;

const BtnTooltipInfo = styled.div`
  padding: 0px;
  margin: -5px;
`;

//endregion
