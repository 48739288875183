import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import GameDetail from 'views/App/UserAcquisition/GameDetail';
import GamesList from 'views/App/UserAcquisition/GamesList';
import TitleBar from 'components/TitleBar';
import styled from 'styled-components';
import { Content } from 'views/App';

const routes = [
  {
    path: '/acquisition',
    exact: true,
    view: GamesList,
    title: 'Welcome to PlayStack Dashboard'
  },
  {
    path: '/acquisition/game/',
    exact: false,
    view: GameDetail,
    title: 'Welcome to PlayStack Dashboard'
  }
];
class UserAcquisition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: null
    };
    this.header = 'User Acquisition';
    this.components = {};
    this.addComponents();
    this.showGameDetails = this.showGameDetails.bind(this);
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  addComponents() {
    //this.components.KpiMain = KpiMain;
    //this.components.KpiGameDetails = KpiGameDetails;
  }

  showGameDetails(id, name, icon) {
    this.props.history.push({
      pathname: `/acquisition/game/${id}`,
      search: '',
      state: { id: id, name: name, icon: icon }
    });
  }

  render() {
    return (
      <Fragment>
        <TitleBar
          name={this.props.user.first_name}
          title={this.props.title}
          color={this.props.color}
          logout={this.props.logoutCall}
          history={this.props.history}
        />
        <Content>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                id={index}
                path={route.path}
                exact={route.exact}
                render={props => (
                  <route.view
                    showGameDetails={this.showGameDetails}
                    updatePrivCall={this.props.updatePrivCall}
                    privilegesUpdated={this.props.privilegesUpdated}
                    {...props}
                  />
                )}
                //component={route.view}
              />
            ))}

            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </Content>
      </Fragment>
    );
  }
}

export default UserAcquisition;
