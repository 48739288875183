import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

class SearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedValue: null,
      valueKey: null,
      itemsList: [],
      searchedList: [],
      searchingMode: false,
      mounted: false,
      showList: false
    };

    this.mySearch = React.createRef();

    this.showList = this.showList.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    this.setState({
      displayedValue: this.props.display,
      valueKey: this.props.value,
      itemsList: this.props.items,
      mounted: true
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    // return (
    //   nextProps.display !== this.state.displayedValue || nextProps.value !== this.state.valueKey || nextState.displayedValue !== this.state.displayedValue || nextProps.items !== this.state.itemsList
    //  );
    return true;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.display !== state.displayedValue || props.value !== state.valueKey) {
      return {
        displayedValue: props.display,
        valueKey: props.value
      };
    }

    return null;
  }

  selectOption(e) {
    this.props.handler(e);
    this.showList();
  }

  showList() {
    if (!this.state.showList) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
    let show = this.state.showList;
    this.setState({ showList: !show });
  }

  handleOutsideClick(e) {
    if (this.mySearch.current) {
      if (this.mySearch.current.contains(e.target)) {
        //console.log('yes, contains');
        return;
      }

      this.props.handleCheck();
      this.showList();
    }
  }

  render() {
    // console.log('SearchList STATE PROPS', this.state, this.props);
    return (
      <Fragment>
        <div ref={this.mySearch}>
          <SearchContainer>
            <TextForm
              name={this.props.value}
              value={this.props.display}
              onChange={this.props.handleTypeValue}
              onClick={this.showList}
            />
            <Arrow />
          </SearchContainer>
          {this.state.showList ? (
            <ListContainer>
              {Object.entries(this.props.items).map(([key, value]) => (
                <Option key={value.code}>
                  <input
                    name={value.name}
                    type="radio"
                    key={value.code}
                    id={value.code + value.name}
                    value={value.code}
                    onClick={e => {
                      this.selectOption(e);
                    }}
                  />
                  <label htmlFor={value.code + value.name}>{value.name}</label>
                </Option>
              ))}
            </ListContainer>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default SearchList;
const Option = styled.div`
  width: 100%;
  min-height: 30px;
  align-items: center;
  cursor: pointer;
  background: #282b30;
  border: #141617 solid 0.5px;
  pointer-events: all;
  color: ${props => (props.color ? props.color : 'white')};
  transition: background 0.4s ease-in-out;
  box-sizing: border-box;
  white-space: nowrap;
  border-radius: 3px;
  &:hover {
    background: #1e2022;
    border-radius: 3px;
  }
  input {
    width: 100%;
    opacity: 0;
    display: none;
    //position: absolute;
    left: -99999px;
  }

  label {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    padding-top: 5px;
    padding-left: 10px;
    display: block;
    cursor: pointer;
  }
`;
const ListContainer = styled.div`
  height: auto;
  margin-top: 3px;
  max-height: 400px;
  width: 100%;
  background: #1e2022;
  overflow: auto;
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #242629;
    height: 1px;
    width: 8px;
  }
  ::-webkit-scrollbar {
    height: 8px;
    background-color: #242629;
    border-radius: 10px;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #00d5a9;
    width: 8px;
  }
`;

const Arrow = styled.span`
  content: '';
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #00d5a9;
  position: absolute !important;
  right: 10px;
  top: 12px;
  pointer-events: none;
  z-index: 20;
`;

const SearchContainer = styled.div`
  position: relative;
`;

const TextForm = styled.input`
  border-radius: 3px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  height: 30px;
  box-sizing: border-box;
  padding-left: 10px;
  border: none;
  outline: none;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: ${props => (props.colorText ? props.colorText : 'rgb(225, 225, 229)')};
  letter-spacing: 0.05em;
  width: 100%;
  padding-right: 10px;
  &:focus {
    outline: none !important;
    box-shadow: 0 0 5px rgb(21, 130, 110);
    border: 1px solid rgb(21, 130, 110);
  }
  &::placeholder {
    color: rgb(52, 57, 63);
  }
`;
