import React, { Component } from 'react';
import GameInstallsPanel from './GameInstallsPanel';
import storage from 'utils/storage';
import LoadingIndicator from 'components/LoadingIndicator';
import AnimatedView from 'components/AnimatedView';
import styled, { injectGlobal, keyframes } from 'styled-components';
import './style.css';

class GameList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      gamesData: null,
      markedItem: null,
      isFetching: false
    };
    this.mounted = false;

    this.handleGameClicked = this.handleGameClicked.bind(this);
  }
  /** GameList Lifecycle Methods */
  //region Lifecycle Methods
  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.privilegesUpdated) this.fetchData();
    else
      this.setState({
        isFetching: false
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }
  //endregion

  /**GameList Fetching Data */
  //region Fetching Data
  fetchData() {
    this.setState({ isFetching: true });
    this.getGamesData()
      .then(data => this.getInstallsData(data))
      .then(data => this.onDataReceived(data));
  }

  getGamesData() {
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/games';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.gamesData = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.gamesData = json.result;
        else data.gamesData = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }
  getInstallsData(data) {
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/data/installs_list/7';
    const url = `${PATH_BASE}${PATH_CALL}`;

    let payload = {
      games: [],
      platform: 'all',
      country: 'all'
    };

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    if (data == undefined) {
      return;
    }

    data.installsData = null;
    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.installsData = json.result;
        else data.installsData = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  onDataReceived(data) {
    if (!data || data.failed) {
      //if(receivedData.updatedToken==null)
      this.props.updatePrivCall(false);

      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }

    if (!this.mounted) return;
    let installsDict = [];
    data.installsData.message.data.map(item => {
      Object.entries(item).map(([key, value]) => {
        installsDict[key] = value;
      });
    });

    this.setState({
      isFetching: false,
      gamesData: data.gamesData,
      installsData: installsDict,
      markedItem: '',
      showTooltip: null
    });

    // setTimeout(this.marItem, 500);
  }
  //endregion

  handleGameClicked(item) {
    this.props.showGameDetails(item.slug, item.name, item.icon);

    //this.setState({ markedItem: item.id });
  }

  render() {
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }
    if (!this.state.gamesData) {
      return (
        <div className="fetchDataerrorMessage">
          <h3>Oops!</h3>
          <p>
            You don't have permissions to see this view or there is no data available at the moment.
            Please try Again!
          </p>
        </div>
      );
    }
    let i = 0;
    let games = this.state.gamesData.map(item => {
      if (storage.GAMES_ICONS[item.slug]) item.icon = storage.GAMES_ICONS[item.slug];
      i++;
      if (i % 3 === 0)
        return (
          <AnimatedView animKey={'AnimatedView' + item.slug}>
            <GameInstallsPanel
              key={item.slug}
              item={item}
              installsData={this.state.installsData[item.slug]}
              onClick={() => this.handleGameClicked(item)}
            />
          </AnimatedView>
        );
      return (
        <AnimatedView animKey={'AnimatedView_' + item.slug}>
          <GameInstallsPanel
            key={item.slug}
            item={item}
            installsData={this.state.installsData[item.slug]}
            onClick={() => this.handleGameClicked(item)}
          />
        </AnimatedView>
      );
    });

    return (
      <Container>
        <GamesContainer>{games}</GamesContainer>
      </Container>
    );
  }
}

export default GameList;

/** GameList Styles */
//region Styles
const Container = styled.div`
  width: calc(100%);
`;

const GamesContainer = styled.div`
  display: grid;
  margin-top: 15rem;
  margin-bottom: 12rem;
  grid-template-columns: 1fr 1fr 1fr;
  width: 96%;
  margin-left: 2%;
  justify-items: center;
  align-items: center;
  grid-gap: 3rem;

  padding: 0;
  background-color: #2f3238;
  @media (min-width: 2000px) {
    width: 170rem;
  }
  @media (max-width: 1380px) {
    margin-top: 10rem;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 960px) {
    margin-top: 5rem;
    grid-template-columns: 1fr;
  }
`;
//endregion
