import React, { PropTypes, Component } from 'react';
import { tween, easing } from 'popmotion';
import styled from 'styled-components';

class Gauge extends Component {
  constructor(props) {
    super(props);
    this.bar = React.createRef();
    this.arrow = React.createRef();
    this.radial = React.createRef();
    this.radial1 = React.createRef();
    this.radial2 = React.createRef();
    this.radial3 = React.createRef();
    this.isAnimating = false;
  }

  componentDidMount() {
    this.drawCircle();
    this.startAnimation();
    //this.arrow.current.setAttribute("opacity", "0.0");
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('componentDidUpdate', this.isAnimating, this.props.currentState);
    if (!this.isAnimating && this.props.currentState === 'showResults') this.startAnimation();
    //this.props.currentState === 'showResults' &&
    if (this.props.currentState === 'waitForInput') this.clear();
  }
  //(32, 190, 350);
  calculatePath(rad, start, end) {
    let i = 0;
    // circle.setAttribute("stroke", color);
    let angle = start;
    const radius = rad;
    let d = 'm0,0';
    let find = true;
    const to = end;
    while (find) {
      angle %= 360;
      if (angle === 0) angle = 360;
      const radians = (angle / 180) * Math.PI;
      const x = Math.cos(radians) * radius;
      const y = 0 + Math.sin(radians) * radius;
      if (i === 0) {
        d = ` M ${x} ${y}`;
      } else d += ` L ${x} ${y}`;

      if ((angle === to && i !== 0) || angle === 0) {
        find = false;
      }
      angle += 1;
      i += 1;
    }
    return d;
  }
  calculateTextPath(rad, start, n) {
    const i = 0;
    // circle.setAttribute("stroke", color);
    let angle = start;
    const radius = rad;

    angle %= 360;
    const radians = (angle / 180) * Math.PI;
    const x = Math.cos(radians) * radius;
    const y = Math.sin(radians) * radius;

    let d = ` M -100 ${y + n}`;
    d += ` L ${-79 + 0.25 * n} ${y + n}`;
    d += ` L ${x} ${y}`;
    return d;
  }

  drawCircle(end = 320) {
    let i = 0;
    // circle.setAttribute("stroke", color);
    let angle = 180;
    const radius = 74;
    let d = '';

    //const element = this.refs[elRef];
    // const element2 = this.refs[`${elRef}Glow`];
    //const element3 = this.refs[`${elRef}Bckg`];

    const clicker = this.bar.current; //this.refs[`${elRef}Clicker`];
    const radial = this.radial.current;
    const radial1 = this.radial1.current;
    const radial2 = this.radial2.current;
    const radial3 = this.radial3.current;

    //clicker.setAttribute("d", ` M 0 0`);

    let to = 350;

    for (let i = 180; i <= 280; i++) {
      const radians = (i / 180) * Math.PI;
      const x = Math.cos(radians) * radius;
      const y = Math.sin(radians) * radius;
      // var e = circle.getAttribute("d");
      if (i === 180) {
        d = ` M ${x} ${y}`;
      } else d += ` L ${x} ${y}`;
    }
    radial1.setAttribute('d', d);

    for (let i = 279; i <= 300; i++) {
      const radians = (i / 180) * Math.PI;
      const x = Math.cos(radians) * radius;
      const y = Math.sin(radians) * radius;

      // var e = circle.getAttribute("d");
      if (i === 279) {
        d = ` M ${x} ${y}`;
      } else d += ` L ${x} ${y}`;
    }
    radial2.setAttribute('d', d);

    for (let i = 299; i <= 360; i++) {
      const radians = (i / 180) * Math.PI;
      const x = Math.cos(radians) * radius;
      const y = Math.sin(radians) * radius;
      // var e = circle.getAttribute("d");
      if (i === 299) {
        d = ` M ${x} ${y}`;
      } else d += ` L ${x} ${y}`;
    }
    radial3.setAttribute('d', d);
    //this.radial.current.setAttribute('transform', `rotate(180 0 0)`);
    // radial.setAttribute("transform", 'rotate(0 0 0)');
  }

  startAnimation() {
    this.isAnimating = true;

    this.arrow.current.setAttribute('fill-opacity', 1);

    /*let t = tween({ to: 180, duration: 500 }).start(v =>
      this.radial.current.setAttribute('transform', `rotate(${v.toString()} 0 0)`)
    );*/
    let rot = this.props.roiAngle > 360 ? 360 : this.props.roiAngle;
    //  console.log('startAnnimation', rot, 'this.props.roiAngle', this.props.roiAngle);
    let t1 = tween({ from: 180, to: rot, duration: 2000, ease: easing.backOut }).start({
      update: v => {
        if (this.arrow.current)
          this.arrow.current.setAttribute('transform', `rotate(${v.toString()} 0 0)`);
      },
      complete: () => {
        //   console.log('complete animation', this.isAnimating);
      }
    });

    //this.animateBigLine();
    //this.animateSmallLine(290);
  }

  clear() {
    this.isAnimating = false;
    //this.radial3.current.setAttribute('stroke-opacity', 0);
    //this.radial.current.setAttribute('transform', `rotate(0 0 0)`);
    this.arrow.current.setAttribute('transform', `rotate(180 0 0)`);
  }

  render() {
    const { targetLine, actualLine, roiColor, arpColor, dauColor, roiAngle } = this.props;
    const rot2 = `rotate(${targetLine.toString()} 0 0)`;

    const c1 = this.calculatePath(58, 180, 360);

    const b5 = this.calculatePath(74, 180, 360);

    const c3 = this.calculatePath(90, 180, 360);

    const rad = (targetLine / 180) * Math.PI;
    const tx = Math.cos(rad) * 110 - 10;
    const ty = Math.sin(rad) * 110 - 3;
    const targetTxtPos = { x: tx, y: ty };

    const gradName1 = `linearGradient_${Math.floor(Math.random() * 1000)}`;
    const gradName2 = `linearGradient_${Math.floor(Math.random() * 1000)}`;
    const gradName3 = `linearGradient_${Math.floor(Math.random() * 1000)}`;
    /*    <path
               d={d1}
               id="d1Clicker"
               ref={this.bar}
               fill="none"
               stroke={`url(#${gradName3})`}
               strokeOpacity="1.0"
               strokeLinecap="round"
               strokeWidth="16"
           />preserveAspectRatio="xMidYMid meet"
           <rect width="200" height="130"  transform="translate(-100,-100)"/>

            ${({ windowWidth }) => windowWidth && `grid-template-rows: 40px ${(windowWidth - 90) * 0.53}px`};
             */
    return (
      <SVG viewBox="-100 -100 200 130">
        <defs>
          <linearGradient id={gradName1} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={roiColor} stopOpacity="0.5" />
            <stop offset="100%" stopColor={roiColor} />
          </linearGradient>
          <linearGradient id={gradName2} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={arpColor} stopOpacity="0.5" />
            <stop offset="100%" stopColor={arpColor} />
          </linearGradient>
          <linearGradient id={gradName3} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={dauColor} stopOpacity="0.5" />
            <stop offset="100%" stopColor={dauColor} />
          </linearGradient>
          <linearGradient id="linear4" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="25%" stopColor="#3A3C42" />
            <stop offset="100%" stopColor="white" />
          </linearGradient>

          <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
            <feGaussianBlur stdDeviation="2 2" result="shadow" />
            <feOffset dx="6" dy="6" />
          </filter>

          <linearGradient
            id="redyel"
            gradientUnits="objectBoundingBox"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor="#d32212" />
            <stop offset="100%" stopColor="#f5b22a" />
          </linearGradient>
          <linearGradient
            id="yelgre"
            gradientUnits="objectBoundingBox"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor="#f5b22a" />
            <stop offset="100%" stopColor="#87B53F" />
          </linearGradient>
          <linearGradient
            id="grecya"
            gradientUnits="objectBoundingBox"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor="#87B53F" />
            <stop offset="100%" stopColor="#39b549" />
          </linearGradient>
          <linearGradient id="cyablu" gradientUnits="objectBoundingBox" x1="1" y1="1" x2="0" y2="0">
            <stop offset="0%" stopColor="#00ffff" />
            <stop offset="100%" stopColor="#ffff00" />
          </linearGradient>
          <linearGradient id="blumag" gradientUnits="objectBoundingBox" x1="0" y1="1" x2="0" y2="0">
            <stop offset="0%" stopColor="#0000ff" />
            <stop offset="100%" stopColor="#ff00ff" />
          </linearGradient>
          <linearGradient id="magred" gradientUnits="objectBoundingBox" x1="0" y1="1" x2="1" y2="0">
            <stop offset="0%" stopColor="#ff00ff" />
            <stop offset="100%" stopColor="#ff0000" />
          </linearGradient>
        </defs>

        <g transform="translate(0,20)" width="100%" height="100%">
          <path d={c1} id="arc1" fill="none" stroke="#24272b" strokeWidth="16" />
          <g id="radial" ref={this.radial} fill="none" strokeWidth="16">
            <path ref={this.radial3} d="" stroke="url(#grecya)" />
            <path ref={this.radial1} d="" stroke="url(#redyel)" />
            <path ref={this.radial2} d="" stroke="url(#yelgre)" />
          </g>

          <path d={c3} id="arc2" fill="none" stroke="#24272b" strokeWidth="16" />

          <path
            id="MyPath"
            fill="none"
            stroke="rgba(255,255,255,0.6)"
            strokeOpacity="0.5"
            strokeLinecap="round"
            d="M 51 0 L 97 0 "
            transform={rot2}
            strokeWidth="2"
          />
          <text
            x={targetTxtPos.x - 4}
            y={targetTxtPos.y + 8}
            fill="rgba(255,255,255,0.6)"
            fontSize="7"
          >
            Target
          </text>

          <polygon
            ref={this.arrow}
            fill="white"
            fillOpacity={'1.0'}
            points="0,0 0,2 100,0 0,-2"
            transform={`rotate( 180 0 0)`}
          />

          <circle
            cx="0"
            cy="0"
            r="6"
            stroke="#3f3f3f"
            strokeOpacity="1.0"
            strokeWidth="0"
            fillOpacity="1.0"
            fill="#3f3f3f"
          />
        </g>
      </SVG>
    );
  }
}
/*
 <text dx="53%" dy="3" fontSize="9" fill="white">
            <textPath xlinkHref="#MyPath">TARGET</textPath>
          </text>
 */

const SVG = styled.svg``;

export default Gauge;
