import React, { Component, Fragment } from 'react';
import CommonButton, { Button } from 'components/CommonButton/index';
import Textfield from 'components/Textfield';
import DropdownList from 'components/DropdownList';
import DropdownList2 from 'components/DropdownList2';
import FilterList from 'components/FilterList';
import styled from 'styled-components';
import add_btn from '../assets/add_btn.png';
import edit_big from '../assets/edit_big.png';
import x_red from '../assets/x_red.png';
import delete_btn from '../assets/delete_btn.png';
import storage from 'utils/storage';
import { Content } from 'views/App';
import PopupDelete from '../PopupDelete';
import LoadingIndicator from 'components/LoadingIndicator';

class GmsEditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configName: '',
      newConfigName: '',
      configSlug: '',
      configNameEdit: false,
      dataSourceList: [],
      dataSourceListPlaceholder: 'Add Data Source',
      dataSourceListCurrent: '',
      dataSourceTable: [],
      dataSourceCopyTable: [],
      openDeletePopup: false,
      profileSlugToDelete: '',
      dataList: [],
      savedData: [],
      removedData: [],
      addedData: [],
      needUpdate: false,
      btnConfirmUpdate: false,
      needSave: true,
      isFetching: false
    };
    this.mounted = false;
    this.selectDataSource = this.selectDataSource.bind(this);
    this.setEditNameMode = this.setEditNameMode.bind(this);
    this.addSourceToTable = this.addSourceToTable.bind(this);
    this.setNewProfileName = this.setNewProfileName.bind(this);
    this.deleteProfile = this.deleteProfile.bind(this);
    this.editConfigProfile = this.editConfigProfile.bind(this);
    this.sourceDeleteFromTable = this.sourceDeleteFromTable.bind(this);
    this.confirmEditSource = this.confirmEditSource.bind(this);
    this.cancelEditSource = this.cancelEditSource.bind(this);
    this.openCloseEditSource = this.openCloseEditSource.bind(this);
    this.editSourceValues = this.editSourceValues.bind(this);
    this.noDeleteProfileBtn = this.noDeleteProfileBtn.bind(this);
    this.handleProfileSlugDelete = this.handleProfileSlugDelete.bind(this);
    this.handleDeleteBtn = this.handleDeleteBtn.bind(this);
    this.sortDropdownList = this.sortDropdownList.bind(this);
  }
  /**Mounting, fetching and parsing data */
  //region Fetching and parsing data
  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.privilegesUpdated) this.fetchData();
    else
      this.setState({
        isFetching: false
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  fetchData() {
    if (!this.mounted) return;
    this.setState({ isFetching: true });
    this.getData()
      .then(data => this.getListData(data))
      .then(data => this.onDataReceived(data));
  }
  getData() {
    if (!this.mounted) return;
    if (this.props.location.state == undefined) {
      this.props.history.push({
        pathname: '/gms/profiles'
      });
      return;
    }
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/configuration_profiles/configuration_profile/${
      this.props.location.state.data.slug
    }/view`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.payload = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.payload = json.result;
        else data.payload = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  getListData(data) {
    if (!this.mounted) return;
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/data_sources/list?data=configuration_params`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.list_data = json.result;
        else data.list_data = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  onDataReceived(data) {
    if (data.failed) {
      //if(receivedData.updatedToken==null)
      this.props.updatePrivCall(false);

      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }

    if (!this.mounted) return;

    this.setState(
      {
        isFetching: false
      },
      () => {
        this.parseData(data);
      }
    );

    // setTimeout(this.marItem, 500);
  }

  parseData(data) {
    let data_list = [];
    let dropdown_data = [];
    Object.entries(data.list_data).map(([key, val]) => {
      let param_list = {};
      param_list['service'] = val.name;
      param_list['slug'] = val.slug;
      param_list['isEdited'] = true;
      param_list['params'] = [];
      let dropdown_dataI = {};
      dropdown_dataI['key'] = val.slug;
      dropdown_dataI['value'] = val.name;
      dropdown_data.push(dropdown_dataI);
      Object.entries(val.configuration_params).map(([k, v]) => {
        let param_item = {};
        param_item['name'] = v.name;
        param_item['value'] = '';
        param_item['slug'] = v.slug;
        param_list['params'].push(param_item);
      });

      data_list.push(param_list);
    });
    let config_data = [];
    Object.entries(data.payload[0].data_sources).map(([key, val]) => {
      let param_list = {};
      param_list['service'] = val.name;
      param_list['slug'] = val.slug;
      Object.entries(dropdown_data).map(([k, v]) => {
        if (dropdown_data[k].key === val.slug) {
          delete dropdown_data[k];
        }
      });

      param_list['isEdited'] = false;
      param_list['params'] = [];
      Object.entries(val.configuration_params).map(([k, v]) => {
        let param_item = {};
        param_item['name'] = v.slug;
        if (v.value == null) {
          param_item['value'] = '';
        } else {
          param_item['value'] = v.value;
        }
        param_item['slug'] = v.slug;
        param_list['params'].push(param_item);
      });
      config_data.push(param_list);
    });

    let copy_config_data = JSON.parse(JSON.stringify(config_data));

    this.setState(
      {
        dataList: data_list,
        dataSourceList: dropdown_data,
        configName: data.payload[0].name,
        newConfigName: data.payload[0].name,
        configSlug: data.payload[0].slug,
        dataSourceTable: config_data,
        dataSourceCopyTable: copy_config_data,
        savedData: data.payload[0].data_sources
      },
      () => {
        this.sortDropdownList();
      }
    );
  }
  //endregion

  sortDropdownList() {
    let dropdown_list = this.state.dataSourceList;
    dropdown_list.sort((a, b) => {
      if (a['value'].toLowerCase() < b['value'].toLowerCase()) return -1;

      if (a['value'].toLowerCase() > b['value'].toLowerCase()) return 1;

      return 0;
    });

    this.setState({ dataSourceList: dropdown_list });
  }

  setEditNameMode() {
    let editMode = this.state.configNameEdit;
    let savedName = this.state.configName;
    if (editMode == false) {
      this.setState({ configNameEdit: !editMode, newConfigName: savedName });
    } else {
      this.setState({ configNameEdit: !editMode, needSave: true });
    }
  }

  selectDataSource(event) {
    const target = event.target;
    this.setState({
      dataSourceListPlaceholder: '',
      dataSourceListCurrent: target.value
    });
  }

  deleteProfile() {
    if (this.state.profileSlugToDelete != this.state.configSlug) {
      return;
    }
    this.setState({ isFetching: true });
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/configuration_profiles/configuration_profile/${
      this.state.configSlug
    }/delete`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let payload = {
      data: {
        slug: this.state.configSlug
      }
    };

    return fetch(url, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();

        if (status === 200) return json;
        else {
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        this.props.history.push({
          pathname: '/gms/profiles'
        });
        return json;
      })
      .catch(error => {
        this.setState({ isFetching: false });
        console.error(error);
      });
  }

  editConfigProfile() {
    if (this.state.needSave === false) {
      return;
    }
    this.setState({ isFetching: true });
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/configuration_profiles/configuration_profile/${this.state.configSlug}/edit`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };

    let name;
    if (this.state.configNameEdit) {
      name = this.state.newConfigName;
    } else {
      name = this.state.configName;
    }
    let payload = {
      data: {
        name: name
      }
    };

    let added_data = [];
    Object.entries(this.state.dataSourceTable).map(([key, val]) => {
      Object.entries(this.state.addedData).map(([k, v]) => {
        if (val.slug === v) {
          let added_item = {};
          added_item['slug'] = val.slug;
          added_item['configuration_params'] = [];
          Object.entries(val.params).map(([m, n]) => {
            let item = {};
            item['slug'] = n.slug;
            item['value'] = n.value;
            added_item['configuration_params'].push(item);
          });
          added_data.push(added_item);
        }
      });
    });
    if (this.state.removedData.length > 0) {
      payload.data.remove_data_sources = this.state.removedData;
    }

    if (added_data.length > 0) {
      payload.data.add_data_sources = added_data;
    }
    let modify_data = [];
    Object.entries(this.state.savedData).map(([key, val]) => {
      Object.entries(this.state.dataSourceTable).map(([k, v]) => {
        if (val.slug === v.slug) {
          Object.entries(val.configuration_params).map(([sk, sv]) => {
            Object.entries(v.params).map(([dk, dv]) => {
              if (sv.slug === dv.slug && sv.value !== dv.value) {
                modify_data.push(v.slug);
              }
            });
          });
        }
      });
    });
    let modify_object = [];
    Object.entries(this.state.dataSourceTable).map(([key, val]) => {
      Object.entries(modify_data).map(([mk, mv]) => {
        if (val.slug === mv) {
          let modify_item = {};
          modify_item['slug'] = val.slug;
          modify_item['configuration_params'] = [];
          Object.entries(val.params).map(([k, v]) => {
            let item = {};
            item['slug'] = v.slug;
            item['value'] = v.value;
            modify_item['configuration_params'].push(item);
          });

          modify_object.push(modify_item);
        }
      });
    });

    if (modify_object.length > 0) {
      payload.data.modify_data_sources = modify_object;
    }

    return fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) storage.USER.token = token;
        if (status === 200) return json;
        else {
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        this.props.history.push({
          pathname: '/gms/profiles'
        });
        return json;
      })
      .catch(error => {
        this.setState({ isFetching: false });
        console.error(error);
      });
  }

  setNewProfileName(event) {
    const target = event.target;
    let name = target.value;
    let update = false;
    if (name !== '' && name.length >= 1 && name.length < 50) {
      update = true;
    }
    this.setState({
      newConfigName: target.value,
      needSave: update
    });
  }
  /** Data source management */
  //region Data source management

  addSourceToTable() {
    let currentSource = this.state.dataSourceListCurrent;
    let sourceTable = this.state.dataSourceTable;
    let copySourceTable = this.state.dataSourceCopyTable;
    let dropdownList = this.state.dataSourceList;
    if (currentSource !== '') {
      let addTable = this.state.addedData;
      Object.entries(this.state.dataList).map(([k, v]) => {
        if (v.slug === currentSource) {
          sourceTable.push(v);
          let newObj = JSON.parse(JSON.stringify(v));
          copySourceTable.push(newObj);
          addTable.push(v.slug);
        }
      });

      Object.entries(this.state.dataSourceList).map(([key, val]) => {
        if (val.key == currentSource) {
          delete dropdownList[key];
        }
      });
    }
    this.setState({
      dataSourceTable: sourceTable,
      dataSourceCopyTable: copySourceTable,
      dataSourceListCurrent: '',
      dataSourceListPlaceholder: 'Add Data Source',
      dataSourceList: dropdownList
    });
  }

  confirmEditSource(sourceId) {
    let sourceTable = this.state.dataSourceTable;
    let copySourceTable = this.state.dataSourceCopyTable;
    sourceTable[sourceId].isEdited = false;
    let objectToCopy = JSON.parse(JSON.stringify(sourceTable[sourceId]));
    copySourceTable[sourceId] = objectToCopy;
    this.setState({ dataSourceTable: sourceTable, dataSourceCopyTable: copySourceTable });
  }

  cancelEditSource(sourceId) {
    let sourceTable = this.state.dataSourceTable;
    let copySourceTable = this.state.dataSourceCopyTable;

    let objectToCopy = JSON.parse(JSON.stringify(copySourceTable[sourceId]));
    sourceTable[sourceId] = objectToCopy;
    sourceTable[sourceId].isEdited = false;
    this.setState({ dataSourceTable: sourceTable });
  }

  sourceDeleteFromTable(sourceId) {
    let sourceTable = this.state.dataSourceTable;
    let copySourceTable = this.state.dataSourceCopyTable;
    let removedItems = this.state.removedData;
    let addedData = this.state.addedData;
    let dropdownList = this.state.dataSourceList;

    dropdownList.push({ key: sourceTable[sourceId].slug, value: sourceTable[sourceId].service });
    if (addedData.includes(sourceTable[sourceId].slug)) {
      let item = addedData.indexOf(addedData[sourceTable[sourceId]]);
      addedData.splice(item, 1);
      sourceTable.splice(sourceId, 1);
    } else {
      Object.entries(this.state.savedData).map(([key, val]) => {
        if (val.slug == sourceTable[sourceId].slug) {
          removedItems.push(val.slug);
        }
      });
      sourceTable.splice(sourceId, 1);
    }

    copySourceTable.splice(sourceId, 1);
    this.setState(
      {
        dataSourceTable: sourceTable,
        dataSourceCopyTable: copySourceTable,
        removedData: removedItems,
        addedData: addedData,
        dataSourceList: dropdownList
      },
      () => {
        this.sortDropdownList();
      }
    );
  }

  openCloseEditSource(sourceId) {
    let sourceTable = this.state.dataSourceTable;
    let val = sourceTable[sourceId].isEdited;
    sourceTable[sourceId].isEdited = !val;
    this.setState({ dataSourceTable: sourceTable });
  }

  editSourceValues(event, sourceId, key) {
    const target = event.target;
    let sourceTable = this.state.dataSourceTable;

    sourceTable[sourceId].params[key].value = target.value;
    this.setState({ dataSourceTable: sourceTable });
  }
  //endregion

  handleProfileSlugDelete(event) {
    const target = event.target;
    let needUpdate = false;
    if (target.value === this.state.configSlug) {
      needUpdate = true;
    }
    this.setState({
      profileSlugToDelete: target.value,
      btnConfirmUpdate: needUpdate
    });
  }

  noDeleteProfileBtn() {
    this.setState({ openDeletePopup: false });
  }

  handleDeleteBtn() {
    this.setState({ openDeletePopup: true });
  }

  render() {
    if (!this.mounted) return;
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }
    let configName;
    if (this.state.configNameEdit) {
      configName = (
        <TextLabel>
          <Textfield
            labelText="Name"
            id="addPlatformName"
            value={this.state.newConfigName}
            onChange={this.setNewProfileName}
          />
          <ProfileEditMode src={x_red} onClick={this.setEditNameMode} />
        </TextLabel>
      );
    } else {
      configName = (
        <ProfileName>
          {this.state.configName}
          <ProfileIconEdit src={edit_big} onClick={this.setEditNameMode} />
        </ProfileName>
      );
    }

    return (
      <Fragment>
        <ViewTile>{'Edit Configuration Profile'}</ViewTile>
        {this.state.openDeletePopup ? (
          <div>
            <BackgroundPopup />
            <PopupDelete
              closeBtn={this.noDeleteProfileBtn}
              onChange={this.handleProfileSlugDelete}
              yesBtn={this.deleteProfile}
              slug={this.state.configSlug}
              itemType={' configuration profile'}
              needUpdate={this.state.btnConfirmUpdate}
            />
          </div>
        ) : null}
        <AddProfileContainer>
          <ProfileNameContainer>{configName}</ProfileNameContainer>
          <DeleteProfileContaner>
            <DeleteImg src={delete_btn} onClick={this.handleDeleteBtn} />
          </DeleteProfileContaner>
          <ListLabel>
            <LabelForm htmlFor="addProfileSource">Data Source</LabelForm>
            {/*  <DropdownList
              items={this.state.dataSourceList}
              name="addGamesDataSourceList"
              id="addGamesDataSource"
              placeholder={this.state.dataSourceListPlaceholder}
              uppercase={false}
              handler={this.selectDataSource}
              currentOpt={this.state.dataSourceListCurrent}
              kvPairs={true}
            /> */}
            <DropdownList2
              items={this.state.dataSourceList}
              name="addGamesDataSourceList"
              id="addGamesDataSource"
              placeholder={this.state.dataSourceListPlaceholder}
              handler={this.selectDataSource}
              currentOption={this.state.dataSourceListCurrent}
            />
          </ListLabel>
          <AddSource>
            <AddSourceImg src={add_btn} alt="add source" onClick={this.addSourceToTable} />
          </AddSource>
          <SourcesContainer>
            <FilterList
              items={this.state.dataSourceTable}
              deleteSource={this.sourceDeleteFromTable}
              openCloseEdit={this.openCloseEditSource}
              editText={this.editSourceValues}
              confirmEdit={this.confirmEditSource}
              cancelEdit={this.cancelEditSource}
            />
          </SourcesContainer>
          <ButtonContainer>
            <Button needUpdate={this.state.needSave} onClick={this.editConfigProfile}>
              Save changes
            </Button>
          </ButtonContainer>
        </AddProfileContainer>
      </Fragment>
    );
  }
}

export default GmsEditProfile;

//region Styles
const ViewTile = styled.h1`
  width: 95%;
  color: #fff;
  padding: 0rem 0rem 1%;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 4rem;
  margin-right: 3%;
  margin-left: 2%;
  margin-bottom: 1rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;

const AddSourceImg = styled.img`
  cursor: pointer;
  grid-row: 2;
  grid-column: 2/3;
`;

const LabelForm = styled.label`
  font-size: 12px;
  color: #99999c;
  display: block;
  margin-bottom: 5px;
`;

const AddProfileContainer = styled.div`
  display: grid;
  margin-top: 50px;
  grid-template-columns: 30rem 80rem;
  grid-row-gap: 20px;
  grid-column-gap: 10px;
  margin-left: 13%;
  padding-bottom: 60px;
`;

const ProfileNameContainer = styled.div`
  grid-column: 1/2;
  grid-row: 1;
  position: relative;
`;

const TextLabel = styled.div`
  position: absolute;
  top: -21px;
  width: 90%;
`;

const ListLabel = styled.div`
  grid-column: 1/2;
  grid-row: 2;
`;

const AddSource = styled.div`
  grid-column: 2/3;
  grid-row: 2;
  margin-top: 23px;
`;

const ButtonContainer = styled.div`
  grid-row: 4;
  grid-column: 1/2;
  height: 32px;
`;

const SourcesContainer = styled.div`
  grid-row: 3;
  grid-column: 1/3;
`;

const ProfileEditMode = styled.img`
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  top: 28px;
  left: 270px;
`;

const ProfileIconEdit = styled.img`
  display: inline;
  margin-left: 10px;
  cursor: pointer;
`;

const ProfileName = styled.div`
  font-size: 16px;
  color: rgb(225, 225, 229);
  letter-spacing: 0.05rem;
  font-weight: bolder;
  font-family: 'Titillium Web';
`;

const DeleteProfileContaner = styled.div`
  grid-column: 2/3;
  grid-row: 1;
`;

const BackgroundPopup = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
  background-color: #282b30;
  opacity: 0.7;
  z-index: 100;
`;

const DeleteImg = styled.img`
  cursor: pointer;
`;
//endregion
