export const formatNumberWithK = (num, defaultSize = 1) => {
  num = Math.round(num * 100) / 100;
  return num > 999
    ? num % 1000 === 0
      ? (num / 1000).toFixed(0) + 'k'
      : (num / 1000).toFixed(1) + 'k'
    : num % 1 === 0
    ? num
    : num.toFixed(defaultSize);
};

export default formatNumberWithK;
