import React, { Component } from 'react';
import SimpleChart from './SimpleChart';
import styled, { injectGlobal, keyframes } from 'styled-components';
import GameIcon from 'components/GameIcon';
import storage from 'utils/storage';
class GameInstallsPanel extends Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    let updatedItems = this.props.items;

    updatedItems[value] = target.checked;
    this.props.changeHandler(this.props.id, updatedItems);
  }

  render() {
    let overallLevel = 0;
    if (storage.GAMES_DATA[this.props.item.slug]) {
      storage.GAMES_DATA[this.props.item.slug].alerts.map(item => {
        if (item.level > overallLevel) overallLevel = item.level;
      });
    }

    let colorsTable = ['#009432', '#f79f1f', '#ea2027'];
    return (
      <Panel key={'gamesInstallsPanelKey' + Math.random() * 1000} onClick={this.props.onClick}>
        <GameIcon
          margin={'20px'}
          slug={this.props.item.slug}
          src={this.props.item.icon}
          size={80}
          indicatorSize={24}
          color={colorsTable[overallLevel]}
          tooltip={{
            orientation: 'left',
            top: '-9',
            left: '35',
            right: null,
            bottom: null,
            arrow: { top: '14px', left: null, right: '100%', bottom: null },
            origin: { top: '14px', left: 0 }
          }}
        />
        <Info>{this.props.item.name}</Info>
        <Graph>
          <SimpleChart data={this.props.installsData} />
        </Graph>
      </Panel>
    );
  }
}

export default GameInstallsPanel;
/** GameInstallsPanel Styles */
//region Styles
const fadein = keyframes`
 0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const Icon = styled.img`
  width: 80px;
  height: 80px;
  margin: 20px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 4;
`;

const Graph = styled.div`
  display: flex;
  align-items: center;
  width: 140px;
  height: 100px;
`;

const Info = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  font-weight: 600;
  height: 80px;
  color: #e1e1e5;
  box-sizing: border-box;
`;
const Panel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 578px;
  min-width: 400px;
  height: 120px;
  border: 1px solid #36393f;
  border-radius: 4px;
  background-color: #282b30;
  cursor: pointer;
  animation: ${fadein} 0.3s ease-in-out;
  &:hover {
    transition: background-color 0.2s ease-in-out;
    background-color: #1e2022;
  }
`;
//endregion
