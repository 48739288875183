import React, { Component, Fragment } from 'react';
import CommonButton, { Button } from 'components/CommonButton/index';
import FilterList from 'components/FilterList';
import Textfield from 'components/Textfield';
import add_btn from '../assets/add_btn.png';
import styled from 'styled-components';
import { Content } from 'views/App';
import storage from 'utils/storage';
import LoadingIndicator from 'components/LoadingIndicator';
class GmsAddData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      dateName: '',
      dateID: '',
      currentGameParameter: '',
      currentConfigurationProfile: '',
      gameParameterTable: [],
      gameCopyTable: [],
      configurationProfileTable: [],
      configurationCopyTable: [],
      isTheSame: false,
      isTheSameConfig: false,
      needUpdate: false
    };
    this.mounted = false;
    this.setDateName = this.setDateName.bind(this);
    this.setDateID = this.setDateID.bind(this);
    this.setGameParameter = this.setGameParameter.bind(this);
    this.setConfigurationProfile = this.setConfigurationProfile.bind(this);
    this.addGameParameter = this.addGameParameter.bind(this);
    this.addConfigurationProfile = this.addConfigurationProfile.bind(this);
    this.openCloseEditGameParameter = this.openCloseEditGameParameter.bind(this);
    this.openCloseEditConfiguration = this.openCloseEditConfiguration.bind(this);
    this.confirmEditGame = this.confirmEditGame.bind(this);
    this.cancelEditGame = this.cancelEditGame.bind(this);
    this.confirmEditConfig = this.confirmEditConfig.bind(this);
    this.cancelEditConfig = this.cancelEditConfig.bind(this);
    this.deleteGameParameterItem = this.deleteGameParameterItem.bind(this);
    this.deleteConfigParameterItem = this.deleteConfigParameterItem.bind(this);
    this.editGameParameterValue = this.editGameParameterValue.bind(this);
    this.editConfigParameterValue = this.editConfigParameterValue.bind(this);
    this.handleAddData = this.handleAddData.bind(this);
    this.checkUpdate = this.checkUpdate.bind(this);
    this.checkTheSame = this.checkTheSame.bind(this);
    this.checkTheSameConfig = this.checkTheSameConfig.bind(this);
  }
  /** Add Data Lifecycle Methods */
  //region Lifecycle Methods
  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.mounted = false;
  }
  //endregion
  /** Setting textfields */
  //region Setting textfields

  setDateName(event) {
    const target = event.target;
    this.setState(
      {
        dateName: target.value
      },
      () => {
        this.checkUpdate();
      }
    );
  }

  checkTheSame() {
    let sourceTable = this.state.gameParameterTable;
    let counter = 0;
    Object.entries(sourceTable).map(([k, v]) => {
      if (v.value === this.state.currentGameParameter) {
        counter++;
      }
    });

    if (counter > 0) {
      this.setState({ isTheSame: true });
    } else {
      this.setState({ isTheSame: false });
    }
  }

  checkTheSameConfig() {
    let sourceTable = this.state.configurationProfileTable;
    let counter = 0;
    Object.entries(sourceTable).map(([k, v]) => {
      if (v.value === this.state.currentConfigurationProfile) {
        counter++;
      }
    });

    if (counter > 0) {
      this.setState({ isTheSameConfig: true });
    } else {
      this.setState({ isTheSameConfig: false });
    }
  }

  setGameParameter(event) {
    const target = event.target;

    this.setState(
      {
        currentGameParameter: target.value
      },
      () => {
        this.checkTheSame();
      }
    );
  }

  setConfigurationProfile(event) {
    const target = event.target;
    this.setState(
      {
        currentConfigurationProfile: target.value
      },
      () => {
        this.checkTheSameConfig();
      }
    );
  }
  setDateID(event) {
    const target = event.target;
    this.setState(
      {
        dateID: target.value
      },
      () => {
        this.checkUpdate();
      }
    );
  }
  //endregion

  checkUpdate() {
    let update = false;
    if (
      this.state.dateName.length >= 1 &&
      this.state.dateName.length < 50 &&
      this.state.dateName !== '' &&
      this.state.dateID.length >= 1 &&
      this.state.dateID.length < 50 &&
      this.state.dateID !== ''
    ) {
      update = true;
    }
    this.setState({ needUpdate: update });
  }

  /** Data Source Panel management */
  //region Data Source Panel management
  openCloseEditGameParameter(sourceId) {
    let sourceTable = this.state.gameParameterTable;
    let value = sourceTable[sourceId].isEdited;
    sourceTable[sourceId].isEdited = !value;
    this.setState({ gameParameterTable: sourceTable });
  }

  openCloseEditConfiguration(sourceId) {
    let sourceTable = this.state.configurationProfileTable;
    let value = sourceTable[sourceId].isEdited;
    sourceTable[sourceId].isEdited = !value;
    this.setState({ configurationProfileTable: sourceTable });
  }

  confirmEditGame(sourceId) {
    let sourceTable = this.state.gameParameterTable;
    let copyTable = this.state.gameCopyTable;
    sourceTable[sourceId].isEdited = false;
    let objectToCopy = JSON.parse(JSON.stringify(sourceTable[sourceId]));

    copyTable[sourceId] = objectToCopy;

    this.setState({ gameParameterTable: sourceTable, gameCopyTable: copyTable });
  }

  cancelEditGame(sourceId) {
    let sourceTable = this.state.gameParameterTable;
    let copyTable = this.state.gameCopyTable;
    let objectToCopy = JSON.parse(JSON.stringify(copyTable[sourceId]));

    sourceTable[sourceId] = objectToCopy;
    sourceTable[sourceId].isEdited = false;
    this.setState({ gameParameterTable: sourceTable });
  }

  confirmEditConfig(sourceId) {
    let sourceTable = this.state.configurationProfileTable;
    let copyTable = this.state.configurationCopyTable;
    sourceTable[sourceId].isEdited = false;
    let objectToCopy = JSON.parse(JSON.stringify(sourceTable[sourceId]));

    copyTable[sourceId] = objectToCopy;

    this.setState({ configurationProfileTable: sourceTable, configurationCopyTable: copyTable });
  }

  cancelEditConfig(sourceId) {
    let sourceTable = this.state.configurationProfileTable;
    let copyTable = this.state.configurationCopyTable;
    let objectToCopy = JSON.parse(JSON.stringify(copyTable[sourceId]));

    sourceTable[sourceId] = objectToCopy;
    sourceTable[sourceId].isEdited = false;
    this.setState({ configurationProfileTable: sourceTable });
  }

  deleteGameParameterItem(sourceId) {
    let sourceTable = this.state.gameParameterTable;
    let copySourceTable = this.state.gameCopyTable;
    sourceTable.splice(sourceId, 1);
    copySourceTable.splice(sourceId, 1);
    this.setState({ gameParameterTable: sourceTable, gameCopyTable: copySourceTable });
  }

  deleteConfigParameterItem(sourceId) {
    let sourceTable = this.state.configurationProfileTable;
    let copyTable = this.state.configurationCopyTable;
    sourceTable.splice(sourceId, 1);
    copyTable.splice(sourceId, 1);
    this.setState({ configurationProfileTable: sourceTable, configurationCopyTable: copyTable });
  }

  editGameParameterValue(event, sourceId) {
    const target = event.target;
    let sourceTable = this.state.gameParameterTable;
    sourceTable[sourceId].value = target.value;
    this.setState({ gameParameterTable: sourceTable });
  }

  editConfigParameterValue(event, sourceId) {
    const target = event.target;
    let sourceTable = this.state.configurationProfileTable;
    sourceTable[sourceId].value = target.value;
    this.setState({ configurationProfileTable: sourceTable });
  }
  addGameParameter() {
    let currentGameParameter = this.state.currentGameParameter;
    if (currentGameParameter !== '') {
      let sourceTable = this.state.gameParameterTable;
      let copySourceTable = this.state.gameCopyTable;
      sourceTable.push({ value: currentGameParameter, isEdited: false });
      copySourceTable.push({ value: currentGameParameter, isEdited: false });
      document.getElementById('addDataParameters').value = '';
      this.setState({
        gameParameterTable: sourceTable,
        currentGameParameter: '',
        gameCopyTable: copySourceTable
      });
    }
  }

  addConfigurationProfile() {
    let currentConfigurationProfile = this.state.currentConfigurationProfile;
    if (currentConfigurationProfile !== '') {
      let sourceTable = this.state.configurationProfileTable;
      sourceTable.push({ value: currentConfigurationProfile, isEdited: false });
      let copyTable = this.state.configurationCopyTable;
      copyTable.push({ value: currentConfigurationProfile, isEdited: false });
      document.getElementById('addDataConfiguration').value = '';
      this.setState({
        configurationProfileTable: sourceTable,
        currentConfigurationProfile: '',
        configurationCopyTable: copyTable
      });
    }
  }

  //endregion

  handleAddData() {
    if (!this.state.needUpdate) {
      return;
    } else {
      this.setState({ isFetching: true });
      let game_params = [];
      Object.entries(this.state.gameParameterTable).map(([k, v]) => {
        game_params.push(v.value);
      });

      let config_params = [];
      Object.entries(this.state.configurationProfileTable).map(([k, v]) => {
        config_params.push(v.value);
      });
      let payload = {
        data: {
          name: this.state.dateName,
          slug: this.state.dateID,
          configuration_params: config_params,
          game_params: game_params
        }
      };
      const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
      const PATH_CALL = '/data_sources/add';
      const url = `${PATH_BASE}${PATH_CALL}`;
      let headers = {
        'Access-Control-Origin': '*',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: storage.USER.token
      };

      return fetch(url, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(payload)
      })
        .then(response => {
          let status = response.status;
          let token = response.headers.get('token');
          let json = response.json();
          if (token != null) storage.USER.token = token;
          if (status === 200) return json;
          else {
            return;
          }
        })
        .then(json => {
          if (json === undefined) return;
          this.props.history.push({
            pathname: '/gms/data'
          });
          return json;
        })
        .catch(error => {
          this.setState({ isFetching: false });
          console.error(error);
        });
    }
  }

  render() {
    if (!this.mounted) return;
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }
    return (
      <Fragment>
        <ViewTile>{'Add Data Source'}</ViewTile>
        <AddDataContainer>
          <DateName>
            <Textfield
              labelText="Name"
              id="addDataName"
              placeholder="Data Source Name"
              onChange={this.setDateName}
            />
          </DateName>
          <DateID>
            <Textfield
              labelText="ID"
              id="addDataID"
              placeholder="Data Source ID"
              onChange={this.setDateID}
            />
          </DateID>
          <ButtonContainer>
            <Button needUpdate={this.state.needUpdate} onClick={this.handleAddData}>
              Add data source
            </Button>
          </ButtonContainer>
          <GameParameters>
            <Textfield
              labelText="Game Parameters"
              id="addDataParameters"
              placeholder="Add Parameter"
              onChange={this.setGameParameter}
            />
          </GameParameters>
          <AddGame>
            <AddImg
              isTheSame={this.state.isTheSame}
              src={add_btn}
              onClick={this.addGameParameter}
            />
          </AddGame>
          <ConfigurationParameters>
            <Textfield
              labelText="Configuration Parameters"
              id="addDataConfiguration"
              placeholder="Add Parameter"
              onChange={this.setConfigurationProfile}
            />
          </ConfigurationParameters>
          <AddParameters>
            <AddImg
              isTheSame={this.state.isTheSameConfig}
              src={add_btn}
              onClick={this.addConfigurationProfile}
            />
          </AddParameters>

          {this.state.gameParameterTable ? (
            <FilterGameParametersContainer>
              <FilterList
                items={this.state.gameParameterTable}
                openCloseEdit={this.openCloseEditGameParameter}
                deleteSource={this.deleteGameParameterItem}
                oneLabelMode={true}
                editText={this.editGameParameterValue}
                confirmEdit={this.confirmEditGame}
                cancelEdit={this.cancelEditGame}
              />
            </FilterGameParametersContainer>
          ) : null}
          {this.state.configurationProfileTable ? (
            <FilterConfigurationParameters>
              <FilterList
                items={this.state.configurationProfileTable}
                openCloseEdit={this.openCloseEditConfiguration}
                deleteSource={this.deleteConfigParameterItem}
                oneLabelMode={true}
                editText={this.editConfigParameterValue}
                confirmEdit={this.confirmEditConfig}
                cancelEdit={this.cancelEditConfig}
              />
            </FilterConfigurationParameters>
          ) : null}
        </AddDataContainer>
      </Fragment>
    );
  }
}

export default GmsAddData;

//region Styles
const ViewTile = styled.h1`
  width: 95%;
  color: #fff;
  padding: 0rem 0rem 1%;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 4rem;
  margin-right: 3%;
  margin-left: 2%;
  margin-bottom: 1rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;

const AddDataContainer = styled.div`
  display: grid;
  margin-top: 50px;
  grid-template-columns: 30rem 5rem 30rem 5rem;
  grid-row-gap: 20px;
  grid-column-gap: 10px;
  margin-left: 13%;
`;

const DateName = styled.div`
  grid-row: 1;
  grid-column: 1/2;
`;

const DateID = styled.div`
  grid-row: 2;
  grid-column: 1/2;
`;

const ButtonContainer = styled.div`
  grid-row: 5;
  grid-column: 1/2;
  height: 32px;
`;

const GameParameters = styled.div`
  grid-row: 3;
  grid-column: 1/2;
`;

const ConfigurationParameters = styled.div`
  grid-row: 3;
  grid-column: 3/4;
`;

const AddGame = styled.div`
  grid-row: 3;
  grid-column: 2/3;
`;

const AddParameters = styled.div`
  grid-row: 3;
  grid-column: 4/5;
`;

const AddImg = styled.img`
  margin-top: 25px;
  cursor: pointer;
  opacity: 1;
  pointer-events: auto;
  ${({ isTheSame }) =>
    isTheSame &&
    `
  opacity:0.5;
  pointer:none;
  pointer-events:none;
  `};
`;

const FilterGameParametersContainer = styled.div`
  grid-row: 4;
  grid-column: 1/2;
`;

const FilterConfigurationParameters = styled.div`
  grid-row: 4;
  grid-column: 3/4;
`;
//endregion
