import React, { Component } from 'react';
import { defaults, Bar, Line } from 'react-chartjs-2';
import { backgroundColorTable, borderColorTable } from 'components/ColorPicker/index';
import formatNumberWithK from 'utils/formatNumberWithK';

class ChartInstance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false
    };
    this.chartRef = React.createRef();
    this.mounted = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("shouldComponentUpdate",this.state,nextState)
    //if(this.state.tooltip !== nextState.tooltip)return true;
    if (nextProps.data !== this.props.data) return true;
    return false;
  }

  componentDidMount() {
    // console.log("componentDidMount",this.state)
    this.setState({ mounted: true });
    this.mounted = true;
    //console.log('this.chartRef)',this.chartRef);
    this.props.updateChartRef(this.chartRef);
    //this.forceUpdate();
  }

  render() {
    let data = this.props.parseData(this.props.data);
    let dt;
    dt = Object.assign({}, data);
    let options = {
      title: {
        display: false,
        text: ''
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          fontColor: '#989898',
          fontSize: 15
        }
      },
      elements: {
        line: {
          borderWidth: 2
        },
        point: { radius: 0, hitRadius: 0, hoverRadius: 0 }
      },
      tooltips: {
        enabled: false,
        labelColors: backgroundColorTable,
        xPadding: 10,
        yPadding: 10,
        yAlign: 'top',
        position: 'nearest',
        intersect: false,
        mode: 'label',
        custom: tooltipModel => {
          this.props.showTooltip(tooltipModel);
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            ticks: {
              padding: 0,
              maxRotation: 30,
              autoSkip: true
            },
            stacked: false,

            gridLines: {
              drawBorder: true,
              color: '#2f3238'
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              min: 0,
              beginAtZero: true,
              callback: function(label, index, labels) {
                return '$' + formatNumberWithK(label) + '';
              }
            },
            scaleLabel: {
              display: true
            },

            stacked: false,

            gridLines: {
              drawBorder: true,

              color: '#2f3238'
            }
          }
        ]
      }
    };
    return <Line ref={this.chartRef} data={dt} options={options} />;
  }
}

export default ChartInstance;
