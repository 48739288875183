import React from 'react';
import PlayStackLogo from 'components/PlayStackLogo';
import LoginForm from './LoginForm';
import LoadingIndicator from 'components/LoadingIndicator';
import './style.css';
import styled from 'styled-components';
import { Content } from 'views/App';

const Login = props => {
  return (
    <div className="LoginContainer">
      <PlayStackLogo />
      <LoginForm
        invalid_user={props.invalid_user}
        handleLogin={props.handleLogin}
        logout_info={props.logout_info}
      />
      {props.waiting && <LoadingIndicator />}
    </div>
  );
};

export default Login;
