import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

const RadioInput = props => {
  return (
    <RadioButton>
      <input
        id={props.id}
        checked={props.checked}
        type="radio"
        onChange={props.onChange}
        value={props.value}
      />
      <label htmlFor={props.id}>{props.label}</label>
    </RadioButton>
  );
};

export default RadioInput;

const RadioButton = styled.div`
  input[type='radio']:checked,
  input[type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  input[type='radio']:checked + label,
  input[type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 22px;
    padding-right: 10px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #e1e1e5;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.05rem;
  }
  input[type='radio']:checked + label:before,
  input[type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #414449;
    border-radius: 100%;
    background: rgb(31, 32, 34);
  }
  input[type='radio']:checked + label:after,
  input[type='radio']:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: rgb(3, 213, 172);
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  input[type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  input[type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;
