import React, { Component } from 'react';

import DataContainer from './DataContainer';
import LoadingIndicator from 'components/LoadingIndicator';

import styled, { injectGlobal, keyframes } from 'styled-components';

const Container = styled.div`
  display: grid;

  margin-top: ${props => (props.topMargin ? props.topMargin : '128px')};
  margin-left: 10%;
  margin-right: 8%;
  margin-bottom: 8rem;
  margin-bottom: ${props => (props.bottomMargin ? props.bottomMargin : '128px')};
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  grid-gap: 88px;

  padding: 0;
  background-color: #2f3238;
  @media (max-width: 1800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

class GridView extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {}

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {}

  render() {
    if (this.props.isFetching) {
      return <LoadingIndicator />;
    }
    if (!this.props.data) {
      return (
        <div className="fetchDataerrorMessage">
          <h3>Oops!</h3>
          <p>
            You don't have permissions to see this view or there is no data available at the moment.
            Please try Again!
          </p>
        </div>
      );
    }
    return (
      <Container topMargin={this.props.topMargin} bottomMargin={this.props.bottomMargin}>
        {this.props.data.map(item => {
          if (this.props.filter && item[this.props.filter] === this.props.filterVal) {
            return (
              <DataContainer
                key={item.slug}
                panel={this.props.panel}
                data={item}
                handleClick={this.props.handleClick}
                listData={this.props.listData}
              />
            );
          } else if (this.props.filter === undefined) {
            return (
              <DataContainer
                key={item.slug}
                panel={this.props.panel}
                data={item}
                handleClick={this.props.handleClick}
                listData={this.props.listData}
              />
            );
          }
        })}
      </Container>
    );
  }
}

export default GridView;
