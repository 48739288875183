import React, { Component, Fragment } from 'react';
import './style.css';
import AnimatedView from 'components/AnimatedView';
import CommonButton from 'components/CommonButton/index';
import ColorPicker from 'components/ColorPicker';
import styled from 'styled-components';
import warning_icon from '../../../Login/LoginForm/assets/warning_icon.png';
import CheckboxList from 'components/CheckboxList';
import storage from 'utils/storage';
import posed from 'react-pose';
import Popup from 'components/Popup';
import Textfield from 'components/Textfield';

class GroupsPopup extends Component {
  constructor(props) {
    super(props);
    const color = props.userData ? props.userData.color : 'rgb(153, 153, 156)';
    this.state = {
      list_games: null,
      warning_name_group: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.parseData = this.parseData.bind(this);
    this.parseEditData = this.parseEditData.bind(this);
    this.handleCheckPermission = this.handleCheckPermission.bind(this);
    this.renderPrivilegesList = this.renderPrivilegesList.bind(this);
    this.handleClickColorPicker = this.handleClickColorPicker.bind(this);
    this.handlePickColor = this.handlePickColor.bind(this);
  }

  componentWillMount() {
    if (this.props.type != 'delete') {
      this.parseData();
    }
    if (this.props.type == 'edit') {
      this.parseEditData();
    }
  }

  parseData() {
    let games_list = [];
    let games_sort = [];
    Object.entries(storage.GAMES_DATA).map(([k, v]) => {
      let obj = {};
      obj['name'] = k;
      obj['value'] = v;
      games_sort.push(obj);
    });

    games_sort.sort((a, b) => {
      if (a['name'].toLowerCase() < b['name'].toLowerCase()) return -1;

      if (a['name'].toLowerCase() > b['name'].toLowerCase()) return 1;

      return 0;
    });

    Object.entries(games_sort).map(([k, v]) => {
      games_list[v.name] = { ...v.value };
      games_list[v.name]['checked'] = false;
    });

    let newState = { ...this.state };

    newState.list_games = games_list;

    this.setState(newState);
  }

  parseEditData() {
    let games_list = [];
    let games_sort = [];
    Object.entries(storage.GAMES_DATA).map(([k, v]) => {
      let obj = {};
      obj['name'] = k;
      obj['value'] = v;
      games_sort.push(obj);
    });

    games_sort.sort((a, b) => {
      if (a['name'].toLowerCase() < b['name'].toLowerCase()) return -1;

      if (a['name'].toLowerCase() > b['name'].toLowerCase()) return 1;

      return 0;
    });

    Object.entries(games_sort).map(([k, v]) => {
      games_list[v.name] = { ...v.value };
      games_list[v.name]['checked'] = false;
    });

    this.props.userData.games.forEach(item => {
      if (games_list[item].slug == item) {
        games_list[item]['checked'] = true;
      }
    });

    let newState = { ...this.state };
    newState.list_games = games_list;

    this.setState(newState);
  }

  handleCheckPermission(event) {
    const target = event.target;
    const value = target.value;

    let newState = { ...this.state };
    newState.list_games[value]['checked'] = !newState.list_games[value]['checked'];
    this.setState(newState);
  }

  renderPrivilegesList() {
    let privileges = this.state.list_games;
    let categories = this.props.categories;
    let items = [];
    let divStyleEmpty = {
      margin: 'initial',
      padding: 'initial'
    };
    Object.entries(categories).map(([k, v]) => {
      items.push(<div className="popupPrivCatLabel">{`  - ${k} - `}</div>);

      for (let key of v) {
        items.push(
          <div
            className="checkboxItem popupCheckbox"
            style={divStyleEmpty}
            key={privileges[key].name + Math.random()}
          >
            <input
              type="checkbox"
              name={privileges[key].name}
              id={privileges[key].name}
              value={privileges[key].id}
              defaultChecked={privileges[key].checked}
              onChange={this.handleCheckPermission}
            />
            <label htmlFor={privileges[key].name}>
              <ColorGroup color={privileges[key].color}> {privileges[key].name}</ColorGroup>
            </label>
            <br />
          </div>
        );
      }
    });

    return items;
  }

  renderGroupsList(groups) {
    let items = [];
    groups.forEach(group => {
      let divStyleEmpty = {
        margin: 'initial',
        padding: 'initial'
      };
      items.push(
        <div
          className="checkboxItem popupCheckbox"
          style={divStyleEmpty}
          key={group.groupName + Math.random()}
        >
          <input type="checkbox" name={group.groupName} id={group.groupName} value={group.id} />
          <label htmlFor={group.groupName}>
            <ColorGroup color={group.color}>{group.groupName}</ColorGroup>
          </label>
          <br />
        </div>
      );
    });
    return items;
  }

  handleClickColorPicker() {
    if (!this.state.show_palette) {
      this.setState({
        show_palette: true
      });
    } else if (this.state.show_palette) {
      this.setState({
        show_palette: false
      });
    }
  }

  handlePickColor(event) {
    const target = event.target;
    const value = target.getAttribute('data-value');
    this.setState({
      picked_color: value
      //show_palette: false
    });
  }

  render() {
    let title = this.props.type === 'edit' ? 'Edit Group' : 'New Group';
    let palette = '';
    if (this.state.show_palette) {
      if (this.props.userData) {
        palette = (
          <GroupsColorPalette>
            <ColorPicker handlePickColor={this.handlePickColor} active={this.state.picked_color} />
          </GroupsColorPalette>
        );
      } else {
        palette = (
          <GroupsColorPalette>
            <ColorPicker handlePickColor={this.handlePickColor} active={this.state.picked_color} />
          </GroupsColorPalette>
        );
      }
    }

    if (this.props.type === 'delete') {
      let firstName = this.props.userData.name
        .split(' ')
        .slice(0, -1)
        .join(' ');
      let lastName = this.props.userData.name
        .split(' ')
        .slice(-1)
        .join(' ');
      title = 'Delete Group';
      return (
        <Container>
          <Popup
            title={`Delete Group`}
            show={this.props.closeCallBack}
            visible={this.props.visible}
            popupheight={270}
            popupwidth={470}
          >
            <DeleteContainer>
              <InfoPopupText>
                <p>
                  You are about to delete the group
                  <b> {this.props.userData.name}</b>.
                </p>
                <p>Are you sure?</p>
              </InfoPopupText>
              <ButtonPanel>
                <div className="deleteBtn">
                  <CommonButton text="Yes" onClick={this.handleDelete} />
                </div>
                <div className="cancelBtn">
                  <CommonButton text="No" onClick={this.props.closeCallBack} cancel={true} />
                </div>
              </ButtonPanel>
            </DeleteContainer>
          </Popup>
        </Container>
      );
    }
    if (this.props.type === 'edit') {
      const colorStyle = {
        color: this.state.picked_color
      };
      let warn_info = null;
      if (this.state.warning_name_group) {
        warn_info = (
          <WarningElement>
            <img src={warning_icon} /> <span>Please fill Group Name field</span>
          </WarningElement>
        );
      }

      return (
        <Container key={'addGameGroupKey'}>
          <Popup visible={this.props.visible} title={'Edit Group'} show={this.props.closeCallBack}>
            <PopupForm>
              <div>{warn_info}</div>
              <form ref="form" onSubmit={this.handleSubmit}>
                <LabelContainer>
                  <Textfield
                    labelText={'Group Name'}
                    value={this.props.userData.name}
                    id={'gname'}
                    type={'text'}
                    autoFocus={true}
                    name={'groupname'}
                  />
                </LabelContainer>
                <LabelForm htmlFor="field">Games</LabelForm>
                <CheckboxContainer>
                  <CheckboxList
                    withProperties={true}
                    items={this.state.list_games}
                    id={'addGroupPopup'}
                    handleItems={this.handleCheckPermission}
                    heightProp={400}
                    checkboxWidth={312}
                  />
                </CheckboxContainer>

                <ButtonPanel>
                  <CommonButton width={'50rem'} text="SAVE" onClick={this.handleSubmit} />
                </ButtonPanel>
              </form>
            </PopupForm>
          </Popup>
          {palette}
        </Container>
      );
    }
    const colorStyle = {
      color: this.state.picked_color
    };
    let warn_info = null;
    if (this.state.warning_name_group) {
      warn_info = (
        <WarningElement>
          <img src={warning_icon} /> <span>Please fill Group Name field</span>
        </WarningElement>
      );
    }

    return (
      <Container key={'Dsdadassa'}>
        <Popup
          key="addNewGrupKey"
          visible={this.props.visible}
          show={this.props.closeCallBack}
          title={'New Group'}
        >
          <PopupForm>
            <div>{warn_info}</div>
            <form ref="form" onSubmit={this.handleSubmit}>
              <LabelContainer>
                <Textfield
                  labelText={'Group Name'}
                  id={'gname'}
                  type={'text'}
                  autoFocus={true}
                  name={'groupname'}
                />
              </LabelContainer>
              <LabelForm htmlFor="field">Permissions</LabelForm>
              <CheckboxContainer>
                <CheckboxList
                  withProperties={true}
                  items={this.state.list_games}
                  id={'addGroup'}
                  handleItems={this.handleCheckPermission}
                  heightProp={400}
                />
              </CheckboxContainer>

              <ButtonPanel>
                <CommonButton width={'50rem'} text="ADD GAME GROUP" onClick={this.handleSubmit} />
              </ButtonPanel>
            </form>
          </PopupForm>
        </Popup>
        {palette}
      </Container>
    );
  }

  handleDelete() {
    this.props.submitCallBack();
  }

  handleSubmit(ev) {
    ev.preventDefault();
    if (this.props.type === 'delete') {
      return;
    }

    let kvpairs = [];
    kvpairs['type'] = this.props.type;
    kvpairs['games'] = [];
    if (this.props.type === 'edit') kvpairs['id'] = this.props.userData.group_id;
    let form = this.refs.form;
    for (let i = 0; i < form.elements.length; i++) {
      let e = form.elements[i];

      if (e.name === undefined || e.value === undefined || e.name === '') continue;
      if (e.name === 'groupname') {
        if (e.value === '') {
          this.setState({ warning_name_group: true });
          return;
          //add warning
        }
      }
      if (form.elements[i].type === 'checkbox') {
        if (form.elements[i].checked) kvpairs['games'].push(e.value);
      } else kvpairs[e.name] = e.value;
    }
    if (document.getElementById('gcolor')) {
      let color_element = document.getElementById('gcolor');
      let color = color_element.getAttribute('data-value');
      kvpairs['color'] = color;
    }

    this.props.submitCallBack(kvpairs);
  }
}

export default GroupsPopup;

/** Game Groups Popup Styles */
//region Styles
const InfoPopupText = styled.div`
  color: #e1e1e5;
  text-align: center;
  margin: 25px;
  letter-spacing: 0.05rem;
  line-height: 1.5;
  font-size: 14px;
`;

const WarningElement = styled.div`
  visibility: visible;
  position: absolute;
  color: #f29c10;
  font-size: 12px;
  letter-spacing: 0.1rem;
  padding-bottom: 8px;
  text-align: center;
  left: 200px;
`;

const PopupForm = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #99999c;
  width: 50rem;
  margin: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

const GroupsColorPalette = styled.div`
  background: #1e2022;
  position: absolute;
  top: calc(60% - 22rem);
  left: calc(50% + 30rem);

  border: 1px solid #1e2022;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  border-radius: 3px;
  width: 30rem;
  padding: 10px;
  padding-right: 0px;
  margin-top: 10px;
  z-index: 102;
`;

const ColorGroup = styled.div`
  color: ${props => props.color};
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
`;

const ButtonPanel = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;

  height: 3.5rem;
`;

const LabelForm = styled.label`
  display: inline-block;
  width: auto;
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const DeleteContainer = styled.div`
  margin-top: 4rem;
`;

const LabelContainer = styled.div`
  margin-bottom: 20px;
`;

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 101;
  margin: 0;
  padding: 0;
`;
//endregion
