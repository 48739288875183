import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import x_red from './assets/x_red.png';
import check_green from './assets/check_green.png';
const LabelForm = styled.label`
  font-size: 12px;
  color: #99999c;
  display: block;
  margin-bottom: 5px;
`;

const TextForm = styled.input`
  border-radius: 3px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  height: 30px;
  box-sizing: border-box;
  padding-left: 10px;
  border: none;
  outline: none;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: ${props => (props.colorText ? props.colorText : 'rgb(225, 225, 229)')};
  letter-spacing: 0.05em;
  width: 100%;
  padding-right: 10px;
  &:focus {
    outline: none !important;
    box-shadow: 0 0 5px rgb(21, 130, 110);
    border: 1px solid rgb(21, 130, 110);
  }
  &::placeholder {
    color: rgb(52, 57, 63);
  }
  ${props =>
    props.textfieldHeight && {
      height: props.textfieldHeight + 'px'
    }} ${({ editPanel }) =>
    editPanel &&
    `


  `};
`;

const Textfield = ({
  labelText,
  id,
  type,
  placeholder,
  onChange,
  displayLabel,
  value,
  autoFocus,
  colorText,
  name,
  maxLength,
  displayEditPanel,
  handleAccept,
  handleDiscard,
  textfieldHeight,
  textfieldWidth,
  min,
  max,
  displayTicks
}) => {
  let label;
  if (displayLabel == false) {
    label = null;
  } else {
    label = <LabelForm htmlFor={id}>{labelText}</LabelForm>;
  }

  return (
    <TextfieldContainer>
      {label}

      {displayEditPanel == true ? (
        <Fragment>
          <EditPanelContainer textfieldHeight={textfieldHeight} textfieldWidth={textfieldWidth}>
            <TextForm
              maxLength={maxLength ? maxLength : '60'}
              autoFocus={autoFocus}
              type={type}
              id={id}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              colorText={colorText}
              name={name}
              editPanel={displayEditPanel}
              textfieldHeight={textfieldHeight}
              min={min ? min : -100000}
              max={max ? max : 100000}
            />
            {displayTicks == true ? (
              <Fragment>
                <InfoDecline
                  src={x_red}
                  onClick={() => {
                    handleDiscard(name);
                  }}
                />
                <InfoAccept
                  src={check_green}
                  onClick={() => {
                    handleAccept(name);
                  }}
                />
              </Fragment>
            ) : null}
          </EditPanelContainer>
        </Fragment>
      ) : (
        <TextForm
          maxLength={maxLength ? maxLength : '60'}
          autoFocus={autoFocus}
          type={type}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={value}
          colorText={colorText}
          name={name}
          min={min ? min : -1000000}
          max={max ? max : 1000000}
        />
      )}
    </TextfieldContainer>
  );
};

export default Textfield;

const EditPanelContainer = styled.div`
  position: relative;
  width: ${props => (props.textfieldWidth ? props.textfieldWidth + 'px' : 'auto')};
  padding-right: 15px;
  height: ${props => (props.textfieldHeight ? props.textfieldHeight + 'px' : '30px')};
`;

const InfoAccept = styled.img`
  position: absolute;
  bottom: 2px;
  right: 1px;
  cursor: pointer;
`;

const InfoDecline = styled.img`
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
`;
const TextfieldContainer = styled.div`
  display: block;
  position: relative;
`;
