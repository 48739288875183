import React, { Component, PureComponent, Fragment } from 'react';
import { defaults, Bar, Line } from 'react-chartjs-2';
import formatNumberWithDots from 'utils/formatNumberWithDots';
import {
  backgroundColorTable,
  borderColorTable,
  colorChartUAPalette,
  colorChartUABackground,
  colorChartUABackgroundHover
} from 'components/ColorPicker/index';

class RevenueChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false
    };
    this.chartRef = React.createRef();
    this.mounted = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("shouldComponentUpdate",this.state,nextState)
    //if(this.state.tooltip !== nextState.tooltip)return true;
    if (nextProps.data !== this.props.data) return true;
    return false;
  }

  componentDidMount() {
    // console.log("componentDidMount",this.state)
    this.setState({ mounted: true });
    this.mounted = true;
    //console.log('this.chartRef)',this.chartRef);
    this.props.updateChartRef(this.chartRef);
    //this.forceUpdate();
  }

  parseData(data) {
    let labels = [];
    let ads = [];
    let iap = [];
    let total = [];

    data.map(item => {
      labels.push(item.date);
      ads.push(item.ad_revenue);
      iap.push(item.iap_revenue);
      total.push(item.total);
    });

    return {
      labels: labels,
      datasets: [
        {
          label: 'Ads',
          hidden: false,
          fill: false,
          backgroundColor: backgroundColorTable[0],
          borderWidth: 2,
          borderColor: borderColorTable[0],
          pointHitDetectionRadius: 1,
          data: ads
        },
        {
          label: 'IAP',
          hidden: false,
          fill: false,
          backgroundColor: backgroundColorTable[1],
          borderWidth: 2,
          borderColor: borderColorTable[1],
          pointHitDetectionRadius: 1,
          data: iap
        },
        {
          label: 'Total',
          hidden: false,
          fill: false,
          backgroundColor: backgroundColorTable[2],
          borderWidth: 2,
          borderColor: borderColorTable[2],
          pointHitDetectionRadius: 1,
          data: total
        }
      ]
    };
  }

  render() {
    let data = this.parseData(this.props.data);
    let dt;
    dt = Object.assign({}, data);
    let options = {
      title: {
        display: false,
        text: ''
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: false,
          fontColor: '#989898'
        }
      },
      tooltips: {
        enabled: false,
        labelColors: backgroundColorTable,
        xPadding: 10,
        yPadding: 10,
        yAlign: 'top',
        position: 'nearest',
        intersect: false,
        mode: 'label',
        custom: tooltipModel => {
          this.props.showTooltip(tooltipModel);
        }
      },
      elements: {
        line: {
          borderWidth: 2
        },
        point: { radius: 0, hitRadius: 0, hoverRadius: 0 }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            stacked: false,
            gridLines: {
              drawBorder: true,
              color: '#2f3238'
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              min: 0,
              beginAtZero: true,
              callback: function(label, index, labels) {
                let new_label = formatNumberWithDots(label);
                return '$' + new_label;
              }
            },
            scaleLabel: {
              display: true
            },

            stacked: false,

            gridLines: {
              drawBorder: true,

              color: '#2f3238'
            }
          }
        ]
      }
    };

    return <Line ref={this.chartRef} data={dt} options={options} />;
  }
}

export default RevenueChart;
