import React from 'react';

import logo from './assets/logo.png';
import logoSmall from './assets/logo-small.png';

const smallStyle = {
  width: '5rem',
  height: '5rem',
  margin: '0.5rem 0.5rem 0.5rem 0.5rem',
  cursor: 'pointer'
};

const PlayStackLogo = ({ small, callBack }) => (
  <img
    src={small ? logoSmall : logo}
    onClick={callBack}
    style={small && smallStyle}
    alt="PlayStack Logo"
  />
);

export default PlayStackLogo;
