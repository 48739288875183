import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import TitleBar from 'components/TitleBar';
import PlayStackLogo from 'components/PlayStackLogo';
import styled from 'styled-components';
import { Content } from 'views/App';
import WelcomeMain from './WelcomeMain';
/** Welcome Routes*/
//region Routes
const routes = [
  {
    path: '/',
    exact: true,
    view: WelcomeMain,
    title: 'Welcome to PlayStack Dashboard'
  }
];
//endregion

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: null
    };
    this.showOld = false;
  }

  render() {
    if (this.showOld) {
      return (
        <Fragment>
          <TitleBar
            name={this.props.user.first_name}
            title={this.props.title}
            color={this.props.color}
            logout={this.props.logoutCall}
            history={this.props.history}
          />
          <Content>
            <CenteredLogo>
              <PlayStackLogo />
            </CenteredLogo>
          </Content>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <TitleBar
          name={this.props.user.first_name}
          title={this.props.title}
          color={this.props.color}
          logout={this.props.logoutCall}
          history={this.props.history}
        />
        <Content>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                id={index}
                path={route.path}
                exact={route.exact}
                render={props => (
                  <route.view
                    passwordTemp={this.props.passwordTemp}
                    showGameDetails={this.showGameDetails}
                    updatePrivCall={this.props.updatePrivCall}
                    privilegesUpdated={this.props.privilegesUpdated}
                    {...props}
                  />
                )}
                //component={route.view}
              />
            ))}
          </Switch>
        </Content>
      </Fragment>
    );
  }
}

export default Welcome;
/** Welcome Styles */
//region Styles
const CenteredLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 6rem);
`;
//endregion
