import React, { Component, PureComponent, Fragment } from 'react';
import styled from 'styled-components';

class GroupsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemRows: null
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(nextProps,prevState,'<<<<<')
    if (nextProps.itemRows == prevState.itemRows) return null;
    else return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.itemRows !== nextProps.itemRows) {
      //console.log(this.props.itemRows,' <> ',nextProps.itemRows,' <> ',this.props.itemRows == nextProps.itemRows);
      //return true;
    }
    /*if (this.state.count !== nextState.count) {
      return true;
    }*/
    return false;
  }

  render() {
    return (
      <GroupsContent key={'GroupsContent'}>
        <GroupsHeader>{this.props.title}</GroupsHeader>
        {this.props.itemRows}
      </GroupsContent>
    );
  }
}
export default GroupsList;

const GroupsContent = styled.div`
  height: auto;
  padding: 0 0 2rem;
  height: auto;
  top: 15rem;
  left: calc(60% + 8rem);
  color: #99999c;
  max-width: 40rem;
  min-width: 30rem;
  background: rgba(36, 39, 43, 1);
  text-align: center;
  border-style: solid;
  border-color: rgba(54, 57, 63, 1);
  border-width: 2px;
  margin-bottom: 110px;
  ul {
    font-size: 1.5rem;
    padding-top: 0;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 0;
    text-align: left;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  li {
    margin-right: 1rem;
    margin-left: 2.5rem;
    padding-bottom: 1px;
  }
  span {
    left: -2rem;
  }
`;

const GroupsHeader = styled.div`
  color: #99999c;
  padding: 0rem 0rem 0rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-right: 4rem;
  margin-left: 4rem;
  margin-bottom: 0rem;
`;

//endregion
