import React, { Component, Fragment } from 'react';
import Textfield from 'components/Textfield';
import styled from 'styled-components';
import CommonButton, { Button } from 'components/CommonButton/index';
import Popup from 'components/Popup';

export const PopupConfigurationProfile = ({ closeBtn, yesBtn }) => {
  return (
    <Popup
      mainColor={'#f37927'}
      show={closeBtn}
      title={'Warning!'}
      visible={true}
      popupheight={250}
    >
      <PopupTextWarning>
        Changing the configuration profile will reset the Data Sources for this game. Are you sure
        you wish to proceed?
      </PopupTextWarning>

      <ButtonPanel>
        <NoBtn>
          <CommonButton text="No" cancel={true} onClick={closeBtn} />
        </NoBtn>
        <YesBtn>
          <CommonButton text="Yes" onClick={yesBtn} />
        </YesBtn>
      </ButtonPanel>
    </Popup>
  );
};

const PopupDelete = ({ closeBtn, yesBtn, onChange, slug, itemType, needUpdate }) => {
  let gameName = (
    <GameSlug>
      "<SlugColor>{slug}</SlugColor>"
    </GameSlug>
  );
  return (
    <Popup
      popupheight={310}
      mainColor={'#04d5ac'}
      show={closeBtn}
      title={`Delete ${itemType}`}
      visible={true}
    >
      <PopupText>
        To confirm you want to delete this {itemType} please enter {gameName} in the text box below
        and press `confirm`
      </PopupText>
      <TextFieldContainer>
        <Textfield
          autoFocus={true}
          displayLabel={false}
          placeholder={`Enter the ${itemType} slug`}
          id={'deleteGameSlug'}
          onChange={onChange}
        />
      </TextFieldContainer>
      <ButtonPanel>
        <NoBtn>
          <CommonButton text="Cancel" cancel={true} onClick={closeBtn} />
        </NoBtn>
        <YesBtn>
          <Button needUpdate={needUpdate} onClick={yesBtn}>
            Confirm
          </Button>
        </YesBtn>
      </ButtonPanel>
    </Popup>
  );
};

export default PopupDelete;

//region Styles
const PopupText = styled.div`
  color: #e1e1e5;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
  letter-spacing: 0.05rem;
  padding: 0px 40px;
  line-height: 2.5;
  font-size: 14px;
`;

const PopupTextWarning = styled(PopupText)`
  margin-bottom: 15px;
  margin-top: 20px;
`;

const ButtonPanel = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 15rem 15rem 1fr;
  grid-column-gap: 10px;
  margin: 0 auto;
`;

const NoBtn = styled.div`
  grid-row: 1;
  grid-column: 2/3;
`;

const YesBtn = styled.div`
  grid-row: 1;
  grid-column: 3/4;
`;

const TextFieldContainer = styled.div`
  width: 25rem;
  margin-left: 170px;
  margin-top: -30px;
  margin-bottom: 20px;
`;
const GameSlug = styled.div`
  line-height: 1;
`;
const SlugColor = styled.span`
  color: #04d5ac;
`;
//endregion
