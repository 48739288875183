import React, { Component } from 'react';
import styled, { injectGlobal, keyframes } from 'styled-components';

const fadein = keyframes`
 0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 128px;
  min-height: 148px;
  height: 100%;
  // border: 1px solid #36393f;
  //background-color: #24272b;
  animation: ${fadein} 0.3s ease-in-out;
`;

class DataContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }

  componentDidMount() {}
  componentWillMount() {}
  componentWillUnmount() {}

  render() {
    return (
      <Panel>
        {
          <this.props.panel
            data={this.props.data}
            handleClick={this.props.handleClick}
            listData={this.props.listData}
          />
        }
      </Panel>
    );
  }
}

export default DataContainer;
