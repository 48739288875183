import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose';
import Gauge from 'components/Gauge';
import AnimatedNumber from 'components/AnimatedNumber';

import { tween } from 'popmotion';
class IgnitionStatus extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // console.log('IgnnitionStatus RENDER RENDER', this.props.data);
    const red = '#d32212';
    const orange = '#F59432';
    const yellow = '#f5b22a';
    const greenl = '#87B53F';
    const green = '#39b549';
    const grey = '#5F5F5F';
    const gradient_1 = 'linear-gradient(to right, #f95846 0%, #7f3f2f 100%)';
    const gradient_2 = 'linear-gradient(to right, #ffa119 0%, #7e4316 100%)';
    const gradient_3 = 'linear-gradient(to right, #5bb960 0%, #214321 100%)';

    let data_collection_start = this.props.data ? this.props.data.data_collection_start : '';
    let data_collection_end = this.props.data ? this.props.data.data_collection_end : '';
    let to = this.props.data ? 'to' : '-';
    let roi = this.props.data ? this.props.data.roi : 0;
    let ltv = this.props.data ? this.props.data.ltv.toFixed(2) : 0;
    let cpi = this.props.data ? this.props.data.cpi.toFixed(2) : 0;
    let target = this.props.data ? this.props.data.expected.roi : 0;

    let roi30 = this.props.data ? this.props.data.additional_predictions.roi_30 : 0;
    let ltv30 = this.props.data ? this.props.data.additional_predictions.ltv_30.toFixed(2) : 0;

    let roi60 = this.props.data ? this.props.data.additional_predictions.roi_60 : 0;
    let ltv60 = this.props.data ? this.props.data.additional_predictions.ltv_60.toFixed(2) : 0;

    let roi90 = this.props.data ? this.props.data.additional_predictions.roi_90 : 0;
    let ltv90 = this.props.data ? this.props.data.additional_predictions.ltv_90.toFixed(2) : 0;

    let ltv_period = this.props.data ? this.props.data.ltv_period : 0;
    let ignition_range = this.props.data ? this.props.data.ignition_range : 0;
    let color = '#fefefe';
    let statusColor = '#f5b22a';
    let titleGradient = 'linear-gradient(to right, #ffa119 0%, #7e4316 100%)';

    let roiAngle = (target ? (roi ? roi.toFixed(2) : 0) / target : 0) * 120;

    if (roi > 0 && roi < 30) {
      statusColor = color = '#c2241e';
      titleGradient = 'linear-gradient(to right, #f95846 0%, #7f3f2f 100%)';
    } else if (roi >= 30 && roi < 100) {
      statusColor = color = '#f5b22a';
      titleGradient = 'linear-gradient(to right, #ffa119 0%, #7e4316 100%)';
    } else if (roi >= 100 && roi < 120) {
      statusColor = color = '#39b549'; //'#87B53F';
      titleGradient = 'linear-gradient(to right, #5bb960 0%, #214321 100%)';
    } else if (roi > 120) {
      statusColor = color = '#39b549';
      titleGradient = 'linear-gradient(to right, #5bb960 0%, #214321 100%)';
    }
    statusColor = roiAngle >= 100 ? (roiAngle >= 120 ? green : yellow) : red;
    titleGradient = roiAngle >= 100 ? (roiAngle >= 120 ? gradient_3 : gradient_2) : gradient_1;
    if (roiAngle === 0) {
      statusColor = grey;
      titleGradient = grey;
    }
    let handler = null;
    if (this.props.changeView) {
      handler = () => this.props.changeView(this.props.slug);
    }
    return (
      <PopupFrame
        windowWidth={this.props.windowWidth}
        color={statusColor}
        popupheight={this.props.popupheight ? this.props.popupheight : 700}
        popupwidth={this.props.popupwidth ? this.props.popupwidth : 600}
        onClick={handler}
      >
        <PopupTitle color={statusColor} titleGradient={titleGradient}>
          <Title>Ignition Status</Title>
        </PopupTitle>

        <Visualisation>
          <Gauge
            targetLine={180 + 120}
            roiAngle={180 + roiAngle}
            currentState={this.props.currentState}
            setCurrentState={this.props.setCurrentState}
          />
        </Visualisation>
        <Results>
          <ResultRow big>
            <Result big>
              <LabelText big>
                ROI ({ltv_period}
                d):
              </LabelText>
              <ResultText big color={statusColor}>
                <AnimatedNumber value={roi} after={'%'} initialValue={'-'} toFixed={null} />
              </ResultText>
            </Result>
          </ResultRow>
          <ResultRow>
            <ResultGroup>
              <Result width={'100%'}>
                <LabelText size={'20px'}>LTV(30d):</LabelText>
                <ResultText>
                  <AnimatedNumber before={'$'} value={ltv30} initialValue={'-'} toFixed={2} />
                </ResultText>
              </Result>
              <Result width={'100%'}>
                <LabelText size={'20px'}>LTV(60d):</LabelText>
                <ResultText>
                  <AnimatedNumber before={'$'} value={ltv60} initialValue={'-'} toFixed={2} />
                </ResultText>
              </Result>
              <Result width={'100%'}>
                <LabelText size={'20px'}>LTV(90d):</LabelText>
                <ResultText>
                  <AnimatedNumber before={'$'} value={ltv90} initialValue={'-'} toFixed={2} />
                </ResultText>
              </Result>
            </ResultGroup>
            <ResultGroup>
              <Result width={'100%'}>
                <LabelText size={'20px'}>ROI(30d):</LabelText>
                <ResultText>
                  <AnimatedNumber after={'%'} value={roi30} initialValue={'-'} toFixed={null} />
                </ResultText>
              </Result>
              <Result width={'100%'}>
                <LabelText size={'20px'}>ROI(60d):</LabelText>
                <ResultText>
                  <AnimatedNumber after={'%'} value={roi60} initialValue={'-'} toFixed={null} />
                </ResultText>
              </Result>
              <Result width={'100%'}>
                <LabelText size={'20px'}>ROI(90d):</LabelText>
                <ResultText>
                  <AnimatedNumber after={'%'} value={roi90} initialValue={'-'} toFixed={null} />
                </ResultText>
              </Result>
            </ResultGroup>
          </ResultRow>
          <ResultRow cpi>
            <Result cpi>
              <LabelText cpi>CPI:</LabelText>
              <ResultText cpi>
                <AnimatedNumber before={'$'} value={cpi} initialValue={'-'} toFixed={2} />
              </ResultText>
            </Result>
          </ResultRow>
        </Results>
        <Period>
          Locked for {data_collection_start} {to} {data_collection_end}{' '}
        </Period>
      </PopupFrame>
    );
  }
}

export default IgnitionStatus;

//region Styles

const PopupFrame = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 0.5rem;
  background-color: #282b30;
  border: 1px solid ${props => props.color};
  color: #ffffff;
  text-align: center;
  //background-color: #dcd4dc;
  box-sizing: border-box;
  display: grid;
`;

const PopupTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-radius: 15px 15px 0 0;
  line-height: 4.6rem;
  height: 4.6rem;
  margin: 0px;
  text-align: left;
  letter-spacing: 0.075rem;
  box-sizing: border-box;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background: ${props => props.titleGradient};
`;

const Title = styled.div`
  font-size: 1.8rem;
  color: #fefefe;
  line-height: 4.6rem;

  margin-left: 20px;
  text-align: center;
  box-sizing: border-box;

  font-weight: 400;
`;

const Visualisation = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  height: auto;
`;

const Results = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 0px;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: flex-start;
  //background-color: #9aff19;
`;
const ResultGroup = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  width: 48%;
  background-color: #24272b;
  border-radius: 10px;
`;

const ResultRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  //padding-left: 20px;
  //padding-right: 20px;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${props => (props.big ? '6px' : '6px')};
  margin-top: 5px;
  font-size: ${props => (props.big ? '26px' : '14px')};
  line-height: ${props => (props.big || props.cpi ? '50px' : '50px')};
`;

const Result = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${props => (props.cpi ? '80px' : '15px')};
  padding-right: ${props => (props.cpi ? '80px' : '15px')};
  justify-content: space-between;
  width: ${props => (props.width ? props.width : '100%')};
  line-height: ${props => (props.big || props.cpi ? '50px' : '30px')};
  background-color: #24272b;
  border-radius: 10px;
`;

const LabelText = styled.div`
  color: #fefefe;

  font-weight: ${props => (props.big ? 400 : 400)};
`;

const ResultText = styled.div`
  color: ${props => (props.color ? props.color : '#fefefe')};
  justify-self: flex-end;
  font-weight: ${props => (props.big ? 400 : 400)};
`;
const Period = styled.div`
  color: rgba(255, 255, 255, 0.6);
  position: relative;
  text-align: center;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 5rem;
`;

//endregion
