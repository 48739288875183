import React, { Component } from 'react';
import posed from 'react-pose';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import moment from 'moment';
import { defaults } from 'react-chartjs-2';
import routes from 'routes';
import storage from 'utils/storage';
import styled from 'styled-components';
import LoadingIndicator from 'components/LoadingIndicator';
import ChangePasswordForm from 'views/App/ChangePasswordForm';
import Login from 'views/App/Login';

export const AppContext = React.createContext();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginHandled: false,
      waiting: false,
      waiting2: false,
      waiting3: false,
      privilegesUpdated: false,
      invalid_user: false,
      logout_info: false,
      pass_temporary: false,
      pose: 'before',
      user: {
        authed: false,
        name: 'anonymous',
        token: '',
        role: 'anonymous',
        priviliges: []
      },
      logout: () => {},
      returnToLogin: () => {},
      getUserPriviliges: () => {},
      getGamesIcons: () => {},
      getPlatformsIcons: () => {}
    };
    this.mounted = false;
    this.returnToLogin = this.returnToLogin.bind(this);
    this.logout = this.logout.bind(this);
    this.updateUserData = this.updateUserData.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.getUserPriviliges = this.getUserPriviliges.bind(this);
    this.getGamesIcons = this.getGamesIcons.bind(this);
    this.getPlatformsIcons = this.getPlatformsIcons.bind(this);
    this.getToken = this.getToken.bind(this);
    this.setToken = this.setToken.bind(this);
  }
  /** App Lifecycle Methods */
  //region App Lifecycle Methods
  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    const tokenObject = this.getToken();
    this.setState({
      logout: this.returnToLogin,
      returnToLogin: this.returnToLogin,
      getUserPriviliges: this.getUserPriviliges,
      getGamesIcons: this.getGamesIcons,
      getPlatformsIcons: this.getPlatformsIcons,
      pose: 'after'
    });

    if (tokenObject != null) {
      if (tokenObject.remember) {
        let timeDiff = this.compareDates(tokenObject.timestamp, new Date().getTime());

        if (
          tokenObject.token &&
          tokenObject.user_data &&
          tokenObject.user_data.first_name &&
          tokenObject.user_data.last_name &&
          timeDiff < 2800
        ) {
          this.setState({ waiting: true, loginHandled: true });
          storage.USER.token = tokenObject.token;

          storage.USER.first_name = tokenObject.user_data.first_name;
          storage.USER.last_name = tokenObject.user_data.last_name;

          this.getGamesIcons();

          this.getPlatformsIcons();
          this.getUserPriviliges(false);
        } else {
          this.setToken(null);
          this.setState({ loginHandled: true });
        }
      } else {
        let timeDiff = this.compareDates(tokenObject.timestamp, new Date().getTime());

        if (tokenObject.token && timeDiff < 45) {
          this.setState({ waiting: true, loginHandled: true });
          storage.USER.token = tokenObject.token;
          storage.USER.first_name = tokenObject.user_data.first_name;
          storage.USER.last_name = tokenObject.user_data.last_name;
          this.getGamesIcons();
          this.getPlatformsIcons();
          this.getUserPriviliges(false);
        } else {
          this.setToken(null);
          this.setState({ loginHandled: true });
        }
      }
    } else {
      this.setState({ loginHandled: true });
    }
  }
  //endregion
  compareDates(startDate, endDate) {
    let start = moment(startDate);
    let end = moment(endDate);
    return end.diff(start, 'minutes');
  }
  /** Authentication Handlers - login, logout, tokens */
  //region Authentication Handlers
  handleLogin(email, password, remember, form) {
    this.setState({ waiting: true, logout_info: false });

    this.login(email, password, remember).then(
      data => {
        this.getGamesIcons();
        this.getPlatformsIcons();
        this.getUserPriviliges(false, data);
        this.setState({ pass_temporary: data.user_data.password_temporary });
      },
      error => {
        this.setToken(null);
        storage.USER.authed = false;
        let user = storage.USER;
        this.setState({ user: user, privilegesUpdated: false, waiting: false, invalid_user: true });
      }
    );
  }

  getToken() {
    let object = null;
    let token = window.localStorage.getItem('psLoginToken');
    if (token) object = JSON.parse(token);
    return object;
  }

  setToken(object) {
    if (object != null) window.localStorage.setItem('psLoginToken', JSON.stringify(object));
    else window.localStorage.removeItem('psLoginToken');
  }

  returnToLogin() {
    this.setToken(null);
    window.sessionStorage.clear();
    window.localStorage.clear();

    storage.USER = {
      token: null,
      groups: null,
      privileges: null,
      authed: false,
      name: null
    };
    this.setState({
      waiting: false,
      privilegesUpdated: false,
      logout_info: true,
      user: {
        authed: false,
        name: 'anonymous',
        token: '',
        role: 'anonymous',
        priviliges: []
      },
      invalid_user: false
    });
  }

  logout() {
    const PATH_BASE = storage.AUTH_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';
    const PATH_CALL = '/logout';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setToken(null);
        window.sessionStorage.clear();
        window.localStorage.clear();

        if (data && data.status == 'SUCCESS') {
          storage.USER = {
            token: null,
            groups: null,
            privileges: null,
            authed: false,
            name: null
          };
          this.setState({
            waiting: false,
            privilegesUpdated: false,
            logout_info: true,
            user: {
              authed: false,
              name: 'anonymous',
              token: '',
              role: 'anonymous',
              priviliges: []
            },
            invalid_user: false
          });

          return data;
        } else return;
      })
      .catch(error => {
        console.log('<< App >> : < login > : error : ', error);
      });
  }

  login(email, password, remember) {
    window.sessionStorage.clear();
    window.localStorage.clear();

    const PATH_BASE = storage.AUTH_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';

    const PATH_CALL = '/login';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*'
    };

    let formData = new FormData();
    let setToken = this.setToken;
    let setState = this.setState;

    formData.append('email', email);
    formData.append('password', password);
    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: formData
    })
      .then(this.handleErrors)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data && data.status == 'SUCCESS') {
          storage.USER.token = data.auth_token;
          storage.USER.first_name = data.user_data.first_name;
          storage.USER.last_name = data.user_data.last_name;
          const object = {
            token: data.auth_token,
            user_data: data.user_data,
            timestamp: new Date().getTime(),
            remember: remember
          };
          setToken(object);
          return data;
        } else {
          return Promise.reject('login failed');
        }
      });
  }
  //endregion

  handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }
  /** Icons Handlers */
  //region Icons Handlers
  getGamesIcons(silentMode = false) {
    if (!this.mounted) return;
    if (!silentMode) this.setState({ waiting2: true });
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/games/list/?data=icon|alerts';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.payload = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.payload = json.result;
        else data.payload = json;
        this.parseGamesIcons(data, silentMode);
        return data;
      })
      .catch(error => {
        this.setToken(null);
        storage.USER.authed = false;
        let user = storage.USER;
        this.setState({ user: user, privilegesUpdated: false, waiting2: false });
      });
  }

  getPlatformsIcons(silentMode = false) {
    if (!this.mounted) return;
    if (!silentMode) this.setState({ waiting3: true });
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/platforms/list?data=icon';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.payload = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.payload = json.result;
        else data.payload = json;
        this.parsePlatformsIcons(data, silentMode);
        return data;
      })
      .catch(error => {
        this.setToken(null);
        storage.USER.authed = false;
        let user = storage.USER;
        this.setState({ user: user, privilegesUpdated: false, waiting3: false });
      });
  }

  parseGamesIcons(data, silentMode) {
    let games = [];
    let icons = [];
    let names = [];
    data.payload.map(item => {
      icons[item.slug] = item.icon;
      names[item.slug] = item.name;
      games[item.slug] = item;
    });
    storage.GAMES_DATA = games;
    storage.GAMES_ICONS = icons;
    storage.GAMES_NAMES = names;
    if (!silentMode) this.setState({ waiting2: false });
  }

  parsePlatformsIcons(data, silentMode) {
    let platforms = [];
    data.payload.map(item => {
      platforms[item.slug] = item.icon;
    });
    storage.PLATFORMS_ICONS = platforms;
    if (!silentMode) this.setState({ waiting3: false });
  }
  //endregion

  /** User Priviliges and Data Management*/
  //region User Priviliges and Data Management
  getUserPriviliges(update = false, payload = undefined) {
    const PATH_BASE = storage.AUTH_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';
    const PATH_CALL = '/get_credentials';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    const getToken = this.getToken;
    const setToken = this.setToken;

    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(this.handleErrors)
      .then(response => {
        return response.json();
      })
      .then(function(data) {
        if (data.new_token) {
          let tokenObject = getToken();
          tokenObject.token = data.new_token;
          storage.USER.token = data.new_token;

          setToken(tokenObject);
        }
        return data;
      })
      .then(data => {
        if (update) this.updateUserData(data);
        else this.getUserData(data);
      })
      .catch(error => {
        this.setToken(null);
        storage.USER.authed = false;
        let user = storage.USER;
        this.setState({ user: user, privilegesUpdated: false, waiting: false });
      });
  }

  getUserData(data) {
    if (data && data.status == 'SUCCESS') {
      storage.USER.privileges = data.result;
      storage.USER.authed = true;
      let user = storage.USER;
      this.setState({ user: user, waiting: false, privilegesUpdated: false, invalid_user: false });
    } else {
      this.setToken(null);
      storage.USER.authed = false;
      let user = storage.USER;
      this.setState({ user: user, privilegesUpdated: false, waiting: false });
    }
  }

  updateUserData(data) {
    if (data && data.status == 'SUCCESS') {
      storage.USER.privileges = data.result;
      let user = storage.USER;
      this.setState({ user: user, privilegesUpdated: true });
    } else {
      storage.USER.authed = false;
      let user = storage.USER;
      this.setState({ user: user, privilegesUpdated: false });
    }
  }
  //endregion
  render() {
    const tokenObject = this.getToken();

    defaults.global.defaultFontFamily = 'Roboto';
    //defaults.global.defaultFontColor = '#dbd30c';
    if (this.state.waiting || this.state.waiting2 || !this.state.loginHandled) {
      return (
        <MainAppContainer>
          <AppView />
          <LoadingIndicator />
        </MainAppContainer>
      );
    }

    if (!this.state.user.authed && tokenObject === null) {
      return (
        <Router>
          <MainAppLoginContainer>
            <LoginViewContainer>
              <Switch>
                <Route path={`/password_reset`} exact component={ChangePasswordForm} />
                <Route
                  path="/login"
                  exact
                  render={() => (
                    <Login
                      waiting={this.state.waiting}
                      handleLogin={this.handleLogin}
                      invalid_user={this.state.invalid_user}
                      logout_info={this.state.logout_info}
                    />
                  )}
                  //component={Login}
                />
                <Route
                  path="/"
                  render={() => <Redirect to="/login" />}
                  //component={NotImpelmented}
                />
              </Switch>
            </LoginViewContainer>
          </MainAppLoginContainer>
        </Router>
      );
    } else if (!this.state.user.authed && tokenObject !== null) {
      return (
        <Router>
          <MainAppContainer>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={props => <route.menubar config={route.menuConfig} {...props} />}
                  //component={route.menubar}
                />
              ))}

              <Route render={() => <Redirect to="/" />} />
            </Switch>

            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index + 'Key'}
                  id={index + 'Id'}
                  path={route.path}
                  exact={route.exact}
                  render={props => (
                    <route.view
                      user={this.state.user}
                      filter={route.filter}
                      title={route.title}
                      color={route.gradient}
                      privilegesUpdated={this.state.privilegesUpdated}
                      updatePrivCall={this.getUserPriviliges}
                      logoutCall={this.logout}
                      {...props}
                    />
                  )}
                  //component={route.view}
                />
              ))}

              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </MainAppContainer>
        </Router>
      );
    } else if (this.state.user.authed) {
      return (
        <AppContext.Provider value={this.state}>
          <Router>
            <MainAppContainer>
              <Switch>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={props => (
                      <route.menubar path={route.path} config={route.menuConfig} {...props} />
                    )}
                    //component={route.menubar}
                  />
                ))}
                <Route render={() => <Redirect to="/" />} />
              </Switch>

              <Switch>
                {routes.map((route, index) => (
                  <Route
                    key={index + 'dd'}
                    id={index + 'dd'}
                    path={route.path}
                    exact={route.exact}
                    render={props => (
                      <route.view
                        user={this.state.user}
                        passwordTemp={this.state.pass_temporary}
                        filter={route.filter}
                        title={route.title}
                        color={route.gradient}
                        privilegesUpdated={this.state.privilegesUpdated}
                        updatePrivCall={this.getUserPriviliges}
                        logoutCall={this.logout}
                        {...props}
                      />
                    )}
                    //component={route.view}
                  />
                ))}

                <Route render={() => <Redirect to="/" />} />
              </Switch>
            </MainAppContainer>
          </Router>
        </AppContext.Provider>
      );
    }
  }
}

export default App;

/** App Styles */
//region Styles

const MainAppContainer = styled.div`
  display: grid;
  grid-template-columns: 6rem 1fr;
  grid-template-rows: 6rem 1fr;
  background-color: #2f3238;
`;

const MainAppLoginContainer = styled.div`
  position: relative;
  display: flex;
  background-color: #2f3238;
  width: 100%;
  min-height: 100%;
`;

const LoginViewContainer = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: #2f3238;
`;

export const MenuBarContainer = styled.div`
  flex-direction: column;
  width: 6rem;
  display: flex;
  grid-row: 1/3;
  grid-column: 1/2;
  color: #ffffff;
  background-color: #282b30;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  padding: 0rem 0rem 0rem;
  box-sizing: border-box;
  font-weight: 600;
  z-index: 1000;
`;

export const TitleContent = styled.div`
  grid-column: 2/3;
  grid-row: 1;
  z-index: 500;
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  grid-column: 2/3;
  grid-row: 2;
`;

const AppView = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: #2f3238;
`;

//endregion
