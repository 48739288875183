import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import EditText from 'components/EditText';

class DeveloperContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
  }
  /*DeveloperContact Lifecycle Methods */
  //region LifecycleMethods
  componentDidMount() {
    //   console.log('component did mount');
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }
  //endregion

  render() {
    return (
      <DataInfoContainer>
        <DropdownContainer>
          <EditText
            id={'PICountry'}
            data_id={this.props.data_id}
            name={'external_contact_country'}
            labelTxt={'Country'}
            itemsList={this.props.countries_list}
            isDropdown={true}
            value={this.props.data.external_contact_country.code}
            display={this.props.data.external_contact_country.name}
            callback={this.props.callback}
          />
        </DropdownContainer>
        <KeyContact>
          <EditText
            id={'DevKeyContact'}
            data_id={this.props.data_id}
            name={'external_contact_person'}
            labelTxt={'Key Contact'}
            value={this.props.data.external_contact_person}
            callback={this.props.callback}
          />
        </KeyContact>

        <Email>
          <EditText
            id={'PIEmail'}
            data_id={this.props.data_id}
            name={'external_contact_email'}
            labelTxt={'Email'}
            value={this.props.data.external_contact_email}
            callback={this.props.callback}
          />
        </Email>
        <Tel>
          <EditText
            id={'PITel'}
            data_id={this.props.data_id}
            name={'external_contact_phone'}
            labelTxt={'Tel No.'}
            value={this.props.data.external_contact_phone}
            callback={this.props.callback}
          />
        </Tel>
      </DataInfoContainer>
    );
  }
}

export default DeveloperContact;

//region Styles

const Tel = styled.div`
  grid-column: 4/5;
  grid-row: 1;
`;
const Email = styled.div`
  grid-column: 3/4;
  grid-row: 1;
`;

const KeyContact = styled.div`
  grid-column: 2/3;
  grid-row: 1;
`;

const DropdownContainer = styled.div`
  position: relative;
  grid-row: 1;
  grid-column: 1/2;
`;

const DataInfoContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  display: grid;
  height: 50px;
  grid-template-columns: 35rem 22rem 29rem 19rem;
  grid-column-gap: 4rem;
`;

//endregion
