import React, { Component } from 'react';
import './style.css';
import { defaults, Bar } from 'react-chartjs-2';
import './style.css';
import currency from 'utils/currencyFormatter';

class RevenueChart extends Component {
  constructor(props) {
    super(props);

    this.months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];

    this.recievedData = this.props.data;

    let revenueData = {};

    for (const key of Object.keys(this.recievedData)) {
      revenueData[this.recievedData[key].game] = {
        daily: this.parseData(this.recievedData[key].daily),
        weekly: this.parseData(this.recievedData[key].weekly),
        monthly: this.parseData(this.recievedData[key].monthly)
      };
    }

    this.state = {
      revenue_group: 'daily',
      currentGame: '',
      revenueData: revenueData
    };

    this.handleChange = this.handleChange.bind(this);
  }

  parseData(data) {
    let labels = [];
    let ads = [];
    let iap = [];
    let total = [];

    for (const key of Object.keys(data)) {
      labels.push(data[key].date);
      ads.push(data[key].ad_revenue);
      iap.push(data[key].revenue);
      total.push(data[key].ad_revenue + data[key].revenue);
    }
    return {
      labels: labels,
      datasets: [
        {
          label: 'Ads',
          hidden: false,
          backgroundColor: 'rgba(0,213,169,0.2)',
          borderWidth: 1,
          borderColor: 'rgba(0,213,169,0.8)',
          data: ads
        },
        {
          label: 'IAP',
          hidden: false,
          backgroundColor: 'rgba(80,170,238,0.2)',
          borderWidth: 1,
          borderColor: 'rgba(80,170,238,0.8)',
          data: iap
        },
        {
          label: 'Total',
          hidden: true,
          backgroundColor: 'rgba(219,74,184,0.2)',
          borderWidth: 1,
          borderColor: 'rgba(219,74,184,0.8)',
          data: total
        }
      ]
    };
  }

  render() {
    const dropdown = (
      <select onChange={this.handleChange}>
        <option value="daily">daily</option>
        <option value="weekly">weekly</option>
        <option value="monthly">monthly</option>
      </select>
    );

    let dt;
    if (this.props.id === '')
      dt = {
        labels: [''],
        datasets: [
          {
            label: 'Ads',
            hidden: false,
            backgroundColor: 'rgba(0,213,169,0.2)',
            borderWidth: 1,
            borderColor: 'rgba(0,213,169,0.8)',
            data: ['']
          },
          {
            label: 'IAP',
            hidden: false,
            backgroundColor: 'rgba(80,170,238,0.2)',
            borderWidth: 1,
            borderColor: 'rgba(80,170,238,0.8)',
            data: ['']
          },
          {
            label: 'Total',
            hidden: true,
            backgroundColor: 'rgba(219,74,184,0.2)',
            borderWidth: 1,
            borderColor: 'rgba(219,74,184,0.8)',
            data: ['']
          }
        ]
      };
    else dt = Object.assign({}, this.state.revenueData[this.props.id][this.state.revenue_group]);

    return (
      <div className={'revenueChartContent'}>
        <div className={'baseChart'}>
          <h2 className={'chartTitle'}>
            {this.props.title}
            <div className="dropdownBox">{dropdown}</div>
          </h2>
          <Bar
            ref="myChart"
            data={dt}
            options={{
              title: {
                display: false,
                text: ''
              },
              legend: {
                onClick: (event, legendItem) => {
                  //const original = defaults.global.legend.onClick;
                  // original.call(this, event, legendItem);
                  const chart = this.refs.myChart.chartInstance;

                  const index = legendItem.datasetIndex;
                  const ci = chart;
                  const meta = ci.getDatasetMeta(index);
                  const meta1 = ci.getDatasetMeta(0);
                  const meta2 = ci.getDatasetMeta(1);
                  const meta3 = ci.getDatasetMeta(2);

                  if (index === 0 || index === 1) {
                    meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
                    if (meta.hidden === false || meta.hidden === null) meta3.hidden = null;
                  } else if (index === 2) {
                    meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
                    if (meta.hidden === false) {
                      meta1.hidden = true;
                      meta2.hidden = true;
                    } else if (meta.hidden === null) {
                      meta1.hidden = null;
                      meta2.hidden = null;
                    }
                    // meta3.hidden = meta3.hidden === null ? !ci.data.datasets[0].hidden : null;
                    // meta4.hidden = meta4.hidden === null ? !ci.data.datasets[1].hidden : null;
                  }

                  // We hid a dataset ... rerender the chart

                  ci.update();
                },
                display: true,
                position: 'bottom',
                labels: {
                  usePointStyle: false,
                  fontColor: '#989898'
                }
              },
              tooltips: {
                callbacks: {
                  label: function(tooltipItem, data) {
                    return currency(tooltipItem.yLabel);
                  }
                }
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      drawBorder: true,
                      color: '#2f3238'
                    }
                  }
                ],
                yAxes: [
                  {
                    ticks: {
                      callback: function(label, index, labels) {
                        if (Number(label) < 1000)
                          return currency(label, {
                            prepend: '',
                            decimalSize: 0
                          });
                        if (Number(label) >= 1000 && Number(label) < 1000000)
                          return (
                            currency(label / 1000, {
                              prepend: '',
                              decimalSize: 0
                            }) + 'k'
                          );
                        if (Number(label) >= 1000000)
                          return (
                            currency(label / 1000000, {
                              prepend: '',
                              decimalSize: 0
                            }) + 'M'
                          );
                      }
                    },
                    scaleLabel: {
                      display: true,
                      labelString: 'USD'
                    },

                    stacked: true,

                    gridLines: {
                      drawBorder: true,

                      color: '#2f3238'
                    }
                  }
                ]
              }
            }}
          />
        </div>
      </div>
    );
  }

  handleGroupChange(group, value) {
    const state = {};
    state[group] = value;
    this.setState(state);
  }

  handleChange(event) {
    this.setState({ revenue_group: event.target.value });
  }
}

export default RevenueChart;
