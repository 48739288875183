import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import ChartLegend from 'components/ChartLegend';
import { backgroundColorTable, borderColorTable } from 'components/ColorPicker/index';
import formatNumberWithDots from 'utils/formatNumberWithDots';
import TooltipStyle from 'components/TooltipStyle';
import ChartInstance from './ChartInstance';

class ChartPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltip: null,
      mounted: false,
      chartRef: null
    };

    this.showTooltip = this.showTooltip.bind(this);
    this.updateChartRef = this.updateChartRef.bind(this);
    this.mounted = false;
  }

  componentDidMount() {
    this.setState({ mounted: true });
    this.mounted = true;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.mounted && !this.state.mounted) {
      return true;
    }

    return true;
  }

  updateChartRef(ref) {
    this.setState({ chartRef: ref.current.chartInstance });
  }
  showTooltip(tooltip) {
    if (tooltip.opacity === 0) {
      this.setState({
        tooltip: undefined
      });
    } else {
      this.setState({
        tooltip: tooltip
      });
    }
  }
  render() {
    return (
      <Panel>
        <Title>{this.props.chartTitle}</Title>
        <Canvas>
          <Tooltip
            data={this.state.tooltip}
            frontSymbol={this.props.frontSymbol}
            backSymbol={this.props.backSymbol}
          />
          <ChartInstance
            data={this.props.data}
            showTooltip={this.showTooltip}
            updateChartRef={this.updateChartRef}
            parseData={this.props.parseData}
          />
        </Canvas>
        {this.state.chartRef ? (
          <ChartLegendContainer>
            <ChartLegend chart={this.state.chartRef} mark={'dot'} />
          </ChartLegendContainer>
        ) : null}
      </Panel>
    );
  }
}

export default ChartPanel;

const Tooltip = ({ data, frontSymbol, backSymbol }) => {
  if (!data) {
    return null;
  }
  let footer = null;
  let content = [];
  Object.entries(data.body).map(([key, val]) => {
    let str_body = val.lines.toString();
    let body_arr = str_body.split(': ');
    let body_float = parseFloat(body_arr[1]);
    let body_format = formatNumberWithDots(body_float);
    if (key >= 0) {
      let check_unreliable = str_body.includes('UNRELIABLE');
      if (str_body.startsWith('LTV') && check_unreliable) {
        footer = <TooltipFooter>* - Unreliable</TooltipFooter>;
        let onlyData = body_arr[0].split('UNRELIABLE');
        content.push(
          <Fragment key={onlyData}>
            <div>
              <TooltipMark colorMark={data.labelColors[key].borderColor}>&#x25CF;</TooltipMark>
              {onlyData[0]}: {frontSymbol}
              {body_format} *
            </div>
          </Fragment>
        );
      } else if (str_body.startsWith('ROI') && check_unreliable) {
        footer = <TooltipFooter>* - Unreliable</TooltipFooter>;
        let onlyData = body_arr[0].split('UNRELIABLE');
        content.push(
          <Fragment key={onlyData}>
            <div>
              <TooltipMark colorMark={data.labelColors[key].borderColor}>&#x25CF;</TooltipMark>
              {onlyData[0]} : {body_format}
              {backSymbol} *
            </div>
          </Fragment>
        );
      } else if (str_body.startsWith('CPI') && check_unreliable) {
        footer = <TooltipFooter>* - Unreliable</TooltipFooter>;
        let onlyData = body_arr[0].split('UNRELIABLE');
        content.push(
          <Fragment key={onlyData}>
            <div>
              <TooltipMark colorMark={data.labelColors[key].borderColor}>&#x25CF;</TooltipMark>
              {onlyData[0]} : {frontSymbol} {body_format}*
            </div>
          </Fragment>
        );
      } else if (!check_unreliable && (str_body.startsWith('CPI') || str_body.startsWith('LTV'))) {
        content.push(
          <Fragment key={body_arr[0]}>
            <div>
              <TooltipMark colorMark={data.labelColors[key].borderColor}>&#x25CF;</TooltipMark>
              {body_arr[0]} : {frontSymbol}
              {body_format}
            </div>
          </Fragment>
        );
      } else if (!check_unreliable && str_body.startsWith('ROI')) {
        content.push(
          <Fragment key={body_arr[0]}>
            <div>
              <TooltipMark colorMark={data.labelColors[key].borderColor}>&#x25CF;</TooltipMark>
              {body_arr[0]} : {body_format}
              {backSymbol}
            </div>
          </Fragment>
        );
      } else if (
        !str_body.startsWith('ROI') &&
        !str_body.startsWith('LTV') &&
        !str_body.startsWith('CPI')
      ) {
        content.push(
          <Fragment key={body_arr[0]}>
            <div>
              <TooltipMark colorMark={data.labelColors[key].borderColor}>&#x25CF;</TooltipMark>
              {body_arr[0]} : {frontSymbol}
              {body_format}
              {backSymbol}
            </div>
          </Fragment>
        );
      }
    }
  });
  return (
    <TooltipStyle top={`${data.caretY - 5}px`} left={data.caretX} twidth={250} bottomSide={true}>
      <TooltipTitle>{data.title}</TooltipTitle>
      <TooltipContent>{content}</TooltipContent>
      {footer}
    </TooltipStyle>
  );
};

const ChartLegendContainer = styled.div`
  margin-top: -10px;
`;

const TooltipTitle = styled.h2`
  text-align: center;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 8px;
  border-bottom: 1px solid #505561;
  letter-spacing: 0.05rem;
`;

const TooltipContent = styled.div`
  font-size: 12px;
  text-align: left;
  margin-top: 3px;
`;

const TooltipMark = styled.span`
  opacity: 1;
  display: inline-block;
  z-index: 1900;
  font-size: 15px;
  padding-right: 3px;
  padding-left: 20px;
  color: ${props => (props.colorMark ? props.colorMark : '#fff')};
`;

const TooltipFooter = styled.div`
  border-top: 1px solid #505561;
  font-size: 10px;
  color: #99999c;
  text-align: right;
  width: 100%;
  padding-top: 5px;
  margin-top: 5px;
`;

const Canvas = styled.div`
  padding: 4px;
  padding-right: 25px;
  margin: 0;
  background: #282b30;
  position: relative;
  height: 240px;
`;

const Panel = styled.div`
  margin: 0px;
  background-color: #282b30;
  color: #e1e1e5;
  font-weight: 400;
  border: 0.5px solid #36393f;
  border-radius: 3px;
  padding: 1px;
  height: 330px;
`;
const Title = styled.div`
  color: #fefefe;
  font-size: 1.8rem;
  line-height: 3.6rem;
  font-weight: 600;
  padding-left: 8px;
  margin-right: 2rem;
  margin-left: 2rem;
  margin-top: 1rem;
  margin-bottom: 10px;
  border-bottom: 1px solid #606064;
  box-sizing: border-box;
`;
