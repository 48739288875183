import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

class Legend extends Component {
  constructor(props) {
    super(props);
    this.handleClickLegendItem = this.handleClickLegendItem.bind(this);
    this.handleClickROIItem = this.handleClickROIItem.bind(this);
    this.legendRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.chart !== this.props.chart) return true;
    if (nextProps.active_option !== this.props.active_option) return true;
    if (nextProps.hoveredLabel !== this.props.hoveredLabel) return true;
    // console.log('component not update legend');
    return false;
  }

  handleClickROIItem(item, meta, chart) {
    let metaUnreliable;
    if (item.label === '3m') {
      metaUnreliable = chart.getDatasetMeta(1);
    } else if (item.label === '6m') {
      metaUnreliable = chart.getDatasetMeta(3);
    } else if (item.label === '9m') {
      metaUnreliable = chart.getDatasetMeta(5);
    } else if (item.label === '1y') {
      metaUnreliable = chart.getDatasetMeta(7);
    }
    meta.hidden = meta.hidden === null ? !meta.hidden : null;
    metaUnreliable.hidden = metaUnreliable.hidden === null ? !metaUnreliable.hidden : null;
    chart.update();
    //  this.props.updateLegendRef(this.legendRef);
    this.forceUpdate();
  }

  handleClickLegendItem(meta, chart) {
    meta.hidden = meta.hidden === null ? !meta.hidden : null;
    chart.update();
    this.forceUpdate();
  }
  render() {
    return (
      <Panel ref={this.legendRef}>
        <LabelTitleActive>{this.props.active_option}:</LabelTitleActive>
        <LegendOne>
          <LegendActive
            chart={this.props.chart}
            handleItem={this.handleClickLegendItem}
            hoveredLabel={this.props.hoveredLabel}
          />
        </LegendOne>
        <LabelTitle>ROI:</LabelTitle>
        <LegendTwo>
          <LegendROI chart={this.props.chart} handleItem={this.handleClickROIItem} />
        </LegendTwo>
      </Panel>
    );
  }
}

const LegendROI = ({ chart, handleItem }) => (
  <Fragment>
    {chart.data.datasets.map((item, index) => {
      let meta = chart.getDatasetMeta(index);
      if (index < 8 && !item.label.endsWith('UNRELIABLE')) {
        return (
          <LegendItem
            key={index}
            isHidden={meta.hidden}
            onClick={() => handleItem(item, meta, chart)}
          >
            <LabelMark color={item.borderColor}>&#x25CF;</LabelMark>
            {item.label}
          </LegendItem>
        );
      }
    })}
  </Fragment>
);

const LegendActive = ({ chart, handleItem, hoveredLabel }) => (
  <Fragment>
    {chart.data.datasets.map((item, index) => {
      let meta = chart.getDatasetMeta(index);
      if (index >= 8) {
        if (hoveredLabel == item.label) {
          return (
            <LegendItem
              key={index}
              isHidden={meta.hidden}
              color={'#04d5ac'}
              onClick={() => handleItem(meta, chart)}
            >
              <LabelMark color={item.borderColor}>&#x25A0;</LabelMark>
              {item.label}
            </LegendItem>
          );
        } else {
          return (
            <LegendItem key={index} isHidden={meta.hidden} onClick={() => handleItem(meta, chart)}>
              <LabelMark color={item.borderColor}>&#x25A0;</LabelMark>
              {item.label}
            </LegendItem>
          );
        }
      }
    })}
  </Fragment>
);

/** Chart Legend Styles */
//region Legend Styles
const Panel = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 5rem 60rem;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  color: #99999c;
  margin-top: 10px;
  font-size: 12px;
  cursor: pointer;
`;

const LegendOne = styled.div`
  grid-column: 3/4;
  grid-row: 1;
`;

const LegendTwo = styled.div`
  grid-column: 3/4;
  grid-row: 2;
`;
const LegendItem = styled.span`
  display: block;
  float: left;
  padding-right: 20px;
  padding-bottom: 5px;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  color: ${props => props.color};
  text-decoration: ${props => (props.isHidden ? 'line-through' : 'none')};
`;

const LabelTitle = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: normal;
  float: left;
  color: #66676b;
  margin: 0px;
  margin-top: 2px;
  padding-right: 17px;
  grid-column: 2/3;
  grid-row: 2;
`;

const LabelTitleActive = styled(LabelTitle)`
  grid-row: 1;
`;

const LabelMark = styled.span`
  padding: 2px;
  color: ${props => props.color};
  cursor: default;
`;

//endregion

export default Legend;
