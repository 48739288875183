import React, { PropTypes, Component } from 'react';

class SvgGauge extends Component {
  componentDidMount() {
    this.startAnimation();
  }

  calculatePath(rad, start, end) {
    let i = 0;
    // circle.setAttribute("stroke", color);
    let angle = start;
    const radius = rad;
    let d = 'm0,0';
    let find = true;
    const to = end;
    while (find) {
      angle %= 360;
      const radians = (angle / 180) * Math.PI;
      const x = Math.cos(radians) * radius;
      const y = 0 + Math.sin(radians) * radius;
      if (i === 0) {
        d = ` M ${x} ${y}`;
      } else d += ` L ${x} ${y}`;

      if (angle === to && i !== 0) {
        find = false;
      }
      angle += 1;
      i += 1;
    }
    return d;
  }

  calculateTextPath(rad, start, n) {
    const i = 0;
    // circle.setAttribute("stroke", color);
    let angle = start;
    const radius = rad;

    angle %= 360;
    const radians = (angle / 180) * Math.PI;
    const x = Math.cos(radians) * radius;
    const y = Math.sin(radians) * radius;

    let d = ` M -100 ${y + n}`;
    d += ` L ${-79 + 0.25 * n} ${y + n}`;
    d += ` L ${x} ${y}`;
    return d;
  }

  animateBigLine() {
    const lineSmall = this.refs.lineSmall;
    const lineBig = this.refs.lineBig;
    let angle = 189;
    const angle2 = 189;
    const to = this.props.targetLine + 2;
    const to2 = this.props.actualLine;

    const timer2 = window.setInterval(() => {
      angle += 1;
      if (angle >= to) {
        window.clearInterval(timer2);
      }
      lineBig.setAttribute('transform', `rotate(${angle}, 0, 0)`);
    }, 10);
  }

  animateSmallLine(to) {
    if (window.smallArrowInterval) window.clearInterval(window.smallArrowInterval);
    const lineSmall = this.refs.lineSmall;
    const isAnimating = lineSmall.getAttribute('stroke-opacity') == '0.9';

    if (isAnimating) return;
    let angle = 189;
    lineSmall.setAttribute('transform', `rotate(${angle}, 0, 0)`);
    lineSmall.setAttribute('stroke-opacity', '0.9');
    // const timer3 =
    window.smallArrowInterval = window.setInterval(() => {
      angle += 1;
      if (angle >= to) {
        window.clearInterval(window.smallArrowInterval);
      }
      lineSmall.setAttribute('transform', `rotate(${angle}, 0, 0)`);
    }, 5);
  }

  hideSmallLine() {
    const lineSmall = this.refs.lineSmall;
    lineSmall.setAttribute('stroke-opacity', '0.0');
  }

  mouseOverHandler(elRef, ang, tTxt) {
    const element = this.refs[elRef];
    const element2 = this.refs[`${elRef}Glow`];
    const element3 = this.refs[`${elRef}Bckg`];
    const element4 = this.refs[`${elRef}GlowMask`];
    const element5 = this.refs[`${elRef}BckgMask`];
    const element6 = this.refs[`${elRef}Mask`];
    const tooltip = this.refs.tooltip;
    const tooltipBckg = this.refs.tooltipBckg;
    const tooltipArrow = this.refs.tooltipArrow;
    const tooltipTxt = this.refs.tooltipTxt;

    // let target = evt.target;
    element.setAttribute('stroke-opacity', '1.0');
    // element4.setAttribute('stroke-opacity', '1.0');
    const lineSmall = this.refs.lineSmall;
    lineSmall.setAttribute('transform', `rotate(${ang}, 0, 0)`);
    this.animateSmallLine(ang + 2);

    let color = '';
    let r = 44;
    const txt = tTxt;

    if (elRef == 'd1') {
      color = this.props.roiColor;
      r = 68;
    } else if (elRef == 'd2') {
      color = this.props.arpColor;
      r = 56;
    } else if (elRef == 'd3') {
      color = this.props.dauColor;
      r = 44;
    }

    const angle = ang % 360;

    const radians = (angle / 180) * Math.PI;
    const x = Math.cos(radians) * r - 20;
    const y = Math.sin(radians) * r - 26;

    tooltip.setAttribute('opacity', '1.0');
    tooltipBckg.setAttribute('fill', color);
    tooltipArrow.setAttribute('fill', color);
    tooltip.setAttribute('transform', 'translate(0, 0)');
    tooltip.setAttribute('transform', `translate(${x},${y})`);
    tooltipTxt.innerHTML = txt;

    // element3.setAttribute('stroke-opacity', '0.1');
  }

  mouseOutHandler(elRef) {
    const element = this.refs[elRef];
    const element2 = this.refs[`${elRef}Glow`];
    const element3 = this.refs[`${elRef}Bckg`];
    const element4 = this.refs[`${elRef}GlowMask`];
    const element5 = this.refs[`${elRef}BckgMask`];
    const element6 = this.refs[`${elRef}Mask`];
    const tooltip = this.refs.tooltip;
    this.hideSmallLine();
    // let target = evt.target;
    element.setAttribute('stroke-opacity', '0.0');
    tooltip.setAttribute('opacity', '0.0');
    tooltip.setAttribute('transform', 'translate(0, 0)');
    // element4.setAttribute('stroke-opacity', '0.0');
    // element3.setAttribute('stroke-opacity', '1.0');
  }

  animatePath(rad, start, end, elRef, txt) {
    let i = 0;
    // circle.setAttribute("stroke", color);
    let angle = start;
    const radius = rad;
    let d = '';

    const element = this.refs[elRef];
    const element2 = this.refs[`${elRef}Glow`];
    const element3 = this.refs[`${elRef}Bckg`];
    const clicker = this.refs[`${elRef}Clicker`];
    let to = end;
    if (end < 196) {
      // element.setAttribute('stroke', '#282b30');
      to = 196;
    }

    clicker.removeEventListener('mouseover', () => {
      this.mouseOverHandler(elRef, to, txt);
    });
    clicker.removeEventListener('mouseout', () => {
      this.mouseOutHandler(elRef);
    });

    clicker.addEventListener('mouseover', () => {
      this.mouseOverHandler(elRef, to, txt);
    });
    clicker.addEventListener('mouseout', () => {
      this.mouseOutHandler(elRef);
    });

    const timer1 = window.setInterval(() => {
      angle += 1;
      angle %= 360;
      const radians = (angle / 180) * Math.PI;
      const x = Math.cos(radians) * radius;
      const y = Math.sin(radians) * radius;
      // var e = circle.getAttribute("d");
      if (i === 0) {
        d = ` M ${x} ${y}`;
      } else d += ` L ${x} ${y}`;

      if (angle >= to && i !== 0) {
        window.clearInterval(timer1);
      }
      if (d != '') {
        element.setAttribute('d', d);
        element2.setAttribute('d', d);
        element3.setAttribute('d', d);
        clicker.setAttribute('d', d);
      }
      i++;
    }, 10);
  }

  startAnimation() {
    let roiTxt = `${(this.props.roiAngle - 190).toFixed(2)}%`;
    let arpTxt = `${(this.props.arpAngle - 190).toFixed(2)}%`;
    let dauTxt = `${(this.props.dauAngle - 190).toFixed(2)}%`;

    let displayRoiAngle = this.props.roiAngle > 350 ? 350 : this.props.roiAngle;
    let displayArpAngle = this.props.arpAngle > 350 ? 350 : this.props.arpAngle;
    let displayDauAngle = this.props.dauAngle > 350 ? 350 : this.props.dauAngle;

    this.animatePath(68, 188, displayRoiAngle, 'd1', roiTxt);
    this.animatePath(56, 188, displayArpAngle, 'd2', arpTxt);
    this.animatePath(44, 188, displayDauAngle, 'd3', dauTxt);
    this.animateBigLine();
  }

  render() {
    const {
      targetLine,
      actualLine,
      roiAngle,
      arpAngle,
      dauAngle,
      roiColor,
      arpColor,
      dauColor,
      roiTxt,
      arpTxt,
      dauTxt
    } = this.props;
    const rot2 = `rotate(${targetLine.toString()} 0 0)`;
    const rot = `rotate(${actualLine.toString()} 0 0)`;
    const d1 = this.calculatePath(68, 190, 192); // roiAngle);
    const d2 = this.calculatePath(56, 190, 192); // arpAngle);
    const d3 = this.calculatePath(44, 190, 192); // dauAngle);

    const mask1 = this.calculatePath(68, 190, 191);
    const mask2 = this.calculatePath(68, 180, 190);

    const roiTextPath = this.calculateTextPath(68, 187, 6);
    const arpTextPath = this.calculateTextPath(56, 187, 14);
    const dauTextPath = this.calculateTextPath(44, 187, 22);

    const mask3 = this.calculatePath(56, 190, 191);
    const mask4 = this.calculatePath(56, 180, 190);

    const mask5 = this.calculatePath(44, 190, 191);
    const mask6 = this.calculatePath(44, 180, 190);

    const b1 = this.calculatePath(44, 190, 350);
    const b2 = this.calculatePath(50, 190, 350);
    const b3 = this.calculatePath(56, 190, 350);
    const b4 = this.calculatePath(62, 190, 350);
    const b5 = this.calculatePath(68, 190, 350);

    const c1 = this.calculatePath(32, 190, 350);
    const c2 = this.calculatePath(37, 190, 350);
    const c3 = this.calculatePath(74, 190, 350);
    const c4 = this.calculatePath(79, 190, 350);

    const rad = (targetLine / 180) * Math.PI;
    const tx = Math.cos(rad) * 100 - 10;
    const ty = Math.sin(rad) * 100 - 3;
    const targetTxtPos = { x: tx, y: ty };

    const roiTxtPos = { x: -100, y: -4 };
    const arpTxtPos = { x: -100, y: 6 };
    const dauTxtPos = { x: -100, y: 15 };

    const gradName1 = `linearGradient_${Math.floor(Math.random() * 1000)}`;
    const gradName2 = `linearGradient_${Math.floor(Math.random() * 1000)}`;
    const gradName3 = `linearGradient_${Math.floor(Math.random() * 1000)}`;

    return (
      <svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 118" width="100%" height="100%">
        <defs>
          <linearGradient id={gradName1} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={roiColor} stopOpacity="0.5" />
            <stop offset="100%" stopColor={roiColor} />
          </linearGradient>
          <linearGradient id={gradName2} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={arpColor} stopOpacity="0.5" />
            <stop offset="100%" stopColor={arpColor} />
          </linearGradient>
          <linearGradient id={gradName3} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={dauColor} stopOpacity="0.5" />
            <stop offset="100%" stopColor={dauColor} />
          </linearGradient>
          <linearGradient id="linear4" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="25%" stopColor="#3A3C42" />
            <stop offset="100%" stopColor="white" />
          </linearGradient>
          <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
            <feGaussianBlur stdDeviation="2 2" result="shadow" />
            <feOffset dx="6" dy="6" />
          </filter>
          <clipPath id="clip1">
            <path
              d={mask1}
              id="clip1"
              fill="black"
              stroke="black"
              strokeOpacity="1.0"
              strokeLinecap="square"
              strokeWidth="10"
            />
          </clipPath>
        </defs>

        <g transform="translate(50,100) ">
          <path
            d={c1}
            id="arc1"
            fill="none"
            stroke="#232323"
            strokeOpacity="1.0"
            strokeLinecap="square"
            strokeWidth="6"
          />
          <path
            d={c2}
            id="arc1"
            fill="none"
            stroke="#232323"
            strokeOpacity="1.0"
            strokeLinecap="square"
            strokeWidth="6"
          />
          <path
            d={c3}
            id="arc1"
            fill="none"
            stroke="#232323"
            strokeOpacity="1.0"
            strokeLinecap="square"
            strokeWidth="6"
          />
          <path
            d={c4}
            id="arc1"
            fill="none"
            stroke="#232323"
            strokeOpacity="1.0"
            strokeLinecap="square"
            strokeWidth="6"
          />

          <path
            d={b1}
            id="arc1"
            fill="none"
            stroke="#2B2B2B"
            strokeOpacity="1.0"
            strokeLinecap="square"
            strokeWidth="6"
          />
          <path
            d={b2}
            id="arc1"
            fill="none"
            stroke="#232323"
            strokeOpacity="1.0"
            strokeLinecap="square"
            strokeWidth="6"
          />
          <path
            d={b3}
            id="arc1"
            fill="none"
            stroke="#2B2B2B"
            strokeOpacity="1.0"
            strokeLinecap="square"
            strokeWidth="6"
          />
          <path
            d={b4}
            id="arc1"
            fill="none"
            stroke="#232323"
            strokeOpacity="1.0"
            strokeLinecap="square"
            strokeWidth="6"
          />
          <path
            d={b5}
            id="arc1"
            fill="none"
            stroke="#2B2B2B"
            strokeOpacity="1.0"
            strokeLinecap="square"
            strokeWidth="6"
          />

          <path
            d={mask1}
            id="d1GlowMask"
            ref="d1GlowMask"
            fill="none"
            stroke={roiColor}
            strokeOpacity="0.5"
            strokeLinecap="square"
            strokeWidth="6"
          />
          <path
            d={d1}
            id="roi"
            ref="d1Glow"
            fill="none"
            stroke={roiColor}
            strokeOpacity="1.0"
            strokeLinecap="round"
            strokeWidth="6"
          />

          <path
            d={mask2}
            id="d1BckgMask"
            ref="d1BckgMask"
            fill="none"
            stroke="#282b30"
            strokeOpacity="1.0"
            strokeLinecap="square"
            strokeWidth="4"
          />
          <path
            d={d1}
            id="roiBckg"
            ref="d1Bckg"
            fill="none"
            stroke="#282b30"
            strokeOpacity="1.0"
            strokeLinecap="round"
            strokeWidth="4"
          />

          <path
            d={mask1}
            id="d1Mask"
            ref="d1Mask"
            fill="none"
            stroke={roiColor}
            strokeOpacity="0.0"
            strokeLinecap="square"
            strokeWidth="4"
          />
          <path
            d={d1}
            id="d1"
            ref="d1"
            fill="none"
            stroke={`url(#${gradName1})`}
            strokeOpacity="0.0"
            strokeLinecap="square"
            strokeWidth="4"
          />

          <path
            d={d1}
            id="d1Clicker"
            ref="d1Clicker"
            fill="none"
            stroke={roiColor}
            strokeOpacity="0.0"
            strokeLinecap="round"
            strokeWidth="10"
          />

          <path
            d={mask3}
            id="d2GlowMask"
            ref="d2GlowMask"
            fill="none"
            stroke={arpColor}
            strokeOpacity="0.5"
            strokeLinecap="square"
            strokeWidth="6"
          />
          <path
            d={d2}
            id="arc1"
            ref="d2Glow"
            fill="none"
            stroke={arpColor}
            strokeOpacity="1.0"
            strokeLinecap="round"
            strokeWidth="6"
          />

          <path
            d={mask4}
            id="d2BckgMask"
            ref="d2BckgMask"
            fill="none"
            stroke="#282b30"
            strokeOpacity="1.0"
            strokeLinecap="square"
            strokeWidth="4"
          />
          <path
            d={d2}
            id="d2Bckg"
            ref="d2Bckg"
            fill="none"
            stroke="#282b30"
            strokeOpacity="1.0"
            strokeLinecap="round"
            strokeWidth="4"
          />

          <path
            d={mask3}
            id="d2Mask"
            ref="d2Mask"
            fill="none"
            stroke={arpColor}
            strokeOpacity="0.0"
            strokeLinecap="square"
            strokeWidth="4"
          />
          <path
            d={d2}
            id="d2"
            ref="d2"
            fill="none"
            stroke={`url(#${gradName2})`}
            strokeOpacity="0.0"
            strokeLinecap="square"
            strokeWidth="4"
          />

          <path
            d={d2}
            id="d2Clicker"
            ref="d2Clicker"
            fill="none"
            stroke={arpColor}
            strokeOpacity="0.0"
            strokeLinecap="round"
            strokeWidth="10"
          />

          <path
            d={mask5}
            id="d3GlowMask"
            ref="d3GlowMask"
            fill="none"
            stroke={dauColor}
            strokeOpacity="0.5"
            strokeLinecap="square"
            strokeWidth="6"
          />
          <path
            d={d3}
            id="d3Glow"
            ref="d3Glow"
            fill="none"
            stroke={dauColor}
            strokeOpacity="1.0"
            strokeLinecap="round"
            strokeWidth="6"
          />

          <path
            d={mask6}
            id="d3BckgMask"
            ref="d3BckgMask"
            fill="none"
            stroke="#282b30"
            strokeOpacity="1.0"
            strokeLinecap="square"
            strokeWidth="4"
          />
          <path
            d={d3}
            id="d3Bckg"
            ref="d3Bckg"
            fill="none"
            stroke="#282b30"
            strokeOpacity="1.0"
            strokeLinecap="round"
            strokeWidth="4"
          />

          <path
            d={mask5}
            id="d3Mask"
            ref="d3Mask"
            fill="none"
            stroke={dauColor}
            strokeOpacity="0.0"
            strokeLinecap="square"
            strokeWidth="4"
          />
          <path
            d={d3}
            id="d3"
            ref="d3"
            fill="none"
            stroke={`url(#${gradName3})`}
            strokeOpacity="0.0"
            strokeLinecap="square"
            strokeWidth="4"
          />

          <path
            d={d3}
            id="d3Clicker"
            ref="d3Clicker"
            fill="none"
            stroke={dauColor}
            strokeOpacity="0.0"
            strokeLinecap="round"
            strokeWidth="10"
          />

          <line
            x1="0"
            y1="0"
            x2="35"
            y2="-1"
            ref="lineSmall"
            stroke="url(#linear4)"
            strokeOpacity="0.0"
            strokeLinecap="round"
            strokeWidth="4"
            transform={rot}
          />
          <line
            x1="0"
            y1="0"
            x2="90"
            y2="0"
            ref="lineBig"
            stroke="#04d5ac"
            strokeOpacity="1.0"
            strokeWidth="1"
            transform={rot2}
          />
          <circle
            cx="0"
            cy="0"
            r="4"
            stroke="#99999c"
            strokeWidth="4"
            fillOpacity="1.0"
            fill="#99999c"
          />

          <text
            x={targetTxtPos.x}
            y={targetTxtPos.y + 6}
            fontFamily="Roboto"
            fill="rgb(131, 132, 137)"
            fontSize="8"
          >
            Target
          </text>

          <path
            d={roiTextPath}
            id="RoiTextPath"
            fill="none"
            stroke="#404040"
            strokeOpacity="1.0"
            strokeLinecap="square"
            strokeWidth="0.5"
          />
          <path
            d={arpTextPath}
            id="ArpTextPath"
            fill="none"
            stroke="#404040"
            strokeOpacity="1.0"
            strokeLinecap="square"
            strokeWidth="0.5"
          />
          <path
            d={dauTextPath}
            id="DauTextPath"
            fill="none"
            stroke="#404040"
            strokeOpacity="1.0"
            strokeLinecap="square"
            strokeWidth="0.5"
          />
          <text
            x={roiTxtPos.x}
            y={roiTxtPos.y - 10}
            fontFamily="Roboto"
            fontSize="8"
            fill="rgb(131, 132, 137)"
          >
            0%
          </text>
          <text
            x={roiTxtPos.x + 184}
            y={roiTxtPos.y - 10}
            fontFamily="Roboto"
            fontSize="8"
            fill="rgb(131, 132, 137)"
          >
            160%
          </text>
          <text
            x={roiTxtPos.x}
            y={roiTxtPos.y}
            fontFamily="Roboto"
            fontSize="6"
            fill="rgb(131, 132, 137)"
          >
            ROI
          </text>
          <text
            x={arpTxtPos.x}
            y={arpTxtPos.y}
            fontFamily="Roboto"
            fontSize="6"
            fill="rgb(131, 132, 137)"
          >
            LTV
          </text>
          <text
            x={dauTxtPos.x}
            y={dauTxtPos.y}
            fontFamily="Roboto"
            fontSize="6"
            fill="rgb(131, 132, 137)"
          >
            CPI
          </text>
        </g>

        <g transform="translate(50,100) ">
          <g ref="tooltip" transform="translate(0,0)" pointerEvents="none" opacity="0">
            <g className="tooltip exact">
              <rect width="47" height="19" rx="2" ry="2" fill="black" />
              <rect
                ref="tooltipBckg"
                transform="translate(0.5,0.5) "
                width="46"
                height="18"
                rx="2"
                ry="2"
                fill={dauColor}
              />
              <text
                ref="tooltipTxt"
                dy="13"
                x="24"
                fill="#F3F9E9"
                fontSize="0.8rem"
                textAnchor="middle"
              >
                122
              </text>
              <polygon points="15,19 23,19 19,23" fill="black" />
              <polygon ref="tooltipArrow" points="16,18 22,18 19,22" fill={dauColor} />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default SvgGauge;
