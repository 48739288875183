import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Panel from 'components/Panel';
import Popup from 'components/Popup';
import Textfield from 'components/Textfield';
import CommonButton, { Button } from 'components/CommonButton/index';
import moment from 'moment';
import TooltipStyle from 'components/TooltipStyle';
class AlertPanels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openColors: false,
      choosenColor: null,
      level: 0,
      comment: ''
    };
    this.showColorTooltip = this.showColorTooltip.bind(this);
    this.chooseColor = this.chooseColor.bind(this);
    this.setComment = this.setComment.bind(this);
    this.close = this.close.bind(this);
    this.create = this.create.bind(this);
  }

  showColorTooltip() {
    let open = null;
    {
      this.state.openColors ? (open = false) : (open = true);
    }
    this.setState({ openColors: open });
  }

  chooseColor(color) {
    let level = 0;
    switch (color) {
      case '#f79f1f':
        level = 1;
        break;
      case '#ea2027':
        level = 2;
        break;
    }
    this.setState({ choosenColor: color, openColors: false, level: level });
  }

  setComment(event) {
    const target = event.target;

    this.setState({ comment: target.value });
  }

  close() {
    this.setState({ openColors: false, choosenColor: null });
    this.props.handleAlertPopup();
  }
  create() {
    let comment = 'No info';
    if (/\S/.test(this.state.comment)) {
      comment = this.state.comment;
    }

    this.props.createAlert(comment, this.state.level);
    this.setState({ openColors: false, choosenColor: null });
  }

  render() {
    let colorsTable = ['#f79f1f', '#ea2027'];
    /*
                "id": alert.id,
                   "comment": alert.comment,
                   "level": alert.level,
                   "date_created": alert.date_created
                */

    return (
      <Fragment>
        <Popup
          title={'Add Alert'}
          show={this.close}
          popupheight={256}
          popupwidth={600}
          visible={this.props.isAlertPopup}
        >
          <PopupContainer>
            <AlertTypeContainer>
              {this.state.openColors ? (
                <TooltipStyle theight={40} twidth={89} bottomSide={true} top={'10px'} left={19}>
                  {Object.entries(colorsTable).map(([k, v]) => {
                    return (
                      <TooltipItems
                        key={'key-' + v}
                        aColor={v}
                        onClick={() => this.chooseColor(v)}
                      />
                    );
                  })}
                </TooltipStyle>
              ) : null}
              <TypeTitle>Severity</TypeTitle>
              <AlertPopupType aColor={this.state.choosenColor} onClick={this.showColorTooltip} />
            </AlertTypeContainer>
            <AlertPopupTextfield>
              <Textfield
                onChange={this.setComment}
                labelText={'Comment'}
                id={'alertText'}
                type={'text'}
                autoFocus
                maxLength="50"
              />
            </AlertPopupTextfield>
            <AlertPopupButtons>
              <CommonButton
                disabled={false}
                cancel={true}
                width={'150px'}
                text={'Cancel'}
                onClick={this.close}
              />
              <CommonButton
                disabled={this.state.choosenColor === null || this.state.comment.length === 0}
                width={'150px'}
                text={'Add'}
                onClick={this.create}
              />
            </AlertPopupButtons>
          </PopupContainer>
        </Popup>

        <AlertViewTitle>Alerts</AlertViewTitle>
        <AlertItems>
          {Object.entries(this.props.alerts_list).map(([key, val]) => {
            // let data = moment(val.date_created, 'DD-MM-YYYY');

            let data = moment(val.date_created).format('HH:mm:ss'); // moment(val.date_created).local().toString();//format('HH:mm, DD-MM-YYYY');
            // console.log(val.date_created,data)
            return (
              <Fragment key={key}>
                <AlertDate key={'date-item-' + val.id}>{`${
                  val.date_created
                }  ( ${data} your time )`}</AlertDate>
                <AlertItem
                  key={'item-' + val.id}
                  aColor={val.level === 1 ? '#ffd31b' : '#f90a1a'}
                  aType={'type'}
                  aText={val.comment}
                  deleteAlert={this.props.deleteAlert}
                  id={val.id}
                />
              </Fragment>
            );
          })}
          <AlertButton>
            <CommonButton text={'+ Add alert'} onClick={this.props.handleAlertPopup} />
          </AlertButton>
        </AlertItems>
      </Fragment>
    );
  }
}

export default AlertPanels;

const AlertItem = props => {
  let colorType = null;
  {
    props.aType === 'important'
      ? (colorType = 'rgb(219, 70, 70)')
      : (colorType = 'rgb(233, 143, 16)');
  }
  return (
    <Panel itemId={props.id} handleDelete={props.deleteAlert}>
      <PanelContainer>
        <AlertType aColor={props.aColor} />
        <AlertText>{props.aText}</AlertText>
      </PanelContainer>
    </Panel>
  );
};

const AlertPopupButtons = styled.div`
  grid-row: 3;
  grid-column: 1/3;
  display: flex;
  justify-content: space-around;
  width: 100%;

  padding: 1rem;

  margin-top: 2rem;
  margin-bottom: 1rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;

const AlertViewTitle = styled.div`
  width: 100%;
  color: #fff;
  padding: 0rem 0rem 1%;
  font-size: 16px;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 4rem;
  margin-bottom: 3rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;

const AlertItems = styled.div`
  width: 92%;
  margin: 0% 3%;
  position: relative;
`;

const AlertButton = styled.div`
  height: 32px;
  width: 130px;
  position: absolute;
  right: 0px;
`;
const AlertDate = styled.div`
  font-size: 1.1rem;
  letter-spacing: 0.06rem;
  color: #99999c;
  padding-left: 8px;
  padding-bottom: 4px;
`;

const PanelContainer = styled.div`
  display: grid;
  grid-template-columns: 3rem 38rem;
  line-height: 1.2;
  vertical-align: middle;
`;
const AlertText = styled.div`
  font-size: 1.2rem;
  letter-spacing: 0.06rem;
  color: rgb(225, 225, 229);
`;

const AlertType = styled.div`
  float: left;
  background-color: ${props => (props.aColor ? props.aColor : 'rgb(56, 60, 64)')};
  border-radius: 50%;
  width: 12px;
  height: 12px;
`;

const AlertPopupType = styled(AlertType)`
  float: none;
  width: 24px;
  height: 24px;
  margin-left: 7px;
  &:hover {
    cursor: pointer;
    border: 2px solid #00d5a9;
  }
`;

const TooltipItems = styled(AlertPopupType)`
  pointer-events: all;
  float: left;
`;

const AlertTypeContainer = styled.div`
  position: relative;
  grid-column: 1/2;
`;

const TypeTitle = styled.div`
  font-size: 12px;
  color: #99999c;
  display: block;
  margin-bottom: 5px;
`;

const AlertPopupTextfield = styled.div`
  grid-column: 2/3;
`;

const PopupContainer = styled.div`
  margin: 30px 90px 20px 90px;
  display: grid;
  grid-template-columns: 6rem 35rem;
`;
