import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

const TooltipStyle = props => {
  return (
    <Fragment>
      {props.leftSide ? (
        <LeftSide top={props.top} left={props.left} twidth={props.twidth}>
          {props.children}
        </LeftSide>
      ) : null}
      {props.bottomSide ? (
        <BottomSide top={props.top} left={props.left} twidth={props.twidth}>
          {props.children}
        </BottomSide>
      ) : null}
    </Fragment>
  );
};

export default TooltipStyle;

const ChartTooltip = styled.div`
  position: absolute;
  background-color: #40444c;
  border-radius: 3px;
  pointer-events: none;

  z-index: 1900;
  font-family: 'Roboto', sans-serif;
  color: #e1e1e5;
  font-size: 1rem;
  height: ${props => (props.theight ? props.theight + 'px' : 'auto')};
  width: ${props => (props.twidth ? props.twidth + 'px' : '300px')};
  box-shadow: 0px 1px 11px 2px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.05rem;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  top: ${props => (props.top ? props.top : '0px')};
  left: ${props => (props.left ? `${props.left}px` : '0px')};
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
`;

const LeftSide = styled(ChartTooltip)`
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, -50%);
  &:after,
  &:before {
    right: 100%;
    top: 95%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #40444c;
    border-width: 10px;
    margin-top: -25px;
  }
  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #40444c;
    border-width: 10px;
    margin-top: -25px;
  }
`;

const BottomSide = styled(ChartTooltip)`
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, -100%);
  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #40444d;
    border-width: 4px;
    margin-left: -4px;
  }
  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: #40444d;
    border-width: 7px;
    margin-left: -7px;
  }
`;
