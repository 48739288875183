import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

const OptionButtonPanel = styled.div`
  margin: 0px;
  width: 100%;
  height: ${props => (props.height ? props.height + 2 + 'px' : '32px')};
  border-style: solid;
  border-width: 1px;
  border-color: rgb(30, 32, 34);
  border-radius: 3px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  padding: 0px;
  display: flex;
`;

const OptionBtn = styled.button`
  padding: 0px;
  width: 50%;
  height: ${props => (props.height ? props.height + 'px' : '30px')};
  border: 0px none;
  font-size: ${props => (props.fontSize ? props.fontSize + 'px' : '12px')};
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
  text-transform: capitalize;
  text-align: center;
  background-color: rgb(30, 32, 34);
  float: left;
  outline-style: none;
  cursor: pointer;
`;

const CurrentOptionBtn = styled(OptionBtn)`
  border-radius: 3px;
  color: #ffffff;
  background-image: -moz-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.36), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);

  ${({ switchColor }) =>
    switchColor == 'green' &&
    `
background-image: -moz-linear-gradient( 90deg, rgb(47,130,34) 0%, rgb(68,189,50) 100%);
 background-image: -webkit-linear-gradient( 90deg, rgb(47,130,34) 0%, rgb(68,189,50) 100%);
 background-image: -ms-linear-gradient( 90deg, rgb(47,130,34) 0%, rgb(68,189,50) 100%);
  `};

  ${({ switchColor }) =>
    switchColor == 'red' &&
    `

background-image: -moz-linear-gradient( 90deg, rgb(168,24,28) 0%, rgb(234,32,39) 100%);
 background-image: -webkit-linear-gradient( 90deg, rgb(168,24,28) 0%, rgb(234,32,39) 100%);
 background-image: -ms-linear-gradient( 90deg, rgb(168,24,28) 0%, rgb(234,32,39) 100%);
  `};
`;

class Switch extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { items, handleChange, labelText } = this.props;
    return (
      <OptionButtonPanel height={this.props.height} onClick={handleChange}>
        {Object.entries(items).map(([key, val]) =>
          labelText == val ? (
            <CurrentOptionBtn
              fontSize={this.props.fontSize}
              height={this.props.height}
              key={key}
              value={val}
              switchColor={this.props.switchColor}
            >
              {val}
            </CurrentOptionBtn>
          ) : (
            <OptionBtn
              fontSize={this.props.fontSize}
              height={this.props.height}
              key={key}
              value={val}
            >
              {val}
            </OptionBtn>
          )
        )}
      </OptionButtonPanel>
    );
  }
}

export default Switch;
