import React, { Component, PureComponent } from 'react';
import CheckboxPanel from './CheckboxPanel';
import RegionCheckboxPanel from './RegionCheckboxPanel';
import storage from 'utils/storage';
import moment from 'moment';
import LoadingIndicator from 'components/LoadingIndicator';
import SimpleIndicator from 'components/SimpleIndicator';
import AnimatedView from 'components/AnimatedView';
import CampaignsTable from './CampaignsTable';
import UserAcquisitionChart from './UserAcquisitionChart';
import styled from 'styled-components';
import SelectDeselectAll from 'components/SelectDeselectAll';
import DropdownList from 'components/DropdownList';
import { CheckboxContainer, PanelTitle } from './CheckboxPanel';
import CustomDatePicker from 'components/CustomDatePicker';
import './style.css';
import close_icon from './assets/close_icon.png';
import translateName from 'utils/translateColumnName';
import CheckboxList from 'components/CheckboxList';
import Textfield from 'components/Textfield';
/** Game Detail Pure Components */
//region Game Detail Pure Components
const OptionButtons = ({ active_button, items, handleChartOptionButton }) => (
  <OptionButtonPanel>
    {Object.entries(items).map(([key, val]) => {
      let button =
        active_button == val ? (
          <CurrentOptionBtn value={val} onClick={handleChartOptionButton}>
            {val}
          </CurrentOptionBtn>
        ) : (
          <OptionBtn value={val} onClick={handleChartOptionButton}>
            {val}
          </OptionBtn>
        );
      return <div key={key}>{button}</div>;
    })}
  </OptionButtonPanel>
);

const FrequencyButtons = ({ items, active_button, handleFrequencyButton, active }) => (
  <FrequencyContainer>
    {Object.entries(items).map(([key, val]) => {
      let button =
        active_button == val ? (
          <FrequencyCurrent value={val} onClick={handleFrequencyButton} active={active}>
            {val}
          </FrequencyCurrent>
        ) : (
          <FrequencyBtn value={val} onClick={handleFrequencyButton} active={active}>
            {val}
          </FrequencyBtn>
        );
      return <div key={key}>{button}</div>;
    })}
  </FrequencyContainer>
);

const ItemsTab = ({ items, tab, handleCheck, buttonState, handleSelect }) => {
  return (
    <ItemsTabPanel>
      <ItemsTabTitle>{tab}</ItemsTabTitle>
      <CheckboxList
        items={items}
        id={tab}
        checkboxWidth={150}
        handleItems={handleCheck}
        handleParam={tab}
      />
      <SelectDeselectPlaceholder>
        <SelectDeselectAll
          buttonState={buttonState[tab]}
          selectDeselectAll={() => handleSelect(tab)}
        />
      </SelectDeselectPlaceholder>
    </ItemsTabPanel>
  );
};

const ItemsToCheck = ({ items, handleCheck, buttonState, handleSelect }) => {
  let itemList = [];
  {
    Object.entries(items).map(([key, val]) => {
      itemList.push(
        <ItemsTab
          key={key}
          items={val}
          tab={key}
          handleCheck={handleCheck}
          buttonState={buttonState}
          handleSelect={handleSelect}
        />
      );
    });
  }
  return (
    <ShowAndHidePanel>
      <ShowHideTitle>Show / Hide columns</ShowHideTitle>
      <ShowAndHideListCont>{itemList}</ShowAndHideListCont>
    </ShowAndHidePanel>
  );
};
//endregion
class GameDetail extends PureComponent {
  constructor(props) {
    super(props);
    /**Prepare data to state */
    //region Prepare data
    let currentDate = new Date();
    let oneWeekAgo = new Date().setDate(currentDate.getDate() - 6);
    const chart_options_table = ['Costs', 'Installs'];
    const chart_frequency_table = ['Daily', 'Weekly', 'Monthly'];
    const table_view_frequency_table = ['Daily', 'Weekly', 'Monthly'];
    const table_checkbox_items = {
      ROI: {
        '7d ROI': false,
        '1m ROI': false,
        '3m ROI': false,
        '6m ROI': false,
        '9m ROI': true,
        '12m ROI': false
      },
      LTV: {
        '7d LTV': false,
        '1m LTV': false,
        '3m LTV': false,
        '6m LTV': false,
        '9m LTV': true,
        '12m LTV': false
      },
      Other: {
        Revenue: false,
        'Revenue to date': false,
        Installs: true,

        CPI: true,

        Impressions: false,
        Clicks: false,
        'Conversion rate': false
      }
    };
    //endregion
    /** Setting state in constructor */
    //region State
    this.state = {
      filtersData: {
        platforms: [],
        sources: [],
        campaigns: [],
        region_data: []
      },

      platforms: [],
      sources: [],
      campaigns: [],
      region_data: [],

      current_region_id: 'Europe',
      searchCountry: '',
      searched_countries: '',
      searching_mode: false,

      searched_filters: {
        platforms: '',
        sources: '',
        campaigns: ''
      },
      isDownloadindData: false,
      isFetching: true,
      updatingTable: false,
      updatingChart: false,
      showTableDatePicker: false,
      showChartDatePicker: false,
      needUpdate: false,
      acquisitionData: {},
      tableDatesFrom: oneWeekAgo,
      tableDatesTo: new Date(),
      chartDatesFrom: oneWeekAgo,
      chartDatesTo: new Date(),
      chartData: [],
      chart_options: chart_options_table,
      chart_option_active_button: 'Costs',
      chart_frequency_options: chart_frequency_table,
      chart_frequency_active_button: 'Daily',
      table_view_frequency_options: table_view_frequency_table,
      table_view_frequency_active_button: 'Daily',
      table_show_records: 1,
      table_selected_source: '',
      table_selected_campaign: '',
      table_tabular_group: 'source',
      sorting_button: 'desc',
      table_view_checkbox_items: table_checkbox_items,
      btn_state_all_false: false,
      btn_state_all_true: true,
      btn_state_diff: false,
      table_select_state: {
        ROI: {
          all_false: false,
          all_true: false,
          all_diff: true
        },
        LTV: {
          all_false: false,
          all_true: false,
          all_diff: true
        },
        Other: {
          all_false: false,
          all_true: false,
          all_diff: true
        }
      },
      checkboxes_select_state: {
        sources: {
          all_false: false,
          all_true: false,
          all_diff: true
        },
        platforms: {
          all_false: false,
          all_true: true,
          all_diff: false
        },

        campaigns: {
          all_false: false,
          all_true: true,
          all_diff: false
        }
      },
      region_select_state: {
        Africa: {
          all_false: false,
          all_true: true,
          all_diff: false
        },
        Antarctica: {
          all_false: false,
          all_true: true,
          all_diff: false
        },
        Asia: {
          all_false: false,
          all_true: true,
          all_diff: false
        },
        Europe: {
          all_false: false,
          all_true: true,
          all_diff: false
        },
        'North America': {
          all_false: false,
          all_true: true,
          all_diff: false
        },
        Oceania: {
          all_false: false,
          all_true: true,
          all_diff: false
        },
        'South America': {
          all_false: false,
          all_true: true,
          all_diff: false
        },
        Special: {
          all_false: false,
          all_true: true,
          all_diff: false
        }
      },

      table_current_sort: 'Amount spend',
      table_filters_list: [{ name: 'Installs', modifier: '>=', value: 1 }]
    };
    //endregion

    /** Methods declarations */
    //region Methods declarations
    this.fetchData = this.fetchData.bind(this);
    this.onDataReceived = this.onDataReceived.bind(this);
    this.onUpdateDataReceived = this.onUpdateDataReceived.bind(this);
    this.getFiltersData = this.getFiltersData.bind(this);
    this.getAcquisitionData = this.getAcquisitionData.bind(this);
    this.getAcquisitionSummary = this.getAcquisitionSummary.bind(this);
    this.handleRegionsChange = this.handleRegionsChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleChartOptionButton = this.handleChartOptionButton.bind(this);
    this.handleChartFrequencyButton = this.handleChartFrequencyButton.bind(this);
    this.handleRecordsChange = this.handleRecordsChange.bind(this);
    this.handleShowTableItems = this.handleShowTableItems.bind(this);
    this.handleSearchedFilterChange = this.handleSearchedFilterChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFilterListChange = this.handleFilterListChange.bind(this);
    this.handleCurrentClickedRegion = this.handleCurrentClickedRegion.bind(this);
    this.onSearchCountryChange = this.onSearchCountryChange.bind(this);
    this.handleApplyFilters = this.handleApplyFilters.bind(this);
    this.updateAcquisitionData = this.updateAcquisitionData.bind(this);
    this.updateAcquisitionSummary = this.updateAcquisitionSummary.bind(this);
    this.tableNavigationHandler = this.tableNavigationHandler.bind(this);
    this.chartChangeTableNavigation = this.chartChangeTableNavigation.bind(this);
    this.compareDec = this.compareDec.bind(this);
    this.updateCustomDataRangeChart = this.updateCustomDataRangeChart.bind(this);
    this.handleAllSetTrueOrFalse = this.handleAllSetTrueOrFalse.bind(this);
    this.selectDeselectOneRegion = this.selectDeselectOneRegion.bind(this);
    this.checkRegionItemsState = this.checkRegionItemsState.bind(this);
    this.checkAllRegions = this.checkAllRegions.bind(this);
    this.checkOneRegionItemsState = this.checkOneRegionItemsState.bind(this);
    this.checkCheckboxItemsState = this.checkCheckboxItemsState.bind(this);
    this.handleSelectDeselectAllCheckbox = this.handleSelectDeselectAllCheckbox.bind(this);
    this.checkTableItemsState = this.checkTableItemsState.bind(this);
    this.handleSelectDeselectTable = this.handleSelectDeselectTable.bind(this);
    this.downloadTableDataHandler = this.downloadTableDataHandler.bind(this);
    this.checkedTableItemsList = this.checkedTableItemsList.bind(this);
    this.handleSelectSortTable = this.handleSelectSortTable.bind(this);
    this.handleSortTable = this.handleSortTable.bind(this);
    this.handleAddTableFilters = this.handleAddTableFilters.bind(this);
    this.handleSelectFilter = this.handleSelectFilter.bind(this);
    this.handleSelectModifier = this.handleSelectModifier.bind(this);
    this.handleSelectValue = this.handleSelectValue.bind(this);
    this.handleDeleteFilter = this.handleDeleteFilter.bind(this);
    //endregion
  }
  /** Game Detail Lifecycle Methods */
  //region Lifecycle Methods
  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    this.mounted = false;
  }
  //endregion
  /** Mounting, fetch and parse data */
  //region Fetch and parse data
  fetchData() {
    this.setState(prevState => ({
      isFetching: true
    }));
    // this.setState( { ...this.state, isFetching:true };);
    this.getFiltersData()
      .then(data => this.getAcquisitionData(data))
      .then(data => this.getAcquisitionSummary(data))
      .then(data => this.onDataReceived(data));

    //setTimeout(this.onDataReceived, 2000);
  }

  parseFiltersData(data) {
    let filters = {};
    filters.platforms = [];
    filters.sources = [];
    filters.campaigns = [];
    filters.region_data = [];

    data.platforms.map(item => {
      filters.platforms[item] = true;
    });

    data.sources.map(item => {
      filters.sources[item] = true;
    });
    if ('Organic' in filters.sources) {
      filters.sources['Organic'] = false;
    }
    data.campaigns.map(item => {
      filters.campaigns[item] = true;
    });

    Object.entries(data.sorted_region_data).map(([key, value]) => {
      Object.entries(value).map(([k, val]) => {
        filters.region_data[k] = [];
        for (let v of val) {
          filters.region_data[k][v] = true;
        }
      });
    });
    return filters;
  }

  onDataReceived(data) {
    if (!this.mounted) return;

    if (data === undefined) {
      return;
    }

    let from = moment(data.acquisitionData.result.dates[0], 'DD-MM-YYYY');
    let to = moment(
      data.acquisitionData.result.dates[data.acquisitionData.result.dates.length - 1],
      'DD-MM-YYYY'
    );

    let cfrom = moment(data.chartsData.result[0].date, 'DD-MM-YYYY');
    let cto = moment(data.chartsData.result[data.chartsData.result.length - 1].date, 'DD-MM-YYYY');
    const filtersData = this.parseFiltersData(data.filtersData);
    let newState = { ...this.state };
    newState.tableDatesFrom = from;
    newState.tableDatesTo = to;
    newState.chartDatesFrom = cfrom;
    newState.chartDatesTo = cto;
    newState.chartData = data.chartsData.result;
    newState.acquisitionData = data.acquisitionData.result;
    newState.filtersData = filtersData;
    newState.isFetching = false;
    newState.platforms = filtersData.platforms;
    newState.sources = filtersData.sources;
    newState.campaigns = filtersData.campaigns;
    newState.region_data = filtersData.region_data;
    this.setState(newState);
  }

  onUpdateDataReceived(data) {
    if (!this.mounted) return;

    if (data === undefined) {
      return;
    }
    let from = this.state.tableDatesFrom;
    let to = this.state.tableDatesTo;

    let cfrom = this.state.chartDatesFrom;
    let cto = this.state.chartDatesTo;

    let chartsData = this.state.chartsData;
    let acquisitionData = this.state.acquisitionData;

    let newState = { ...this.state };
    //newState.needUpdate = false;

    newState.isFetching = false;

    if (data.newFiltersData !== null) {
      newState.filtersData = data.newFiltersData;
    }

    if (data.acquisitionData !== null) {
      newState.updatingTable = false;

      acquisitionData = data.acquisitionData.result;
      newState.tableDatesFrom = from;
      newState.tableDatesTo = to;
      newState.acquisitionData = acquisitionData; //data.acquisitionData.result;
    }

    if (data.chartsData !== null) {
      newState.updatingChart = false;

      chartsData = data.chartsData.result;
      newState.chartDatesFrom = cfrom;
      newState.chartDatesTo = cto;
      newState.chartData = chartsData; //data.chartsData.result;
    }

    this.setState(newState);
  }
  getFiltersData() {
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.189.113.91:5000';
    const PATH_CALL = `/filters/${this.props.location.state.id}`;
    const url = `${PATH_BASE}${PATH_CALL}`;

    //this.setState({ isFetching: true });
    this.setState(prevState => ({
      isFetching: true
    }));
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};

    data.filtersData = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        if (status != 200) {
          return;
        }
        let json = response.json();

        return json;
      })
      .then(json => {
        if (json == undefined) {
          return;
        }
        data.filtersData = json.result;
        return data;
      });
  }

  getAcquisitionData(data, options = null) {
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.189.113.91:5000';
    const PATH_CALL = `/acquisition_tabular_data/${this.props.location.state.id}/source`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    // const url = 'http://192.168.0.187:5020/acquisition_tabular_data/snipers-vs-thieves/source';
    // this.setState({ isFetching: true });
    this.setState(prevState => ({
      isFetching: true
    }));
    let payload = {};
    if (options != null) payload = options;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    if (data == undefined) {
      return;
    }
    data.acquisitionData = null;
    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status != 200) {
        }
        return json;
      })
      .then(json => {
        data.acquisitionData = json;
        return data;
      });
  }

  getAcquisitionSummary(data, options = null) {
    //const platform = this.state.selectedPlatform.toLowerCase();
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.189.113.91:5000';
    const PATH_CALL = `/acquisition_summary/${this.props.location.state.id}`;
    /*const PATH_CALL = `/acquisition_summary/${this.props.location.state.id}/${moment(
          this.state.to
        ).format('DD-MM-YYYY')}/${moment(this.state.from).format(
          'DD-MM-YYYY'
        )}?data=retention|revenue|revenue_cost&platform=${platform}`;
    */
    const url = `${PATH_BASE}${PATH_CALL}`;

    //this.setState({ isFetching: true });
    this.setState(prevState => ({
      isFetching: true
    }));
    let payload = {};
    if (options != null) payload = options;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    if (data == undefined) {
      return;
    }
    data.chartsData = null;
    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status != 200) {
          console.log('error', status);
        }
        return json;
      })
      .then(json => {
        data.chartsData = json;
        return data;
      });
  }
  downloadTableDataHandler() {
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.189.113.91:5000';
    const PATH_CALL = `/acquisition_tabular_data_csv/${this.props.location.state.id}/${
      this.state.table_tabular_group
    }`;

    this.setState(prevState => ({
      isDownloadindData: true
    }));

    const url = `${PATH_BASE}${PATH_CALL}`;
    //this.setState({ updatingTable: true });

    let campaigns = [];
    let platforms = [];
    let countries = [];
    let sources = [];
    let filters = [];
    let columns = [];

    if (this.state.table_tabular_group === 'source') {
      //source|campaign|adset
      Object.entries(this.state.campaigns).map(([key, value]) => {
        if (value === true) campaigns.push(key);
      });

      Object.entries(this.state.sources).map(([key, value]) => {
        if (value === true) sources.push(key);
      });
    } else if (this.state.table_tabular_group === 'campaign') {
      //source|campaign|adset
      sources.push(this.state.table_selected_source);

      Object.entries(this.state.campaigns).map(([key, value]) => {
        if (value === true) campaigns.push(key);
      });
    } else if (this.state.table_tabular_group === 'adset') {
      //source|campaign|adset
      sources.push(this.state.table_selected_source);
      campaigns.push(this.state.table_selected_campaign);
    }

    Object.entries(this.state.region_data).map(([key, value]) => {
      Object.entries(value).map(([key, value]) => {
        if (value === true) countries.push(key);
      });
    });

    Object.entries(this.state.platforms).map(([key, value]) => {
      if (value === true) platforms.push(key);
    });

    filters = this.state.table_filters_list.map(item => {
      return {
        type: item.modifier === '>=' ? 'ge' : 'lt',
        column: translateName(item.name),
        value: parseInt(item.value)
      };
    });

    Object.entries(this.state.table_view_checkbox_items).map(([key, value]) => {
      Object.entries(value).map(([key, value]) => {
        if (value === true) columns.push(translateName(key));
      });
    });

    let payload = {
      columns: columns,
      date_start: this.state.chartDatesFrom.format('DD-MM-YYYY'),
      date_end: this.state.chartDatesTo.format('DD-MM-YYYY'),
      granulation: this.state.chart_frequency_active_button.toLowerCase(),
      sources: sources,
      campaigns: campaigns,
      adsets: 'all',
      platforms: platforms,
      countries: countries,
      sort_column: translateName(this.state.table_current_sort),
      sort_order: this.state.sorting_button,
      filters: filters
    };

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    const setState = this.setState;

    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        //let json = response.json();
        if (status != 200) {
          this.setState({ isDownloadindData: false });
          console.log('error', status);
        }
        let blob = response.blob();
        return blob;
      })
      .then(blob => {
        //data.acquisitionData = json;

        this.setState({ isDownloadindData: false });

        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        let fileName = `media-sources-data-${moment(new Date()).format('DD-MM-YYYY_HH-mm-ss')}.csv`;
        a.href = url;
        a.download = fileName;
        a.click();
        //return json;
      });
  }
  //endregion

  compareDec(a, b) {
    if (a.amount_spent < b.amount_spent) return 1;
    if (a.amount_spent > b.amount_spent) return -1;
    return 0;
  }
  /** Update data **/
  //region Update data

  updateCustomDataRangeChart(dateFrom, dateTo, needUpdate) {
    let newState = { ...this.state };
    if (needUpdate) {
      newState.needUpdate = false;
      newState.chartDatesFrom = moment(dateFrom, 'DD-MM-YYYY');
      newState.chartDatesTo = moment(dateTo, 'DD-MM-YYYY');
      this.setState(newState);
      setTimeout(this.handleApplyFilters, 0, 'all');
    } else return;
  }

  updateAcquisitionData(data, options = null, newFiltersData = null) {
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.189.113.91:5000';
    const PATH_CALL = `/acquisition_tabular_data/${this.props.location.state.id}/${
      this.state.table_tabular_group
    }`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    //this.setState({ updatingTable: true });
    this.setState(prevState => ({
      updatingTable: true,
      updatingChart: true
    }));
    let payload = {};
    if (options != null) payload = options;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    if (data.chartsData === undefined) data.chartsData = null;
    data.newFiltersData = newFiltersData;
    data.acquisitionData = null;
    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status != 200) {
          console.log('error', status);
        }
        return json;
      })
      .then(json => {
        data.acquisitionData = json;
        return data;
      });
  }

  updateAcquisitionSummary(data, options = null, newFiltersData = null) {
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.189.113.91:5000';
    const PATH_CALL = `/acquisition_summary/${this.props.location.state.id}`;

    const url = `${PATH_BASE}${PATH_CALL}`;

    //this.setState({ updatingChart: true });
    this.setState(prevState => ({
      updatingChart: true
    }));
    let payload = {};
    if (options != null) payload = options;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    if (data.newFiltersData === undefined) data.newFiltersData = newFiltersData;
    if (data.acquisitionData === undefined) data.acquisitionData = null;

    data.chartsData = null;
    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status != 200) {
          console.log('error', status);
        }
        return json;
      })
      .then(json => {
        data.chartsData = json;
        return data;
      });
  }
  //endregion

  chartChangeTableNavigation(selectedSource) {
    let newState = { ...this.state };
    newState.updatingTable = true;
    newState.table_tabular_group = 'campaign';
    newState.table_selected_source = selectedSource;
    this.setState(newState);
    setTimeout(this.handleApplyFilters, 0, 'all');
    document.getElementById('campaignsTable').scrollIntoView();
  }

  tableNavigationHandler(selectedItem, selectedTab) {
    if (this.state.table_tabular_group === 'source') {
      let newState = { ...this.state };

      newState.updatingTable = true;
      newState.table_tabular_group = selectedTab;
      newState.table_selected_source = selectedItem;

      this.setState(newState);
    } else if (this.state.table_tabular_group === 'campaign') {
      let newState = { ...this.state };

      newState.updatingTable = true;
      newState.table_tabular_group = selectedTab;
      newState.table_selected_campaign = selectedItem;

      this.setState(newState);
    } else if (this.state.table_tabular_group === 'adset') {
      let newState = { ...this.state };

      newState.updatingTable = true;
      newState.table_tabular_group = selectedTab;
      newState.table_selected_campaign = selectedItem;

      this.setState(newState);
    }

    setTimeout(this.handleApplyFilters, 0, 'all');
  }

  /** Checkbox Lists Methods */
  //region Checkbox Lists Method
  handleInputChange(items) {
    let updatedItems = items;

    //this.state.region_data[this.state.current_region_id] = updatedItems;
    this.handleRegionsChange(updatedItems, this.state.current_region_id);
  }
  handleFilterListChange(id, items) {
    let newState = { ...this.state };
    let searchedFilters = { ...newState.searched_filters };
    searchedFilters[id] = items;
    newState.searched_filters = searchedFilters;
    this.setState(newState, () => {
      this.checkCheckboxItemsState(id);
    });
  }
  checkCheckboxItemsState(attribute) {
    let trueCounter = 0;
    let falseCounter = 0;
    let totalLength = 0;
    if (
      this.state.searched_filters[attribute] !== '' &&
      this.state.searched_filters[attribute] != null
    ) {
      Object.entries(this.state.searched_filters[attribute]).map(([k, v]) => {
        if (v === true) {
          trueCounter += 1;
        } else if (v === false) {
          falseCounter += 1;
        }
        totalLength += 1;
      });
    } else {
      Object.entries(this.state[attribute]).map(([k, v]) => {
        if (v === true) {
          trueCounter += 1;
        } else if (v === false) {
          falseCounter += 1;
        }
        totalLength += 1;
      });
    }

    let newStateSelect = { ...this.state.checkboxes_select_state[attribute] };
    let allDiff = false;
    let allTrue = false;
    let allFalse = false;

    if (
      (trueCounter !== totalLength && trueCounter !== 0) ||
      (falseCounter !== totalLength && falseCounter !== 0)
    ) {
      allDiff = true;
    } else if (trueCounter === totalLength) {
      allTrue = true;
    } else if (falseCounter === totalLength) {
      allFalse = true;
    }
    newStateSelect.all_true = allTrue;
    newStateSelect.all_false = allFalse;
    newStateSelect.all_diff = allDiff;

    this.setState({
      checkboxes_select_state: Object.assign({}, this.state.checkboxes_select_state, {
        [attribute]: newStateSelect
      })
    });
    this.forceUpdate();
  }
  handleSelectDeselectAllCheckbox(attribute) {
    let setValue;
    let newState = { ...this.state };
    let changedItems = {};
    let allDiff;
    let allFalse;
    let allTrue;
    if (this.state.checkboxes_select_state[attribute].all_false) {
      setValue = true;
      allTrue = true;
      allFalse = false;
    } else if (this.state.checkboxes_select_state[attribute].all_diff) {
      setValue = false;
      allDiff = false;
      allFalse = true;
    } else if (this.state.checkboxes_select_state[attribute].all_true) {
      setValue = false;
      allTrue = false;
      allFalse = true;
    }
    if (
      this.state.searched_filters[attribute] !== '' &&
      this.state.searched_filters[attribute] != null
    ) {
      let filtered_items = [];
      Object.entries(this.state.searched_filters[attribute]).map(([k, v]) => {
        filtered_items[k] = setValue;
      });

      Object.entries(this.state[attribute]).map(([k, v]) => {
        changedItems[k] = v;
        if (filtered_items[k] != null) {
          changedItems[k] = setValue;
        }
      });

      newState[attribute] = changedItems;
      newState.searched_filters[attribute] = filtered_items;
    } else {
      Object.entries(this.state[attribute]).map(([k, v]) => {
        changedItems[k] = setValue;
      });
    }
    let changeState = {
      all_true: allTrue,
      all_false: allFalse,
      all_diff: allDiff
    };
    newState[attribute] = changedItems;
    newState.checkboxes_select_state[attribute] = changeState;
    newState.needUpdate = true;
    this.setState(newState);
    this.forceUpdate();
  }
  checkOneRegionItemsState(region) {
    let trueCounter = 0;
    let falseCounter = 0;
    let totalLength = 0;
    if (!this.state.searching_mode) {
      Object.entries(this.state.region_data[region]).map(([k, v]) => {
        if (v == true) {
          trueCounter += 1;
        } else if (v == false) {
          falseCounter += 1;
        }
        totalLength += 1;
      });
    }
    if (this.state.searching_mode) {
      Object.entries(this.state.searched_countries[region]).map(([k, v]) => {
        if (v == true) {
          trueCounter += 1;
        } else if (v == false) {
          falseCounter += 1;
        }
        totalLength += 1;
      });
    }

    let newStateRegions = { ...this.state.region_select_state[region] };
    let allDiff = false;
    let allTrue = false;
    let allFalse = false;
    if (
      (trueCounter !== totalLength && trueCounter !== 0) ||
      (falseCounter !== totalLength && falseCounter !== 0)
    ) {
      allDiff = true;
    } else if (trueCounter === totalLength) {
      allTrue = true;
    } else if (falseCounter === totalLength) {
      allFalse = true;
    }

    if (
      this.state.searching_mode &&
      Object.keys(this.state.searched_countries[region]).length == 0
    ) {
      allDiff = false;
      allTrue = false;
      allFalse = true;
    }
    newStateRegions.all_true = allTrue;
    newStateRegions.all_false = allFalse;
    newStateRegions.all_diff = allDiff;

    let item = {
      all_true: allTrue,
      all_false: allFalse,
      all_diff: allDiff
    };

    return item;
  }
  checkRegionItemsState() {
    let trueCounter = 0;
    let falseCounter = 0;
    let totalLength = 0;
    if (!this.state.searching_mode) {
      for (let region in this.state.region_data) {
        Object.entries(this.state.region_data[region]).map(([k, v]) => {
          if (v == true) {
            trueCounter += 1;
          } else if (v == false) {
            falseCounter += 1;
          }
          totalLength += 1;
        });
      }
    }
    if (this.state.searching_mode) {
      for (let region in this.state.region_data) {
        Object.entries(this.state.searched_countries[region]).map(([k, v]) => {
          if (v == true) {
            trueCounter += 1;
          } else if (v == false) {
            falseCounter += 1;
          }
          totalLength += 1;
        });
      }
    }
    let newState = { ...this.state };
    let allDiff = false;
    let allTrue = false;
    let allFalse = false;

    if (
      (trueCounter !== totalLength && trueCounter !== 0) ||
      (falseCounter !== totalLength && falseCounter !== 0)
    ) {
      allDiff = true;
    } else if (trueCounter === totalLength) {
      allTrue = true;
    } else if (falseCounter === totalLength) {
      allFalse = true;
    }
    newState.btn_state_all_true = allTrue;
    newState.btn_state_all_false = allFalse;
    newState.btn_state_diff = allDiff;
    this.setState(newState, () => {
      this.checkAllRegions();
    });
    this.forceUpdate();
  }
  selectDeselectOneRegion(region) {
    let setValue;
    let regionBtnState = { ...this.state.region_select_state[region] };
    let newState = { ...this.state };
    if (this.state.region_select_state[region].all_false) {
      setValue = true;
      regionBtnState.all_true = true;
      regionBtnState.all_false = false;
    } else if (this.state.region_select_state[region].all_diff) {
      setValue = false;
      regionBtnState.all_diff = false;
      regionBtnState.all_false = true;
    } else if (this.state.region_select_state[region].all_true) {
      setValue = false;
      regionBtnState.all_true = false;
      regionBtnState.all_false = true;
    }

    let changedItems = [];
    if (!this.state.searching_mode) {
      Object.entries(this.state.region_data[region]).map(([k, v]) => {
        changedItems[k] = setValue;
      });
    }

    if (this.state.searching_mode) {
      let searched_data = [];
      Object.entries(this.state.searched_countries[region]).map(([k, v]) => {
        searched_data[k] = setValue;
      });

      Object.entries(this.state.region_data[region]).map(([k, v]) => {
        changedItems[k] = v;
        if (changedItems[k] != searched_data[k] && searched_data[k] != null) {
          changedItems[k] = setValue;
        }
      });
      newState.searched_countries[region] = searched_data;
    }

    newState.region_data[region] = changedItems;
    newState.needUpdate = true;
    this.setState(newState, () => {
      this.checkRegionItemsState();
    });
  }
  handleAllSetTrueOrFalse() {
    //depends on value
    let setValue;
    let newState = { ...this.state };
    if (this.state.btn_state_all_false) {
      setValue = true;
      newState.btn_state_all_true = true;
      newState.btn_state_all_false = false;
    } else if (this.state.btn_state_diff) {
      setValue = false;
      newState.btn_state_diff = false;
      newState.btn_state_all_false = true;
    } else if (this.state.btn_state_all_true) {
      setValue = false;
      newState.btn_state_all_true = false;
      newState.btn_state_all_false = true;
    }

    let changedRegions = [];
    if (!this.state.searching_mode) {
      for (let region in this.state.region_data) {
        changedRegions[region] = [];
        Object.entries(this.state.region_data[region]).map(([key, value]) => {
          changedRegions[region][key] = setValue;
        });
      }
      newState.region_data = changedRegions;
    }
    if (this.state.searching_mode) {
      let searched_data = [];
      for (let region in this.state.searched_countries) {
        searched_data[region] = [];
        Object.entries(this.state.searched_countries[region]).map(([key, value]) => {
          searched_data[region][key] = setValue;
        });
      }

      let changedItems = [];
      for (let region in this.state.region_data) {
        changedItems[region] = [];
        Object.entries(this.state.region_data[region]).map(([key, value]) => {
          changedItems[region][key] = value;
          if (
            changedItems[region][key] != searched_data[region][key] &&
            searched_data[region][key] != null
          ) {
            changedItems[region][key] = setValue;
          }
        });
      }

      newState.searched_countries = searched_data;
      newState.region_data = changedItems;
    }

    newState.needUpdate = true;

    this.setState(newState, () => {
      this.checkAllRegions();
    });
    this.forceUpdate();
  }
  checkAllRegions() {
    let region_state = {};
    Object.entries(this.state.region_data).map(([k, v]) => {
      region_state[k] = this.checkOneRegionItemsState(k);
    });
    this.setState({ region_select_state: region_state });
  }
  handleCurrentClickedRegion(event) {
    const target = event.target;
    const value = target.textContent;
    let text_input = document.getElementsByClassName('countryText');
    // text_input[0].value = '';
    let newState = { ...this.state };
    newState.current_region_id = value;
    // newState.searchCountry = '';
    //  newState.searched_countries = '';
    // newState.searching_mode = false;
    this.setState(newState, () => {
      this.checkRegionItemsState();
    });
  }
  onSearchCountryChange(event) {
    const target = event.target;
    const value = target.value;
    let searchTerm = value;
    const isSearched = searchTerm => item =>
      !searchTerm || item.toLowerCase().includes(searchTerm.toLowerCase());

    this.setState({
      searchCountry: searchTerm
    });

    if (searchTerm !== '') {
      this.setState(
        {
          searching_mode: true
        },
        () => {
          this.checkRegionItemsState();
        }
      );
    } else if (searchTerm === '') {
      this.setState({ searching_mode: false }, () => {
        this.checkRegionItemsState();
      });
    }
    const items_filter = [];

    for (let v in this.state.region_data) {
      items_filter[v] = [];
      Object.keys(this.state.region_data[v])
        .filter(isSearched(searchTerm))
        .map(item => {
          items_filter[v][item] = this.state.region_data[v][item];
        });
      this.setState(
        {
          searched_countries: items_filter
        },
        () => {
          this.checkRegionItemsState();
        }
      );
    }
  }
  handleFilterChange(filterId, updatedItems) {
    let newState = { ...this.state };
    newState[filterId] = updatedItems;
    newState.needUpdate = true;

    this.setState(newState, () => {
      this.checkCheckboxItemsState(filterId);
    });
  }

  handleRegionsChange(updatedItems, region_id) {
    let newState = { ...this.state };
    let newRegionData = { ...newState.region_data };
    newRegionData[region_id] = { ...newRegionData[region_id], ...updatedItems };
    newState.region_data = newRegionData;
    newState.needUpdate = true;
    this.setState(newState, () => {
      this.checkRegionItemsState();
    });
  }
  handleSearchedFilterChange(filterId, updatedItems, attribute) {
    let newState = { ...this.state };
    if (filterId == 'searched_filters') {
      newState[filterId][attribute] = updatedItems;
    } else {
      newState[filterId] = updatedItems;
    }
    let new_attribute = [];
    Object.entries(this.state[attribute]).map(([key, value]) => {
      Object.entries(updatedItems).map(([k, v]) => {
        if (key == k && value != v) {
          new_attribute[key] = v;
        } else if (!new_attribute.hasOwnProperty(key)) {
          new_attribute[key] = value;
        }
      });
    });
    newState[attribute] = new_attribute;
    newState.needUpdate = true;
    this.setState(newState, () => {
      this.checkCheckboxItemsState(attribute);
    });
  }
  //endregion

  /** Filtering Table Methods */
  //region Filtering Table Methods
  handleSelectFilter(event, i) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let table_object = [...this.state.table_filters_list];

    table_object[i].name = name;
    this.setState({ table_filters_list: table_object });
  }

  handleSelectModifier(event, i) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let table_object = [...this.state.table_filters_list];
    table_object[i].modifier = name;
    this.setState({ table_filters_list: table_object });
  }

  handleSelectValue(event, i) {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    let table_object = [...this.state.table_filters_list];

    table_object[i].value = value;
    this.setState({ table_filters_list: table_object });
  }

  handleDeleteFilter(i) {
    let table_object = [...this.state.table_filters_list];
    table_object.splice(i, 1);
    this.setState({ table_filters_list: table_object });
  }
  checkedTableItemsList() {
    let checkedList = [];
    Object.entries(this.state.table_view_checkbox_items).map(([key, val]) => {
      Object.entries(val).map(([k, v]) => {
        if (v === true && k !== 'Revenue to date') {
          checkedList.push(k);
        }
      });
    });

    return checkedList;
  }

  handleSortTable() {
    let sort_state;
    if (this.state.sorting_button == 'asc') {
      sort_state = 'desc';
    } else if (this.state.sorting_button == 'desc') {
      sort_state = 'asc';
    }

    this.setState({ sorting_button: sort_state });
  }

  handleSelectSortTable(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ table_current_sort: name });
  }

  handleAddTableFilters(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let filter_object = { name: name, modifier: '>=', value: 0 };
    let tableState = this.state.table_filters_list;

    tableState.push(filter_object);

    this.setState({ table_filters_list: tableState });
    this.forceUpdate();
  }
  checkTableItemsState(tab) {
    let trueCounter = 0;
    let falseCounter = 0;
    let totalLength = 0;
    let new_current_sort;
    let newState = { ...this.state };
    Object.entries(this.state.table_view_checkbox_items[tab]).map(([key, val]) => {
      if (key == this.state.table_current_sort && val == false) {
        new_current_sort = 'Amount spend';
        newState.table_current_sort = new_current_sort;
      }
      if (val == true) {
        trueCounter += 1;
      } else if (val == false) {
        falseCounter += 1;
      }
      totalLength += 1;
    });

    let allDiff = false;
    let allTrue = false;
    let allFalse = false;
    if (
      (trueCounter !== totalLength && trueCounter !== 0) ||
      (falseCounter !== totalLength && falseCounter !== 0)
    ) {
      allDiff = true;
    } else if (trueCounter === totalLength) {
      allTrue = true;
    } else if (falseCounter === totalLength) {
      allFalse = true;
    }

    newState.table_select_state[tab].all_true = allTrue;
    newState.table_select_state[tab].all_false = allFalse;
    newState.table_select_state[tab].all_diff = allDiff;

    this.setState(newState);
    this.forceUpdate();
  }

  handleSelectDeselectTable(tab) {
    let setValue;
    let newState = { ...this.state };
    let changedItems = {};
    let allDiff;
    let allFalse;
    let allTrue;
    if (this.state.table_select_state[tab].all_false) {
      setValue = true;
      /*   newState.table_select_state[tab].all_true=true;
              newState.table_select_state[tab].all_false=false;*/
      allTrue = true;
      allFalse = false;
    } else if (this.state.table_select_state[tab].all_diff) {
      setValue = false;
      /*   newState.table_select_state[tab].all_diff = false;
                newState.table_select_state[tab].all_false = true;*/
      allDiff = false;
      allFalse = true;
    } else if (this.state.table_select_state[tab].all_true) {
      setValue = false;
      /*  newState.table_select_state[tab].all_true=false;
              newState.table_select_state[tab].all_false=true;*/
      allTrue = false;
      allFalse = true;
    }
    let new_sort_table = newState.table_current_sort;
    Object.entries(this.state.table_view_checkbox_items[tab]).map(([k, v]) => {
      changedItems[k] = setValue;
      if (k == this.state.table_current_sort && setValue == false) {
        new_sort_table = 'Amount spend';
        newState.table_current_sort = new_sort_table;
      }
    });

    let changeState = {
      all_true: allTrue,
      all_false: allFalse,
      all_diff: allDiff
    };
    newState.table_view_checkbox_items[tab] = changedItems;

    let table_object = [...this.state.table_filters_list];
    Object.entries(changedItems).map(([key, val]) => {
      Object.entries(table_object).map(([k, v]) => {
        if (key === v.name && val === false) {
          table_object.splice(k, 1);
        }
      });
    });

    this.setState({
      table_view_checkbox_items: Object.assign({}, this.state.table_view_checkbox_items, {
        [tab]: changedItems
      }),
      table_select_state: Object.assign({}, this.state.table_select_state, {
        [tab]: changeState
      }),
      table_filters_list: table_object,
      table_current_sort: new_sort_table
    });
    // this.forceUpdate();
  }
  //endregion

  handleApplyFilters(mode = 'all') {
    let data = {};
    let campaigns = [];
    let platforms = [];
    let countries = [];
    let sources = [];
    let chart_sources = [];
    let chart_campaigns = [];
    let newState = { ...this.state };
    let newFiltersData = { ...newState.filtersData };

    newFiltersData.platforms = this.state.platforms;
    newFiltersData.sources = this.state.sources;
    newFiltersData.campaigns = this.state.campaigns;
    newFiltersData.region_data = this.state.region_data;
    // this.state.table_tabular_group
    // this.state.table_selected_sourcet
    // this.state.table_selected_campaign

    Object.entries(this.state.campaigns).map(([key, value]) => {
      if (value === true) chart_campaigns.push(key);
    });

    Object.entries(this.state.sources).map(([key, value]) => {
      if (value === true) chart_sources.push(key);
    });

    if (this.state.table_tabular_group === 'source') {
      //source|campaign|adset
      Object.entries(this.state.campaigns).map(([key, value]) => {
        if (value === true) campaigns.push(key);
      });

      Object.entries(this.state.sources).map(([key, value]) => {
        if (value === true) sources.push(key);
      });
    } else if (this.state.table_tabular_group === 'campaign') {
      //source|campaign|adset
      sources.push(this.state.table_selected_source);

      Object.entries(this.state.campaigns).map(([key, value]) => {
        if (value === true) campaigns.push(key);
      });
    } else if (this.state.table_tabular_group === 'adset') {
      //source|campaign|adset
      sources.push(this.state.table_selected_source);
      campaigns.push(this.state.table_selected_campaign);
    }

    Object.entries(this.state.region_data).map(([key, value]) => {
      Object.entries(value).map(([key, value]) => {
        if (value === true) countries.push(key);
      });
    });

    Object.entries(this.state.platforms).map(([key, value]) => {
      if (value === true) platforms.push(key);
    });

    let optionsTable = {
      date_start: this.state.chartDatesFrom.format('DD-MM-YYYY'),
      date_end: this.state.chartDatesTo.format('DD-MM-YYYY'),
      granulation: this.state.chart_frequency_active_button.toLowerCase(),
      campaigns: campaigns,
      platforms: platforms,
      countries: countries,
      sources: sources
    };

    let optionsChart = {
      date_start: this.state.chartDatesFrom.format('DD-MM-YYYY'),
      date_end: this.state.chartDatesTo.format('DD-MM-YYYY'),
      granulation: this.state.chart_frequency_active_button.toLowerCase(),
      campaigns: chart_campaigns,
      platforms: platforms,
      countries: countries,
      sources: chart_sources
    };

    if (mode == 'all') {
      this.updateAcquisitionData(data, optionsTable, newFiltersData)
        .then(data => this.updateAcquisitionSummary(data, optionsChart))
        .then(data => this.onUpdateDataReceived(data));
    } else if (mode === 'table') {
      this.updateAcquisitionData(data, optionsTable, newFiltersData).then(data =>
        this.onUpdateDataReceived(data)
      );
    } else if (mode === 'chart') {
      this.updateAcquisitionSummary(data, optionsChart, newFiltersData).then(data =>
        this.onUpdateDataReceived(data)
      );
    }
  }

  handleChartOptionButton(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let active = value;

    if (value == this.state.chart_option_active_button) {
      this.state.chart_options.forEach(function(item) {
        if (value != item) {
          active = item;
        }
      });
    }
    let newState = { ...this.state };
    newState.chart_option_active_button = active;
    this.setState(newState);
  }

  handleChartFrequencyButton(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const newState = { ...this.state };
    newState.chart_frequency_active_button = value;
    newState.needUpdate = false;
    this.setState(newState);

    setTimeout(this.handleApplyFilters, 0, 'all');
  }

  handleRecordsChange(event) {
    const target = event.target;
    const value = target.value;
    let newState = { ...this.state };
    newState.table_show_records = value;
    this.setState(newState);
  }

  handleShowTableItems(event, tab) {
    const target = event.target;

    const value = target.value;
    const name = target.name;
    //let updatedItems = this.state.table_view_checkbox_items;
    let left = 0;

    {
      Object.entries(this.state.table_view_checkbox_items).map(([key, val]) => {
        Object.entries(val).map(([k, v]) => {
          if (v === true) left += 1;
        });
      });
    }
    //  if (left <= 1 && this.state.table_view_checkbox_items[tab][value] === true) return;
    //updatedItems[value] = target.checked;
    let newState = { ...this.state };
    let new_table_view_checkbox_items = {
      ...newState.table_view_checkbox_items[tab],
      [value]: target.checked
    };
    let table_object = [...this.state.table_filters_list];
    Object.entries(table_object).map(([k, v]) => {
      if (v.name === value && target.checked === false) {
        this.handleDeleteFilter(k);
      }
    });

    newState.table_view_checkbox_items[tab] = new_table_view_checkbox_items;
    this.setState(
      {
        table_view_checkbox_items: Object.assign({}, this.state.table_view_checkbox_items, {
          [tab]: new_table_view_checkbox_items
        })
      },
      () => {
        this.checkTableItemsState(tab);
      }
    );
  }

  render() {
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }
    if (!this.state.acquisitionData) {
      return (
        <div className="fetchDataerrorMessage">
          <h3>Oops!</h3>
          <p>
            You don't have permissions to see this view or there is no data available at the moment.
            Please try Again!
          </p>
        </div>
      );
    }
    /** Filter Lists for Table*/
    //region Filter Lists
    let selected_list = this.checkedTableItemsList();
    selected_list.push('Amount spend');
    let filter_list = this.checkedTableItemsList();
    let button_sort;
    if (this.state.sorting_button == 'asc') {
      button_sort = <ButtonSort onClick={this.handleSortTable}>descending &#9662;</ButtonSort>;
    } else if (this.state.sorting_button == 'desc') {
      button_sort = (
        <ButtonSort>
          <span onClick={this.handleSortTable}>ascending &#9652;</span>
        </ButtonSort>
      );
    }
    let modifiers = ['>=', '<'];
    let list = [];

    Object.entries(this.state.table_filters_list).map(([k, v]) => {
      list.push(
        <FilterItem key={k + v + 'name'}>
          <FilterName key={k + 'name'}>
            <DropdownList
              items={filter_list}
              currentOpt={this.state.table_filters_list[k].name}
              uppercase={false}
              handler={this.handleSelectFilter}
              param={k}
              name={'uaFilterListUpdate' + k}
            />
          </FilterName>
          <FilterModifier key={k + 'modify'}>
            <DropdownList
              items={modifiers}
              currentOpt={this.state.table_filters_list[k].modifier}
              uppercase={false}
              name={'uaModifierListUpdate' + k}
              param={k}
              handler={this.handleSelectModifier}
            />
          </FilterModifier>
          <FilterValue key={k + 'uaValueListUpdate'}>
            <Textfield
              type="number"
              placeholder={this.state.table_filters_list[k].value}
              value={this.state.table_filters_list[k].value}
              onChange={e => {
                this.handleSelectValue(e, k);
              }}
              displayLabel={false}
            />
          </FilterValue>
          <DeleteFilter
            key={'uaFilterClose' + k}
            src={close_icon}
            onClick={() => this.handleDeleteFilter(k)}
          />
        </FilterItem>
      );
    });
    //endregion

    return (
      <AnimatedView>
        <GameDetailWrapper key="AcqGameDetailsKey">
          <GameDetailTitle key="GameDetailTitleKey">
            <GameDetailIcon key="GameDetailIconKey" src={this.props.location.state.icon} />
            {this.props.location.state.name}
          </GameDetailTitle>
          <GameDetailContainer key="GameDetailContainerKEy">
            <ChartOptions key="ChartOptionsKey">
              <OptionButtons
                key="OptionButtonsKey"
                items={this.state.chart_options}
                active_button={this.state.chart_option_active_button}
                handleChartOptionButton={this.handleChartOptionButton}
              />
            </ChartOptions>
            <ChartPanel key="ChartPanelKey">
              <ChartDataFrequency>
                <FrequencyButtons
                  items={this.state.chart_frequency_options}
                  active_button={this.state.chart_frequency_active_button}
                  handleFrequencyButton={this.handleChartFrequencyButton}
                  active={!this.state.updatingTable && !this.state.updatingChart}
                />
              </ChartDataFrequency>
              <ChartDataPicker key="ChartDataPickerKey">
                <CustomDatePicker
                  from={this.state.chartDatesFrom}
                  to={this.state.chartDatesTo}
                  handleUpdate={this.updateCustomDataRangeChart}
                  name="uachart"
                  active={!this.state.updatingTable && !this.state.updatingChart}
                />
              </ChartDataPicker>
            </ChartPanel>
            <ChartDisplay>
              {this.state.updatingChart ? (
                <SimpleIndicator />
              ) : (
                <UserAcquisitionChart
                  active_option={this.state.chart_option_active_button}
                  data={this.state.chartData}
                  tableChange={this.chartChangeTableNavigation}
                />
              )}
            </ChartDisplay>
            <FilterButton>
              <Button
                onClick={() => {
                  this.setState(prevState => ({
                    needUpdate: false
                  }));
                  this.handleApplyFilters('all');
                }}
                needUpdate={this.state.needUpdate}
                active={!this.state.updatingTable && !this.state.updatingChart}
              >
                APPLY FILTERS
              </Button>
            </FilterButton>
            <Filtering>
              <SourceBox>
                <CheckboxPanel
                  name="Attribution source"
                  id="sources"
                  items={this.state.sources}
                  changeHandler={this.handleFilterChange}
                  changeSearchHandler={this.handleSearchedFilterChange}
                  handleListChange={this.handleFilterListChange}
                  searched_data={this.state.searched_filters.sources}
                  button_state={this.state.checkboxes_select_state.sources}
                  selectHandler={this.handleSelectDeselectAllCheckbox}
                  checkboxWidth={143}
                />
              </SourceBox>
              <PlatformsBox>
                <CheckboxPanel
                  name="Platform"
                  id="platforms"
                  items={this.state.platforms}
                  changeHandler={this.handleFilterChange}
                  changeSearchHandler={this.handleSearchedFilterChange}
                  handleListChange={this.handleFilterListChange}
                  searched_data={this.state.searched_filters.platforms}
                  button_state={this.state.checkboxes_select_state.platforms}
                  selectHandler={this.handleSelectDeselectAllCheckbox}
                  checkboxWidth={140}
                />
              </PlatformsBox>
              <CampaignBox>
                <CheckboxPanel
                  name="Campaign"
                  id="campaigns"
                  items={this.state.campaigns}
                  changeHandler={this.handleFilterChange}
                  changeSearchHandler={this.handleSearchedFilterChange}
                  handleListChange={this.handleFilterListChange}
                  searched_data={this.state.searched_filters.campaigns}
                  button_state={this.state.checkboxes_select_state.campaigns}
                  selectHandler={this.handleSelectDeselectAllCheckbox}
                  checkboxWidth={212}
                />
              </CampaignBox>

              <RegionBox>
                <RegionCheckboxPanel
                  name="Region"
                  id="region_data"
                  items={this.state.region_data}
                  searchHandler={this.onSearchCountryChange}
                  clickedRegionHandler={this.handleCurrentClickedRegion}
                  searching_mode={this.state.searching_mode}
                  current_region_id={this.state.current_region_id}
                  searched_countries={this.state.searched_countries}
                  handleInputChange={this.handleInputChange}
                  changeHandler={this.handleRegionsChange}
                  selectDeselectAll={this.handleAllSetTrueOrFalse}
                  allTrue={this.state.btn_state_all_true}
                  allFalse={this.state.btn_state_all_false}
                  allDiff={this.state.btn_state_diff}
                  buttonStateAll={this.state.region_select_state}
                  selectOneRegion={this.selectDeselectOneRegion}
                />
              </RegionBox>
            </Filtering>

            <TableFlitering>
              <TableCheckboxes>
                <ItemsToCheck
                  key={'itemsToCheckKey'}
                  items={this.state.table_view_checkbox_items}
                  handleCheck={this.handleShowTableItems}
                  buttonState={this.state.table_select_state}
                  handleSelect={this.handleSelectDeselectTable}
                />
              </TableCheckboxes>
              <TableDropdowns>
                <TableSort>
                  <SortByText>Sort by :</SortByText>
                  <DropdownList
                    items={selected_list}
                    currentOpt={this.state.table_current_sort}
                    name={'uasort'}
                    handler={this.handleSelectSortTable}
                    uppercase={false}
                  />
                  {button_sort}
                </TableSort>
                <TableFiltering>
                  {list}
                  <PickFilter>
                    {' '}
                    <DropdownList
                      items={filter_list}
                      name={'uafiltermain'}
                      uppercase={false}
                      placeholder={'Add a filter...'}
                      handler={this.handleAddTableFilters}
                    />
                  </PickFilter>
                </TableFiltering>
              </TableDropdowns>

              {this.state.updatingTable ? (
                <TableLoadIndicator id="campaignsTable">
                  <SimpleIndicator />
                </TableLoadIndicator>
              ) : (
                <CampaignsTable
                  id="campaignsTable"
                  filtersData={this.state.filtersData}
                  acquisitionData={this.state.acquisitionData}
                  checkboxItems={this.state.table_view_checkbox_items}
                  tabularGroup={this.state.table_tabular_group}
                  selectedSource={this.state.table_selected_source}
                  selectedCampaign={this.state.table_selected_campaign}
                  tableNavigationHandler={this.tableNavigationHandler}
                  filters={this.state.table_filters_list}
                  showRecords={this.state.table_show_records}
                  currentSort={this.state.table_current_sort}
                  sorting={this.state.sorting_button}
                />
              )}

              <SaveDataButton
                onClick={() => {
                  this.downloadTableDataHandler();
                }}
                active={
                  !this.state.isDownloadindData &&
                  !this.state.updatingTable &&
                  !this.state.updatingChart
                }
                needUpdate={true}
              >
                Save Data
              </SaveDataButton>
            </TableFlitering>
          </GameDetailContainer>
        </GameDetailWrapper>
      </AnimatedView>
    );
  }
}

export default GameDetail;
/** Game Detail Styles */
//region Styles
const GameDetailWrapper = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 150px;
  background-color: #2f3238;
  box-sizing: border-box;
  overflow: auto;
`;

const GameDetailTitle = styled.h2`
  width: 90%;
  color: #fff;
  padding: 0rem 0rem 2.5rem;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #636975;
  padding-top: 1rem;
  margin-right: 5rem;
  margin-left: 3.5rem;
  margin-bottom: 1rem;
`;

const GameDetailIcon = styled.img`
  height: 5rem;
  width: 5rem;
  border-radius: 10px;
  margin-top: -1rem;
  margin-right: 1rem;
  margin-left: 0rem;
  margin-bottom: -2rem;
`;

const GameDetailContainer = styled.div`
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 7rem 78rem 80rem 1rem;
  grid-column-gap: 50px;
  grid-row-gap: 10px;
`;

const ChartPanel = styled.div`
  grid-column: 2;
  grid-row: 2;
  overflow: auto;
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: 37.5rem 40rem;
`;

const ChartOptions = styled.div`
  grid-column: 1/3;
  grid-row: 1;
  padding-left: 380px;
`;

const ChartDataFrequency = styled.div`
  grid-column: 1/2;
  grid-row: 1;
  position: relative;
`;

const ChartDataPicker = styled.div`
  grid-column: 2 / span 6;
  grid-row: 1;
`;

const ChartDisplay = styled(CheckboxContainer)`
  grid-column: 2/3;
  grid-row: 3;
  min-height: 700px;
  height: auto;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 1px;
  padding-bottom: 10px;
`;

const Filtering = styled.div`
  grid-column: 3/4;
  grid-row: 3/4;
  display: grid;
  grid-template-columns: 23rem 23rem 30rem;
  grid-gap: 20px;
  grid-row-gap: 9px;
`;

const SourceBox = styled.div.attrs({ className: 'source' })`
  grid-column: 1/2;
  grid-row: 1;
`;

const PlatformsBox = styled.div.attrs({ className: 'platform' })`
  grid-column: 2/3;
  grid-row: 1;
`;

const CampaignBox = styled.div.attrs({ className: 'campaign' })`
  grid-column: 3;
  grid-row: 1;
`;

const RegionBox = styled.div`
  grid-column: 1/4;
  grid-row: 3;
`;

const TableFlitering = styled.div`
  padding-top: 40px;
  grid-column: 2/4;
  display: grid;
  grid-template-columns: 75rem 50rem;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

const TableCheckboxes = styled.div`
  grid-column: 1/2;
  grid-row: 1;
`;

const TableDropdowns = styled.div`
  grid-column: 2/3;
  grid-row: 1;
  display: grid;
  grid-template-columns: 35rem 20rem;
  grid-column-gap: 10px;
`;

const TableSort = styled.div`
  grid-column: 1/2;
  grid-row: 1;
  display: grid;
  grid-template-columns: 5rem 15rem 11rem;
  grid-column-gap: 9px;
  &:nth-child(1) {
    color: #99999c;
    font-size: 12px;
    grid-column: 1/2;
  }

  &:nth-child(2) {
    grid-column: 2/3;
  }

  &:nth-child(3) {
    grid-column: 3/4;
  }
`;

const TableFiltering = styled.div`
  grid-column: 2/3;
  grid-row: 1;
`;

const FilterItem = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 20rem 7rem 9rem 2rem;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  grid-template-rows: 30px 30px 30px 30px 30px;
  height: 40px;
`;

const FilterName = styled.div`
  grid-column: 1/2;
`;

const FilterModifier = styled.div`
  grid-column: 2/3;
`;

const FilterValue = styled.div`
  grid-column: 3/4;
`;

const DeleteFilter = styled.img`
  grid-column: 4/5;
  cursor: pointer;
  padding-top: 8px;
`;

const PickFilter = styled.div`
  grid-column: 1/2;
`;

const SortByText = styled.div`
  padding-top: 6px;
`;

const ButtonSort = styled.span`
  height: 30px;
  padding-top: 6px;
  color: #04d5ac;
  font-size: 12px;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  letter-spacing: 0.18em;
`;

const FilterButton = styled.div`
  text-align: right;
  grid-column: 3/4;
  grid-row: 2;
`;

const Button = styled.button`
  width: 180px;
  height: 30px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(30, 32, 34);
  border-radius: 3px;
  font-size: 13px;
  font-family: 'Roboto';
  color: rgb(255, 255, 255);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  text-align: center;
  background-color: #555555;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.36), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
  cursor: ${props => (props.needUpdate && props.active ? 'pointer' : 'initial')};
  background-image: ${props =>
    props.needUpdate && props.active
      ? '-webkit-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%)'
      : '-webkit-linear-gradient(90deg, rgb(133,133,133) 0%, rgb(214,214,214) 100%)'};
  &:hover {
    background-image: ${props =>
      props.needUpdate && props.active
        ? '-webkit-linear-gradient(90deg, rgb(23, 132, 113) 0%, rgb(0, 213, 169) 100%)'
        : '-webkit-linear-gradient( 90deg, rgb(113,113,113) 0%, rgb(149,149,149) 100%)'};
  }
  pointer-events: ${props => (props.needUpdate && props.active ? 'auto' : 'none')};

  &:focus {
    outline: none;
  }
`;

const TableLoadIndicator = styled.div`
  margin-top: 45px;
  overflow: hidden;
  grid-column: 1/5;
  grid-row: 2;
  width: 100%;
  height: 500px;
`;

const ShowAndHidePanel = styled(CheckboxContainer)`
  height: auto;
  padding-bottom: 32px;
`;

const ShowHideTitle = styled(PanelTitle)`
  margin-bottom: 5px;
`;

const ShowAndHideListCont = styled.div`
  display: grid;
  grid-template-columns: 22rem 22rem 22rem;
  grid-column-gap: 2.5rem;
  width: 100%;

  &:nth-child(1) {
    grid-column: 1/2;
  }

  &:nth-child(2) {
    grid-column: 2/3;
  }

  &:nth-child(3) {
    grid-column: 3/4;
  }
`;

const ItemsTabPanel = styled.div`
  margin: 0px 12px;
  position: relative;
`;

const ItemsTabTitle = styled.h1`
  font-size: 12px;
  font-weight: lighter;
  letter-spacing: 0.05rem;
  padding-left: 10px;
`;

const SelectDeselectPlaceholder = styled.div`
  position: absolute;
  top: 30px;
  right: -5px;
`;

const OptionButtonPanel = styled.div`
  margin: 0px;
  width: 262px;
  height: 32px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(30, 32, 34);
  border-radius: 3px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  padding: 0px;
`;

const OptionBtn = styled.button`
  cursor: pointer;
  padding: 0px;
  width: 130px;
  height: 30px;
  border: 0px none;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
  text-transform: capitalize;
  text-align: center;
  background-color: rgb(30, 32, 34);
  float: left;
  outline-style: none;
`;

const CurrentOptionBtn = styled(OptionBtn)`
  border-radius: 3px;
  color: #ffffff;
  background-image: -moz-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.36), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
`;

const FrequencyBtn = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.075em;
  color: #99999c;
  float: left;
  display: block;
  padding: 5px 20px;
  height: 30px;
  outline-style: none;
  border-right: 1px solid #363940;
  pointer-events: ${props => (props.active ? 'auto' : 'none')};
`;

const FrequencyCurrent = styled(FrequencyBtn)`
  color: #00d5a9;
`;

const FrequencyContainer = styled.div`
  overflow: auto;
  display: block;
  > :nth-child(1) {
    ${FrequencyBtn} {
      padding-left: 1rem;
    }
    ${FrequencyCurrent} {
      padding-left: 1rem;
    }
  }
  > :nth-child(3) {
    ${FrequencyBtn} {
      border: none;
    }
    ${FrequencyCurrent} {
      border: none;
    }
  }
`;

const SaveDataButton = styled(Button)`
  grid-column: 3/5;
  grid-row: 3;
  justify-self: end;
`;

//endregion
