import React, { Component, Fragment } from 'react';
import { AppContext } from 'views/App';
import styled from 'styled-components';
import { Content } from 'views/App';
import posed, { PoseGroup } from 'react-pose';
import storage from 'utils/storage';

class GameIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offsetX: 0,
      offsetY: 0,
      showTooltip: false
    };
    this.repositionTooltip = this.repositionTooltip.bind(this);
    this.tooltipRef = React.createRef();
  }
  /**Admin Lifecycle Methods*/
  //region  Admin Lifecycle Methods

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this.mounted = false;
  }
  //endregion

  repositionTooltip() {
    let rect = this.tooltipRef.current.getBoundingClientRect();
    if (rect.width + rect.x > window.innerWidth) {
      this.setState({ offsetX: rect.width + rect.x - window.innerWidth + 50, showTooltip: true });
    }
  }

  render() {
    let colorsTable = ['#009432', '#f79f1f', '#ea2027'];
    let showToolTip =
      storage.GAMES_DATA[this.props.slug] && storage.GAMES_DATA[this.props.slug].alerts.length > 0
        ? true
        : false;
    return (
      <Container size={this.props.size} margin={this.props.margin}>
        <Image src={this.props.src} size={this.props.size} />
        <Click
          size={this.props.indicatorSize}
          onMouseEnter={() => {
            this.repositionTooltip();
          }}
          onMouseLeave={() => {}}
        >
          <Indicator size={this.props.indicatorSize} preenterpose={'initial'} type={'outer'} />
          <Indicator
            size={this.props.indicatorSize - 6}
            preenterpose={'initial'}
            color={this.props.color}
            type={'inner'}
          />
          <Tooltip
            ref={this.tooltipRef}
            offsetX={this.state.offsetX}
            left={0}
            config={this.props.tooltip}
            showToolTip={true}
          >
            {!showToolTip ? (
              <Alert key={'healthy-data-key'}>
                <CircleCont>
                  <Circle color={colorsTable[0]} />
                </CircleCont>
                {'Data is healthy'}
              </Alert>
            ) : null}
            {storage.GAMES_DATA[this.props.slug]
              ? storage.GAMES_DATA[this.props.slug].alerts.map((item, index, arr) => {
                  const separator = index === arr.length - 1 ? false : true;
                  return (
                    <Alert key={item.id}>
                      <CircleCont>
                        <Circle color={colorsTable[item.level]} />
                      </CircleCont>
                      <Comment separator={separator}>{item.comment}</Comment>
                    </Alert>
                  );
                })
              : null}
          </Tooltip>
        </Click>
      </Container>
    );
  }
  //endregion
}
export default GameIcon;

/**Animated View Styles*/
//region  Styles

const Alert = styled.div`
  display: flex;
  text-align: left;
  height: 40px;
  font-size: 11px;
  line-height: 40px;
  letter-spacing: 0.05px;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-items: center;
`;

const CircleCont = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  width: 7px;
  height: 40px;
  margin-left: 10px;
  margin-right: 8px;
  padding-bottom: 1px;
`;

const Circle = styled.div`
  width: 7px;
  height: 7px;

  border-radius: 50%;
  background-color: ${props => props.color};
`;
const Comment = styled.div`
  width: 100%;
  height: 40px;
  border-bottom: ${props => (props.separator ? '1px solid #505561' : 'none')};
`;

const Image = styled.img`
  width: ${props => props.size + 'px'};
  height: ${props => props.size + 'px'};
  border-radius: ${props => Math.ceil(props.size / 5) + 'px'};
  position: absolute;
  top: 0;
  left: 0;
`;

const Indicator = styled(
  posed.div({
    enter: {
      scale: 1,
      opacity: 1,
      delay: 500,
      transition: { duration: 400 }
    },
    exit: { opacity: 0.5, transition: { duration: 100 } },
    initial: { scale: 0, opacity: 0 }
  })
)`
  width: ${props => (props.size ? props.size + 'px' : '2rem')};
  height: ${props => (props.size ? props.size + 'px' : '2rem')};
  background-color: ${props => (props.color ? props.color : '#2f3238')};
  border-radius: 50%;
  position: absolute;
  bottom: ${props => (props.type === 'inner' ? 2 + 'px' : '-1px')};
  right: ${props => (props.type === 'inner' ? 2 + 'px' : '-1px')};
  margin: 0;
  padding: 0;
`;
// dsds: ${props => (props.left ? `${props.left}px` : '0px')};
// transform: translate(100%, 0%);
const Tooltip = styled.div`
  position: absolute;
  background-color: #40444c;
  border-radius: 3px;
  pointer-events: none;
  z-index: 200;
  font-family: 'Roboto', sans-serif;
  color: #e1e1e5;
  font-size: 1rem;
  height: auto;
  width: auto;
  max-width: 450px;
  box-shadow: 0px 1px 2px 1px rgb(42, 42, 42);
  letter-spacing: 0.05rem;
  padding-left: 0px;
  padding-right: 18px;
  //padding-top: 4px !important;
  //padding-bottom: 10px !important;
  display: ${props => (props.showToolTip ? 'initial' : 'none')};

  top: ${props => (props.config.top ? props.config.top + 'px' : 'initial')};

   left:${props =>
     props.config.left
       ? 'calc(' + props.config.left + 'px' + ' - ' + props.offsetX + 'px' + ')'
       : 'initial'};
  bottom: ${props => (props.config.bottom ? props.config.bottom + 'px' : 'initial')};
  right: ${props => (props.config.right ? props.config.right + 'px' : 'initial')};
 transform: ${props =>
   props.config.origin
     ? 'translate(' + props.config.origin.x + ',' + props.config.origin.y + ')'
     : 'initial'};

}
 

  &:after,
  &:before {
    top: ${props => (props.config.arrow.top ? props.config.arrow.top : 'initial')};
    left: ${props =>
      props.config.arrow.left
        ? 'calc(' + props.config.arrow.left + ' + ' + props.offsetX + 'px' + ')'
        : 'initial'};
    bottom: ${props => (props.config.arrow.bottom ? props.config.arrow.bottom : 'initial')};
    right: ${props => (props.config.arrow.right ? props.config.arrow.right : 'initial')};
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: ${props =>
      props.config.orientation === 'bottom' ? '#40444c' : 'rgba(136, 183, 213, 0)'};
    border-left-color: ${props =>
      props.config.orientation === 'right' ? '#40444c' : 'rgba(136, 183, 213, 0)'};
    border-bottom-color: ${props =>
      props.config.orientation === 'top' ? '#40444c' : 'rgba(136, 183, 213, 0)'};
    border-right-color: ${props =>
      props.config.orientation === 'left' ? '#40444c' : 'rgba(136, 183, 213, 0)'};
    border-width: 7.5px;
  }
  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: ${props =>
      props.config.orientation === 'bottom' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(136, 183, 213, 0)'};
    border-left-color: ${props =>
      props.config.orientation === 'right' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(136, 183, 213, 0)'};
    border-bottom-color: ${props =>
      props.config.orientation === 'top' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(136, 183, 213, 0)'};
    border-right-color: ${props =>
      props.config.orientation === 'left' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(136, 183, 213, 0)'};
    border-width: 8px;
  }
  //transform-origin: top left;
  //transform: scale(0.4);

  transition: all 0.3s ease-in-out;

  opacity: 0; //0
`;

const Click = styled.div`
  width: 30px;
  height: 30px;
  bottom: 0;
  right: 0;
  position: absolute;
  width: ${props => (props.size ? props.size + 'px' : '2rem')};
  height: ${props => (props.size ? props.size + 'px' : '2rem')};

  &:hover ${Tooltip} {
    //transform: scale(1);
    opacity: 1;
  }
`;

const Container = styled.div`
  width: ${props => (props.size ? props.size + 'px' : '6rem')};
  height: ${props => (props.size ? props.size + 'px' : '6rem')};
  margin: ${props => (props.margin ? props.margin : '0')};
  padding: 0;
  position: relative;
`;

//endregion
