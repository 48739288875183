import React, { Component, Fragment } from 'react';
import CommonButton, { Button } from 'components/CommonButton/index';
import FilterList from 'components/FilterList';
import Textfield from 'components/Textfield';
import add_btn from '../assets/add_btn.png';
import styled from 'styled-components';
import { Content } from 'views/App';
import edit_big from '../assets/edit_big.png';
import x_red from '../assets/x_red.png';
import delete_btn from '../assets/delete_btn.png';
import LoadingIndicator from 'components/LoadingIndicator';
import storage from 'utils/storage';
import PopupDelete from '../PopupDelete';

class GmsEditData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      dataName: '',
      newDataName: '',
      editNameMode: false,
      dataID: '',
      savedDataID: '',
      gameParameterTable: [],
      gameCopyTable: [],
      configurationProfileTable: [],
      configCopyTable: [],
      newGameParameter: '',
      newConfigurationParameter: '',
      openDeletePopup: false,
      dataSlugToDelete: '',
      savedGameParameter: [],
      savedConfigParams: [],
      removedGameParams: [],
      removedConfigParams: [],

      updateDeleteBtn: false,
      needSave: true,
      isTheSame: false,
      isTheSameConfig: false
    };
    this.mounted = false;
    this.setEditDataNameMode = this.setEditDataNameMode.bind(this);
    this.setNewDataName = this.setNewDataName.bind(this);
    this.setDataID = this.setDataID.bind(this);
    this.addGameParameter = this.addGameParameter.bind(this);
    this.addConfigurationParameter = this.addConfigurationParameter.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.deleteDataSource = this.deleteDataSource.bind(this);
    this.openCloseEditGameParameter = this.openCloseEditGameParameter.bind(this);
    this.confirmEditGame = this.confirmEditGame.bind(this);
    this.cancelEditGame = this.cancelEditGame.bind(this);
    this.confirmEditConfig = this.confirmEditConfig.bind(this);
    this.cancelEditConfig = this.cancelEditConfig.bind(this);
    this.openCloseEditConfiguration = this.openCloseEditConfiguration.bind(this);
    this.deleteGameParameterItem = this.deleteGameParameterItem.bind(this);
    this.deleteConfigParameterItem = this.deleteConfigParameterItem.bind(this);
    this.editGameParameterValue = this.editGameParameterValue.bind(this);
    this.editConfigParameterValue = this.editConfigParameterValue.bind(this);
    this.setGameParameter = this.setGameParameter.bind(this);
    this.setConfigurationProfile = this.setConfigurationProfile.bind(this);
    this.noDeleteDataBtn = this.noDeleteDataBtn.bind(this);
    this.handleDataSlugDelete = this.handleDataSlugDelete.bind(this);
    this.handleDeleteBtn = this.handleDeleteBtn.bind(this);
    this.isTheSame = this.isTheSame.bind(this);
    this.isTheSameConfig = this.isTheSameConfig.bind(this);
  }
  /** Mounting component, Fetching and parsing data*/
  //region Fetching and parsing data
  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.privilegesUpdated) this.fetchData();
    else
      this.setState({
        isFetching: false
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  fetchData() {
    if (!this.mounted) return;
    this.setState({ isFetching: true });
    this.getData().then(data => this.onDataReceived(data));
  }

  getData() {
    if (this.props.location.state == undefined) {
      this.props.history.push({
        pathname: '/gms/data'
      });
      return;
    }
    if (!this.mounted) return;
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/data_sources/data_source/${this.props.location.state.data.slug}/view`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.payload = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.payload = json.result;
        else data.payload = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  onDataReceived(data) {
    if (data.failed) {
      //if(receivedData.updatedToken==null)
      this.props.updatePrivCall(false);

      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }

    if (!this.mounted) return;

    this.setState(
      {
        isFetching: false
      },
      () => {
        this.parseData(data.payload[0]);
      }
    );

    // setTimeout(this.marItem, 500);
  }

  parseData(data) {
    let gameParams = [];
    let configParams = [];
    let gameCopy = [];
    let configCopy = [];
    Object.entries(data.game_params).map(([k, v]) => {
      gameParams.push({ value: v.name, slug: v.slug, isEdited: false });
      gameCopy.push({ value: v.name, slug: v.slug, isEdited: false });
    });

    Object.entries(data.configuration_params).map(([k, v]) => {
      configParams.push({ value: v.name, slug: v.slug, isEdited: false });
      configCopy.push({ value: v.name, slug: v.slug, isEdited: false });
    });

    this.setState({
      dataName: data.name,
      newDataName: data.name,
      dataID: data.slug,
      savedDataID: data.slug,
      gameParameterTable: gameParams,
      gameCopyTable: gameCopy,
      configurationProfileTable: configParams,
      configCopyTable: configCopy,
      savedGameParameter: data.game_params,
      savedConfigParams: data.configuration_params
    });
  }
  //endregion

  setEditDataNameMode() {
    let currentMode = this.state.editNameMode;
    let savedName = this.state.dataName;
    if (currentMode == false) {
      this.setState({ editNameMode: !currentMode, newDataName: savedName });
    } else {
      this.setState({
        editNameMode: !currentMode,
        needSave: true
      });
    }
  }

  setNewDataName(event) {
    const target = event.target;
    let name = target.value;
    let update = false;
    if (
      name.length >= 1 &&
      name.length < 50 &&
      name !== '' &&
      this.state.dataID !== '' &&
      this.state.dataID.length < 50 &&
      this.state.dataID.length >= 1
    ) {
      update = true;
    }
    this.setState({
      newDataName: target.value,
      needSave: update
    });
  }
  /** Data source management */
  //region Data source management
  addGameParameter() {
    let currentGameParameter = this.state.newGameParameter;
    if (currentGameParameter !== '') {
      let sourceTable = this.state.gameParameterTable;
      if (sourceTable == null) {
        sourceTable = [];
      }
      let copyTable = this.state.gameCopyTable;
      sourceTable.push({
        value: currentGameParameter,
        isEdited: false,
        added_id: currentGameParameter
      });
      copyTable.push({
        value: currentGameParameter,
        isEdited: false,
        added_id: currentGameParameter
      });
      document.getElementById('addDataParameters').value = '';
      this.setState({
        gameParameterTable: sourceTable,
        gameCopyTable: copyTable,
        newGameParameter: ''
      });
    }
  }

  addConfigurationParameter() {
    let currentConfigurationProfile = this.state.newConfigurationParameter;
    if (currentConfigurationProfile !== '') {
      let sourceTable = this.state.configurationProfileTable;
      if (sourceTable == null) {
        sourceTable = [];
      }
      sourceTable.push({
        value: currentConfigurationProfile,
        isEdited: false,
        added_id: currentConfigurationProfile
      });
      let copyTable = this.state.configCopyTable;
      copyTable.push({
        value: currentConfigurationProfile,
        isEdited: false,
        added_id: currentConfigurationProfile
      });
      document.getElementById('addDataConfiguration').value = '';
      this.setState({
        configurationProfileTable: sourceTable,
        configCopyTable: copyTable,
        newConfigurationParameter: ''
      });
    }
  }

  openCloseEditGameParameter(sourceId) {
    let sourceTable = this.state.gameParameterTable;
    let value = sourceTable[sourceId].isEdited;
    sourceTable[sourceId].isEdited = !value;
    this.setState({ gameParameterTable: sourceTable });
  }

  confirmEditGame(sourceId) {
    let sourceTable = this.state.gameParameterTable;
    let copyTable = this.state.gameCopyTable;
    sourceTable[sourceId].isEdited = false;

    let objectToCopy = JSON.parse(JSON.stringify(sourceTable[sourceId]));

    copyTable[sourceId] = objectToCopy;

    this.setState({ gameParameterTable: sourceTable, gameCopyTable: copyTable });
  }

  cancelEditGame(sourceId) {
    let sourceTable = this.state.gameParameterTable;
    let copyTable = this.state.gameCopyTable;
    let objectToCopy = JSON.parse(JSON.stringify(copyTable[sourceId]));

    sourceTable[sourceId] = objectToCopy;
    sourceTable[sourceId].isEdited = false;
    this.setState({ gameParameterTable: sourceTable });
  }

  confirmEditConfig(sourceId) {
    let sourceTable = this.state.configurationProfileTable;
    let copyTable = this.state.configCopyTable;
    sourceTable[sourceId].isEdited = false;
    let objectToCopy = JSON.parse(JSON.stringify(sourceTable[sourceId]));

    copyTable[sourceId] = objectToCopy;

    this.setState({ configurationProfileTable: sourceTable, configCopyTable: copyTable });
  }

  cancelEditConfig(sourceId) {
    let sourceTable = this.state.configurationProfileTable;
    let copyTable = this.state.configCopyTable;
    let objectToCopy = JSON.parse(JSON.stringify(copyTable[sourceId]));

    sourceTable[sourceId] = objectToCopy;
    sourceTable[sourceId].isEdited = false;
    this.setState({ configurationProfileTable: sourceTable });
  }

  openCloseEditConfiguration(sourceId) {
    let sourceTable = this.state.configurationProfileTable;
    let value = sourceTable[sourceId].isEdited;
    sourceTable[sourceId].isEdited = !value;
    this.setState({ configurationProfileTable: sourceTable });
  }

  deleteGameParameterItem(sourceId) {
    let sourceTable = this.state.gameParameterTable;
    let removedGameParams = this.state.removedGameParams;

    Object.entries(this.state.savedGameParameter).map(([k, v]) => {
      if (v.name === sourceTable[sourceId].value) {
        removedGameParams.push(v.slug);
      }
    });

    sourceTable.splice(sourceId, 1);
    let copyTable = this.state.gameCopyTable;
    copyTable.splice(sourceId, 1);

    this.setState({
      gameParameterTable: sourceTable,
      gameCopyTable: copyTable,
      removedGameParams: removedGameParams
    });
  }

  deleteConfigParameterItem(sourceId) {
    let sourceTable = this.state.configurationProfileTable;
    let removedConfigParams = this.state.removedConfigParams;

    Object.entries(this.state.savedConfigParams).map(([k, v]) => {
      if (v.name === sourceTable[sourceId].value) {
        removedConfigParams.push(v.slug);
      }
    });

    sourceTable.splice(sourceId, 1);
    let copyTable = this.state.configCopyTable;
    copyTable.splice(sourceId, 1);
    this.setState({
      configurationProfileTable: sourceTable,
      configCopyTable: copyTable,
      removedConfigParams: removedConfigParams
    });
  }

  editGameParameterValue(event, sourceId) {
    const target = event.target;
    let sourceTable = this.state.gameParameterTable;

    sourceTable[sourceId].value = target.value;

    this.setState({ gameParameterTable: sourceTable });
  }

  editConfigParameterValue(event, sourceId) {
    const target = event.target;
    let sourceTable = this.state.configurationProfileTable;

    sourceTable[sourceId].value = target.value;

    this.setState({ configurationProfileTable: sourceTable });
  }
  //endregion
  setGameParameter(event) {
    const target = event.target;
    this.setState(
      {
        newGameParameter: target.value
      },
      () => {
        this.isTheSame();
      }
    );
  }

  isTheSame() {
    let sourceTable = this.state.gameParameterTable;
    let counter = 0;
    Object.entries(sourceTable).map(([k, v]) => {
      if (v.value === this.state.newGameParameter) {
        counter++;
      }
    });

    if (counter > 0) {
      this.setState({ isTheSame: true });
    } else {
      this.setState({ isTheSame: false });
    }
  }

  setConfigurationProfile(event) {
    const target = event.target;
    this.setState(
      {
        newConfigurationParameter: target.value
      },
      () => {
        this.isTheSameConfig();
      }
    );
  }

  isTheSameConfig() {
    let sourceTable = this.state.configurationProfileTable;
    let counter = 0;
    Object.entries(sourceTable).map(([k, v]) => {
      if (v.value === this.state.newConfigurationParameter) {
        counter++;
      }
    });

    if (counter > 0) {
      this.setState({ isTheSameConfig: true });
    } else {
      this.setState({ isTheSameConfig: false });
    }
  }

  setDataID(event) {
    const target = event.target;
    let update = false;
    let newData = target.value;
    let name;
    if (this.state.editNameMode) {
      name = this.state.newDataName;
    } else {
      name = this.state.dataName;
    }
    if (
      name.length >= 1 &&
      name.length < 50 &&
      name !== '' &&
      newData !== '' &&
      newData.length < 50 &&
      newData.length >= 1
    ) {
      update = true;
    }
    this.setState({
      dataID: target.value,
      needSave: update
    });
  }

  saveChanges() {
    if (this.state.needSave === false) {
      return;
    }
    this.setState({ isFetching: true });
    let newName;
    if (this.state.editNameMode) {
      newName = this.state.newDataName;
    } else {
      newName = this.state.dataName;
    }

    let modifyGameParams = [];

    Object.entries(this.state.gameParameterTable).map(([key, val]) => {
      if (val.slug !== undefined) {
        if (val.slug !== val.value && val.added_id === undefined) {
          let gameParamMod = {};
          gameParamMod[val.slug] = val.value;
          modifyGameParams.push(gameParamMod);
        }
      }
    });

    let modifyConfigurationParams = [];

    Object.entries(this.state.configurationProfileTable).map(([key, val]) => {
      if (val.slug != undefined) {
        if (val.slug !== val.value && val.added_id === undefined) {
          let configParamMod = {};
          configParamMod[val.slug] = val.value;
          modifyConfigurationParams.push(configParamMod);
        }
      }
    });

    let payload = {
      data: {
        name: newName,
        slug: this.state.dataID
      }
    };
    let added_game_params = [];
    Object.entries(this.state.gameParameterTable).map(([k, v]) => {
      if (v.added_id !== undefined) {
        added_game_params.push(v.value);
      }
    });

    let added_config_params = [];
    Object.entries(this.state.configurationProfileTable).map(([k, v]) => {
      if (v.added_id !== undefined) {
        added_config_params.push(v.value);
      }
    });

    if (added_game_params.length > 0) {
      payload.data.add_game_params = added_game_params;
    }

    if (this.state.removedGameParams.length > 0) {
      payload.data.remove_game_params = this.state.removedGameParams;
    }

    if (added_config_params.length > 0) {
      payload.data.add_configuration_params = added_config_params;
    }

    if (this.state.removedConfigParams.length > 0) {
      payload.data.remove_configuration_params = this.state.removedConfigParams;
    }

    if (modifyGameParams.length > 0) {
      payload.data.modify_game_params = modifyGameParams;
    }

    if (modifyConfigurationParams.length > 0) {
      payload.data.modify_configuration_params = modifyConfigurationParams;
    }

    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/data_sources/data_source/${this.state.savedDataID}/edit`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };

    return fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) storage.USER.token = token;
        if (status === 200) {
          this.props.getIcons(true);
          return json;
        } else {
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        this.props.history.push({
          pathname: '/gms/data'
        });
        return json;
      })
      .catch(error => {
        this.setState({ isFetching: false });
        console.error(error);
      });
  }

  deleteDataSource() {
    if (this.state.dataID !== this.state.dataSlugToDelete) {
      return;
    }
    this.setState({ isFetching: true });
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/data_sources/data_source/${this.state.dataID}/delete`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let payload = {
      data: {
        slug: this.state.dataID
      }
    };

    return fetch(url, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status === 200) return json;
        else {
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        this.props.history.push({
          pathname: '/gms/data'
        });
        return json;
      })
      .catch(error => {
        this.setState({ isFetching: false });
        console.error(error);
      });
  }

  handleDataSlugDelete(event) {
    const target = event.target;
    let needUpdate = false;
    if (target.value === this.state.savedDataID) {
      needUpdate = true;
    }
    this.setState({
      dataSlugToDelete: target.value,
      updateDeleteBtn: needUpdate
    });
  }

  noDeleteDataBtn() {
    this.setState({ openDeletePopup: false });
  }

  handleDeleteBtn() {
    this.setState({ openDeletePopup: true });
  }

  render() {
    if (!this.mounted) return;
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }
    let dataName;
    if (this.state.editNameMode) {
      dataName = (
        <DateName>
          <DateEditName>
            <Textfield
              labelText="Name"
              id="editDataName"
              type="text"
              value={this.state.newDataName}
              onChange={this.setNewDataName}
            />
            <DateEditMode src={x_red} onClick={this.setEditDataNameMode} />
          </DateEditName>
        </DateName>
      );
    } else {
      dataName = (
        <DateNameText>
          {this.state.dataName}
          <DateEdit src={edit_big} onClick={this.setEditDataNameMode} />
        </DateNameText>
      );
    }

    return (
      <Fragment>
        <ViewTile>{'Edit Data Source'}</ViewTile>
        {this.state.openDeletePopup ? (
          <div>
            <BackgroundPopup />
            <PopupDelete
              closeBtn={this.noDeleteDataBtn}
              onChange={this.handleDataSlugDelete}
              yesBtn={this.deleteDataSource}
              slug={this.state.dataID}
              itemType={' data source'}
              needUpdate={this.state.updateDeleteBtn}
            />
          </div>
        ) : null}
        <AddDataContainer>
          {dataName}
          <DeleteIconContainer>
            <DeleteIcon src={delete_btn} onClick={this.handleDeleteBtn} />
          </DeleteIconContainer>
          <DateID>
            <Textfield
              labelText="ID"
              id="editDataID"
              placeholder="Data Source ID"
              onChange={this.setDataID}
              value={this.state.dataID}
            />
          </DateID>
          <ButtonContainer>
            <Button needUpdate={this.state.needSave} onClick={this.saveChanges}>
              Save changes
            </Button>
          </ButtonContainer>
          <GameParameters>
            <Textfield
              labelText="Game Parameters"
              id="addDataParameters"
              placeholder="Add Parameter"
              onChange={this.setGameParameter}
            />
          </GameParameters>
          <AddGame>
            <AddImg
              isTheSame={this.state.isTheSame}
              src={add_btn}
              onClick={this.addGameParameter}
            />
          </AddGame>
          <ConfigurationParameters>
            <Textfield
              labelText="Configuration Parameters"
              id="addDataConfiguration"
              placeholder="Add Parameter"
              onChange={this.setConfigurationProfile}
            />
          </ConfigurationParameters>
          <AddParameters>
            <AddImg
              isTheSame={this.state.isTheSameConfig}
              src={add_btn}
              onClick={this.addConfigurationParameter}
            />
          </AddParameters>
          {this.state.gameParameterTable ? (
            <FilterGameParametersContainer>
              <FilterList
                items={this.state.gameParameterTable}
                openCloseEdit={this.openCloseEditGameParameter}
                deleteSource={this.deleteGameParameterItem}
                oneLabelMode={true}
                editText={this.editGameParameterValue}
                confirmEdit={this.confirmEditGame}
                cancelEdit={this.cancelEditGame}
              />
            </FilterGameParametersContainer>
          ) : null}
          {this.state.configurationProfileTable ? (
            <FilterConfigurationParameters>
              <FilterList
                items={this.state.configurationProfileTable}
                openCloseEdit={this.openCloseEditConfiguration}
                deleteSource={this.deleteConfigParameterItem}
                oneLabelMode={true}
                editText={this.editConfigParameterValue}
                confirmEdit={this.confirmEditConfig}
                cancelEdit={this.cancelEditConfig}
              />
            </FilterConfigurationParameters>
          ) : null}
        </AddDataContainer>
      </Fragment>
    );
  }
}

export default GmsEditData;
//region Styles
const ViewTile = styled.h1`
  width: 95%;
  color: #fff;
  padding: 0rem 0rem 1%;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 4rem;
  margin-right: 3%;
  margin-left: 2%;
  margin-bottom: 1rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;

const AddDataContainer = styled.div`
  display: grid;
  margin-top: 50px;
  grid-template-columns: 30rem 5rem 30rem 5rem;
  grid-row-gap: 20px;
  grid-column-gap: 10px;
  margin-left: 13%;
`;

const DeleteIconContainer = styled.div`
  grid-row: 1;
  grid-column: 2/3;
`;

const DeleteIcon = styled.img`
  cursor: pointer;
`;

const DateName = styled.div`
  grid-row: 1;
  grid-column: 1/2;
  position: relative;
  font-family: 'Titillium Web';
`;

const DateEditName = styled.div`
  position: absolute;
  top: -24px;
  width: 90%;
`;

const DateNameText = styled.div`
  font-size: 16px;
  color: rgb(225, 225, 229);
  letter-spacing: 0.05rem;
  font-weight: bolder;
  font-family: 'Titillium Web';
`;

const DateEdit = styled.img`
  display: inline;
  margin-left: 10px;
  cursor: pointer;
`;

const DateEditMode = styled.img`
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  top: 33px;
  left: 270px;
`;

const DateID = styled.div`
  grid-row: 2;
  grid-column: 1/2;
`;

const ButtonContainer = styled.div`
  grid-row: 5;
  grid-column: 1/2;
  height: 32px;
`;

const GameParameters = styled.div`
  grid-row: 3;
  grid-column: 1/2;
`;

const ConfigurationParameters = styled.div`
  grid-row: 3;
  grid-column: 3/4;
`;

const AddGame = styled.div`
  grid-row: 3;
  grid-column: 2/3;
`;

const AddParameters = styled.div`
  grid-row: 3;
  grid-column: 4/5;
`;

const AddImg = styled.img`
  margin-top: 21px;
  cursor: pointer;
  opacity: 1;
  pointer-events: auto;
  ${({ isTheSame }) =>
    isTheSame &&
    `

  opacity:0.5;
  pointer:none;
  pointer-events:none;
  `};
`;

const FilterGameParametersContainer = styled.div`
  grid-row: 4;
  grid-column: 1/2;
`;

const FilterConfigurationParameters = styled.div`
  grid-row: 4;
  grid-column: 3/4;
`;

const BackgroundPopup = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
  background-color: #282b30;
  opacity: 0.7;
  z-index: 100;
`;
//endregion
