import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Textfield from 'components/Textfield';
import DropdownList from 'components/DropdownList';
import x_red from './assets/x_red.png';
import check_green from './assets/check_green.png';
import Calendar from 'components/CustomDatePicker/Calendar';
import SearchList from 'components/SearchList';
import formatRoundNumberWithDots from 'utils/formatRoundNumberWithDots';
import formatNumberWithDots from 'utils/formatNumberWithDots';
import SmallLoader from 'components/SmallLoader';
import moment from 'moment';
import warning_icon from '../../views/App/Login/LoginForm/assets/warning_icon.png';

const changingText = (name, f, l, letters_number) => {
  if (name == null) return;
  let size = null;
  if (letters_number) {
    size = letters_number;
  } else {
    size = 100;
  }
  if (name.length > size) {
    let start = name.substring(0, f);

    let end = name.slice(l);

    let textNew = start + '...' + end;
    return textNew;
  } else {
    return name;
  }
};

class EditText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      value: '',
      temp: '',
      name: '',
      placeholder: '',
      isEdited: false,
      mounted: false,
      currentDropdown: '',
      waiting: false,
      changeData: false,
      savedDisplay: null,
      display: null,
      itemsListToDisplay: [],
      savedItemsList: [],
      displayTicks: true,
      mailWarning: false,
      isHovered: false
    };
    this.changeValue = this.changeValue.bind(this);
    this.acceptChanges = this.acceptChanges.bind(this);
    this.discardChanges = this.discardChanges.bind(this);
    this.editValue = this.editValue.bind(this);
    this.setDate = this.setDate.bind(this);
    this.typeCountry = this.typeCountry.bind(this);
    this.checkInput = this.checkInput.bind(this);
    this.displayOnHover = this.displayOnHover.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
  }

  componentDidMount() {
    //console.log('EditText componentDidMount', this.props, '< <<<');
    let placeholder = '';
    let display = '';
    let savedDisplay = '';
    let itemList = [];
    let displayTicks = true;
    if (this.props.itemsList && this.props.isDropdown) {
      itemList = this.props.itemsList;
    }
    let isEdited = false;
    let value = this.props.value;
    // console.log('edit text value:', this.props.value, value);
    if (value === null || value === '') {
      value = '';
      isEdited = true;
    }

    if (this.props.value == null && this.props.isDropdown) {
      value = '';
      placeholder = 'Select country';
      isEdited = true;
    }

    if (this.props.value !== null && this.props.isDropdown) {
      display = this.props.display;
      savedDisplay = this.props.display;
    }

    if (this.props.value == null && this.props.isCalendar) {
      value = moment();
      placeholder = true;
      displayTicks = false;
    }
    if (this.props.isCalendar && this.props.value != null) {
      placeholder = false;
    }

    if (this.props.isAlwaysEdited) {
      isEdited = true;
      if (this.props.value !== null && this.props.value !== '') {
        displayTicks = false;
      }
    }

    this.setState({
      //value: this.props.value,
      temp: value,
      name: this.props.name,
      isEdited: isEdited,
      placeholder: placeholder,
      value: value,
      mounted: true,
      display: display,
      savedDisplay: savedDisplay,
      itemsListToDisplay: itemList,
      savedItemsList: this.props.itemsList,
      displayTicks: displayTicks
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.temp !== nextProps.value ||
      this.state.isEdited !== nextState.isEdited ||
      this.state.temp !== nextState.temp ||
      this.state.placeholder !== nextState.placeholder ||
      this.state.value !== nextProps.value ||
      this.state.waiting !== nextState.waiting ||
      this.state.changeData !== nextState.changeData ||
      this.state.display !== nextProps.display ||
      this.state.display !== nextState.display ||
      this.state.itemsListToDisplay !== nextState.itemsListToDisplay ||
      nextProps.itemsList !== this.state.savedItemsList ||
      nextState.showList !== this.state.showList ||
      nextState.displayTicks !== this.state.displayTicks ||
      nextState.mailWarning !== this.state.mailWarning ||
      nextState.isHovered !== this.state.isHovered
    );
  }

  static getDerivedStateFromProps(props, state) {
    // console.log('getDerivedStatFromProps', state, props, props.value !== state.value);
    if (props.value !== state.value) {
      return {
        value: props.value
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      this.setState({ waiting: false });
    }
  }

  setDate(date) {
    if (date == 'nodata') {
      this.setState({
        temp: moment(),
        placeholder: true,
        changeData: true,
        displayTicks: true
      });
    } else {
      this.setState({
        temp: moment(date).format('DD-MM-YYYY'),
        placeholder: false,
        changeData: true,
        displayTicks: true
      });
    }
  }

  changeValue(e) {
    let target = e.target;
    if (this.props.isDropdown) {
      this.setState({
        temp: target.value,
        placeholder: '',
        changeData: true,
        display: target.name,
        savedDisplay: target.name,
        showList: false,
        displayTicks: true,
        itemsListToDisplay: this.state.savedItemsList
      });
    } else {
      this.setState({
        temp: target.value,
        changeData: true,
        displayTicks: true,
        mailWarning: false
      });
    }

    //console.log(target.value, typeof target.value);
  }

  typeCountry(e) {
    let target = e.target;
    let value = target.value;

    let searchTerm = value;
    this.setState({ display: e.target.value }, () => {
      // console.log('state>>', this.state);
    });
    if (value === '') {
      this.setState({ itemsListToDisplay: this.state.savedItemsList });
    } else {
      const isSearched = searchTerm => item =>
        !searchTerm || item['name'].toLowerCase().includes(searchTerm.toLowerCase());

      let items_searched = [];
      Object.values(this.state.savedItemsList)
        .filter(isSearched(searchTerm))
        .map(item => {
          /*   const key = Object.values(this.state.savedItemsList).find(

            key => this.state.savedItemsList[key]=== item['name']
          ); */
          items_searched.push(item);
        });

      this.setState({ itemsListToDisplay: items_searched, displayTicks: true });
    }
  }

  checkInput() {
    if (this.state.display == '' || this.state.itemsListToDisplay.length == 0) {
      this.setState({
        display: this.state.savedDisplay,
        itemsListToDisplay: this.state.savedItemsList,
        displayTicks: true
      });
    }
  }

  acceptChanges(name) {
    if (this.props.isDropdown) {
      this.checkInput();
    }
    if (this.props.isAlwaysEdited) {
      if (this.state.temp === '' || this.state.temp === null) {
        this.setState(
          {
            isEdited: true,
            displayTicks: false
          },
          () => {
            this.props.callback(this.props.data_id, this.state.temp, this.state.name);
          }
        );
      } else if (this.props.name == 'financier_contact_email') {
        if (/\S+@\S+/.test(this.state.temp)) {
          this.setState(
            {
              isEdited: true,
              displayTicks: false
            },
            () => {
              this.props.callback(this.props.data_id, this.state.temp, this.state.name);
            }
          );
        } else {
          this.setState({
            isEdited: true,
            displayTicks: true,
            mailWarning: true
          });
        }
      } else {
        this.setState(
          {
            isEdited: true,
            displayTicks: false
          },
          () => {
            this.props.callback(this.props.data_id, this.state.temp, this.state.name);
          }
        );
      }
    } else {
      if (
        this.props.isCalendar &&
        this.state.changeData == true &&
        this.state.placeholder == true
      ) {
        this.setState(
          {
            isEdited: true,
            waiting: false,
            displayTicks: false,
            temp: moment()
          },
          () => {
            this.props.callback(this.props.data_id, null, this.state.name);
          }
        );
      } else if (
        this.props.isCalendar &&
        this.state.changeData == false &&
        this.props.value != null
      ) {
        {
          this.setState({
            isEdited: false,
            waiting: false,
            displayTicks: false
          });
        }
      } else if (
        (this.state.temp === '' && this.props.value === null) ||
        (this.props.value === '' && this.state.temp === '')
      ) {
        // console.log('emty or null');
        this.setState({
          isEdited: true,
          waiting: false,
          displayTicks: false
        });
      } else if (
        this.props.value !== null &&
        (this.state.temp === '' || this.state.temp === null)
      ) {
        //console.log('prop not null but temp empty')
        this.setState(
          {
            isEdited: true,
            waiting: false,
            displayTicks: false
          },
          () => {
            this.props.callback(this.props.data_id, this.state.temp, this.state.name);
          }
        );
      } else if (this.state.temp == this.props.value && this.props.value !== null) {
        // console.log('temp same as prop and prop not null')
        this.setState({
          isEdited: false,
          waiting: false,
          displayTicks: false
        });
      } else if (this.props.name == 'external_contact_email') {
        if (/\S+@\S+/.test(this.state.temp)) {
          this.setState(
            {
              isEdited: false,
              waiting: true
            },
            () => {
              this.props.callback(this.props.data_id, this.state.temp, this.state.name);
            }
          );
        } else {
          this.setState({
            isEdited: true,
            displayTicks: true,
            mailWarning: true
          });
        }
      } else {
        // console.log('base case');
        this.setState(
          {
            isEdited: false,
            waiting: true
          },
          () => {
            this.props.callback(this.props.data_id, this.state.temp, this.state.name);
          }
        );
      }
    }
  }

  discardChanges(name) {
    if (this.props.isDropdown) {
      if (this.props.value == null || this.props.value == '') {
        this.setState({
          temp: this.props.value,
          isEdited: true,
          displayTicks: false,
          display: this.props.display
        });
      } else {
        this.setState({
          temp: this.props.value,
          isEdited: false,
          display: this.props.display
        });
      }
    } else {
      let newTemp = this.props.value;
      if (this.props.value == null) {
        newTemp = '';
      }

      if (this.props.isAlwaysEdited) {
        if (this.props.value == null || this.props.value === '') {
          this.setState({
            temp: newTemp,
            displayTicks: false,
            mailWarning: false
          });
        } else {
          this.setState({
            temp: newTemp,
            displayTicks: false,
            mailWarning: false
          });
        }
      } else {
        if (this.props.value == null || this.props.value === '') {
          this.setState({
            temp: newTemp,
            isEdited: true,
            displayTicks: false,
            placeholder: true,
            mailWarning: false
          });
        } else {
          this.setState({
            temp: newTemp,
            isEdited: false,
            placeholder: false,
            mailWarning: false
          });
        }
      }
    }
  }

  editValue(name) {
    this.setState({
      isEdited: true,
      displayTicks: true
    });
    // console.log('props, state', this.props, this.state);
  }

  displayOnHover(value, letters_number) {
    //console.log('hover');
    if (!value) return;
    if (!letters_number) return;
    if (value.length > letters_number && !this.state.isHovered) {
      // console.log('display on hover', value);
      this.setState({ isHovered: true });
    }
  }

  hideTooltip() {
    // console.log('hide tooltip');
    this.setState({ isHovered: false });
  }

  render() {
    if (this.props.displayOnly) {
      return (
        <TextContainer
          onMouseEnter={() => {
            this.displayOnHover(this.props.value, this.props.letters_number);
          }}
          onMouseLeave={this.hideTooltip}
        >
          {' '}
          {this.state.isHovered ? <HoveredText>{this.props.value}</HoveredText> : null}
          <ContactText displayOnly>
            {this.props.value === null && this.props.isCalendar ? '' : null}
            {this.props.value == 'null' && !this.props.isCalendar ? '' : null}
            {this.props.dataType == 'currency'
              ? `$${formatNumberWithDots(this.props.value)}`
              : null}
            {this.props.dataType == 'percent' ? `${formatNumberWithDots(this.props.value)}%` : null}
            {!this.props.dataType &&
            !(this.props.value == null) &&
            !(this.props.value == null && this.props.isCalendar)
              ? `${changingText(this.props.value, 5, -6, this.props.letters_number)}`
              : null}
          </ContactText>
        </TextContainer>
      );
    }
    // console.log('EDIT TEXT STATE', this.state);
    if (this.props.isCalendar) {
      return (
        <div>
          {this.state.isEdited ? (
            <CalendarContainer>
              <Calendar
                date={moment(this.state.temp, 'DD-MM-YYYY')}
                type="oneDate"
                changeDate={this.setDate}
                name="loanAmountsCalendar"
                toRangeDate={moment()
                  .startOf('day')
                  .add(2, 'year')}
                placeholderVal={this.state.placeholder}
                fieldHeight={24}
                noDataOption={true}
              />
              {this.state.displayTicks ? (
                <Fragment>
                  <InfoDeclineCal
                    src={x_red}
                    onClick={() => {
                      this.discardChanges(this.props.name);
                    }}
                  />
                  <InfoAcceptCal
                    src={check_green}
                    onClick={() => {
                      this.acceptChanges(this.props.name);
                    }}
                  />
                </Fragment>
              ) : null}
            </CalendarContainer>
          ) : (
            <Fragment>
              {this.state.waiting ? (
                <ContactText>
                  <SmallLoader />
                </ContactText>
              ) : (
                <ContactText
                  onClick={() => {
                    this.editValue(this.props.name);
                  }}
                >
                  {this.props.value}
                </ContactText>
              )}
            </Fragment>
          )}
        </div>
      );
    }

    let textfieldType = this.props.textfieldType ? this.props.textfieldType : 'text';
    // console.log(this.props.id, this.props.dataType);
    return (
      <div>
        {this.state.isEdited ? (
          this.props.isDropdown ? (
            <Fragment>
              <DLabel>{this.props.labelTxt}</DLabel>
              <SearchList
                ref={this.myListRef}
                value={this.state.temp}
                items={this.state.itemsListToDisplay}
                id={this.props.id}
                display={this.state.display}
                handler={this.changeValue}
                handleTypeValue={this.typeCountry}
                handleCheck={this.checkInput}
              />
              {this.state.displayTicks ? (
                <Fragment>
                  <InfoDecline
                    src={x_red}
                    onClick={() => {
                      this.discardChanges('country');
                    }}
                  />
                  <InfoAccept
                    src={check_green}
                    onClick={() => {
                      this.acceptChanges('country');
                    }}
                  />
                </Fragment>
              ) : null}
            </Fragment>
          ) : (
            <TextContainer>
              {this.state.mailWarning ? (
                <WarningInfo>
                  {' '}
                  <img src={warning_icon} /> Please enter a valid email address.
                </WarningInfo>
              ) : null}
              <Textfield
                labelText={this.props.labelTxt}
                id={this.props.id}
                name={this.props.name}
                type={textfieldType}
                displayLabel={true}
                onChange={this.changeValue}
                value={this.state.temp}
                displayEditPanel={true}
                displayTicks={this.state.displayTicks}
                handleAccept={this.acceptChanges}
                handleDiscard={this.discardChanges}
                textfieldHeight={this.props.textfieldHeight}
                textfieldWidth={this.props.textfieldWidth}
                maxLength={100}
              />
            </TextContainer>
          )
        ) : (
          <Fragment>
            <DLabel>{this.props.labelTxt}</DLabel>
            {this.state.waiting ? (
              <ContactText>
                <SmallLoader />
              </ContactText>
            ) : this.props.isDropdown ? (
              <ContactText
                onClick={() => {
                  this.editValue(this.props.name);
                }}
              >
                {this.props.display}
              </ContactText>
            ) : (
              <div
                onMouseEnter={() => {
                  this.displayOnHover(this.props.value, this.props.letters_number);
                }}
                onMouseLeave={this.hideTooltip}
              >
                <TextContainer>
                  {this.state.isHovered ? <HoveredText>{this.props.value}</HoveredText> : null}
                  <ContactText
                    onClick={() => {
                      this.editValue(this.props.name);
                    }}
                  >
                    {this.props.dataType == 'currency'
                      ? `$${formatNumberWithDots(this.props.value)}`
                      : null}
                    {this.props.dataType == 'percent'
                      ? `${formatNumberWithDots(this.props.value)}%`
                      : null}
                    {!this.props.dataType
                      ? `${changingText(this.props.value, 8, -8, this.props.letters_number)}`
                      : null}
                  </ContactText>
                </TextContainer>
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

export default EditText;

const HoveredText = styled.div`
  position: absolute;
  top: -5px;
  left: 50%;
  width: 200px;
  height: auto;
  background: #1c1c1c;
  font-size: 12px;
  padding: 10px;
  word-break: break-word;
  z-index: 102;
  line-height: 1.5;
  border-radius: 3px;
  border: 0.5px solid #3a3a3a;
  transition: all 0.2s ease-in-out;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, -100%);
  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-top-color: #1c1c1c;
    border-width: 4px;
    margin-left: -4px;
  }
  &:before {
    border-top-color: #1c1c1c;
    border-width: 7px;
    margin-left: -7px;
  }
`;

const TextContainer = styled.div`
  position: relative;
`;

const WarningInfo = styled.div`
  visibility: visible;
  position: absolute;
  color: #f29c10;
  font-size: 11px;
  letter-spacing: 0.1rem;
  right: 16px;
`;

const InfoAcceptCal = styled.img`
  position: absolute;
  bottom: 2px;
  right: 1px;
  cursor: pointer;
`;

const InfoDeclineCal = styled.img`
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
`;

const InfoAccept = styled.img`
  position: absolute;
  top: 39px;
  right: -14px;
  cursor: pointer;
`;

const InfoDecline = styled.img`
  position: absolute;
  top: 22px;
  right: -13px;
  cursor: pointer;
`;

const DLabel = styled.p`
  font-size: 12px;
  color: #99999c;
  display: block;
  margin-bottom: 5px;
  margin-top: 0px;
`;

const ContactText = styled.div`
  padding-top: 2px;
  font-size: 13px;
  letter-spacing: 0.05rem;
  display: block;
  height: 20px;
  margin-top: 0px;
  word-wrap: break-word;
  line-height: 20px;
  cursor: ${props => (props.displayOnly ? 'auto' : 'pointer')};
`;

const CalendarContainer = styled.div`
  position: relative;
  margin: 0 5px;
  padding-right: 15px;
  //width: auto;
`;
