import React, { Component, Fragment } from 'react';
import storage from 'utils/storage';
import CommonButton, { Button } from 'components/CommonButton/index';
import Textfield from 'components/Textfield';
import styled from 'styled-components';
import { Content } from 'views/App';
import edit_big from '../assets/edit_big.png';
import game_icon from 'views/App/assets/platforms.svg';
import LoadingIndicator from 'components/LoadingIndicator';
class GmsAddPlatform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      platformName: '',
      gameIcon: '',
      needSave: false
    };

    this.setPlatformName = this.setPlatformName.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.addPlatform = this.addPlatform.bind(this);
  }
  /** Add Platform Lifecycle Methods */
  //region Lifecycle Methods
  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}
  //endregion
  setPlatformName(event) {
    const target = event.target;
    let name = target.value;
    let update = false;
    if (name != '' && name.length >= 1 && name.length < 50) {
      update = true;
    }
    this.setState({
      platformName: target.value,
      needSave: update
    });
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onload = () => {
      this.setState({
        //gameIcon: file,
        gameIcon: reader.result
      });
    };
    reader.readAsDataURL(file);
  }

  addPlatform() {
    if (this.state.needSave === false) {
      return;
    }
    this.setState({ isFetching: true });
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/platforms/add';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let payload = {
      data: {
        name: this.state.platformName,
        icon: this.state.gameIcon
      }
    };
    return fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) storage.USER.token = token;
        if (status === 200) return json;
        else {
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        this.props.history.push({
          pathname: '/gms/platforms'
        });
        return json;
      })
      .catch(error => {
        this.setState({ isFetching: false });
        console.error(error);
      });
  }

  render() {
    const icon = this.state.gameIcon ? this.state.gameIcon : game_icon;
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }
    return (
      <Fragment>
        <ViewTile>{'Add Platform'}</ViewTile>
        <AddPlatformContainer>
          <div>
            <Textfield
              labelText="Name"
              id="addPlatformName"
              type="text"
              placeholder="Platform Name"
              onChange={this.setPlatformName}
            />
          </div>
          <IconContainer>
            <PlatformIcon src={icon} />
            <ImgEditLabel htmlFor={'image-input'}>
              <img src={edit_big} />
            </ImgEditLabel>
            <EditImageInput
              id={'image-input'}
              ref={this.imageInputRef}
              type="file"
              onChange={this.handleImageChange}
            />
          </IconContainer>
          <ButtonContainer>
            <Button needUpdate={this.state.needSave} onClick={this.addPlatform}>
              Add platform
            </Button>
          </ButtonContainer>
        </AddPlatformContainer>
      </Fragment>
    );
  }
}

export default GmsAddPlatform;

//region Styles
const ViewTile = styled.h1`
  width: 95%;
  color: #fff;
  padding: 0rem 0rem 1%;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 4rem;
  margin-right: 3%;
  margin-left: 2%;
  margin-bottom: 1rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;

const AddPlatformContainer = styled.div`
  width: 30rem;
  display: grid;
  position: relative;
  margin-top: 50px;
  grid-row-gap: 4rem;
  margin-left: 13%;
  &:nth-child(1) {
    grid-row: 1;
  }

  &:nth-child(2) {
    grid-row: 2;
  }

  &:nth-child {
    grid-row: 3;
  }
`;

const IconContainer = styled.div`
  position: relative;
`;

const ButtonContainer = styled.div`
  height: 32px;
`;

const PlatformIcon = styled.img`
  border-radius: 30px;
  height: 130px;
  width: 130px;
`;

const ImgEditLabel = styled.label`
  cursor: pointer;
`;

const EditImageInput = styled.input`
  display: none;
`;
//endregion
