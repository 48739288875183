import React, { Component, Fragment } from 'react';
import './style.css';
import storage from 'utils/storage';
import currency from 'utils/currencyFormatter';
import numberFormatter from 'utils/numberFormatter';
import formatRoundNumberWithDots from 'utils/formatRoundNumberWithDots';
import formatNumberWithK from 'utils/formatNumberWithK';
import SvgGauge from './SvgGauge';
import Gauge from 'components/Gauge';
import KPIChart from './KPIChart';
import EditTargets from './EditTargets';
import PreviewTargets from './PreviewTargets';
import CustomDatePicker from 'components/CustomDatePicker';
import AnimatedView from 'components/AnimatedView';
import IgnitionStatus from 'components/IgnitionStatus';
import moment from 'moment';
import styled from 'styled-components';
import CommonButton from 'components/CommonButton/index';
import all_icon from './assets/all_icon.png';
import LoadingIndicator from 'components/LoadingIndicator';
import DropdownList from 'components/DropdownList';
import DropdownList2 from 'components/DropdownList2';

class KpiGameDetails extends Component {
  constructor(props) {
    super(props);
    let currentDate = new Date();
    let oneMonthAgo = new Date().setDate(currentDate.getDate() - 29);
    //oneWeekAgo.setDate(currentDate.getDate() - 6);

    this.state = {
      showDatePicker: false,
      selectedPlatform: 'all',
      selectedPeriod: '30',
      from: oneMonthAgo,
      to: new Date(),
      normalData: null,
      premiumData: null,
      revenueData: null,
      retentionData: null,
      platformsData: null,
      previewData: null,
      revCostData: null,
      isEditingTargets: false,
      isPreviewTargets: false,
      isFetching1: false,
      isFetching2: false,
      isFetching3: false,
      isFetching4: false,
      isFetchingPreview: false
    };
    this.mounted = false;
    this.onDataReceived = this.onDataReceived.bind(this);
    this.getNormalData = this.getNormalData.bind(this);
    this.getPremiumData = this.getPremiumData.bind(this);
    this.getChartsData = this.getChartsData.bind(this);
    this.getPlatformsData = this.getPlatformsData.bind(this);

    this.fetchData = this.fetchData.bind(this);
    this.selectPlatformHandler = this.selectPlatformHandler.bind(this);
    this.selectPeriodHandler = this.selectPeriodHandler.bind(this);
    this.dateRangeClikHandler = this.dateRangeClikHandler.bind(this);
    this.editTargetClickHandler = this.editTargetClickHandler.bind(this);
    this.closeEditTargetClickHandler = this.closeEditTargetClickHandler.bind(this);
    this.submitEditTargetClickHandler = this.submitEditTargetClickHandler.bind(this);
    this.previewTargetClickHandler = this.previewTargetClickHandler.bind(this);
    this.closePreviewTargetClickHandler = this.closePreviewTargetClickHandler.bind(this);
    this.submitPreviewTargetClickHandler = this.submitPreviewTargetClickHandler.bind(this);
    this.updateCustomDateRange = this.updateCustomDateRange.bind(this);
  }

  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    this.mounted = false;
  }

  selectPlatformHandler(platform) {
    this.setState({
      isFetching: true,
      selectedPlatform: platform
    });
    setTimeout(this.fetchData, 0);
  }

  selectPeriodHandler(event) {
    const target = event.target;
    const period = target.value;
    this.setState({
      isFetching: true,
      selectedPeriod: period
    });
    setTimeout(this.fetchData, 0);
  }

  dateRangeClikHandler() {
    this.setState({
      showDatePicker: true
    });
  }

  updateCustomDateRange(dateFrom, dateTo, needUpdate) {
    if (needUpdate) {
      this.setState({
        from: moment(dateFrom),
        to: moment(dateTo),
        fetchData: true
      });

      setTimeout(this.fetchData, 0);
    } else return;
  }

  editTargetClickHandler() {
    this.setState({ isEditingTargets: true });
  }

  closeEditTargetClickHandler() {
    this.setState({ isEditingTargets: false });
  }

  submitEditTargetClickHandler(data) {
    this.setState({ isEditingTargets: false });
    this.sendTargetsData(data);
  }

  previewTargetClickHandler() {
    this.setState({ isPreviewTargets: true });
  }

  closePreviewTargetClickHandler() {
    this.setState({ isPreviewTargets: false });
  }

  submitPreviewTargetClickHandler(data) {
    //this.setState({ isPreviewTargets: false });
    // console.log('send preview data : ', data);
    this.setState({
      previewData: null
    });

    this.sendPreviewData(data);
  }

  /** Fetching data */
  //region Fetching data
  onDataReceived(data) {
    if (!this.mounted) return;
    if (data === undefined || !data.chartsData || !data.normalData || !data.premiumData) {
      this.setState({
        isFetching1: false,
        isFetching2: false,
        isFetching3: false
      });

      return;
    }

    let from = moment(data.chartsData.result.start, 'DD-MM-YYYY');
    let to = moment(data.chartsData.result.end, 'DD-MM-YYYY');

    this.setState({
      from: from,
      to: to,
      normalData: data.normalData.result.data,
      premiumData: data.premiumData.result,
      revenueData: data.chartsData.result.data[1].revenue,
      retentionData: data.chartsData.result.data[0].retention,
      revCostData: data.chartsData.result.data[2].revenue_cost,
      mauDauData: data.chartsData.result.data[4].mau_dau,
      cpiLtvRoiData: data.chartsData.result.data[3].predictions,
      arpdauData: data.chartsData.result.data[5].user_data,
      isFetching1: false,
      isFetching2: false,
      isFetching3: false
    });
  }

  getPlatformsData() {
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/games/game/${this.props.match.params.id}/view`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    this.setState({ isFetching4: true });
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.payload = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.payload = json.result;
        else data.payload = json;
        this.setState({ platformsData: data.payload[0].platforms, isFetching4: false });
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  getNormalData() {
    const platform = this.state.selectedPlatform.toLowerCase();
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.189.113.91:5000';
    const PATH_CALL = `/analytics/game/${this.props.match.params.id}/${
      this.state.selectedPeriod
    }?platform=${platform}`;
    const url = `${PATH_BASE}${PATH_CALL}`;

    this.setState({ isFetching1: true });

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};

    data.normalData = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        if (status !== 200) {
          return;
        }
        let json = response.json();

        return json;
      })
      .then(json => {
        if (json === undefined) {
          return;
        }
        data.normalData = json;
        return data;
      });
  }

  sendTargetsData(data) {
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.189.113.91:5000';
    const PATH_CALL = `/analytics/ignition_status/edit_targets/${this.props.match.params.id}`;
    const url = `${PATH_BASE}${PATH_CALL}`;

    this.setState({ isFetching1: true });

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    let payload = {
      roi: Number(data.roi),
      ltv: Number(data.ltv),
      cpi: Number(data.cpi),
      ltv_period: Number(data.ltv_period),
      k_factor: Number(data.k_factor),
      data_collection_start: data.data_collection_start,
      data_collection_end: data.data_collection_end,
      cohort_based_ltv: data.cohort_based_ltv,
      acquisition_type: data.acquisition_type
    };

    if (data === undefined) {
      return;
    }
    data.premiumData = null;
    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status !== 200) {
          setTimeout(this.fetchData, 0);
          console.log('error', status);
          return;
        }
        setTimeout(this.fetchData, 0);
        return json;
      })
      .catch(error => {
        console.error(error);
        //this.hideAddUser();
        //this.fetchData();
      });
  }

  sendPreviewData(data) {
    const platform = this.state.selectedPlatform.toLowerCase();
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.189.113.91:5000';
    const PATH_CALL = `/analytics/ignition_status/${
      this.props.match.params.id
    }?platform=${platform}&k_factor=${data.k_factor}&ltv_period=${
      data.ltv_period
    }&data_collection_start=${data.data_collection_start}&data_collection_end=${
      data.data_collection_end
    }&acquisition_type=${data.acquisition_type}`;
    const url = `${PATH_BASE}${PATH_CALL}`;

    this.setState({ isFetchingPreview: true });

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    let payload = {
      ltv_period: Number(data.ltv_period),
      k_factor: Number(data.k_factor),
      data_collection_start: data.data_collection_start,
      data_collection_end: data.data_collection_end,
      acquisition_type: data.acquisition_type
    };

    if (data === undefined) {
      return;
    }
    data.premiumData = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status !== 200) {
          //setTimeout(this.fetchData, 0);
          console.log('error', status);
          return;
        }
        //setTimeout(this.fetchData, 0);
        return json;
      })
      .then(json => {
        // console.log('preview response: ', json.result);
        this.setState({
          previewData: json.result,
          isFetchingPreview: false
        });
        return json;
      })
      .catch(error => {
        console.error(error);
        //this.hideAddUser();
        //this.fetchData();
      });
  }

  getPremiumData(data) {
    const platform = this.state.selectedPlatform.toLowerCase();
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.189.113.91:5000';
    const PATH_CALL = `/analytics/ignition_status/${
      this.props.match.params.id
    }?platform=${platform}`;
    const url = `${PATH_BASE}${PATH_CALL}`;

    this.setState({ isFetching2: true });
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    if (data === undefined) {
      return;
    }
    data.premiumData = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status !== 200) {
          console.log('error', status);
        }
        return json;
      })
      .then(json => {
        data.premiumData = json;
        return data;
      });
  }

  getChartsData(data) {
    const platform = this.state.selectedPlatform.toLowerCase();
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.189.113.91:5000';

    const PATH_CALL = `/analytics/timeseries/${this.props.match.params.id}/${moment(
      this.state.to
    ).format('DD-MM-YYYY')}/${moment(this.state.from).format(
      'DD-MM-YYYY'
    )}?data=retention|revenue|revenue_cost|predictions|mau_dau|user_data&platform=${platform}`;

    const url = `${PATH_BASE}${PATH_CALL}`;

    this.setState({ isFetching3: true });
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    if (data === undefined) {
      return;
    }
    data.chartsData = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status !== 200) {
          console.log('error', status);
        }
        return json;
      })
      .then(json => {
        data.chartsData = json;
        return data;
      });
  }

  fetchData() {
    this.setState({ isFetching: true });
    this.getPlatformsData();
    this.getNormalData()
      .then(data => this.getPremiumData(data))
      .then(data => this.getChartsData(data))
      .then(data => this.onDataReceived(data));

    //setTimeout(this.onDataReceived, 2000);
  }
  //endregion

  render() {
    if (
      this.state.isFetching1 ||
      this.state.isFetching2 ||
      this.state.isFetching3 ||
      this.state.isFetching4
    ) {
      return <LoadingIndicator />;
    }

    if (!this.state.normalData || !this.state.premiumData || !this.state.platformsData) {
      return (
        <div className="fetchDataerrorMessage">
          <h3>Oops!</h3>
          <p>
            You don't have permissions to see this view or there is no data available at the moment.
            Please try Again!
          </p>
        </div>
      );
    }
    /** Game data tables */
    //region Game Data
    const gameData = this.state.normalData;
    let red = {
      color: 'red',
      fontSize: 1.3 + 'rem'
    };
    let green = {
      color: 'green',
      fontSize: 1.3 + 'rem'
    };
    let yellow = {
      color: '#fbffc6',
      fontSize: 1.3 + 'rem'
    };
    let daily_spendChange =
      Number(gameData.period_spend_sum.current) - Number(gameData.period_spend_sum.compare);
    let dauChange = Number(gameData.dau.current) - Number(gameData.dau.compare);
    let arpuChange = Number(gameData.arpu.current) - Number(gameData.arpu.compare);
    let arppuChange = Number(gameData.arppu.current) - Number(gameData.arppu.compare);
    let arpdauChange = Number(gameData.arpdau.current) - Number(gameData.arpdau.compare);
    let conversionChange =
      Number(gameData.conversion.current) - Number(gameData.conversion.compare);
    let daily_spend_avg;
    let dau;
    let arpu;
    let arppu;
    let arpdau;
    let conversion;

    if (Number(gameData.period_spend_sum.compare) === 0) {
      daily_spend_avg = (
        <span>
          <span>{'$' + formatRoundNumberWithDots(gameData.period_spend_sum.current)}</span>
          <span>{null}</span>
        </span>
      );
    } else if (daily_spendChange > 0) {
      let percentChange = (
        (daily_spendChange / Number(gameData.period_spend_sum.compare)) *
        100
      ).toFixed(0);
      daily_spend_avg = (
        <span>
          <span>{'$' + formatRoundNumberWithDots(gameData.period_spend_sum.current)}</span>
          <span style={green}>{' \u25B2' + formatNumberWithK(percentChange, 0) + '%'}</span>
        </span>
      );
    } else if (daily_spendChange < 0) {
      let percentChange =
        ((daily_spendChange * -1) / Number(gameData.period_spend_sum.compare)) * 100;
      daily_spend_avg = (
        <span>
          <span>{'$' + formatRoundNumberWithDots(gameData.period_spend_sum.current)}</span>
          <span style={red}>{' \u25BC' + formatNumberWithK(percentChange, 0) + '%'}</span>
        </span>
      );
    } else if (daily_spendChange === 0)
      daily_spend_avg = (
        <span>
          <span>{'$' + formatRoundNumberWithDots(gameData.period_spend_sum.current)}</span>
          <span>{null}</span>
        </span>
      );
    if (Number(gameData.dau.compare) === 0) {
      dau = (
        <span>
          <span>{numberFormatter(gameData.dau.current)}</span>
          <span>{null}</span>
        </span>
      );
    } else if (dauChange > 0) {
      let percentChange = (dauChange / Number(gameData.dau.compare)) * 100;
      dau = (
        <span>
          <span>{numberFormatter(gameData.dau.current.toFixed(0))}</span>
          <span style={green}>{' \u25B2' + formatNumberWithK(percentChange, 0) + '%'}</span>
        </span>
      );
    } else if (dauChange < 0) {
      let percentChange = ((dauChange * -1) / Number(gameData.dau.compare)) * 100;
      dau = (
        <span>
          <span>{numberFormatter(gameData.dau.current.toFixed(0))}</span>
          <span style={red}>{' \u25BC' + formatNumberWithK(percentChange, 0) + '%'}</span>
        </span>
      );
    } else if (dauChange === 0)
      dau = (
        <span>
          <span>{numberFormatter(gameData.dau.current.toFixed(2))}</span>
          <span>{null}</span>
        </span>
      );

    if (Number(gameData.arpu.compare) === 0) {
      arpu = (
        <span>
          <span>{currency(gameData.arpu.current)}</span>
          <span style={red}>{null}</span>
        </span>
      );
    } else if (arpuChange > 0) {
      let percentChange = (arpuChange / Number(gameData.arpu.compare)) * 100;
      arpu = (
        <span>
          <span>{currency(gameData.arpu.current)}</span>
          <span style={green}>{' \u25B2' + formatNumberWithK(percentChange, 0) + '%'}</span>
        </span>
      );
    } else if (arpuChange < 0) {
      let percentChange = ((arpuChange * -1) / Number(gameData.arpu.compare)) * 100;
      arpu = (
        <span>
          <span>{currency(gameData.arpu.current)}</span>
          <span style={red}>{' \u25BC' + formatNumberWithK(percentChange, 0) + '%'}</span>
        </span>
      );
    } else if (arpuChange === 0)
      arpu = (
        <span>
          <span>{currency(gameData.arpu.current)}</span>
          <span style={red}>{null}</span>
        </span>
      );

    if (Number(gameData.arppu.compare) === 0) {
      arppu = (
        <span>
          <span>{currency(gameData.arppu.current)}</span>
          <span style={red}>{null}</span>
        </span>
      );
    } else if (arppuChange > 0) {
      let percentChange = (arppuChange / Number(gameData.arppu.compare)) * 100;
      arppu = (
        <span>
          <span>{currency(gameData.arppu.current)}</span>
          <span style={green}>{' \u25B2' + formatNumberWithK(percentChange, 0) + '%'}</span>
        </span>
      );
    } else if (arppuChange < 0) {
      let percentChange = ((arppuChange * -1) / Number(gameData.arppu.compare)) * 100;
      arppu = (
        <span>
          <span>{currency(gameData.arppu.current)}</span>
          <span style={red}>{' \u25BC' + formatNumberWithK(percentChange, 0) + '%'}</span>
        </span>
      );
    } else if (arppuChange === 0)
      arppu = (
        <span>
          <span>{currency(gameData.arppu.current)}</span>
          <span style={red}>{null}</span>
        </span>
      );

    if (Number(gameData.arpdau.compare) === 0)
      arpdau = (
        <span>
          <span>{currency(gameData.arpdau.current)}</span>
          <span style={red}>{null}</span>
        </span>
      );
    else if (arpdauChange > 0) {
      let percentChange = (arpdauChange / Number(gameData.arpdau.compare)) * 100;
      arpdau = (
        <span>
          <span>{currency(gameData.arpdau.current)}</span>
          <span style={green}>{' \u25B2' + formatNumberWithK(percentChange, 0) + '%'}</span>
        </span>
      );
    } else if (arpdauChange < 0) {
      let percentChange = ((arpdauChange * -1) / Number(gameData.arpdau.compare)) * 100;
      arpdau = (
        <span>
          <span>{currency(gameData.arpdau.current)}</span>
          <span style={red}>{' \u25BC' + formatNumberWithK(percentChange, 0) + '%'}</span>
        </span>
      );
    } else if (arpdauChange === 0)
      arpdau = (
        <span>
          <span>{currency(gameData.arpdau.current)}</span>
          <span style={red}>{null}</span>
        </span>
      );
    if (Number(gameData.conversion.compare) === 0) {
      conversion = (
        <span>
          <span>{Number(gameData.conversion.current).toFixed(3) + '%'}</span>
          <span>{null}</span>
        </span>
      );
    } else if (conversionChange > 0) {
      let percentChange = (conversionChange / Number(gameData.conversion.compare)) * 100;
      conversion = (
        <span>
          <span>{Number(gameData.conversion.current).toFixed(3) + '%'}</span>
          <span style={green}>{' \u25B2' + formatNumberWithK(percentChange, 0) + '%'}</span>
        </span>
      );
    } else if (conversionChange < 0) {
      let percentChange = ((conversionChange * -1) / Number(gameData.conversion.compare)) * 100;
      conversion = (
        <span>
          <span>{Number(gameData.conversion.current).toFixed(3) + '%'}</span>
          <span style={red}>{' \u25BC' + formatNumberWithK(percentChange, 0) + '%'}</span>
        </span>
      );
    } else if (conversionChange === 0)
      conversion = (
        <span>
          <span>{Number(gameData.conversion.current).toFixed(3) + '%'}</span>
          <span style={red}>{null}</span>
        </span>
      );

    let editTargets = 'ddd';

    if (
      this.state.isEditingTargets &&
      ((this.props.filter === 'internal' &&
        storage.USER.privileges.includes('manage_apps_in_insight')) ||
        (this.props.filter === 'external' &&
          storage.USER.privileges.includes('manage_external_apps_in_insight')))
    ) {
      editTargets = true;
    }
    //endregion
    /** LTV and CPI data tables*/
    //region LTV and CPI
    let ltv_1;
    let ltv_7;
    let ltv_14;
    let ltv_30;
    let ltv_60;
    let ltv_90;
    let cpi;
    let retention_1;
    let retention_7;
    let retention_14;
    let retention_30;
    let retention_90;

    if (gameData.retention_1.type == 'no_data') {
      retention_1 = ['No data'];
    } else {
      retention_1 = [Number(gameData.retention_1.current).toFixed(2) + '%'];
    }

    if (gameData.retention_7.type == 'no_data') {
      retention_7 = ['No data'];
    } else {
      retention_7 = [Number(gameData.retention_7.current).toFixed(2) + '%'];
    }

    if (gameData.retention_14.type == 'no_data') {
      retention_14 = ['No data'];
    } else {
      retention_14 = [Number(gameData.retention_14.current).toFixed(2) + '%'];
    }
    if (gameData.retention_30.type == 'no_data') {
      retention_30 = ['No data'];
    } else {
      retention_30 = [Number(gameData.retention_30.current).toFixed(2) + '%'];
    }
    if (gameData.retention_90.type == 'no_data') {
      retention_90 = ['No data'];
    } else {
      retention_90 = [Number(gameData.retention_90.current).toFixed(2) + '%'];
    }

    if (gameData.cpi.type == 'no_data') {
      cpi = ['No data'];
    } else {
      cpi = [currency(gameData.cpi.current)];
    }

    if (gameData.ltv_1.type == 'no_data') {
      ltv_1 = ['No data'];
    } else {
      ltv_1 = [currency(gameData.ltv_1.current)];
    }

    if (gameData.ltv_7.type == 'no_data') {
      ltv_7 = ['No data'];
    } else {
      ltv_7 = [currency(gameData.ltv_7.current)];
    }

    if (gameData.ltv_14.type == 'no_data') {
      ltv_14 = ['No data'];
    } else {
      ltv_14 = [currency(gameData.ltv_14.current)];
    }

    if (gameData.ltv_30.type == 'no_data') {
      ltv_30 = ['No data'];
    } else {
      ltv_30 = [currency(gameData.ltv_30.current)];
    }

    if (!gameData.ltv_60 || gameData.ltv_60.type == 'no_data') {
      ltv_60 = ['No data'];
    } else {
      ltv_60 = [currency(gameData.ltv_60.current)];
    }

    if (gameData.ltv_90.type == 'no_data') {
      ltv_90 = ['No data'];
    } else {
      ltv_90 = [currency(gameData.ltv_90.current)];
    }
    //endregion
    let periods_object = [
      { key: '7', value: 'Period: 7 days' },
      { key: '30', value: 'Period: 30 days' },
      { key: '60', value: 'Period: 60 days' },
      { key: '90', value: 'Period: 90 days' }
    ];

    return (
      <AnimatedView>
        <div key="KpiGameDetailsKey" className={'kpiGameDetailsContainer'}>
          <GameDetailTitle>
            <GameDetailIcon src={storage.GAMES_ICONS[this.props.match.params.id]} />
            {this.state.normalData.game_name}
          </GameDetailTitle>

          <KPIGrid>
            <GaugeContainer>
              <IgnitionStatus data={this.state.premiumData} />
            </GaugeContainer>
            <Panels>
              <KPIPanel>
                <Title>KPIs</Title>
                <div className="kpiGameDetailsGridContainer">
                  <div className="kpiDtInfo">
                    <span>Last {this.state.selectedPeriod} days</span>
                    <span>Previous {this.state.selectedPeriod} days</span>
                  </div>
                  <div className="dt1">
                    <DataTile
                      key={'dt1'}
                      className="dt1"
                      label={'UA spend'}
                      data={[
                        daily_spend_avg,
                        '$' + formatRoundNumberWithDots(gameData.period_spend_sum.compare)
                      ]}
                    />
                  </div>

                  <div className="dt2">
                    <DataTile
                      className="dt2"
                      key={'dt2'}
                      label={'DAU'}
                      data={[dau, numberFormatter(gameData.dau.compare.toFixed(0))]}
                    />
                  </div>

                  <div className="dt3">
                    <DataTile
                      className="dt3"
                      key={'dt3'}
                      label={'ARPU'}
                      data={[arpu, currency(gameData.arpu.compare)]}
                    />
                  </div>
                  <div className="dt4">
                    <DataTile
                      className="dt4"
                      key={'dt4'}
                      label={'ARPPU'}
                      data={[arppu, currency(gameData.arppu.compare)]}
                    />
                  </div>
                  <div className="dt5">
                    <DataTile
                      className="dt5"
                      key={'dt5'}
                      label={'Conversion'}
                      data={[conversion, Number(gameData.conversion.compare).toFixed(3) + '%']}
                    />
                  </div>
                  <div className="dt6">
                    <DataTile
                      className="dt6"
                      key={'dt6'}
                      label={'ARPDAU'}
                      data={[arpdau, currency(gameData.arpdau.compare)]}
                    />
                  </div>
                  <div className="dt7">
                    <DataTile
                      className="dt7"
                      key={'dt7'}
                      label={'All Time Revenue'}
                      data={['$' + formatRoundNumberWithDots(gameData.all_time_revenue.current)]}
                    />
                  </div>
                  <div className="dt8">
                    <DataTile className="dt8" key={'dt8'} label={'LTV (1d)'} data={ltv_1} />
                  </div>
                  <div className="dt9">
                    <DataTile className="dt9" key={'dt9'} label={'LTV (7d)'} data={ltv_7} />
                  </div>
                  <div className="dt10">
                    <DataTile className="dt10" key={'dt10'} label={'LTV (14d)'} data={ltv_14} />
                  </div>
                  <div className="dt11">
                    <DataTile className="dt11" key={'dt11'} label={'LTV (30d)'} data={ltv_30} />
                  </div>
                  <div className="dt19">
                    <DataTile className="dt19" key={'dt19'} label={'LTV (60d)'} data={ltv_60} />
                  </div>
                  <div className="dt12">
                    <DataTile className="dt12" key={'dt12'} label={'LTV (90d)'} data={ltv_90} />
                  </div>
                  <div className="cpiTile">
                    <DataTile key={'cpiTile'} label={`CPI`} data={cpi} />
                  </div>
                  <div className="dt13">
                    <DataTile
                      className="dt13"
                      key={'dt13'}
                      label={'All Time UA Spend'}
                      data={['$' + formatRoundNumberWithDots(gameData.all_time_spend.current)]}
                    />
                  </div>
                  <div className="dt14">
                    <DataTile
                      className="dt14"
                      key={'dt14'}
                      label={'Retention (1d)'}
                      data={retention_1}
                    />
                  </div>
                  <div className="dt15">
                    <DataTile
                      className="dt15"
                      key={'dt15'}
                      label={'Retention (7d)'}
                      data={retention_7}
                    />
                  </div>
                  <div className="dt16">
                    <DataTile
                      className="dt16"
                      key={'dt16'}
                      label={'Retention (14d)'}
                      data={retention_14}
                    />
                  </div>
                  <div className="dt17">
                    <DataTile
                      className="dt17"
                      key={'dt17'}
                      label={'Retention (30d)'}
                      data={retention_30}
                    />
                  </div>
                  <div className="dt18">
                    <DataTile
                      className="dt18"
                      key={'dt18'}
                      label={'Retention (90d)'}
                      data={retention_90}
                    />
                  </div>
                  {/* <DropdownList
                    items={periods_object}
                    name={'kpiPeriods'}
                    currentOpt={this.state.selectedPeriod}
                    handler={this.selectPeriodHandler}
                    kvPairs={true}
                    uppercase={false}
                  /> */}
                  <DropdownList2
                    items={periods_object}
                    name={'kpiPeriods'}
                    currentOption={this.state.selectedPeriod}
                    handler={this.selectPeriodHandler}
                  />
                </div>
              </KPIPanel>
              <TotalLoanPanel>
                <Title>Total loan</Title>
                <div className="totalContainer">
                  <div className="tldt1">
                    <DataTile key={'dt18'} label={'Play Capital'} data={['No data']} />
                  </div>
                  <div className="tldt2">
                    <DataTile key={'dt19'} label={'Ignition Capital'} data={['No data']} />
                  </div>
                </div>
              </TotalLoanPanel>
            </Panels>
            <PlatformButtons>
              <PlatformPicker
                selectedPlatform={this.state.selectedPlatform}
                platforms={this.state.platformsData}
                callBack={platform => this.selectPlatformHandler(platform)}
              />
            </PlatformButtons>
            <EditTarget>
              {((this.props.filter === 'internal' &&
                storage.USER.privileges.includes('manage_apps_in_insight')) ||
                (this.props.filter === 'external' &&
                  storage.USER.privileges.includes('manage_external_apps_in_insight'))) && (
                <Fragment>
                  <CommonButton
                    width={'120px'}
                    text="CONFIGURE"
                    onClick={this.editTargetClickHandler}
                  />
                  <CommonButton
                    width={'120px'}
                    text="PREVIEW"
                    onClick={this.previewTargetClickHandler}
                  />
                </Fragment>
              )}
            </EditTarget>
            <Charts>
              <RevenueContainer>
                <KPIChart
                  title={'Revenue'}
                  id={this.props.match.params.id}
                  data={this.state.revenueData}
                  slug={'revenue'}
                  frontSymbol={'$'}
                />
              </RevenueContainer>
              <RetentionContainer>
                <KPIChart
                  title={'Retention'}
                  id={this.props.match.params.id}
                  data={this.state.retentionData}
                  slug={'retention'}
                  backSymbol={'%'}
                />
              </RetentionContainer>
              <RevcostContainer>
                <KPIChart
                  title={'Revenue / Cost'}
                  id={this.props.match.params.id}
                  data={this.state.revCostData}
                  slug={'revenuecost'}
                  frontSymbol={'$'}
                />
              </RevcostContainer>
              <DauMauContainer>
                <KPIChart
                  title={'DAU, MAU'}
                  id={this.props.match.params.id}
                  data={this.state.mauDauData}
                  slug={'daumau'}
                />
              </DauMauContainer>
              <ArpdauContainer>
                <KPIChart
                  title={'ARPDAU, ARPU, ARPPU'}
                  id={this.props.match.params.id}
                  data={this.state.arpdauData}
                  slug={'arpdau'}
                  frontSymbol={'$'}
                />
              </ArpdauContainer>
              <CpiLtvRoiContainer>
                <KPIChart
                  title={'CPI, LTV, ROI'}
                  id={this.props.match.params.id}
                  data={this.state.cpiLtvRoiData}
                  slug={'cpiltvroi'}
                  frontSymbol={'$'}
                  backSymbol={'%'}
                />
              </CpiLtvRoiContainer>
            </Charts>
            <div />
            <PickerContainer>
              <CustomDatePicker
                active={true}
                from={this.state.from}
                to={this.state.to}
                handleUpdate={this.updateCustomDateRange}
                name="kpiDetail"
                cWidth={true}
              />
            </PickerContainer>
          </KPIGrid>

          <EditTargets
            key={'am1' + Math.random() * 1200}
            type={'add'}
            editTargets={this.state.isEditingTargets}
            data={this.state.premiumData}
            closeCallBack={this.closeEditTargetClickHandler}
            submitCallBack={this.submitEditTargetClickHandler}
          />
          <PreviewTargets
            key={'previewTargets'}
            type={'add'}
            editTargets={this.state.isPreviewTargets}
            data={this.state.premiumData}
            previewData={this.state.previewData}
            isFetchingPreview={this.state.isFetchingPreview}
            closeCallBack={this.closePreviewTargetClickHandler}
            submitCallBack={this.submitPreviewTargetClickHandler}
          />
        </div>
      </AnimatedView>
    );
  }
}
/*

    <RevenueChart title={'Retention'} id={'fff22'} data={this.state.retentionData} />
    <RevenueChart title={'Revenue/Cost'} id={'ff322'} data={this.state.revCostData} />
*/

const DataTile = ({ label, data }) => {
  let rows = data.map((item, index) => {
    return <KpiGameDetailsValues key={label + '<>' + index}>{item}</KpiGameDetailsValues>;
  });

  return (
    <div className="kpiGameDetailsDataTile">
      <KPIGameDetailsLabels>{label}</KPIGameDetailsLabels>
      <div>{rows}</div>
    </div>
  );
};

const PlatformButton = ({ label, img, slug, selected, callBack }) => {
  //region Platform Button Styles
  const Button = styled.div`
    display: flex;
    cursor: pointer;
    width: 140px;
    height: 34px;
    border-color: rgb(28, 28, 28);
    border-radius: 3px;
    background-image: -moz-linear-gradient(
      90deg,
      rgb(36, 39, 43) 0%,
      rgb(40, 43, 48) 100%,
      rgb(40, 43, 48) 100%,
      rgb(40, 43, 48) 100%
    );
    background-image: -webkit-linear-gradient(
      90deg,
      rgb(36, 39, 43) 0%,
      rgb(40, 43, 48) 100%,
      rgb(40, 43, 48) 100%,
      rgb(40, 43, 48) 100%
    );
    background-image: -ms-linear-gradient(
      90deg,
      rgb(36, 39, 43) 0%,
      rgb(40, 43, 48) 100%,
      rgb(40, 43, 48) 100%,
      rgb(40, 43, 48) 100%
    );
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.44), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.25);
    /*box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);*/
    font-style: normal;
    padding-top: 1px;
    margin-right: 3rem;
    border-style: solid;
    border-width: 1px;
    font-size: 1.3rem;
    line-height: 3rem;
    font-family: 'Roboto', sans-serif;
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.075em;
    align-items: center;
    justify-items: center;
  `;

  const ButtonSelected = styled(Button)`
    background-image: -moz-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
    background-image: -webkit-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
    background-image: -ms-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.36), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
    cursor: pointer;
    box-sizing: border-box;
  `;

  const Label = styled.span`
    width: 100%;
    text-align: center;
  `;

  const Icon = styled.img`
    width: 2rem;
    height: 2rem;
    margin-left: 1.5rem;
    margin-right: -1rem;
  `;
  //endregion
  return selected ? (
    <ButtonSelected>
      <Icon src={img} />
      <Label>{label}</Label>
    </ButtonSelected>
  ) : (
    <Button
      onClick={() => {
        callBack(slug);
      }}
    >
      <Icon src={img} />
      <Label>{label}</Label>
    </Button>
  );
};

const PlatformPicker = ({ selectedPlatform, platforms, callBack }) => {
  const Picker = styled.div`
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: left;
    padding: 0.2rem;
  `;

  let platformsToDisplay = [];
  platformsToDisplay.push(
    <PlatformButton
      key={'all'}
      label={'ALL'}
      img={all_icon}
      slug={'all'}
      selected={selectedPlatform === 'all'}
      callBack={callBack}
    />
  );

  let sort = 'name';
  platforms.sort((a, b) => {
    if (a[sort].toLowerCase() < b[sort].toLowerCase()) return -1;

    if (a[sort].toLowerCase() > b[sort].toLowerCase()) return 1;

    return 0;
  });

  platforms.map(item => {
    platformsToDisplay.push(
      <PlatformButton
        key={item.slug}
        label={item.name}
        img={item.icon}
        slug={item.slug}
        selected={selectedPlatform === item.slug}
        callBack={callBack}
      />
    );
  });

  return <Picker>{platformsToDisplay}</Picker>;
};
/*
const RagStatus = ({ data }) => {
  const green = '#24a159';
  const red = '#c2241e';
  const yellow = '#edb103';

  const startValue = 190;
  const lengthValue = 160;
  const targetLine = startValue + 100;

  let daysForRagStatus = data.ignition_range;
  let expectedReturnRate = data.expected.roi;
  let expectedARPDAU = data.expected.ltv;
  let expectedDAU = data.expected.cpi;

  let actualReturnRate = data.actual.roi;
  let arpdau = data.actual.ltv;
  let dau = data.actual.cpi;

  let ltv_period = data.ltv_period;

  let roiAngle =
    (expectedReturnRate
      ? (actualReturnRate ? actualReturnRate.toFixed(2) : 0) / expectedReturnRate
      : 0) * 100;
  let arpAngle = (expectedARPDAU ? (arpdau ? arpdau.toFixed(2) : 0) / expectedARPDAU : 0) * 100;
  let dauAngle = (expectedDAU ? expectedDAU / (dau ? dau.toFixed(2) : 10000) : 0) * 100;

  const roiColor = roiAngle >= 80 ? (roiAngle >= 100 ? green : yellow) : red;
  const arpColor = arpAngle >= 80 ? (arpAngle >= 100 ? green : yellow) : red;
  const dauColor = dauAngle >= 80 ? (dauAngle >= 100 ? green : yellow) : red;

  const roiStyle = {
    color: roiColor
  };
  const arpStyle = {
    color: arpColor
  };
  const dauStyle = {
    color: dauColor
  };

  const roiTxt = actualReturnRate ? `${actualReturnRate.toFixed(2)}` : '-';
  const arpTxt = arpdau ? Number(arpdau).toFixed(2) : '-';
  const dauTxt = dau ? numberFormatter(dau.toFixed(2)) : '-';

  roiAngle += startValue;
  arpAngle += startValue;
  dauAngle += startValue;

  const actualLine = roiAngle; //Math.min(roiAngle, arpAngle, dauAngle);

  let boxClassNames = 'box';
  let headerClassNames = 'header';

  if (actualLine < startValue + 80) {
    headerClassNames += ' headerRed';
    boxClassNames += ' boxRed';
  } else if (actualLine > startValue + 80 && actualLine < startValue + 100) {
    headerClassNames += ' headerYellow';
    boxClassNames += ' boxYellow';
  } else if (actualLine >= startValue + 100) {
    headerClassNames += ' headerGreen';
    boxClassNames += ' boxGreen';
  }
  let from = moment(data.data_collection_start, 'DD-MM-YYYY').format('DD.MM.YYYY');
  let to = moment(data.data_collection_end, 'DD-MM-YYYY').format('DD.MM.YYYY');
  return (
    <div className="kpiGameDetailsRagStatus">
      <div className={boxClassNames}>
        <h3 className={headerClassNames}>Ignition Status</h3>

        <IgnitionStatusContainer>
          <Gauge
            targetLine={targetLine}
            actualLine={actualLine}
            roiAngle={roiAngle}
            arpAngle={arpAngle}
            dauAngle={dauAngle}
            roiColor={roiColor}
            arpColor={arpColor}
            dauColor={dauColor}
            roiTxt={roiTxt}
            arpTxt={arpTxt}
            dauTxt={dauTxt}
          />
        </IgnitionStatusContainer>

        <p />
        <p className={'box__value'}>
          <span />
          <span>Target</span>
          <span>Actuals</span>
        </p>
        <p className={'box__value'}>
          <span>ROI (%):</span>
          <span>{expectedReturnRate ? `${expectedReturnRate}` : '-'}</span>
          <span style={roiStyle}>{actualReturnRate ? `${actualReturnRate.toFixed(2)}` : '-'}</span>
        </p>
        <p className={'box__value'}>
          <span>LTV ($):</span>
          <span>{expectedARPDAU ? expectedARPDAU.toFixed(2) : '-'}</span>
          <span style={arpStyle}>{arpdau ? arpdau.toFixed(2) : '-'}</span>
        </p>
        <p className={'box__value'}>
          <span>CPI ($):</span>
          <span>{expectedDAU ? Number(expectedDAU.toFixed(2)) : '-'}</span>
          <span style={dauStyle}>{dau ? dau.toFixed(2) : '-'}</span>
        </p>

        <div className={'daysForRagStatus'}>
          Locked for {from} - {to}. ROI based on LTV ({data.ltv_period}
          d)
        </div>
      </div>
    </div>
  );
};*/
export default KpiGameDetails;
//region Styles

const IgnitionStatusContainer = styled.div`
  margin: 0;
  height: 23rem;
  width: 40rem;
`;
const IgnitionStatusButtons = styled.div`
  display: flex;
  width: 300px;
`;

const GameDetailTitle = styled.h2`
  width: 95%;
  color: #fff;
  padding: 0rem 0rem 2.5rem;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #636975;
  padding-top: 1rem;
  margin-right: 5rem;
  margin-left: 3.5rem;
  margin-bottom: 1rem;
`;

const GameDetailIcon = styled.img`
  height: 5rem;
  width: 5rem;
  border-radius: 10px;
  margin-top: -1rem;
  margin-right: 1rem;
  margin-left: 0rem;
  margin-bottom: -2rem;
`;
export const Title = styled.div`
  color: #fefefe;
  font-size: 1.8rem;
  line-height: 3.6rem;
  font-weight: 600;
  padding-left: 8px;
  margin-top: 0.5rem;
  margin-bottom: 20px;
  border-bottom: 1px solid #606064;
  box-sizing: border-box;
`;

const KPIGrid = styled.div`
  display: grid;
  grid-template-columns: 5rem 42rem 120rem 1fr;
  grid-gap: 20px;
  overflow-x: hidden;
`;

const PlatformButtons = styled.div`
  grid-column: 2/4;
  grid-row: 1;
`;

const GaugeContainer = styled.div`
  grid-column: 2/3;
  grid-row: 2/5;
`;

const Panels = styled.div`
  grid-column: 3/4;
  grid-row: 2;
  display: grid;
  grid-template-columns: 120rem 1fr;
  grid-row-gap: 10px;
`;
const EditTarget = styled.div`
  grid-column: 2/3;
  grid-row: 5;
  width: 100%;
  height: 3.6rem;
  display: flex;
  justify-content: space-around;
`;

const Charts = styled.div`
  grid-column: 2/5;
  grid-row: 7;
  display: grid;
  grid-template-columns: 80rem 80rem;
  grid-column-gap: 3rem;
  grid-row-gap: 30px;
  padding-bottom: 30px;
`;

const KPIPanel = styled.div`
  margin: 0px;
  background-color: #282b30;
  width: 100%;
  color: #e1e1e5;
  font-weight: 400;
  border: 0.5px solid #36393f;
  border-radius: 3px;
  padding: 15px 20px;
  padding-top: 0px;
  grid-column: 1;
  grid-row: 1;
`;

const PickerContainer = styled.div`
  grid-column: 2/3;
  grid-row: 6;
  padding-top: 40px;
  width: 332px;
`;

const RevenueContainer = styled.div`
  grid-column: 1/2;
  grid-row: 1;
`;

const RetentionContainer = styled.div`
  grid-column: 2/3;
  grid-row: 1;
`;

const RevcostContainer = styled.div`
  grid-column: 1/2;
  grid-row: 2;
`;

const DauMauContainer = styled.div`
  grid-column: 2/3;
  grid-row: 2;
`;

const ArpdauContainer = styled.div`
  grid-column: 1/2;
  grid-row: 3;
`;

const CpiLtvRoiContainer = styled.div`
  grid-column: 2/3;
  grid-row: 3;
`;

const KPIGameDetailsLabels = styled.span`
  height: 3rem;
  text-align: center;
  display: block;
  color: #ffffff;
  background: #24272b;
  letter-spacing: 0.05rem;
  line-height: 3rem;
  font-weight: 500;
  box-sizing: border-box;
`;

const KpiGameDetailsValues = styled(KPIGameDetailsLabels)`
  font-weight: normal;
  background-color: #282b30;
  color: #99999c;
  border-top: 1px solid #36393f;
`;

const TotalLoanPanel = styled(KPIPanel)`
  width: 400px;
  color: #e1e1e5;
  grid-column: 1;
  grid-row: 2;
`;

const Clicker = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
  background-color: #282b30;
  opacity: 0.7;
  z-index: 100;
`;
//endregion
