import React, { Component, Fragment } from 'react';
import styled, { keyframes, css } from 'styled-components';

class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousValue: 0
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    //  console.log('should update progres bar', this.props.value != nextProps.value);
    return this.props.value != nextProps.value;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.previousValue !== prevProps.value) {
      this.setState({ previousValue: prevProps.value });
    }
  }

  render() {
    // console.log('render progress bar');
    return this.props.rounded ? (
      <BackgroundRounded height={this.props.height}>
        <ValueRounded
          height={this.props.height}
          value={this.props.value}
          previousValue={this.state.previousValue}
        />
        {this.props.notDisplayText ? null : <ProgressBarText>{this.props.value}%</ProgressBarText>}
      </BackgroundRounded>
    ) : (
      <ProgressBarBackground height={this.props.height}>
        <ProgressBarValue
          height={this.props.height}
          value={this.props.value}
          previousValue={this.state.previousValue}
        />
        {this.props.notDisplayText ? null : <ProgressBarText>{this.props.value}%</ProgressBarText>}
      </ProgressBarBackground>
    );
  }
}

export default ProgressBar;

const ProgressBarBackground = styled.div`
  width: 100%;
  height: ${props => (props.height ? props.height + 'px' : '20px')};
  background: #141617;
  position: relative;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.2), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
`;

function progressBuilder(percent, previous) {
  //console.log('percent, previous', percent, previous);

  const progress = keyframes`
    0%  { width:${previous}%;}
    100%{ width:${percent}%;}
`;

  const animation = props =>
    css`
      ${progress} 1s ease;
    `;

  return animation;
}

function barColor(value) {
  //console.log('barcolor', value);
  let color;
  if (value < 50) {
    color = '#d32212';
  } else if (50 <= value && value < 100) {
    color = '#f5b22a';
  } else if (value == 100) {
    color = '#39b549';
  }

  return color;
}

const ProgressBarValue = styled.div`
  width: ${props => (props.value ? `${props.value}%` : '0%')};
  position: absolute;
  height: ${props => (props.height ? props.height - 2 + 'px' : '18px')};
  margin: 1px 1px;
  transition: 0.4s linear;
  transition-property: width, background-color;
  background-color: ${props => (props.value ? `${barColor(props.value)}` : '#39b549')};
  animation: ${props =>
    props.value ? progressBuilder(props.value, props.previousValue) : progressBuilder(0)};
`;

const ValueRounded = styled(ProgressBarValue)`
  border-radius: 20px;
`;

const BackgroundRounded = styled(ProgressBarBackground)`
  border-radius: 20px 20px;
`;

const ProgressBarText = styled.div`
  position: absolute;
  font-size: 12px;
  font-weight: bolder;
  color: #fafafa;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 1);
`;
