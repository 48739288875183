import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import CommonButton, { Button } from 'components/CommonButton/index';
import storage from 'utils/storage';
import LoadingIndicator from 'components/LoadingIndicator';
import Textfield from 'components/Textfield';
import Popup from 'components/Popup';
import PasswordForm from './PasswordForm';
import warning from './assets/warning.png';
import PlayStackLogo from 'components/PlayStackLogo';

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waiting: false,
      secret: '',
      invalidSecret: false,
      isChanged: null,
      showPopup: false,
      invalidInput: false
    };
    this.mounted = false;
    this.checkSecret = this.checkSecret.bind(this);
    this.saveNewPassword = this.saveNewPassword.bind(this);
    this.returnLogin = this.returnLogin.bind(this);
  }

  componentWillMount() {}

  componentDidMount() {
    this.mounted = true;
    let phrase = this.props.location.search;
    let code = phrase.match(/\?code=(.*)/)[1];

    if (phrase.startsWith('?code=')) {
      this.setState({ waiting: true, secret: code }, () => {
        this.checkSecret();
      });
    }
  }

  returnLogin() {
    this.props.history.push({
      pathname: `/login`
    });
  }

  checkSecret() {
    const PATH_BASE = storage.ADMIN_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/user/password_change/check_secret`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let payload = {
      secret: this.state.secret
    };

    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status === 200) {
          this.setState({ waiting: false });
          return json;
        } else {
          this.setState({ waiting: false, invalidSecret: true });
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        return json;
      })
      .catch(error => {
        console.error(error);
      });
  }

  saveNewPassword(new_pass) {
    this.setState({ waiting: true });
    const PATH_BASE = storage.AUTH_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/change_password`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let payload = {
      new_password: new_pass,
      secret: this.state.secret
    };

    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status === 200) {
          this.setState({ waiting: false, showPopup: true, isChanged: true });
          return json;
        } else if (status === 400) {
          this.setState({ waiting: false, invalidInput: true });
        } else {
          this.setState({ waiting: false, showPopup: true, isChanged: false });
        }
      })
      .then(json => {
        if (json === undefined) return;
        return json;
      })
      .catch(error => {
        console.error(error);
      });
  }
  render() {
    if (!this.mounted) return null;
    if (this.state.waiting) {
      return <LoadingIndicator />;
    }
    let change = null;
    if (this.state.isChanged !== null) {
      if (this.state.isChanged) {
        change = (
          <Fragment>
            <PopupText>
              Password has been successfully changed. <br />
              You have been logged out from all existing sessions.
            </PopupText>
            <ButtonOne>
              <CommonButton text={'Return to login'} onClick={this.returnLogin} />
            </ButtonOne>
          </Fragment>
        );
      } else {
        change = (
          <Fragment>
            <PopupText>Error changing password: password reset link expired</PopupText>
            <ButtonOne>
              <CommonButton text={'Return to login'} onClick={this.returnLogin} />
            </ButtonOne>
          </Fragment>
        );
      }
    }

    return (
      <Fragment>
        <Popup
          title={'Change Password'}
          mainColor={'#04d5ac'}
          show={this.props.showPopup}
          popupheight={255}
          visible={this.state.showPopup}
        >
          {change}
        </Popup>
        {this.state.invalidSecret ? (
          <ContainerMain>
            <PlayStackLogo />{' '}
            <ContainerInvalid>
              <img src={warning} /> It looks like you clicked on an invalid password reset link or
              it has already expired.
            </ContainerInvalid>
            <ButtonContainer>
              <CommonButton text="Back to login page" onClick={this.returnLogin} />
            </ButtonContainer>
          </ContainerMain>
        ) : (
          <Container>
            <PlayStackLogo />

            <PasswordForm
              title={'Change Password'}
              saveNewPassword={this.saveNewPassword}
              invalidInput={this.state.invalidInput}
            />
          </Container>
        )}
      </Fragment>
    );
  }
}

export default ChangePasswordForm;

//region Styles
const PopupText = styled.div`
  color: #e1e1e5;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 25px;
  letter-spacing: 0.05rem;
  padding: 0px 40px;
  line-height: 1.5;
  font-size: 14px;
`;

const ButtonOne = styled.div`
  margin: 0 auto;
  width: 33%;
`;

const Container = styled.div`
  margin: 0 auto;
  margin-top: 200px;
  text-align: center;
  width: 300px;
`;

const ContainerMain = styled.div`
  margin: 0 auto;
  margin-top: 270px;
  text-align: center;
  width: 600px;
  @media (max-width: 594px) {
    width: 300px;
  }
`;

const ContainerInvalid = styled.div`
  color: #f29c10;
  font-size: 12px;
  letter-spacing: 0.1rem;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  width: 225px;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
`;
//endregion
