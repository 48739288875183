import React, { Component, Fragment } from 'react';
import AnimatedMenu from 'components/AnimatedMenu';
import './style.css';
import MenuBarButton from '../MenuBarButton';
import kpi from './assets/kpi.png';
import kpiext from './assets/kpi_external.png';
import admin from './assets/admin.png';
import ua from './assets/ua.png';
import playignite from './assets/playignite.png';
import back from './assets/back_arrow.png';
import games_management from './assets/games_management.png';
import storage from 'utils/storage';
import styled from 'styled-components';
import PlayStackLogo from '../../components/PlayStackLogo';
import { MenuBarContainer } from 'views/App';

const Separator = styled.div`
  display: flex;
  width: 6rem;
  height: 0.5rem;
  background-color: #d5aa06;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

class MenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = { date: new Date() };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    let items = [];

    for (let [k, v] of Object.entries(this.props.config)) {
      if (k === 'back') {
        items.push(
          <MenuBarButton
            key="backKey"
            selected={`/${k}` === this.props.history.location.pathname}
            image={back}
            color="#282b30"
            text="Back"
            target="back"
            history={this.props.history}
            submenu={v}
          />
        );
      } else if (k === 'hardBack') {
        items.push(
          <MenuBarButton
            key="hardBackKey"
            selected={`/${k}` === this.props.history.location.pathname}
            image={back}
            color="#282b30"
            text="Back"
            target="/"
            history={this.props.history}
            submenu={v}
          />
        );
      } else if (k === 'separator') items.push(<Separator />);
      else if (k === 'kpi' && storage.USER.privileges.includes('access_analytics_section')) {
        items.push(
          <MenuBarButton
            key="kpiKey"
            selected={this.props.history.location.pathname.indexOf(k) != -1}
            image={kpi}
            color="#00d5a9"
            text="KPIs and Trends"
            target="/kpi"
            history={this.props.history}
            submenu={v}
          />
        );
      } else if (
        k === 'external' &&
        storage.USER.privileges.includes('access_external_ignition_status_section')
      ) {
        items.push(
          <MenuBarButton
            key="kpiExtKey"
            selected={this.props.history.location.pathname.indexOf(k) != -1}
            image={kpiext}
            color="#73e5d2"
            text="Ignition Capital Analysis"
            target="/external"
            history={this.props.history}
            submenu={v}
          />
        );
      } else if (
        k === 'acquisition' &&
        storage.USER.privileges.includes('access_acquisition_section')
      ) {
        items.push(
          <MenuBarButton
            key="acquisitionKey"
            selected={this.props.history.location.pathname.indexOf(k) != -1}
            image={ua}
            color="#57aaed"
            text="User Acquisition"
            target="/acquisition"
            history={this.props.history}
            submenu={v}
          />
        );
      } else if (k === 'financial' && storage.USER.privileges.includes('access_appstart_section')) {
        items.push(
          <MenuBarButton
            key="financialKey"
            selected={this.props.history.location.pathname.indexOf(k) != -1}
            image={playignite}
            color="#db46b9"
            text="PlayIgnite Ledger"
            target="/financial"
            history={this.props.history}
            submenu={v}
          />
        );
      } else if (
        k === 'playignite' &&
        (storage.USER.privileges.includes('access_playignite_admin_section') ||
          storage.USER.privileges.includes('access_playignite_client_section')) &&
        !(
          storage.USER.privileges.includes('access_playignite_admin_section') &&
          storage.USER.privileges.includes('access_playignite_client_section')
        )
      ) {
        items.push(
          <MenuBarButton
            key="playigniteKey"
            selected={this.props.history.location.pathname.indexOf(k) != -1}
            image={playignite}
            color="#db46b9"
            text="PlayIgnite"
            target="/playignite"
            history={this.props.history}
            submenu={v}
          />
        );
      } else if (k === 'admin' && storage.USER.privileges.includes('view_admin_panel')) {
        items.push(
          <MenuBarButton
            key="adminKey"
            selected={this.props.history.location.pathname.indexOf(k) != -1}
            image={admin}
            color="#93278f"
            text="Admin Panel"
            target="/admin"
            history={this.props.history}
            submenu={v}
          />
        );
      } else if (
        k === 'gms' &&
        storage.USER.privileges.includes('access_games_management_section')
      ) {
        items.push(
          <MenuBarButton
            key="gmsKey"
            selected={this.props.history.location.pathname.indexOf(k) != -1}
            image={games_management}
            color="rgb(45,67,156)"
            text="Games Management    "
            target="/gms"
            history={this.props.history}
            submenu={v}
          />
        );
      } else if (k === 'dev' && process.env.NODE_ENV === 'development') {
        items.push(
          <MenuBarButton
            key="devKey"
            selected={`/${k}` === this.props.history.location.pathname}
            image={ua}
            color="#8d8d8d"
            text="Dev"
            target="/dev"
            history={this.props.history}
            submenu={v}
          />
        );
      }
    }
    return (
      <MenuBarContainer key="sideBarKey">
        <PlayStackLogo
          callBack={() => {
            this.props.history.push({
              pathname: '/',
              search: '',
              state: { detail: 'detail' }
            }); //`${this.props.target}/roster/${f.nr}` ; //this.props.target
          }}
          small
        />
        <AnimatedMenu>{items}</AnimatedMenu>
      </MenuBarContainer>
    );
  }
}

export default MenuBar;
