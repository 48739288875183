import React, { Component, Fragment } from 'react';
import Textfield from 'components/Textfield';
import styled from 'styled-components';
import CommonButton, { Button } from 'components/CommonButton/index';
import x_white from '../assets/x_white.png';

const PopupAddDeveloper = ({ closeBtn, yesBtn, onChange, slug, itemType, needUpdate }) => {
  return (
    <Popup color={'#04d5ac'}>
      <CloseIcon src={x_white} onClick={closeBtn} />
      <PopupTitle color={'#04d5ac'}>Add Developer</PopupTitle>
      <PopupText>Enter Developer name:</PopupText>
      <TextFieldContainer>
        <Textfield
          autoFocus={true}
          displayLabel={false}
          placeholder={`Enter the developer name`}
          id={'addDeveloperName'}
          onChange={onChange}
        />
      </TextFieldContainer>
      <ButtonPanel>
        <NoBtn>
          <CommonButton text="Cancel" cancel={true} onClick={closeBtn} />
        </NoBtn>
        <YesBtn>
          <Button needUpdate={needUpdate} onClick={yesBtn}>
            Confirm
          </Button>
        </YesBtn>
      </ButtonPanel>
    </Popup>
  );
};
//yesBtn
export default PopupAddDeveloper;

//region Styles
const Popup = styled.div`
  display: block;
  position: fixed;
  width: 600px;
  height: 320px;
  top: 50%;
  left: 50%;
  border-radius: 5px;

  background-color: #24272b;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 101;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.7), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
  border: 1px solid;
  border-color: ${props => (props.color ? props.color : '#04d5ac')};
`;

const PopupTitle = styled.div`
  width: 100%;
  background-color: ${props => (props.color ? props.color : '#04d5ac')};
  height: 80px;
  margin: 0px;
  font-size: 22px;
  padding-top: 25px;
  letter-spacing: 0.075rem;
  text-align: center;
  font-weight: bold;
  font-family: 'Titillium Web';
`;

const PopupText = styled.div`
  color: #e1e1e5;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  letter-spacing: 0.05rem;
  padding: 0px 40px;
  line-height: 2.5;
  font-size: 14px;
`;

const ButtonPanel = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 15rem 15rem 1fr;
  grid-column-gap: 10px;
  margin: 0 auto;
`;

const NoBtn = styled.div`
  grid-row: 1;
  grid-column: 2/3;
`;

const YesBtn = styled.div`
  grid-row: 1;
  grid-column: 3/4;
`;

const CloseIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const TextFieldContainer = styled.div`
  width: 25rem;
  margin-left: 170px;
  margin-top: -30px;
  margin-bottom: 20px;
`;

//endregion
