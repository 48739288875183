export const format = x => {
  if (!x) {
    return x;
  }
  x = Number(x).toFixed(2);
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts[1] === '00') return parts[0];
  else return parts.join('.');
  /*if (parts[1] && parts[1] > 100) {
    parts[1] %= 100;
  }*/
  //return parts.join('.');
};

export default format;
