import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import download from '../../assets/download.png';
import upload from '../../assets/upload.png';
import check_green from '../../assets/check_green.png';
import x_red from '../../assets/x_red.png';
import ProductsTable from '../index';

const changingText = (name, f, l) => {
  if (name.length > 18) {
    let start = name.substring(0, f);

    let end = name.slice(l);

    let textNew = start + '...' + end;
    return textNew;
  } else {
    return name;
  }
};

class FileManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      file: {
        ignition_capital: null,
        playcapital: null,
        playcredits: null
      }
    };
    this.handleMenu = this.handleMenu.bind(this);
    this.handleSubMenu = this.handleSubMenu.bind(this);
    this.menuFrame = React.createRef();
    this.handleFile = this.handleFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    let submenu = {};
    Object.entries(this.props.data).map(([k, v]) => {
      submenu[v.slug] = false;
    });
    this.setState({ submenu: submenu });
  }

  handleMenu() {
    let newState = { ...this.state };
    if (!this.state.isOpen) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
    let isOpen = this.state.isOpen;
    newState.isOpen = !isOpen;
    if (this.props.actionType == 'upload') {
      this.props.clearUploadStatus('all');
    }
    if (!isOpen == false) {
      newState.file.ignition_capital = null;
      newState.file.playcapital = null;
      newState.file.playcredits = null;
      if (this.props.actionType == 'upload') {
        this.props.clearUploadStatus('all');
      }
    }
    this.setState(newState);
  }

  handleOutsideClick(e) {
    if (!this.menuFrame.current) {
      return;
    }
    if (this.menuFrame) {
      if (this.menuFrame.current.contains(e.target)) {
        // console.log('handler contains');
        return;
      }

      this.handleMenu();
    }
  }

  handleFile(e, slug) {
    //console.log('handle add file', slug,e.target.files[0] );
    if (!e.target.files[0]) {
      this.props.clearUploadStatus(slug);
    }
    let newState = { ...this.state };
    newState.file[slug] = e.target.files[0];
    this.setState(newState);
  }

  deleteFile(slug) {
    //console.log('delete file', slug);
    document.getElementById('fileUpload' + slug).value = '';
    this.props.clearUploadStatus(slug);
    setTimeout(this.handleDelete, 4, slug);
  }

  handleDelete(slug) {
    // console.log('handle Delete', slug);
    let newState = { ...this.state };
    newState.file[slug] = null;
    this.setState(newState);
  }

  handleSubMenu(product) {
    // console.log('hello submenu for', product);
    let newState = { ...this.state };
    let currSubMenuState = newState.submenu[product];
    newState.submenu[product] = !currSubMenuState;
    this.setState(newState);
  }

  render() {
    if (this.props.actionType === 'download') {
      return (
        <FileManagerContainer>
          <Icon onClick={this.handleMenu} src={download} />
          <div ref={this.menuFrame}>
            {this.state.isOpen ? (
              <MenuContainer width={240}>
                {Object.entries(this.props.data).map(([k, v]) => (
                  <Fragment key={v.slug + this.props.element}>
                    <ProductContainer active={true}>
                      <ProductTitle>{v.name}</ProductTitle>
                      <ScrollableContainer>
                        {Object.entries(v.files).map(([fk, fv]) => (
                          <FileItem
                            key={fv.version + this.props.element}
                            current={fv.current}
                            onClick={() => {
                              this.props.handler(
                                v.slug,
                                this.props.element,
                                fv.version,
                                fv.file_name
                              );
                            }}
                            title={fv.file_name}
                          >
                            {fv.readable_version}
                            <FileName>{changingText(fv.file_name, 15, -15)}</FileName>
                          </FileItem>
                        ))}
                      </ScrollableContainer>
                    </ProductContainer>
                  </Fragment>
                ))}
              </MenuContainer>
            ) : null}{' '}
          </div>
        </FileManagerContainer>
      );
    }
    if (this.props.actionType === 'upload') {
      return (
        <FileManagerContainer>
          <Icon onClick={this.handleMenu} src={upload} />
          <div ref={this.menuFrame}>
            {this.state.isOpen ? (
              <MenuContainer>
                {Object.entries(this.props.data).map(([k, v]) => (
                  <ProductContainer key={v.slug + this.props.element} active={v.active}>
                    <ProductTitle>{v.name}</ProductTitle>
                    <LabelContainer>
                      {this.props.uploadStatus[v.slug] === 'SUCCESS' ? (
                        <UploadStatus success>File successfully uploaded.</UploadStatus>
                      ) : null}
                      {this.props.uploadStatus[v.slug] === 'UPLOADING' ? (
                        <UploadStatus upload>Uploading file...</UploadStatus>
                      ) : null}
                      {this.props.uploadStatus[v.slug] === 'FAILED' ? (
                        <UploadStatus failed>Failed to upload selected file.</UploadStatus>
                      ) : null}

                      <input
                        name={'fileUpload' + v.slug}
                        id={'fileUpload' + v.slug}
                        onChange={e => this.handleFile(e, v.slug)}
                        type="file"
                      />
                      <label htmlFor={'fileUpload' + v.slug}>Choose File</label>
                      {this.state.file[v.slug] == null ? (
                        <FileState>No file chosen</FileState>
                      ) : (
                        <FileState>{changingText(this.state.file[v.slug].name, 7, -10)}</FileState>
                      )}
                      {this.state.file[v.slug] !== null ? (
                        <Fragment>
                          <Accept
                            src={check_green}
                            alt="Upload file"
                            uploadStatus={this.props.uploadStatus[v.slug]}
                            onClick={() => {
                              this.props.handler(
                                this.state.file[v.slug],
                                v.slug,
                                this.props.element
                              );
                            }}
                          />
                          <Decline
                            src={x_red}
                            uploadStatus={this.props.uploadStatus[v.slug]}
                            onClick={() => this.deleteFile(v.slug)}
                            alt="Remove file"
                          />
                        </Fragment>
                      ) : null}
                    </LabelContainer>
                  </ProductContainer>
                ))}
              </MenuContainer>
            ) : null}{' '}
          </div>
        </FileManagerContainer>
      );
    }
  }
}

export default FileManager;

const FileName = styled.div`
  font-weight: normal;
  font-size: 1.1rem;
`;

const UploadStatus = styled.div`
  font-size: 10px;
  color: #ffa119;
  position: absolute;
  top: -16px;
  left: 6px;
  ${({ success }) =>
    success &&
    `
    color:#39b54a;
`};

  ${({ upload }) =>
    upload &&
    `

    color:#f8bd2c;
`};

  ${({ failed }) =>
    failed &&
    `


    color:#ce0f0f;
`};
`;

const ScrollableContainer = styled.div`
  max-height: 150px;
  overflow: auto;
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #242629;
    height: 1px;
    width: 8px;
  }
  ::-webkit-scrollbar {
    height: 8px;
    background-color: #242629;
    border-radius: 10px;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #00d5a9;
    width: 8px;
  }
`;

const Accept = styled.img`
  object-fit: contain;
  margin-right: 10px;
  cursor: pointer;

  ${({ uploadStatus }) =>
    uploadStatus === 'UPLOADING' &&
    `
   // -webkit-filter: grayscale(1);
	//-webkit-filter: grayscale(100%);
	//filter: gray;
	//filter: grayscale(100%);
	opacity:0.2;
	pointer-events:none !important;
	cursor:auto;
    `};
`;

const Decline = styled.img`
  object-fit: contain;
  cursor: pointer;

  ${({ uploadStatus }) =>
    uploadStatus === 'UPLOADING' &&
    `

   // -webkit-filter: grayscale(1);
	//-webkit-filter: grayscale(100%);
	//filter: gray;
	//filter: grayscale(100%);
	opacity:0.2;
	pointer-events:none !important;
	cursor:auto;
    `};
`;

const FileState = styled.div`
  line-height: 29px;
  margin-right: 10px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 20px;
  margin-bottom: 5px;
`;

const ProductTitle = styled.div`
  text-align: left;
  color: #fefefe;
  font-size: 1.3rem;
  line-height: 3.6rem;
  font-family: 'Titillium Web', sans-serif;
  padding-left: 8px;
  font-weight: bold;
  border-bottom: 1px solid #606064;
  margin-right: 20px;
  margin-bottom: 10px;
  box-sizing: border-box;
`;

const MenuContainer = styled.div`
  width: ${props => (props.width ? props.width + 'px' : '320px')};
  height: auto;
  position: absolute;
  background: #1e2022;
  border: 1px solid #2f3238;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;
  padding-bottom: 10px;
  padding-left: 10px;
`;

const ProductContainer = styled.div`
  height: auto;
  width: 100%;
  color: #fafafa;
  font-size: 12px;
  padding: 5px;
  margin: 0 auto;
  text-align: left;

  input {
    opacity: 0;
    display: none;
    position: absolute;
  }

  input + label {
    cursor: pointer;
    border-radius: 3px;
    padding: 6px 10px;
    margin-left: 4px;
    margin-right: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(30, 32, 34);
    font-size: 13px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.075em;
    text-align: center;
    background-color: #555555;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.36), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
    background-image: -webkit-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
    &:hover {
      background-image: -webkit-linear-gradient(90deg, rgb(23, 132, 113) 0%, rgb(0, 213, 169) 100%);
    }
    &:focus {
      outline: none;
    }
  }

  ${({ active }) =>
    !active &&
    `
  // -webkit-filter: grayscale(1);
	//-webkit-filter: grayscale(100%);
//	filter: gray;
//	filter: grayscale(100%);
	opacity:0.2;
	pointer-events:none !important;
	cursor:auto;
  `};
`;

const FileItem = styled.div`
  padding: 8px 5px;
  color: #e1e1e5;
  cursor: pointer;
  &:hover {
    color: #55dfc4;
  }
  ${({ current }) =>
    current &&
    `
    font-weight:bold;
    color:#00d5a9;
  `};
`;

const FileManagerContainer = styled.div`
  position: relative;
`;

const Icon = styled.img`
  cursor: pointer;
`;
