import React, { Component, Fragment } from 'react';
import './style.css';
import CommonButton from 'components/CommonButton/index';
import styled from 'styled-components';
import warning_icon from '../../../Login/LoginForm/assets/warning_icon.png';
import close_icon from '../assets/close_icon.png';
import CheckboxList from 'components/CheckboxList';
import LoadingIndicator from 'components/LoadingIndicator';
import Popup from 'components/Popup';

class UserPopup extends Component {
  constructor() {
    super();
    this.state = {
      revenue_group: 'daily',
      warning_name: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmitAndMore = this.handleSubmitAndMore.bind(this);
  }

  handleResetPasswordPopup() {
    this.setState({ isReset: true });
  }
  handleNoReset() {
    this.setState({ isReset: false });
  }

  render() {
    let title = this.props.type === 'edit' ? 'Edit User' : 'New User';
    if (this.props.type === 'delete') {
      title = 'Delete User';
      return (
        <Popup
          mainColor={'#04d5ac'}
          show={this.props.closeCallBack}
          title={title}
          popupheight={264}
          visible={this.props.visible}
        >
          <InfoPopupText>
            <p>
              You are about to delete the user{' '}
              <b>{this.props.userData ? this.props.userData.email : ''}</b>.
            </p>
            <p>Are you sure?</p>
          </InfoPopupText>
          <ButtonPanel>
            <div className="deleteBtn">
              <CommonButton text="Yes" onClick={this.handleDelete} />
            </div>
            <div className="cancelBtn">
              <CommonButton text="No" onClick={this.props.closeCallBack} cancel={true} />
            </div>
          </ButtonPanel>
        </Popup>
      );
    }
    if (this.props.type === 'edit') {
      let firstName = (this.props.userData ? this.props.userData.name : '')
        .split(' ')
        .slice(0, -1)
        .join(' ');
      let lastName = (this.props.userData ? this.props.userData.name : '')
        .split(' ')
        .slice(-1)
        .join(' ');

      return (
        <Fragment>
          <Popup
            mainColor={'#04d5ac'}
            show={this.props.handleNoReset}
            title={'Reset Password'}
            popupheight={364}
            visible={this.props.isSent}
          />
          <Popup
            mainColor={'#04d5ac'}
            show={this.props.handleNoReset}
            title={'Reset Password'}
            popupheight={364}
            visible={this.props.isReset}
          >
            {this.props.isFetching ? <LoadingIndicator /> : null}
            {this.props.isSent ? (
              <Fragment>
                <InfoPopupSend>
                  <TextBold>Email sent.</TextBold>
                  User should receive an email with their new password shortly.
                </InfoPopupSend>
                <ButtonOne>
                  {' '}
                  <CommonButton text={'OK'} cancel={false} onClick={this.props.handleNoReset} />
                </ButtonOne>
              </Fragment>
            ) : (
              <Fragment>
                <InfoPopupText>
                  You are about to reset password for the user <br />
                  <UserMail>{this.props.userData ? this.props.userData.email : ''}</UserMail>
                  <br /> User will receive email message with their new password <br />
                  and will be signed off from all existing sessions. <br /> <br />
                  This operation is permanent. <br /> Are you sure?
                </InfoPopupText>
                <ButtonPanel>
                  <BtnYesReset>
                    <CommonButton
                      text={'Yes'}
                      onClick={() =>
                        this.props.handleResetPassword(
                          this.props.userData ? this.props.userData.email : ''
                        )
                      }
                    />
                  </BtnYesReset>
                  <BtnNoReset>
                    <CommonButton text={'No'} cancel={true} onClick={this.props.handleNoReset} />
                  </BtnNoReset>
                </ButtonPanel>
              </Fragment>
            )}
          </Popup>
        </Fragment>
      );
    }
    let warn_info = null;
    if (this.state.warning_name) {
      warn_info = (
        <WarningElement>
          <img src={warning_icon} /> <span>Please fill Email field</span>
        </WarningElement>
      );
    }
    return (
      <div key="addUserPopupKey" className={'popup'}>
        <div className={'closeButton'} onClick={this.props.closeCallBack}>
          <img src={close_icon} />
        </div>
        <PopupTitle>{title}</PopupTitle>
        <div className={'popupForm'}>
          <div>{warn_info}</div>
          <form ref="form" onSubmit={this.handleSubmit}>
            <div className="labelContainer">
              <label className="labelForm" htmlFor="email">
                Email:
              </label>
              <input
                autoFocus
                className="textfield formText"
                type="text"
                id="email"
                name="email"
                placeholder="Your email.."
              />
            </div>
            <div className="labelContainer">
              <label className="labelForm" htmlFor="fname">
                First Name:
              </label>
              <input
                className="textfield formText"
                type="text"
                id="fname"
                name="firstname"
                placeholder="Your first name.."
              />
            </div>
            <div className="labelContainer">
              <label className="labelForm" htmlFor="lname">
                Last Name:
              </label>
              <input
                className="textfield formText"
                type="text"
                id="lname"
                name="lastname"
                placeholder="Your last name.."
              />
            </div>
            <label className="labelGroups" htmlFor="field">
              Groups:
            </label>
            <CheckboxContainer>
              {/* <CheckboxList
                groupsItems={this.props.groupsData}
                id={'addUser'}
                checkboxWidth={214}
              /> */}
            </CheckboxContainer>
            <ButtonPanel>
              <div className="submitBtn">
                <CommonButton text="Submit" onClick={this.handleSubmit} />
              </div>
              <div className="submitMoreBtn">
                <CommonButton text="Submit and add more" onClick={this.handleSubmitAndMore} />
              </div>
            </ButtonPanel>
          </form>
        </div>
      </div>
    );
  }

  handleDelete() {
    this.props.submitCallBack();
  }

  handleSubmit(ev) {
    ev.preventDefault();
    if (this.props.type === 'delete') {
      return;
    }
    let kvpairs = [];
    if (this.props.type === 'edit') kvpairs['id'] = this.props.userData.id;
    kvpairs['type'] = this.props.type;
    kvpairs['groups'] = [];
    let form = this.refs.form;
    for (let i = 0; i < form.elements.length; i++) {
      let e = form.elements[i];

      if (e.name === undefined || e.value === undefined || e.name === '') continue;
      if (e.name === 'email') {
        if (e.value === '') {
          this.setState({ warning_name: true });
          return;
        }
      }
      if (form.elements[i].type === 'checkbox') {
        if (form.elements[i].checked) kvpairs['groups'].push(parseInt(e.value));
      } else kvpairs[e.name] = e.value;
    }

    this.props.submitCallBack(kvpairs);
  }

  handleSubmitAndMore(ev) {
    ev.preventDefault();
    if (this.props.type === 'delete') {
      return;
    }
    let kvpairs = [];
    if (this.props.type === 'edit') kvpairs['id'] = this.props.userData.id;
    kvpairs['type'] = this.props.type;
    kvpairs['groups'] = [];
    let form = this.refs.form;
    for (let i = 0; i < form.elements.length; i++) {
      let e = form.elements[i];
      if (e.name === undefined || e.value === undefined || e.name === '') continue;
      if (e.name === 'email') {
        if (e.value === '') {
          this.setState({ warning_name: true });
          return;
        }
      }
      if (form.elements[i].type === 'checkbox') {
        if (form.elements[i].checked) kvpairs['groups'].push(parseInt(e.value));
      } else kvpairs[e.name] = e.value;
    }

    this.props.submitMoreCallBack(kvpairs);
    form.reset();
  }
}

export default UserPopup;

//region Styles
export const WarningElement = styled.div`
  visibility: visible;
  position: relative;
  color: #f29c10;
  font-size: 12px;
  letter-spacing: 0.1rem;
  padding-bottom: 8px;
  text-align: center;
`;

const UserMail = styled.span`
  font-weight: bold;
  color: #00d5a9;
  display: inline-block;
  margin: 5px 0px;
`;

export const PopupTitle = styled.h1`
  font-size: 3rem;
  margin-top: 4rem;
  margin-right: 2rem;
  margin-left: 2rem;
  margin-bottom: 4rem;
  text-align: center;
  color: #04d4ab;
`;

export const ButtonPanel = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  height: 3rem;
`;

const ButtonOne = styled.div`
  margin: 0 auto;
  width: 120px;
`;

const BtnYes = styled.div`
  margin-left: 5rem;
  width: 10rem;
  margin-right: 5rem;
`;

const BtnNo = styled.div`
  width: 15rem;
`;

const BtnYesReset = styled.div`
  width: 10rem;
`;

const BtnNoReset = styled.div`
  width: 10rem;
  margin-left: 5rem;
`;

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
  background-color: #282b30;
  opacity: 0.9;
  z-index: 100;
`;

const InfoPopupText = styled.div`
  color: #e1e1e5;
  text-align: center;
  margin: 30px 30px;
  letter-spacing: 0.05rem;
  line-height: 1.5;
  font-size: 14px;
`;

const InfoPopupSend = styled(InfoPopupText)`
  margin-top: 20px;
`;

const TextBold = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-bottom: 4px;
  margin-top: 70px;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  width: 247px;
`;

//endregion
