import React, { Component, PureComponent, Fragment } from 'react';
import { defaults, Bar, Line } from 'react-chartjs-2';
import styled, { injectGlobal, keyframes } from 'styled-components';
import Legend from '../UAChartLegend';
import formatNumberWithDots from 'utils/formatNumberWithDots';
import formatNumberWithK from 'utils/formatNumberWithK';
import {
  backgroundColorTable,
  borderColorTable,
  colorChartUAPalette,
  colorChartUABackground,
  colorChartUABackgroundHover
} from 'components/ColorPicker/index';

class UABarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false
    };
    this.chartRef = React.createRef();
    this.mounted = false;
  }
  /** UA Chart Lifecycle Methods */
  //region Lifecycle Methods
  /*

         ->   getDerivedStateFromProps {active_option: "Costs", data: Array(7), tableChange: ƒ} {tooltip: null, updateChart: false}

         -->   componentDidMount {tooltip: null, updateChart: false}

         ->   getDerivedStateFromProps {active_option: "Costs", data: Array(7), tableChange: ƒ} {tooltip: null, updateChart: true}

         -->   shouldComponentUpdate {tooltip: null, updateChart: false} {tooltip: null, updateChart: true}

   */
  static getDerivedStateFromProps(props, state) {
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("shouldComponentUpdate",this.state,nextState)
    //if(this.state.tooltip !== nextState.tooltip)return true;
    if (nextProps.data !== this.props.data) return true;
    if (nextProps.active_option !== this.props.active_option) return true;
    return false;
  }

  componentDidMount() {
    // console.log("componentDidMount",this.state)
    this.setState({ mounted: true });
    this.mounted = true;
    //console.log('this.chartRef)',this.chartRef);
    this.props.updateChartRef(this.chartRef);
    //this.forceUpdate();
  }

  componentWillUnmount() {}

  //endregion
  /** UA Chart Parsing Data */
  //region Parsing Data
  parseData(data, active_option) {
    let labels_data = [];
    let roi_12m = [];
    let roi_3m = [];
    let roi_6m = [];
    let roi_9m = [];
    let roi_12m_dashed = [];
    let roi_3m_dashed = [];
    let roi_6m_dashed = [];
    let roi_9m_dashed = [];
    let sources = [];

    data.map(item => {
      let predictions_reliable = item.predictions_reliable;
      let roi_usable = item.roi_usable;
      labels_data.push(item.date);
      Object.entries(item['sources']).map(([key, val]) => {
        if (sources[key] === undefined) sources[key] = [];
        if (sources[key].costs === undefined) sources[key].costs = [];
        if (sources[key].installs === undefined) sources[key].installs = [];
        sources[key].costs.push(val.costs);
        sources[key].installs.push(val.installs);
      });
      if (roi_usable == false) {
        roi_12m.push('No value');
        roi_3m.push('No value');
        roi_6m.push('No value');
        roi_9m.push('No value');
        roi_12m_dashed.push('No value');
        roi_3m_dashed.push('No value');
        roi_6m_dashed.push('No value');
        roi_9m_dashed.push('No value');
      } else if (roi_usable == true) {
        if (predictions_reliable == true) {
          roi_12m.push(item['12m_roi']);
          roi_3m.push(item['3m_roi']);
          roi_6m.push(item['6m_roi']);
          roi_9m.push(item['9m_roi']);
          roi_12m_dashed.push('No value');
          roi_3m_dashed.push('No value');
          roi_6m_dashed.push('No value');
          roi_9m_dashed.push('No value');
        } else if (predictions_reliable == false) {
          roi_12m_dashed.push(item['12m_roi']);
          roi_3m_dashed.push(item['3m_roi']);
          roi_6m_dashed.push(item['6m_roi']);
          roi_9m_dashed.push(item['9m_roi']);
          roi_12m.push('No value');
          roi_3m.push('No value');
          roi_6m.push('No value');
          roi_9m.push('No value');
        }
      }
    });

    let dataSets = [];
    dataSets.push({
      label: '3m',
      type: 'line',
      hidden: false,
      fill: false,
      borderWidth: 2,
      borderColor: borderColorTable[0],
      data: roi_3m,
      yAxisID: 'y-axis-2',
      pointHitDetectionRadius: 1
    });

    dataSets.push({
      label: '3m UNRELIABLE',
      type: 'line',
      hidden: false,
      fill: false,
      borderWidth: 2,
      borderColor: borderColorTable[0],
      borderDash: [5, 10],
      data: roi_3m_dashed,
      yAxisID: 'y-axis-2',
      pointHitDetectionRadius: 1
    });

    dataSets.push({
      label: '6m',
      type: 'line',
      hidden: false,
      fill: false,
      borderWidth: 2,
      borderColor: borderColorTable[1],
      data: roi_6m,
      yAxisID: 'y-axis-2',
      pointHitDetectionRadius: 1
    });

    dataSets.push({
      label: '6m UNRELIABLE',
      type: 'line',
      hidden: false,
      fill: false,
      borderWidth: 2,
      borderColor: borderColorTable[1],
      borderDash: [5, 10],
      data: roi_6m_dashed,
      yAxisID: 'y-axis-2',
      pointHitDetectionRadius: 1
    });

    dataSets.push({
      label: '9m',
      type: 'line',
      hidden: false,
      fill: false,
      borderWidth: 2,
      borderColor: borderColorTable[2],
      data: roi_9m,
      yAxisID: 'y-axis-2',
      pointHitDetectionRadius: 1
    });

    dataSets.push({
      label: '9m UNRELIABLE',
      type: 'line',
      hidden: false,
      fill: false,
      borderWidth: 2,
      borderColor: borderColorTable[2],
      borderDash: [5, 10],
      data: roi_9m_dashed,
      yAxisID: 'y-axis-2',
      pointHitDetectionRadius: 1
    });

    dataSets.push({
      label: '1y',
      type: 'line',
      hidden: false,
      fill: false,
      borderColor: borderColorTable[3],
      borderWidth: 2,
      data: roi_12m,
      yAxisID: 'y-axis-2',
      pointHitDetectionRadius: 1
    });

    dataSets.push({
      label: '1y UNRELIABLE',
      type: 'line',
      hidden: false,
      fill: false,
      borderColor: borderColorTable[3],
      borderDash: [5, 10],
      borderWidth: 2,
      data: roi_12m_dashed,
      yAxisID: 'y-axis-2',
      pointHitDetectionRadius: 1
    });

    let i = 0;
    Object.entries(sources).map(([key, val]) => {
      dataSets.push({
        label: key,
        type: 'bar',
        hidden: false,
        borderColor: colorChartUAPalette[i],
        backgroundColor: colorChartUABackground[i],
        borderWidth: 2,
        data: sources[key][active_option.toLowerCase()],
        yAxisID: 'y-axis-1',
        pointHitDetectionRadius: 1,
        hoverBackgroundColor: colorChartUABackgroundHover[i]
      });
      i++;
    });

    return {
      labels: labels_data,
      datasets: dataSets
    };
  }
  //endregion

  render() {
    let data = this.parseData(this.props.data, this.props.active_option);

    let dt;
    dt = Object.assign({}, data);
    let label_mark = '';
    let active_mode = '';
    if (this.props.active_option == 'Costs') {
      label_mark = '$';
      active_mode = 'Costs';
    } else if (this.props.active_option == 'Installs') {
      label_mark = '';
      active_mode = 'Installs';
    }
    let _this = this;
    let optionsChart = {
      events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove', 'touchend'],
      onClick: this.props.handleClick,
      hover: {
        onHover: function(e, el) {
          const section = el[0];
          const currentStyle = e.currentTarget.style;
          currentStyle.cursor = section ? 'pointer' : 'context-menu';
          var element = this.getElementAtEvent(e)[0];

          if (element) {
            _this.props.handleHover(element._model.datasetLabel);
          } else {
            _this.props.handleHover(null);
          }
        }
      },
      title: {
        display: false,
        text: ''
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: false,
          fontColor: '#A9A9AB'
        }
      },
      elements: { point: { radius: 0.04, hitRadius: 0, hoverRadius: 0.04 } },
      tooltips: {
        enabled: false,
        //labelColors: backgroundColorTable,
        yAlign: 'top',
        position: 'nearest',
        intersect: false,
        mode: 'label',

        custom: tooltipModel => {
          this.props.showTooltip(tooltipModel);
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            barPercentage: 0.5,
            stacked: true,
            gridLines: {
              drawBorder: true,
              color: '#2f3238'
            }
          }
        ],
        yAxes: [
          {
            stacked: true,
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-1',
            scaleLabel: {
              display: true,
              labelString: ''
            },

            gridLines: {
              drawBorder: true,
              color: '#2f3238'
            },
            labels: {
              show: true
            },
            ticks: {
              min: 0,
              beginAtZero: true,
              callback: function(label, index, labels) {
                let new_label = '';
                if (label_mark == '') {
                  new_label = formatNumberWithK(label);
                } else if (label_mark == '$') {
                  new_label = formatNumberWithDots(label);
                }
                return label_mark + new_label;
              }
            }
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-2',
            scaleLabel: {
              display: true,
              labelString: 'ROI'
            },
            gridLines: {
              display: false
            },
            labels: {
              show: true
            },
            ticks: {
              min: 0,
              beginAtZero: true,
              callback: function(label, index, labels) {
                return formatNumberWithDots(label) + '%';
              }
            }
          }
        ]
      }
    };
    return <Bar ref={this.chartRef} data={dt} options={optionsChart} />;
  }

  handleChange(event) {
    this.setState({ revenue_group: event.target.value });
  }
}

export default UABarChart;
