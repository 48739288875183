const storage = {
  CREDENTIALS: {
    appId: 33783,
    authKey: '4T8BXcvgmFFgsDy',
    authSecret: 'GagWxXn7aVQwExs'
  },
  CONFIG: null,
  TOKEN: null,
  USER: {
    token: null,
    groups: null,
    privileges: null,
    authed: false,
    first_name: null,
    last_name: null
  },
  current_view: null,
  ADMIN_SERVICE_URL: {
    production: 'https://dashboard.playstack.com/api/admin',
    staging: 'https://pulse-staging.playstack.com/api/admin',
    development: 'https://pulse-staging.playstack.com/api/admin'
    //development: 'https://pulse.playstack.com/api/admin'
  },
  APPSTART_SERVICE_URL: {
    production: 'https://dashboard.playstack.com/api/backend',
    staging: 'https://pulse-staging.playstack.com/api/backend',
    development: 'https://pulse-staging.playstack.com/api/backend'
    //development: 'https://pulse.playstack.com/api/backend'
  },
  AUTH_SERVICE_URL: {
    production: 'https://dashboard.playstack.com/api/auth',
    staging: 'https://pulse-staging.playstack.com/api/auth',
    development: 'https://pulse-staging.playstack.com/api/auth'
    //development: 'https://pulse.playstack.com/api/auth'
  },
  ACQ_SERVICE_URL: {
    production: 'https://dashboard.playstack.com/api/acquisition',
    staging: 'https://pulse-staging.playstack.com/api/acquisition',
    development: 'https://pulse-staging.playstack.com/api/acquisition'
    //development: 'https://pulse.playstack.com/api/acquisition'
  },
  GAMES_SERVICE_URL: {
    production: 'https://dashboard.playstack.com/api/games-admin',
    staging: 'https://pulse-staging.playstack.com/api/games-admin',
    development: 'https://pulse-staging.playstack.com/api/games-admin'
    //development: 'https://pulse.playstack.com/api/games-admin'
  },
  PLAYIGNITE_SERVICE_URL: {
    staging: 'https://pulse-staging.playstack.com/api/playignite',
    development: 'https://pulse-staging.playstack.com/api/playignite',
    production: 'https://dashboard.playstack.com/api/playignite'
    //development: 'https://pulse.playstack.com/api/playignite'
  },

  /*
  ADMIN_SERVICE_URL: {
    production: 'https://dashboard.playstack.com/api/admin',
    staging: 'http://35.197.240.58:5000',
    development: 'http://35.197.240.58:5000'
  },
  APPSTART_SERVICE_URL: {
    production: 'https://dashboard.playstack.com/api/backend',
    staging: 'http://35.197.205.121:5000',
    development: 'http://35.197.205.121:5000'
  },
  AUTH_SERVICE_URL: {
    production: 'https://dashboard.playstack.com/api/auth',
    staging: 'http://35.197.224.120:5000',
    development: 'http://35.197.224.120:5000'
  },
  ACQ_SERVICE_URL: {
    production: 'https://dashboard.playstack.com/api/acquisition',
    staging: 'http://35.197.220.79:5000',
    development: 'http://35.197.220.79:5000'
  },
  GAMES_SERVICE_URL: {
    production: 'https://dashboard.playstack.com/api/games-admin',
    staging: 'http://35.189.106.49:5000',
    development: 'http://35.189.106.49:5000'
  },*/
  COLORS: [
    '#57aaed',
    '#00d5a9',
    '#db46b9',
    '#93278f',
    '#57aaed',
    '#00d5a9',
    '#db46b9',
    '#93278f',
    '#57aaed',
    '#00d5a9',
    '#db46b9',
    '#93278f',
    '#57aaed',
    '#00d5a9',
    '#db46b9',
    '#93278f',
    '#57aaed',
    '#00d5a9',
    '#db46b9',
    '#93278f',
    '#57aaed',
    '#00d5a9',
    '#db46b9',
    '#93278f',
    '#57aaed',
    '#00d5a9',
    '#db46b9',
    '#93278f',
    '#57aaed',
    '#00d5a9',
    '#db46b9',
    '#93278f',
    '#57aaed',
    '#00d5a9',
    '#db46b9',
    '#93278f',
    '#57aaed',
    '#00d5a9',
    '#db46b9',
    '#93278f',
    '#57aaed',
    '#00d5a9',
    '#db46b9',
    '#93278f',
    '#292929',
    'rgba(199,20,193,0.45)',
    'rgba(24,255,255,0.45)',
    'rgba(53,255,0,0.45)',
    'rgba(249,36,61,0.45)',
    'rgba(227,144,13,0.45)',
    'rgba(232,83,254,0.45)',
    'rgba(198,255,0,0.45)',
    'rgba(170,83,254,0.45)',
    'rgba(205,175,0,0.45)',
    'rgba(41,121,255,0.45)',
    'rgba(178,255,89,0.45)',
    'rgba(206,147,216,0.45)',
    'rgba(221,44,0,0.45)',
    'rgba(198,255,0,0.45)',
    'rgba(100,255,218,0.45)',
    'rgba(255,111,0,0.45)',
    'rgba(179,136,255,0.45)',
    'rgba(255,0,96,0.45)',
    'rgba(0,176,255,0.45)',
    'rgba(0,84,255,0.45)',
    'rgba(108,201,48,0.45)',
    'rgba(255,255,141,0.45)',
    'rgba(213,0,249,0.45)',
    'rgba(240,88,88,0.45)',
    'rgba(128,204,248,0.45)',
    'rgba(6,175,102,0.45)',
    'rgba(0, 213, 169,0.4)',
    'rgba(80,170,238,0.4)',
    'rgba(219, 70, 185,0.4)',
    'rgba(243, 121, 39,0.4)',
    'rgba(255, 92, 54,0.4)',
    'rgba(198,255,0,0.4)',
    'rgba(255, 190, 139,0.4)',
    'rgba(112, 19, 50,0.4)',
    'rgba(32, 131, 140,0.4)',
    'rgba(123,31,162,0.4)',
    'rgba(182, 198, 195,0.4)',
    'rgba(221,44,0,0.4)',
    'rgba(24,255,255,0.4)'
  ],
  GAMES_DATA: [],
  GAMES_ICONS: [],
  GAMES_NAMES: [],
  PLATFORMS_ICONS: []
};

export default storage;
