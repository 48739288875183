import React, { Component, PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import storage from 'utils/storage';
import edit from '../assets/edit.png';
import delete_btn from '../assets/delete_btn.png';
import reset_pswd from '../assets/reset_password.png';
import AnimatedList from 'components/AnimatedList';

class UsersTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      itemRows: null
    };
  }
  /*static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(nextProps,prevState,'<<<<<')
    if(nextProps.itemRows==prevState.itemRows)return null
    else return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.itemRows !== nextProps.itemRows) {
      //console.log(this.props.itemRows,' <> ',nextProps.itemRows,' <> ',this.props.itemRows == nextProps.itemRows);
      //return true;
    }
    if (this.state.count !== nextState.count) {
      return true;
    }
    return true;
  }
*/

  renderItem(item) {
    const onMouseOverCallback = () => this.props.handleRowOver(item);
    const onMouseOutCallback = () => this.props.handleRowOut(item);
    const deleteUserHandler = () => this.props.deleteUserHandler(item);
    const handleResetPasswordPopup = () => this.props.handleResetPasswordPopup(item);
    const editUserHandler = () =>
      this.props.editUserHandler(
        item,
        this.props.permissionDropdown,
        this.props.gamesGroupsDropdown
      );

    let marked = null;
    let buttons = null;
    let isMarked = '#282b30';
    if (this.props.markedItem != null && item.id === this.props.markedItem.id) {
      marked = 'tableMarkedRow';
      isMarked = 'rgb(30, 32, 34)';
      if (storage.USER.privileges.includes('edit_users'))
        buttons = (
          <RowButtonsPanel key={'rowButtonKey'}>
            <RowButton onClick={editUserHandler}>
              <RowBtnTooltip>
                <BtnTooltipInfo>Edit user</BtnTooltipInfo>
              </RowBtnTooltip>
              <RowBtnImg src={edit} />
            </RowButton>
            <RowButton onClick={handleResetPasswordPopup}>
              <RowBtnTooltip>
                <BtnTooltipInfo>Reset password</BtnTooltipInfo>
              </RowBtnTooltip>
              <RowBtnImg src={reset_pswd} />
            </RowButton>
            <RowButton onClick={deleteUserHandler}>
              <RowBtnTooltip>
                <BtnTooltipInfo>Delete user</BtnTooltipInfo>
              </RowBtnTooltip>
              <RowBtnImg src={delete_btn} />
            </RowButton>
          </RowButtonsPanel>
        );
    }
    const itemRows = [
      <RowTable
        //className={marked}
        isMarked={isMarked}
        key={`row-data-${item.id}`}
        onMouseEnter={onMouseOverCallback}
        onMouseLeave={onMouseOutCallback}
      >
        <td className="tEmail">{item.email}</td>
        <td className="tName">{item.name}</td>
        <td className="tLastActive">{item.last_login}</td>
        <td className="tGroups">
          <SimpleList>
            {this.renderGroupsList(item.auth_user_groups, item.game_groups, item.id)}
          </SimpleList>
        </td>
        <td className={'financesInvCells'}>
          <AnimatedList>{buttons}</AnimatedList>
        </td>
      </RowTable>
    ];

    return itemRows;
  }

  renderGroupsList(groups, game_groups, markedItemId) {
    const items = [];
    groups.forEach(group => {
      const game_items = [];
      //if (this.props.markedItem != null && markedItemId === this.props.markedItem.id) {
      game_groups[group].map(item => {
        if (this.props.gameGroupsData[item]) {
          game_items.push(
            <li key={`row-data-list-${group}-${this.props.gameGroupsData[item].name}`}>
              {this.props.gameGroupsData[item].name}
            </li>
          );
        }
      });
      //}

      items.push(
        <li key={`row-data-list-${group}-${this.props.groupsData[group].name}`}>
          <ColorGroup color={this.props.groupsData[group].color}>
            <span>{this.props.groupsData[group].name}</span>
          </ColorGroup>

          <GameGroupsList key={'keyanimatedlist'}>{game_items}</GameGroupsList>
        </li>
      );
    });
    return items;
  }

  render() {
    let allUsersRows = [];
    let title = '';
    if (!this.props.searchMode) {
      if (this.props.selectedPermission == 'all') {
        for (let key in this.props.usersData) {
          let item = this.props.usersData[key];
          const perItemRows = this.renderItem(item);
          allUsersRows = allUsersRows.concat(perItemRows);
          if (this.props.markedItem != null && item.id === this.props.markedItem.id)
            title = item.title;
        }
      } else {
        for (let key in this.props.usersData) {
          if (
            this.props.usersData[key]['auth_user_groups'].includes(this.props.selectedPermission)
          ) {
            let item = this.props.usersData[key];
            const perItemRows = this.renderItem(item);
            allUsersRows = allUsersRows.concat(perItemRows);
            if (this.props.markedItem != null && item.id === this.props.markedItem.id)
              title = item.title;
          }
        }
      }
    } else if (this.props.searchMode) {
      if (this.props.selectedPermission == 'all') {
        for (let key in this.props.searchedUsersList) {
          let item = this.props.searchedUsersList[key];
          const perItemRows = this.renderItem(item);
          allUsersRows = allUsersRows.concat(perItemRows);
          if (this.props.markedItem != null && item.id === this.props.markedItem.id)
            title = item.title;
        }
      } else {
        for (let key in this.props.searchedUsersList) {
          if (
            this.props.searchedUsersList[key]['auth_user_groups'].includes(
              this.props.selectedPermission
            )
          ) {
            let item = this.props.searchedUsersList[key];
            const perItemRows = this.renderItem(item);
            allUsersRows = allUsersRows.concat(perItemRows);
            if (this.props.markedItem != null && item.id === this.props.markedItem.id)
              title = item.title;
          }
        }
      }
    }

    return (
      <UsersTableContent>
        <TableContent>
          <thead className={'tableHeader'}>
            <tr>
              <td className="tEmail">Email</td>
              <td className="tName">Full Name</td>
              <td className="tLastActive">Last Active</td>
              <td className="tGroups">Groups</td>
              <td className={'financesInvCells'} />
            </tr>
          </thead>
        </TableContent>
        <TableContent>
          <tbody className={'scrollableTbody'}>{allUsersRows}</tbody>
        </TableContent>
      </UsersTableContent>
    );
  }
}
export default UsersTable;

const UsersTableContent = styled.div`
  top: 15rem;
  left: 4rem;
  color: #fff;
`;

const TableContent = styled.table`
  position: relative;
  background-color: #282b30;
  width: 100%;
  font-size: 1.7rem;
  text-align: center;
  vertical-align: middle;
  color: #afafaf;
  line-height: 4rem;
  table-layout: fixed;
  border: 2px solid #2f3238;
  border-collapse: collapse;
  cursor: pointer;
  margin-top: -2px;

  td {
    border: 2px solid #35383e;
    border-collapse: collapse;
    table-layout: fixed !important;
    cursor: pointer;
    font-size: 1.5rem;
    word-wrap: break-word;
    padding-left: 5px;
    padding-right: 5px;
  }

  td:last-child {
    border: none;
  }
`;

export const RowButtonsPanel = styled.div`
  display: flex;
  position: relative;
  background-color: #2f3238;
  flex-direction: row;
  width: 3rem;
  align-items: center;
  justify-items: center;
  //position: relative;
`;
export const RowBtnTooltip = styled.div`
  display: none;
  position: absolute;
  padding: 0px;
  width: 120px;
  line-height: 4rem;
  height: 30px !important;
  margin: 0px;
  z-index: 1090;
  top: -31px;
  cursor: auto;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  text-align: center;
  background: #282b30;
  border-radius: 3px;
  border: #00d5a9 solid 0.5px;
  color: #00d5a9;
  opacity: 0;
`;

export const RowButton = styled.div`
  display: inline-flex;
  float: left;
  height: 3rem;
  width: 3rem;
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
  &:hover ${RowBtnTooltip} {
    opacity: 1;
    display: block;
  }
`;

export const RowBtnImg = styled.img`
  height: 3rem;
  width: 3rem;
  margin: auto;
  display: block;
  &:hover ${RowBtnTooltip} {
    opacity: 1;
    display: block;
  }
`;

export const BtnTooltipInfo = styled.div`
  padding: 0px;
  margin: -5px;
`;

const SimpleList = styled.ul`
  font-size: 1.2rem;
  line-height: 2rem;
  list-style-type: none;
  margin-left: 2rem;
  padding: 0;
  text-align: left;
`;
const ColorGroup = styled.div`
  color: ${props => props.color};
  font-weight: bold;
`;

const GameGroupsList = styled.ul`
  font-size: 1.2rem;
  line-height: 2rem;
  list-style-type: initial;
  margin-left: 1rem;
  padding-left: 10px;
  text-align: left;
`;

const RowTable = styled.tr`
  transition: background-color 0.4s ease-in-out;
  background-color: ${props => (props.isMarked ? props.isMarked : '#282b30')};
`;

//endregion
