import React, { Fragment, Component } from 'react';
import DataPanel from './DataPanel';
import DataPanelBig from './DataPanelBig';
import storage from 'utils/storage';
import LoadingIndicator from 'components/LoadingIndicator';
import ChangePasswordPopups from '../../ChangePasswordForm/ChangePasswordPopups';
import styled, { injectGlobal, keyframes } from 'styled-components';
import PlayStackLogo from 'components/PlayStackLogo';
import { AppContext } from 'views/App';

class WelcomeMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isFetching: false,
      shouldUpdate: false
    };
    this.mounted = false;
    this.showPopup = this.showPopup.bind(this);
  }
  /** WelcomeMain Lifecycle Methods */
  //region Welcome Main Lifecycle Methods
  componentDidMount() {
    this.mounted = true;
    this.fetchData();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps', nextProps, prevState);

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log('componentDidUpdate', prevProps, prevState, this.props, this.state);
    if (
      prevProps.privilegesUpdated !== this.props.privilegesUpdated &&
      this.props.privilegesUpdated === true
    ) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }
  //endregion

  /** Fetching Data */
  //region Fetching Data
  fetchData() {
    if (!this.mounted) return;
    this.setState({ isFetching: true });

    this.getGamesData().then(data => this.onDataReceived(data));
  }

  getGamesData() {
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/analytics/dashboard_first_glance_all';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.payload = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.payload = json.result;
        else data.payload = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  onDataReceived(data) {
    if (!data || data.failed) {
      //if(receivedData.updatedToken==null)
      this.props.updatePrivCall(false);

      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }

    if (!this.mounted) return;

    this.setState({
      isFetching: false,
      data: data.payload,
      showTooltip: null,
      openPopup: this.props.passwordTemp
    });

    // setTimeout(this.marItem, 500);
  }
  //endregion
  showPopup() {
    let show = this.state.openPopup;
    this.setState({ openPopup: !show });
  }

  render() {
    //if (!this.mounted) return;
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }

    if (!this.state.data) {
      return (
        <div className="fetchDataerrorMessage">
          <h3>Oops!</h3>
          <p>
            You don't have permissions to see this view or there is no data available at the moment.
            Please try Again!
          </p>
        </div>
      );
    }
    return (
      <Fragment>
        <AppContext.Consumer>
          {({ getGamesIcons, logout }) => (
            <Fragment>
              {this.state.openPopup ? (
                <ChangePasswordPopups
                  isTemporary={true}
                  showPopup={this.showPopup}
                  logoutCall={logout}
                />
              ) : null}
            </Fragment>
          )}
        </AppContext.Consumer>
        {this.state.data.internal_games == null && this.state.data.external_games == null && (
          <CenteredLogo>
            <PlayStackLogo />
          </CenteredLogo>
        )}

        {this.state.data.internal_games != null ? (
          <Fragment>
            <Title>Playstack Games</Title>
            <Container>
              <DataPanelBig
                key={'revenue7daysKey'}
                type={'currency'}
                name={'REVENUE'}
                color={'#56aaec'}
                period={'last 7 days'}
                value={this.state.data.internal_games['7'].total_revenue}
                value_two={this.state.data.internal_games['30'].total_revenue}
                period_two={'last 30 days'}
                color_two={'#28bdcb'}
              />
              <DataPanelBig
                key={'spend7daysKey'}
                type={'currency'}
                name={'CAMPAIGN SPEND'}
                color={'#02d0ad'}
                period={'last 7 days'}
                value={this.state.data.internal_games['7'].total_campaign_spend}
                value_two={this.state.data.internal_games['30'].total_campaign_spend}
                period_two={'last 30 days'}
                color_two={'#38e5bf'}
              />
              <DataPanelBig
                key={'total7daysKey'}
                type={'number'}
                name={'TOTAL INSTALLS'}
                color={'#be73cd'}
                period={'last 7 days'}
                value={this.state.data.internal_games['7'].total_installs}
                value_two={this.state.data.internal_games['30'].total_installs}
                period_two={'last 30 days'}
                color_two={'#db46b9'}
              />
              <DataPanelBig
                key={'norganic7daysKey'}
                type={'number'}
                name={'NON-ORGANIC INSTALLS'}
                color={'#e546a1'}
                period={'last 7 days'}
                value={this.state.data.internal_games['7'].total_non_organic}
                value_two={this.state.data.internal_games['30'].total_non_organic}
                period_two={'last 30 days'}
                color_two={'#f16e3e'}
              />
            </Container>
          </Fragment>
        ) : null}
        {this.state.data.external_games != null ? (
          <Fragment>
            <Title>Non-Playstack Games</Title>
            <Container>
              <DataPanelBig
                key={'revenue30daysKey'}
                type={'currency'}
                name={'REVENUE'}
                color={'#56aaec'}
                period={'last 7 days'}
                value={this.state.data.external_games['7'].total_revenue}
                period_two={'last 30 days'}
                value_two={this.state.data.external_games['30'].total_revenue}
                color_two={'#28bdcb'}
              />
              <DataPanelBig
                key={'spend30daysKey'}
                type={'currency'}
                name={'CAMPAIGN SPEND'}
                color={'#02d0ad'}
                period={'last 7 days'}
                value={this.state.data.external_games['7'].total_campaign_spend}
                period_two={'last 30 days'}
                value_two={this.state.data.external_games['30'].total_campaign_spend}
                color_two={'#38e5bf'}
              />
              <DataPanelBig
                key={'total30daysKey'}
                type={'number'}
                name={'TOTAL INSTALLS'}
                color={'#be73cd'}
                period={'last 7 days'}
                value={this.state.data.external_games['7'].total_installs}
                period_two={'last 30 days'}
                value_two={this.state.data.external_games['30'].total_installs}
                color_two={'#db46b9'}
              />
              <DataPanelBig
                key={'norganic30daysKey'}
                type={'number'}
                name={'NON-ORGANIC INSTALLS'}
                color={'#e546a1'}
                period={'last 7 days'}
                value={this.state.data.external_games['7'].total_non_organic}
                period_two={'last 30 days'}
                value_two={this.state.data.external_games['30'].total_non_organic}
                color_two={'#f16e3e'}
              />
            </Container>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

export default WelcomeMain;

/** WelcomeMain Styles */
//region Styles
const CenteredLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 6rem);
`;

const Title = styled.h2`
  width: 96%;
  color: #fff;
  padding: 0rem 0rem 1rem 2.5rem;
  font-size: 2.5rem;
  line-height: 4rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 20px;
  margin-left: 3rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;

const Container = styled.div`
  display: grid;
  margin-top: 40px;
  margin-left: 120px;
  margin-right: 120px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  grid-gap: 80px;

  padding: 0;
  background-color: #2f3238;
  @media (max-width: 1600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;
//endregion
