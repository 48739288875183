import React, { Component } from 'react';
import './style.css';
import { defaults, Bar, Line } from 'react-chartjs-2';
import moment from 'moment';
import styled from 'styled-components';
import './style.css';
import formatNumberWithDots from 'utils/formatNumberWithDots';

class SimpleChart extends Component {
  constructor(props) {
    super(props);

    let revenueData = this.parseData(this.props.data);

    this.state = {
      revenueData: revenueData
    };
    this.handleChange = this.handleChange.bind(this);
  }

  parseData(data) {
    let labels = [];
    let installs = [];
    let maxInstalls = 0;
    let total = 0;
    if (data) {
      data.map(item => {
        labels.push(moment(item.date).format('DD-MM-YYYY'));
        installs.push(item.installs);
        total += item.installs;

        if (Number(item.installs) > maxInstalls) maxInstalls = Number(item.installs);
      });
    }

    return {
      labels: labels,
      maxInstalls: maxInstalls,
      total: total,
      datasets: [
        {
          label: 'Installs',
          hidden: false,
          backgroundColor: '#109179',
          borderWidth: 0,
          borderColor: 'rgba(0,213,169,1.0)',
          hoverBackgroundColor: '#00d5a9', //'rgba(0,213,169,1.0)',
          data: installs
        }
      ]
    };
  }

  render() {
    let dt;

    dt = Object.assign({}, this.state.revenueData);
    let max = this.state.revenueData.maxInstalls;

    return (
      <SimpleChartContent>
        <SimpleChartTitle>{formatNumberWithDots(this.state.revenueData.total)}</SimpleChartTitle>
        <SimpleChartCanvas>
          <Bar
            ref="myChart"
            data={dt}
            width={120}
            height={80}
            options={{
              title: {
                display: false,
                text: ''
              },
              legend: {
                display: false
              },
              layout: {
                padding: {
                  right: 10,
                  left: 10,
                  bottom: 15,
                  top: 15
                }
              },
              tooltips: {
                titleFontSize: 11,
                cornerRadius: 2,
                bodyFontSize: 11,
                bodyFontColor: '#10ff9e',
                footerFontSize: 8,
                backgroundColor: '#454545',
                displayColors: false,
                callbacks: {
                  label: function(tooltipItem, data) {
                    return '    ' + formatNumberWithDots(tooltipItem.yLabel);
                  }
                }
              },
              responsive: false,
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    display: false,
                    categoryPercentage: 0.8,
                    barPercentage: 1.0,
                    stacked: false,
                    gridLines: {
                      drawBorder: true,
                      color: '#2f3238'
                    }
                  }
                ],
                yAxes: [
                  {
                    display: false,

                    ticks: {
                      min: 0,
                      max: max,
                      stepWidth: 1
                    },
                    scaleLabel: {
                      display: false,
                      labelString: 'USD'
                    },

                    stacked: false,

                    gridLines: {
                      drawBorder: false,

                      color: '#2f3238'
                    }
                  }
                ]
              }
            }}
          />
        </SimpleChartCanvas>
        <SimpleChartTitle>Total Installs</SimpleChartTitle>
      </SimpleChartContent>
    );
  }

  handleChange(event) {
    this.setState({ revenue_group: event.target.value });
  }
}

export default SimpleChart;
/** SimpleChart Styles */
//region Styles
const SimpleChartTitle = styled.div`
  color: #636363;
  font-size: 1.5rem;
  line-height: 20px;
  font-weight: 200;
  text-align: center;
  width: 140px;
  letter-spacing: 1px;
  margin: 0rem;
  box-sizing: border-box;
`;

const SimpleChartContent = styled.div`
  height: 100px;
  width: 140px;
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 0rem;
  padding-right: 0rem;
  padding-left: 0rem;
  padding-bottom: 0rem;
  flex-direction: column;
  border-left: 1px solid #3a3a3a;
  border-radius: 0px;
  box-sizing: border-box;
`;

const SimpleChartCanvas = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  height: 60px;
  width: 120px;

  flex-grow: 1;
  &::selection {
    background: transparent;
  }
  &::-moz-selection {
    background: transparent;
  }
`;

//endregion
