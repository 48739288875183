import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import CommonButton from 'components/CommonButton';
const FinancierContact = props => {
  return (
    <PIDetailsForClient>
      <div>
        <DLabel>PlayIgnite Contact</DLabel>
        <ContactText>{props.data.financier_contact_person}</ContactText>
      </div>
      <div>
        <DLabel>Email</DLabel>
        <ContactText>{props.data.financier_contact_email}</ContactText>
      </div>
      <div>
        <DLabel>Tel No.</DLabel>
        <ContactText>{props.data.financier_contact_phone}</ContactText>
      </div>
      <ContactButton>
        <CommonButton text={'CONTACT'} onClick={props.handler} />
      </ContactButton>
    </PIDetailsForClient>
  );
};

export default FinancierContact;

const DLabel = styled.p`
  font-size: 12px;
  color: #99999c;
  display: block;
  margin-bottom: 5px;
  margin-top: 0px;
`;

const ContactText = styled.div`
  //padding-top: 5px;
  font-size: 13px;
  letter-spacing: 0.05rem;
  display: block;
  height: 20px;
  margin-top: 0px;
  line-height: 20px;
`;

const ContactButton = styled.div`
  margin-top: 12px;
  grid-column: 4/5;
  grid-row: 1;
  height: 32px;
`;

const PIDetailsForClient = styled.div`
  margin-top: 10px;
  width: 100%;
  display: grid;
  height: 50px;
  grid-template-columns: 18em 25rem 18rem 14rem;
  grid-column-gap: 4rem;
`;
