import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import EditText from 'components/EditText';

class PlayIgniteContact extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <Fragment>
        <Title>PlayIgnite contact details</Title>
        <ContactContainer>
          {' '}
          <KeyContact>
            <EditText
              id={'PICKeyContact'}
              data_id={this.props.data_id}
              name={'financier_contact_person'}
              labelTxt={'Key Contact'}
              isAlwaysEdited={true}
              value={this.props.data.financier_contact_person}
              callback={this.props.callback}
            />
          </KeyContact>
          <Email>
            <EditText
              id={'PICEmail'}
              data_id={this.props.data_id}
              name={'financier_contact_email'}
              labelTxt={'Email'}
              isAlwaysEdited={true}
              value={this.props.data.financier_contact_email}
              callback={this.props.callback}
            />
          </Email>
          <Tel>
            <EditText
              id={'PICTel'}
              data_id={this.props.data_id}
              name={'financier_contact_phone'}
              labelTxt={'Tel No.'}
              isAlwaysEdited={true}
              value={this.props.data.financier_contact_phone}
              callback={this.props.callback}
            />
          </Tel>
        </ContactContainer>
      </Fragment>
    );
  }
}

export default PlayIgniteContact;

const Title = styled.div`
  font-size: 12px;
  color: #99999c;
  display: block;
  margin-bottom: 5px;
  margin-top: 0px;
`;

const Tel = styled.div`
  grid-row: 3;
`;
const Email = styled.div`
  grid-row: 2;
`;

const KeyContact = styled.div`
  grid-row: 1;
`;

const ContactContainer = styled.div`
  margin: 0px;
  background-color: #282b30;
  height: 260px;
  color: #e1e1e5;
  font-weight: 400;
  border: 0.5px solid #36393f;
  border-radius: 3px;
  padding-top: 28px;
  padding-bottom: 5px;
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: 7rem 7rem 7rem;
`;
