import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import KpiMain from './KpiMain';
import TitleBar from 'components/TitleBar';
import KpiGameDetails from './KpiGameDetails';

import styled from 'styled-components';
import { Content } from 'views/App';
/** Routes */
//region Routes
const routes = [
  {
    path: '/kpi',
    exact: true,
    view: KpiMain,
    title: 'Welcome to PlayStack Dashboard'
  },
  {
    path: '/external',
    exact: true,
    view: KpiMain,
    title: 'Welcome to PlayStack Dashboard'
  },
  {
    path: '/kpi/game/:id',
    exact: false,
    view: KpiGameDetails,
    title: 'Welcome to PlayStack Dashboard'
  },
  {
    path: '/external/game/:id',
    exact: false,
    view: KpiGameDetails,
    title: 'Welcome to PlayStack Dashboard'
  }
];
//endregion
class Kpi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentView: KpiMain
    };
    this.components = {};
    this.addComponents();
    this.showGameDetails = this.showGameDetails.bind(this);
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  addComponents() {
    this.components.KpiMain = KpiMain;
    this.components.KpiGameDetails = KpiGameDetails;
  }

  showGameDetails(id, name, icon) {
    if (this.props.filter === 'internal') {
      this.props.history.push({
        pathname: `/kpi/game/${id}`,
        search: ''
        //  state: { id: id, name: name, icon: icon }
      });
    } else {
      this.props.history.push({
        pathname: `/external/game/${id}`,
        search: ''
        // state: { id: id, name: name, icon: icon }
      });
    }
  }

  render() {
    return (
      <Fragment>
        <TitleBar
          name={this.props.user.first_name}
          title={this.props.title}
          color={this.props.color}
          logout={this.props.logoutCall}
          history={this.props.history}
        />
        <Content>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                id={index}
                path={route.path}
                exact={route.exact}
                render={props => (
                  <route.view
                    changeView={this.showGameDetails}
                    filter={this.props.filter}
                    updatePrivCall={this.props.updatePrivCall}
                    privilegesUpdated={this.props.privilegesUpdated}
                    {...props}
                  />
                )}
                //component={route.view}
              />
            ))}
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </Content>
      </Fragment>
    );
  }
}

export default Kpi;
