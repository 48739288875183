import React, { Component, Fragment } from 'react';
import './style.css';
import CommonButton from 'components/CommonButton/index';
import styled from 'styled-components';
import DropdownList from 'components/DropdownList';
import Popup from 'components/Popup';
import Textfield from 'components/Textfield';
import CustomDatePicker from 'components/CustomDatePicker';
import moment from 'moment';
import SwitchButton from 'components/Switch';
import RadioInput from 'components/RadioInput';
class EditTargets extends Component {
  constructor(props) {
    super();

    this.state = {
      selectedPeriod: props.data.ltv_period,
      dropdownOptions: [],
      currentLTVModel: 'Retention',
      isCohortModel: null,
      from: null,
      to: null,
      mounted: false,
      displayedList: [],
      warning_msg: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.selectPeriodHandler = this.selectPeriodHandler.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleCalendarUpdate = this.handleCalendarUpdate.bind(this);
    this.handleChangeLTVModel = this.handleChangeLTVModel.bind(this);
    this.handleRadioButton = this.handleRadioButton.bind(this);
    this.setMessage = this.setMessage.bind(this);
    this.getData = this.getData.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.from !== this.state.from) return true;
    if (nextState.to !== this.state.to) return true;
    if (nextState.selectedPeriod !== this.state.selectedPeriod) return true;

    return true;
  }
  componentDidMount() {
    this.setState({ mounted: false }, () => {
      this.getData();
    });
  }

  getData() {
    let data = this.props.data;
    let dropdownOptions = [];
    data.allowed_periods.map(item => {
      let dropdownItem = { key: item, value: 'LTV (' + item + ')' };
      //groupsData[item.id] = item;
      dropdownOptions.push(dropdownItem);
    });

    let current_acquisition_type = data.acquisition_type;
    let cohort_types = [];
    Object.entries(data.ltv_cohort_acquisition_types).map(([k, v]) => {
      let aType = { name: v, checked: false };
      if (v == 'non-organic') {
        cohort_types.unshift(aType);
      } else {
        cohort_types.push(aType);
      }
    });
    let retention_types = [];
    Object.entries(data.ltv_retention_acquisition_types).map(([k, v]) => {
      let aType = { name: v, checked: false };
      if (v == 'non-organic') {
        retention_types.unshift(aType);
      } else {
        retention_types.push(aType);
      }
    });
    let displayed_options = [];
    let min_period = 0;
    let isCohort = data.cohort_based_ltv;
    let currentModel = null;
    if (isCohort) {
      currentModel = 'Cohort';
      displayed_options = cohort_types;
      min_period = data.min_cohort_data_collection_period;
    } else {
      currentModel = 'Retention';
      displayed_options = retention_types;
      min_period = data.min_retention_data_collection_period;
    }

    Object.entries(displayed_options).map(([k, v]) => {
      let checked;
      {
        current_acquisition_type == v.name ? (v.checked = true) : (v.checked = false);
      }
    });
    let fromData = data.data_collection_start;
    let toData = data.data_collection_end;

    this.setState(
      {
        dropdownOptions: dropdownOptions,
        isCohortModel: isCohort,
        currentLTVModel: currentModel,
        from: moment(fromData, 'DD-MM-YYYY'),
        to: moment(toData, 'DD-MM-YYYY'),
        mounted: true,
        cohort_types: cohort_types,
        retention_types: retention_types,
        displayedList: displayed_options,
        acquisition_type: current_acquisition_type,
        min_cohort_period: data.min_cohort_data_collection_period,
        min_retention_period: data.min_retention_data_collection_period,
        min_period: min_period
      },
      () => {
        this.setMessage();
      }
    );
  }

  handleCalendarUpdate(dateFrom, dateTo, needUpdate) {
    if (needUpdate) {
      this.setState({ from: moment(dateFrom, 'DD-MM-YYYY'), to: moment(dateTo, 'DD-MM-YYYY') });
    }
  }

  handleChangeLTVModel() {
    if (this.state.isCohortModel) {
      let list = this.state.retention_types;
      let period = this.state.min_retention_period;
      Object.entries(list).map(([k, v]) => {
        if (v.checked) {
          v.checked = false;
        }
        if (v.name == 'non-organic') {
          v.checked = true;
        }
      });
      this.setState(
        {
          currentLTVModel: 'Retention',
          isCohortModel: false,
          displayedList: list,
          acquisition_type: 'non-organic',
          min_period: period
        },
        () => {
          this.setMessage();
        }
      );
    } else {
      let list = this.state.cohort_types;
      let period = this.state.min_cohort_period;
      Object.entries(list).map(([k, v]) => {
        if (v.checked) {
          v.checked = false;
        }
        if (v.name == 'non-organic') {
          v.checked = true;
        }
      });
      this.setState(
        {
          currentLTVModel: 'Cohort',
          isCohortModel: true,
          displayedList: list,
          acquisition_type: 'non-organic',
          min_period: period
        },
        () => {
          this.setMessage();
        }
      );
    }
  }

  handleRadioButton(e) {
    let event = e.target.id;
    let list = this.state.displayedList;
    Object.entries(list).map(([k, v]) => {
      if (v.checked && event != v.name) {
        v.checked = false;
      }
      if (event == v.name) {
        v.checked = true;
      }
    });
    this.setState({ displayedList: list, acquisition_type: event });
  }

  setMessage() {
    let text = (
      <span>
        Minimum days for the <b>{this.state.currentLTVModel}</b> LTV Model is{' '}
        <b>{this.state.min_period}</b>
      </span>
    );
    this.setState({ warning_msg: text });
  }

  render() {
    let expectedReturnRate = this.props.data.expected.roi;
    let expectedARPDAU = this.props.data.expected.ltv;
    let expectedDAU = this.props.data.expected.cpi;
    let kfactor = this.props.data.k_factor;
    let period = this.props.data.ltv_period;

    let options = [];
    this.props.data.allowed_periods.map(item => {
      options.push(item);
    });
    if (!this.state.mounted) {
      return null;
    }

    return (
      <Popup
        show={this.props.closeCallBack}
        mainColor={'#04d5ac'}
        title={'Configure'}
        popupwidth={500}
        popupheight={510}
        visible={this.props.editTargets}
      >
        <PopupBody>
          <form
            ref="form"
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <PopupItem>
              <LabelText>LTV Model</LabelText>
              <SwitchButton
                items={['Cohort', 'Retention']}
                handleChange={this.handleChangeLTVModel}
                labelText={this.state.currentLTVModel}
              />
            </PopupItem>
            <PopupItem>
              <Textfield
                labelText={'ROI (%, Target)'}
                type={'number'}
                id={'roi'}
                name={'roi'}
                value={expectedReturnRate}
              />
            </PopupItem>
            <PopupItem>
              <Textfield
                labelText={'LTV ($, Target)'}
                type={'number'}
                id={'numberLtv'}
                name={'ltv'}
                value={expectedARPDAU}
              />
            </PopupItem>
            <PopupItem>
              <Textfield
                labelText={'CPI ($, Target)'}
                type={'number'}
                id={'numberCpi'}
                name={'cpi'}
                value={expectedDAU}
              />
            </PopupItem>
            <PopupItem>
              <LabelText>Acquisition Type:</LabelText>
              <RadioField>
                {Object.entries(this.state.displayedList).map(([k, v]) => {
                  return (
                    <RadioInput
                      key={v.name + this.state.currentLTVModel}
                      id={v.name}
                      checked={v.checked}
                      onChange={this.handleRadioButton}
                      label={v.name}
                      value={v.name}
                    />
                  );
                })}
              </RadioField>
            </PopupItem>
            <PopupItem>
              <Textfield
                labelText={'K-Factor (Global setting)'}
                type={'number'}
                id={'previewKfactor'}
                name={'k_factor'}
                value={kfactor}
                min={1}
                max={3}
              />
            </PopupItem>
            <SubmitButton>
              <CommonButton
                disabled={this.state.showMessage}
                text="Submit"
                onClick={this.handleSubmit}
              />
            </SubmitButton>
          </form>
        </PopupBody>
      </Popup>
    );
  }
  selectPeriodHandler(event) {
    const target = event.target;
    const period = target.value;
    this.setState({
      selectedPeriod: period
    });
  }
  onChange(e) {
    let value = e.target.value;
    if (value < this.props.data.min_ignition_range) {
      this.setState({ showMessage: true });
    } else this.setState({ showMessage: false });
  }
  handleSubmit(ev) {
    ev.preventDefault();

    let kvpairs = [];

    let form = this.refs.form;
    for (let i = 0; i < form.elements.length; i++) {
      let e = form.elements[i];

      if (e.name === undefined || e.value === undefined || e.name === '' || e.name === 'period')
        continue;
      kvpairs[e.name] = e.value;
    }
    kvpairs['ltv_period'] = this.state.selectedPeriod;
    kvpairs['data_collection_start'] = moment(this.state.from).format('DD-MM-YYYY');
    kvpairs['data_collection_end'] = moment(this.state.to).format('DD-MM-YYYY');
    kvpairs['cohort_based_ltv'] = this.state.isCohortModel;
    kvpairs['acquisition_type'] = this.state.acquisition_type;

    this.props.submitCallBack(kvpairs);
  }
}

export default EditTargets;
//region Styles

const PopupItem = styled.div`
  margin-top: 20px;
`;
const PopupBody = styled.div`
  padding-top: 1rem;
  padding-right: 8.5rem;
  padding-left: 8.4rem;
  padding-bottom: 1rem;
`;

export const SubmitButton = styled.div`
  font-size: 3rem;
  margin-top: 4rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3rem;
  width: 10rem;
`;

const LabelText = styled.label`
  font-size: 12px;
  color: #99999c;
  display: block;
  margin-bottom: 5px;
`;

const RadioField = styled.div`
  width: 100%;
  overflow: auto;
  height: 75px;
  padding: 5px;
  border-radius: 3px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
`;
//endregion
