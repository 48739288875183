import React, { Component, Fragment } from 'react';
import styled, { keyframes } from 'styled-components';
import GameIcon from 'components/GameIcon';
import storage from 'utils/storage';
import OptionTooltip from '../../PlayIgniteDetails/OptionTooltip';
import ProgressBar from 'components/ProgressBar';
import formatRoundNumberWithDots from 'utils/formatRoundNumberWithDots';
import formatNumberWithDots from 'utils/formatNumberWithDots';

class GamesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markedItem: null
    };
    this.handleRowOver = this.handleRowOver.bind(this);
    this.handleRowOut = this.handleRowOut.bind(this);
  }

  componentDidMount() {
    //   console.log('mounted');
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.markedItem !== nextState.markedItem ||
      this.props.data !== nextProps.data ||
      this.props.ignitonStatusData !== nextProps.ignitionStatusData
    ) {
      //  console.log('update GamesTable');
      return true;
    } else {
      return false;
    }
  }

  handleRowOver(item) {
    this.setState({ markedItem: item });
  }

  handleRowOut() {
    this.setState({ markedItem: null });
  }
  render() {
    if (this.props.data == null) {
      return null;
    }

    let headersData = null;
    let tableHeader = null;
    let rows = null;
    let userMetrics = null;
    if (storage.USER.privileges.includes('access_playignite_admin_section')) {
      headersData = this.props.data[0].admin_metrics[0].data;
      userMetrics = 'admin_metrics';
    }

    if (this.props.actionType === 'admin') {
      tableHeader = (
        <tr>
          <Td key={'game_header'} width={140} active={true}>
            Game
          </Td>
          <Td key={'loan_type_header'} width={118} active={true}>
            {' '}
            Loan Type
          </Td>
          {Object.entries(headersData).map(([key, value]) => (
            <Td key={value.slug + 'header'} active={true}>
              {value.name}
            </Td>
          ))}
        </tr>
      );
      let counter = 0;
      rows = this.props.data.map(item => {
        let rowColorsTable = ['#282B30', '#24272B'];

        if (counter == 2) {
          counter = 0;
        }
        /*let isMarked = '#282b30';*/
        let isMarked = rowColorsTable[counter];
        counter++;
        if (this.state.markedItem != null && item.slug === this.state.markedItem.slug) {
          isMarked = 'rgb(30, 32, 34)';
        }

        let overallLevel = 0;
        if (storage.GAMES_DATA[item.slug]) {
          storage.GAMES_DATA[item.slug].alerts.map(item => {
            if (item.level > overallLevel) overallLevel = item.level;
          });
        }

        let colorsTable = ['#009432', '#f79f1f', '#ea2027'];

        return (
          <Fragment key={'game_row' + item.slug}>
            {Object.entries(item[userMetrics]).map(([key, value]) => (
              <TableRows
                color={rowColorsTable[1]}
                isMarked={isMarked}
                key={item.slug + key}
                onMouseEnter={() => {
                  this.handleRowOver(item);
                }}
                onMouseLeave={() => {
                  this.handleRowOut();
                }}
                onClick={() => {
                  this.props.changeView(item.slug);
                }}
              >
                {key == 0 ? (
                  <GameTd active={true} key={item.slug + key} rowSpan={3}>
                    <GameIconPanel>
                      <GameTitle>{item.name}</GameTitle>
                      <DevTitle>{item.developer.name}</DevTitle>
                      <GameIcon
                        slug={item.slug}
                        src={item.icon}
                        size={42}
                        indicatorSize={15}
                        color={colorsTable[overallLevel]}
                        tooltip={{
                          orientation: 'left',
                          top: '-12',
                          left: '30',
                          right: null,
                          bottom: null,
                          arrow: { top: '14px', left: null, right: '100%', bottom: null },
                          origin: { top: '14px', left: 0 }
                        }}
                      />
                    </GameIconPanel>
                  </GameTd>
                ) : null}

                <ProductName key={value.slug + key} active={value.active}>
                  {value.name}
                </ProductName>
                {Object.entries(value.data).map(([k, v]) => {
                  switch (v.slug) {
                    case 'loan_total':
                    case 'loan_facility_available':
                    case 'loan_estimated_amount':
                    case 'loan_fees_accrued':
                      return v.value !== null && v.value !== 'n/a' ? (
                        <Td active={value.active} key={item.slug + value.slug + k}>
                          ${formatNumberWithDots(v.value)}
                        </Td>
                      ) : (
                        <Td active={value.active} key={item.slug + value.slug + k} />
                      );
                    case 'loan_fee':
                      return v.value !== null && v.value !== 'n/a' ? (
                        <Td active={value.active} key={item.slug + value.slug + k}>
                          {formatRoundNumberWithDots(v.value)}%
                        </Td>
                      ) : (
                        <Td active={value.active} key={item.slug + value.slug + k} />
                      );
                    case 'ltv_60':
                    case 'cpi':
                      return v.value !== null && v.value !== 'n/a' ? (
                        <Td active={value.active} key={item.slug + value.slug + k} rowSpan={3}>
                          ${formatNumberWithDots(v.value)}
                        </Td>
                      ) : null;
                    case 'onboarding_progress':
                      return (
                        <Td active={value.active} key={item.slug + value.slug + k}>
                          <ProgressBar value={v.value} />
                        </Td>
                      );
                    case 'ignition_status':
                      return v.value !== null && v.value !== 'n/a' ? (
                        <Td active={value.active} key={item.slug + value.slug + k} rowSpan={3}>
                          {formatNumberWithDots(v.value)}%
                        </Td>
                      ) : null;
                    case 'loan_agreement':
                    case 'debenture':
                    case 'indemnity':
                    case 'bfi_certificate':
                    case 'bank_verification':
                    case 'bank_details_switched':
                    case 'store_admin_access_granted':
                    case 'kyc_aml_status':
                      return (
                        <Td active={value.active} key={item.slug + value.slug + k}>
                          <OptionTooltip displayOnly={true} value={v.value} />
                        </Td>
                      );
                    case 'loan_next_date':
                      return (
                        <Td active={value.active} key={item.slug + value.slug + k}>
                          {v.value}
                        </Td>
                      );
                  }
                })}
              </TableRows>
            ))}
          </Fragment>
        );
      });
    }

    if (this.props.actionType === 'client') {
      headersData = this.props.data.ignition_capital;
      tableHeader = (
        <tr>
          <Td colSpan={2} key={'productsH'}>
            Products
          </Td>
          {Object.entries(headersData.data_per_game1[0]).map(([k, v]) => {
            if (k == 0) {
              return;
            }
            return <Td key={v.slug + 'H'}>{v.name}</Td>;
          })}
          {Object.entries(headersData.element_values_data1).map(([k, v]) => (
            <Td key={v.slug + 'H'}>{v.name}</Td>
          ))}
          {Object.entries(this.props.globalData).map(([k, v]) => (
            <Td key={v.slug + 'H'}>{v.name}</Td>
          ))}
          {Object.entries(headersData.element_values_data2).map(([k, v]) => (
            <Td key={v.slug + 'H'}>{v.name}</Td>
          ))}
          {Object.entries(headersData.data_per_game2[0]).map(([k, v]) => (
            <Td key={v.slug + 'H'}>{v.name}</Td>
          ))}
        </tr>
      );

      let rowSpanGlobal =
        this.props.data['ignition_capital'].data_per_game1.length +
        this.props.data['playcapital'].data_per_game1.length +
        this.props.data['playcredits'].data_per_game1.length;

      rows = Object.entries(this.props.data).map(([key, value]) => {
        return (
          <Fragment key={key + 'mainRowContainer'}>
            {Object.entries(value.data_per_game1).map(([k, v]) => {
              let activeFlag = value.data_per_game1[k][0].active;

              let isMarked = '#282b30';

              if (this.state.markedItem != null && value.slug + k === this.state.markedItem) {
                isMarked = 'rgb(30, 32, 34)';
              }

              return (
                <TableRows
                  isMarked={isMarked}
                  isClickable={value.is_game_specific}
                  key={k + value.slug + 'mainRow'}
                  onMouseEnter={() => {
                    this.handleRowOver(value.slug + k);
                  }}
                  onMouseLeave={() => {
                    this.handleRowOut();
                  }}
                  onClick={() => {
                    this.props.changeView(value.data_per_game1[k][0].slug);
                  }}
                >
                  {k == 0 ? (
                    <ClientGameTd
                      key={value.slug + 'rowTitle'}
                      rowSpan={value.data_per_game1.length}
                      width={130}
                    >
                      {value.name}
                    </ClientGameTd>
                  ) : null}
                  {Object.entries(v).map(([vk, vv]) => {
                    if (vk == 0 && value.is_game_specific) {
                      return (
                        <ClientGameTd
                          key={vk + vv.slug + value.slug}
                          width={150}
                          active={activeFlag}
                        >
                          <ClientGameTitle>
                            {' '}
                            <Image slug={vv.slug} src={vv.icon} size={24} />
                            <span>{vv.value}</span>
                          </ClientGameTitle>
                        </ClientGameTd>
                      );
                    } else if (vk == 0 && !value.is_game_specific) {
                      return (
                        <ClientGameTd active={activeFlag} key={vk + vv.slug + value.slug}>
                          {vv.value}
                        </ClientGameTd>
                      );
                    } else {
                      switch (vv.slug) {
                        case 'loan_total':
                        case 'loan_facility_available':
                        case 'loan_fees_accrued':
                          return vv.value !== null ? (
                            <Td active={activeFlag} width={100} key={vk + vv.slug + value.slug}>
                              ${formatNumberWithDots(vv.value)}
                            </Td>
                          ) : (
                            <Td active={activeFlag} width={100} key={vk + vv.slug + value.slug} />
                          );
                        case 'loan_fee':
                          return vv.value !== null ? (
                            <Td active={activeFlag} key={vk + vv.slug + value.slug}>
                              {' '}
                              {formatRoundNumberWithDots(vv.value)}%
                            </Td>
                          ) : (
                            <Td active={activeFlag} key={vk + vv.slug + value.slug} />
                          );
                        case 'ltv_60':
                        case 'cpi':
                          return vv.value !== null ? (
                            <Td active={activeFlag} key={vk + vv.slug + value.slug}>
                              {' '}
                              ${formatNumberWithDots(vv.value)}
                            </Td>
                          ) : null;
                        case 'onboarding_progress':
                          return (
                            <Td active={activeFlag} key={vk + vv.slug + value.slug}>
                              <ProgressBar value={vv.value} />
                            </Td>
                          );
                        case 'ignition_status':
                          return vv.value !== null ? (
                            <Td active={activeFlag} key={vk + vv.slug + value.slug}>
                              {' '}
                              {formatNumberWithDots(vv.value)}%
                            </Td>
                          ) : null;
                      }
                    }
                  })}
                  {!value.is_game_specific ? (
                    <Fragment key={key + value.slug + 'null_values'}>
                      <Td active={activeFlag} key={k + value.slug + '-1'}>
                        -
                      </Td>
                      <Td active={activeFlag} key={k + value.slug + '-2'}>
                        -
                      </Td>
                      <Td active={activeFlag} key={k + value.slug + '-3'}>
                        -
                      </Td>
                    </Fragment>
                  ) : null}
                  {Object.entries(value.element_values_data1).map(([ek, ev]) => {
                    if (k == 0)
                      return (
                        <Td
                          color={true}
                          key={ek + value.slug + ev.slug}
                          rowSpan={value.data_per_game1.length}
                        >
                          <OptionTooltip displayOnly={true} value={ev.value} />
                        </Td>
                      );
                  })}
                  {Object.entries(this.props.globalData).map(([gk, gv]) => {
                    if (key == 'ignition_capital' && k == 0)
                      return (
                        <Td color={true} key={gk + value.slug + gv.slug} rowSpan={rowSpanGlobal}>
                          <OptionTooltip displayOnly={true} value={gv.value} />
                        </Td>
                      );
                  })}

                  {Object.entries(value.element_values_data2).map(([ek, ev]) => {
                    if (k == 0)
                      return (
                        <Td
                          color={true}
                          key={ek + value.slug + ev.slug}
                          rowSpan={value.data_per_game2.length}
                        >
                          <OptionTooltip displayOnly={true} value={ev.value} />
                        </Td>
                      );
                  })}

                  <Td active={activeFlag} key={k + value.slug + value.data_per_game2[k][0].slug}>
                    {value.data_per_game2[k][0].value}
                  </Td>
                  {value.data_per_game2[k][1].value !== null ? (
                    <Td active={activeFlag} key={k + value.slug + value.data_per_game2[k][1].slug}>
                      ${formatNumberWithDots(value.data_per_game2[k][1].value)}
                    </Td>
                  ) : (
                    <Td
                      active={activeFlag}
                      key={k + value.slug + value.data_per_game2[k][1].slug}
                    />
                  )}
                </TableRows>
              );
            })}
          </Fragment>
        );
      });
    }

    return (
      <div>
        <Table>
          <TableHead>{tableHeader}</TableHead>
          <tbody>{rows}</tbody>
        </Table>
      </div>
    );
  }
}

export default GamesTable;

//region Styles

const GameTitle = styled.div``;

const SignCellContainer = styled.div``;

const Image = styled.img`
  width: ${props => props.size + 'px'} !important;
  height: ${props => props.size + 'px'} !important;
  border-radius: ${props => Math.ceil(props.size / 5) + 'px'};
  margin: 6px 0px;
  margin-right: 12px;
`;

const ClientGameTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5px;
  span {
    margin: 0 auto;
  }
`;

const DevTitle = styled.div`
  font-size: 10px;
  line-height: 1.4;
  padding: 3px 0px;
`;

const Table = styled.table`
  border: 0px solid #2f3238;
  border-collapse: collapse;
  table-layout: auto !important;
  height: 4rem;
  // background-color: #282b30;
  transition: background-color 0.2s ease-in-out;
  width: auto;
  font-size: 1.3rem;
  text-align: center;
  vertical-align: middle;
  color: #99999c;
  table-layout: auto;
  margin-top: 10px;
  margin-bottom: 50px;
  padding-bottom: 20px;
`;

const Td = styled.td`
  border: 2px solid #36393f;
  border-collapse: collapse;
  table-layout: auto !important;
min-width:90px;
  max-width: ${props => (props.width ? props.width + 'px' : '118px')};
  // cursor: pointer;
 // padding-left: ${props => (props.zeroPadding ? '0rem' : '1.2rem')};
//  padding-right: ${props => (props.zeroPadding ? '0rem' : '1.2rem')};
  padding:0px 5px;
  cursor: pointer;
  height: 40px;
  
  ${({ color }) =>
    color &&
    `
      background-color:#282b30 !important;
      pointer-events:none !important;
  `};

${({ active }) =>
  active == false &&
  `

//	-webkit-filter: grayscale(1);
//	-webkit-filter: grayscale(100%);
//	filter: gray;
//	filter: grayscale(100%);
	opacity:0.2;
	//pointer-events:none !important;
	cursor:pointer;
  `};

`;

const ClientGameTd = styled(Td)`
  padding: 4px;
  background-color: #1e2022;
  font-size: 12px;
  color: #e1e1e5;
  font-weight: bolder;
  letter-spacing: 0.05rem;
  word-break: break-word;
`;

const TableHead = styled.thead`
  background-color: #1e2022;
  text-align: center;
  border: 0px solid #2f3238;
  font-size: 12px;
  height: 91px;
  color: #e1e1e5;
  font-weight: bolder;
  letter-spacing: 0.05rem;

  p {
    font-size: 10px;
    padding-top: 0px;
    color: #99999c;
    font-weight: 400;
  }

  tr {
  }
  ${Td} {
    height: 60px;
    padding: 18px 5px;
  }
`;

const ProductName = styled(Td)`
  background: #1e2022;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05rem;
`;

const GameIconPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05rem;
  text-align: center;
`;

const GameTd = styled(Td)`
  background: #1e2022;
`;

const TableRows = styled.tr`
  transition: background-color 0.4s ease-in-out;
  background-color: ${props => (props.isMarked ? props.isMarked : '#282b30')};
  ${Td} {
    cursor: pointer;
    padding: 5px 5px;
  }

  ${({ isClickable }) =>
    isClickable == false &&
    `
	pointer-events:none;

  `};
`;
//endregion
