import React, { Component, Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import DropdownList2 from 'components/DropdownList2';
const WeekDays = () => {
  let label = [];
  for (let i = 1; i <= 7; i++) {
    label.push(
      <WeekdaysLabel key={moment().day(i)}>
        {moment()
          .day(i)
          .format('ddd')}
      </WeekdaysLabel>
    );
  }
  return <div>{label}</div>;
};

const SelectMonth = ({ thisDate, change, name, toRange, notSubtract }) => {
  let months_list = moment.months();
  let now = moment()
    .subtract(2, 'day')
    .startOf('day');
  let numberOfMonths = null;
  if (thisDate.format('YYYY') === toRange.format('YYYY')) {
    let startOfThisYear = moment(new Date(toRange.year(), 0, 1));
    let monthDiff;
    if (notSubtract) {
      monthDiff = moment(toRange)
        .subtract(2, 'day')
        .startOf('day')
        .diff(startOfThisYear, 'months', false);
    } else {
      monthDiff = moment(toRange)
        .startOf('day')
        .diff(startOfThisYear, 'months', false);
    }
    numberOfMonths = monthDiff;
  } else {
    numberOfMonths = 11;
  }
  // let months = [];
  let months_items = [];
  for (let i = 0; i <= numberOfMonths; i++) {
    let date = moment(thisDate).month(i);
    // let idName = i + date + name;
    let item = {};
    item.key = date.format('DD-MM-YYYY');
    item.value = date.format('MMMM');
    months_items.push(item);
    {
      /* if (date.format('MMMM') === thisDate.format('MMMM')) {
      months.unshift(
        <CurrentOptionInput
          name="test"
          type="radio"
          key={idName}
          id={idName}
          value={date}
          onClick={() => change(date)}
        />,
        <OptionLabel key={i + name} htmlFor={idName}>
          {' '}
          {date.format('MMMM')}
        </OptionLabel>
      );
    } else {
      months.push(
        <OptionInput
          name="test"
          type="radio"
          key={idName}
          id={idName}
          value={date}
          onClick={() => change(date)}
        />,
        <OptionLabel key={i + name} htmlFor={idName}>
          {' '}
          {date.format('MMMM')}
        </OptionLabel>
      );
    } */
    }
  }

  return (
    <Fragment>
      {/*<Select tabIndex="1">{months}</Select> */}
      <DropdownList2
        items={months_items}
        name="calendarMonths"
        id="calendarMonthsDropdown"
        handler={change}
        currentOption={thisDate.format('DD-MM-YYYY')}
        backgroundColor={'#141617'}
        uppercase={true}
      />
    </Fragment>
  );
};

const SelectYear = ({ thisDate, change, name, sinceRange, toRange, notSubtract }) => {
  let sinceYear = sinceRange.year();
  let sinceDate = new Date(sinceYear, 0, 1);
  let dateWrapper = moment(sinceDate);
  let yearsDiff;
  if (notSubtract) {
    yearsDiff = moment(toRange)
      .startOf('day')
      .diff(dateWrapper, 'years', false); //between toRange and sinceYear
  } else {
    yearsDiff = moment(toRange)
      .subtract(2, 'day')
      .startOf('day')
      .diff(dateWrapper, 'years', false); //between toRange and sinceYear
  }
  let toDate = moment(dateWrapper).add(yearsDiff, 'years', false);
  // let years = [];
  let years_list = [];

  for (let i = 0; i <= yearsDiff; i++) {
    let year = moment(dateWrapper).add(i, 'year');
    // let idName = i + name;
    let item = {};
    item.key = year.format('YYYY');
    item.value = year.format('YYYY');
    years_list.push(item);
    {
      /*  if (dateWrapper > thisDate || thisDate.format('YYYY') > toDate.format('YYYY')) {
        if (i == 0) {
        years.unshift(
          <CurrentOptionInput
            name="test"
            type="radio"
            key={idName}
            id={idName}
            value={year}
            onClick={() => change(year, thisDate, i)}
          />,
          <OptionLabel key={i + date} htmlFor={idName}>
            {' '}
            {year.format('YYYY')}
          </OptionLabel>
        );
      } else {
        years.push(
          <OptionInput
            name="test"
            type="radio"
            key={idName}
            id={idName}
            value={year}
            onClick={() => change(year, thisDate, i)}
          />,
          <OptionLabel key={i + date} htmlFor={idName}>
            {' '}
            {year.format('YYYY')}
          </OptionLabel>
        );
      }
    } else {
      if (year.format('YYYY') === thisDate.format('YYYY')) {
        years.unshift(
          <CurrentOptionInput
            key={idName}
            name="test"
            type="radio"
            id={idName}
            value={year}
            onClick={() => change(year, thisDate, i)}
          />,
          <OptionLabel key={i + date} htmlFor={idName}>
            {' '}
            {year.format('YYYY')}
          </OptionLabel>
        );
      } else {
        years.push(
          <OptionInput
            key={idName}
            name="test"
            type="radio"
            id={idName}
            value={year}
            onClick={() => change(year, thisDate, i)}
          />,
          <OptionLabel key={i + date} htmlFor={idName}>
            {' '}
            {year.format('YYYY')}
          </OptionLabel>
        );
      }
    }*/
    }
  }
  return (
    <Fragment>
      {/*<Select tabIndex="1">{years}</Select>*/}
      <DropdownList2
        items={years_list}
        name="calendarYears"
        id="calendarYearsDropdown"
        handler={change}
        currentOption={thisDate.format('YYYY')}
        backgroundColor={'#141617'}
        uppercase={true}
      />
    </Fragment>
  );
};

const Heading = ({
  thisDate,
  changeMonth,
  changeSelectedMonth,
  changeSelectedYear,
  name,
  sinceRange,
  toRange,
  notSubtract
}) => {
  let disabled_left = false;
  let disabled_right = false;
  if (thisDate.format('MM YYYY') === sinceRange.format('MM YYYY')) {
    disabled_left = true;
  }
  let nextMonth = null;
  if (thisDate.format('MM YYYY') === toRange.format('MM YYYY')) {
    disabled_right = true;
  }

  return (
    <Fragment>
      <HeaderContainer>
        <div onClick={disabled_left ? null : () => changeMonth(moment(thisDate), 'subtract')}>
          <ArrowLeft disabled={disabled_left} />
        </div>
        <SelectMonth
          thisDate={thisDate}
          toRange={toRange}
          change={changeSelectedMonth}
          name={name}
          notSubtract={notSubtract}
        />
        <SelectYear
          thisDate={thisDate}
          change={changeSelectedYear}
          name={name}
          sinceRange={sinceRange}
          toRange={toRange}
          notSubtract={notSubtract}
        />
        <div onClick={disabled_right ? null : () => changeMonth(moment(thisDate), 'add')}>
          <ArrowRight disabled={disabled_right} />
        </div>
      </HeaderContainer>
    </Fragment>
  );
};

const Days = ({
  changeDateHandler,
  currentDate,
  daysInMonth,
  firstDay,
  previousMonth,
  previousMonthDays,
  nextMonthDay,
  currentDayFormated,
  currentDayColor,
  sinceRange,
  toRange
}) => {
  let days = [];
  for (let i = firstDay.day(); i > 1; i--) {
    previousMonth.date(previousMonthDays - i + 2);
    if (previousMonth.date(previousMonthDays - i + 2) < sinceRange) {
      days.push(
        <DaysDisabled
          key={previousMonth.date(previousMonthDays - i + 2)}
          alt={previousMonth.date(previousMonthDays - i + 2).format('DD/MM/YYYY')}
          title={previousMonth.date(previousMonthDays - i + 2).format('DD/MM/YYYY')}
        >
          {previousMonth.date(previousMonthDays - i + 2).format('D')}
        </DaysDisabled>
      );
    } else {
      days.push(
        <NotThisMonthDays
          key={previousMonth.date(previousMonthDays - i + 2) + 'p'}
          onClick={() => changeDateHandler(previousMonth.date(previousMonthDays - i + 2))}
          alt={previousMonth.date(previousMonthDays - i + 2).format('DD/MM/YYYY')}
          title={previousMonth.date(previousMonthDays - i + 2).format('DD/MM/YYYY')}
        >
          {previousMonth.date(previousMonthDays - i + 2).format('D')}
        </NotThisMonthDays>
      );
    }
  }

  if (firstDay.day() === 0) {
    for (let i = 5; i >= 0; i--) {
      if (previousMonth.date(previousMonthDays - i) < sinceRange) {
        days.push(
          <DaysDisabled
            key={previousMonth.date(previousMonthDays - i).format('D')}
            alt={previousMonth.date(previousMonthDays - i).format('DD/MM/YYYY')}
            title={previousMonth.date(previousMonthDays - i).format('DD/MM/YYYY')}
          >
            {previousMonth.date(previousMonthDays - i).format('D')}
          </DaysDisabled>
        );
      } else {
        days.push(
          <NotThisMonthDays
            key={previousMonth.date(previousMonthDays - i) + 'p'}
            onClick={() => changeDateHandler(previousMonth.date(previousMonthDays - i))}
            alt={previousMonth.date(previousMonthDays - i).format('DD/MM/YYYY')}
            title={previousMonth.date(previousMonthDays - i).format('DD/MM/YYYY')}
          >
            {previousMonth.date(previousMonthDays - i).format('D')}
          </NotThisMonthDays>
        );
      }
    }
  }

  for (let i = 1; i <= daysInMonth; i++) {
    currentDate.date(i);

    if (currentDate.date(i) > toRange) {
      days.push(
        <DaysDisabled
          key={currentDate.date(i)}
          alt={currentDate.date(i).format('DD/MM/YYYY')}
          title={currentDate.date(i).format('DD/MM/YYYY')}
        >
          {currentDate.date(i).format('D')}
        </DaysDisabled>
      );
    } else if (currentDate.date(i).format('DD/MM/YYYY') === currentDayFormated) {
      days.push(
        <DaysLabel
          color={currentDayColor}
          key={currentDate.date(i)}
          onClick={() => changeDateHandler(currentDate.date(i))}
          alt={currentDate.date(i).format('DD/MM/YYYY')}
          title={currentDate.date(i).format('DD/MM/YYYY')}
        >
          {currentDate.date(i).format('D')}
        </DaysLabel>
      );
    } else {
      days.push(
        <DaysLabel
          key={currentDate.date(i)}
          onClick={() => changeDateHandler(currentDate.date(i))}
          alt={currentDate.date(i).format('DD/MM/YYYY')}
          title={currentDate.date(i).format('DD/MM/YYYY')}
        >
          {currentDate.date(i).format('D')}
        </DaysLabel>
      );
    }
  }
  let daysCounter = days.length;
  for (let i = 1; i <= 42 - daysCounter; i++) {
    nextMonthDay.date(i);
    if (nextMonthDay.date(i) > toRange) {
      days.push(
        <DaysDisabled
          key={nextMonthDay.date(i).format('D')}
          alt={nextMonthDay.date(i).format('DD/MM/YYYY')}
          title={nextMonthDay.date(i).format('DD/MM/YYYY')}
        >
          {nextMonthDay.date(i).format('D')}
        </DaysDisabled>
      );
    } else {
      days.push(
        <NotThisMonthDays
          key={nextMonthDay.date(i) + 'n'}
          onClick={() => changeDateHandler(nextMonthDay.date(i))}
          alt={nextMonthDay.date(i).format('DD/MM/YYYY')}
          title={nextMonthDay.date(i).format('DD/MM/YYYY')}
        >
          {nextMonthDay.date(i).format('D')}
        </NotThisMonthDays>
      );
    }
  }

  return <div>{days}</div>;
};
let currDay = null;
let date = null;
let toRangeDate = null;
class Calendar extends Component {
  constructor(props) {
    super(props);
    date = this.props.date;
    if (this.props.toRangeDate) {
      toRangeDate = moment(this.props.toRangeDate);
    } else {
      toRangeDate = moment()
        .subtract(2, 'day')
        .endOf('day');
    }

    this.state = {
      markedDay: moment(date),
      numberDaysInCurrentMonth: moment(date).daysInMonth(),
      firstDayOfCurrentMonth: moment(date).startOf('month'),
      previousMonthDate: moment(date).subtract(1, 'month'),
      numberDaysPreviousMonth: moment(date)
        .subtract(1, 'month')
        .daysInMonth(),
      nextMonthDate: moment(date).add(1, 'month'),
      markedDayFormat: moment(date).format('DD/MM/YYYY'),
      displayCalendar: false,
      dayColor: '#0e6355',
      sinceRangeDate: moment(new Date(2017, 0, 1)).startOf('day'),
      toRangeDate: toRangeDate,
      noData: false
    };

    this.displayCalendar = this.displayCalendar.bind(this);
    this.changeCurrentDay = this.changeCurrentDay.bind(this);
    this.changeCurrentDayDelayed = this.changeCurrentDayDelayed.bind(this);
    this.changeCurrentMonth = this.changeCurrentMonth.bind(this);
    this.changeSelectedMonth = this.changeSelectedMonth.bind(this);
    this.changeSelectedMonthDelayed = this.changeSelectedMonthDelayed.bind(this);
    this.changeSelectedYear = this.changeSelectedYear.bind(this);
    this.changeSelectedYearDelayed = this.changeSelectedYearDelayed.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.noDataSelect = this.noDataSelect.bind(this);
  }

  handleClickOutside(e) {
    // console.log('handleclickOutside', e.target);
    if (this.calendarNode) {
      if (this.calendarNode.contains(e.target)) {
        return;
      }
      if (e['path'] != undefined && e.path.includes(this.node)) {
        return;
      }
      // console.log('calendar not contains');
      this.displayCalendar();
    }
  }

  displayCalendar() {
    if (!this.props.displayChange) {
      if (this.props.type === 'oneDate') {
        if (!this.state.displayCalendar) {
          document.addEventListener('click', this.handleClickOutside, false);
        } else {
          document.removeEventListener('click', this.handleClickOutside, false);
        }
      }
      let display = this.state.displayCalendar;
      this.setState({
        displayCalendar: !display
      });
    } else {
      this.props.displayChange(this.props.type);
    }
  }

  changeCurrentDay(date) {
    setTimeout(this.changeCurrentDayDelayed, 0, date);
  }
  changeCurrentDayDelayed(date) {
    this.setState({
      markedDay: moment(date),
      numberDaysInCurrentMonth: moment(date).daysInMonth(),
      firstDayOfCurrentMonth: moment(date).startOf('month'),
      previousMonthDate: moment(date).subtract(1, 'month'),
      numberDaysPreviousMonth: moment(date)
        .subtract(1, 'month')
        .daysInMonth(),
      nextMonthDate: moment(date).add(1, 'month'),
      markedDayFormat: moment(date).format('DD/MM/YYYY')
    });
    if (this.props.displayChange) {
      this.props.displayChange(this.props.type);
    } else {
      this.displayCalendar();
    }
    this.props.changeDate(date);
  }

  changeCurrentMonth(date, option) {
    let newDate = null;
    if (option === 'subtract') {
      newDate = date.subtract(1, 'month');
    } else if (option === 'add') {
      newDate = date.add(1, 'month');
    }
    currDay = newDate;
    let currDay2 = newDate;

    this.setState({
      markedDay: newDate,
      numberDaysInCurrentMonth: newDate.daysInMonth(),
      firstDayOfCurrentMonth: newDate.startOf('month'),
      previousMonthDate: moment(currDay).subtract(1, 'month'),
      numberDaysPreviousMonth: moment(currDay)
        .subtract(1, 'month')
        .daysInMonth(),
      nextMonthDate: moment(currDay2).add(1, 'month')
      // markedDayFormat: moment(newDate).format('DD/MM/YYYY')
    });
  }

  changeSelectedMonthDelayed(selectedMonth) {
    let newDate = selectedMonth;
    let currDay = newDate;
    let currDay2 = newDate;
    this.setState({
      markedDay: newDate,
      numberDaysInCurrentMonth: newDate.daysInMonth(),
      firstDayOfCurrentMonth: newDate.startOf('month'),
      previousMonthDate: moment(currDay).subtract(1, 'month'),
      numberDaysPreviousMonth: moment(currDay)
        .subtract(1, 'month')
        .daysInMonth(),
      nextMonthDate: moment(currDay2).add(1, 'month')
      // markedDayFormat: moment(newDate).format('DD/MM/YYYY')
    });
  }

  changeSelectedMonth(e) {
    let value = moment(e.target.value, 'DD-MM-YYYY');
    setTimeout(this.changeSelectedMonthDelayed, 0, value);
  }

  noDataSelect() {
    this.displayCalendar();
    this.setState({ noData: true }, () => {
      this.props.changeDate('nodata');
    });
  }
  changeSelectedYearDelayed(selectedYear) {
    let month = moment(this.state.markedDay).month();
    let change = new Date(selectedYear, month, 20);
    let newDate = moment(change);
    if (
      newDate.format('MM YYYY') > this.state.toRangeDate.format('MM YYYY') ||
      newDate.format('MM YYYY') < this.state.sinceRangeDate.format('MM YYYY')
    ) {
      newDate = moment(this.state.toRangeDate);
    }
    let currDay = newDate;
    let currDay2 = newDate;

    this.setState({
      markedDay: newDate, //month, year
      numberDaysInCurrentMonth: newDate.daysInMonth(),
      firstDayOfCurrentMonth: newDate.startOf('month'),
      previousMonthDate: moment(currDay).subtract(1, 'month'),
      numberDaysPreviousMonth: moment(currDay)
        .subtract(1, 'month')
        .daysInMonth(),
      nextMonthDate: moment(currDay2).add(1, 'month')
      //  markedDayFormat: moment(newDate).format('DD/MM/YYYY')
    });
  }

  changeSelectedYear(e) {
    let selectedYear = e.target.value;
    setTimeout(this.changeSelectedYearDelayed, 0, selectedYear);
  }

  render() {
    let display = null;
    if (this.props.displayChange) {
      display = this.props.display;
    } else {
      display = this.state.displayCalendar;
    }
    let calendarLabel = null;
    if (this.props.type == 'oneDate') {
      if (this.props.placeholderVal == true) {
        calendarLabel = (
          <CurrentDatePlaceSelectStyle
            fieldHeight={this.props.fieldHeight}
            onClick={this.displayCalendar}
            placeholder="true"
          >
            DD/MM/YYYY
          </CurrentDatePlaceSelectStyle>
        );
      } else {
        calendarLabel = (
          <CurrentDatePlaceSelectStyle
            fieldHeight={this.props.fieldHeight}
            onClick={this.displayCalendar}
          >
            {this.state.markedDayFormat}
          </CurrentDatePlaceSelectStyle>
        );
      }
    } else {
      calendarLabel = (
        <CurrentDatePlace onClick={this.displayCalendar}>
          {this.state.markedDayFormat}
        </CurrentDatePlace>
      );
    }
    return (
      <Fragment>
        <CalendarMainContainer>
          {calendarLabel}
          <div
            ref={node => {
              this.calendarNode = node;
            }}
          >
            {display ? (
              <CalendarContainer>
                <Heading
                  thisDate={this.state.markedDay}
                  changeMonth={this.changeCurrentMonth}
                  changeSelectedMonth={this.changeSelectedMonth}
                  changeSelectedYear={this.changeSelectedYear}
                  name={this.props.name}
                  sinceRange={this.state.sinceRangeDate}
                  toRange={this.state.toRangeDate}
                  notSubtract={this.props.notSubtract}
                />
                <DaysWeeksContainer>
                  <WeekDays />
                  <Days
                    changeDateHandler={this.changeCurrentDay}
                    currentDate={this.state.markedDay}
                    daysInMonth={this.state.numberDaysInCurrentMonth}
                    firstDay={this.state.firstDayOfCurrentMonth}
                    previousMonth={this.state.previousMonthDate}
                    previousMonthDays={this.state.numberDaysPreviousMonth}
                    nextMonthDay={this.state.nextMonthDate}
                    currentDayFormated={this.state.markedDayFormat}
                    currentDayColor={this.state.dayColor}
                    sinceRange={this.state.sinceRangeDate}
                    toRange={this.state.toRangeDate}
                  />
                </DaysWeeksContainer>
                {this.props.noDataOption ? (
                  <NoDataSelect onClick={this.noDataSelect}>No date</NoDataSelect>
                ) : null}
              </CalendarContainer>
            ) : null}
          </div>
        </CalendarMainContainer>
      </Fragment>
    );
  }
}

export default Calendar;
/** Calendar Styles */
//region Styles

const NoDataSelect = styled.div`
  padding: 10px;
  width: 100px;
  border-radius: 10px;
  margin: 0 auto;
  border: 0.5px solid #e1e1e5;
  cursor: pointer;
  font-size: 1.3rem;
  text-transform: uppercase;
  &:hover {
    color: #04d5ac;
    border: 0.5px solid #04d5ac;
  }
`;
const CalendarContainer = styled.div`
  margin-top: 3px;
  padding: 10px 5px;
  display: block;
  background-color: #1e2022;
  width: 385px;
  border: 0.5px solid #36393f;
  border-radius: 3px;
  z-index: 1061;
  position: absolute;
`;

const CalendarMainContainer = styled.div`
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
`;
const ArrowLeft = styled.p`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 8px 4px 0;

  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  margin: 3px;
  margin-left: 10px;
  margin-top: 10px;
  border-color: ${props =>
    props.disabled
      ? 'transparent #99999c transparent transparent'
      : 'transparent #04d5ac transparent transparent'};
  &:hover {
    border-color: ${props =>
      props.disabled
        ? 'transparent #99999c transparent transparent'
        : 'transparent #04d5ac transparent transparent'};
  }
`;

const ArrowRight = styled(ArrowLeft)`
  border-width: 4px 0 4px 8px;
  margin-left: 0px;
  margin-right: 13px;

  border-color: ${props =>
    props.disabled
      ? 'transparent transparent transparent #99999c'
      : 'transparent transparent transparent #04d5ac'};
  &:hover {
    border-color: ${props =>
      props.disabled
        ? 'transparent transparent transparent #99999c'
        : 'transparent transparent transparent #04d5ac'};
  }
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5rem 14rem 14rem 3rem;
  grid-column-gap: 2rem;
  width: 100%;
  margin: 5px 0px;
  padding-top: 8px;

  &:nth-child(1) {
    grid-column: 1/2;
    grid-row: 1;
  }
  &:nth-child(2) {
    grid-column: 2/3;
    grid-row: 1;
  }

  &:nth-child(3) {
    grid-column: 3/4;
    grid-row: 1;
  }

  &:nth-child(4) {
    grid-column: 4/5;
    grid-row: 1;
  }

  & > h1 {
    font-size: 1.4rem;
    margin: 0px;
    padding-right: 20px;
    padding-top: 15px;
    text-transform: uppercase;
    font-weight: lighter;
    text-align: center;
    letter-spacing: 0.3rem;
    grid-column: 1/5;
    grid-row: 2;
  }
`;

const DaysWeeksContainer = styled.div`
  width: 360px;
  margin: auto;
  margin-top: 10px;
`;

const WeekdaysLabel = styled.span`
  display: inline-block;
  width: 40px;
  margin: 5px;
  text-align: center;
  text-transform: uppercase;
  color: #04d5ac;
  font-size: 1.3rem;
`;

const DaysLabel = styled(WeekdaysLabel)`
  color: #e1e1e5;
  height: 30px;
  margin: 5px;
  padding-top: 8px;
  cursor: pointer;
  border-radius: 8px;
  background: ${props => (props.color ? props.color : '#1e2022')};
  &:hover {
    color: ${props => (props.color ? '#e1e1e5' : '#04d5ac')};
  }
`;

const DaysDisabled = styled(DaysLabel)`
  color: #636363;
  cursor: default;
  opacity: 0.2;
  &:hover {
    color: #636363;
  }
`;

const NotThisMonthDays = styled(DaysLabel)`
  color: #636363;
`;

const CurrentDatePlace = styled.div`
  border-radius: 3px;
  background-color: #141617;
  -webkit-box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.2),
    inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.2), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  height: ${props => (props.fieldHeight ? props.fieldHeight + 'px' : '30px')};
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 10px;
  border: none;
  outline: none;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: #e1e1e5;
  letter-spacing: 0.05em;
  line-height: ${props => (props.fieldHeight ? props.fieldHeight + 'px' : '30px')};
  padding-right: 10px;
  width: 120px;
  cursor: pointer;
  text-align: center;
`;

const CurrentDatePlaceSelectStyle = styled(CurrentDatePlace)`
  width: 100%;
  background-color: #1e2022;
  text-align: left;
  color: ${props => (props.placeholder ? 'rgb(52,57,64)' : '#e1e1e5')};
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #00d5a9;
    position: absolute;
    right: 10px;
    top: calc(50% - 2.5px);
    pointer-events: none;
    z-index: 3;
  }
`;

const OptionLabel = styled.label`
  padding: 0 0px 0 10px;
  min-height: 30px;
  display: flex;
  align-items: center;
  background: #282b30;
  border: #141617 solid 0.5px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 10px;
  pointer-events: none;
  order: 2;
  z-index: 1;
  transition: background 0.4s ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 3px;
  &:hover {
    background: #141617;
    border-radius: 3px;
  }
`;

const Select = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 30px;
  color: rgb(225, 225, 229);

  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  border-radius: 3px;
  outline: none;
  background-color: #141617;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  cursor: pointer;
  &:focus ${OptionLabel} {
    position: relative;
    pointer-events: all;
    cursor: pointer;
  }
`;

const OptionInput = styled.input`
  opacity: 0;
  position: absolute;
  left: -99999px;
`;

const CurrentOptionInput = styled(OptionInput)`
  + ${OptionLabel} {
    order: 1;
    z-index: 2;
    height: 30px;
    padding-top: 2px;
    background-color: #141617;
    border-top: none;
    position: relative;
    border-radius: 3px;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.2), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  }

  + ${OptionLabel}:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #00d5a9;
    position: absolute;
    right: 10px;
    top: calc(50% - 2.5px);
    pointer-events: none;
    z-index: 3;
  }

  + ${OptionLabel}:before {
    position: absolute;
    right: 0;
    height: 28px;
    width: 28px;
    content: '';
  }
`;
//endregion
