import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import storage from 'utils/storage';
import EditText from 'components/EditText';
import OptionTooltip from '../OptionTooltip';
import ProgressBar from 'components/ProgressBar';
import FileManager from './FileManager';
import moment from 'moment';
class ProductsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsNumber: 4,
      isHovered: false
    };
    this.displayOnHover = this.displayOnHover.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
  }

  displayOnHover(value, letters_number) {
    //console.log('hover');
    if (!value) return;
    if (!letters_number) return;
    if (value.length > letters_number && !this.state.isHovered) {
      // console.log('display on hover', value);
      this.setState({ isHovered: true });
    }
  }

  hideTooltip() {
    // console.log('hide tooltip');
    this.setState({ isHovered: false });
  }

  render() {
    let displayOnly = null;
    if (storage.USER.privileges.includes('access_playignite_client_section')) {
      displayOnly = true;
    } else {
      displayOnly = false;
    }

    let tableHeader = null;
    let tableCells = [];
    if (this.props.typeData == 'loans') {
      //  console.log('loans', this.props.data, this.props.column_data);
      tableHeader = (
        <tr key={'loans_header'}>
          <HTd key={'loans_header_empty'} typeData={this.props.typeData} />
          {Object.entries(this.props.column_data).map(([k, v]) => (
            <HTd key={'loans_header' + k} typeData={this.props.typeData}>
              {v.name}
            </HTd>
          ))}
          <HTd key={'loans_data_empty2'} typeData={this.props.typeData} />
        </tr>
      );

      Object.entries(this.props.data).map(([key, val]) => {
        let row = [];
        row.push(
          <RowTitle key={'row_loans_title'} active={val.active} loans>
            {val.name}
          </RowTitle>
        );
        Object.entries(this.props.column_data).map(([k, v]) => {
          Object.entries(val.loans_data).map(([lk, lv]) => {
            if (v.slug == lv.slug) {
              if (lv.slug == 'loan_next_date') {
                row.push(
                  <DataCell
                    key={'row_loans_cell' + v.slug + val.slug}
                    product_slug={['loans_data', val.slug]}
                    category_element_slug={lv.slug}
                    value={lv.value}
                    active={val.active}
                    callback={this.props.callback}
                    isCalendar={true}
                    loan={true}
                    width={150}
                    displayOnly={displayOnly}
                  />
                );
              } else if (lv.slug == 'loan_movement_mom') {
                row.push(
                  <DataCell
                    key={'row_loans_cell' + v.slug + val.slug}
                    product_slug={['loans_data', val.slug]}
                    category_element_slug={lv.slug}
                    value={lv.value}
                    active={val.active}
                    callback={this.props.callback}
                    textfieldType={'number'}
                    loan={true}
                    displayOnly={true}
                    dataType={'currency'}
                  />
                );
              } else {
                let dataType = null;

                switch (lv.slug) {
                  case 'loan_total':
                  case 'loan_facility_available':
                    dataType = 'currency';
                    break;
                  case 'loan_fee':
                    dataType = 'percent';
                    break;
                }
                row.push(
                  <DataCell
                    key={'row_loans_cell' + v.slug + val.slug}
                    product_slug={['loans_data', val.slug]}
                    category_element_slug={lv.slug}
                    value={lv.value}
                    active={val.active}
                    callback={this.props.callback}
                    textfieldType={'number'}
                    loan={true}
                    displayOnly={displayOnly}
                    dataType={dataType}
                  />
                );
              }
            }
          });
        });
        row.push(<td key={'row_loans_cell_empty'} />);
        tableCells.push(<tr key={'loans_row' + key}>{row}</tr>);
      });
    }
    if (this.props.typeData == 'products') {
      tableHeader =
        this.props.category_id >= 1 ? null : (
          <tr key={'row_header_products'}>
            <HTd notDisplay key={'header_products_empty'} />
            {Object.entries(this.props.data).map(([k, v]) => (
              <HTd key={'header_products' + v.slug} active={v.active} displayOnly={displayOnly}>
                <OptionTooltip
                  id={this.props.category_name + v.name}
                  data_id={'game_products'}
                  name={v.slug}
                  value={v.active}
                  textValue={v.name}
                  callback={this.props.handleDisplayColumn}
                  displayOnly={displayOnly}
                  handleTitle={true}
                >
                  <ProgressBarContainer active={v.active}>
                    <ProgressBar
                      value={v.onboarding_progress}
                      height={12}
                      notDisplayText={true}
                      rounded={true}
                    />
                  </ProgressBarContainer>
                </OptionTooltip>
              </HTd>
            ))}
            {storage.USER.privileges.includes('access_playignite_client_section') ? (
              <td key={'header_products_empty0'} />
            ) : null}
            <HTd notDisplay key={'header_products_empty2'} />
          </tr>
        );

      Object.entries(this.props.element_categories).map(([key, val]) => {
        let row = [];
        row.push(<RowTitle key={'row_title' + val.slug}>{val.name}</RowTitle>);
        Object.entries(this.props.data).map(([k, v]) => {
          Object.entries(v.element_categories[this.props.category_id].elements).map(([ek, ev]) => {
            if (storage.USER.privileges.includes('access_playignite_admin_section')) {
              displayOnly = ev.display_only;
            }

            if (ev.name === val.name) {
              let dataType = null;
              switch (ev.slug) {
                case 'loan_starting_size':
                  dataType = 'currency';
                  break;
                case 'loan_estimated_amount':
                  dataType = 'currency';
                  break;
                case 'loan_fees_accrued':
                  dataType = 'currency';
                  break;
              }
              row.push(
                <DataCell
                  key={'row_products' + val.slug + v.slug}
                  product_slug={v.slug}
                  category_element_slug={ev.slug}
                  value={ev.value}
                  active={v.active}
                  is_selector={ev.is_selector}
                  callback={this.props.callback}
                  displayOnly={displayOnly}
                  dataType={dataType}
                  textfieldType={ev.type}
                  force_placeholder={ev.force_placeholder}
                  width={180}
                  letters_number={25}
                />
              );
            }
          });
        });
        if (this.props.docsData[val.slug]) {
          if (storage.USER.privileges.includes('access_playignite_client_section')) {
            row.push(
              <FileIcons key={'row_products_files0'}>
                <FileManager
                  data={this.props.docsData[val.slug]}
                  actionType={'upload'}
                  handler={this.props.handleUpload}
                  element={val.slug}
                  uploadStatus={this.props.uploadStatus}
                  clearUploadStatus={this.props.clearUploadStatus}
                />
              </FileIcons>
            );
          }
          row.push(
            <td key={'row_products_files1'}>
              <FileManager
                data={this.props.docsData[val.slug]}
                actionType={'download'}
                handler={this.props.handleDownload}
                element={val.slug}
              />
            </td>
          );
        } else {
          if (storage.USER.privileges.includes('access_playignite_client_section')) {
            row.push(<FileIcons key={'row_products_files0'} />);
          }
          row.push(<td key={'row_products_files1'} />);
        }

        tableCells.push(<tr key={'row_products' + key}>{row}</tr>);
      });
    }
    //console.log('table props', this.props);
    return (
      <ProductsTableContainer>
        <Category>{this.props.category_name}</Category>
        <Table border={0} cellSpacing={0}>
          <TableHead>{tableHeader}</TableHead>
          <TableBody typeData={this.props.typeData}>{tableCells}</TableBody>
        </Table>
      </ProductsTableContainer>
    );
  }
}

export default ProductsTable;

//region Styles

const DataCell = props => {
  //console.log('DataCell props', props);
  if (props.force_placeholder) {
    return (
      <td>
        <CellEdit active={props.active} loan={props.loan} width={props.width}>
          <EditText
            id={props.product_slug + props.category_element_slug}
            name={props.category_element_slug}
            data_id={props.product_slug}
            value={'N/A'}
            callback={props.callback}
            isCalendar={props.isCalendar}
            textfieldHeight={24}
            textfieldType={props.textfieldType}
            displayOnly={true}
            dataType={props.dataType}
          />
        </CellEdit>
      </td>
    );
  }
  if (props.is_selector) {
    return (
      <td>
        <CellEdit active={props.active} width={props.width} optionTooltip>
          <OptionTooltip
            key={props.product_slug + props.category_element_slug}
            id={props.product_slug + props.category_element_slug}
            data_id={props.product_slug}
            name={props.category_element_slug}
            value={props.value}
            callback={props.callback}
            displayOnly={props.displayOnly}
          />
        </CellEdit>
      </td>
    );
  }
  return (
    <td>
      <CellEdit active={props.active} loan={props.loan} width={props.width}>
        <EditText
          id={props.product_slug + props.category_element_slug}
          name={props.category_element_slug}
          data_id={props.product_slug}
          value={props.value}
          callback={props.callback}
          isCalendar={props.isCalendar}
          textfieldHeight={24}
          textfieldType={props.textfieldType}
          displayOnly={props.displayOnly}
          dataType={props.dataType}
          letters_number={props.letters_number}
        />
      </CellEdit>
    </td>
  );
};

const FileIcons = styled.td`
  width: 50px !important;
  background: none !important;
`;

const CellEdit = styled.div`
  // padding-bottom: 5px;
  width: ${props => (props.width ? props.width + 'px' : '120px')};
  margin: 0 auto;
  margin-top: ${props => (props.optionTooltip ? '8px' : '0px')};
  //padding: 0px 34px;
  padding-bottom: 4px;
  ${({ active }) =>
    !active &&
    `
	opacity:0.2;
	pointer-events:none !important;
	cursor:none;
  `};

  ${({ loan }) =>
    loan &&
    `
    padding :0px 5px;
    padding-bottom:4px;
  `};
`;

const TableBody = styled.tbody`
  text-align: center;
  border: none;
  font-size: 1.5rem;

  td {
    height: 40px;
    width: 150px;
  }
  tr:nth-child(odd) td {
    background: #282b30;
  }
  tr:nth-child(even) td {
    background: #24272b;
  }

  tr > td:first-child {
    width: 200px;
    text-align: left;
    padding-left: 20px;
  }
  tr > td:last-child {
    width: 40px !important;
    padding: 0px;
    background: none;
  }

  ${({ typeData }) =>
    typeData == 'loans' &&
    `
   tr  > td{ 
   width:145px !important;}
    tr > td:first-child{
        width:255px !important;
    }

  `};
`;

const RowTitle = styled.td`
  font-family: 'Titillium Web', sans-serif;
  font-size: 14px;
  letter-spacing: 0.05rem;
  font-weight: bolder;
  color: ${props => (props.loans ? '#39b549' : '#e1e1e5')};
  ${({ active, loans }) =>
    !active &&
    loans &&
    `
color:#505357;

	pointer-events:none !important;
	cursor:none;
  `};
`;

const ProgressBarContainer = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0px 40px;
  ${({ active }) =>
    !active &&
    `

//	-webkit-filter: grayscale(1);
//	-webkit-filter: grayscale(100%);
//	filter: gray;
//	filter: grayscale(100%);
	opacity:0.2;
	pointer-events:none !important;
	cursor:none;
  `};
`;

const ProductsTableContainer = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  padding-left: 50px;
  padding-bottom: 40px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
`;

const Category = styled.div`
  position: absolute;
  font-size: 14px;
  margin: 0 !important;
  padding: 0 !important;
  transform-origin: 50% 0%;
  line-height: 50px;
  top: 50%;
  font-family: 'Titillium Web', sans-serif;
  letter-spacing: 0.5rem;
  transform: translate(-50%, 0%) rotate(-90deg) !important ;
  width: auto !important;
  height: auto !important;
`;

const Table = styled.table`
  letter-spacing: 0.04rem;
  width: 100%;
`;

const HTd = styled.td`
  width: 150px;
  height: 50px;
  text-align: center;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  letter-spacing: 0.05rem;
  font-size: 14px;
  color: ${props => (props.color ? props.color : 'color: #fff;')};

  &:first-child {
    width: 200px;
  }
  &:last-child {
    width: 40px;
  }
  cursor: ${props => (props.notDisplay ? 'auto' : 'pointer')};

  ${({ typeData }) =>
    typeData == 'loans' &&
    `
    cursor: auto;
      width:145px !important;
    &:first-child{
        width:255px !important;
    }
    
  
  `};

  ${({ displayOnly }) =>
    displayOnly == true &&
    `

    cursor:auto;
    `};
`;

const TableHead = styled.thead`
  background: none;
`;

//endregion
