import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import check from './assets/check.png';
import clock from './assets/clock.png';
import q_mark from './assets/q_mark.png';
import x from './assets/x.png';
import Switch from 'components/Switch';
import SmallLoader from 'components/SmallLoader';
class OptionTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
      mounted: false,
      value: null,
      waiting: false
    };

    this.handleShowTooltip = this.handleShowTooltip.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.changeDisplay = this.changeDisplay.bind(this);
    this.optionRef = React.createRef();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    if (this.props.textValue) {
      if (this.props.value) {
        this.setState({
          label: 'ON',
          switchColor: 'green',
          value: this.props.value,
          mounted: true
        });
      } else {
        this.setState({ label: 'OFF', switchColor: 'red', value: this.props.value, mounted: true });
      }
    } else {
      this.setState({ value: this.props.value, mounted: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.showTooltip !== nextState.showTooltip ||
      this.state.waiting !== nextState.waiting ||
      nextState.value !== this.state.value ||
      nextState.label !== this.state.label ||
      nextState.switchColor !== this.state.switchColor ||
      nextProps.value !== this.props.value ||
      this.props.handleTitle == true
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      if (props.textValue) {
        let label;
        let color;
        if (props.value) {
          label = 'ON';
          color = 'green';
        } else {
          label = 'OFF';
          color = 'red';
        }
        return {
          value: props.value,
          label: label,
          switchColor: color
        };
      } else {
        return {
          value: props.value
        };
      }
    }

    return null;
  }

  componentDidUpdate(prevState, prevProps) {
    if (prevState.value !== this.state.value) {
      this.setState({ waiting: false });
    }
  }

  handleShowTooltip() {
    if (!this.state.showTooltip) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
    let show = this.state.showTooltip;
    this.setState({ showTooltip: !show });
  }

  handleOutsideClick(e) {
    if (this.optionRef.current) {
      if (this.optionRef.current.contains(e.target)) {
        return;
      }

      this.handleShowTooltip();
    }
  }

  selectOption(opt) {
    if (opt == this.state.value) {
      this.setState({ showTooltip: false, waiting: false }, () => {
        //this.props.callback(this.props.data_id, opt, this.props.name);
      });
    } else if (opt == '' && this.state.value == null) {
      this.setState({ showTooltip: false, waiting: false }, () => {
        this.props.callback(this.props.data_id, opt, this.props.name);
      });
    } else {
      this.setState({ showTooltip: false, waiting: true }, () => {
        this.props.callback(this.props.data_id, opt, this.props.name);
      });
    }
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  changeDisplay(value, label) {
    let newLabel;
    let newColor;
    if (label == 'ON') {
      newLabel = 'OFF';
      newColor = 'red';
    } else {
      newLabel = 'ON';
      newColor = 'green';
    }
    document.removeEventListener('click', this.handleOutsideClick, false);
    this.setState(
      { label: newLabel, switchColor: newColor, waiting: true, showTooltip: false },
      () => {
        this.props.callback(this.props.data_id, !value, this.props.name);
      }
    );
  }

  render() {
    // console.log('OPTION TOOLTIP RENDER >>> this.state, this.props', this.state, this.props);
    if (this.state.waiting) {
      return (
        <div>
          <SmallLoader />
        </div>
      );
    }

    if (this.props.displayOnly && this.props.textValue) {
      return (
        <OptionTooltipContainer>
          <TextContainer active={this.props.value}>
            {this.props.textValue}
            <div>{this.props.children}</div>
          </TextContainer>
        </OptionTooltipContainer>
      );
    }

    if (this.props.displayOnly && !this.props.textValue) {
      return (
        <OptionTooltipContainer>
          {this.props.value == null ? <OptionImg displayOnly src={q_mark} /> : null}
          {this.props.value == 'PENDING' ? <OptionImg displayOnly src={clock} /> : null}
          {this.props.value == 'ACCEPTED' ? <OptionImg displayOnly src={check} /> : null}
          {this.props.value == 'DECLINED' ? <OptionImg displayOnly src={x} /> : null}
        </OptionTooltipContainer>
      );
    }

    if (this.props.textValue) {
      return (
        <OptionTooltipContainer>
          {this.state.showTooltip ? (
            <div ref={this.optionRef}>
              <TooltipContainer>
                <SwitchContainer>
                  {' '}
                  <Switch
                    items={['ON', 'OFF']}
                    handleChange={() => this.changeDisplay(this.props.value, this.state.label)}
                    labelText={this.state.label}
                    height={23}
                    fontSize={10}
                    switchColor={this.state.switchColor}
                  />
                </SwitchContainer>
              </TooltipContainer>
            </div>
          ) : null}
          <TextContainer active={this.props.value} onClick={this.handleShowTooltip}>
            {this.props.textValue}
            <div>{this.props.children}</div>
          </TextContainer>
        </OptionTooltipContainer>
      );
    }

    return (
      <OptionTooltipContainer>
        {this.state.showTooltip ? (
          <div ref={this.optionRef}>
            <TooltipContainer>
              <IconContainer>
                <OptionImg
                  src={q_mark}
                  onClick={() => {
                    this.selectOption('');
                  }}
                />{' '}
                <OptionImg
                  src={check}
                  onClick={() => {
                    this.selectOption('ACCEPTED');
                  }}
                />{' '}
                <OptionImg
                  src={clock}
                  onClick={() => {
                    this.selectOption('PENDING');
                  }}
                />{' '}
                <OptionImg
                  src={x}
                  onClick={() => {
                    this.selectOption('DECLINED');
                  }}
                />
              </IconContainer>
            </TooltipContainer>
          </div>
        ) : null}

        {this.props.value == null ? (
          <OptionContainer>
            <OptionImg onClick={this.handleShowTooltip} src={q_mark} />
          </OptionContainer>
        ) : null}
        {this.props.value == 'PENDING' ? (
          <OptionContainer>
            <OptionImg onClick={this.handleShowTooltip} src={clock} />
          </OptionContainer>
        ) : null}
        {this.props.value == 'ACCEPTED' ? (
          <OptionContainer>
            <OptionImg onClick={this.handleShowTooltip} src={check} />
          </OptionContainer>
        ) : null}
        {this.props.value == 'DECLINED' ? (
          <OptionContainer>
            <OptionImg onClick={this.handleShowTooltip} src={x} />
          </OptionContainer>
        ) : null}
      </OptionTooltipContainer>
    );
  }
}

export default OptionTooltip;

const TextContainer = styled.div`
  color: #39b549;
  ${({ active }) =>
    !active &&
    `
//	-webkit-filter: grayscale(1);
//	-webkit-filter: grayscale(100%);
//	filter: gray;
//	filter: grayscale(100%);
	opacity:0.2;

  `};
`;

const OptionContainer = styled.div`
  //margin-top: 8px;
`;

const OptionImg = styled.img`
  cursor: ${props => (props.displayOnly ? 'auto' : 'pointer')};
`;

const OptionTooltipContainer = styled.div`
  position: relative;
`;

const SwitchContainer = styled.div`
  width: 48px;
  height: 25px;
`;

const IconContainer = styled.div`
  width: 120px;
`;

const TooltipContainer = styled.div`
  position: absolute;
  display: block;
  height: 40px;
  width: auto;
  filter: none;
  padding: 8px;
  background-color: #40444c;
  border-radius: 3px;
  box-shadow: 0px 1px 11px 2px rgba(0, 0, 0, 0.5);
  ${OptionImg} {
    padding: 0px 4px;
  }

  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, -100%);
  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #40444d;
    border-width: 4px;
    margin-left: -4px;
  }
  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: #40444d;
    border-width: 7px;
    margin-left: -7px;
  }
  top: -8px;
  left: 50%;
`;
