import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #282b30;
  align-items: center;
  justify-content: center;
`;

const Loader = styled.div`
  height: 100px;
  width: 100px;
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

class SimpleIndicator extends Component {
  constructor() {
    super(...arguments);
  }

  componentWillMount() {}

  componentDidMount() {
    //setTimeout(() =>chatPageAction.addMesssage("Pawel "),1000);
  }

  componentWilUnmount() {}

  onChange(state) {}

  render() {
    return (
      <Container>
        <Loader>LOADING</Loader>
      </Container>
    );
  }
}

export default SimpleIndicator;
