import React, { Component, PureComponent, Fragment } from 'react';
import './style.css';
import styled from 'styled-components';
import usr_logout from './assets/usr_logout.png';
import usr_menu from './assets/usr_menu.png';
import { TitleContent } from 'views/App';
import ChangePasswordPopups from 'views/App/ChangePasswordForm/ChangePasswordPopups';
import { AppContext } from 'views/App';
import posed, { PoseGroup } from 'react-pose';
import AnimatedTitleBar from 'components/AnnimatedTitleBar';

function LoginPanel(props) {
  return (
    <TitleMenuBox>
      <PoseGroup animateOnMount preenterpose={'initial'}>
        {props.active ? (
          <Container key={'fragment'}>
            <ResetPassword key="resetPasswordKey" onClick={props.passwordPopup}>
              Change Password
            </ResetPassword>
            <Logout key="logoutKey" onClick={props.logout}>
              Log Out <LogoutIcon src={usr_logout} />
            </Logout>
          </Container>
        ) : null}
      </PoseGroup>
    </TitleMenuBox>
  );
}

class TitleBar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
      showPopup: false,
      pose: 'before'
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handlePopup = this.handlePopup.bind(this);
  }

  componentDidMount() {
    this.setState({
      pose: 'after'
    });
  }

  handleClick() {
    if (!this.state.isActive) {
      document.addEventListener('click', this.handleClickOutside, false);
    } else {
      document.removeEventListener('click', this.handleClickOutside, false);
    }

    this.setState(prevState => ({
      isActive: !prevState.isActive
    }));
  }

  handleClickOutside(e) {
    if (this.node) {
      if (this.node.contains(e.target)) {
        return;
      }
      if (this.state.isActive) this.handleClick();
    }
  }

  handlePopup() {
    let state = this.state.showPopup;
    document.removeEventListener('click', this.handleClickOutside, false);
    this.setState({ isActive: false, showPopup: !state });
  }

  render() {
    return (
      <Fragment>
        <AppContext.Consumer>
          {AppState => (
            <Fragment>
              {this.state.showPopup ? (
                <ChangePasswordPopups showPopup={this.handlePopup} logoutCall={AppState.logout} />
              ) : null}
              <AnimatedTitleBar>
                <KappaPride
                  className="TitleBar"
                  key={this.props.id}
                  color={this.props.color}
                  style={{ backgroundColor: this.props.color }}
                  pose={this.state.pose}
                >
                  {this.props.title}
                  <NameBarContainer>
                    <TitleBarMenu onClick={this.handleClick}>
                      Hi, {this.props.name}! <TitleMenuIcon src={usr_menu} />
                    </TitleBarMenu>
                  </NameBarContainer>
                </KappaPride>
                <LoginContainer>
                  <div
                    ref={node => {
                      this.node = node;
                    }}
                  >
                    <LoginPanel
                      active={this.state.isActive}
                      logout={AppState.logout}
                      passwordPopup={this.handlePopup}
                    />
                  </div>
                </LoginContainer>
              </AnimatedTitleBar>
            </Fragment>
          )}
        </AppContext.Consumer>
      </Fragment>
    );
  }
}

export default TitleBar;
/** TitleBar Styles */
//region Styles
const KappaPride1 = styled(
  posed.div({
    before: { scale: 1, backgroundColor: '#f9415d', opacity: 0 },
    after: { scale: 1.0, backgroundColor: '#fcab10', opacity: 1, delay: 100 }
  })
)`
  background: ${props => props.color};
  display: grid;
  min-width: 100%;
  grid-template-columns: 70% 30%;
  grid-row-gap: 0px;
  &:nth-child(1) {
    grid-row: 1;
    grid-column: 1;
  }

  &:nth-child(2) {
    grid-row: 1;
    grid-column: 2;
  }
`;

const KappaPride = styled.div`
  background: ${props => props.color};
  display: grid;
  min-width: 100%;
  grid-template-columns: 70% 30%;
  grid-row-gap: 0px;
  &:nth-child(1) {
    grid-row: 1;
    grid-column: 1;
  }

  &:nth-child(2) {
    grid-row: 1;
    grid-column: 2;
  }
`;

const LoginContainer = styled.div`
  position: relative;
`;

const NameBarContainer = styled.div`
  position: relative;
`;

const TitleMenuBox = styled.div`
  position: absolute;
  // top: 60px;
  right: 0px;
  align-items: right;
  width: 193px;
  display: block;
  z-index: 101;
  color: #afafaf;
  font-size: 1.4rem;
  font-family: 'Titillium Web', sans-serif;
  border-radius: 3px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
`;
const TitleBarMenu = styled.span`
  right: 2.2rem;
  position: absolute;
  cursor: pointer;
  font-size: 1.6rem;
  font-family: 'Titillium Web', sans-serif;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
`;
const Logout = styled(
  posed.div({
    enter: {
      opacity: 1,
      delay: 200,
      x: '0%',
      transition: { duration: 300 }
    },
    exit: { x: '100%', delay: 200, opacity: 0.0, transition: { duration: 200 } },
    initial: { opacity: 0, x: '100%' }
    // before: { scale: 1.0, backgroundColor: '#f9415d', opacity: 0.0 },
    //after: { scale: 1.0, backgroundColor: '#fcab10', opacity: 1,delay:500 }
  })
)`
  padding: 15px 10px;
  padding-left: 37px;
  border-radius: 3px;
  cursor: pointer;
  background: #24272b;
  font-family: 'Titillium Web', sans-serif;
  &:hover {
    background-color: rgb(30, 32, 34);
    transition: background-color 0.4s ease-in-out;
  }
`;

const ResetPassword = styled(
  posed.div({
    enter: {
      opacity: 1,
      delay: 100,
      x: '0%',
      transition: { duration: 300 }
    },
    exit: { x: '100%', opacity: 0.0, transition: { duration: 200 } },
    initial: { opacity: 0, x: '100%' }
    // before: { scale: 1.0, backgroundColor: '#f9415d', opacity: 0.0 },
    //after: { scale: 1.0, backgroundColor: '#fcab10', opacity: 1,delay:500 }
  })
)`
  padding: 15px 10px;
  padding-left: 37px;
  border-radius: 3px;
  cursor: pointer;
  background: #24272b;
  font-family: 'Titillium Web', sans-serif;
  border-bottom: 1px solid #3a3a3a;
  border-radius: 0px;
  display: block;
  &:hover {
    background-color: rgb(30, 32, 34);
    transition: background-color 0.4s ease-in-out;
  }
`;

const Container = styled(
  posed.div({
    enter: {
      opacity: 1,
      delay: 0,
      transition: { duration: 300 }
    },
    exit: { opacity: 0 },
    initial: { opacity: 0.3 }
    // before: { scale: 1.0, backgroundColor: '#f9415d', opacity: 0.0 },
    //after: { scale: 1.0, backgroundColor: '#fcab10', opacity: 1,delay:500 }
  })
)`
  opacity: 0;
  overflow: hidden;
`;

const Container2 = styled.div`
  overflow: hidden;
`;

const LogoutIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 2px;
  left: 35px;
`;

const TitleMenuIcon = styled.img`
  position: relative;
  top: 2px;
  padding-left: 12px;
`;
//endregion
