import React, { Component } from 'react';
import './style.css';
import ledger from './assets/ledger.png';
import detail from './assets/detail_dash.png';
import RevenueChart from '../RevenueChart';
import LoadingIndicator from 'components/LoadingIndicator';
import currency from 'utils/currencyFormatter';
import storage from 'utils/storage';
import styled from 'styled-components';

const FinancesGrid = styled.div`
  margin-top: 100px;
  display: grid;
  grid-template-columns: 5rem 4rem 30rem 30rem 30rem 3rem 10rem 57rem;
  grid-row-gap: 20px;
  margin-left: 20px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #2f3238;
  padding-top: 4rem;
`;

const Title = styled.div`
  width: 80%;
  color: #fff;
  padding: 0rem 0rem 1.2rem;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;

  margin-right: 4rem;
  margin-left: 4rem;
  margin-bottom: 1rem;
  z-index: 101;
`;

class FinancialMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      financialData: null,
      revenueData: null,
      exchangeData: null,
      markedItem: null,
      isFetching: false,
      revenue_group: 0,
      showTooltip: null
    };
    this.mounted = false;
    this.marItem = this.marItem.bind(this);
    this.onDataReceived = this.onDataReceived.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    // defaults.global.line =  {
    //   borderColor: '#00f85a',
    // }
    // defaults.global.defaultFontColor = '#1f9c0c';
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.privilegesUpdated) this.fetchData();
    else
      this.setState({
        isFetching: false
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onDataReceived(data) {
    if (data.failed) {
      //if(receivedData.updatedToken==null)
      this.props.updatePrivCall(false);

      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }

    if (!this.mounted) return;

    let formattedData = {};

    let total_revenue = 0;
    let total_receivables = 0;
    let total_ignition = 0;
    let total_ignition_available = 0;
    let total_invoice = 0;
    let total_invoice_available = 0;

    let i;
    let financialData = data.financialData;
    for (i = 0; i < financialData.length; i++) {
      let item = { ...financialData[i] };
      item.buttons = true;
      item.id = item.game;

      total_revenue += item.revenue;
      total_receivables += item.receivables;
      total_ignition += item.ignition;
      total_ignition_available += item.ignition_available;
      total_invoice += item.invoice;
      total_invoice_available += item.invoice_available;

      if (storage.GAMES_ICONS[financialData[i].slug])
        item.icon = storage.GAMES_ICONS[financialData[i].slug];

      formattedData[item.game] = item;
    }
    formattedData['totals'] = {
      id: 'Total',
      icon: '',
      game: 'TOTAL',
      revenue: total_revenue,
      receivables: total_receivables,
      ignition: total_ignition,
      ignition_available: data.advanceData.available_ignition,
      invoice: total_invoice,
      invoice_available: total_invoice_available,
      revenue_update: null,
      buttons: false
    };

    this.setState({
      isFetching: false,
      financialData: formattedData,
      revenueData: data.revenueData,
      exchangeData: data.exchangeData,
      advanceData: data.advanceData,
      markedItem: '',
      showTooltip: null
    });

    setTimeout(this.marItem, 500);
  }

  marItem() {
    this.setState({
      markedItem: 'Total'
    });
  }

  handleGroupChange(group, value) {
    const state = {};
    state[group] = value;
    this.setState(state);
  }

  fetchData() {
    //const PATH_BASE = 'http://192.168.0.187:5000' ;
    const PATH_BASE = storage.APPSTART_SERVICE_URL[process.env.REACT_APP_BACKEND]; // 'http://35.197.205.121:5000';
    const PATH_CALL = '/selects/all_games_financial_data';
    const PATH_CALL2 = '/selects/all_games_revenue_data';
    const PATH_CALL3 = '/currencies/exchange_rates?currencies=USD-GBP,EUR-GBP';
    const PATH_CALL4 = '/selects/loans_facility_data';
    const url = `${PATH_BASE}${PATH_CALL}`;
    const url2 = `${PATH_BASE}${PATH_CALL2}`;
    const url3 = `${PATH_BASE}${PATH_CALL3}`;
    const url4 = `${PATH_BASE}${PATH_CALL4}`;
    this.setState({ isFetching: true });
    this.getFinancialData(url)
      .then(finData => this.getRevenueData(url2, finData))
      .then(frData => this.getExchangeRates(url3, frData))
      .then(freData => this.getAdvanceData(url4, freData))
      .then(finalData => this.onDataReceived(finalData));

    //setTimeout(this.onDataReceived, 2000);
  }
  getFinancialData(url) {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.usersData = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        data.financialData = json;
        return data;
      });
  }

  getRevenueData(url, data) {
    if (data.failed) {
      data.revenueData = null;
      return data;
    }

    let headers = {
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(json => {
        data.revenueData = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  getExchangeRates(url, data) {
    if (data.failed) {
      data.exchangeData = null;
      return data;
    }
    let headers = {
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(json => {
        data.exchangeData = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  getAdvanceData(url, data) {
    if (data.failed) {
      data.advanceData = null;
      return data;
    }
    let headers = {
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(json => {
        data.advanceData = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  handleRowClick(item) {
    this.setState({ markedItem: item.id });
  }

  handleRowClick2(item) {
    this.props.changeView('FinancialLedger', `AppStart >> Ledger >> ${item.game}`);
  }

  handleRowClick3(item) {
    this.props.changeView('FinancialDashBoard', `AppStart >> DashBoard >> ${item.game}`);
  }

  handleLastUpdateOver(item, event) {
    const tp = { ...item };
    tp.posX = event.pageX - 50;
    tp.posY = event.pageY - 60;
    this.setState({ showTooltip: tp });
  }

  handleLastUpdateOut() {
    this.setState({ showTooltip: null });
  }

  renderItem(item) {
    const onMouseOverCallback = event => this.handleLastUpdateOver(item, event);
    const onMouseOutCallback = () => this.handleLastUpdateOut(item);
    const clickCallback = () => this.handleRowClick(item);
    const clickCallback2 = () => this.handleRowClick2(item);
    const clickCallback3 = () => this.handleRowClick3(item);

    let marked = null;
    let buttons = null;
    if (this.state.markedItem != '' && item.id === this.state.markedItem) {
      marked = 'tableMarkedRow';

      if (item.buttons)
        buttons = (
          <div className={'financesGameBtnCont'}>
            <div className={'financesGameButton'} onClick={clickCallback2}>
              <img className={'financesIcon'} src={ledger} />
            </div>
            <div className={'financesGameButton'} onClick={clickCallback3}>
              <img className={'financesIcon'} src={detail} />
            </div>
          </div>
        );
    }

    let content = (
      <div className={'financesIconBtnCont'}>
        <img className={'financesIcon'} src={item.icon} />
      </div>
    );
    if (item.id === 'Total') content = item.game;

    let info = null;

    if (item.revenue_update != null) {
      if (Object.keys(item.revenue_update).length > 0)
        info = (
          <div
            className="lastUpdateInfo"
            onMouseEnter={onMouseOverCallback}
            onMouseLeave={onMouseOutCallback}
          >
            ?
          </div>
        );
    }

    const itemRows = [
      <tr className={marked} onClick={clickCallback} key={`row-data-${item.id}`}>
        <td className={'financesInvCells'}>{content}</td>
        <td>
          {currency(item.revenue)}
          {info}
        </td>
        <td>{currency(item.receivables)}</td>
        <td>{currency(item.ignition)}</td>
        <td>{currency(item.ignition_available)}</td>
        <td>{currency(item.invoice)}</td>
        <td>{currency(item.invoice_available)}</td>
        <td className={'financesInvCells'}>{buttons}</td>
      </tr>
    ];

    return itemRows;
  }

  render() {
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }
    if (!this.state.financialData) {
      return (
        <div className="fetchDataerrorMessage">
          <h3>Oops!</h3>
          <p>
            You don't have permissions to see this view or there is no data available at the moment.
            Please try Again!
          </p>
        </div>
      );
    }

    let allItemRows = [];
    let title = '';
    let id = '';
    for (const key of Object.keys(this.state.financialData)) {
      const perItemRows = this.renderItem(this.state.financialData[key]);
      allItemRows = allItemRows.concat(perItemRows);
      if (
        this.state.markedItem != '' &&
        this.state.financialData[key].id === this.state.markedItem
      ) {
        title = this.state.financialData[key].game;
        id = this.state.financialData[key].id;
      }
    }

    let tooltip = null;
    if (this.state.showTooltip) {
      for (const key of Object.keys(this.state.showTooltip.revenue_update)) {
      }

      let divStyle = {
        left: this.state.showTooltip.posX + 'px',
        top: this.state.showTooltip.posY + 'px'
      };
      let psn_eu = this.state.showTooltip.revenue_update.psn_eu;
      let psn_na = this.state.showTooltip.revenue_update.psn_na;
      if (psn_eu != null) psn_eu = psn_eu.slice(5, 16);
      else psn_eu = '-';
      if (psn_na != null) psn_na = psn_na.slice(5, 16);
      else psn_na = '-';

      tooltip = (
        <div style={divStyle} className="lastUpdateTooltip">
          <div className="lastUpdateTooltipText">
            Last update from SCEE:
            <span className="lastUpdateTooltipDate">{psn_eu}</span>
          </div>
          <div className="lastUpdateTooltipText">
            Last update from SCEA:
            <span className="lastUpdateTooltipDate">{psn_na}</span>
          </div>
        </div>
      );
    }

    return (
      <Container key="FinancialMainKey">
        <FinancesGrid>
          <ExchangeTable data={this.state.exchangeData} />
          <TotalsIgnitionTable data={this.state.advanceData} />
          <TotalsInvoiceTable data={this.state.advanceData} />
          <FinancesTable itemRows={allItemRows} />
          <RevenueChart title={title} id={id} data={this.state.revenueData} />
          {tooltip}
        </FinancesGrid>
      </Container>
    );
  }
}

const FinancesTable = ({ itemRows }) => {
  return (
    <div className={'financesContent'}>
      <table className={'tableContentSticky'}>
        <thead className={'tableHeader'}>
          <tr>
            <td rowSpan={2} className={'financesInvCells'} />
            <td rowSpan={2}>Revenue</td>
            <td rowSpan={2}>Account Receivable</td>
            <td colSpan={2}>Ignition Capital</td>
            <td colSpan={2}>Play Capital</td>
            <td rowSpan={2} className={'financesInvCells'} />
          </tr>
          <tr>
            <td>Advanced</td>
            <td>Available</td>
            <td>Advanced</td>
            <td>Available</td>
          </tr>
        </thead>
      </table>
      <table className={'tableContent'}>
        <tbody className={'scrollableTbody'}>{itemRows}</tbody>
      </table>
    </div>
  );
};

const TotalsIgnitionTable = ({ data }) => {
  return (
    <div className={'totalsVelocityContent'}>
      <table className={'tableContent'}>
        <thead className={'tableHeader'}>
          <tr>
            <td colSpan={2}>Ignition Capital</td>
          </tr>
          <tr>
            <td>Advanced</td>
            <td>Available</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{currency(data.advanced_ignition)}</td>
            <td>{currency(data.available_ignition)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const TotalsInvoiceTable = ({ data }) => {
  return (
    <div className={'totalsInvoiceContent'}>
      <table className={'tableContent'}>
        <thead className={'tableHeader'}>
          <tr>
            <td colSpan={2}> Play Capital</td>
          </tr>
          <tr>
            <td>Advanced</td>
            <td>Available</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{currency(data.advanced_invoice)}</td>
            <td>{currency(data.available_invoice)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const ExchangeTable = ({ data }) => {
  return (
    <div className={'exchangesContent'}>
      <table className={'tableContent'}>
        <thead className={'tableHeader'}>
          <tr>
            <td colSpan={2}>Exchange Rates</td>
          </tr>
        </thead>
        <tbody className={'exchangeTableBody'}>
          <tr>
            <td>USD/GBP</td>
            <td>{data[0].value.toFixed(4)}</td>
          </tr>
          <tr>
            <td>EUR/GBP</td>
            <td>{data[1].value.toFixed(4)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FinancialMain;
