import React, { Component, PureComponent, Fragment } from 'react';
import './style.css';
import moment from 'moment';
import game_icon from './assets/game_icon.png';
import ledger from 'views/App/assets/ledger.png';
import AnimatedView from 'components/AnimatedView';
import LoadingIndicator from 'components/LoadingIndicator';
import currency from 'utils/currencyFormatter';
import numberFormatter from 'utils/numberFormatter';
import storage from 'utils/storage';
import styled from 'styled-components';
import DetailInfo from './DetailInfo';
import formatRoundNumberWithDots from 'utils/formatRoundNumberWithDots';
import formatNumberWithK from 'utils/formatNumberWithK';
import posed, { PoseGroup } from 'react-pose';
import { tween, spring } from 'popmotion';
import GameIcon from 'components/GameIcon';
import RadioInput from 'components/RadioInput';

class KpiMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      gamesData: null,
      gamesExtraData: null,
      aggregateData: null,
      selectedPeriod: 30,
      selectedCompare: 7,
      markedItem: null,
      isFetching1: true,
      isFetching2: true,
      revenue_group: 0,
      showTooltip: null,
      pose: 'before',
      showData: true
    };
    this.mounted = false;
    this.marItem = this.marItem.bind(this);
    this.onDataReceived = this.onDataReceived.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.getGamesData = this.getGamesData.bind(this);
    this.getGamesExtraData = this.getGamesExtraData.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    // this.handleCompareChange = this.handleCompareChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.handleRowOut = this.handleRowOut.bind(this);
    this.handleRowOver = this.handleRowOver.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchData();
  }

  /*static getDerivedStateFromProps(nextProps, prevState) {
    console.log('getDerivedStateFromProps');
    // console.log(nextProps,prevState,'<<<<<')
    // if (nextProps.itemRows == prevState.itemRows) return null;
    //else
    return null;
  }*/
  componentWillReceiveProps(nextProps) {
    if (nextProps.privilegesUpdated) this.fetchData();
    else
      this.setState({
        isFetching1: false,
        isFetching2: false
      });
  }
  shouldComponentUpdate(nextProps, nextState) {
    //console.log('shouldComponentUpdate',nextState,this.state)
    if (
      !nextState.isFetching1 &&
      !nextState.isFetching2 &&
      (this.state.isFetching1 !== nextState.isFetching1 ||
        this.state.isFetching2 !== nextState.isFetching2)
    ) {
      return true;
    }
    if (
      nextState.isFetching1 &&
      nextState.isFetching2 &&
      (this.state.isFetching1 !== nextState.isFetching1 ||
        this.state.isFetching2 !== nextState.isFetching2)
    ) {
      return true;
    }
    if (nextState.showData && !this.state.showData) {
      return true;
    }

    if (
      nextState.markedItem &&
      this.state.markedItem &&
      nextState.markedItem.game_slug !== this.state.markedItem.game_slug
    ) {
      return true;
    }
    if (
      (nextState.markedItem !== null && this.state.markedItem === null) ||
      (nextState.markedItem === null && this.state.markedItem !== null)
    ) {
      return true;
    }

    return false;
    //if (this.props.itemRows !== nextProps.itemRows) {
    //console.log(this.props.itemRows,' <> ',nextProps.itemRows,' <> ',this.props.itemRows == nextProps.itemRows);
    //return true;
    //}
    /*if (this.state.count !== nextState.count) {
      return true;
    }*/
    //return false;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  marItem() {
    this.setState({
      markedItem: 'Total'
    });
  }

  handleGroupChange(group, value) {
    const state = {};
    state[group] = value;
    this.setState(state);
  }
  compareDates(startDate, endDate) {
    if (!startDate || !endDate) return 0;
    let start = moment(startDate);
    let end = moment(endDate);
    return end.diff(start, 'minutes');
  }

  /** Fetching data */
  //region Fetching data
  fetchData() {
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.189.113.91:5000';
    const PATH_CALL = `/analytics/dashboard_kpi/${this.state.selectedPeriod}/${
      this.state.selectedCompare
    }?games-filter=${this.props.filter}`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    this.setState({ isFetching1: true, isFetching2: true, pose: 'before' });
    this.getGamesData(url).then(gamesData => this.onDataReceived(gamesData));
    this.getGamesExtraData().then(gamesExtraData => this.onExtraDataReceived(gamesExtraData));
  }

  onDataReceived(data) {
    if (data.failed) {
      //if(receivedData.updatedToken==null)
      this.props.updatePrivCall(false);

      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }
    //this.setToken(data, 'KpiMainData');
    if (!this.mounted) return;

    /* let newState = { ...this.state };
    newState.gamesData = data.gamesData.data;
    newState.aggregateData = data.gamesData.aggregate;
    console.log('newState',newState)
    this.setState(newState);
*/
    this.setState({
      gamesData: data.gamesData.data,
      aggregateData: data.gamesData.aggregate,
      showTooltip: null,
      isFetching1: false
    });

    // setTimeout(this.marItem, 500);
  }
  getGamesData(url) {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.gamesData = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }

        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.gamesData = json.result;
        else data.gamesData = json;
        return data;
      })
      .catch(error => {
        data.failed = true;
        return data;
        console.error('zjebalo sie ', error);
      });
  }

  getGamesExtraData() {
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/analytics/dashboard_metrics_trends/30?games-filter=${this.props.filter}`;
    const url = `${PATH_BASE}${PATH_CALL}`;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.gamesExtraData = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.gamesExtraData = json.result;
        else data.gamesExtraData = json;
        return data;
      })
      .catch(error => {
        data.failed = true;
        return data;
        console.error(error);
      });
  }

  onExtraDataReceived(data) {
    if (!this.mounted) return;
    if (data.failed) {
      //if(receivedData.updatedToken==null)
      this.props.updatePrivCall(false);

      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }

    /*let newState = { ...this.state };
        newState.gamesExtraData = data.gamesExtraData;
        console.log('newState2',newState)
        this.setState(newState);
    */
    this.setState({
      gamesExtraData: data.gamesExtraData,
      isFetching2: false
    });
  }
  //endregion

  updateData(state, props) {
    let data = this.getToken('KpiMainData');
    let extraData = this.getToken('KpiMainExtraData');

    return {
      gamesData: data.gamesData.data,
      aggregateData: data.gamesData.aggregate
    };
  }
  updateExtraData(state, props) {
    let data = this.getToken('KpiMainExtraData');
    return {
      gamesExtraData: data.gamesExtraData
    };
  }

  handleRowClick(item) {
    this.props.changeView(item.game_slug, item.name, item.icon);

    //this.setState({ markedItem: item.id });
  }

  handleRowOver(item) {
    this.setState({ markedItem: item });
  }

  handleRowOut() {
    this.setState({ markedItem: null });
  }
  handlePeriodChange(changeEvent) {
    this.setState({
      selectedPeriod: changeEvent.target.value,
      pose: 'before',
      isFetching1: true
    });
    setTimeout(this.fetchData, 500);
    //this.fetchData();
    //this.props.changeView('FinancialLedger', `AppStart >> Ledger >> ${item.game}`);
  }

  handleLastUpdateOver(item, event) {
    const tp = { ...item };
    tp.posX = event.pageX - 50;
    tp.posY = event.pageY - 60;
    this.setState({ showTooltip: tp });
  }

  handleLastUpdateOut() {
    this.setState({ showTooltip: null });
  }

  render() {
    let state = this.state;

    if (this.state.isFetching1 || this.state.isFetching2) {
      return <LoadingIndicator />;
    }

    if (this.state.gamesData == null || this.state.gamesExtraData == null) {
      return (
        <div className="fetchDataerrorMessage">
          <h3>Oops!</h3>
          <p>
            You don't have permissions to see this view or there is no data available at the moment.
            Please try Again!
          </p>
        </div>
      );
    }

    let tooltip = null;

    if (this.state.showTooltip) {
      for (const key of Object.keys(this.state.showTooltip.revenue_update)) {
      }

      let divStyle = {
        left: this.state.showTooltip.posX + 'px',
        top: this.state.showTooltip.posY + 'px'
      };
      let psn_eu = this.state.showTooltip.revenue_update.psn_eu;
      let psn_na = this.state.showTooltip.revenue_update.psn_na;
      if (psn_eu != null) psn_eu = psn_eu.slice(5, 16);
      else psn_eu = '-';
      if (psn_na != null) psn_na = psn_na.slice(5, 16);
      else psn_na = '-';

      tooltip = (
        <div style={divStyle} className="lastUpdateTooltip">
          <div className="lastUpdateTooltipText">
            Last update from SCEE:
            <span className="lastUpdateTooltipDate">{psn_eu}</span>
          </div>
          <div className="lastUpdateTooltipText">
            Last update from SCEA:
            <span className="lastUpdateTooltipDate">{psn_na}</span>
          </div>
        </div>
      );
    }

    return (
      <AnimatedView
        animKey={'AnimatedViewKpiMain'}
        onPoseComplete={() => {
          this.setState({ showData: true });
        }}
      >
        <KPIContainer key={'KpiContainerKey'}>
          <RadioButtonPeriod
            key={'KpiContainerRadioButtonPeriodKey'}
            handleOptionChange={this.handlePeriodChange}
            selectedOption={this.state.selectedPeriod}
          />
          <GamesTable
            key={'KpiMainGamesTableKey'}
            showData={this.state.showData}
            data={this.state.gamesData}
            aggregate={this.state.aggregateData}
            dataExtra={this.state.gamesExtraData}
            markedItem={this.state.markedItem}
            over={this.handleRowOver}
            out={this.handleRowOut}
            callBack={this.handleRowClick}
          />
          {tooltip}
        </KPIContainer>
      </AnimatedView>
    );
  }
}

const RadioButtonPeriod = ({ handleOptionChange, selectedOption }) => {
  return (
    <PeriodsContainer>
      <RadioButtonsContainer>
        <PeriodTitle>Period:</PeriodTitle>
        <form>
          <div>
            <RadioInput
              id={'yesterday'}
              value={'1'}
              onChange={handleOptionChange}
              checked={Number(selectedOption) === 1}
              label={'Last Day'}
            />
          </div>
          <div>
            <RadioInput
              id={'lastweek'}
              value={'7'}
              onChange={handleOptionChange}
              checked={Number(selectedOption) === 7}
              label={'Last Week'}
            />
          </div>
          <div>
            <RadioInput
              id={'lastmonth'}
              value={'30'}
              onChange={handleOptionChange}
              checked={Number(selectedOption) === 30}
              label={'Last Month'}
            />
          </div>
          <div>
            <RadioInput
              id={'last3months'}
              value={'90'}
              onChange={handleOptionChange}
              checked={Number(selectedOption) === 90}
              label={'Last 3 Months'}
            />
          </div>
        </form>
      </RadioButtonsContainer>
    </PeriodsContainer>
  );
};

const GamesTable = ({ data, aggregate, dataExtra, markedItem, over, out, callBack, showData }) => {
  let red = {
    color: 'red',
    fontSize: 1.3 + 'rem',
    margin: 10 + 'px'
  };
  let green = {
    color: 'green',
    fontSize: 1.3 + 'rem',
    margin: 10 + 'px'
  };
  let yellow = {
    color: '#fbffc6',
    fontSize: 1.3 + 'rem'
  };
  /** Rows info*/
  //region Row info
  let rows = data.map(item => {
    let info = [];
    let value = [];
    let values = [];
    let color = [];
    let marked = '';
    let buttons = null;
    const onMouseOverCallback = () => over(item);
    const onMouseOutCallback = () => out(item);
    let gameExtra = {};
    item.icon = game_icon;
    /* if (item.game_name === 'Letta') item.icon = letta;
    else if (item.game_name === 'Snipers vs Thieves') item.icon = svt;
    else if (item.game_name === 'Dick Wilde') item.icon = dickwilde;
    else if (item.game_name === 'Magnate') item.icon = magnate;
    else if (item.game_name === 'Fractured Space') item.icon = fracturedspace;
    else if (item.game_name === 'Cubiti: Dodge') item.icon = cubiti;
    else if (item.game_name === 'Mowy Lawn') item.icon = mowy;
    else if (item.game_name === 'Stardust Battle') item.icon = stardust;
    else if (item.game_name === 'Tiny Armies') item.icon = armies;
    else if (item.game_name === 'Ava Airborne') item.icon = ava;
    else if (item.game_name === 'Dig that Gold') item.icon = dig;*/

    if (storage.GAMES_ICONS[item.game_slug]) item.icon = storage.GAMES_ICONS[item.game_slug];

    Object.entries(item).map(([k, v]) => {
      let change = 0;
      if (k in dataExtra['trends'][item.game_slug]) {
        change = formatNumberWithK(dataExtra['trends'][item.game_slug][k].change, 0);
        if (dataExtra['trends'][item.game_slug][k].change < 1) {
          change = formatNumberWithK(dataExtra['trends'][item.game_slug][k].change, 2);
        }
      } else {
        change = 0;
      }
      switch (v.type) {
        case 'no_data':
          value[k] = <NoValueFormat>No data</NoValueFormat>;
          break;
        case 'percent':
          value[k] = `${Number(v.current).toFixed(2)} %`;
          break;
        case 'number':
          value[k] = `${numberFormatter(v.current)}`;
          values[k] = dataExtra['trends'][item.game_slug][k].values;
          if (dataExtra['trends'][item.game_slug][k].sign === 1) {
            color[k] = '1, 210, 167';
            info[k] = <span style={green}>{' \u25B2' + change + '%'}</span>;
          } else if (dataExtra['trends'][item.game_slug][k].sign === -1) {
            color[k] = '202, 67, 172';
            info[k] = <span style={red}>{' \u25BC' + change + '%'}</span>;
          } else if (dataExtra['trends'][item.game_slug][k].sign === 0) {
            color[k] = '86, 150, 178';
            info[k] = <span>{null}</span>;
          }
          break;
        case 'currency':
          if (k == 'revenue_period' || k == 'revenue_avg') {
            value[k] = `${'$' + formatRoundNumberWithDots(v.current)}`;
          } else {
            value[k] = `${currency(v.current)}`;
          }
          if (k == 'revenue_period') break;
          values[k] = dataExtra['trends'][item.game_slug][k].values;
          if (dataExtra['trends'][item.game_slug][k].sign === 1) {
            color[k] = '1, 210, 167';
            info[k] = <span style={green}>{' \u25B2' + change + '%'}</span>;
          } else if (dataExtra['trends'][item.game_slug][k].sign === -1) {
            color[k] = '202, 67, 172';
            info[k] = <span style={red}>{' \u25BC' + change + '%'}</span>;
          } else if (dataExtra['trends'][item.game_slug][k].sign === 0) {
            info[k] = <span>{null}</span>;
            color[k] = '86, 150, 178';
          }
          break;
      }

      if (Number(v.current) === 0) {
        info[k] = null;
      }
    });
    let isMarked = '#282b30';
    if (markedItem != null && item.game_slug === markedItem.game_slug) {
      isMarked = 'rgb(30, 32, 34)';
      if (item.buttons)
        buttons = (
          <div className={'financesGameBtnCont'}>
            <div className={'financesGameButton'}>
              <img className={'financesIcon'} src={ledger} />
            </div>
          </div>
        );
    }

    let overallLevel = 0;
    if (storage.GAMES_DATA[item.game_slug]) {
      storage.GAMES_DATA[item.game_slug].alerts.map(item => {
        if (item.level > overallLevel) overallLevel = item.level;
      });
    }

    let colorsTable = ['#009432', '#f79f1f', '#ea2027'];

    return (
      <Fragment key={item.game_slug}>
        <TableRows
          isMarked={isMarked}
          onClick={() => callBack(item)}
          key={`row-data-${item.game_slug}`}
          onMouseEnter={onMouseOverCallback}
          onMouseLeave={onMouseOutCallback}
        >
          <td>
            <GameIconPanel>
              <GameTitle>{item.game_name}</GameTitle>
              <GameIcon
                slug={item.game_slug}
                src={item.icon}
                size={42}
                indicatorSize={15}
                color={colorsTable[overallLevel]}
                tooltip={{
                  orientation: 'left',
                  top: '-12',
                  left: '30',
                  right: null,
                  bottom: null,
                  arrow: { top: '14px', left: null, right: '100%', bottom: null },
                  origin: { top: '14px', left: 0 }
                }}
              />
            </GameIconPanel>
          </td>
          <td>
            <DetailInfo
              key={`detail-info-ltv-${item.game_slug}`}
              showData={showData}
              value={value['ltv_60']}
              info={info['ltv_60']}
              values={values['ltv_60']}
              color={color['ltv_60']}
              dates={dataExtra.dates}
            />
          </td>
          <td>
            <DetailPanel>{value['roi_60']}</DetailPanel>
          </td>
          <td>
            <DetailInfo
              showData={showData}
              value={value['cpi']}
              info={info['cpi']}
              values={values['cpi']}
              color={color['cpi']}
              dates={dataExtra.dates}
            />
          </td>
          <td>
            <DetailInfo
              showData={showData}
              value={value['installs']}
              info={info['installs']}
              values={values['installs']}
              color={color['installs']}
              dates={dataExtra.dates}
            />
          </td>
          <td>
            <DetailInfo
              showData={showData}
              value={value['mau']}
              info={info['mau']}
              values={values['mau']}
              color={color['mau']}
              dates={dataExtra.dates}
            />
          </td>
          <td>
            <DetailInfo
              showData={showData}
              value={value['dau']}
              info={info['dau']}
              values={values['dau']}
              color={color['dau']}
              dates={dataExtra.dates}
            />
          </td>
          <td>
            <DetailInfo
              showData={showData}
              value={value['revenue_avg']}
              info={info['revenue_avg']}
              values={values['revenue_avg']}
              color={color['revenue_avg']}
              dates={dataExtra.dates}
            />
          </td>
          <td>
            <DetailPanel>{value['revenue_period']}</DetailPanel>
          </td>
          <td>
            <DetailPanel>{value['paying_percent']}</DetailPanel>
          </td>
        </TableRows>
      </Fragment>
    );
  });
  //endregion
  /** Aggregate info */
  //region Aggregate Info
  let aggregate_value = [];
  let aggregate_info = [];
  let aggregate_values = [];
  let aggregate_color = [];
  Object.entries(aggregate).map(([key, value]) => {
    let change = 0;
    if (key in dataExtra['aggregate']) {
      change = formatNumberWithK(dataExtra['aggregate'][key].change, 0);
      if (dataExtra['aggregate'][key].change < 1 && dataExtra['aggregate'][key].change > 0) {
        change = formatNumberWithK(dataExtra['aggregate'][key].change, 2);
      }
    }
    switch (value.type) {
      case 'no_data':
        aggregate_value[key] = <NoValueAverage>No data</NoValueAverage>;
        break;
      case 'percent':
        aggregate_value[key] = `${Number(value.current).toFixed(2)} %`;
        break;
      case 'number':
        aggregate_value[key] = `${numberFormatter(value.current)}`;

        aggregate_values[key] = dataExtra['aggregate'][key].values;

        if (dataExtra['aggregate'][key].sign === 1) {
          aggregate_color[key] = '1, 210, 167';
          aggregate_info[key] = <span style={green}>{' \u25B2' + change + '%'}</span>;
        } else if (dataExtra['aggregate'][key].sign === -1) {
          aggregate_color[key] = '202, 67, 172';
          aggregate_info[key] = <span style={red}>{' \u25BC' + change + '%'}</span>;
        } else if (dataExtra['aggregate'][key].sign === 0) {
          aggregate_color[key] = '86, 150, 178';
          aggregate_info[key] = <span>{null}</span>;
        }
        break;
      case 'currency':
        if (key == 'revenue_period' || key == 'revenue_avg') {
          aggregate_value[key] = `${'$' + formatRoundNumberWithDots(value.current)}`;
        } else {
          aggregate_value[key] = `${currency(value.current)}`;
        }
        if (key == 'revenue_period') break;
        aggregate_values[key] = dataExtra['aggregate'][key].values;
        if (dataExtra['aggregate'][key].sign === 1) {
          aggregate_color[key] = '1, 210, 167';
          aggregate_info[key] = <span style={green}>{' \u25B2' + change + '%'}</span>;
        } else if (dataExtra['aggregate'][key].sign === -1) {
          aggregate_color[key] = '202, 67, 172';
          aggregate_info[key] = <span style={red}>{' \u25BC' + change + '%'}</span>;
        } else if (dataExtra['aggregate'][key].sign === 0) {
          aggregate_color[key] = '86, 150, 178';
          aggregate_info[key] = <span>{null}</span>;
        }
    }
  });
  //endregion
  /** Average detail info */
  //region Average detail info
  let average = (
    <tr>
      <td>
        <GameIconPanel>Total / Average</GameIconPanel>
      </td>
      <td>
        <DetailInfo
          showData={showData}
          value={aggregate_value['ltv_60']}
          info={aggregate_info['ltv_60']}
          values={aggregate_values['ltv_60']}
          color={aggregate_color['ltv_60']}
          dates={dataExtra.dates}
        />
      </td>
      <td>
        {aggregate_value['roi_60']}
        {aggregate_info['roi_60']}
      </td>
      <td>
        <DetailInfo
          showData={showData}
          value={aggregate_value['cpi']}
          info={aggregate_info['cpi']}
          values={aggregate_values['cpi']}
          color={aggregate_color['cpi']}
          dates={dataExtra.dates}
        />
      </td>
      <td>
        <DetailInfo
          showData={showData}
          value={aggregate_value['installs']}
          info={aggregate_info['installs']}
          values={aggregate_values['installs']}
          color={aggregate_color['installs']}
          dates={dataExtra.dates}
        />
      </td>
      <td>
        <DetailInfo
          showData={showData}
          value={aggregate_value['mau']}
          info={aggregate_info['mau']}
          values={aggregate_values['mau']}
          color={aggregate_color['mau']}
          dates={dataExtra.dates}
        />
      </td>
      <td>
        <DetailInfo
          showData={showData}
          value={aggregate_value['dau']}
          info={aggregate_info['dau']}
          values={aggregate_values['dau']}
          color={aggregate_color['dau']}
          dates={dataExtra.dates}
        />
      </td>
      <td>
        <DetailInfo
          showData={showData}
          value={aggregate_value['revenue_avg']}
          info={aggregate_info['revenue_avg']}
          values={aggregate_values['revenue_avg']}
          color={aggregate_color['revenue_avg']}
          dates={dataExtra.dates}
        />
      </td>
      <td>
        {aggregate_value['revenue_period']}
        {aggregate_info['revenue_period']}
      </td>
      <td>
        <DetailPanel>
          {aggregate_value['paying_percent']}
          {aggregate_info['paying_percent']}
        </DetailPanel>
      </td>
    </tr>
  );
  //endregion
  return (
    <div className={'trendsContent'}>
      <table className={'trendsTableContent'}>
        <thead className={'trendsTableHeader'}>
          <tr>
            <td>Game</td>
            <td>
              LTV (60d)
              <p>Average</p>
            </td>
            <td>
              ROI (60d)
              <p>Average</p>
            </td>
            <td>
              CPI
              <p>Total</p>
            </td>
            <td>
              Installs
              <p>Total</p>
            </td>
            <td>
              MAU
              <p>Average</p>
            </td>
            <td>
              DAU
              <p>Average</p>
            </td>
            <td>
              Revenue (avg daily)
              <p>Average</p>
            </td>
            <td>
              Revenue (period)
              <p>Total</p>
            </td>
            <td>
              % Paying
              <p>Total</p>
            </td>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
        <AverageTotal>{average}</AverageTotal>
      </table>
    </div>
  );
};

export default KpiMain;

//region Styles
const Container = styled(
  posed.div({
    enter: {
      opacity: 1,
      delay: 500,
      transition: { duration: 500 }
    },
    exit: { opacity: 0 },
    initial: { opacity: 0 },
    before: { opacity: 0 },
    after: { opacity: 1, x: '0%', delay: 100 }
    // before: { scale: 1.0, backgroundColor: '#f9415d', opacity: 0.0 },
    //after: { scale: 1.0, backgroundColor: '#fcab10', opacity: 1,delay:500 }
  })
)`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #2f3238;
  padding-top: 1rem;
`;

const GameIconPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  min-width: 155px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05rem;
  text-align: center;
`;
const KpiGameIcon = styled.img`
  height: 42px;
  width: 42px;
  margin: auto;
  margin-top: 8px;
  display: block;
  border-radius: 10px;
`;

const GameTitle = styled.div`
  margin-bottom: 8px;
`;

const DetailPanel = styled.div`
  text-align: center;
`;

const PeriodsContainer = styled.div`
  position: relative;
  grid-row: 1;
  grid-column: 3/5;
  font-size: 1.3rem;
  height: 3rem;
`;

const RadioButtonsContainer = styled.div`
  position: absolute;
  right: 0px;
  margin-right: 0px;
  padding-top: 10px;
  & > form {
    display: inline !important;
    & > div {
      display: inline !important;
      float: left;
    }
  }
`;

const PeriodTitle = styled.span`
  color: #99999c;
  font-size: 12px;
  padding-right: 5px;
  padding-top: 3px;
  float: left;
`;

const KPIContainer = styled.div`
  margin-top: 128px;
  display: grid;
  grid-template-columns: 16rem 75rem 73rem 1rem;
  @media (max-width: 1800px) {
    grid-template-columns: 10% 40% 40% 10%;
  }
  @media (max-width: 1746px) {
    grid-template-columns: 5% 45% 43% 6%;
  }
  @media (max-width: 1559px) {
    margin-top: 25px;
    grid-template-columns: 2rem 71rem 71rem 1rem;
  }
`;

const poseConfig = {
  closed: { width: 0 },
  open: {
    width: '330px',
    flip: false,
    transition: props =>
      tween({
        ...props,
        duration: 10000
      })
  }
};

const Loader = styled(
  posed.div({
    enter: {
      opacity: 1,
      x: '0%',
      flip: false,
      transition: props =>
        tween({
          ...props,
          duration: 100
        })
    },
    exit: { opacity: 0, x: '-400%' },
    initial: { opacity: 1, x: '-300%' }

    // before: { scale: 1.0, backgroundColor: '#f9415d', opacity: 0.0 },
    //after: { scale: 1.0, backgroundColor: '#fcab10', opacity: 1,delay:500 }
  })
)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 20%;
  margin: 0;
  padding: 0;
  background-color: #fc4561;
`;

const NoValueFormat = styled.span`
  padding-top: 6px;

  display: block;
  font-size: 12px;
`;

const NoValueAverage = styled.span`
  z-index: 10;

  padding-top: 5px;

  text-align: center;
  display: block;
  font-size: 12px;
`;

const AverageTotal = styled.tbody`
  & > div {
    cursor: default;
    height: 72px;
  }
`;

const TableRows = styled.tr`
  transition: background-color 0.4s ease-in-out;
  background-color: ${props => (props.isMarked ? props.isMarked : '#282b30')};
`;

//endregion
