import React, { Component } from 'react';
import { defaults, Bar, Line } from 'react-chartjs-2';
import styled from 'styled-components';
import { backgroundColorTable, borderColorTable } from 'components/ColorPicker/index';
import formatNumberWithDots from 'utils/formatNumberWithDots';

class ArpDauChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false
    };
    this.chartRef = React.createRef();
    this.mounted = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("shouldComponentUpdate",this.state,nextState)
    //if(this.state.tooltip !== nextState.tooltip)return true;
    if (nextProps.data !== this.props.data) return true;
    return false;
  }

  componentDidMount() {
    // console.log("componentDidMount",this.state)
    this.setState({ mounted: true });
    this.mounted = true;
    //console.log('this.chartRef)',this.chartRef);
    this.props.updateChartRef(this.chartRef);
    //this.forceUpdate();
  }
  parseData(data) {
    let labels = [];
    let arpdau = [];
    let arppu = [];
    let arpu = [];

    data.map(item => {
      labels.push(item.date);
      arpdau.push(item.arpdau);
      arppu.push(item.arppu);
      arpu.push(item.arpu);
    });

    let dataSets = [];

    dataSets.push({
      label: 'ARPDAU',
      type: 'line',
      hidden: false,
      fill: false,
      borderWidth: 2,
      borderColor: borderColorTable[0],
      backgroundColor: backgroundColorTable[0],
      pointHitDetectionRadius: 1,
      data: arpdau,
      yAxisID: 'y-axis-1'
    });

    dataSets.push({
      label: 'ARPPU',
      type: 'line',
      hidden: false,
      fill: false,
      borderWidth: 2,
      borderColor: borderColorTable[1],
      backgroundColor: backgroundColorTable[1],
      pointHitDetectionRadius: 1,
      data: arppu,
      yAxisID: 'y-axis-1'
    });

    dataSets.push({
      label: 'ARPU',
      type: 'line',
      hidden: false,
      fill: false,
      borderWidth: 2,
      borderColor: borderColorTable[2],
      backgroundColor: backgroundColorTable[2],
      pointHitDetectionRadius: 1,
      data: arpu,
      yAxisID: 'y-axis-1'
    });

    return {
      labels: labels,
      datasets: dataSets
    };
  }

  render() {
    let data = this.parseData(this.props.data);
    let dt;
    dt = Object.assign({}, data);
    let options = {
      title: {
        display: false,
        text: ''
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          fontColor: '#A9A9AB'
        }
      },
      tooltips: {
        enabled: false,
        labelColors: backgroundColorTable,
        xPadding: 10,
        yPadding: 10,
        yAlign: 'top',
        position: 'nearest',
        intersect: false,
        mode: 'label',
        custom: tooltipModel => {
          this.props.showTooltip(tooltipModel);
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      elements: { point: { radius: 0, hitRadius: 0, hoverRadius: 0 } },
      scales: {
        xAxes: [
          {
            gridLines: {
              drawBorder: true,
              color: '#2f3238'
            }
          }
        ],
        yAxes: [
          {
            stacked: false,
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-1',
            scaleLabel: {
              display: true,
              labelString: ''
            },

            gridLines: {
              drawBorder: true,
              color: '#2f3238'
            },
            labels: {
              show: true
            },

            ticks: {
              min: 0,
              beginAtZero: true,
              callback: function(label, index, labels) {
                let new_label = formatNumberWithDots(label);
                return '$' + new_label;
              }
            }
          }
        ]
      }
    };

    return <Line ref={this.chartRef} data={dt} options={options} />;
  }
}

export default ArpDauChart;
