import React, { Component, Fragment } from 'react';
import Textfield from 'components/Textfield';
import styled from 'styled-components';
import CommonButton, { Button } from 'components/CommonButton/index';
import warning from '../assets/warning.png';

class PasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: '',
      password2: '',
      needSave: false,
      passwordValid: true
    };
    this.setNewPassword = this.setNewPassword.bind(this);
    this.setNewPassword2 = this.setNewPassword2.bind(this);
    this.checkEqual = this.checkEqual.bind(this);
    this.handleSaveNewPassword = this.handleSaveNewPassword.bind(this);
    this.validPassword = this.validPassword.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
  }

  setNewPassword(e) {
    let target = e.target;
    this.setState({ password1: target.value }, () => {
      this.checkEqual();
    });
  }

  setNewPassword2(e) {
    let target = e.target;
    this.setState({ password2: target.value }, () => {
      this.checkEqual();
    });
  }

  checkEqual() {
    let update = false;
    if (
      this.state.password1 !== '' &&
      this.state.password2 !== '' &&
      this.state.password1.length >= 1 &&
      this.state.password2.length >= 1
    ) {
      update = true;
    }
    this.setState({ needSave: update, passwordValid: true });
  }

  validPassword() {
    if (this.state.password1 === this.state.password2) {
      let pattern = /^[a-zA-Z0-9\$\_\-]\S{7,}$/;
      let test = pattern.test(this.state.password1);
      if (test === true) {
        return true;
      }
    } else {
      return false;
    }
  }

  sendRequest() {
    if (this.state.needSave) {
      this.props.saveNewPassword(this.state.password2);
    }
  }

  handleSaveNewPassword() {
    if (!this.state.needSave) {
      return;
    }
    let result = this.validPassword();
    if (result === true) {
      this.sendRequest();
    }
    this.setState({ passwordValid: result });
  }

  render() {
    let info = null;
    if (this.props.isCentered && this.props.isTemporary) {
      info = (
        <InfoTemporary>
          New password must start with number, letter, underscore or hyphen and be at least 8
          characters long.
        </InfoTemporary>
      );
    } else if (this.props.isCentered) {
      info = (
        <InfoCentered>
          New password must start with number, letter, underscore or hyphen and be at least 8
          characters long.
        </InfoCentered>
      );
    } else {
      info = (
        <Info>
          New password must start with number, letter, underscore or hyphen and be at least 8
          characters long.
        </Info>
      );
    }
    return (
      <PassContainer>
        <Title>{this.props.title}</Title>
        {info}

        <FormContainer>
          {this.props.invalidInput || !this.state.passwordValid ? (
            <Warning>
              <WarningIcon src={warning} />
              Error changing password: invalid input.
            </Warning>
          ) : null}
          <TextContainer>
            <Textfield
              type={'password'}
              displayLabel={false}
              placeholder={`Enter new password`}
              id={'setPass1'}
              onChange={this.setNewPassword}
            />
          </TextContainer>
          <TextContainer>
            <Textfield
              type={'password'}
              displayLabel={false}
              placeholder={`Repeat new password`}
              id={'setPass2'}
              onChange={this.setNewPassword2}
            />
          </TextContainer>

          {this.props.isTemporary ? (
            <ButtonPanel>
              <NoBtn>
                <CommonButton text="Do it later" cancel={true} onClick={this.props.show} />
              </NoBtn>
              <YesBtn>
                <Button needUpdate={this.state.needSave} onClick={this.handleSaveNewPassword}>
                  Change Password
                </Button>
              </YesBtn>
            </ButtonPanel>
          ) : (
            <ButtonContainer>
              <Button needUpdate={this.state.needSave} onClick={this.handleSaveNewPassword}>
                Change password
              </Button>
            </ButtonContainer>
          )}
        </FormContainer>
      </PassContainer>
    );
  }
}

export default PasswordForm;

//region Styles
const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'Titillium Web';
  color: #00d5a9;
`;

const Info = styled.div`
  margin: 0 auto;
  margin-top: 5px;
  color: #e1e1e5;
  width: 270px;
  font-size: 12px;
  margin-bottom: 10px;
  text-align: left;
  line-height: 1.3;
  letter-spacing: 0.15rem;
`;

const InfoCentered = styled(Info)`
  width: 480px;
  text-align: center;
`;

const InfoTemporary = styled(InfoCentered)`
  color: #99999c;
  letter-spacing: 0.1rem;
  width: 430px;
`;

const TextContainer = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  margin: 0 auto;
  height: 32px;
  margin-top: 20px;
`;

const PassContainer = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto;
`;

const FormContainer = styled.div`
  margin: 0 auto;
  width: 270px;
  position: relative;
  margin-top: 40px;
  display: block;
`;

const Warning = styled.div`
  width: 290px;
  color: #f29c10;
  font-size: 12px;
  letter-spacing: 0.15rem;
  text-align: center;
  margin: 10px;
  display: block;
  position: absolute;
  top: -37px;
  left: -20px;
`;

const WarningIcon = styled.img`
  margin-right: 5px;
`;

const ButtonPanel = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 18rem 18rem 1fr;
  grid-column-gap: 30px;
  margin-left: -85px;
  padding-top: 10px;
  height: 32px;
  margin-bottom: 40px;
`;

const NoBtn = styled.div`
  grid-row: 1;
  grid-column: 2/3;
`;

const YesBtn = styled.div`
  grid-row: 1;
  grid-column: 3/4;
`;
//endregion
