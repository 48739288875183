import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Panel from 'components/Panel';

let template_external = [
  {
    service: 'appsflyer',
    params: [
      { name: 'platform', value: 'android' },
      { name: 'key', value: '12345' },
      { name: 'password', value: 'mnhf' }
    ],
    isEdited: true
  }
];

let templateOneLabelItem = [{ value: 'android', isEdited: true }];

const changingText = name => {
  if (name.length > 12) {
    let start = name.substring(0, 4);

    let end = name.slice(-4);

    let textNew = start + '...' + end;
    return textNew;
  } else {
    return name;
  }
};

const SourceItemDisplay = ({ item, sourceId, deleteSource, openCloseEdit, oneLabelMode }) => {
  return (
    <SourceContainer>
      {oneLabelMode ? (
        <Panel
          handleDelete={deleteSource}
          editPanel={openCloseEdit}
          itemId={sourceId}
          pHeight={'65px'}
        >
          <SourceNameOne title={item.value}>{item.value}</SourceNameOne>
        </Panel>
      ) : (
        <Panel
          itemId={sourceId}
          handleDelete={deleteSource}
          editPanel={openCloseEdit}
          pHeight={'65px'}
          maxpHeight={'65px'}
        >
          <SourceName>{item.service}</SourceName>
          <SourceParams>
            {Object.values(item.params).map(key => {
              return (
                <Fragment key={key + Math.random()}>
                  <SourceItemDisplayKV>
                    <PlaceholderTitle>{key.name}: </PlaceholderTitle>
                    <SourceLabel title={key.value}>{changingText(key.value)}</SourceLabel>
                  </SourceItemDisplayKV>
                </Fragment>
              );
            })}
          </SourceParams>
        </Panel>
      )}
    </SourceContainer>
  );
};

const SourceItemToEdit = ({
  item,
  openCloseEdit,
  sourceId,
  deleteSource,
  oneLabelMode,
  editText,
  confirmEdit,
  cancelEdit
}) => {
  return (
    <SourceContainer>
      {oneLabelMode ? (
        <Panel
          itemId={sourceId}
          handleCancel={cancelEdit}
          handleAccept={confirmEdit}
          pHeight={'65px'}
        >
          <SourceItemEdit>
            <TextForm
              type="text"
              id={item.value + sourceId}
              placeholder={item.value}
              value={item.value}
              onChange={e => editText(e, sourceId)}
            />
          </SourceItemEdit>
        </Panel>
      ) : (
        <Panel
          itemId={sourceId}
          handleCancel={cancelEdit}
          handleAccept={confirmEdit}
          pHeight={'65px'}
        >
          <SourceName>{item.service}</SourceName>

          {Object.entries(item.params).map(([key, val]) => {
            return (
              <Fragment key={key + sourceId + val.name}>
                <SourceItem>
                  <EditParamTitle>{val.name}</EditParamTitle>
                  <TextForm
                    type="text"
                    id={val.name + sourceId + key + Math.random()}
                    placeholder={val.name}
                    value={val.value}
                    onChange={e => editText(e, sourceId, key)}
                  />
                </SourceItem>
              </Fragment>
            );
          })}
        </Panel>
      )}
    </SourceContainer>
  );
};

const FilterList = ({
  items,
  openCloseEdit,
  deleteSource,
  oneLabelMode,
  editText,
  confirmEdit,
  cancelEdit
}) => {
  let itemsList = [];
  Object.entries(items).map(([key, val]) => {
    if (val.isEdited) {
      itemsList.push(
        <SourceItemToEdit
          id={key}
          key={key + val}
          item={val}
          openCloseEdit={openCloseEdit}
          sourceId={key}
          deleteSource={deleteSource}
          oneLabelMode={oneLabelMode}
          editText={editText}
          confirmEdit={confirmEdit}
          cancelEdit={cancelEdit}
        />
      );
    } else {
      itemsList.push(
        <SourceItemDisplay
          key={key + val + Math.random()}
          item={val}
          sourceId={key}
          deleteSource={deleteSource}
          openCloseEdit={openCloseEdit}
          oneLabelMode={oneLabelMode}
        />
      );
    }
  });

  return <div>{itemsList}</div>;
};

export default FilterList;

/** Filter List styles*/
//region Styles
const TextForm = styled.input`
  border-radius: 3px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  height: 30px;
  box-sizing: border-box;
  padding-left: 10px;
  border: none;
  outline: none;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(225, 225, 229);
  letter-spacing: 0.05em;
  width: 100%;
  padding-right: 10px;
  margin-top: 4px;
`;

const SourceName = styled.div`
  color: #04d5ac;
  font-size: 12px;
  font-weight: bold;
  padding-top: 5px;
  width: 20%;
  letter-spacing: 0.09rem;
  float: left;
`;

const SourceParams = styled.div`
  width: 100%;
  display: block;
  margin-top: -3px;
`;

const SourceNameOne = styled(SourceName)`
  width: 95%;
`;

const SourceLabel = styled.div`
  color: #04d5ac;
  display: block;
  font-size: 12px;
  font-weight: normal;
  padding-right: 2px;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;

const PlaceholderTitle = styled.div`
  color: rgb(225, 225, 229);
  font-size: 12px;
  font-weight: bold;
  padding-right: 5px;
  padding-bottom: 5px;
  display: block;
`;
const SourceItem = styled.div`
  display: inline;
  margin: 0px 10px;
  float: left;
  margin-top: -13px;
`;

const SourceItemEdit = styled.div`
  float: left;
  margin-top: -7px;
  width: 90%;
`;

const SourceItemDisplayKV = styled.div`
  display: inline-block;
  width: 19rem;
  letter-spacing: 0.09rem;
  margin: 0px 8px;
`;

const SourceContainer = styled.div`
  margin-bottom: 10px;
`;

const EditParamTitle = styled.div`
  font-size: 12px;
  color: #99999c;
  display: block;
`;
//endregion
