import React, { Component, Fragment } from 'react';
import Popup from 'components/Popup';
import styled from 'styled-components';
import Textfield from 'components/Textfield';
import CommonButton, { Button } from 'components/CommonButton/index';
import storage from 'utils/storage';
import LoadingIndicator from 'components/LoadingIndicator';
import warning_icon from '../LoginForm/assets/warning_icon.png';

class RememberPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      needUpdate: false,
      send: false,
      isFetching: false,
      isInvalid: false
    };

    this.enterEmail = this.enterEmail.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
  }

  enterEmail(e) {
    let target = e.target;
    let update = false;
    if (target.value !== '') {
      update = true;
    }
    this.setState({ email: target.value, needUpdate: update, isInvalid: false });
  }

  sendRequest() {
    if (!this.state.needUpdate) {
      return;
    }
    this.setState({ isFetching: true });
    const PATH_BASE = storage.ADMIN_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/user/password_change/prepare`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let payload = {
      email: this.state.email
    };

    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status === 200) {
          this.setState({ isInvalid: false, isFetching: false, send: true });
          return json;
        } else {
          this.setState({ isInvalid: true, isFetching: false, send: false });
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;

        return json;
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    if (this.state.isFetching) {
      return (
        <Popup
          mainColor={'#04d5ac'}
          show={this.props.show}
          title={'Reset Password'}
          visible={this.state.isFetching}
          popupheight={310}
        >
          <LoadingIndicator />
        </Popup>
      );
    }

    return (
      <Popup
        mainColor={'#04d5ac'}
        show={this.props.show}
        title={'Reset Password'}
        popupheight={310}
        visible={true}
      >
        {this.state.send ? (
          <Fragment>
            <PopupText>
              <TextBold>Email sent.</TextBold>
              If there was an account associated with the email address you've entered, you should
              receive an email with further instructions shortly.
            </PopupText>
            <ButtonOne>
              <CommonButton text={'OK'} onClick={this.props.show} />
            </ButtonOne>
          </Fragment>
        ) : (
          <Fragment>
            <PopupText>
              Please enter your email that you have used to access PlayStack Pulse. <br />
              You will receive an email with further instructions.
            </PopupText>
            {this.state.isInvalid ? (
              <Warning>
                <WarningIcon src={warning_icon} />
                Error: invalid email.
              </Warning>
            ) : null}
            <TextFieldContainer>
              <Textfield
                displayLabel={false}
                placeholder={`Enter your email`}
                id={'enterPassReset'}
                onChange={this.enterEmail}
              />
            </TextFieldContainer>

            <ButtonPanel>
              <NoBtn>
                <CommonButton text="Cancel" cancel={true} onClick={this.props.show} />
              </NoBtn>
              <YesBtn>
                <Button needUpdate={this.state.needUpdate} onClick={this.sendRequest}>
                  Send
                </Button>
              </YesBtn>
            </ButtonPanel>
          </Fragment>
        )}
      </Popup>
    );
  }
}

export default RememberPassword;
//region Styles
const PopupText = styled.div`
  color: #e1e1e5;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 10px;
  letter-spacing: 0.05rem;
  padding: 0px 40px;
  line-height: 1.5;
  font-size: 14px;
`;

const ButtonOne = styled.div`
  margin: 0 auto;
  width: 27%;
  margin-top: 35px;
`;

const Warning = styled.div`
  color: #f29c10;
  font-size: 12px;
  letter-spacing: 0.1rem;
  text-align: center;
  position: absolute;
  top: 168px;
  left: 220px;
  display: block;
`;

const ButtonPanel = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 15rem 15rem 1fr;
  grid-column-gap: 25px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const NoBtn = styled.div`
  grid-row: 1;
  grid-column: 2/3;
`;

const YesBtn = styled.div`
  grid-row: 1;
  grid-column: 3/4;
`;
const TextFieldContainer = styled.div`
  width: 25rem;
  display: block;
  margin-left: 170px;
  margin-top: 30px;
  margin-bottom: 20px;
`;

const WarningIcon = styled.img`
  margin-right: 5px;
`;

const TextBold = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-bottom: 4px;
`;
//endregion
