import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import CommonButton, { Button } from 'components/CommonButton/index';
import Popup from 'components/Popup';
import PasswordForm from '../PasswordForm';
import storage from 'utils/storage';
import LoadingIndicator from '../../../../components/LoadingIndicator';

class ChangePasswordPopups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChanged: null,
      isFailed: null,
      invalidInput: false,
      isLoading: false
    };
    this.setNewPassword = this.setNewPassword.bind(this);
    this.returnToLogin = this.returnToLogin.bind(this);
  }

  setNewPassword(new_pass) {
    this.setState({ isFetching: true });
    const PATH_BASE = storage.AUTH_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/change_password`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let payload = {
      new_password: new_pass
    };

    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status === 200) {
          this.setState({ isFetching: false, isChanged: true });
          return json;
        } else if (status === 400) {
          this.setState({ isFetching: false, invalidInput: true });
          return;
        } else {
          this.setState({ isFetching: false, isFailed: true });
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        return json;
      })
      .catch(error => {
        console.error(error);
      });
  }

  returnToLogin() {
    this.props.logoutCall();
  }

  render() {
    let colorPopup = '#04d5ac';
    let tempInfo1 = null;
    if (this.state.isLoading) {
      return (
        <Popup
          mainColor={colorPopup}
          show={this.props.showPopup}
          popupwidth={600}
          popupheight={280}
          title={'Change your password'}
        >
          <LoadingIndicator />
        </Popup>
      );
    }
    if (this.props.isTemporary) {
      tempInfo1 = (
        <TempText>
          Please change your temporary password generated by system. <br />
          You can do this here or you can change it later using <br />
          the Change Password button in the menu at the top right of the screen.
        </TempText>
      );
      colorPopup = '#f37927';
    }
    let change = null;
    if (this.state.isChanged) {
      colorPopup = '#04d5ac';
      change = (
        <Popup
          mainColor={colorPopup}
          show={this.props.showPopup}
          title={'Change your password'}
          popupwidth={600}
          popupheight={280}
          visible={this.state.isChanged}
        >
          <PopupText>
            Your password has been successfully changed. You have been logged out from Playstack
            Pulse, please log in again using your new password.
          </PopupText>
          <ButtonOne>
            <CommonButton text={'Return to login'} onClick={this.returnToLogin} />
          </ButtonOne>
        </Popup>
      );
    } else if (this.state.isFailed) {
      colorPopup = '#04d5ac';
      change = (
        <Popup
          mainColor={colorPopup}
          show={this.props.showPopup}
          title={'Change your password'}
          popupwidth={600}
          popupheight={280}
          visible={this.state.isFailed}
        >
          <PopupText>
            Oops! Something went wrong with password change. Please relog and try again.
          </PopupText>
          <ButtonOne>
            <CommonButton text={'Return to login'} onClick={this.returnToLogin} />
          </ButtonOne>
        </Popup>
      );
    }

    return (
      <Fragment>
        {this.state.isChanged || this.state.isFailed ? (
          <Fragment>{change}</Fragment>
        ) : (
          <Popup
            mainColor={colorPopup}
            show={this.props.showPopup}
            title={'Change your password'}
            popupwidth={600}
            popupheight={280}
            visible={!this.state.isChanged && !this.state.isFailed}
          >
            <PopupContainer>
              {tempInfo1}
              <PassContainer>
                <PasswordForm
                  isTemporary={this.props.isTemporary}
                  saveNewPassword={this.setNewPassword}
                  invalidInput={this.state.invalidInput}
                  show={this.props.showPopup}
                  isCentered={true}
                />
              </PassContainer>
            </PopupContainer>
          </Popup>
        )}
      </Fragment>
    );
  }
}

export default ChangePasswordPopups;

//region Styles
const PopupText = styled.div`
  color: #e1e1e5;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  letter-spacing: 0.05rem;
  padding: 0px 40px;
  line-height: 1.5;
  font-size: 14px;
`;

const PassContainer = styled.div`
  padding: 0px 40px;

  margin: 20px auto;
`;

const TempText = styled(PopupText)`
  margin-top: 20px;
  padding: 0px 20px;
  margin-bottom: 10px;
`;

const ButtonOne = styled.div`
  margin: 0 auto;
  width: 33%;
`;

const PopupContainer = styled.div`
  width: 100%;
  margin: 35px auto;
`;
//endregion
