import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import storage from 'utils/storage';
import moment from 'moment';
import DeveloperContact from './DeveloperContact';
import PlayIgniteContact from './PlayIgniteContact';
import ProductsTable from './ProductsTable';
import LoadingIndicator from 'components/LoadingIndicator';
import IgnitionStatus from 'components/IgnitionStatus';
import PlayIgniteCharts from '../PlayIgniteCharts';
import FinancierContact from './FinancierContact';
import ColorfulDataBoxes from '../ColorfulDataBoxes';
class PlayIgniteDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      mounted: false,
      isFetching: false,
      loansData: {},
      uploadStatus: {
        ignition_capital: null,
        playcapital: null,
        playcredits: null
      }
    };

    this.changeData = this.changeData.bind(this);
    this.changeProductsData = this.changeProductsData.bind(this);
    this.getCountriesList = this.getCountriesList.bind(this);
    this.getLoanChartData = this.getLoanChartData.bind(this);
    this.onDataReceived = this.onDataReceived.bind(this);
    this.handlerMailTo = this.handlerMailTo.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleUploadStatus = this.handleUploadStatus.bind(this);
    this.getIgnitionStatusData = this.getIgnitionStatusData.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.updateChartsData = this.updateChartsData.bind(this);
  }

  componentDidMount() {
    this.fetchData()
      .then(gameData => this.getCountriesList(gameData))
      .then(data => this.getLoanChartData(data))
      .then(data => this.getIgnitionStatusData(data))
      .then(finalData => this.onDataReceived(finalData));
    this.setState({ isFetching: true, mounted: true });
  }

  refreshData() {
    this.fetchData()
      .then(gameData => this.getCountriesList(gameData))
      .then(data => this.getLoanChartData(data))
      .then(data => this.getIgnitionStatusData(data))
      .then(finalData => this.onDataReceived(finalData));
  }

  fetchData() {
    //console.log('fetch data');
    const PATH_BASE = storage.PLAYIGNITE_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';
    const PATH_CALL = `/games/${this.props.match.params.game_slug}?data=all`;
    const url = `${PATH_BASE}${PATH_CALL}`;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.gameData = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        //console.log('heeeej');
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }

        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        // console.log('heeeej2');
        if (json.result) data.gameData = json.result;
        else data.gameData = json;
        return data;
      })
      .catch(error => {
        //console.log('heeeej333 error?');
        data.failed = true;
        return data;
      });
  }

  getCountriesList(data) {
    const PATH_BASE = storage.PLAYIGNITE_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';
    const PATH_CALL = '/helpers/country?format=list';
    const url = `${PATH_BASE}${PATH_CALL}`;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    data.countries = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }

        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.countries = json.result;
        else data.countries = json;
        return data;
      })
      .catch(error => {
        data.failed = true;
        return data;
      });
  }

  getLoanChartData(data) {
    const PATH_BASE = storage.PLAYIGNITE_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';
    const PATH_CALL = `/loans/${this.props.match.params.game_slug}`;
    const url = `${PATH_BASE}${PATH_CALL}`;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }

        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.loansChartsData = json.result;
        else data.loansChartsData = json;
        return data;
      })
      .catch(error => {
        data.failed = true;
        return data;
      });
  }

  getIgnitionStatusData(data) {
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.189.113.91:5000';
    const PATH_CALL = `/analytics/ignition_status/${this.props.match.params.game_slug}`;
    const url = `${PATH_BASE}${PATH_CALL}`;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }

        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.ignitionStatusData = json.result;
        else data.ignitionStatusData = json;
        return data;
      })
      .catch(error => {
        data.failed = true;
        return data;
      });
  }

  onDataReceived(data) {
    // console.log('< onDataReceived', data);
    if (data.failed) {
      this.setState({ isFetching: false, isFailed: true });
      this.props.updatePrivCall(false);

      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }
    if (!this.state.mounted) return;

    //console.log('gameData', data.gameData);
    let loans = {};
    let counter = 0;
    Object.entries(data.gameData.loans.products).map(([key, val]) => {
      let objectEl = {};
      objectEl['display_order'] = val.display_order;
      objectEl['name'] = val.name;
      objectEl['slug'] = val.slug;
      objectEl['active'] = val.active;
      objectEl['loans_data'] = {};
      Object.entries(val.loans_data).map(([k, v]) => {
        let loanEl = {};
        if (k === 'loan_total') {
          loanEl['slug'] = k;
          loanEl['name'] = 'Total';
          loanEl['value'] = v;
          objectEl['loans_data'][0] = loanEl;
        } else if (k === 'loan_fee') {
          loanEl['slug'] = k;
          loanEl['name'] = 'Fee';
          loanEl['value'] = v;
          objectEl['loans_data'][1] = loanEl;
        } else if (k === 'loan_movement_mom') {
          loanEl['slug'] = k;
          loanEl['name'] = 'Movement MoM';
          loanEl['value'] = v;
          objectEl['loans_data'][2] = loanEl;
        } else if (k === 'loan_next_date') {
          loanEl['slug'] = k;
          loanEl['name'] = 'Next Loan (date)';
          loanEl['value'] = v;
          objectEl['loans_data'][3] = loanEl;
        } else if (k === 'loan_facility_available') {
          loanEl['slug'] = k;
          loanEl['name'] = 'Facility Available';
          loanEl['value'] = v;
          objectEl['loans_data'][4] = loanEl;
        }
      });
      loans[counter] = objectEl;
      counter++;
    });

    //console.log('loansTEST', loans);
    this.setState(
      {
        isFetching: false,
        data: data.gameData,
        countries: data.countries,
        loansData: loans,
        loansChartsData: data.loansChartsData,
        ignitionStatusData: data.ignitionStatusData
      },
      () => {
        this.parseData(this.state.data);
      }
    );
  }

  parseData(data) {
    if (storage.GAMES_ICONS[data.slug]) {
      data.icon = storage.GAMES_ICONS[data.slug];
    }

    this.setState({ data: data });
  }

  changeData(data_id, value, element_slug) {
    // console.log('changeData', data_id, element_slug, value, typeof value);
    const PATH_BASE = storage.PLAYIGNITE_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';
    const PATH_CALL = `/games/${this.props.match.params.game_slug}`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };

    let payload = {};

    if (Array.isArray(data_id)) {
      payload[data_id[0]] = {};
      payload[data_id[0]][data_id[1]] = {};
      if (element_slug == 'loan_next_date') {
        payload[data_id[0]][data_id[1]][element_slug] = value;
      } else {
        payload[data_id[0]][data_id[1]][element_slug] = parseFloat(value);
      }
    } else {
      payload[data_id] = {};

      payload[data_id][element_slug] = value;
    }

    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) storage.USER.token = token;
        if (status === 200) return json;
      })
      .then(json => {
        if (json === undefined) return;
        this.refreshData();
      })
      .catch(error => {
        console.error(error);
      });
  }

  changeProductsData(product_slug, value, element_slug) {
    //  console.log('change Products Data', product_slug, value, typeof value, element_slug);
    const PATH_BASE = storage.PLAYIGNITE_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';
    const PATH_CALL = `/games/${this.props.match.params.game_slug}/${product_slug}/${element_slug}`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };

    let payload = {};

    if (value === '') {
      payload.value = null;
    } else if (isNaN(value)) {
      payload.value = value;
    } else if (!isNaN(value)) {
      payload.value = parseFloat(value);
    }

    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) storage.USER.token = token;
        if (status === 200) return json;
      })
      .then(json => {
        if (json === undefined) return;
        this.refreshData();
      })
      .catch(error => {
        console.error(error);
      });
  }

  downloadFile(product_slug, element_slug, version, file_name) {
    // console.log('handle download', product_slug, element_slug, version);
    const PATH_BASE = storage.PLAYIGNITE_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';
    const PATH_CALL = `/docs/${
      this.props.match.params.game_slug
    }/${product_slug}/${element_slug}/${version}`;
    const url = `${PATH_BASE}${PATH_CALL}`;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        //let json = response.json();
        if (status != 200) {
          //this.setState({ isDownloadindData: false });
          //   console.log('error', status);
        }
        let blob = response.blob();
        return blob;
      })
      .then(blob => {
        /*
        let url = res.url;
        // console.log('url', url);
        let a = document.createElement('a');
        a.href = url;
        a.download = true;
        a.click(); */
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = file_name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  uploadFile(file, product_slug, element_slug) {
    let newState = { ...this.state };
    newState.uploadStatus[product_slug] = 'UPLOADING';
    this.setState(newState);
    // console.log('handle upload', file, product_slug, element_slug);
    const PATH_BASE = storage.PLAYIGNITE_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';
    const PATH_CALL = `/docs/${this.props.match.params.game_slug}/${product_slug}/${element_slug}`;
    const url = `${PATH_BASE}${PATH_CALL}`;

    let headers = {
      Accept: 'application/json',
      // 'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    let data = new FormData();
    data.append('file', file);

    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: data
    })
      .then(response => {
        let status = response.status;
        //let json = response.json();
        //  console.log('response', response, response.status);
        if (status != 200) {
          //this.setState({ isDownloadindData: false });
          console.log('error', status);
          newState.uploadStatus[product_slug] = 'FAILED';
          this.setState(newState);
        } else {
          newState.uploadStatus[product_slug] = 'SUCCESS';
        }
        this.setState(newState);
        this.refreshData();

        //let responseWithUrl = response;
        // return responseWithUrl;
      })
      .catch(
        error => {
          newState.uploadStatus[product_slug] = 'FAILED';
          this.setState(newState);
          console.log('error', error);
        } // Handle the error response object
      );
  }

  handlerMailTo() {
    window.location.href = `mailto:${this.state.data.financier_contact.financier_contact_email}`;
  }

  handleUploadStatus(slug) {
    let newState = { ...this.state };
    if (slug == 'all') {
      newState.uploadStatus.ignition_capital = null;
      newState.uploadStatus.playcapital = null;
      newState.uploadStatus.playcredits = null;
    } else {
      //  console.log(newState.uploadStatus, slug);
      newState.uploadStatus[slug] = null;
    }
    this.setState(newState);
  }

  updateChartsData(dateFrom, dateTo, needUpdate) {
    if (!needUpdate) return;
    let from = moment(dateFrom).format('DD-MM-YYYY');
    let to = moment(dateTo).format('DD-MM-YYYY');
    const PATH_BASE = storage.PLAYIGNITE_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';
    const PATH_CALL = `/loans/${
      this.props.match.params.game_slug
    }?charts_start_date=${from}&charts_end_date=${to}`;
    const url = `${PATH_BASE}${PATH_CALL}`;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }

        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) {
          data.loansChartsData = json.result;
          this.setState({ loansChartsData: data.loansChartsData });
        } else data.loansChartsData = json;
        return data;
      })
      .catch(error => {
        data.failed = true;
        return data;
      });
  }

  render() {
    //  console.log('RENDER =>>> PlayIgniteDetails state', this.state);
    if (!this.state.mounted) {
      return null;
    }
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }

    if (this.state.isFailed) {
      return (
        <div className="fetchDataerrorMessage">
          <h3>Oops!</h3>
          <p>Invalid or missing required game permissions to see this view, please contact admin</p>
        </div>
      );
    }

    if (
      (!storage.USER.privileges.includes('access_playignite_admin_section') &&
        !storage.USER.privileges.includes('access_playignite_client_section')) ||
      (storage.USER.privileges.includes('access_playignite_admin_section') &&
        storage.USER.privileges.includes('access_playignite_client_section'))
    ) {
      return (
        <div className="fetchDataerrorMessage">
          <h3>Oops!</h3>
          <p>
            You don't have permissions to see this view or there is no data available at the moment.
            Please try Again!
          </p>
        </div>
      );
    }

    // console.log('props', this.props);

    const { data } = this.state;
    let loansAggregate = data.loans.aggregate;
    let templateClientData = [
      {
        name: 'Total Loan Amount',
        value: loansAggregate.loan_total,
        color: '#db46b9',
        type: 'currency'
      },
      {
        name: 'Loan Facility Size',
        value: loansAggregate.loan_facility_available,
        color: '#00d5a9',
        type: 'currency'
      },
      { name: 'Average Fee', value: loansAggregate.loan_fee, color: '#5ca8c9', type: 'percent' },
      {
        name: 'Accrued Interest',
        value: loansAggregate.loan_fees_accrued,
        type: 'currency',
        color: '#dbd300'
      },
      { name: 'Next Advance', value: loansAggregate.loan_next_date, type: 'date', color: '#f37927' }
    ];

    return (
      <div>
        <TitleContainer title={data.name} developer={data.developer.name} icon={data.icon} />
        <DetailViewContainer>
          <Textfields>
            {storage.USER.privileges.includes('access_playignite_admin_section') ? (
              <DeveloperContact
                data={data.external_contact}
                data_id={'external_contact'}
                countries_list={this.state.countries}
                callback={this.changeData}
              />
            ) : null}
            {storage.USER.privileges.includes('access_playignite_client_section') ? (
              <FinancierContact
                data={data.financier_contact}
                data_id={'financier_contact'}
                handler={this.handlerMailTo}
              />
            ) : null}
          </Textfields>
          <BigBoxContainer>
            <ColorfulDataBoxes developer={data.developer.name} data={templateClientData} />
          </BigBoxContainer>
          <IgnitionStatusBox>
            <IgnitionStatus
              data={this.state.ignitionStatusData}
              changeView={this.props.changeViewToKpi}
              slug={this.state.data.slug}
            />
          </IgnitionStatusBox>
          <LoanAmountsBox>
            <LoanAmountsContainer data={this.state.loansData} callback={this.changeData} />
          </LoanAmountsBox>
          <ProductsBox>
            <ProductsTableBox
              data={data.products}
              docsData={data.docs}
              callback={this.changeProductsData}
              handleDisplayColumn={this.changeData}
              handleDownload={this.downloadFile}
              handleUpload={this.uploadFile}
              uploadStatus={this.state.uploadStatus}
              clearUploadStatus={this.handleUploadStatus}
            />
          </ProductsBox>
          {storage.USER.privileges.includes('access_playignite_admin_section') ? (
            <PIContactBox>
              <PlayIgniteContact
                data={data.financier_contact}
                data_id={'financier_contact'}
                callback={this.changeData}
              />
            </PIContactBox>
          ) : null}
          <ChartsContainer>
            <PlayIgniteCharts
              data={this.state.loansChartsData.loan_chart_data}
              title1={'Total Loan'}
              title2={'Interest Fees'}
              from={moment(this.state.loansChartsData.charts_start_date, 'DD-MM-YYYY')}
              to={moment(this.state.loansChartsData.charts_end_date, 'DD-MM-YYYY')}
              handleUpdate={this.updateChartsData}
            />
          </ChartsContainer>
        </DetailViewContainer>
      </div>
    );
  }
}

export default PlayIgniteDetails;

const TitleContainer = props => {
  return (
    <Title>
      <GameImage src={props.icon} />
      <GameName>{props.title}</GameName>
      <DeveloperName>by {props.developer}</DeveloperName>
    </Title>
  );
};

const LoanAmountsContainer = props => {
  return (
    <Fragment>
      <AmountTitle>Loan Amounts outstanding</AmountTitle>
      <GradientContainer>
        <BackgroundContainer>
          <ProductsTable
            data={props.data}
            column_data={props.data[0].loans_data}
            callback={props.callback}
            typeData={'loans'}
          />
        </BackgroundContainer>
      </GradientContainer>
    </Fragment>
  );
};

const ProductsTableBox = props => (
  <ProductsContainer>
    {Object.entries(props.data[0].element_categories).map(([k, v]) => {
      if (!props.data[2].active && v.slug == 'playcredits') {
        return null;
      } else {
        return (
          <ProductsTable
            key={'products_table' + k}
            data={props.data}
            docsData={props.docsData}
            category_name={v.name}
            category_id={k}
            category_slug={v.name}
            element_categories={v.elements}
            callback={props.callback}
            handleDisplayColumn={props.handleDisplayColumn}
            typeData={'products'}
            handleDownload={props.handleDownload}
            handleUpload={props.handleUpload}
            uploadStatus={props.uploadStatus}
            clearUploadStatus={props.clearUploadStatus}
          />
        );
      }
    })}
  </ProductsContainer>
);

//region Styles
const ChartsContainer = styled.div`
  grid-row: 7/8;
  grid-column: 3/6;
  margin-bottom: 100px;
`;

const BigBoxContainer = styled.div`
  grid-row: 2;
  grid-column: 2/7;
`;

const AmountTitle = styled.div`
  font-size: 12px;
  color: #99999c;
  display: block;
  margin-bottom: 5px;
  margin-top: 0px;
`;

const GradientContainer = styled.div`
  border: 1px solid transparent;
  border-top: 1px solid #39b54a;
  border-bottom: 1px solid #ce0f0f;
  border-radius: 3px;
  height: auto;
  background-image: linear-gradient(to bottom, #39b54a 0%, #f8bd2c 50%, #ce0f0f 100%);
  padding: 0px;
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 0px;
  background: #1e2022;
  border-radius: 3px;
  padding-bottom: 10px;
`;

const ProductsBox = styled.div`
  grid-row: 4/7;
  grid-column: 5/6;
  margin-bottom: 20px;
`;

const LoanAmountsBox = styled.div`
  grid-row: 3/4;
  grid-column: 5/6;
`;

const PIContactBox = styled.div`
  grid-row: 5/6;
  grid-column: 3/4;
`;

const IgnitionStatusBox = styled.div`
  margin-top: 20px;
  grid-row: 3/5;
  grid-column: 3/4;
  background: #1e2022;
  border: 0.5px solid #36393f;
  cursor: pointer;
`;
const Textfields = styled.div`
  grid-row: 1;
  grid-column: 2/7;
`;
const DetailViewContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 11rem 6rem 40rem 17rem 92rem 6rem 10rem;
  grid-column-gap: 0rem;
  grid-row-gap: 3rem;
`;

const GameName = styled.div`
  padding-top: 16px;
  display: block;
  padding-bottom: 5px;
  line-height: 1;
`;

const DeveloperName = styled.div`
  line-height: 1;
  display: block;
  font-size: 1.2rem;
  font-weight: normal;
  font-family: 'Roboto';

  color: #99999c;
`;

const GameImage = styled.img`
  border-radius: 10px;

  width: 6rem;
  height: 6rem;
  float: left;
  margin-right: 25px;
  margin-bottom: 15px;
`;

const Title = styled.h2`
  width: 96%;
  color: #fff;
  padding: 0rem 0rem 1rem 2.5rem;
  font-size: 2.5rem;
  line-height: 4rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 2.5rem;
  margin-left: 3rem;
  margin-bottom: 1rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;

const ProductsContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #1e2022;
  border: 0.5px solid #36393f;
  padding-bottom: 10px;
`;

//endregion
