import NotImpelmented from 'views/App/NotImplemented';
import MenuBar from 'components/MenuBar';
import Welcome from 'views/App/Welcome';
import Financial from 'views/App/Financial';
import Admin from 'views/App/Admin';
import Kpi from 'views/App/Kpi';
import Login from 'views/App/Login';
import UserAcquisition from 'views/App/UserAcquisition';
import Dev from 'views/App/Dev';
import PlayIgnite from 'views/App/PlayIgnite';
import GamesManagment from 'views/App/GamesManagement';
import GmsData from 'views/App/GamesManagement/GmsData';
import GmsProfiles from 'views/App/GamesManagement/GmsProfiles';
import GmsPlatforms from 'views/App/GamesManagement/GmsPlatforms';
import GmsGames from 'views/App/GamesManagement/GmsGames';
import Authorization from 'hoc/Authorization';

const AppStartAcess = Authorization(['access_appstart_section']);
const AdminAcess = Authorization(['view_admin_panel2']);
const AnalytycsAccess = Authorization(['access_analytics_section']);
const UserAcquisitionAccess = Authorization(['access_acquisition_section']);
const AccessIgnition = Authorization(['access_external_ignition_status_section']);
const Empty = props => null;

const defaultConfig = {
  kpi: { 'KPIs and Trends': { path: '/kpi', color: '#00d5a9' } },
  external: { 'Ignition Capital Analysis': { path: '/external', color: '#73e5d2' } },
  acquisition: { 'User Acquisition': { path: '/acquisition', color: '#57aaed' } },
  gms: {
    'Games management': {
      path: '/gms/games',
      color: '#2d439c',
      priv: 'access_games_management_section'
    },
    'Data Sources': {
      path: '/gms/data',
      color: '#3a56c5',
      priv: 'access_games_management_data_sources'
    },
    'Configuration profiles': {
      path: '/gms/profiles',
      color: '#4564e4',
      priv: 'access_games_management_config_profiles'
    },
    Platforms: {
      path: '/gms/platforms',
      color: '#4c70ff',
      priv: 'access_games_management_platforms'
    }
  },
  playignite: { PlayIgnite: { path: '/playignite', color: '#db46b9' } },
  //financial: { 'PlayIgnite Ledger': { path: '/financial', color: '#db46b9' } },
  admin: {
    'User Management': { path: '/admin', color: '#93278f' },
    Groups: { path: '/admin/groups', color: '#852481', priv: 'manage_groups' },
    'Game Groups': { path: '/admin/games/groups', color: '#892584', priv: 'manage_groups' }
  },
  dev: { Dev: { path: '/dev', color: '#8d8d8d' } }
};
const defaultConfigBack = {
  back: { Back: { path: '/', color: '#282b30' } },
  kpi: { 'KPIs and Trends': { path: '/kpi', color: '#00d5a9' } },
  external: { 'Ignition Capital Analysis': { path: '/external', color: '#73e5d2' } },
  acquisition: { 'User Acquisition': { path: '/acquisition', color: '#57aaed' } },
  gms: {
    'Games management': {
      path: '/gms/games',
      color: '#2d439c',
      priv: 'access_games_management_section'
    },
    'Data Sources': {
      path: '/gms/data',
      color: '#3a56c5',
      priv: 'access_games_management_data_sources'
    },
    'Configuration profiles': {
      path: '/gms/profiles',
      color: '#4564e4',
      priv: 'access_games_management_config_profiles'
    },
    Platforms: {
      path: '/gms/platforms',
      color: '#4c70ff',
      priv: 'access_games_management_platforms'
    }
  },
  playignite: { PlayIgnite: { path: '/playignite', color: '#db46b9' } },
  //financial: { 'PlayIgnite Ledger': { path: '/financial', color: '#db46b9' } },
  admin: {
    'User Management': { path: '/admin', color: '#93278f' },
    Groups: { path: '/admin/groups', color: '#852481', priv: 'manage_groups' },
    'Game Groups': { path: '/admin/games/groups', color: '#6E126A', priv: 'manage_groups' }
  },
  dev: { Dev: { path: '/dev', color: '#8d8d8d' } }
};
const routes = [
  {
    path: '/',
    exact: true,
    menubar: MenuBar,
    view: Welcome,
    title: 'Welcome to PlayStack Pulse',
    gradient:
      'linear-gradient( 90deg, rgb(87,170,237) 0%, rgb(0,213,169) 25%, rgb(115,229,210) 50%, rgb(219,70,185) 75%, rgb(243,121,39) 100%)',
    menuConfig: defaultConfig
  },
  {
    path: '/playignite',
    exact: false,
    menubar: MenuBar,
    view: PlayIgnite,
    title: 'PlayIgnite',
    gradient: 'linear-gradient( 90deg, rgb(219,70,185) 0%, rgb(133,60,121) 100%)',
    menuConfig: defaultConfigBack
  },
  {
    path: '/financial',
    menubar: MenuBar,
    view: AppStartAcess(Financial),
    title: 'PlayIgnite Ledger',
    gradient: 'linear-gradient( 90deg, rgb(219,70,185) 0%, rgb(133,60,121) 100%)',
    menuConfig: defaultConfigBack
  },
  {
    path: '/gms',
    menubar: MenuBar,
    view: GamesManagment,
    title: 'Games Management',
    gradient: 'linear-gradient( 90deg, rgb(45,67,156) 0%, rgb(71,104,236) 100%)',
    menuConfig: defaultConfigBack
  },
  {
    path: '/admin',
    menubar: MenuBar,
    view: AdminAcess(Admin),
    title: 'User Management',
    gradient: 'linear-gradient( 90deg, rgb(147,39,143) 0%, rgb(97,44,100) 100%)',
    menuConfig: defaultConfigBack
  },
  {
    path: '/kpi',
    exact: false,
    menubar: MenuBar,
    view: AnalytycsAccess(Kpi),
    title: 'KPIs and Trends',
    gradient: 'linear-gradient( 90deg, rgb(0,213,169) 0%, rgb(23,132,113) 100%)',
    filter: 'internal',
    menuConfig: defaultConfigBack
  },
  {
    path: '/external',
    exact: false,
    menubar: MenuBar,
    view: AccessIgnition(Kpi),
    title: 'Ignition Capital Analysis',
    gradient: 'linear-gradient( 90deg, rgb(115,229,210) 0%, rgb(29,168,157) 100%)',
    filter: 'external',
    menuConfig: defaultConfigBack
  },
  {
    path: '/acquisition',
    exact: false,
    menubar: MenuBar,
    view: UserAcquisitionAccess(UserAcquisition),
    title: 'User Acquisition',
    gradient: 'linear-gradient( 90deg, rgb(87,170,237) 0%, rgb(67,110,147) 100%)',
    menuConfig: defaultConfigBack
  },
  {
    path: '/dev',
    exact: false,
    menubar: MenuBar,
    view: Dev,
    title: 'Dev',
    gradient: 'linear-gradient( 90deg, rgb(87,87,87) 0%, rgb(141,141,141) 100%)',
    menuConfig: defaultConfigBack
  }
];

export default routes;
