import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import { tween } from 'popmotion';
class AnimatedNumber extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };

    this.animate = this.animate.bind(this);

    this.number = React.createRef();
  }

  animate() {
    if (this.props.show) {
      this.setState({ visible: false }, this.props.show);
    }
  }
  componentDidMount() {
    if (this.props.value !== 0 || this.props.value === '-') {
      let value = Number(this.props.value);
      value = this.props.toFixed ? value.toFixed(this.props.toFixed) : Math.round(value);
      let t = tween({ to: value, duration: 1500 }).start(v => {
        if (this.number && this.number.current)
          this.number.current.innerHTML =
            (this.props.before ? this.props.before : '') +
            (this.props.toFixed ? v.toFixed(this.props.toFixed) : v.toFixed(0)) +
            (this.props.after ? this.props.after : '');
      });
    } else {
      if (this.number && this.number.current) this.number.current.innerHTML = '-';
    }
  }

  componentDidUpdate() {
    if (this.props.value !== 0 || this.props.value === '-') {
      let value = Number(this.props.value);
      value = this.props.toFixed ? value.toFixed(this.props.toFixed) : Math.round(value);
      let t = tween({ to: value, duration: 1500 }).start(v => {
        if (this.number && this.number.current)
          this.number.current.innerHTML =
            (this.props.before ? this.props.before : '') +
            (this.props.toFixed ? v.toFixed(this.props.toFixed) : v.toFixed(0)) +
            (this.props.after ? this.props.after : '');
      });
    } else {
      if (this.number && this.number.current) this.number.current.innerHTML = '-';
    }
    // this.number.
  }

  render() {
    return <Text ref={this.number}>{this.props.initialValue}</Text>;
  }
}

export default AnimatedNumber;

//region Styles

const Text = styled.div``;

//endregion
// value = (this.props.toFixed?value.toFixed(this.props.toFixed):Math.round(value))
// (this.props.toFixed?v.toFixed(this.props.toFixed):v)
