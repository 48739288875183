import React, { Component, PureComponent } from 'react';
import Regions from './Regions';
import Countries from './Countries';
import './style.css';
import styled from 'styled-components';
import { CheckboxContainer, PanelTitle } from '../CheckboxPanel';
import SelectDeselectAll from 'components/SelectDeselectAll';
import Textfield from 'components/Textfield';
const RegionsCheckboxContainer = styled(CheckboxContainer)`
  height: 413px;
  position: relative;
`;

const RegionsCheckboxListContainer = styled.div`
  overflow: auto;
  margin-left: 10px;
  margin-right: 10px;
`;

const SelectDeselectContainer = styled.div`
  position: absolute;
  right: 21px;
  top: 169px;
`;

const RegionForm = styled.form`
  margin-bottom: 70px;
`;

class RegionCheckboxPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.renderThumbVertical = this.renderThumbVertical.bind(this);
    this.renderTrackVertical = this.renderTrackVertical.bind(this);
  }

  renderThumbVertical({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: '#00D5A9',
      borderRadius: '10px'
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  renderTrackVertical({ style, ...props }) {
    const trackStyle = {
      position: 'absolute',
      width: '6px',
      right: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
      backgroundColor: '#242629'
    };
    return <div style={{ ...style, ...trackStyle }} {...props} />;
  }

  render() {
    if (!this.props.items || this.props.items === undefined) return null;

    return [
      <RegionsCheckboxContainer key={'regionPanelContainerKey'}>
        <PanelTitle>{this.props.name}</PanelTitle>
        <RegionsCheckboxListContainer>
          <RegionForm>
            <Textfield
              id={'regionSearchUA'}
              type={'text'}
              onChange={this.props.searchHandler}
              placeholder={'Type country'}
              value={this.props.searchCountry}
              displayLabel={false}
            />
          </RegionForm>
          <Regions
            currentRegion={this.props.current_region_id}
            regions={this.props.items}
            handleCurrentClickedRegion={this.props.clickedRegionHandler}
            buttonStateAll={this.props.buttonStateAll}
            selectOneRegion={this.props.selectOneRegion}
          />
          <Countries
            items={this.props.items[this.props.current_region_id]}
            searching_mode={this.props.searching_mode}
            searched_countries={this.props.searched_countries[this.props.current_region_id]}
            changeHandler={this.props.handleInputChange}
            renderThumbVertical={this.renderThumbVertical}
            renderTrackVertical={this.renderTrackVertical}
          />
        </RegionsCheckboxListContainer>
        <SelectDeselectContainer>
          <SelectDeselectAll
            selectDeselectAll={this.props.selectDeselectAll}
            allParams={true}
            allTrue={this.props.allTrue}
            allFalse={this.props.allFalse}
            allDiff={this.props.allDiff}
          />
        </SelectDeselectContainer>
      </RegionsCheckboxContainer>
    ];
  }
}

export default RegionCheckboxPanel;
