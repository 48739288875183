import React, { Component } from 'react';
import styled from 'styled-components';
import { InvertTooltip } from '../InvertBtn/index';

const SelectDeselectAllTooltip = styled(InvertTooltip)`
  width: 160px;
  right: -70px;
  opacity: 0;
  position: absolute;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
`;

const SelectDeselectBtn = styled.div`
  width: 13px;
  height: 13px;
  border: 1px solid #04d5ac;
  border-radius: 3px;
  display: block;
  position: absolute;
  cursor: pointer;
  opacity: 1;
  &:hover ${SelectDeselectAllTooltip} {
    opacity: 1;
    display: block;
  }
`;
const SelectElementDiff = styled.div`
  width: 5px;
  height: 5px;
  margin-top: 3px;
  margin-left: 3px;
  display: block;
  background: #04d5ac;
`;

const SelectElementAll = styled.div`
  height: 4px;
  width: 8px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-45deg);
  display: block;
  margin-top: 2px;
  margin-left: 2px;
  color: #04d5ac;
`;

const SelectComponent = styled.div``;

class SelectDeselectAll extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    //   if (!this.props.items || this.props.items === undefined) return null;
    let btn_select = null;

    if (this.props.allParams) {
      if (this.props.allTrue) {
        btn_select = <SelectElementAll />;
      } else if (this.props.allFalse) {
        btn_select = null;
      } else if (this.props.allDiff) {
        btn_select = <SelectElementDiff />;
      }
    } else {
      if (this.props.buttonState.all_true) {
        btn_select = <SelectElementAll />;
      } else if (this.props.buttonState.all_false) {
        btn_select = null;
      } else if (this.props.buttonState.all_diff) {
        btn_select = <SelectElementDiff />;
      }
    }
    let funct = this.props.selectDeselectAll;
    if (this.props.param) {
      funct = () => {
        this.props.selectDeselectAll(this.props.param);
      };
    }

    return (
      <SelectComponent>
        <SelectDeselectBtn onClick={funct}>
          {btn_select}
          <SelectDeselectAllTooltip>
            <span>Select / Deselect All</span>
          </SelectDeselectAllTooltip>
        </SelectDeselectBtn>
      </SelectComponent>
    );
  }
}

export default SelectDeselectAll;
