import React, { Component, Fragment } from 'react';
import moment from 'moment';
import LoadingIndicator from 'components/LoadingIndicator';
import storage from 'utils/storage';
import CommonButton, { Button } from 'components/CommonButton/index';
import DropdownList from 'components/DropdownList';
import DropdownList2 from 'components/DropdownList2';
import Textfield from 'components/Textfield';
import Calendar from 'components/CustomDatePicker/Calendar';
import CheckboxList from 'components/CheckboxList';
import FilterList from 'components/FilterList';
import styled from 'styled-components';
import { Content } from 'views/App';
import add_btn from '../assets/add_btn.png';
import game_icon from '../assets/game_icon.png';
import edit_big from '../assets/edit_big.png';
import SwitchButton from 'components/Switch';
import PopupAddDeveloper from '../PopupAddDeveloper';

class GmsAddGame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      openAddDeveloperPopup: false,
      addDeveloperUpdateBtn: false,
      addDeveloperName: '',
      gameName: '',
      gameIcon: null,
      currentDate: moment().startOf('day'),
      launchDate: '',
      statusList: [],
      statusCurrent: 'live-games',
      configurationProfileList: [],
      configurationProfileCurrent: '',
      configurationProfilePlaceholder: '- Set Configuration Profile -',
      platformsList: [],
      platformsListToDisplay: [],
      dataSourcesList: [],
      dropdownListSources: [],
      developersList: [],
      developerCurrent: '',
      developerPlaceholder: '- Set Developer -',
      dataSourcesTable: [],
      dataSourcesCopyTable: [],
      dataSourceDropdownListPlaceholder: 'New data source',
      dataSourceDropdownListCurrent: '',
      platformsListPlaceholder: 'Set Configuration Profile to see Platform List',
      calendarPlaceholder: true,
      needSave: false,
      needSaveValue: true,
      gameExtInt: 'Internal',
      isInternal: true,
      lastElementHeight: '90px'
    };
    this.mounted = false;
    this.setLaunchDate = this.setLaunchDate.bind(this);
    this.selectStatus = this.selectStatus.bind(this);
    this.setGameName = this.setGameName.bind(this);
    this.selectConfigurationProfile = this.selectConfigurationProfile.bind(this);
    this.checkPlatform = this.checkPlatform.bind(this);
    this.selectDataSource = this.selectDataSource.bind(this);
    this.addSourceToTable = this.addSourceToTable.bind(this);
    this.sourceCloseEditMode = this.sourceCloseEditMode.bind(this);
    this.sourceDeleteFromTable = this.sourceDeleteFromTable.bind(this);
    this.sourceOpenCloseEditMode = this.sourceOpenCloseEditMode.bind(this);
    this.cancelEditDataSource = this.cancelEditDataSource.bind(this);
    this.confirmEditDataSource = this.confirmEditDataSource.bind(this);
    this.handleAddGame = this.handleAddGame.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.editSourceValues = this.editSourceValues.bind(this);
    this.checkUpdate = this.checkUpdate.bind(this);
    this.imageInputRef = React.createRef();
    this.changeIsExternal = this.changeIsExternal.bind(this);
    this.addDeveloper = this.addDeveloper.bind(this);
    this.selectOnFocus = this.selectOnFocus.bind(this);
    this.selectOnBlur = this.selectOnBlur.bind(this);
    this.selectOnChange = this.selectOnChange.bind(this);
  }

  /**Mounting component and fetching data */
  //region Fetching and parsing data
  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.privilegesUpdated) this.fetchData();
    else
      this.setState({
        isFetching: false
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  fetchData() {
    if (!this.mounted) return;
    this.setState({ isFetching: true });
    this.getStatusList()
      .then(data => this.getConfigurationProfilesList(data))
      .then(data => this.getPlatformsList(data))
      .then(data => this.getDevelopersList(data))
      .then(data => this.onDataReceived(data));
  }

  getStatusList() {
    if (!this.mounted) return;
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/game_status/list';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.game_status_list = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }

        return json;
      })
      .then(json => {
        if (json.result) data.game_status_list = json.result;
        else data.game_status_list = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  getConfigurationProfilesList(data) {
    if (!this.mounted) return;
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/configuration_profiles/list';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    data.configuration_profiles_list = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }

        return json;
      })
      .then(json => {
        if (json.result) data.configuration_profiles_list = json.result;
        else data.configuration_profiles_list = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  getPlatformsList(data) {
    if (!this.mounted) return;
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/platforms/list';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    data.platforms_list = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }

        return json;
      })
      .then(json => {
        if (json.result) data.platforms_list = json.result;
        else data.plaforms_list = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  getDevelopersList(data) {
    if (!this.mounted) return;
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/developers/list';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    data.developers_list = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }

        return json;
      })
      .then(json => {
        if (json.result) data.developers_list = json.result;
        else data.developers_list = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  onDataReceived(data) {
    if (data.failed) {
      //if(receivedData.updatedToken==null)
      this.props.updatePrivCall(false);

      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }

    if (!this.mounted) return;

    this.setState({ isFetching: false }, () => {
      this.parseData(data);
    });
  }

  parseData(data) {
    let configuration_profiles_list = {};
    let games_status_list = {};
    let platforms_list = {};
    let developers_list = {};
    Object.entries(data.configuration_profiles_list).map(([key, val]) => {
      configuration_profiles_list[val.slug] = val.name;
    });

    Object.entries(data.game_status_list).map(([key, val]) => {
      games_status_list[val.slug] = val.name;
    });

    data.platforms_list.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;

      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    });

    Object.entries(data.platforms_list).map(([key, val]) => {
      platforms_list[val.slug] = {};
      platforms_list[val.slug]['name'] = val.name;
      platforms_list[val.slug]['checked'] = false;
      platforms_list[val.slug]['slug'] = val.slug;
    });

    Object.entries(data.developers_list).map(([key, val]) => {
      //developers_list[val.slug] = {};
      developers_list[val.slug] = val.name;
      //developers_list[val.slug]['value'] = false;
    });
    this.setState({
      statusList: games_status_list,
      configurationProfileList: configuration_profiles_list,
      platformsListToDisplay: platforms_list,
      platformsList: platforms_list,
      developersList: developers_list,
      isFetching: false
    });
  }

  fetchConfigData(name) {
    this.getConfigProfileView(name).then(data => this.configDataReceived(data));
    this.checkUpdate();
  }

  getConfigProfileView(name) {
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/configuration_profiles/configuration_profile/${name}/view`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.payload = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.payload = json.result;
        else data.payload = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  configDataReceived(data) {
    let dropdownList = [];
    let dataSourcesTable = [];
    let dataSouces = data.payload[0].data_sources;
    Object.entries(dataSouces).map(([key, val]) => {
      let dropdownListI = {};
      dropdownListI['key'] = val.slug;
      dropdownListI['value'] = val.name;
      dropdownList.push(dropdownListI);
      let dropdown_item = {};
      dropdown_item['slug'] = val.slug;
      dropdown_item['service'] = val.name;
      dropdown_item['isEdited'] = true;
      dropdown_item['params'] = [];
      let game_id = { name: 'Game ID', value: '', slug: 'game_id' };
      dropdown_item['params'].push(game_id);
      Object.entries(val.game_vars).map(([k, v]) => {
        let item = {};
        item['name'] = v.name;
        item['value'] = '';
        item['slug'] = v.slug;
        dropdown_item['params'].push(item);
      });
      dataSourcesTable.push(dropdown_item);
    });

    dropdownList.sort((a, b) => {
      if (a['value'].toLowerCase() < b['value'].toLowerCase()) return -1;

      if (a['value'].toLowerCase() > b['value'].toLowerCase()) return 1;

      return 0;
    });

    this.setState({ dataSourcesList: dropdownList, dataSourcesToAdd: dataSourcesTable });
  }
  //endregion

  addDeveloper() {
    if (!this.state.addDeveloperUpdateBtn) {
      return;
    }
    this.setState({ isFetching: true });
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/developers/add`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let payload = {
      data: [
        {
          name: this.state.addDeveloperName
        }
      ]
    };

    return fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status === 200) return json;
        else {
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        //json.developers[0].name json.developers[0].slug
        let list = { ...this.state.developersList };
        list[json.message.developers[0].slug] = json.message.developers[0].name;
        this.setState(
          {
            developersList: list,
            openAddDeveloperPopup: false,
            developerCurrent: json.message.developers[0].slug,
            developerPlaceholder: '',
            isFetching: false
          },
          () => {
            this.checkUpdate();
          }
        );
        /* this.props.history.push({
              pathname: '/gms/games'
            });*/
        return json;
      })
      .catch(error => {
        this.setState({ isFetching: false });
        console.error(error);
      });
  }

  setLaunchDate(date) {
    this.setState({
      launchDate: moment(date).format('DD-MM-YYYY'),
      currentDate: moment(date),
      calendarPlaceholder: false
    });
  }

  checkUpdate() {
    let update = false;
    let sourceUpdate = true;
    Object.entries(this.state.dataSourcesTable).map(([key, val]) => {
      Object.entries(this.state.dataSourcesTable).map(([k, v]) => {
        if (val.slug === v.slug && key !== k) {
          Object.entries(val.params).map(([sk, sv]) => {
            Object.entries(v.params).map(([pk, pv]) => {
              if (sv.slug === 'game_id' && pv.slug === 'game_id' && sv.value === pv.value) {
                sourceUpdate = false;
              }
            });
          });
        }
      });
    });

    if (
      this.state.gameName.length >= 1 &&
      this.state.gameName.length < 50 &&
      this.state.gameName !== '' &&
      this.state.configurationProfileCurrent !== '' &&
      sourceUpdate === true &&
      this.state.developerCurrent.length >= 1
    ) {
      update = true;
    }
    this.setState({ needSave: update });
  }

  selectStatus(event) {
    const target = event.target;
    this.setState({ statusCurrent: target.value });
  }

  selectConfigurationProfile(event) {
    const target = event.target;

    this.setState(
      {
        configurationProfilePlaceholder: '',
        configurationProfileCurrent: target.value,
        platformsListPlaceholder: '',
        dataSourceDropdownListPlaceholder: 'New data source',
        dataSourceDropdownListCurrent: '',
        dataSourcesTable: [],
        dataSourcesCopyTable: []
      },
      () => {
        this.fetchConfigData(target.value);
      }
    );
  }

  setGameName(event) {
    const target = event.target;

    this.setState(
      {
        gameName: target.value
      },
      () => {
        this.checkUpdate();
      }
    );
  }

  selectDataSource(event) {
    const target = event.target;
    this.setState({
      dataSourceDropdownListPlaceholder: '',
      dataSourceDropdownListCurrent: target.value
    });
  }

  checkPlatform(event) {
    const target = event.target;
    let platformList = { ...this.state.platformsList[target.name] };
    platformList['checked'] = target.checked;
    this.setState({
      platformsList: Object.assign({}, this.state.platformsList, {
        [target.name]: platformList
      })
    });
  }

  /** Data source management */
  //region Data Source management

  addSourceToTable() {
    let currentSource = this.state.dataSourceDropdownListCurrent;
    if (currentSource !== '') {
      let sourceTable = this.state.dataSourcesTable;
      let copySourceTable = this.state.dataSourcesCopyTable;
      Object.entries(this.state.dataSourcesToAdd).map(([key, val]) => {
        if (val.slug === currentSource) {
          let valCopy = { ...val };
          let valCopy2 = { ...val };
          let paramsCopy = [];
          let paramsCopy2 = [];
          for (let i = 0; i < val.params.length; i++) {
            let newObj = { ...val.params[i] };
            let newObj2 = { ...val.params[i] };
            paramsCopy.push(newObj);
            paramsCopy2.push(newObj2);
          }
          valCopy.params = paramsCopy;
          valCopy2.params = paramsCopy2;
          sourceTable.push(valCopy);
          copySourceTable.push(valCopy2);
        }
      });

      this.setState({ dataSourcesTable: sourceTable, dataSourcesCopyTable: copySourceTable });
    }
  }

  sourceCloseEditMode(sourceId) {
    let sourceTable = this.state.dataSourcesTable;
    sourceTable[sourceId].isEdited = false;
    this.setState({ dataSourcesTable: sourceTable });
  }

  sourceDeleteFromTable(sourceId) {
    let sourceTable = this.state.dataSourcesTable;
    let copySourceTable = this.state.dataSourcesCopyTable;
    sourceTable.splice(sourceId, 1);
    copySourceTable.splice(sourceId, 1);
    this.setState({ dataSourcesTable: sourceTable, dataSourcesCopyTable: copySourceTable }, () => {
      this.checkUpdate();
    });
  }

  sourceOpenCloseEditMode(sourceId) {
    let sourceTable = this.state.dataSourcesTable;
    let val = sourceTable[sourceId].isEdited;

    sourceTable[sourceId].isEdited = !val;
    this.setState({ dataSourcesTable: sourceTable });
  }

  cancelEditDataSource(sourceId) {
    let sourceTable = this.state.dataSourcesTable;
    let copySourceTable = this.state.dataSourcesCopyTable;
    let objectToCopy = JSON.parse(JSON.stringify(copySourceTable[sourceId]));
    objectToCopy.isEdited = false;

    sourceTable[sourceId] = objectToCopy;
    this.setState({ dataSourcesTable: sourceTable });
  }

  confirmEditDataSource(sourceId) {
    let sourceTable = this.state.dataSourcesTable;
    sourceTable[sourceId].isEdited = false;
    let copySourceTable = this.state.dataSourcesCopyTable;
    let objectToCopy = JSON.parse(JSON.stringify(sourceTable[sourceId]));
    copySourceTable[sourceId] = objectToCopy;

    this.setState({ dataSourcesTable: sourceTable, dataSourcesCopyTable: copySourceTable });
  }
  editSourceValues(event, sourceId, key) {
    const target = event.target;
    let sourceTable = this.state.dataSourcesTable;
    sourceTable[sourceId].params[key].value = target.value;
    this.setState({ dataSourcesTable: sourceTable }, () => {
      this.checkUpdate();
    });
  }

  //endregion

  handleAddGame() {
    if (!this.state.needSave) {
      return;
    } else {
      this.setState({ isFetching: true });
      let platformsToSend = [];
      Object.entries(this.state.platformsList).map(([key, val]) => {
        if (val.checked === true) {
          platformsToSend.push(key);
        }
      });

      if (this.state.configurationProfileCurrent == '') {
        return;
      }
      let external;
      if (this.state.isInternal) {
        external = false;
      } else {
        external = true;
      }
      let payload = {
        data: {
          name: this.state.gameName,
          status: this.state.statusCurrent,
          configuration_profile: this.state.configurationProfileCurrent,
          is_external: external,
          developer: this.state.developerCurrent
        }
      };
      if (this.state.launchDate != '') {
        payload.data.launch_date = this.state.launchDate;
      }

      if (this.state.gameIcon != null) {
        payload.data.icon = this.state.gameIcon;
      }
      if (platformsToSend.length > 0) {
        payload.data.platforms = platformsToSend;
      }

      let gameParams = [];
      Object.entries(this.state.dataSourcesTable).map(([key, val]) => {
        let game_item = {};
        game_item['slug'] = val.slug;
        Object.entries(val.params).map(([k, v]) => {
          game_item[v.slug] = v.value;
        });
        gameParams.push(game_item);
      });

      if (gameParams.length > 0) {
        payload.data.game_params = gameParams;
      }

      const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
      const PATH_CALL = '/games/add';
      const url = `${PATH_BASE}${PATH_CALL}`;
      let headers = {
        'Access-Control-Origin': '*',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: storage.USER.token
      };

      return fetch(url, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(payload)
      })
        .then(response => {
          let status = response.status;
          let token = response.headers.get('token');
          let json = response.json();
          if (token != null) storage.USER.token = token;
          if (status === 200) {
            this.props.getIcons(true);
            window.sessionStorage.clear();
            return json;
          } else {
            return;
          }
        })
        .then(json => {
          if (json === undefined) return;
          this.props.history.push({
            pathname: '/gms/games'
          });
          return json;
        })
        .catch(error => {
          this.setState({ isFetching: false });
          console.error(error);
        });
    }
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onload = () => {
      this.setState({
        //gameIcon: file,
        gameIcon: reader.result
      });
    };
    reader.readAsDataURL(file);
  }

  changeIsExternal(e) {
    if (this.state.gameExtInt === 'Internal') {
      this.setState({
        isInternal: false,
        gameExtInt: 'External'
      });
    } else {
      this.setState({
        isInternal: true,
        gameExtInt: 'Internal'
      });
    }
  }

  selectOnFocus() {
    let newHeight = 100 + 30 * this.state.dataSourcesList.length;
    this.setState({ lastElementHeight: newHeight + 'px' });
  }

  selectOnBlur() {
    this.setState({ lastElementHeight: '0px' });
  }

  selectOnChange() {
    this.setState({ lastElementHeight: '0px' });
  }

  render() {
    const icon = this.state.gameIcon ? this.state.gameIcon : game_icon;
    if (!this.mounted) return;
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }

    let dataSourceBlock;
    if (this.state.configurationProfileCurrent !== '') {
      dataSourceBlock = (
        <Fragment>
          <GameDataSource>
            <LabelForm htmlFor="addGamesDataSource">Data Source</LabelForm>

            {/* <DropdownList
              items={this.state.dataSourcesList}
              name="addGamesDataSourceList"
              id="addGamesDataSource"
              placeholder={this.state.dataSourceDropdownListPlaceholder}
              uppercase={false}
              handler={this.selectDataSource}
              onFocusHandler={() => {
                this.selectOnFocus();
              }}
              onBlurHandler={() => {
                this.selectOnBlur();
              }}
              onChangeHandler={() => {
                this.selectOnChange();
              }}
              currentOpt={this.state.dataSourceDropdownListCurrent}
              kvPairs={true}
            /> */}
            <DropdownList2
              items={this.state.dataSourcesList}
              name="addGamesDataSourceList"
              id="addGamesDataSource"
              placeholder={this.state.dataSourceDropdownListPlaceholder}
              handler={this.selectDataSource}
              onFocusHandler={() => {
                this.selectOnFocus();
              }}
              onBlurHandler={() => {
                this.selectOnBlur();
              }}
              onChangeHandler={() => {
                this.selectOnChange();
              }}
              currentOption={this.state.dataSourceDropdownListCurrent}
            />
          </GameDataSource>
          <AddSource>
            <AddSourceImg src={add_btn} alt="add source" onClick={this.addSourceToTable} />
          </AddSource>
        </Fragment>
      );
    } else {
      dataSourceBlock = <Fragment />;
    }

    let calendarBlock;
    if (this.state.statusCurrent == 'live-games') {
      calendarBlock = (
        <GameCalendar>
          <LabelForm htmlFor="gamesCalendar">Launch Date</LabelForm>
          <Calendar
            date={this.state.currentDate}
            type="oneDate"
            changeDate={this.setLaunchDate}
            name="addGamesCalendar"
            toRangeDate={moment().startOf('day')}
            placeholderVal={this.state.calendarPlaceholder}
          />
        </GameCalendar>
      );
    } else {
      calendarBlock = <div />;
    }

    return (
      <Fragment>
        <ViewTile>{'Add Game'}</ViewTile>
        {this.state.openAddDeveloperPopup ? (
          <div>
            <BackgroundPopup />
            <PopupAddDeveloper
              closeBtn={() => this.setState({ openAddDeveloperPopup: false })}
              onChange={event => {
                const target = event.target;
                let needUpdate = false;
                if (target.value.length > 0) {
                  needUpdate = true;
                }

                this.setState({
                  addDeveloperUpdateBtn: needUpdate,
                  addDeveloperName: target.value
                });
              }}
              yesBtn={this.addDeveloper}
              slug={this.state.gameSlug}
              itemType={'game'}
              needUpdate={this.state.addDeveloperUpdateBtn}
            />
          </div>
        ) : null}
        <AddGameContainer>
          <IconContainer>
            <GameIcon src={icon} />

            <ImgEditLabel htmlFor={'image-input'}>
              <img src={edit_big} />
            </ImgEditLabel>
            <EditImageInput
              id={'image-input'}
              ref={this.imageInputRef}
              type="file"
              onChange={this.handleImageChange}
            />
          </IconContainer>
          <OptionContainer>
            <GameName>
              <Textfield
                labelText="Name"
                id="addGameName"
                type="text"
                placeholder="Game Name"
                onChange={this.setGameName}
              />
            </GameName>
            <Fragment>
              <GameDeveloper>
                <LabelForm htmlFor="addDeveloper">Developer</LabelForm>
                {/* <DropdownList
                  items={this.state.developersList}
                  name="addDeveloperList"
                  id="addDeveloper"
                  placeholder={this.state.developerPlaceholder}
                  uppercase={false}
                  handler={event => {
                    const target = event.target;

                    this.setState(
                      { developerPlaceholder: '', developerCurrent: target.value },
                      () => {
                        this.checkUpdate();
                      }
                    );
                  }}
                  currentOpt={this.state.developerCurrent}
                  compareItemsKey={true}
                /> */}
                <DropdownList2
                  items={this.state.developersList}
                  name="addDeveloperList"
                  id="addDeveloper"
                  placeholder={this.state.developerPlaceholder}
                  handler={event => {
                    const target = event.target;

                    this.setState(
                      { developerPlaceholder: '', developerCurrent: target.value },
                      () => {
                        this.checkUpdate();
                      }
                    );
                  }}
                  currentOption={this.state.developerCurrent}
                />
              </GameDeveloper>
              <AddDeveloper>
                <AddSourceImg
                  src={add_btn}
                  alt="add source"
                  onClick={() => this.setState({ openAddDeveloperPopup: true })}
                />
              </AddDeveloper>
            </Fragment>
            <SliderContainer>
              <SwitchButton
                items={['Internal', 'External']}
                isInternal={this.state.isInternal}
                handleChange={this.changeIsExternal}
                labelText={this.state.gameExtInt}
              />
            </SliderContainer>
            <GameStatus>
              <LabelForm htmlFor="addGameStatus">Status</LabelForm>
              {/* <DropdownList
                items={this.state.statusList}
                name="addGameStatusList"
                id="addGameStatus"
                currentOpt={this.state.statusCurrent}
                uppercase={false}
                handler={this.selectStatus}
                compareItemsKey={true}
              /> */}
              <DropdownList2
                items={this.state.statusList}
                name="addGameStatusList"
                id="addGameStatus"
                currentOption={this.state.statusCurrent}
                handler={this.selectStatus}
              />
            </GameStatus>
            <GameConfProfile>
              <LabelForm htmlFor="addGamesConfProf">Configuration Profile</LabelForm>
              {/* <DropdownList
                items={this.state.configurationProfileList}
                name="addGamesConfProfList"
                id="addGamesConfProf"
                placeholder={this.state.configurationProfilePlaceholder}
                uppercase={false}
                currentOpt={this.state.configurationProfileCurrent}
                handler={this.selectConfigurationProfile}
                compareItemsKey={true}
              /> */}
              <DropdownList2
                items={this.state.configurationProfileList}
                name="addGamesConfProfList"
                id="addGamesConfProf"
                placeholder={this.state.configurationProfilePlaceholder}
                currentOption={this.state.configurationProfileCurrent}
                handler={this.selectConfigurationProfile}
              />
            </GameConfProfile>
            <GamePlatforms>
              <LabelForm htmlFor="addGamesPlatforms">Platforms</LabelForm>
              <CheckboxList
                items={this.state.platformsList}
                id="addGamesPlatformsList"
                placeholder={this.state.platformsListPlaceholder}
                withProperties={true}
                handleItems={this.checkPlatform}
                checkboxWidth={225}
              />
            </GamePlatforms>

            {dataSourceBlock}
            {calendarBlock}
            <GameSelectedSources>
              {this.state.dataSourcesTable ? (
                <FilterList
                  items={this.state.dataSourcesTable}
                  openCloseEdit={this.sourceOpenCloseEditMode}
                  deleteSource={this.sourceDeleteFromTable}
                  editText={this.editSourceValues}
                  confirmEdit={this.confirmEditDataSource}
                  cancelEdit={this.cancelEditDataSource}
                />
              ) : null}
            </GameSelectedSources>

            <ButtonContainer>
              <Button needUpdate={this.state.needSave} onClick={this.handleAddGame}>
                Add Game
              </Button>
            </ButtonContainer>
            <EmptyDiv lastHeight={this.state.lastElementHeight} />
          </OptionContainer>
        </AddGameContainer>
      </Fragment>
    );
  }
}

export default GmsAddGame;

//region Styles
const ViewTile = styled.h1`
  width: 95%;
  color: #fff;
  padding: 0rem 0rem 1%;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 4rem;
  margin-right: 3%;
  margin-left: 2%;
  margin-bottom: 1rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;

const LabelForm = styled.label`
  font-size: 12px;
  color: #99999c;
  display: block;
  margin-bottom: 5px;
`;

const AddGameContainer = styled.div`
  display: grid;
  margin-top: 50px;
  grid-template-columns: 13% 20rem 55rem;
  grid-row-gap: 5rem;
`;

const IconContainer = styled.div`
  grid-row: 1;
  grid-column: 2/3;
  position: relative;
`;

const GameIcon = styled.img`
  border-radius: 30px;
  height: 130px;
  width: 130px;
`;

const OptionContainer = styled.div`
  grid-row: 1;
  grid-column: 3/4;
  display: grid;
  grid-template-columns: 25rem 25rem 15rem;
  grid-row-gap: 20px;
  grid-column-gap: 10px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  margin-bottom: 50px;
`;

const GameCalendar = styled.div`
  grid-row: 3;
`;

const GameName = styled.div`
  grid-row: 1;
`;

const SliderContainer = styled.div`
  grid-row: 2;
  grid-column: 2/3;
  display: flex;
  margin-left: 4rem;
  margin-top: 17px;
`;

const GameStatus = styled(GameName)`
  grid-row: 3;
`;

const GameConfProfile = styled(GameName)`
  grid-row: 4;
`;

const GamePlatforms = styled(GameName)`
  grid-row: 5;
`;

const GameDataSource = styled(GameName)`
  grid-row: 6;
`;

const GameDeveloper = styled(GameName)`
  grid-row: 2;
  grid-column: 1/2;
`;

const AddDeveloper = styled.div`
  margin-top: 24px;
  grid-row: 2;
  grid-column: 2/3;
`;

const AddSource = styled.div`
  margin-top: 24px;
  grid-row: 6;
  grid-column: 2/3;
`;

const AddSourceImg = styled.img`
  cursor: pointer;
`;

const GameSelectedSources = styled(GameName)`
  grid-row: 8;
  grid-column: 1/55;
`;

const ButtonContainer = styled.div`
  grid-row: 9;
  grid-column: 1/2;
  margin-top: 20px;
  height: 32px;
`;

const EmptyDiv = styled.div`
  grid-row: 10;
  grid-column: 1/2;
  height: ${props => (props.lastHeight ? props.lastHeight : '90px')};
  display: block;
`;
const ImgEditLabel = styled.label`
  cursor: pointer;
`;

const EditImageInput = styled.input`
  display: none;
`;

const BackgroundPopup = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
  background-color: #282b30;
  opacity: 0.7;
  z-index: 100;
`;
//endregion
