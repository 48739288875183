import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import ChartPanel from './ChartPanel';
import CustomDatePicker from 'components/CustomDatePicker';
import { backgroundColorTable, borderColorTable } from 'components/ColorPicker/index';
import moment from 'moment/moment';
class PlayIgniteCharts extends Component {
  constructor(props) {
    super(props);
    this.parseData = this.parseData.bind(this);
  }

  shouldComponentUpdate() {
    return true;
  }

  parseData(data) {
    let labels = [];
    let ignition_capital = [];
    let playcapital = [];
    let playcredits = [];
    let total = [];
    data.map(item => {
      labels.push(item.date);
      ignition_capital.push(item.ignition_capital);
      playcapital.push(item.playcapital);
      playcredits.push(item.playcredits);
      total.push(item.total);
    });

    return {
      labels: labels,
      datasets: [
        {
          label: 'Ignition Capital',
          fill: false,
          hidden: false,
          backgroundColor: backgroundColorTable[0],
          borderWidth: 2,
          borderColor: borderColorTable[0],
          pointHitDetectionRadius: 1,
          data: ignition_capital
        },
        {
          label: 'PlayCapital',
          fill: false,
          hidden: false,
          backgroundColor: backgroundColorTable[1],
          borderWidth: 2,
          borderColor: borderColorTable[1],
          pointHitDetectionRadius: 1,
          data: playcapital
        },
        {
          label: 'PlayCredits',
          fill: false,
          hidden: false,
          backgroundColor: backgroundColorTable[2],
          borderWidth: 2,
          borderColor: borderColorTable[2],
          pointHitDetectionRadius: 1,
          data: playcredits
        },
        {
          label: 'Total',
          fill: false,
          hidden: false,
          backgroundColor: backgroundColorTable[3],
          borderWidth: 2,
          borderColor: borderColorTable[3],
          pointHitDetectionRadius: 1,
          data: total
        }
      ]
    };
  }

  render() {
    return (
      <ChartsContainer>
        <Title>Charts</Title>
        <DatePickerContainer>
          <CustomDatePicker
            active={true}
            from={this.props.from}
            to={this.props.to}
            handleUpdate={this.props.handleUpdate}
            name="playigniteCalendar"
            cWidth={true}
            backgroundColor={'#141617'}
            toRangeDate={moment().startOf('day')}
            notSubtract={true}
          />
        </DatePickerContainer>
        <ChartGrid>
          <ChartPanel
            data={this.props.data.total_loan_chart_data.chart_data}
            chartTitle={this.props.title1}
            frontSymbol={'$'}
            parseData={this.parseData}
          />

          <ChartPanel
            data={this.props.data.total_fees_chart_data.chart_data}
            chartTitle={this.props.title2}
            frontSymbol={'$'}
            parseData={this.parseData}
          />
        </ChartGrid>
      </ChartsContainer>
    );
  }
}

export default PlayIgniteCharts;

const DatePickerContainer = styled.div`
  width: 332px;
  margin-bottom: 20px;
`;

const ChartGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
`;

const Title = styled.div`
  color: #fefefe;
  font-size: 1.8rem;
  line-height: 3.6rem;
  //font-weight: 600;
  padding-left: 8px;
  border-bottom: 1px solid #606064;

  margin-bottom: 10px;
  box-sizing: border-box;
`;

const ChartsContainer = styled.div`
  height: 510px;
  background: #1e2022;
  width: 100%;
  border: 0.5px solid #36393f;
  padding: 10px 20px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
`;
