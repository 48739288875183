import React, { Component, Fragment } from 'react';
import warning_icon from './assets/warning_icon.png';
import RememberPassword from '../RememberPassword';
import './style.css';
import LoadingIndicator from 'components/LoadingIndicator';
import styled from 'styled-components';
import CommonButton from 'components/CommonButton';
class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      success_logged: false,
      blank_inputs: false,
      invalid_user: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputUpdate = this.handleInputUpdate.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.openClosePopup = this.openClosePopup.bind(this);
  }

  componentDidMount() {
    this.setState({});
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.success_logged !== nextProps.logout_info) {
      return {
        success_logged: nextProps.logout_info
      };
    }
    if (prevState.invalid_user !== nextProps.invalid_user) {
      return {
        invalid_user: nextProps.invalid_user
      };
    }
    return null;
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email, password, remember } = e.target;
    if (email.value === '' || password.value === '') {
      this.setState({ blank_inputs: true, invalid_user: false, success_logged: false });
      return;
    } else {
      this.setState({ blank_inputs: false });
    }
    this.props.handleLogin(email.value, password.value, remember.checked, e.target.elements);
    // this.props.submit(email.value, password.value, remember.checked);
  }

  handleInputUpdate(id, value, error) {
    if (id.target.value !== '') {
      this.setState({ blank_inputs: false });
    }
    //this.props.updateField(id, value, error);
  }

  handleInputBlur(id) {
    //this.props.blurField(id);
  }

  openClosePopup() {
    let show = this.state.showPopup;
    this.setState({ showPopup: !show });
  }

  render() {
    const { wasSubmited, submitting, error, email, password, remember } = this.props;
    return (
      <Fragment>
        {this.state.showPopup ? <RememberPassword show={this.openClosePopup} /> : null}

        {this.state.success_logged ? (
          <LoggedOutInfo>
            <span id="logout">You have successfully logged out!</span>
          </LoggedOutInfo>
        ) : null}
        {this.state.invalid_user ? (
          <WarningInfo>
            <img src={warning_icon} /> Invalid Username or Password
          </WarningInfo>
        ) : null}
        {this.state.blank_inputs ? (
          <WarningInfo>
            <img src={warning_icon} /> Please fill both the Username and Password fields.
          </WarningInfo>
        ) : null}

        <form onSubmit={this.handleSubmit} className="loginForm">
          <input
            id="email"
            name="email"
            label="Email address"
            type="email"
            placeholder="Email address"
            // value={email.get('value')}
            onChange={this.handleInputUpdate}
            //onBlur={this.handleInputBlur}
            //wasSubmited={wasSubmited}
          />
          <input
            id="password"
            name="password"
            label="Password"
            type="password"
            placeholder="Password"
            // value={password.get('value')}
            onChange={this.handleInputUpdate}
            // onBlur={this.handleInputBlur}
            // wasSubmited={wasSubmited}
          />
          <ForgotPass onClick={this.openClosePopup}>Forgot password?</ForgotPass>
          <div className="toogleContainer">
            <input type="checkbox" id="remember" value />
            <label htmlFor="remember" className="toogleLabel" />
            <div className="toogleLabel">Remember me</div>
          </div>
          <ButtonContainer>
            <CommonButton type={'submit'} label="login" disabled={submitting} text={'LOGIN'} />
          </ButtonContainer>
          {submitting && <LoadingIndicator />}
        </form>
      </Fragment>
    );
  }
}

export default LoginForm;
//region Styles
const LoggedOutInfo = styled.div`
  color: #04d5ac;
  font-size: 12px;
  letter-spacing: 0.1rem;
`;

const ButtonContainer = styled.div`
  height: 32px;
`;

const ForgotPass = styled.div`
  font-size: 11px;
  font-family: 'Roboto', sans-serif;
  color: #5b616e;
  letter-spacing: 0.15em;
  width: 100%;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: #00d5a9;
  }
`;

const WarningInfo = styled.div`
  visibility: visible;
  position: relative;
  color: #f29c10;
  font-size: 12px;
  letter-spacing: 0.1rem;
`;
//endregion
