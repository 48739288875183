import React, { Component, Fragment } from 'react';
import AnimatedView from 'components/AnimatedView';
import './style.css';

class LoadingIndicator extends Component {
  constructor() {
    super(...arguments);
  }

  componentWillMount() {}

  componentDidMount() {
    //setTimeout(() =>chatPageAction.addMesssage("Pawel "),1000);
  }

  componentWilUnmount() {}

  onChange(state) {}

  render() {
    return (
      <Fragment key={'loadingIndicatorKey'}>
        <div key="clickerKey" className="clicker">
          <div key="clickerKey2" className="loaderContainer">
            <div key="clickerKey3" className="loader" />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default LoadingIndicator;
