import React, { Component, Fragment } from 'react';
import AdminMain from './AdminMain';
import TitleBar from 'components/TitleBar';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import AdminGroups from './AdminGroups';
import AdminGamesGroups from './AdminGamesGroups';
import ManageUser from './ManageUser';
import MenuBar from 'components/MenuBar';
import { AppContext } from 'views/App';
import styled from 'styled-components';
import { Content } from 'views/App';

/**Admin Routes*/
//region  Routes
const routes = [
  {
    path: '/admin',
    menubar: MenuBar,
    exact: true,
    view: AdminMain,
    color: '#00d5a9',
    menuConfig: ['back']
  },
  {
    path: '/admin/groups',
    menubar: MenuBar,
    exact: true,
    view: AdminGroups,
    title: 'Admin Panel',
    color: '#00d5a9',
    menuConfig: ['back']
  },
  {
    path: '/admin/games/groups',
    menubar: MenuBar,
    exact: true,
    view: AdminGamesGroups,
    color: '#00d5a9',
    menuConfig: ['back']
  },
  {
    path: '/admin/user/:mode?/:id?',
    menubar: MenuBar,
    exact: true,
    view: ManageUser,
    color: '#00d5a9',
    menuConfig: ['back']
  }
];
//endregion

export const AdminContext = React.createContext();

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: AdminMain,
      waiting: false,
      waiting2: false,
      waiting3: false,
      getGamesIcons: () => {},
      logout: () => {},
      returnToLogin: () => {},
      getUserPriviliges: () => {},
      getGamesIcons: () => {},
      getPlatformsIcons: () => {}
    };
  }

  /**Admin Lifecycle Methods*/
  //region  Admin Lifecycle Methods

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidMount() {
    /* this.setState({
      getGamesIcons: this.getGamesIcons,
      logout: this.returnToLogin,
      returnToLogin: this.returnToLogin,
      getUserPriviliges: this.getUserPriviliges,
      getGamesIcons: this.getGamesIcons,
      getPlatformsIcons: this.getPlatformsIcons
    });*/
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this.mounted = false;
  }
  //endregion

  /**Admin Api Methods*/
  //region  Admin Api Methods
  getGroupsData(url, data) {}

  getGamesGroupsData(url, data) {}

  getPriviligesData(url, data) {}

  onDataReceived(data) {}
  //endregion

  /**Admin Render*/
  //region  Admin Api Methods
  render() {
    return (
      <AdminContext.Provider value={this.state}>
        <AppContext.Consumer>
          {({ getUserPriviliges, privilegesUpdated, getGamesIcons, logout, user }) => (
            <Fragment>
              <TitleBar
                title={this.props.title}
                color={this.props.color}
                history={this.props.history}
                name={user.first_name}
                logout={logout}
              />
              <Content>
                <Switch>
                  {routes.map((route, index) => (
                    <Route
                      key={`${route.pathindex}-${index}`}
                      path={route.path}
                      exact={route.exact}
                      render={props => (
                        <route.view
                          updatePrivCall={getUserPriviliges}
                          privilegesUpdated={privilegesUpdated}
                          {...props}
                        />
                      )}
                    />
                  ))}
                  <Route render={() => <Redirect to="/" />} />
                </Switch>
              </Content>
            </Fragment>
          )}
        </AppContext.Consumer>
      </AdminContext.Provider>
    );
  }
  //endregion
}
export default Admin;
/**Admin Styles*/
//region  Styles

//endregion
