import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import GamesTable from './GamesTable';
import storage from 'utils/storage';
import moment from 'moment';
import LoadingIndicator from 'components/LoadingIndicator';
import FinancierContact from '../PlayIgniteDetails/FinancierContact';
import DataPanel from 'components/DataPanel';
import PlayIgniteCharts from '../PlayIgniteCharts';
import ColorfulDataBoxes from '../ColorfulDataBoxes';
class PlayIgniteMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      ignitionStatusData: null,
      mounted: false,
      isFetching: false,
      globalData: null
    };

    this.fetchData = this.fetchData.bind(this);
    this.onDataReceived = this.onDataReceived.bind(this);
    this.fetchDeveloperData = this.fetchDeveloperData.bind(this);
    this.onDeveloperDataReceived = this.onDeveloperDataReceived.bind(this);
    this.getUpdatedChartsData = this.getUpdatedChartsData.bind(this);
    this.getLoansData = this.getLoansData.bind(this);
    this.handlerMailTo = this.handlerMailTo.bind(this);
  }
  handlerMailTo() {
    window.location.href = `mailto:${this.state.data.financier_contact.financier_contact_email}`;
  }

  componentDidMount() {
    if (storage.USER.privileges.includes('access_playignite_admin_section')) {
      this.fetchData()
        .then(data => this.getIgnitionStatusData(data))
        .then(data => this.getLoansData(data))
        .then(finalData => this.onDataReceived(finalData));
    } else if (storage.USER.privileges.includes('access_playignite_client_section')) {
      this.fetchDeveloperData()
        .then(data => this.getIgnitionStatusData(data))
        .then(data => this.getLoansData(data))
        .then(finalData => this.onDeveloperDataReceived(finalData));
    }
    this.setState({ isFetching: true, mounted: true });
  }

  fetchData() {
    const PATH_BASE = storage.PLAYIGNITE_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';
    const PATH_CALL = '/games?data=all';
    const url = `${PATH_BASE}${PATH_CALL}`;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.gamesData = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }

        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.gamesData = json.result;
        else data.gamesData = json;
        return data;
      })
      .catch(error => {
        data.failed = true;
        return data;
      });
  }

  getIgnitionStatusData(data) {
    const PATH_BASE = storage.ACQ_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';
    const PATH_CALL = '/analytics/ignition_status';
    const url = `${PATH_BASE}${PATH_CALL}`;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    data.updatedToken = false;
    data.failed = false;
    data.ignitionStatusData = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }

        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.ignitionStatusData = json.result;
        else data.ignitionStatusData = json;
        return data;
      })
      .catch(error => {
        data.failed = true;
        return data;
      });
  }

  getLoansData(data) {
    let user;
    if (storage.USER.privileges.includes('access_playignite_admin_section')) {
      user = 'admin';
    } else if (storage.USER.privileges.includes('access_playignite_client_section')) {
      user = 'client';
    }
    const PATH_BASE = storage.PLAYIGNITE_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';
    const PATH_CALL = `/loans?user=${user}`;
    const url = `${PATH_BASE}${PATH_CALL}`;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }

        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.loansData = json.result;
        else data.loansData = json;
        return data;
      })
      .catch(error => {
        data.failed = true;
        return data;
      });
  }

  onDataReceived(data) {
    if (data.failed) {
      this.setState({ isFetching: false, isFailed: true });
      this.props.updatePrivCall(false);
      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }
    if (!this.state.mounted) return;
    this.setState(
      {
        data: data.gamesData,
        ignitionStatusData: data.ignitionStatusData,
        loansData: data.loansData
      },
      () => {
        this.parseData(this.state.data, data.ignitionStatusData, 'admin_metrics', data.loansData);
      }
    );
  }

  parseData(data, ignitionStatusData, mode, loansData) {
    let newData = [...data];
    Object.entries(newData).map(([key, val]) => {
      Object.entries(storage.GAMES_ICONS).map(([k, v]) => {
        if (val.slug === k) {
          newData[key].icon = v;
        }
      });
    });

    let tableData = [];
    Object.entries(newData).map(([key, value]) => {
      tableData[key] = {};
      tableData[key] = {
        name: value.name,
        slug: value.slug,
        icon: value.icon,
        developer: value.developer
      }; //game details
      tableData[key].admin_metrics = {};
      Object.entries(value.admin_metrics).map(([k, v]) => {
        tableData[key].admin_metrics[k] = {
          active: v.active,
          display_order: v.display_order,
          name: v.name,
          slug: v.slug
        }; //product name
        tableData[key].admin_metrics[k]['data'] = {};
        if (v.slug === 'ignition_capital') {
          tableData[key].admin_metrics[k]['data'][5] = {
            name: 'Ignition Status',
            slug: 'ignition_status',
            value: ignitionStatusData[value.slug].roi
          };
          tableData[key].admin_metrics[k]['data'][6] = {
            name: 'LTV 60',
            slug: 'ltv_60',
            value: ignitionStatusData[value.slug].ltv
          };
          tableData[key].admin_metrics[k]['data'][7] = {
            name: 'CPI',
            slug: 'cpi',
            value: ignitionStatusData[value.slug].cpi
          };
        } else {
          tableData[key].admin_metrics[k]['data'][5] = {
            name: 'Ignition Status',
            slug: 'ignition_status',
            value: 'n/a'
          };
          tableData[key].admin_metrics[k]['data'][6] = {
            name: 'LTV 60',
            slug: 'ltv_60',
            value: 'n/a'
          };
          tableData[key].admin_metrics[k]['data'][7] = { name: 'CPI', slug: 'cpi', value: 'n/a' };
        }

        tableData[key].admin_metrics[k]['data'][4] = {
          name: 'Onboarding Progress',
          slug: 'onboarding_progress',
          value: v.onboarding_progress
        };
        Object.entries(v.data).map(([dk, dv]) => {
          switch (dk) {
            case 'loan_total':
              tableData[key].admin_metrics[k]['data'][1] = {
                name: 'Loan Outstanding',
                slug: dk,
                value: dv
              };
              break;
            case 'loan_fee':
              tableData[key].admin_metrics[k]['data'][0] = { name: 'Fee', slug: dk, value: dv };
              break;
            case 'loan_fees_accrued':
              tableData[key].admin_metrics[k]['data'][2] = {
                name: 'Fees Generated',
                slug: dk,
                value: dv
              };
              break;
            case 'loan_facility_available':
              tableData[key].admin_metrics[k]['data'][3] = {
                name: 'Facility Available',
                slug: dk,
                value: dv
              };
              break;

            case 'loan_agreement':
              tableData[key].admin_metrics[k]['data'][8] = {
                name: 'Loan Agreement',
                slug: dk,
                value: dv
              };
              break;

            case 'debenture':
              tableData[key].admin_metrics[k]['data'][9] = {
                name: 'Debenture',
                slug: dk,
                value: dv
              };
              break;
            case 'indemnity':
              tableData[key].admin_metrics[k]['data'][10] = {
                name: 'Indemnity',
                slug: dk,
                value: dv
              };
              break;
            case 'kyc_aml_status':
              tableData[key].admin_metrics[k]['data'][11] = {
                name: 'KYC/AML Status',
                slug: dk,
                value: dv
              };
              break;
            case 'bank_details_switched':
              tableData[key].admin_metrics[k]['data'][12] = {
                name: 'Bank Details Switched',
                slug: dk,
                value: dv
              };
              break;
            case 'store_admin_access_granted':
              tableData[key].admin_metrics[k]['data'][13] = {
                name: 'Store Admin Granted',
                slug: dk,
                value: dv
              };
              break;
            case 'loan_next_date':
              tableData[key].admin_metrics[k]['data'][14] = {
                name: 'Next Loan Date',
                slug: dk,
                value: dv
              };
              break;
            case 'loan_estimated_amount':
              tableData[key].admin_metrics[k]['data'][15] = {
                name: 'Estimated Loan Amount',
                slug: dk,
                value: dv
              };
              break;
          }
        });
      });
    });

    let adminLoansSummary = loansData.loan_admin_summary;
    let loansSummaryAdminData = [
      {
        name: 'Total Loans',
        value: adminLoansSummary.loan_total,
        color: '#db46b9',
        type: 'currency'
      },
      { name: 'Average Fee', value: adminLoansSummary.loan_fee, color: '#00d5a9', type: 'percent' },
      {
        name: 'Fees Month To Date',
        value: adminLoansSummary.loan_month_to_date,
        color: '#5ca8c9',
        type: 'currency'
      },
      {
        name: 'Fees Year To Date',
        value: adminLoansSummary.loan_year_to_date,
        type: 'currency',
        color: '#dbd300'
      }
    ];

    let charts_data = {
      charts_start_date: loansData.charts_start_date,
      charts_end_date: loansData.charts_end_date,
      loan_chart_data: loansData.loan_chart_data
    };

    this.setState({
      data: newData,
      tableData: tableData,
      loanSummary: loansSummaryAdminData,
      chartsData: charts_data,
      isFetching: false
    });
  }

  fetchDeveloperData() {
    const PATH_BASE = storage.PLAYIGNITE_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';
    const PATH_CALL = '/developers';
    const url = `${PATH_BASE}${PATH_CALL}`;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.gamesData = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }

        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.developerData = json.result;
        else data.developerData = json;
        return data;
      })
      .catch(error => {
        data.failed = true;
        return data;
      });
  }

  onDeveloperDataReceived(data) {
    if (data.failed) {
      this.setState({ isFetching: false, isFailed: true });
      this.props.updatePrivCall(false);

      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }
    if (!this.state.mounted) return;
    this.setState(
      {
        data: data.developerData,
        ignitionStatusData: data.ignitionStatusData,
        loansData: data.loansData
      },
      () => {
        this.parseDeveloperData(this.state.data, data.ignitionStatusData, data.loansData);
      }
    );
  }

  parseDeveloperData(data, ignitionStatusData, loansData) {
    let tableData = {};
    Object.entries(data.products_data).map(([key, value]) => {
      tableData[key] = {};
      tableData[key].name = value.name;
      tableData[key].slug = value.slug;
      tableData[key].is_game_specific = value.is_game_specific;

      let mode = null;
      if (value.is_game_specific) {
        mode = 'games_data';
      } else {
        mode = 'misc_data';
      }

      tableData[key].data_per_game1 = [];
      tableData[key].data_per_game2 = [];
      if (value.is_game_specific) {
        Object.entries(value.games_data).map(([k, v]) => {
          let data = {};
          let data2 = {};
          Object.entries(v).map(([gk, gv]) => {
            switch (gk) {
              case 'slug':
                data[0] = { name: v.name, slug: gv, value: v.name, active: v.active };
                Object.entries(storage.GAMES_ICONS).map(([ik, iv]) => {
                  if (gv === ik) {
                    data[0].icon = iv;
                  }
                });

                break;
              case 'loan_fee':
                data[1] = { name: 'Fee', slug: gk, value: gv };
                break;
              case 'loan_total':
                data[2] = { name: 'Loan Outstanding', slug: gk, value: gv };
                break;
              case 'loan_fees_accrued':
                data[3] = { name: 'Fees Paid', slug: gk, value: gv };
                break;
              case 'loan_facility_available':
                data[4] = { name: 'Facility Available', slug: gk, value: gv };
                break;
              case 'onboarding_progress':
                data[5] = { name: 'Onboarding Progress', slug: gk, value: gv };
                break;
              case 'loan_next_date':
                data2[0] = { name: 'Next Loan Date', slug: gk, value: gv };
                break;
              case 'loan_estimated_amount':
                data2[1] = { name: 'Estimated Loan Amount', slug: gk, value: gv };
                break;
            }
          });

          data[6] = {
            name: 'Ignition Status',
            slug: 'ignition_status',
            value: ignitionStatusData[v.slug].roi
          };
          data[7] = { name: 'LTV 60', slug: 'ltv_60', value: ignitionStatusData[v.slug].ltv };
          data[8] = { name: 'CPI', slug: 'cpi', value: ignitionStatusData[v.slug].cpi };

          tableData[key].data_per_game1.push(data);
          tableData[key].data_per_game2.push(data2);
        });
      } else {
        let data = [];
        let data2 = [];
        Object.entries(value.misc_data).map(([gk, gv]) => {
          switch (gk) {
            case 'loan_fee':
              data[1] = { name: 'Fee', slug: gk, value: gv };
              break;
            case 'loan_total':
              data[2] = { name: 'Loan Outstanding', slug: gk, value: gv };
              break;
            case 'loan_fees_accrued':
              data[3] = { name: 'Fees Paid', slug: gk, value: gv };
              break;
            case 'loan_facility_available':
              data[4] = { name: 'Facility Available', slug: gk, value: gv };
              break;
            case 'onboarding_progress':
              data[5] = { name: 'Onboarding Progress', slug: gk, value: gv };
              break;
            case 'loan_next_date':
              data2[0] = { name: 'Next Loan Date', slug: gk, value: gv };
              break;
            case 'loan_estimated_amount':
              data2[1] = { name: 'Estimated Loan Amount', slug: gk, value: gv };
              break;

            case 'label':
              data[0] = { name: gv, slug: gv, value: gv, active: value.misc_data.active };
              break;
          }
        });

        tableData[key].data_per_game1.push(data);
        tableData[key].data_per_game2.push(data2);
      }
      tableData[key].element_values_data1 = [];
      tableData[key].element_values_data2 = [];
      Object.entries(value.element_values_data).map(([ek, ev]) => {
        switch (ek) {
          case 'loan_agreement':
            tableData[key].element_values_data1[0] = {
              name: 'Loan Agreement',
              slug: ek,
              value: ev
            };
            break;
          case 'debenture':
            tableData[key].element_values_data1[1] = { name: 'Debenture', slug: ek, value: ev };
            break;
          case 'bank_details_switched':
            tableData[key].element_values_data2[0] = {
              name: 'Bank Details Switched',
              slug: ek,
              value: ev
            };
            break;
          case 'store_admin_access_granted':
            tableData[key].element_values_data2[1] = {
              name: 'Store Admin Granted',
              slug: ek,
              value: ev
            };
            break;
        }
      });
    });

    let globalData = [];
    Object.entries(data.common_data).map(([key, value]) => {
      switch (key) {
        case 'indemnity':
          globalData[0] = { name: 'Indemnity', slug: key, value: value };
          break;
        case 'kyc_aml_status':
          globalData[1] = { name: 'KYC/AML Status', slug: key, value: value };
          break;
      }
    });
    let loanClientSummary = loansData.loan_client_summary;
    let loanSummaryClientData = [
      {
        name: 'Total Loan Amount',
        value: loanClientSummary.loan_total,
        color: '#db46b9',
        type: 'currency'
      },
      {
        name: 'Loan Facility Size',
        value: loanClientSummary.loan_facility_available,
        color: '#00d5a9',
        type: 'currency'
      },
      {
        name: 'Next Advance',
        value: loanClientSummary.loan_next_date,
        color: '#5ca8c9',
        type: 'date'
      },
      { name: 'Average Fee', value: loanClientSummary.loan_fee, type: 'percent', color: '#dbd300' },
      {
        name: 'Accrued interest fees',
        value: loanClientSummary.loan_fees_accrued,
        type: 'currency',
        color: '#f37927'
      }
    ];

    let charts_data = {
      charts_start_date: loansData.charts_start_date,
      charts_end_date: loansData.charts_end_date,
      loan_chart_data: loansData.loan_chart_data
    };

    this.setState({
      tableData: tableData,
      globalData: globalData,
      loanSummary: loanSummaryClientData,
      chartsData: charts_data,
      isFetching: false
    });
  }

  getUpdatedChartsData(dateFrom, dateTo, needUpdate) {
    if (!needUpdate) return;
    let from = moment(dateFrom).format('DD-MM-YYYY');
    let to = moment(dateTo).format('DD-MM-YYYY');
    let user;
    if (storage.USER.privileges.includes('access_playignite_admin_section')) {
      user = 'admin';
    } else if (storage.USER.privileges.includes('access_playignite_client_section')) {
      user = 'client';
    }
    const PATH_BASE = storage.PLAYIGNITE_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.224.120:5000';
    const PATH_CALL = `/loans?user=${user}&charts_start_date=${from}&charts_end_date=${to}`;
    const url = `${PATH_BASE}${PATH_CALL}`;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    let data = {};

    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }

        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) {
          data.loansData = json.result;
          let charts_data = {
            charts_start_date: data.loansData.charts_start_date,
            charts_end_date: data.loansData.charts_end_date,
            loan_chart_data: data.loansData.loan_chart_data
          };
          this.setState({ chartsData: charts_data });
        } else data.loansData = json;
        return data;
      })
      .catch(error => {
        data.failed = true;
        return data;
      });
  }

  render() {
    if (!this.state.mounted) {
      return null;
    }
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }

    if (this.state.isFailed) {
      return (
        <div className="fetchDataerrorMessage">
          <h3>Oops!</h3>
          <p>Invalid or missing required game permissions to see this view, please contact admin</p>
        </div>
      );
    }

    if (
      (!storage.USER.privileges.includes('access_playignite_admin_section') &&
        !storage.USER.privileges.includes('access_playignite_client_section')) ||
      (storage.USER.privileges.includes('access_playignite_admin_section') &&
        storage.USER.privileges.includes('access_playignite_client_section'))
    ) {
      return (
        <div className="fetchDataerrorMessage">
          <h3>Oops!</h3>
          <p>You don't have permissions to see this view.</p>
        </div>
      );
    }

    return (
      <PIMainContainer>
        {storage.USER.privileges.includes('access_playignite_admin_section') ? (
          <Fragment>
            <Title>All Games List</Title>
            <AdminBoxContainer>
              <ColorfulDataBoxes data={this.state.loanSummary} />
            </AdminBoxContainer>
            <AdminContainer>
              <GamesTable
                actionType={'admin'}
                data={this.state.tableData}
                ignitionStatusData={this.state.ignitionStatusData}
                changeView={this.props.changeView}
              />
            </AdminContainer>
            <ChartsContainer>
              <PlayIgniteCharts
                data={this.state.chartsData.loan_chart_data}
                title1={'All Total Loans'}
                title2={'Total Fees'}
                from={moment(this.state.chartsData.charts_start_date, 'DD-MM-YYYY')}
                to={moment(this.state.chartsData.charts_end_date, 'DD-MM-YYYY')}
                handleUpdate={this.getUpdatedChartsData}
              />
            </ChartsContainer>
          </Fragment>
        ) : null}
        {storage.USER.privileges.includes('access_playignite_client_section') ? (
          <Fragment>
            <Header>
              <Title>{this.state.data.name}</Title>
              <ContactContainer>
                <FinancierContact
                  data={this.state.data.financier_contact}
                  data_id={'financier_contact'}
                  handler={this.handlerMailTo}
                />
              </ContactContainer>
            </Header>
            <Container>
              <BoxContainer>
                <ColorfulDataBoxes data={this.state.loanSummary} />
              </BoxContainer>

              <GamesTable
                actionType={'client'}
                data={this.state.tableData}
                changeView={this.props.changeView}
                globalData={this.state.globalData}
              />
            </Container>
            <ChartsContainer>
              <PlayIgniteCharts
                data={this.state.chartsData.loan_chart_data}
                title1={'Total Loan'}
                title2={'Interest Fees'}
                from={moment(this.state.chartsData.charts_start_date, 'DD-MM-YYYY')}
                to={moment(this.state.chartsData.charts_end_date, 'DD-MM-YYYY')}
                handleUpdate={this.getUpdatedChartsData}
              />
            </ChartsContainer>
          </Fragment>
        ) : null}
      </PIMainContainer>
    );
  }
}

export default PlayIgniteMain;

//region Styles

const Header = styled.div`
  width: 100%;
  position: relative;
`;

const ChartsContainer = styled.div`
  width: 1600px;
  margin: 0 auto;
  margin-bottom: 100px;
`;

const BoxContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 7rem;
  width: 161rem;
`;

const AdminBoxContainer = styled(BoxContainer)`
  margin: 0 auto;
  margin-top: 7rem;
  width: 130rem;
`;

const ContactContainer = styled.div`
  position: absolute;
  right: 70px;
  top: -15px;
`;

const PIMainContainer = styled.div`
  padding-top: 1rem;
`;

const Container = styled.div`
  margin-left: 4rem;
  margin-top: 7rem;
  margin-right: 4rem;
`;

const AdminContainer = styled(Container)`
  margin-left: 8rem;
  margin-right: 8rem;
`;

const Title = styled.h2`
  width: 96%;
  color: #fff;
  padding: 0rem 0rem 1rem 2.5rem;
  font-size: 2.5rem;
  line-height: 4rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 2.5rem;
  margin-left: 3rem;
  margin-bottom: 1rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;
//endregion
