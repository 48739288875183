export const translateColumnName = name => {
  let translate = '';
  switch (name) {
    case '7d ROI':
      translate = '7d_roi';
      break;
    case '1m ROI':
      translate = '1m_roi';
      break;
    case '3m ROI':
      translate = '3m_roi';
      break;
    case '6m ROI':
      translate = '6m_roi';
      break;
    case '9m ROI':
      translate = '9m_roi';
      break;
    case '12m ROI':
      translate = '12m_roi';
      break;

    case '7d LTV':
      translate = '7d_ltv';
      break;
    case '1m LTV':
      translate = '1m_ltv';
      break;
    case '3m LTV':
      translate = '3m_ltv';
      break;
    case '6m LTV':
      translate = '6m_ltv';
      break;
    case '9m LTV':
      translate = '9m_ltv';
      break;
    case '12m LTV':
      translate = '12m_ltv';
      break;
    case 'CPI':
      translate = 'cpi';
      break;
    case 'Installs':
      translate = 'installs';
      break;
    case 'Revenue':
      translate = 'revenue';
      break;
    case 'Impressions':
      translate = 'impressions';
      break;
    case 'Clicks':
      translate = 'clicks';
      break;
    case 'Conversion rate':
      translate = 'conversion_rate';
      break;
    case 'Amount spent':
      translate = 'amount_spent';
      break;

    case 'Revenue to date':
      translate = 'revenue_to_date';
  }
  return translate;
};

export default translateColumnName;
