import React, { Component, Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Calendar from './Calendar';
import posed from 'react-pose';
import warning_icon from './assets/warning_icon.png';
class CustomDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //fromCustom: moment(),
      //toCustom: moment(),
      needUpdate: false,
      showPickerPanel: false,
      showCalendarFrom: false,
      showCalendarTo: false,
      minRangeAlert: false
    };
    this.changeFromDate = this.changeFromDate.bind(this);
    this.changeToDate = this.changeToDate.bind(this);
    this.quickDate = this.quickDate.bind(this);
    this.checkDatesBeforeUpdate = this.checkDatesBeforeUpdate.bind(this);
    this.pickerOpenClose = this.pickerOpenClose.bind(this);
    this.calendarsOpenClose = this.calendarsOpenClose.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.mounted = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidMount() {
    let fromDate = this.props.from;
    let toDate = this.props.to;
    this.mounted = true;
    this.setState({ fromCustom: moment(fromDate), toCustom: moment(toDate), minRangeAlert: false });
  }

  changeFromDate(date) {
    this.setState({
      fromCustom: moment(date),
      needUpdate: true,
      minRangeAlert: false
    });
  }

  changeToDate(date) {
    this.setState({
      toCustom: moment(date),
      needUpdate: true,
      minRangeAlert: false
    });
  }

  pickerOpenClose() {
    let show = this.state.showPickerPanel;
    if (!this.state.showPickerPanel) {
      document.addEventListener('click', this.handleClickOutside, false);
    } else {
      document.removeEventListener('click', this.handleClickOutside, false);
    }
    this.setState({
      showPickerPanel: !show,
      showCalendarFrom: false,
      showCalendarTo: false,
      needUpdate: false,
      minRangeAlert: false
    });
  }

  calendarsOpenClose(type) {
    if (type == 'from') {
      let display = this.state.showCalendarFrom;
      if (this.state.showCalendarTo) {
        this.setState({ showCalendarTo: false, showCalendarFrom: true, minRangeAlert: false });
      } else {
        this.setState({ showCalendarFrom: !display, minRangeAlert: false });
      }
    } else if (type == 'to') {
      let display = this.state.showCalendarTo;
      if (this.state.showCalendarFrom) {
        this.setState({ showCalendarFrom: false, showCalendarTo: true, minRangeAlert: false });
      } else {
        this.setState({ showCalendarTo: !display, minRangeAlert: false });
      }
    }
  }

  handleClickOutside(e) {
    if (this.node) {
      if (this.node.contains(e.target)) {
        return;
      }
      if (e['path'] != undefined && e.path.includes(this.node)) {
        return;
      }
      this.pickerOpenClose();
    }
  }

  quickDate(type) {
    let now;
    if (this.props.notSubtract) {
      now = moment().startOf('day');
    } else {
      now = moment()
        .subtract(2, 'day')
        .startOf('day');
    }
    let from = null;
    let to = null;
    if (type == 'last_day') {
      from = moment(now).startOf('day');
      let to = moment(now).endOf('day');
      this.props.handleUpdate(from, to, true);
    } else if (type == '7days') {
      from = moment(now).subtract(6, 'day');
      to = moment(now);
      this.props.handleUpdate(from, to, true);
    } else if (type == '30days') {
      from = moment(now).subtract(29, 'day');
      to = moment(now);
      this.props.handleUpdate(from, to, true);
    } else if (type == '45days') {
      from = moment(now).subtract(44, 'day');
      to = moment(now);
      this.props.handleUpdate(from, to, true);
    } else if (type == '60days') {
      from = moment(now).subtract(59, 'day');
      to = moment(now);
      this.props.handleUpdate(from, to, true);
    } else if (type == '90days') {
      from = moment(now).subtract(89, 'day');
      to = moment(now);
      this.props.handleUpdate(from, to, true);
    } else if (type == '180days') {
      from = moment(now).subtract(179, 'day');
      to = moment(now);
      this.props.handleUpdate(from, to, true);
    } else if (type == '365days') {
      from = moment(now).subtract(364, 'day');
      to = moment(now);
      this.props.handleUpdate(from, to, true);
    } else if (type == 'thisWeek') {
      from = moment(now).startOf('isoWeek'); //since monday
      to = moment(now);
      this.props.handleUpdate(from, to, true);
    } else if (type == 'thisMonth') {
      from = moment(now).startOf('month');
      to = moment(now);
      this.props.handleUpdate(from, to, true);
    } else if (type == 'thisYear') {
      from = moment(now).startOf('year');
      to = moment(now);
      this.props.handleUpdate(from, to, true);
    }
    this.pickerOpenClose();
  }
  checkDatesBeforeUpdate(from, to) {
    let days_diff = 0;
    if (from > to) {
      days_diff = from.diff(to, 'days') + 1;
      if (days_diff < this.props.min_range) {
        this.setState({ minRangeAlert: true });
      } else {
        this.props.handleUpdate(this.state.toCustom, this.state.fromCustom, this.state.needUpdate);
        this.pickerOpenClose();
      }
    } else {
      days_diff = to.diff(from, 'days') + 1;
      if (days_diff < this.props.min_range) {
        this.setState({ minRangeAlert: true });
      } else {
        this.props.handleUpdate(this.state.fromCustom, this.state.toCustom, this.state.needUpdate);
        this.pickerOpenClose();
      }
    }
  }

  render() {
    if (!this.mounted) {
      return null;
    }
    let display = this.state.showPickerPanel;
    let button = null;
    if (this.state.needUpdate) {
      button = (
        <Button
          type={'button'}
          onClick={() => this.checkDatesBeforeUpdate(this.state.fromCustom, this.state.toCustom)}
          needUpdate={this.state.needUpdate}
        >
          Update
        </Button>
      );
    } else {
      button = (
        <Button type={'button'} needUpdate={this.state.needUpdate}>
          Update
        </Button>
      );
    }

    return (
      <Fragment>
        <div
          ref={node => {
            this.node = node;
          }}
        >
          {this.props.title ? <Header>{this.props.title}</Header> : <Header>Data Range: </Header>}
          <DateRangePanel
            cWidth={this.props.cWidth}
            active={this.props.active}
            onClick={this.pickerOpenClose}
            background={this.props.backgroundColor}
          >
            {' '}
            {this.state.minRangeAlert ? (
              <MinRangeAlert
                pose={this.state.minRangeAlert ? 'enterRange' : 'exitRange'}
                initialPose={'initialRange'}
              >
                {' '}
                <WarningIcon src={warning_icon} />
                {this.props.warning_msg}
              </MinRangeAlert>
            ) : null}
            {this.props.from.format('DD/MM/YYYY')} - {this.props.to.format('DD/MM/YYYY')}
            <ArrowDown active={this.props.active} />
          </DateRangePanel>
          {this.state.showPickerPanel ? (
            <PickerPanel cWidth={this.props.cWidth} displayPicker={this.state.showPickerPanel}>
              <CalendarsPanel>
                <Calendar
                  date={this.state.fromCustom}
                  changeDate={this.changeFromDate}
                  displayChange={this.calendarsOpenClose}
                  type="from"
                  display={this.state.showCalendarFrom}
                  name={this.props.name}
                  toRangeDate={this.props.toRangeDate}
                  notSubtract={this.props.notSubtract}
                />
                <TextElementPicker>to</TextElementPicker>
                <Calendar
                  date={this.state.toCustom}
                  changeDate={this.changeToDate}
                  displayChange={this.calendarsOpenClose}
                  type="to"
                  display={this.state.showCalendarTo}
                  name={this.props.name}
                  toRangeDate={this.props.toRangeDate}
                  notSubtract={this.props.notSubtract}
                />
              </CalendarsPanel>
              {button}
              {this.props.quickDays ? (
                <QuickDatesContainer>
                  <QuickTitle>Quick Dates</QuickTitle>
                  <QuickDatesOneRow>
                    <QuickDate onClick={() => this.quickDate('30days')}>30 days</QuickDate>
                    <QuickDate onClick={() => this.quickDate('45days')}>45 days</QuickDate>
                    <QuickDate onClick={() => this.quickDate('60days')}>60 days</QuickDate>
                    <QuickDate onClick={() => this.quickDate('90days')}>90 days</QuickDate>
                  </QuickDatesOneRow>
                </QuickDatesContainer>
              ) : (
                <QuickDatesContainer>
                  <QuickTitle>Quick dates</QuickTitle>
                  <QuickDatesPanel>
                    {/*  {this.props.notLastDay ? null : <QuickDate onClick={() => this.quickDate('last_day')}>Last day</QuickDate>} */}
                    <QuickDate onClick={() => this.quickDate('7days')}>7 days</QuickDate>
                    <QuickDate onClick={() => this.quickDate('30days')}>30 days</QuickDate>
                    <QuickDate onClick={() => this.quickDate('60days')}>60 days</QuickDate>
                    <QuickDate onClick={() => this.quickDate('365days')}>365 days</QuickDate>
                    <QuickDate onClick={() => this.quickDate('thisWeek')}>This week</QuickDate>
                    <QuickDate onClick={() => this.quickDate('thisMonth')}>This month</QuickDate>
                    <QuickDate onClick={() => this.quickDate('thisYear')}>
                      {moment().format('YYYY')}
                    </QuickDate>
                  </QuickDatesPanel>
                </QuickDatesContainer>
              )}
            </PickerPanel>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default CustomDatePicker;

/** Custom Date Picker Styles */
//region Styles
const QuickDate = styled.span`
  display: inline-block;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
  text-align: center;
  padding: 8px;
  padding-top: 8px;
  border-radius: 3px;
  border: #00d5a9 solid 0.5px;
  color: #00d5a9;
  margin-top: 7px;
  margin-right: 0px;
  cursor: pointer;
  &:hover {
    color: #f7f8f8;
    border: #f7f8f8 solid 0.5px;
  }
`;

const WarningIcon = styled.img`
  width: 12px;
  margin-right: 4px;
  margin-left: 6px;
`;

const Button = styled.button`
  width: 100%;
  height: 30px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(30, 32, 34);
  border-radius: 3px;
  font-size: 13px;
  font-family: 'Roboto';
  color: rgb(255, 255, 255);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  text-align: center;
  background-color: #555555;
  z-index: 1;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.36), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
  cursor: ${props => (props.needUpdate ? 'pointer' : 'initial')};
  background-image: ${props =>
    props.needUpdate
      ? '-webkit-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%)'
      : '-webkit-linear-gradient(90deg, rgb(133,133,133) 0%, rgb(214,214,214) 100%)'};
  &:hover {
    background-image: ${props =>
      props.needUpdate
        ? '-webkit-linear-gradient(90deg, rgb(23, 132, 113) 0%, rgb(0, 213, 169) 100%)'
        : '-webkit-linear-gradient( 90deg, rgb(113,113,113) 0%, rgb(149,149,149) 100%)'};
  }
  &:focus {
    outline: none;
  }
`;

const DateRangePanel = styled.div`
  margin-top: 5px;
  border-radius: 3px;
  background-color: ${props => (props.background ? props.background : '#1e2022')};
  -webkit-box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1),
    inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  height: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 10px;
  border: none;
  position: relative;
  outline: none;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: ${props => (props.active ? '#e1e1e5' : '#747773')};
  letter-spacing: 0.05em;
  line-height: 30px;
  padding-right: 10px;
  width: ${props => (props.cWidth ? '100%' : '331px')};
  display: inline-block;
  pointer-events: ${props => (props.active ? 'auto' : 'none')};
  cursor: ${props => (props.active ? 'pointer' : 'initial')};
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
`;

const PickerPanel = styled.div`
  margin-top: 1px;
  width: 332px;
  background-color: #1e2022;
  border: 0.5px solid #36393f;
  border-radius: 3px;
  padding: 20px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 5rem 5rem auto;
  position: absolute;
  pointer-events: ${props => (props.displayPicker ? 'auto' : 'none')};
  margin-left: ${props => (props.cWidth ? '0px' : '72px')};
  z-index: 1050;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
`;
const TextElementPicker = styled.span`
  font-size: 1.3rem;
  text-align: center;
  padding-top: 5px;
`;
const CalendarsPanel = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 12rem 5rem 13rem;

  &:nth-child(1) {
    grid-column: 1/2;
    grid-row: 1;
  }

  &:nth-child(2) {
    grid-column: 2/3;
    grid-row: 1;
  }

  &:nth-child(3) {
    grid-column: 3/4;
    grid-row: 1;
  }
`;

const QuickDatesContainer = styled.div`
  border-top: 0.5px solid #36393f;
  width: 100%;
`;

const QuickDatesPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${QuickDate} {
    margin-right: 7px;
  }
`;

const QuickDatesOneRow = styled(QuickDatesPanel)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${QuickDate} {
    margin-right: 0px;
    padding-left: 9px;
    padding-right: 9px;
  }
`;

const QuickTitle = styled.p`
  font-size: 1.2rem;
  margin: 10px 0px 5px 0px;
  color: #99999c;
  font-style: normal;
  letter-spacing: 0.05rem;
`;

const ArrowDown = styled.div`
  content: '';
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: ${props => (props.active ? '5px solid #00d5a9' : '5px solid #A6A4A4')};
  position: absolute;
  top: 12px;
  right: 10px;
  z-index: 98;
`;

const Header = styled.span`
  color: #99999c;
  display: inline-block;
  font-size: 12px;
  margin: 0px;
  margin-right: 8px;
`;

const MinRangeAlert = styled(
  posed.div({
    enterRange: {
      opacity: 1,
      transition: { duration: 150 }
    },
    exitRange: { opacity: 0.0, transition: { duration: 150 } },
    initialRange: { opacity: 0 }
  })
)`
  position: absolute;
  top: 135px;
  left: 23px;
  z-index: 1060;
  pointer-events: none;
  font-size: 11px;
  line-height: 1.2;
  color: #ffa119;
`;
//endregion
