import React, { Component } from 'react';
import './style.css';
import styled from 'styled-components';
import SelectDeselectAll from 'components/SelectDeselectAll';

class Regions extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return [
      <div key={'RegionsContainerKey'}>
        <RegionList>
          {Object.entries(this.props.regions).map(([key, val]) => {
            let region =
              this.props.currentRegion == key ? (
                <CurrentRegion value={key} onClick={this.props.handleCurrentClickedRegion}>
                  {key}
                </CurrentRegion>
              ) : (
                <RegionItem value={key} onClick={this.props.handleCurrentClickedRegion}>
                  {key}
                </RegionItem>
              );
            return (
              <RegionItemContainer key={key}>
                <li>{region}</li>
                <SelectDeselectPlaceholder>
                  <SelectDeselectAll
                    selectDeselectAll={this.props.selectOneRegion}
                    buttonState={this.props.buttonStateAll[key]}
                    param={key}
                  />
                </SelectDeselectPlaceholder>
              </RegionItemContainer>
            );
          })}
        </RegionList>
      </div>
    ];
  }
}

export default Regions;
//region Styles

const RegionList = styled.ul`
  position: absolute;
  top: 105px;
  width: 736px;
  margin-bottom: 0px;
  padding: 0px;

  margin-left: 0px;
  display: block;
  list-style-type: none;
`;

const RegionItem = styled.a`
  display: block;
  float: left;
  padding: 18px 14px 18px 25px;
  font-size: 0.8em;
  color: #99999c;
  cursor: pointer;
  overflow: auto;
  user-select: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
`;

const CurrentRegion = styled(RegionItem)`
  border-radius: 3px 3px 0px 0px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  border-bottom: 1px solid #1e2022;
  color: #00d5a9;
  z-index: 1000;
`;

const SelectDeselectPlaceholder = styled.div`
  position: absolute;
  top: 19px;
  left: 9px;
  display: block;
  z-index: 100;
`;

const RegionItemContainer = styled.div`
  position: relative;
  float: left;
`;
//endregion
