import React, { Component, Fragment } from 'react';
import TitleBar from 'components/TitleBar';
import PlayIgniteMain from './PlayIgniteMain';
import PlayIgniteDetails from './PlayIgniteDetails';
import KpiGameDetails from '../Kpi/KpiGameDetails';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { Content } from 'views/App';
import { AppContext } from 'views/App';
/** Routes */
//region Routes
const routes = [
  {
    path: '/playignite',
    exact: true,
    view: PlayIgniteMain,
    title: 'Welcome to PlayStack Dashboard'
  },
  {
    path: '/playignite/game/:game_slug',
    exact: false,
    view: PlayIgniteDetails,
    title: 'Welcome to PlayStack Dashboard'
  },
  {
    path: '/external/game/:id',
    exact: false,
    view: KpiGameDetails,
    title: 'Welcome to PlayStack Dashboard'
  }
];
//endregion

class PlayIgnite extends Component {
  constructor(props) {
    super(props);
    this.changeView = this.changeView.bind(this);
    this.changeViewToKpi = this.changeViewToKpi.bind(this);
  }

  changeView(slug) {
    //  console.log('change view', slug);
    this.props.history.push({
      pathname: `/playignite/game/${slug}`,
      search: '',
      state: { slug: slug }
    });
  }

  changeViewToKpi(slug) {
    this.props.history.push({
      pathname: `/external/game/${slug}`,
      search: '',
      state: { slug: slug }
    });
  }
  render() {
    // console.log('PLAYIGNITE >>> ', this.props);
    return (
      <Fragment>
        <TitleBar
          name={this.props.user.first_name}
          title={this.props.title}
          color={this.props.color}
          logout={this.props.logoutCall}
          history={this.props.history}
        />
        <Content>
          <AppContext.Consumer>
            {({ getGamesIcons, logout }) => (
              <Switch>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    id={index}
                    path={route.path}
                    exact={route.exact}
                    render={props => (
                      <route.view
                        changeView={this.changeView}
                        changeViewToKpi={this.changeViewToKpi}
                        filter={this.props.filter}
                        updatePrivCall={this.props.updatePrivCall}
                        privilegesUpdated={this.props.privilegesUpdated}
                        getIcons={getGamesIcons}
                        {...props}
                      />
                    )}
                    //component={route.view}
                  />
                ))}
                <Route render={() => <Redirect to="/" />} />
              </Switch>
            )}
          </AppContext.Consumer>
        </Content>
      </Fragment>
    );
  }
}

export default PlayIgnite;
