import React, { Component, PureComponent, Fragment } from 'react';
import { defaults, Bar, Line } from 'react-chartjs-2';
import styled, { injectGlobal, keyframes } from 'styled-components';
import Legend from './UAChartLegend';
import BarChart from './UABarChart';
import './style.css';
import {
  backgroundColorTable,
  borderColorTable,
  colorChartUAPalette,
  colorChartUABackground
} from 'components/ColorPicker/index';
import formatNumberWithDots from 'utils/formatNumberWithDots';
import formatNumberWithK from 'utils/formatNumberWithK';
import TooltipStyle from 'components/TooltipStyle';

class UserAcquisitionChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: null,
      active_option: null,
      chartRef: null,
      legendRef: null,
      mounted: false,
      hoveredLabel: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.changeTooltip = this.changeTooltip.bind(this);
    this.updateChartRef = this.updateChartRef.bind(this);
    this.updateLegendRef = this.updateLegendRef.bind(this);
    this.hoverLabelName = this.hoverLabelName.bind(this);
    this.mounted = false;
  }
  /** UA Chart Lifecycle Methods */
  //region Lifecycle Methods
  /*

       ->   getDerivedStateFromProps {active_option: "Costs", data: Array(7), tableChange: ƒ} {tooltip: null, updateChart: false}

       -->   componentDidMount {tooltip: null, updateChart: false}

       ->   getDerivedStateFromProps {active_option: "Costs", data: Array(7), tableChange: ƒ} {tooltip: null, updateChart: true}

       -->   shouldComponentUpdate {tooltip: null, updateChart: false} {tooltip: null, updateChart: true}

 */
  static getDerivedStateFromProps(props, state) {
    // console.log('getDerivedStateFromProps',props,state)
    if (props.active_option != state.active_option) return { active_option: props.active_option };

    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log("shouldComponentUpdate",this.state,nextState)
    //if(this.state.tooltip !== nextState.tooltip)return true;
    if (nextState.active_option !== this.state.active_option) return true;
    if (nextState.mounted && !this.state.mounted) return true;
    return true;
  }

  componentDidMount() {
    // console.log("componentDidMount",this.state)
    this.setState({ mounted: true });
    this.mounted = true;
    //this.forceUpdate();
  }

  componentWillUnmount() {}

  //endregion
  /** UA Chart Parsing Data */
  //region Parsing Data

  //endregion

  updateChartRef(ref) {
    this.setState({ chartRef: ref.current.chartInstance });
  }

  updateLegendRef(ref) {
    this.setState({ legendRef: ref.current });
  }

  handleTableChange(evt) {
    let activeElement = this.state.chartRef.getElementAtEvent(evt);
    if (activeElement.length == 0) {
      return;
    }
    //let info = this.state.chartRef.config.data.datasets[activeElement[0]._datasetIndex].data[
    //activeElement[0]._index
    // ];
    let item = activeElement[0]._model.datasetLabel;
    // let index = this.state.chartRef.chartInstance.data.labels[activeElement[0]._index];
    this.props.tableChange(item, 'source');
  }

  showTooltip = tooltip => {
    //setTimeout(this.changeTooltip, 0, tooltip);
    this.changeTooltip(tooltip);
  };

  changeTooltip(tooltip) {
    if (tooltip.opacity === 0) {
      this.setState({
        tooltip: undefined
      });
    } else {
      this.setState({
        tooltip: tooltip
      });
    }
  }

  hoverLabelName(name) {
    this.setState({ hoveredLabel: name });
  }
  render() {
    return (
      <Panel>
        <Title>{this.props.active_option}</Title>
        <Canvas key={'UaChartCanvas'} onClick={this.handleBarClick} className="uaCanvas">
          <Tooltip data={this.state.tooltip} active={this.state.active_option} />
          <BarChart
            data={this.props.data}
            active_option={this.state.active_option}
            showTooltip={this.showTooltip}
            updateChartRef={this.updateChartRef}
            handleClick={this.handleTableChange}
            handleHover={this.hoverLabelName}
          />
        </Canvas>

        {this.state.chartRef ? (
          <Legend
            chart={this.state.chartRef}
            active_option={this.state.active_option}
            updateLegendRef={this.updateLegendRef}
            hoveredLabel={this.state.hoveredLabel}
          />
        ) : null}
      </Panel>
    );
  }

  handleChange(event) {
    this.setState({ revenue_group: event.target.value });
  }
}

export default UserAcquisitionChart;

const Tooltip = ({ data, active }) => {
  if (!data) {
    return null;
  }
  let footer = null;
  let roiCol = [];
  let secondCol = [];
  let label_mark = '';
  if (active == 'Costs') {
    label_mark = '$';
  } else if (active == 'Installs') {
    label_mark = '';
  }
  Object.entries(data.body).map(([key, val]) => {
    let str_body = val.lines.toString();
    let body_arr = str_body.split(': ');
    let body_float = parseFloat(body_arr[1]);
    let body_format = formatNumberWithDots(body_float);

    let check_body =
      str_body.startsWith('3m') ||
      str_body.startsWith('6m') ||
      str_body.startsWith('9m') ||
      str_body.startsWith('1y');
    let check_unreliable = str_body.includes('UNRELIABLE');
    if (check_unreliable) {
      let onlyDate = body_arr[0].split('UNRELIABLE');
      roiCol.push(
        <Fragment key={onlyDate[0]}>
          <div>
            <TooltipMark colorMark={data.labelColors[key].borderColor}>&#x25CF;</TooltipMark>
            {onlyDate[0]}: {body_format}% *
          </div>
        </Fragment>
      );
      footer = <TooltipFooter>* - Unreliable</TooltipFooter>;
    }
    if (
      !check_unreliable &&
      key == 0 &&
      !check_unreliable &&
      (!str_body.startsWith('3m') &&
        !str_body.startsWith('6m') &&
        !str_body.startsWith('9m') &&
        !str_body.startsWith('1y'))
    ) {
      roiCol.push(<NoData key={str_body}>No data</NoData>);
    }
    if (check_body && !check_unreliable) {
      roiCol.push(
        <Fragment key={body_arr[0]}>
          <div>
            <TooltipMark colorMark={data.labelColors[key].borderColor}>&#x25CF;</TooltipMark>
            {body_arr[0]}: {body_format}%
          </div>
        </Fragment>
      );
    } else if (!check_body || key >= 4) {
      secondCol.push(
        <Fragment key={body_arr[0]}>
          <div>
            <TooltipMark colorMark={data.labelColors[key].borderColor}>&#x25A0;</TooltipMark>
            {body_arr[0]}: {body_format}
            {label_mark}
          </div>
        </Fragment>
      );
    }
  });

  return (
    <TooltipStyle top={'50%'} left={data.caretX + 165} leftSide={true}>
      <TooltipTitle>{data.title}</TooltipTitle>
      <TooltipContent>
        <RoiColumn>
          <TooltipSubtitle>ROI</TooltipSubtitle>
          {roiCol}
        </RoiColumn>
        <SecondColumn>
          <TooltipSubtitle>{active}</TooltipSubtitle>
          {secondCol}
        </SecondColumn>
        {footer}
      </TooltipContent>
    </TooltipStyle>
  );
};

/**UA Chart Styles */
//region Styles
const Panel = styled.div`
  background: #282b30;
  width: 100%;
  color: #fff;
  display: flex;
  padding-top: 0rem;
  padding-right: 0rem;
  padding-left: 0rem;
  padding-bottom: 0rem;
  flex-direction: column;
  box-sizing: border-box;
`;

const Title = styled.div`
  color: #fefefe;
  font-size: 1.8rem;
  line-height: 3.6rem;
  font-weight: 600;
  margin-right: 2rem;
  margin-left: 2rem;
  margin-top: 1rem;
  margin-bottom: 10px;
  border-bottom: 1px solid #606064;
  box-sizing: border-box;
`;

const Canvas = styled.div`
  padding: 0;
  margin: 0;
  background: #282b30;
  flex-grow: 1;
  position: relative;
  min-height: 560px !important;
  height: auto;
`;

const TooltipTitle = styled.h1`
  text-align: center;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 8px;
  border-bottom: 1px solid #505561;
  letter-spacing: 0.05rem;
`;

const TooltipContent = styled.div`
  font-size: 12px;
  display: grid;
  grid-template-columns: 40% 59%;
  grid-column-gap: 5px;
`;
const RoiColumn = styled.div`
  grid-column: 1/2;
  grid-row: 1;
`;

const SecondColumn = styled.div`
  grid-column: 2/3;
  grid-row: 1;
`;
const TooltipSubtitle = styled.h2`
  font-size: 12px;
  text-align: center;
  margin-top: 3px;
`;
const TooltipMark = styled.span`
  opacity: 1;
  display: inline-block;
  z-index: 1900;
  font-size: 15px;
  padding-right: 3px;
  color: ${props => (props.colorMark ? props.colorMark : '#fff')};
`;

const TooltipFooter = styled.div`
  grid-column: 1/3;
  grid-row: 2;
  border-top: 1px solid #505561;
  font-size: 10px;
  color: #99999c;
  text-align: right;
  width: 100%;
  padding-top: 5px;
  margin-top: 5px;
`;
const NoData = styled.span`
  padding-left: 28px;
  display: block;
`;
//endregion
