import React, { Component, Fragment } from 'react';
import { AppContext } from 'views/App';
import styled from 'styled-components';
import { Content } from 'views/App';
import posed, { PoseGroup } from 'react-pose';

/**Animated Vieww*/
//region  Routes

//endregion

class AnimatedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posed: 'initial'
    };
  }
  /**Admin Lifecycle Methods*/
  //region  Admin Lifecycle Methods

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this.mounted = false;
  }
  //endregion

  render() {
    return (
      <Container>
        <PoseGroup animateOnMount>
          <Animated key={'animtedListKey' + Math.random() * 1000}>{this.props.children}</Animated>
        </PoseGroup>
      </Container>
    );
  }
  //endregion
}
export default AnimatedList;

/**Animated View Styles*/
//region  Styles
const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`;

const Animated = styled(
  posed.div({
    enter: {
      opacity: 1,
      delay: 100,
      transition: { duration: 1000 }
    },
    exit: { opacity: 0.0, transition: { duration: 0 } },
    initial: { opacity: 0 }
    // before: { scale: 1.0, backgroundColor: '#f9415d', opacity: 0.0 },
    //after: { scale: 1.0, backgroundColor: '#fcab10', opacity: 1,delay:500 }
  })
)`
  width: 100%;
  margin: 0;
  padding: 0;
`;
//endregion
