import React, { Component, Fragment } from 'react';
import FinancialMain from './FinancialMain';
import FinancialDashBoard from './FinancialDashBoard';
import FinancialLedger from './FinancialLedger';
import TitleBar from 'components/TitleBar';

import styled from 'styled-components';
import { Content } from 'views/App';

class Financial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: FinancialMain
    };
    this.header = 'AppStart';
    this.components = {};
    this.addComponents();
    this.handleGoBack = this.handleGoBack.bind(this);
    this.changeView = this.changeView.bind(this);
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  addComponents() {
    this.components.FinancialMain = FinancialMain;
    this.components.FinancialDashBoard = FinancialDashBoard;
    this.components.FinancialLedger = FinancialLedger;
  }

  changeView(view, header) {
    const currentView = this.components[view];
    this.header = header;
    this.setState({ currentView });
  }

  handleGoBack() {
    if (this.state.currentView === FinancialMain) this.props.redirect('/');
    else if (this.state.currentView === FinancialDashBoard)
      this.changeView('FinancialMain', `AppStart`);
    else if (this.state.currentView === FinancialLedger)
      this.changeView('FinancialMain', `AppStart`);
  }

  getHeader() {
    if (this.state.currentView === FinancialMain) this.props.redirect('/');
    else if (this.state.currentView === FinancialDashBoard) this.changeView('FinancialMain');
    else if (this.state.currentView === FinancialLedger) this.changeView('FinancialMain');
  }

  render() {
    return (
      <Fragment>
        <TitleBar
          name={this.props.user.first_name}
          title={this.props.title}
          color={this.props.color}
          logout={this.props.logoutCall}
          history={this.props.history}
        />
        <Content>
          <this.state.currentView
            changeView={this.changeView}
            updatePrivCall={this.props.updatePrivCall}
            privilegesUpdated={this.props.privilegesUpdated}
          />
        </Content>
      </Fragment>
    );
  }
}

export default Financial;
