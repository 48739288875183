import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PrivilegesPanel from '../PrivilegesPanel';
import CommonButton, { Button } from 'components/CommonButton';
import AnimatedView from 'components/AnimatedView';
import Textfield from 'components/Textfield';
import { AdminContext } from 'views/App/Admin/';
import storage from 'utils/storage';
import LoadingIndicator from 'components/LoadingIndicator';
class ManageUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.match.params.mode.slice(1),
      email: '',
      firstName: '',
      lastName: '',
      permissionTable: [],
      groupsList: this.props.location.state.permissionList,
      gameGroupsList: this.props.location.state.gameGroupsData,
      needUpdate: false,
      isFetching: false,
      title: 'Add User',
      lastElementHeight: 0
    };
    this.deletePermission = this.deletePermission.bind(this);
    this.addPermission = this.addPermission.bind(this);
    this.addGameGroup = this.addGameGroup.bind(this);
    this.checkUpdate = this.checkUpdate.bind(this);
    this.selectPermission = this.selectPermission.bind(this);
    this.selectGameGroup = this.selectGameGroup.bind(this);
    this.deleteGameGroup = this.deleteGameGroup.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setFirstName = this.setFirstName.bind(this);
    this.setLastName = this.setLastName.bind(this);
    this.prepareData = this.prepareData.bind(this);
    this.sendInfo = this.sendInfo.bind(this);
    this.parseData = this.parseData.bind(this);
    this.sendSaveInfo = this.sendSaveInfo.bind(this);
    this.selectOnFocus = this.selectOnFocus.bind(this);
    this.selectOnBlur = this.selectOnBlur.bind(this);
    this.selectOnChange = this.selectOnChange.bind(this);
  }

  componentDidMount() {
    if (this.state.type === 'edit') {
      this.setState({ isFetching: true }, () => {
        this.parseData();
      });
    }
  }

  parseData() {
    let data = this.props.location.state.item;
    let sourceTable = this.state.permissionTable;
    let counter = sourceTable.length;
    Object.entries(data.auth_user_groups).map(([key, value]) => {
      let privilegeName = '';
      let gameGroup = [];
      Object.entries(this.state.groupsList).map(([kp, vp]) => {
        if (value == vp.key) {
          privilegeName = vp.value;
        }
      });

      Object.entries(data.game_groups).map(([k, v]) => {
        if (k == value) {
          if (v.length == 0) {
            gameGroup.push({ id: null, value: '', placeholder: 'Select Game Group' });
          } else {
            for (let game of v) {
              let gameGroupName = '';
              Object.entries(this.state.gameGroupsList).map(([gk, gv]) => {
                if (game == gv.key) {
                  gameGroupName = gv.value;
                }
              });
              gameGroup.push({ id: game, value: gameGroupName, placeholder: '' });
            }
          }
        }
      });

      counter++;
      sourceTable.push({
        permission: { id: value, value: privilegeName, placeholder: '' },
        game_groups: gameGroup,
        panel_id: privilegeName + counter
      });
    });
    this.setState(
      {
        email: data.email,
        firstName: data.first_name,
        lastName: data.last_name,
        permissionTable: sourceTable,
        id: data.id,
        title: 'Edit User',
        isFetching: false
      },
      () => {
        this.checkUpdate();
      }
    );
  }

  setEmail(e) {
    const target = e.target;
    this.setState({ email: target.value }, () => {
      this.checkUpdate();
    });
  }

  setFirstName(e) {
    const target = e.target;
    this.setState({ firstName: target.value }, () => {
      this.checkUpdate();
    });
  }

  setLastName(e) {
    const target = e.target;
    this.setState({ lastName: target.value }, () => {
      this.checkUpdate();
    });
  }

  checkUpdate() {
    if (this.state.lastName !== '' && this.state.firstName !== '' && this.state.email !== '') {
      this.setState({ needUpdate: true });
    } else {
      this.setState({ needUpdate: false });
    }
  }

  deletePermission(id) {
    let sourceTable = this.state.permissionTable;
    sourceTable.splice(id, 1);
    this.setState({ permissionTable: sourceTable });
  }

  addPermission() {
    let sourceTable = this.state.permissionTable;
    sourceTable.push({
      permission: { id: null, value: '', placeholder: 'Select Permission Group' },
      game_groups: [{ id: null, value: '', placeholder: 'Select Game Group' }]
    });
    this.setState({ permissionTable: sourceTable });
  }

  addGameGroup(id) {
    let sourceTable = this.state.permissionTable;
    let gameGroups = sourceTable[id].game_groups;
    gameGroups.push({ id: null, value: '', placeholder: 'Select Game Group' });
    sourceTable[id].game_groups = gameGroups;
    this.setState({ permissionTable: sourceTable });
  }

  selectPermission(e, id) {
    let sourceTable = this.state.permissionTable;
    let target = e.target;

    sourceTable[id].permission.id = target.value;
    sourceTable[id].permission.value = target.name;
    sourceTable[id].permission.placeholder = '';
    this.setState({ permissionTable: sourceTable });
  }

  selectGameGroup(e, params) {
    let sourceTable = this.state.permissionTable;

    let target = e.target;
    let panelId = params[0];
    let gameGroupId = params[1];
    let game = sourceTable[panelId].game_groups[gameGroupId];

    if (target.value == 'none') {
      game.id = target.value;
    } else {
      game.id = parseInt(target.value);
    }

    game.value = target.name;
    game.placeholder = '';
    sourceTable[panelId].game_groups[gameGroupId] = game;
    this.setState({ permissionTable: sourceTable });
  }

  deleteGameGroup(panelId, gameId) {
    let sourceTable = this.state.permissionTable;
    let gamesTable = sourceTable[panelId].game_groups;
    gamesTable.splice(gameId, 1);
    sourceTable[panelId].game_groups = gamesTable;
    this.setState({ permissionTable: sourceTable });
  }

  selectOnFocus() {
    let newHeight = 30 * this.state.groupsList.length;
    this.setState({ lastElementHeight: newHeight + 'px' });
  }

  selectOnBlur() {
    this.setState({ lastElementHeight: '0px' });
  }

  selectOnChange() {
    this.setState({ lastElementHeight: '0px' });
  }
  /**Sending user info */
  //region Preparing and sending data
  prepareData() {
    let permissions = [];
    let permission_game_groups = {};
    Object.entries(this.state.permissionTable).map(([key, value]) => {
      let game_groups = [];
      let permission_id = value.permission.id;
      if (permission_id !== null) {
        permissions.push(value.permission.id);
      }
      Object.entries(value.game_groups).map(([k, v]) => {
        if (v.id !== null && v.id !== 'none') {
          game_groups.push(v.id);
        }
      });
      if (permission_id !== null) {
        permission_game_groups[permission_id] = game_groups;
      }
    });
    let data = {
      email: this.state.email,
      firstname: this.state.firstName,
      lastname: this.state.lastName,
      auth_groups: permissions,
      game_groups: permission_game_groups
    };

    if (this.state.type == 'edit') {
      this.sendSaveInfo(data);
    } else {
      this.sendInfo(data);
    }
  }

  sendSaveInfo(data) {
    if (!this.state.needUpdate) {
      return;
    }
    this.setState({ isFetching: true });
    const PATH_BASE = storage.ADMIN_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.240.58:5000';
    const PATH_CALL = '/users/update';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };

    let payload = {
      user_id: this.props.match.params.id.slice(1),
      email: data.email,
      first_name: data.firstname,
      last_name: data.lastname,
      replace_groups: data.game_groups
    };

    return fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) storage.USER.token = token;
        if (status === 200) return json;
      })
      .then(json => {
        if (json === undefined) return;
        this.props.history.push({
          pathname: `/admin`
        });
        return json;
      })
      .catch(error => {
        this.setState({ isFetching: false });
        console.error(error);

        this.props.history.push({
          pathname: `/admin`
        });
      });
  }
  sendInfo(data) {
    if (!this.state.needUpdate) {
      return;
    }
    this.setState({ isFetching: true });
    const PATH_BASE = storage.ADMIN_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.240.58:5000';
    const PATH_CALL = '/users/add';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };

    let payload = {
      email: data.email,
      first_name: data.firstname,
      last_name: data.lastname,
      auth_groups: data.auth_groups,
      game_groups: data.game_groups
    };

    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) storage.USER.token = token;
        if (status === 200) return json;
      })
      .then(json => {
        if (json === undefined) return;
        this.props.history.push({
          pathname: `/admin`
        });
        return json;
      })
      .catch(error => {
        this.setState({ isFetching: false });
        console.error(error);
        this.props.history.push({
          pathname: `/admin`
        });
      });
  }
  //endregion

  render() {
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }
    let button;
    if (this.state.type == 'add') {
      button = (
        <Button onClick={this.prepareData} needUpdate={this.state.needUpdate}>
          ADD USER
        </Button>
      );
    } else if (this.state.type == 'edit') {
      button = (
        <Button onClick={this.prepareData} needUpdate={this.state.needUpdate}>
          SAVE CHANGES
        </Button>
      );
    }
    return (
      <AdminContext.Consumer>
        {AdminState => {
          return (
            <AnimatedView>
              <ViewTile>{this.state.title}</ViewTile>
              <UserContainer>
                <UserData>
                  <Textfield
                    labelText={'Email'}
                    id={'addUserEmail'}
                    placeholder={'ie. jonsnow@example.com'}
                    value={this.state.email}
                    onChange={this.setEmail}
                    autoFocus={true}
                  />
                  <Textfield
                    labelText={'First Name'}
                    id={'addUserFirstName'}
                    placeholder={'ie. Jon'}
                    value={this.state.firstName}
                    onChange={this.setFirstName}
                  />
                  <Textfield
                    labelText={'Last Name'}
                    id={'addUserLastName'}
                    placeholder={'ie. Snow'}
                    value={this.state.lastName}
                    onChange={this.setLastName}
                  />
                </UserData>
                <PrivilegesContainer>
                  <PermissionTitle>Permissions</PermissionTitle>
                  <PrivilegesPanel
                    items={this.state.permissionTable}
                    deletePermission={this.deletePermission}
                    gamesGroupsList={this.state.gameGroupsList}
                    groupsList={this.state.groupsList}
                    deletePanel={this.deletePermission}
                    addGameGroup={this.addGameGroup}
                    selectPermission={this.selectPermission}
                    selectGame={this.selectGameGroup}
                    deleteGameGroup={this.deleteGameGroup}
                    onFocusHandler={this.selectOnFocus}
                    onBlurHandler={this.selectOnBlur}
                    onChangeHandler={this.selectOnChange}
                  />
                  <PermissionButtonContainer>
                    <CommonButton text="+ ADD PERMISSION" onClick={this.addPermission} />
                  </PermissionButtonContainer>
                </PrivilegesContainer>
                <AddUserBtn>{button}</AddUserBtn>
                <EmptyDiv lastHeight={this.state.lastElementHeight} />
              </UserContainer>
            </AnimatedView>
          );
        }}
      </AdminContext.Consumer>
    );
  }
}

export default ManageUser;

//region Styles

const ViewTile = styled.h1`
  width: 95%;
  color: #fff;
  padding: 0rem 0rem 1%;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 4rem;
  margin-right: 3%;
  margin-left: 2%;
  margin-bottom: 1rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;

const UserContainer = styled.div`
  width: 90%;
  margin-left: 10%;
`;

const PrivilegesContainer = styled.div`
  width: 800px;
`;

const EmptyDiv = styled.div`
  grid-row: 10;
  grid-column: 1/2;
  height: ${props => (props.lastHeight ? props.lastHeight : '0px')};
  display: block;
`;

const UserData = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 26rem 22rem 22rem;
  grid-column-gap: 5rem;
  margin-top: 80px;
  margin-bottom: 70px;
  &:nth-child(1) {
    grid-column: 1/2;
  }

  &:nth-child(2) {
    grid-column: 2/3;
  }
  &:nth-child(3) {
    grid-column: 3/4;
  }
`;

const PermissionTitle = styled.div`
  font-family: 'Titillium Web';
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
`;

const PermissionButtonContainer = styled.div`
  width: 155px;
`;

const AddUserBtn = styled.div`
  margin-top: 40px;
  width: 220px;
  margin-bottom: 80px;
  height: 32px;
`;
//endregion
