/* eslint-disable arrow-body-style */

const defaults = {
  prepend: '$',
  thousandsSize: 3,
  thousandsDelimiter: ',',
  decimalSize: 2,
  decimalDelimiter: '.'
};

export const currency = (n, opt) => {
  // Get options
  if (n === undefined) return n;
  if (opt) {
    for (const i in defaults) {
      if (!(i in opt)) {
        opt[i] = defaults[i];
      }
    }
  } else {
    opt = defaults;
  }
  //if(n===0)opt['decimalSize'] = 0;
  // Create regexp
  let re = `\\d(?=(\\d{${opt.thousandsSize || 3}})+${opt.decimalSize > 0 ? '\\D' : '$'})`;
  re = new RegExp(re, 'g');

  // Create number
  let num = n.toFixed(Math.max(0, ~~opt.decimalSize));
  num = (opt.decimalDelimiter ? num.replace('.', opt.decimalDelimiter) : num).replace(
    re,
    `$&${opt.thousandsDelimiter || ','}`
  );

  // Currency unit
  if (opt.prepend) num = opt.prepend + num;

  return num;
};

export default currency;
