import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import x_white from './assets/x_white.png';
import posed, { PoseGroup } from 'react-pose';
import moment from 'moment/moment';

class Popup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };
    this.close = this.close.bind(this);
  }

  close() {
    if (this.props.show) {
      this.setState({ visible: false }, this.props.show);
    }
  }

  render() {
    return (
      <Fragment>
        <BackgroundPopup
          style={{
            pointerEvents: this.state.visible ? 'auto' : 'none',
            display: this.props.visible ? 'initial' : 'none'
          }}
          initialPose={'initial'}
          pose={this.props.visible ? 'visible' : 'hidden'}
          onClick={this.state.visible ? this.close : () => {}}
        />
        <PopupFrame
          initialPose={'initial'}
          style={{
            pointerEvents: this.state.visible ? 'auto' : 'none',
            display: this.props.visible ? 'initial' : 'none'
          }}
          pose={this.props.visible ? 'visible' : 'hidden'}
          color={this.props.mainColor}
          popupheight={this.props.popupheight ? this.props.popupheight : 700}
          popupwidth={this.props.popupwidth ? this.props.popupwidth : 600}
          onPoseComplete={() => {
            this.setState({ visible: this.props.visible });
          }}
        >
          <CloseIcon src={x_white} onClick={this.close} />
          <PopupTitle color={this.props.mainColor}>{this.props.title}</PopupTitle>
          <Content initialPose={'initial'} pose={this.props.visible ? 'visible' : 'hidden'}>
            {this.props.children}
          </Content>
        </PopupFrame>
      </Fragment>
    );
  }
}

export default Popup;

//region Styles

const Content = styled(
  posed.div({
    visible: {
      opacity: 1,
      delay: 300,
      transition: { duration: 500 }
    },
    hidden: { opacity: 0.0, transition: { duration: 300 } },
    initial: { opacity: 0 }
  })
)`
  width: 100%;
  height: 100%;
`;

const PopupFrame = styled(
  posed.div({
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 300 }
    },
    hidden: { scale: 0, transition: { duration: 300 } },
    initial: {
      scale: 0,
      opacity: 0,
      x: ({ popupwidth }) => -popupwidth / 2,
      y: ({ popupheight }) => -popupheight / 2
    }
  })
)`
  position: fixed;
  width: ${props => (props.popupwidth ? props.popupwidth + 'px' : '600px')};
  min-height: ${props => (props.popupheight ? props.popupheight + 'px' : '320px')};
  height: auto;
  top: calc(50% - 6rem);
  left: 50%;
  border-radius: 5px;
  background-color: #24272b;
  z-index: 101;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.7), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
  border: 1px solid;
  border-color: ${props => (props.color ? props.color : '#04d5ac')};
`;

const PopupTitle = styled.div`
  width: 100%;
  background-color: ${props => (props.color ? props.color : '#04d5ac')};
  height: 80px;
  margin: 0px;
  font-size: 22px;
  padding-top: 25px;
  letter-spacing: 0.075rem;
  text-align: center;
  font-weight: bold;
  font-family: 'Titillium Web';
`;

const CloseIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const BackgroundPopup = styled(
  posed.div({
    visible: {
      opacity: 0.9,
      transition: { duration: 100 }
    },
    hidden: { opacity: 0.0, display: 'none', transition: { duration: 300 } },
    initial: { opacity: 0 }
  })
)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
  background-color: #282b30;
  opacity: 0.7;
  z-index: 99;
`;
//endregion
