import React, { Component } from 'react';
import { defaults, Bar, Line } from 'react-chartjs-2';
import currency from 'utils/currencyFormatter';
import { backgroundColorTable, borderColorTable } from 'components/ColorPicker/index';
import formatNumberWithDots from 'utils/formatNumberWithDots';
import styled from 'styled-components';

class CpiLtvRoiChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false
    };
    this.chartRef = React.createRef();
    this.mounted = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("shouldComponentUpdate",this.state,nextState)
    //if(this.state.tooltip !== nextState.tooltip)return true;
    if (nextProps.data !== this.props.data) return true;
    return false;
  }

  componentDidMount() {
    // console.log("componentDidMount",this.state)
    this.setState({ mounted: true });
    this.mounted = true;
    //console.log('this.chartRef)',this.chartRef);
    this.props.updateChartRef(this.chartRef);
    //this.forceUpdate();
  }
  parseData(data) {
    let labels = [];
    let cpi = [];
    let ltv = [];
    let roi = [];
    let roi_dashed = [];
    let ltv_dashed = [];
    data.map(item => {
      let predictions_reliable = item[0].predictions_data.predictions_reliable;
      let roi_usable = item[0].predictions_data.roi_usable;
      let ltv_usable = item[0].predictions_data.ltv_usable;
      labels.push(item[0].date);
      cpi.push(item[0].cpi);
      if (roi_usable) {
        if (predictions_reliable) {
          roi.push(item[0].predictions_data.roi * 100);
          roi_dashed.push('No value');
        } else {
          roi.push('No value');
          roi_dashed.push(item[0].predictions_data.roi * 100);
        }
      } else {
        roi.push('No value');
        roi_dashed.push('No value');
      }
      if (ltv_usable) {
        if (predictions_reliable) {
          ltv.push(item[0].predictions_data.ltv);
          ltv_dashed.push('No value');
        } else {
          ltv.push('No value');
          ltv_dashed.push(item[0].predictions_data.ltv);
        }
      } else {
        ltv.push('No value');
        ltv_dashed.push('No value');
      }
    });

    let dataSets = [];

    dataSets.push({
      label: 'CPI',
      type: 'line',
      hidden: false,
      fill: false,
      borderWidth: 2,
      borderColor: borderColorTable[0],
      backgroundColor: backgroundColorTable[0],
      data: cpi,
      yAxisID: 'y-axis-1'
    });

    dataSets.push({
      label: 'LTV',
      type: 'line',
      hidden: false,
      fill: false,
      borderWidth: 2,
      borderColor: borderColorTable[1],
      backgroundColor: backgroundColorTable[1],
      data: ltv,
      yAxisID: 'y-axis-1'
    });

    dataSets.push({
      label: 'LTV UNRELIABLE',
      type: 'line',
      hidden: false,
      fill: false,
      borderWidth: 2,
      borderColor: borderColorTable[1],
      backgroundColor: backgroundColorTable[1],
      borderDash: [5, 10],
      data: ltv_dashed,
      yAxisID: 'y-axis-1'
    });

    dataSets.push({
      label: 'ROI',
      type: 'line',
      hidden: false,
      fill: false,
      borderWidth: 2,
      borderColor: borderColorTable[2],
      backgroundColor: backgroundColorTable[2],
      data: roi,
      yAxisID: 'y-axis-2'
    });

    dataSets.push({
      label: 'ROI UNRELIABLE',
      type: 'line',
      hidden: false,
      fill: false,
      borderWidth: 2,
      borderColor: borderColorTable[2],
      backgroundColor: backgroundColorTable[2],
      borderDash: [5, 10],
      data: roi_dashed,
      yAxisID: 'y-axis-2'
    });

    return {
      labels: labels,
      datasets: dataSets
    };
  }
  render() {
    let data = this.parseData(this.props.data);
    let dt;
    dt = Object.assign({}, data);
    let options = {
      title: {
        display: false,
        text: ''
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          fontColor: '#A9A9AB'
        }
      },
      tooltips: {
        enabled: false,
        labelColors: backgroundColorTable,
        xPadding: 10,
        yPadding: 10,
        yAlign: 'top',
        position: 'nearest',
        intersect: false,
        mode: 'label',
        custom: tooltipModel => {
          this.props.showTooltip(tooltipModel);
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      elements: { point: { radius: 0.04, hitRadius: 0, hoverRadius: 0.04 } },
      scales: {
        xAxes: [
          {
            gridLines: {
              drawBorder: true,
              color: '#2f3238'
            }
          }
        ],
        yAxes: [
          {
            stacked: false,
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-1',
            scaleLabel: {
              display: true,
              labelString: ''
            },

            gridLines: {
              drawBorder: true,
              color: '#2f3238'
            },
            labels: {
              show: true
            },

            ticks: {
              min: 0,
              beginAtZero: true,
              callback: function(label, index, labels) {
                let new_label = formatNumberWithDots(label);
                return '$' + new_label;
              }
            }
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-2',
            scaleLabel: {
              display: true,
              labelString: 'ROI'
            },
            gridLines: {
              display: false
            },
            labels: {
              show: true
            },
            ticks: {
              min: 0,
              suggestedMax: 200,
              beginAtZero: true,
              callback: function(label, index, labels) {
                return formatNumberWithDots(label) + '%';
              }
            }
          }
        ]
      }
    };

    return <Line ref={this.chartRef} data={dt} options={options} />;
  }
}

export default CpiLtvRoiChart;
