import React, { Component } from 'react';
import styled from 'styled-components';
import invert from './assets/invert.svg';

export const InvertTooltip = styled.div`
  display: none;
  position: absolute;
  right: -50px;
  bottom: 15px;
  width: 130px;
  cursor: auto;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  text-align: center;
  background: #282b30;
  padding: 6px;
  padding-top: 8px;
  border-radius: 3px;
  border: #00d5a9 solid 0.5px;
  color: #00d5a9;
  opacity: 0;
  z-index: 20;
`;

const InvertButton = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  cursor: pointer;
  &:hover ${InvertTooltip} {
    opacity: 1;
    display: block;
  }
`;

const InvertComponent = styled.div`
  position: relative;
`;

class InvertBtn extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <InvertComponent>
        <InvertButton>
          <InvertTooltip>
            <span>Invert selection</span>
          </InvertTooltip>
          <img src={invert} onClick={this.props.reverse} />
        </InvertButton>
      </InvertComponent>
    );
  }
}

export default InvertBtn;
