import React, { Component } from 'react';

const Authorization = privilegesNeeded => WrappedComponent => {
  return class WithAuthorization extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      const { privileges } = this.props.user;

      for (let privilege of privileges) {
        if (privilegesNeeded.includes(privilege)) {
          return <WrappedComponent {...this.props} user={this.props.user} />;
        }
      }
      return <WrappedComponent {...this.props} user={this.props.user} />;
    }
  };
};

export default Authorization;
