import React, { Component } from 'react';
import styled, { keyframes, css } from 'styled-components';

class SmallLoader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <SmallLoaderContainer>
        <Loader rotate={'true'} />
      </SmallLoaderContainer>
    );
  }
}

export default SmallLoader;

const roller = keyframes`
to { transform:rotate(360deg);}
`;

const animation = props => css`
  ${roller} 0.9s linear infinite;
`;

const Loader = styled.div`
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #db46b9;
  border-top-color: #00d5a9;
  border-right-color: #5ca8c9;
  border-bottom-color: #f37927;
  animation: ${animation};
`;

const SmallLoaderContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 20px;
  height: 20px;
`;
