import React, { Component } from 'react';
import TitleBar from 'components/TitleBar';
import './style.css';

class NotImpelmented extends Component {
  render() {
    return <TitleBar title={this.props.title} color={this.props.color} />;
  }
}

export default NotImpelmented;
