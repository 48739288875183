import React, { Component, Fragment } from 'react';

import './style.css';
import styled from 'styled-components';
import storage from 'utils/storage';

//style={{ background: this.props.color }}

const SubMenuItemBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 6rem;
  opacity: 0;
  background: #020101;
  box-sizing: border-box;
`;
const SubMenuItem = styled.div`
  display: flex;
  width: 100%;
  height: 6rem;
  background: ${props => props.color};
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;

  &:hover ${SubMenuItemBackground} {
    opacity: 0.5;
  }
  //border-bottom: 2px solid rgba(41,41,41,0.3);
`;

const Button = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  width: 6rem;
  height: 6rem;
  background: #020101;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  max-width: 400px;
  transition: 0.25s linear;
   opacity: 0;
    z-index: 3;
    &:hover {
     // opacity: 0.0;
      //transition-delay: 0.25s;
      //transition-duration: 0.0s ;
    
     //width: calc(${props => props.width} + 6rem);
  }

`;

const Selected = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  cursor: pointer;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 10px solid ${props => props.color};
  margin-left: 6rem;
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 0rem;
  height: 6rem;
  left: 6rem;
  right: 0;
  cursor: pointer;
  background: ${props => props.color};
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: end;
  justify-self: end;
  z-index: 1;
  opacity: 0;
`;

const SubMenuItemText = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 6rem;
  background: ${props => props.color};
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;

  font-family: 'Titillium Web';
  font-size: 1.4rem;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
`;

const Image = styled.img`
  display: block;
  box-sizing: border-box;
  transform: scale(0.9);
  opacity: 0.9;
  transition: 0.25s linear;
  z-index: 3;
  pointer-events: none;
`;

const Text = styled.div`
  width: 100%;
  margin-left: 8rem;
  text-align: left;
  font-family: 'Titillium Web';
  font-size: 1.4rem;
  font-weight: 600;
  opacity: 0;
  width: ${props => props.width};
  overflow: hidden;
  white-space: nowrap;
  z-index: 4;
`;

const Container = styled.div`
  display: flex;
  width: 6rem;
  height: 6rem;
  cursor: pointer;
  background: ${props => props.color};
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
 
  &:hover ${SubMenuItem} {
    opacity: 1;
    transition-delay: 0.1s;
    transition-duration: 0.25s ;
  }

 
 &:hover ${SubMenu} {
    opacity: 1;
    width: ${props => props.width};
    height: ${props => props.count};
    transition-duration: 0.15s ;
    
  }
  &:hover ${Text} {
    //opacity: 1;
    //width: ${props => props.width};

   // transition-duration: 0.55s;
    //transition-delay: 0.9s;
    //transition-timing-function: linear;
  }
  &:hover ${Image} {
    opacity: 1;
    transform: scale(1);
    transition: 0.25s linear;
  }
`;

/*
.menuBarBtnTxt {
  width: 10rem;
  justify-self: right;
}

.menuBarBtnTxt p {
  text-align: left;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
}

 */

class MenuBarButton extends Component {
  constructor(props) {
    super(props);
    this.backHandler = this.backHandler.bind(this);
    this.menuItemClickHandler = this.menuItemClickHandler.bind(this);
  }

  menuItemClickHandler(item_path) {
    let path = this.props.history.location.pathname;
    if (this.props.target === 'back') {
      if (path.includes('/kpi/game/')) {
        this.props.history.push({
          pathname: '/kpi'
        });

        return;
      } else if (path.includes('/external/game/')) {
        this.props.history.push({
          pathname: '/external'
        });
        return;
      } else if (path.includes('/admin/groups')) {
        this.props.history.push({
          pathname: '/admin'
        });
        return;
      } else if (path.includes('/admin/games/groups')) {
        this.props.history.push({
          pathname: '/admin'
        });
        return;
      } else if (path.includes('/acquisition/game/')) {
        this.props.history.push({
          pathname: '/acquisition'
        });
        return;
      } else if (path.includes('/gms/games/add')) {
        this.props.history.push({
          pathname: '/gms/games/'
        });
        return;
      } else if (path.includes('/gms/profiles/add')) {
        this.props.history.push({
          pathname: '/gms/profiles/'
        });
        return;
      } else if (path.includes('/gms/data/add')) {
        this.props.history.push({
          pathname: '/gms/data/'
        });
        return;
      } else if (path.includes('/gms/platforms/add')) {
        this.props.history.push({
          pathname: '/gms/platforms/'
        });
        return;
      } else if (path.includes('/gms/games/edit')) {
        this.props.history.push({
          pathname: '/gms/games/'
        });
        return;
      } else if (path.includes('/gms/profiles/edit')) {
        this.props.history.push({
          pathname: '/gms/profiles/'
        });
        return;
      } else if (path.includes('/gms/data/edit')) {
        this.props.history.push({
          pathname: '/gms/data/'
        });
        return;
      } else if (path.includes('/gms/platforms/edit')) {
        this.props.history.push({
          pathname: '/gms/platforms/'
        });
        return;
      }

      this.props.history.push({
        pathname: '/',
        search: '',
        state: {}
      });
    } else
      this.props.history.push({
        pathname: item_path,
        search: '',
        state: {}
      }); //`${this.props.target}/roster/${f.nr}` ; //this.props.target
  }

  backHandler() {
    let path = this.props.history.location.pathname;
    if (this.props.target === 'back') {
      if (path.includes('/kpi/game/')) {
        this.props.history.push({
          pathname: '/kpi'
        });

        return;
      } else if (path.includes('/external/game/')) {
        this.props.history.push({
          pathname: '/external'
        });
        return;
      } else if (path.includes('/admin/groups')) {
        this.props.history.push({
          pathname: '/admin'
        });
        return;
      } else if (path.includes('/admin/user/')) {
        this.props.history.push({
          pathname: '/admin'
        });
        return;
      } else if (path.includes('/admin/games/groups')) {
        this.props.history.push({
          pathname: '/admin'
        });
        return;
      } else if (path.includes('/acquisition/game/')) {
        this.props.history.push({
          pathname: '/acquisition'
        });
        return;
      } else if (path.includes('/gms/games/add')) {
        this.props.history.push({
          pathname: '/gms/games/'
        });
        return;
      } else if (path.includes('/gms/profiles/add')) {
        this.props.history.push({
          pathname: '/gms/profiles/'
        });
        return;
      } else if (path.includes('/gms/data/add')) {
        this.props.history.push({
          pathname: '/gms/data/'
        });
        return;
      } else if (path.includes('/gms/platforms/add')) {
        this.props.history.push({
          pathname: '/gms/platforms/'
        });
        return;
      } else if (path.includes('/gms/games/edit')) {
        this.props.history.push({
          pathname: '/gms/games/'
        });
        return;
      } else if (path.includes('/gms/profiles/edit')) {
        this.props.history.push({
          pathname: '/gms/profiles/'
        });
        return;
      } else if (path.includes('/gms/data/edit')) {
        this.props.history.push({
          pathname: '/gms/data/'
        });
        return;
      } else if (path.includes('/gms/platforms/edit')) {
        this.props.history.push({
          pathname: '/gms/platforms/'
        });
        return;
      } else if (path.includes('/playignite/game')) {
        this.props.history.push({
          pathname: '/playignite/'
        });
        return;
      }
      this.props.history.push({
        pathname: '/',
        search: '',
        state: {}
      });
    }
    //this.props.history.goBack()
    else
      this.props.history.push({
        pathname: this.props.target,
        search: '',
        state: {}
      }); //`${this.props.target}/roster/${f.nr}` ; //this.props.target
  }

  render() {
    const textLength = this.props.text.length < 15 ? 176 : 80 + this.props.text.length * 6 + 22;

    let items = [];
    Object.entries(this.props.submenu).map(([k, v]) => {
      if ((v.priv && storage.USER.privileges.includes(v.priv)) || !v.priv)
        items.push(
          <Fragment key={`${this.props.text}-${k}`}>
            <SubMenuItem
              color={v.color}
              onClick={() => {
                this.menuItemClickHandler(v.path);
              }}
            >
              <SubMenuItemBackground />
              <SubMenuItemText>{k}</SubMenuItemText>
            </SubMenuItem>
          </Fragment>
        );
    });
    /*
    {Object.keys(this.props.submenu).length > 0 && (
          <SubMenu color={this.props.color} width={textLength + 'px'}>
            {Object.entries(this.props.submenu).map(([k, v]) => {
              console.log(v.priv)
            return (
              <Fragment key={`${this.props.text}-${k}`}>
                <SubMenuItem
                  color={v.color}
                  onClick={() => {
                    this.menuItemClickHandler(v.path);
                  }}
                >
                  <SubMenuItemBackground />
                  <SubMenuItemText>{k}</SubMenuItemText>
                </SubMenuItem>
              </Fragment>
            )})}
          </SubMenu>
        )}
     */

    let count = items.length * 6; // Object.keys(this.props.submenu).length * 6;
    return (
      <Container
        selected={this.props.selected}
        color={this.props.color}
        width={textLength + 'px'}
        count={count + 'rem'}
        //className="menuBarContainer"
      >
        {this.props.selected && <Selected color={this.props.color} />}

        <SubMenu color={this.props.color} width={textLength + 'px'}>
          {items}
        </SubMenu>

        <Button
          width={textLength + 'px'}
          onClick={() => {
            this.backHandler();
          }}
          selected={this.props.selected}
          color={this.props.color}
        >
          <Text width={textLength + 'px'}>{this.props.text}</Text>
        </Button>
        {this.props.image && <Image src={this.props.image} alt="game_logo" />}
        {!this.props.image && <div className="menuBarBackBtn">&lsaquo;</div>}
      </Container>
    );
  }
}

export default MenuBarButton;
