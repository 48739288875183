import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

class ChartLegend extends Component {
  constructor(props) {
    super(props);
    this.handleLabelClick = this.handleLabelClick.bind(this);
    this.handleUnreliableClick = this.handleUnreliableClick.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.chart !== this.props.chart) return true;
    return false;
  }

  handleLabelClick(meta, chart) {
    meta.hidden = meta.hidden === null ? !meta.hidden : null;
    chart.update();
    this.forceUpdate();
  }

  handleUnreliableClick(meta, nextMeta, chart) {
    meta.hidden = meta.hidden === null ? !meta.hidden : null;
    nextMeta.hidden = nextMeta.hidden === null ? !nextMeta.hidden : null;
    chart.update();
    this.forceUpdate();
  }

  render() {
    return (
      <Panel>
        <LabelTitle>{this.props.title}</LabelTitle>
        <LegendRow>
          <LegendPanel
            chart={this.props.chart}
            mark={this.props.mark}
            handleClick={this.handleLabelClick}
            handleUnreliableClick={this.handleUnreliableClick}
          />
        </LegendRow>
      </Panel>
    );
  }
}

const LegendPanel = ({ chart, mark, handleClick, handleUnreliableClick }) => (
  <Fragment>
    {chart.data.datasets.map((item, index) => {
      let meta = chart.getDatasetMeta(index);
      if (index >= 0 && !item.label.endsWith('UNRELIABLE')) {
        let nextItem = chart.data.datasets[index + 1];
        if (
          nextItem != undefined &&
          nextItem.label.startsWith(item.label) &&
          nextItem.label.endsWith('UNRELIABLE')
        ) {
          let nextMeta = chart.getDatasetMeta(index + 1);
          return (
            <LegendItem
              key={index}
              isHidden={meta.hidden}
              onClick={() => handleUnreliableClick(meta, nextMeta, chart)}
            >
              <LabelMark color={item.borderColor}>
                {mark === 'dot' ? <span>&#x25CF;</span> : <span>&#x25A0;</span>}
              </LabelMark>
              {item.label}
            </LegendItem>
          );
        } else {
          return (
            <LegendItem key={index} isHidden={meta.hidden} onClick={() => handleClick(meta, chart)}>
              <LabelMark color={item.borderColor}>
                {mark === 'dot' ? <span>&#x25CF;</span> : <span>&#x25A0;</span>}
              </LabelMark>
              {item.label}
            </LegendItem>
          );
        }
      }
    })}
  </Fragment>
);

export default ChartLegend;
/** Chart Legend Styles */
//region Styles
const Panel = styled.div`
  display: flex;
  //grid-template-columns: 5fr 10rem 60rem;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  color: #99999c;
  margin-top: 10px;
  font-size: 12px;
  cursor: pointer;
`;

const LegendRow = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const LegendItem = styled.span`
  display: block;
  float: left;
  padding-right: 30px;
  padding-bottom: 5px;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  color: ${props => props.color};
  text-decoration: ${props => (props.isHidden ? 'line-through' : 'none')};
`;

const LabelTitle = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: normal;
  float: left;
  color: #66676b;
  margin: 0px;
  margin-top: 2px;
  // padding-right: 17px;
  grid-column: 2/3;
  grid-row: 2;
`;

const LabelMark = styled.span`
  padding: 2px;
  color: ${props => props.color};
  cursor: default;
`;
//endregion
