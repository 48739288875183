import React, { Component, PureComponent } from 'react';
import AnimatedView from 'components/AnimatedView';
import AnimatedList from 'components/AnimatedList';
import GroupsList from './GroupsList';
import UsersTable from './UsersTable';
import edit from './assets/edit.png';
import Textfield from 'components/Textfield';
import UserPopup from './UserPopup';
import LoadingIndicator from 'components/LoadingIndicator';
import { withRouter } from 'react-router-dom';
import storage from 'utils/storage';
import './style.css';
import styled from 'styled-components';
import CommonButton from 'components/CommonButton/index';
import DropdownList from 'components/DropdownList';
import DropdownList2 from 'components/DropdownList2';
import { AdminContext } from 'views/App/Admin/';
import AnimatedMenu from '../../../../components/AnimatedMenu';

class AdminMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editUser: null,
      deleteUser: null,
      usersData: null,
      groupsData: null,
      gameGroupsData: null,
      groupsDropdown: [],
      gamesGroupsDropdown: [],
      permissionDropdown: [],
      privilData: null,
      markedItem: null,
      isFetching: false,
      isMenuOpen: false,
      isEditingUser: false,
      isAddingUser: false,
      isDeletingUser: false,
      searchMode: false,
      searchUser: '',
      searchedUsersList: '',
      selectedPermission: 'all',
      isReset: false,
      isLoading: false,
      isSent: false
    };
    this.mounted = false;
    this.onDataReceived = this.onDataReceived.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.showAddUser = this.showAddUser.bind(this);
    this.hideAddUser = this.hideAddUser.bind(this);
    this.notHideAddUser = this.notHideAddUser.bind(this);
    this.addNewUser = this.addNewUser.bind(this);
    this.addMoreNewUser = this.addMoreNewUser.bind(this);
    this.editUserData = this.editUserData.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.manageGroups = this.manageGroups.bind(this);
    this.manageGamesGroups = this.manageGamesGroups.bind(this);
    this.filterUsersByFullName = this.filterUsersByFullName.bind(this);
    this.selectPermission = this.selectPermission.bind(this);
    //this.addUserSubmitHandler = this.addUserSubmitHandler.bind(this);
    this.handleResetPasswordPopup = this.handleResetPasswordPopup.bind(this);
    this.handleNoReset = this.handleNoReset.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);

    this.handleRowOver = this.handleRowOver.bind(this);
    this.handleRowOut = this.handleRowOut.bind(this);
    this.deleteUserHandler = this.deleteUserHandler.bind(this);
    this.editUserHandler = this.editUserHandler.bind(this);
  }

  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    this.fetchData();
  }
  shouldComponentUpdate(nextProps, nextState) {
    let update = false;
    Object.entries(this.state).forEach(([key, value]) => {
      if (value !== nextState[key]) update = true;
      //console.log(key, value === nextState[key]);
    });

    return update;
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.privilegesUpdated) this.fetchData();
    else
      this.setState({
        isFetching: false
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onDataReceived(receivedData) {
    if (receivedData.failed) {
      //if(receivedData.updatedToken==null)
      this.props.updatePrivCall(false);

      return;
    } else {
      if (receivedData.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }

    const groupsData = {};

    receivedData.groupsData.response_message.groups.map(item => {
      groupsData[item.id] = item;
    });

    const groupsList = [];
    const permissionGroups = [];
    groupsList.push({ key: 'all', value: 'All groups' });
    Object.entries(receivedData.groupsData.response_message.groups).map(([key, val]) => {
      let groups_item = {};
      groups_item['key'] = val.id;
      groups_item['value'] = val.name;
      groups_item['color'] = val.color;
      groupsList.push(groups_item);
      let permission_group = {};
      permission_group['key'] = val.id;
      permission_group['value'] = val.name;
      permission_group['color'] = val.color;
      permissionGroups.push(permission_group);
    });

    groupsList.sort((a, b) => {
      if (a['value'].toLowerCase() < b['value'].toLowerCase()) return -1;

      if (a['value'].toLowerCase() > b['value'].toLowerCase()) return 1;

      return 0;
    });

    permissionGroups.sort((a, b) => {
      if (a['value'].toLowerCase() < b['value'].toLowerCase()) return -1;

      if (a['value'].toLowerCase() > b['value'].toLowerCase()) return 1;

      return 0;
    });

    const gamesGroupsList = [];
    const gamesGroupsData = [];
    Object.entries(receivedData.gameGroupsData.response_message.groups).map(([key, val]) => {
      let group_item = {};
      group_item['key'] = val.group_id;
      group_item['value'] = val.name;
      gamesGroupsList.push(group_item);

      gamesGroupsData[val.group_id] = val;
    });

    gamesGroupsList.push({ key: 'none', value: 'None' });

    gamesGroupsList.sort((a, b) => {
      if (a['value'].toLowerCase() < b['value'].toLowerCase()) return -1;

      if (a['value'].toLowerCase() > b['value'].toLowerCase()) return 1;

      return 0;
    });

    const usersData = {};

    receivedData.usersData.response_message.users.map(item => {
      usersData[item.id] = item;
    });

    const privilegesList = {};
    const privilegesCategories = {};

    Object.entries(receivedData.priviligiesData.response_message.sections).map(([k, v]) => {
      privilegesCategories[k] = v;
    });

    receivedData.priviligiesData.response_message.roles.map(item => {
      privilegesList[item.id] = item;
    });

    let privilegesData = { privileges: privilegesList, categories: privilegesCategories };

    if (!this.mounted) return;

    this.setState({
      isFetching: false,
      usersData: usersData,
      groupsData: groupsData,
      gameGroupsData: gamesGroupsData,
      privilData: privilegesData,
      groupsDropdown: groupsList,
      gamesGroupsDropdown: gamesGroupsList,
      permissionDropdown: permissionGroups
    });
  }

  handleGroupChange(group, value) {
    const state = {};
    state[group] = value;
    this.setState(state);
  }

  handleResetPassword(email) {
    this.setState({ isFetching: true });
    const PATH_BASE = storage.ADMIN_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.240.58:5000';
    const PATH_CALL = '/users/reset';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let payload = {
      email: email
    };

    return fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) storage.USER.token = token;
        if (status === 200) {
          this.setState({ isFetching: false, isSent: true });
          return json;
        } else {
          this.setState({ isFetching: false });
          this.handleNoReset();
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        return json;
      })
      .catch(error => {
        console.error(error);
        this.handleNoReset();
      });
  }

  handleResetPasswordPopup(item) {
    this.setState({ isEditingUser: true, isMenuOpen: true, editUser: item, isReset: true });
  }
  handleNoReset() {
    this.setState({ isEditingUser: false, isMenuOpen: false, isReset: false, isSent: false });
  }

  fetchData() {
    this.setState({ isFetching: true });
    //setTimeout(this.onDataReceived, 2000);

    const PATH_BASE = storage.ADMIN_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.240.58:5000';
    const PATH_CALL = '/users/list';
    const PATH_CALL2 = '/groups/list';
    const PATH_CALL3 = '/roles/list';
    const PATH_CALL4 = '/game_groups/list';
    const url = `${PATH_BASE}${PATH_CALL}`;
    const url2 = `${PATH_BASE}${PATH_CALL2}`;
    const url3 = `${PATH_BASE}${PATH_CALL3}`;
    const url4 = `${PATH_BASE}${PATH_CALL4}`;

    this.getUsersData(url)
      .catch(error => {
        console.error('catch3 error: ', error);
      })
      .then(usersData => this.getGroupsData(url2, usersData))
      .then(prevData => this.getPriviligesData(url3, prevData))
      .then(prevData => this.getGameGroupsData(url4, prevData))
      .then(finalData => this.onDataReceived(finalData));
    //this.getUsersData(url).then((response) => this.onDataReceived(response));
  }

  getUsersData(url) {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.usersData = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();

        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        data.usersData = json;
        return data;
      });
  }

  getGroupsData(url, prevData) {
    if (prevData.failed) {
      prevData.groupsData = null;
      return prevData;
    }
    let headers = {
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(json => {
        prevData.groupsData = json;
        return prevData;
      })
      .catch(error => {
        console.error(error);
      });
  }

  getPriviligesData(url, prevData) {
    if (prevData.failed) {
      prevData.priviligiesData = null;
      return prevData;
    }

    let headers = {
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(json => {
        prevData.priviligiesData = json;
        return prevData;
      })
      .catch(error => {
        console.error(error);
      });
  }

  getGameGroupsData(url, prevData) {
    if (prevData.failed) {
      prevData.gameGroupsData = null;
      return prevData;
    }
    let headers = {
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(json => {
        prevData.gameGroupsData = json;
        return prevData;
      })
      .catch(error => {
        console.error(error);
      });
  }

  showAddUser(permissionList, gameGroupsData) {
    {
      /*
    this.setState({
      isAddingUser: true,
      isMenuOpen: true,
      searchMode: false,
      searchUser: ''
    }); */
    }

    this.props.history.push({
      pathname: '/admin/user/:add',
      state: { permissionList, gameGroupsData }
    });
  }

  hideAddUser() {
    this.setState({
      isEditingUser: false,
      isAddingUser: false,
      isDeletingUser: false,
      searchMode: false,
      searchUser: '',
      isMenuOpen: false
    });
  }

  notHideAddUser() {
    this.setState({
      isEditingUser: false,
      isAddingUser: true,
      isDeletingUser: false,
      searchMode: false,
      searchUser: '',
      isMenuOpen: true
    });
  }

  addNewUser(data) {
    const id = 'id' + Math.random() * 10;
    const PATH_BASE = storage.ADMIN_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.240.58:5000';
    const PATH_CALL = '/users/add';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };

    let payload = {
      email: data.email,
      first_name: data.firstname,
      last_name: data.lastname,
      password: 'test',
      auth_groups: data.groups
    };

    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) storage.USER.token = token;
        if (status === 200) return json;
        else {
          this.hideAddUser();
          this.props.updatePrivCall(true);
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        this.hideAddUser();
        this.fetchData();
        return json;
      })
      .catch(error => {
        console.error(error);
        this.hideAddUser();
      });
  }

  addMoreNewUser(data) {
    const id = 'id' + Math.random() * 10;
    const PATH_BASE = storage.ADMIN_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.240.58:5000';
    const PATH_CALL = '/users/add';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };

    let payload = {
      email: data.email,
      first_name: data.firstname,
      last_name: data.lastname,
      password: 'test',
      auth_groups: data.groups
    };

    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) storage.USER.token = token;
        if (status === 200) return json;
        else {
          this.notHideAddUser();
          this.props.updatePrivCall(true);
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        this.notHideAddUser();
        this.fetchData();
        return json;
      })
      .catch(error => {
        console.error(error);
        this.hideAddUser();
      });
  }

  deleteUser() {
    const PATH_BASE = storage.ADMIN_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.240.58:5000';
    const PATH_CALL = '/users/delete';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };

    let payload = {
      user_id: this.state.deleteUser.id
    };

    return fetch(url, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) storage.USER.token = token;
        if (status === 200) return json;
        else {
          this.hideAddUser();
          this.props.updatePrivCall(true);
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;

        this.hideAddUser();
        this.fetchData();
        return json;
      })
      .catch(error => {
        console.error(error);
        this.hideAddUser();
      });
  }

  editUserData(data) {
    let add_groups = [];
    let remove_groups = [];
    let current_groups = this.state.usersData[data.id].auth_user_groups;

    data.groups.forEach(item => {
      if (!current_groups.includes(item)) add_groups.push(item);
    });
    current_groups.forEach(item => {
      if (!data.groups.includes(item)) remove_groups.push(item);
    });

    const PATH_BASE = storage.ADMIN_SERVICE_URL[process.env.REACT_APP_BACKEND]; //'http://35.197.240.58:5000';
    const PATH_CALL = '/users/update';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };

    let payload = {
      user_id: data.id,
      email: data.email,
      first_name: data.firstname,
      last_name: data.lastname,
      add_groups: add_groups,
      remove_groups: remove_groups,
      status: true
    };

    return fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) storage.USER.token = token;
        if (status === 200) return json;
        else {
          this.hideAddUser();
          this.props.updatePrivCall(true);
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        this.hideAddUser();
        this.fetchData();
        return json;
      })
      .catch(error => {
        console.error(error);
        this.hideAddUser();
        //this.fetchData();
      });
  }

  handleRowClick(item) {
    //this.setState({ markedItem: item });
  }

  handleRowOver(item) {
    let newstate = { ...this.state };
    newstate.markedItem = item;
    this.setState(newstate);
  }

  handleRowOut() {
    this.setState({ markedItem: null });
  }

  deleteUserHandler(item) {
    this.setState({ isDeletingUser: true, isMenuOpen: true, deleteUser: item });
  }

  editUserHandler(item, permissionList, gameGroupsData) {
    this.setState({ isEditingUser: true, isMenuOpen: true, editUser: item });
    this.props.history.push({
      pathname: `/admin/user/:edit/:${item.id}`,
      state: { item, permissionList, gameGroupsData }
    });
  }

  manageGroups() {
    this.props.history.push({
      pathname: 'admin/groups',
      search: '',
      state: { privilegesData: this.state.privilData }
    }); //`${this.props.target}/roster/${f.nr}` ; //this.props.target
  }
  manageGamesGroups() {
    this.props.history.push({
      pathname: 'admin/games/groups',
      search: '',
      state: { privilegesData: this.state.privilData }
    }); //`${this.props.target}/roster/${f.nr}` ; //this.props.target
  }

  filterUsersByFullName(event) {
    const target = event.target;
    const value = target.value;
    let searchTerm = value;
    const isSearched = searchTerm => item =>
      !searchTerm || item.toLowerCase().includes(searchTerm.toLowerCase());
    this.setState({
      searchUser: searchTerm
    });

    if (searchTerm !== '') {
      this.setState({
        searchMode: true
      });
    } else if (searchTerm === '') {
      this.setState({ searchMode: false });
    }
    let items_filtered = [];

    for (let key in this.state.usersData) {
      if (this.state.usersData[key]['name'].toLowerCase().includes(searchTerm.toLowerCase())) {
        items_filtered[key] = this.state.usersData[key];
      }

      this.setState({
        searchedUsersList: items_filtered
      });
    }
  }

  selectPermission(event) {
    const target = event.target;
    const value = target.value;

    let items_filtered = [];
    if (value == 'all') {
      this.setState({
        selectedPermission: value
      });
    } else {
      let currentPerm = parseInt(value);
      for (let key in this.state.usersData) {
        if (this.state.usersData[key]['auth_user_groups'].includes(currentPerm)) {
          items_filtered[key] = this.state.usersData[key];
        }
      }
      this.setState({
        selectedPermission: currentPerm
      });
    }
  }

  renderGroup(group) {
    const items = [];

    group.permissions.forEach(item => {
      items.push(
        <li key={`row-data-${group.id}-${item}`}>
          <GroupsItems>
            <CheckMark color={group.color}>&#x2714;</CheckMark>
            <PrivilageItem> {this.state.privilData.privileges[item].name}</PrivilageItem>
          </GroupsItems>
        </li>
      );
    });
    const rows = [
      <div key={`grp-data-${group.name}`}>
        <GroupTitle>
          <ColorGroup color={group.color}>{group.name}</ColorGroup>
        </GroupTitle>
        <PermissionList>{items}</PermissionList>
      </div>
    ];

    return rows;
  }

  renderGamesGroup(group) {
    const items = [];
    group.games.forEach(item => {
      items.push(
        <li key={`row-data-games-${group.id}-${item}`}>
          <GroupsItems>
            <DotMark color={'#2beeae'}>&#x2022;</DotMark>
            <PrivilageItem> {storage.GAMES_NAMES[item]}</PrivilageItem>
          </GroupsItems>
        </li>
      );
    });
    const rows = [
      <div key={`grp-data-games-${group.name}`}>
        <GroupTitle>
          <ColorGroup color={'#ebebeb'}>{group.name}</ColorGroup>
        </GroupTitle>
        <PermissionList>{items}</PermissionList>
      </div>
    ];

    return rows;
  }

  render() {
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }

    if (
      !this.state.usersData ||
      !this.state.groupsData ||
      !storage.USER.privileges.includes('view_admin_panel')
    ) {
      return (
        <div className="fetchDataerrorMessage">
          <h3>Oops!</h3>
          <p>
            You don't have permissions to see this view or there is no data available at the moment.
            Please try Again!
          </p>
        </div>
      );
    }
    let allGroupsRows = [];

    for (let key in this.state.groupsData) {
      const perGroupRows = this.renderGroup(this.state.groupsData[key]);
      allGroupsRows = allGroupsRows.concat(perGroupRows);
    }

    let allGamesGroupsRows = [];

    for (let key in this.state.gameGroupsData) {
      if (!this.state.gameGroupsData[key].system_group) {
        const perGroupRows = this.renderGamesGroup(this.state.gameGroupsData[key]);
        allGamesGroupsRows = allGamesGroupsRows.concat(perGroupRows);
      }
    }

    return (
      <AdminContext.Consumer>
        {AdminState => {
          //console.log('AdminState', AdminState);
          return (
            <AnimatedView animKey={'AnimatedViewKeyAdmminMain'}>
              <AdminContainer key="AdminMAinKey">
                <ViewTile>{'Users'}</ViewTile>
                <AdminContent>
                  <FilterTable>
                    <form>
                      <Textfield
                        type="text"
                        onChange={this.filterUsersByFullName}
                        placeholder="Filter by full name"
                        value={this.state.searchUser}
                        displayLabel={false}
                      />
                    </form>
                  </FilterTable>
                  <GroupPicker>
                    <DropdownContainer>
                      {/*  <DropdownList
                        items={this.state.groupsDropdown}
                        name="groupsDropdown"
                        id="groupsPermissions"
                        uppercase={false}
                        handler={this.selectPermission}
                        currentOpt={this.state.selectedPermission}
                        kvPairs={true}
                      /> */}
                      <DropdownList2
                        name="groupsDropdown"
                        id="groupsPermissions"
                        currentOption={this.state.selectedPermission}
                        items={this.state.groupsDropdown}
                        handler={this.selectPermission}
                      />
                    </DropdownContainer>
                  </GroupPicker>
                  <AddUser>
                    <AddUserContainer>
                      {storage.USER.privileges.includes('add_users') ? (
                        <CommonButton
                          text="+ ADD USER"
                          onClick={() => {
                            this.showAddUser(
                              this.state.permissionDropdown,
                              this.state.gamesGroupsDropdown
                            );
                          }}
                        />
                      ) : null}
                    </AddUserContainer>
                  </AddUser>
                  <ManageGroups>
                    <ManageGroupsButton
                      callBack={this.manageGroups}
                      isMenuOpen={this.state.isMenuOpen}
                    />
                  </ManageGroups>
                  <ManageGameGroups>
                    <ManageGamesGroupsButton
                      callBack={this.manageGamesGroups}
                      isMenuOpen={this.state.isMenuOpen}
                    />
                  </ManageGameGroups>
                  <UsersTableContainer>
                    <UsersTable
                      searchMode={this.state.searchMode}
                      usersData={this.state.usersData}
                      markedItem={this.state.markedItem}
                      groupsData={this.state.groupsData}
                      searchedUsersList={this.state.searchedUsersList}
                      selectedPermission={this.state.selectedPermission}
                      permissionDropdown={this.state.permissionDropdown}
                      gamesGroupsDropdown={this.state.gamesGroupsDropdown}
                      gameGroupsData={this.state.gameGroupsData}
                      handleRowOver={this.handleRowOver}
                      handleRowOut={this.handleRowOut}
                      handleRowClick2={this.handleRowClick2}
                      editUserHandler={this.editUserHandler}
                      handleResetPasswordPopup={this.handleResetPasswordPopup}
                      deleteUserHandler={this.deleteUserHandler}
                    />
                  </UsersTableContainer>
                  <GroupsTableContainer key={'GroupsTableContainerKey'}>
                    <GroupsList
                      key={'groupTableKey'}
                      title={'Group Permissions'}
                      itemRows={allGroupsRows}
                    />
                  </GroupsTableContainer>
                  <GamesGroupsTableContainer>
                    <GroupsList
                      key={'GamesGroupsTableKey'}
                      title={'Game Groups'}
                      itemRows={allGamesGroupsRows}
                    />
                  </GamesGroupsTableContainer>
                </AdminContent>
                <UserPopup
                  key="am3"
                  type={'delete'}
                  userData={this.state.deleteUser}
                  groupsData={this.state.groupsData}
                  closeCallBack={this.hideAddUser}
                  submitCallBack={this.deleteUser}
                  visible={this.state.isDeletingUser}
                />
                <UserPopup
                  key="am2"
                  type={'edit'}
                  userData={this.state.editUser}
                  groupsData={this.state.groupsData}
                  closeCallBack={this.hideAddUser}
                  submitCallBack={this.editUserData}
                  isReset={this.state.isReset}
                  handleResetPasswordPopup={this.handleResetPasswordPopup}
                  handleResetPassword={this.handleResetPassword}
                  handleNoReset={this.handleNoReset}
                  isLoading={this.state.isLoading}
                  isSent={this.state.isSent}
                  visible={this.state.isEditingUser}
                />
              </AdminContainer>
            </AnimatedView>
          );
        }}
      </AdminContext.Consumer>
    );
  }
}

const ManageGamesGroupsButton = ({ callBack, isMenuOpen }) => {
  if (storage.USER.privileges.includes('manage_groups')) {
    return <CommonButton text="MANAGE GAME GROUPS" onClick={callBack} />;
  } else return null;
};

const ManageGroupsButton = ({ callBack, isMenuOpen }) => {
  if (storage.USER.privileges.includes('manage_groups')) {
    return <CommonButton width={'100%'} text="MANAGE PERMISSION GROUPS" onClick={callBack} />;
  } else return null;
};

const AddUserPopup = ({ closeCallBack, submitCallBack }) => {
  return (
    <div className={'popup'}>
      <div className={'closeButton'} onClick={closeCallBack}>
        X
      </div>
      <h2>New User</h2>
      <div className={'popupForm'}>
        <form onSubmit={submitCallBack}>
          <label htmlFor="fname">First Name</label>
          <input type="text" id="fname" name="firstname" placeholder="Your name.." />
          <label htmlFor="lname">Last Name</label>
          <input type="text" id="lname" name="lastname" placeholder="Your last name.." />
          <label htmlFor="email">Email</label>
          <input type="text" id="email" name="email" placeholder="Your email.." />

          <label htmlFor="group">Groups</label>

          <fieldset>
            <input type="checkbox" name="admin" id="admin" value="admin" />
            <label htmlFor="admin">Administrators</label>
            <br />
            <input type="checkbox" name="analyst" id="analyst" value="analyst" />
            <label htmlFor="analyst">Data Analyst</label>
            <br />
            <input type="checkbox" name="apppstart" id="apppstart" value="apppstart" />
            <label htmlFor="apppstart">AppStart</label>
            <br />
            <input type="checkbox" name="playstack" id="playstack" value="playstack" />
            <label htmlFor="playstack">PlayStack</label>
            <br />
          </fieldset>
          <button>Cancel</button>
        </form>
      </div>
    </div>
  );
};

const TableContentOld = styled.table`
  position: relative;
  background-color: #282b30;
  width: 100%;
  font-size: 1.7rem;
  text-align: center;
  vertical-align: middle;
  color: #afafaf;
  line-height: 4rem;
  table-layout: fixed;
`;

const UsersTableOld = ({ itemRows, isMenuOpen }) => {
  const classes = `${'adminContent'}`;

  return (
    <div className={classes}>
      <TableContentOld>
        <thead className={'tableHeader'}>
          <tr>
            <td className="tEmail">Email</td>
            <td className="tName">Full Name</td>
            <td className="tLastActive">Last Active</td>
            <td className="tGroups">Groups</td>
            <td className={'financesInvCells'} />
          </tr>
        </thead>
      </TableContentOld>
      <TableContentOld>
        <tbody className={'scrollableTbody'}>{itemRows}</tbody>
      </TableContentOld>
    </div>
  );
};

export default withRouter(AdminMain);

//region Styles

const PermissionList = styled.ul`
  list-style-type: none;
`;
const ViewTile = styled.h1`
  width: 95%;
  color: #fff;
  padding: 0rem 0rem 1.2rem;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 4rem;
  margin-right: 4rem;
  margin-left: 4rem;
  margin-bottom: 1rem;
  z-index: 101;
`;

const AdminContent = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 2rem 48rem 48rem 36rem 36rem;
  grid-row-gap: 25px;
  grid-column-gap: 30px;
`;

const FilterTable = styled.div`
  grid-column: 2/4;
  grid-row: 1;
  padding-right: 58px;
`;

const GroupPicker = styled.div`
  grid-column: 2/3;
  grid-row: 2;
`;

const AddUser = styled.div`
  grid-column: 3/4;
  grid-row: 2;
`;

const ManageGroups = styled.div`
  grid-column: 4/5;
  grid-row: 2;
`;

const ManageGameGroups = styled.div`
  grid-column: 5/6;
  grid-row: 2;
`;

const UsersTableContainer = styled.div`
  grid-column: 2/4;
  grid-row: 3;
`;

const GroupsTableContainer = styled.div`
  grid-column: 4;
  grid-row: 3;
`;

const GamesGroupsTableContainer = styled.div`
  grid-column: 5;
  grid-row: 3;
`;

const GroupsItems = styled.div`
  display: grid;
  grid-template-columns: 2rem 29rem;
`;

const GroupsContent = styled.div`
  height: auto;
  padding: 0 0 2rem;
  height: auto;
  top: 15rem;
  left: calc(60% + 8rem);
  color: #99999c;
  max-width: 40rem;
  min-width: 30rem;
  background: rgba(36, 39, 43, 1);
  text-align: center;
  border-style: solid;
  border-color: rgba(54, 57, 63, 1);
  border-width: 2px;
  margin-bottom: 110px;
  ul {
    font-size: 1.5rem;
    padding-top: 0;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 0;
    text-align: left;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  li {
    margin-right: 1rem;
    margin-left: 2.5rem;
    padding-bottom: 1px;
  }
  span {
    left: -2rem;
  }
`;

const CheckMark = styled.div`
  grid-column: 1/2;
  display: inline-block;
  font-weight: bold;
  padding-right: 5px;
  color: ${props => props.color};
`;
const DotMark = styled.div`
  grid-column: 1/2;
  display: inline-block;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  padding-right: 5px;
  color: ${props => props.color};
`;
const PrivilageItem = styled.div`
  grid-column: 2/3;
`;

const DropdownContainer = styled.div`
  width: 300px;
`;

const GroupTitle = styled.div`
  color: #99999c;
  padding: 0rem 0rem 0rem;
  line-height: 4rem;
  font-size: 1.6rem;
  font-weight: 600;
  border-top: 1px solid #606064;
  margin-top: 1rem;
  margin-right: 4rem;
  margin-left: 4rem;
  margin-bottom: 0rem;
`;

const ColorGroup = styled.div`
  color: ${props => props.color};
  font-weight: bold;
`;

const Clicker = styled.div`
  position: ${props => (props.isMenuOpen ? 'fixed' : 'none')};
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
  background-color: #282b30;
  opacity: 0.7;
  z-index: 100;
`;

const AddUserContainer = styled.div`
  box-sizing: border-box;
  float: right;
  padding-right: 62px;
  width: 17rem;
`;

const AdminContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 150px;
`;

const GameGroupsList = styled.ul`
  font-size: 1.2rem;
  line-height: 2rem;
  list-style-type: initial;
  margin-left: 1rem;
  padding-left: 10px;
  text-align: left;
`;
//endregion
