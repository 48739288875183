import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

class DropdownList2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showList: false
    };
    this.dropdownList = React.createRef();
    this.showList = this.showList.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  selectOption(e) {
    // console.log('selected:', e.value);
    if (this.props.param != undefined) {
      this.props.handler(e, this.props.param);
    } else if (this.props.param == undefined) {
      this.props.handler(e);
    }
    setTimeout(this.showList, 0);
  }

  showList() {
    if (!this.state.showList) {
      document.addEventListener('click', this.handleOutsideClick, false);
      if (this.props.onFocusHandler) this.props.onFocusHandler();
    } else {
      if (this.props.onBlurHandler) this.props.onBlurHandler();
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
    let show = this.state.showList;
    this.setState({ showList: !show });
  }

  handleOutsideClick(e) {
    if (this.dropdownList.current) {
      if (this.dropdownList.current.contains(e.target)) {
        // console.log('yes, contains');
        return;
      }

      this.showList();
    }
  }

  render() {
    // console.log('DL2', this.props);
    let currentOption = {};
    Object.entries(this.props.items).map(([k, v]) => {
      //console.log(k, v);
      if (!v.key && !(typeof v.key == 'number')) {
        if (this.props.currentOption == k) {
          currentOption = {
            key: k,
            value: v
          };
        }
      } else if (this.props.currentOption == v.key) {
        currentOption = v;
      }
    });

    //console.log('curr', currentOption);
    return (
      <Fragment>
        <div ref={this.dropdownList}>
          <DropdownContainer uppercase={this.props.uppercase}>
            <CurrentOption
              backgroundColor={this.props.backgroundColor}
              color={currentOption.color}
              onClick={this.showList}
            >
              {this.props.placeholder ? this.props.placeholder : currentOption.value}
              <ArrowDown active={true} />
            </CurrentOption>
            {this.state.showList ? (
              <ListContainer>
                {Object.entries(this.props.items).map(([key, value]) => {
                  if (value.key == undefined) {
                    return (
                      <Option key={key}>
                        <input
                          name={value}
                          type="radio"
                          key={key}
                          id={key + value}
                          value={key}
                          onClick={e => {
                            this.selectOption(e);
                          }}
                        />
                        <label htmlFor={key + value}>{value}</label>
                      </Option>
                    );
                  } else
                    return (
                      <Option key={value.key} color={value.color}>
                        <input
                          name={value.value}
                          type="radio"
                          key={value.key}
                          id={value.key + value.value}
                          value={value.key}
                          onClick={e => {
                            this.selectOption(e);
                          }}
                        />
                        <label htmlFor={value.key + value.value}>{value.value}</label>
                      </Option>
                    );
                })}
              </ListContainer>
            ) : null}
          </DropdownContainer>
        </div>
      </Fragment>
    );
  }
}

export default DropdownList2;

const DropdownContainer = styled.div`
  position: relative;
  line-height: 30px;
  font-size: 12px;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  letter-spacing: 0.07em;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
`;

const Option = styled.div`
  width: 100%;
  min-height: 30px;
  align-items: center;
  cursor: pointer;
  background: #282b30;
  border: #141617 solid 0.5px;
  pointer-events: all;
  color: ${props => (props.color ? props.color : 'white')};
  transition: background 0.4s ease-in-out;
  box-sizing: border-box;
  white-space: nowrap;
  border-radius: 3px;

  &:hover {
    background: #1e2022;
    border-radius: 3px;
  }
  input {
    width: 100%;
    opacity: 0;
    display: none;
    //position: absolute;
    left: -99999px;
  }

  label {
    color: ${props => (props.color ? props.color : 'white')};
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    padding-left: 10px;
    display: block;
    cursor: pointer;
  }
`;

const ListContainer = styled.div`
  z-index: 1000;
  position: absolute;
  top: 30px;
  height: auto;
  margin-top: 3px;
  max-height: 404px;
  width: 100%;
  background: #1e2022;
  overflow: auto;
  border-bottom: 1px solid #1e2022;
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #242629;
    height: 1px;
    width: 8px;
  }
  ::-webkit-scrollbar {
    height: 8px;
    background-color: #242629;
    border-radius: 10px;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #00d5a9;
    width: 8px;
  }
`;

const CurrentOption = styled.div`
  position: relative;
  border-radius: 3px;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'rgb(30, 32, 34)')};
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  height: 30px;
  box-sizing: border-box;
  padding-left: 10px;
  cursor: pointer;
  border: none;
  outline: none;

  color: ${props => (props.color ? props.color : 'white')};

  width: 100%;
  padding-right: 10px;
  &:focus {
    outline: none !important;
    box-shadow: 0 0 5px rgb(21, 130, 110);
    border: 1px solid rgb(21, 130, 110);
  }
  &::placeholder {
    color: rgb(52, 57, 63);
  }
`;

const ArrowDown = styled.div`
  content: '';
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: ${props => (props.active ? '5px solid #00d5a9' : '5px solid #A6A4A4')};
  position: absolute;
  top: 12px;
  right: 10px;
  z-index: 98;
`;
