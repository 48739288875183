import React, { Component, PureComponent } from 'react';
import './style.css';
import styled from 'styled-components';
import SelectDeselectAll from 'components/SelectDeselectAll';
import CheckboxList from 'components/CheckboxList';
import Textfield from 'components/Textfield';
class CheckboxPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSearchedInputChange = this.handleSearchedInputChange.bind(this);
  }

  handleFilterSearch(event) {
    const target = event.target;
    const value = target.value;
    let searchTerm = value;

    let items_filter = [];
    const isSearched = searchTerm => item =>
      !searchTerm || item.toLowerCase().includes(searchTerm.toLowerCase());

    Object.keys(this.props.items)
      .filter(isSearched(searchTerm))
      .map(item => {
        items_filter[item] = this.props.items[item];
      });
    if (searchTerm != '') {
    } else {
      items_filter = null;
    }
    this.props.handleListChange(this.props.id, items_filter);
  }

  handleSearchedInputChange(event) {
    const target = event.target;
    const value = target.value;
    const updatedSearch = { ...this.props.searched_data, [value]: target.checked };
    this.props.changeSearchHandler('searched_filters', updatedSearch, this.props.id);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const updatedItems = { ...this.props.items, [value]: target.checked };
    this.props.changeHandler(this.props.id, updatedItems);
  }

  render() {
    if (!this.props.items || this.props.items === undefined) return null;
    let show_list = null;
    const searched_items = this.props.searched_data;
    if (searched_items != null && searched_items !== '') {
      show_list = (
        <CheckboxList
          items={searched_items}
          id={this.props.id}
          checkboxWidth={this.props.checkboxWidth}
          handleItems={this.handleSearchedInputChange}
        />
      );
    } else {
      show_list = (
        <CheckboxList
          items={this.props.items}
          id={this.props.id}
          checkboxWidth={this.props.checkboxWidth}
          handleItems={this.handleInputChange}
        />
      );
    }

    return (
      <CheckboxContainer key={'checkboxPanelKey_' + this.props.id}>
        <CheckboxPanelTitle key={'panelTitleKey_' + this.props.id}>
          {this.props.name}
        </CheckboxPanelTitle>
        <FormCheckboxContainer>
          <TextfieldContainer>
            <Textfield
              id={'checkboxPanel' + this.props.name}
              type="text"
              placeholder={'Search...'}
              onChange={this.handleFilterSearch}
              displayLabel={false}
            />
          </TextfieldContainer>
          <ListContainer>{show_list}</ListContainer>
        </FormCheckboxContainer>
        <SelectDeselectPlaceholder>
          <SelectDeselectAll
            buttonState={this.props.button_state}
            selectDeselectAll={() => this.props.selectHandler(this.props.id)}
          />
        </SelectDeselectPlaceholder>
      </CheckboxContainer>
    );
  }
}

export default CheckboxPanel;
//region Styles
export const CheckboxContainer = styled.div`
  margin: 0px;
  background-color: #282b30;
  height: 268px;
  color: #e1e1e5;
  font-weight: 400;
  border: 0.5px solid #36393f;
  border-radius: 3px;
  padding: 15px 20px;
  position: relative;
`;

export const PanelTitle = styled.div`
  font-family: 'Roboto Medium', sans-serif;
  color: #e1e1e5;
  font-size: 1.2em;
  text-align: left;
  border-bottom: 1.5px solid #363940;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const CheckboxPanelTitle = styled(PanelTitle)`
  margin-bottom: 10px;
`;

const FormCheckboxContainer = styled.div`
  overflow: auto;
  margin-top: 10px;
`;
const ListContainer = styled.div`
  margin-top: 20px;
  padding-left: 8px;
  padding-right: 8px;
`;

const SelectDeselectPlaceholder = styled.div`
  position: absolute;
  right: 22px;
  top: 118px;
`;

const TextfieldContainer = styled.form`
  margin: 0px 8px;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
`;
//endregion
