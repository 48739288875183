import React, { Component, Fragment } from 'react';
import CommonButton, { Button } from 'components/CommonButton/index';
import Textfield from 'components/Textfield';
import styled from 'styled-components';
import { Content } from 'views/App';
import edit_big from '../assets/edit_big.png';
import delete_btn from '../assets/delete_btn.png';
import x_red from '../assets/x_red.png';
import game_icon from '../assets/game_icon.png';
import storage from 'utils/storage';
import LoadingIndicator from 'components/LoadingIndicator';
import PopupDelete from '../PopupDelete';

class GmsEditPlatform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      platformName: '',
      platformNewName: '',
      editNameMode: false,
      gameIcon: '',
      platformSlug: '',
      openDeletePopup: false,
      platformSlugToDelete: '',
      updateConfirmBtn: false,
      needSave: true
    };
    this.mounted = false;
    this.setEditNameMode = this.setEditNameMode.bind(this);
    this.setNewPlatformName = this.setNewPlatformName.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.editPlatform = this.editPlatform.bind(this);
    this.deletePlatform = this.deletePlatform.bind(this);
    this.noDeletePlatformBtn = this.noDeletePlatformBtn.bind(this);
    this.handleDeleteBtn = this.handleDeleteBtn.bind(this);
    this.handlePlatformSlugDelete = this.handlePlatformSlugDelete.bind(this);
  }
  /** Edit Platform Lifecycle Methods */
  //region Lifecycle Methods
  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    this.fetchData();
  }
  //endregion
  componentWillReceiveProps(nextProps) {}
  /** Fetching and parsing data */
  //region Fetching and parsing data
  fetchData() {
    if (!this.mounted) return;
    this.setState({ isFetching: true });
    this.getData().then(data => this.onDataReceived(data));
  }

  getData() {
    if (!this.mounted) return;
    if (this.props.location.state == undefined) {
      this.props.history.push({
        pathname: '/gms/platforms'
      });
      return;
    }
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/platforms/platform/${this.props.location.state.id}/view`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.payload = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.payload = json.result;
        else data.payload = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  onDataReceived(data) {
    if (data.failed) {
      //if(receivedData.updatedToken==null)
      this.props.updatePrivCall(false);

      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }

    if (!this.mounted) return;

    this.setState(
      {
        isFetching: false
      },
      () => {
        this.parseData(data);
      }
    );

    // setTimeout(this.marItem, 500);
  }

  parseData(dataArg) {
    let data = dataArg.payload[0];
    this.setState({
      platformName: data.name,
      platformNewName: data.name,
      gameIcon: data.icon,
      platformSlug: data.slug
    });
  }
  //endregion

  setEditNameMode() {
    let currentEdit = this.state.editNameMode;
    let savedName = this.state.platformName;
    if (currentEdit === false) {
      this.setState({ editNameMode: !currentEdit, platformNewName: savedName });
    } else {
      this.setState({
        editNameMode: !currentEdit,
        needSave: true
      });
    }
  }

  setNewPlatformName(event) {
    const target = event.target;
    let name = target.value;
    let update = false;
    if (name !== '' && name.length >= 1 && name.length < 50) {
      update = true;
    }
    this.setState({
      platformNewName: target.value,
      needSave: update
    });
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onload = () => {
      this.setState({
        //gameIcon: file,
        gameIcon: reader.result
      });
    };
    reader.readAsDataURL(file);
  }

  editPlatform() {
    if (this.state.needSave === false) {
      return;
    }
    this.setState({ isFetching: true });
    let platformName;
    if (this.state.editNameMode) {
      platformName = this.state.platformNewName;
    } else {
      platformName = this.state.platformName;
    }
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/platforms/platform/${this.state.platformSlug}/edit`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let payload = {
      data: {
        name: platformName,
        icon: this.state.gameIcon
      }
    };

    return fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) storage.USER.token = token;
        if (status === 200) return json;
        else {
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        this.props.history.push({
          pathname: '/gms/platforms'
        });
        return json;
      })
      .catch(error => {
        this.setState({ isFetching: true });
        console.error(error);
      });
  }

  deletePlatform() {
    if (this.state.platformSlug != this.state.platformSlugToDelete) {
      return;
    }
    this.setState({ isFetching: true });
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/platforms/platform/${this.state.platformSlug}/delete`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let payload = {
      data: {
        slug: this.state.platformSlug
      }
    };

    return fetch(url, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();

        if (status === 200) return json;
        else {
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        this.props.history.push({
          pathname: '/gms/platforms'
        });
        return json;
      })
      .catch(error => {
        this.setState({ isFetching: false });
        console.error(error);
      });
  }

  handlePlatformSlugDelete(event) {
    const target = event.target;
    let needUpdate = false;
    if (this.state.platformSlug === target.value) {
      needUpdate = true;
    }
    this.setState({
      platformSlugToDelete: target.value,
      updateConfirmBtn: needUpdate
    });
  }

  noDeletePlatformBtn() {
    this.setState({ openDeletePopup: false });
  }

  handleDeleteBtn() {
    this.setState({ openDeletePopup: true });
  }

  render() {
    if (!this.mounted) return;
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }
    const icon = this.state.gameIcon ? this.state.gameIcon : game_icon;

    let platformName;
    if (this.state.editNameMode) {
      platformName = (
        <NameToEdit>
          <Textfield
            labelText="Name"
            id="addPlatformName"
            type="text"
            value={this.state.platformNewName}
            onChange={this.setNewPlatformName}
          />
          <CancelEditName src={x_red} onClick={this.setEditNameMode} />
        </NameToEdit>
      );
    } else {
      platformName = (
        <PlatformNameText>
          {this.state.platformName}
          <EditName src={edit_big} onClick={this.setEditNameMode} />
        </PlatformNameText>
      );
    }

    return (
      <Fragment>
        <ViewTile>{'Edit Platform'}</ViewTile>
        {this.state.openDeletePopup ? (
          <div>
            <BackgroundPopup />
            <PopupDelete
              closeBtn={this.noDeletePlatformBtn}
              onChange={this.handlePlatformSlugDelete}
              yesBtn={this.deletePlatform}
              slug={this.state.platformSlug}
              itemType={' platform'}
              needUpdate={this.state.updateConfirmBtn}
            />
          </div>
        ) : null}
        <AddPlatformContainer>
          <EditNameContainer>{platformName}</EditNameContainer>
          <DeleteContainer>
            <DeleteIcon src={delete_btn} onClick={this.handleDeleteBtn} />
          </DeleteContainer>
          <IconContainer>
            <PlatformIcon src={icon} />
            <ImgEditLabel htmlFor={'image-input'}>
              <img src={edit_big} />
            </ImgEditLabel>
            <EditImageInput
              id={'image-input'}
              ref={this.imageInputRef}
              type="file"
              onChange={this.handleImageChange}
            />
          </IconContainer>
          <ButtonContainer>
            <Button needUpdate={this.state.needSave} onClick={this.editPlatform}>
              Save Changes
            </Button>
          </ButtonContainer>
        </AddPlatformContainer>
      </Fragment>
    );
  }
}

export default GmsEditPlatform;

//region Styles
const BackgroundPopup = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
  background-color: #282b30;
  opacity: 0.7;
  z-index: 100;
`;

const ViewTile = styled.h1`
  width: 95%;
  color: #fff;
  padding: 0rem 0rem 1%;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 4rem;
  margin-right: 3%;
  margin-left: 2%;
  margin-bottom: 1rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;

const AddPlatformContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 30rem 5rem;
  margin-top: 50px;
  grid-row-gap: 4rem;
  margin-left: 13%;
`;

const EditNameContainer = styled.div`
  grid-row: 1;
  position: relative;
  grid-column: 1/2;
`;

const IconContainer = styled.div`
  grid-row: 2;
  grid-column: 1/2;
  position: relative;
`;

const PlatformIcon = styled.img`
  border-radius: 30px;
  height: 130px;
  width: 130px;
`;

const ImgEditLabel = styled.label`
  cursor: pointer;
`;

const EditImageInput = styled.input`
  display: none;
`;

const ButtonContainer = styled.div`
  grid-row: 3;
  grid-column: 1/2;
  height: 32px;
`;

const PlatformNameText = styled.div`
  font-size: 16px;
  color: rgb(225, 225, 229);
  letter-spacing: 0.05rem;
  font-weight: bolder;
  font-family: 'Titillium Web';
`;

const NameToEdit = styled.div`
  position: absolute;
  top: -21px;
  width: 90%;
`;

const EditName = styled.img`
  display: inline;
  margin-left: 10px;
  cursor: pointer;
`;

const CancelEditName = styled.img`
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  top: 28px;
  left: 270px;
`;

const DeleteContainer = styled.div`
  grid-row: 1;
  grid-column: 2/3;
`;

const DeleteIcon = styled.img`
  cursor: pointer;
`;
//endregion
