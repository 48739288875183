import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import delete_icon from './assets/delete_icon.png';
import check_green from './assets/check_green.png';
import edit_small from './assets/edit_small.png';
import x_red from './assets/x_red.png';

const Panel = props => {
  return (
    <PanelContainer pWidth={props.pWidth} pHeight={props.pHeight} maxpHeight={props.maxpHeight}>
      {props.children}
      {props.handleDelete ? (
        <DeletePanel src={delete_icon} onClick={() => props.handleDelete(props.itemId)} />
      ) : null}
      {props.handleCancel ? (
        <DeletePanel src={x_red} onClick={() => props.handleCancel(props.itemId)} />
      ) : null}
      {props.handleAccept ? (
        <ConfirmEditPanel src={check_green} onClick={() => props.handleAccept(props.itemId)} />
      ) : null}
      {props.editPanel ? (
        <ConfirmEditPanel src={edit_small} onClick={() => props.editPanel(props.itemId)} />
      ) : null}
    </PanelContainer>
  );
};

export default Panel;

const PanelContainer = styled.div`
  min-width: 100%;
  width: ${props => (props.pWidth ? props.pWidth : '100%')};
  padding: 2rem 3rem 2rem 2rem;
  margin: 0px;
  margin-bottom: 20px;
  background-color: #282b30;
  border: 0.5px solid #36393f;
  border-radius: 3px;
  min-height: ${props => (props.pHeight ? props.pHeight : '55px')};
  max-height: ${props => (props.maxpHeight ? props.maxpHeight : 'auto')};
  position: relative;
`;

const DeletePanel = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const ConfirmEditPanel = styled.img`
  position: absolute;
  right: 8px;
  top: 43px;
  cursor: pointer;
`;
