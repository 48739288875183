import React, { Component, Fragment } from 'react';
import moment from 'moment';
import storage from 'utils/storage';
import CommonButton, { Button } from 'components/CommonButton/index';
import DropdownList from 'components/DropdownList';
import DropdownList2 from 'components/DropdownList2';
import Textfield from 'components/Textfield';
import Calendar from 'components/CustomDatePicker/Calendar';
import CheckboxList from 'components/CheckboxList';
import FilterList from 'components/FilterList';
import styled from 'styled-components';
import { Content } from 'views/App';
import add_btn from '../assets/add_btn.png';
import edit_big from '../assets/edit_big.png';
import x_red from '../assets/x_red.png';
import delete_btn from '../assets/delete_btn.png';
import LoadingIndicator from 'components/LoadingIndicator';
import PopupDelete, { PopupConfigurationProfile } from '../PopupDelete';
import PopupAddDeveloper from '../PopupAddDeveloper';
import SwitchButton from 'components/Switch';
import Panel from 'components/Panel';
import AlertPanels from './AlertPanels';

class GmsEditGame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      gameName: '',
      gameSlug: '',
      newGameName: '',
      gameNameEdit: false,
      gameIcon: '',
      currentDate: '',
      launchDate: '',
      statusList: [],
      statusCurrent: '',
      externalId: [],
      statusPlaceholder: '',
      configurationProfileList: [],
      configurationProfileCurrent: '',
      configurationProfilePlaceholder: '- Set Configuration Profile -',
      platformsList: null,
      developersList: [],
      developerCurrent: '',
      developerPlaceholder: '- Set Developer -',
      addDeveloperName: '',
      currentDataSource: '',
      dataSourcesTable: [],
      dataSourcesCopyTable: [],
      dataSourceDropdownListPlaceholder: 'New data source',
      platformsListPlaceholder: 'Set Configuration Profile to see Platform List',
      savedOriginalPlatformsList: null,
      configurationProfileChanged: false,
      deleteGameUpdateBtn: false,
      currentConfigurationProfileToSend: '',
      openConfigPopup: false,
      openDeletePopup: false,
      openAddDeveloperPopup: false,
      gameSlugToDelete: null,
      calendarPlaceholder: true,
      dataSourcesToAdd: [],
      needSave: true,
      isExternal: false,
      isInternal: false,
      gameExtInt: '',
      lastElementHeight: '90px',
      dropdownListSources: [],
      /*
 alerts_list: [
        { date: moment(), text: 'blablablabl bla bla vllalkldfk', color: 'red', type: 'important' },
        {
          date: moment(),
          text: 'As of version 2.0.0, a locale key can be passed',
          color: 'yellow',
          type: 'medium'
        }
      ]
      "alerts": [
                {
                    "id": alert.id,
                    "comment": alert.comment,
                    "level": alert.level,
                    "date_created": alert.date_created
                }


       */
      alerts_list: [],
      isAlertPopup: false
    };
    this.mounted = false;
    this.fetchData = this.fetchData.bind(this);
    this.editName = this.editName.bind(this);
    this.setNewGameName = this.setNewGameName.bind(this);
    this.selectStatus = this.selectStatus.bind(this);
    this.selectConfigurationProfile = this.selectConfigurationProfile.bind(this);
    this.checkPlatform = this.checkPlatform.bind(this);
    this.selectDataSource = this.selectDataSource.bind(this);
    this.setLaunchDate = this.setLaunchDate.bind(this);
    this.openPopupChange = this.openPopupChange.bind(this);
    this.yesChangeConfigProfile = this.yesChangeConfigProfile.bind(this);
    this.noChangeConfigProfile = this.noChangeConfigProfile.bind(this);
    this.handleDeleteBtn = this.handleDeleteBtn.bind(this);
    this.handleGameSlugDelete = this.handleGameSlugDelete.bind(this);
    this.noDeleteGameBtn = this.noDeleteGameBtn.bind(this);
    this.deleteGame = this.deleteGame.bind(this);
    this.handleEditGame = this.handleEditGame.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.addSourceToTable = this.addSourceToTable.bind(this);
    this.openCloseEditDataSource = this.openCloseEditDataSource.bind(this);
    this.confirmEditSource = this.confirmEditSource.bind(this);
    this.cancelEditSource = this.cancelEditSource.bind(this);
    this.deleteDataSource = this.deleteDataSource.bind(this);
    this.editSourceValues = this.editSourceValues.bind(this);
    this.checkUpdate = this.checkUpdate.bind(this);
    this.changeIsExternal = this.changeIsExternal.bind(this);
    this.getDevelopersList = this.getDevelopersList.bind(this);
    this.addDeveloper = this.addDeveloper.bind(this);
    this.selectOnFocus = this.selectOnFocus.bind(this);
    this.selectOnBlur = this.selectOnBlur.bind(this);
    this.selectOnChange = this.selectOnChange.bind(this);
    this.deleteAlert = this.deleteAlert.bind(this);
    this.createAlert = this.createAlert.bind(this);
    this.addAlertPopup = this.addAlertPopup.bind(this);
  }

  checkUpdate() {
    let update = false;
    let sourceUpdate = true;
    Object.entries(this.state.dataSourcesTable).map(([key, val]) => {
      Object.entries(this.state.dataSourcesTable).map(([k, v]) => {
        if (val.slug === v.slug && key !== k) {
          Object.entries(val.params).map(([sk, sv]) => {
            Object.entries(v.params).map(([pk, pv]) => {
              if (sv.slug === 'game_id' && pv.slug === 'game_id' && sv.value === pv.value) {
                sourceUpdate = false;
              }
            });
          });
        }
      });
    });
    if (
      this.state.newGameName.length >= 1 &&
      this.state.newGameName.length < 50 &&
      this.state.newGameName !== '' &&
      sourceUpdate === true
    ) {
      update = true;
    }
    this.setState({ needSave: update });
  }

  /**Mounting, fetching and parsing data */
  //region Fetching and parsing data
  componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {}
  fetchData() {
    if (!this.mounted) return;
    this.setState({ isFetching: true });

    this.getData()
      .then(data => this.getDevelopersList(data))
      .then(data => this.onDataReceived(data));
  }

  getData() {
    if (!this.mounted) return;
    if (this.props.location.state == undefined) {
      this.props.history.push({
        pathname: '/gms/games'
      });
      return;
    }
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];

    const PATH_CALL = `/games/game/${this.props.location.state.id}/view`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.payload = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.payload = json.result;
        else data.payload = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  getDevelopersList(data) {
    if (!this.mounted) return;
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = '/developers/list';
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };

    data.developers_list = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }

        return json;
      })
      .then(json => {
        if (json.result) data.developers_list = json.result;
        else data.developers_list = json;

        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  onDataReceived(data) {
    if (data.failed) {
      //if(receivedData.updatedToken==null)
      this.props.updatePrivCall(false);

      return;
    } else {
      if (data.updatedToken) {
        this.props.updatePrivCall(true);
        return;
      }
    }

    if (!this.mounted) return;

    this.setState(
      {
        isFetching: true
      },
      () => {
        this.parseData(data);
      }
    );

    // setTimeout(this.marItem, 500);
  }

  parseData(dataarg) {
    let data = dataarg.payload[0];

    //  let status_current = data.game_status.slug;
    let developers_list = {};
    let currentDate;
    let calendarPlaceholder = false;
    let launchDate;
    if (data.launch_date != undefined && data.launch_date != false && data.launch_date !== '') {
      currentDate = moment(data.launch_date, 'DD-MM-YYYY');
      launchDate = moment(data.launch_date, 'DD-MM-YYYY');
    } else {
      currentDate = moment(new Date(), 'DD-MM-YYYY');
      calendarPlaceholder = true;
      launchDate = '';
    }
    let configuration_profile_list = this.props.location.state.listData.configuration_profiles_list;
    let statusList = this.props.location.state.listData.games_status_list;
    let platformsListToAdd = this.props.location.state.listData.platforms_list;
    let newPlatforms = [];
    Object.entries(platformsListToAdd).map(([key, val]) => {
      newPlatforms.push(key);
    });
    // let platformsListToDisplay = this.props.location.state.listData.platforms_list;

    Object.entries(dataarg.developers_list).map(([key, val]) => {
      //developers_list[val.slug] = {};
      developers_list[val.slug] = val.name;
      //developers_list[val.slug]['value'] = false;
    });

    newPlatforms.sort();

    let platformsTable = [];
    for (let i = 0; i < newPlatforms.length; i++) {
      let key = newPlatforms[i];
      let val = platformsListToAdd[key];
      platformsTable[key] = val;
    }

    let alerts = [];
    dataarg.payload[0].alerts.map(item => {
      //developers_list[val.slug] = {};
      alerts[item.id] = item;
      //developers_list[val.slug]['value'] = false;
    });

    let configuration_profile;
    let current_configuration_profile;
    let configuration_placeholder = '- Set Configuration Profile -';
    configuration_profile = data.configuration_profile;

    if (data.configuration_profile) {
      current_configuration_profile = data.configuration_profile.slug;
      configuration_placeholder = '';
    } else {
      current_configuration_profile = '';
    }

    let platforms = [];
    let platformPlaceholder = 'Set Configuration Profile to see Platform List';
    let savedOriginalPlatformsList = null;
    if (data.platforms != undefined) {
      platformPlaceholder = '';
      Object.entries(data.platforms).map(([key, val]) => {
        platforms[val.slug] = {};
        platforms[val.slug]['name'] = val.name;
        platforms[val.slug]['checked'] = true;
      });

      savedOriginalPlatformsList = platforms;
    }

    Object.entries(platformsTable).map(([key, val]) => {
      Object.entries(platforms).map(([k, v]) => {
        if (key == k && val.checked != v.checked) {
          val.checked = v.checked;
        }
      });
    });

    let currentStatus;
    let statusPlaceholder;
    if (data.game_status === false) {
      currentStatus = '';
      statusPlaceholder = 'Set status';
    } else {
      currentStatus = data.game_status.slug;
      statusPlaceholder = '';
    }

    let game_sources = [];

    Object.entries(data.external_ids).map(([key, val]) => {
      if (val.service.name === undefined) {
        return;
      }
      let game_item = {};
      game_item['data_id'] = key;
      game_item['service'] = val.service.name;
      game_item['slug'] = val.service.slug;
      game_item['params'] = [];
      game_item['isEdited'] = false;
      let game_id = {};
      game_id['name'] = 'game_id';
      game_id['value'] = val.id;
      game_id['slug'] = 'game_id';
      game_item['params'].push(game_id);
      if (val.params !== '') {
        Object.entries(val.params[0]).map(([k, v]) => {
          let item = {};
          item['name'] = k;
          item['value'] = v;
          item['slug'] = k;
          game_item['params'].push(item);
        });
      } else {
      }
      game_sources.push(game_item);
    });
    let saved_sources = JSON.parse(JSON.stringify(game_sources));
    let copy_game_sources = JSON.parse(JSON.stringify(game_sources));
    let internal;
    let extIntText;
    if (data.is_external) {
      internal = false;
      extIntText = 'External';
    } else {
      internal = true;
      extIntText = 'Internal';
    }
    this.setState(
      {
        gameIcon: data.icon,
        gameName: data.name,
        newGameName: data.name,
        gameSlug: data.slug,
        statusCurrent: currentStatus,
        currentDate: currentDate,
        calendarPlaceholder: calendarPlaceholder,
        configurationProfileCurrent: current_configuration_profile,
        configurationProfilePlaceholder: configuration_placeholder,
        platformsList: platformsTable,
        developersList: developers_list,
        developerPlaceholder: '',
        developerCurrent: data.developer.slug,
        launchDate: launchDate,
        statusList: statusList,
        statusPlaceholder: statusPlaceholder,
        configurationProfileList: configuration_profile_list,
        platformsListPlaceholder: platformPlaceholder,
        savedOriginalPlatformsList: savedOriginalPlatformsList,
        currentConfigurationProfileToSend: current_configuration_profile,
        dataSourcesTable: game_sources,
        dataSourcesCopyTable: copy_game_sources,
        dataSourcesSaved: saved_sources,
        isFetching: false,
        externalId: data.external_ids,
        removedData: [],
        savedConfigProfile: configuration_profile,
        isExternal: data.is_external,
        isInternal: internal,
        gameExtInt: extIntText,
        alerts_list: alerts
      },
      () => this.getConfigData()
    );
  }

  getConfigData() {
    if (this.state.savedConfigProfile === false) {
      return;
    }

    this.getConfigProfileView().then(data => this.configDataReceived(data));
  }

  getConfigProfileView() {
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/configuration_profiles/configuration_profile/${
      this.state.currentConfigurationProfileToSend
    }/view`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
      token: storage.USER.token
    };
    let data = {};
    data.updatedToken = false;
    data.failed = false;
    data.payload = null;
    return fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        let status = response.status;
        let token = response.headers.get('token');
        let json = response.json();
        if (token != null) {
          storage.USER.token = token;
          data.updatedToken = true;
        }
        if (status != 200) {
          data.failed = true;
        }
        return json;
      })
      .then(json => {
        if (json.result) data.payload = json.result;
        else data.payload = json;
        return data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  configDataReceived(data) {
    let dropdownList = [];
    let dataSourcesTable = [];
    let dataSouces = data.payload[0].data_sources;
    Object.entries(dataSouces).map(([key, val]) => {
      let dropdownListI = {};
      dropdownListI['key'] = val.slug;
      dropdownListI['value'] = val.name;
      dropdownList.push(dropdownListI);
      let dropdown_item = {};
      dropdown_item['slug'] = val.slug;
      dropdown_item['service'] = val.name;
      dropdown_item['isEdited'] = true;
      dropdown_item['params'] = [];
      let game_id = { name: 'Game ID', value: '', slug: 'game_id' };
      dropdown_item['params'].push(game_id);

      Object.entries(val.game_vars).map(([k, v]) => {
        let item = {};
        item['name'] = v.name;
        item['value'] = '';
        item['slug'] = v.slug;
        dropdown_item['params'].push(item);
      });
      dataSourcesTable.push(dropdown_item);
    });

    dropdownList.sort((a, b) => {
      if (a['value'].toLowerCase() < b['value'].toLowerCase()) return -1;

      if (a['value'].toLowerCase() > b['value'].toLowerCase()) return 1;

      return 0;
    });

    this.setState({ dataSourcesToAdd: dataSourcesTable, dropdownListSources: dropdownList });
  }

  //endregion

  setLaunchDate(date) {
    this.setState({
      launchDate: moment(date).format('DD-MM-YYYY'),
      currentDate: moment(date),
      calendarPlaceholder: false
    });
  }

  editName() {
    let editState = this.state.gameNameEdit;
    let savedName = this.state.gameName;
    if (editState == false) {
      this.setState({
        gameNameEdit: !editState,
        newGameName: savedName
      });
    } else {
      this.setState({
        gameNameEdit: !editState,
        needSave: true
      });
    }
  }

  setNewGameName(event) {
    const target = event.target;

    this.setState(
      {
        newGameName: target.value
      },
      () => {
        this.checkUpdate();
      }
    );
  }

  selectStatus(event) {
    const target = event.target;
    this.setState({ statusCurrent: target.value, statusPlaceholder: '' });
  }

  selectConfigurationProfile(event) {
    const target = event.target;

    this.setState({
      configurationProfilePlaceholder: '',
      configurationProfileCurrent: target.value,
      configurationProfileChanged: true
    });
  }

  openPopupChange() {
    if (this.state.configurationProfileChanged == false) {
      return;
    } else {
      this.setState({
        openConfigPopup: true
      });
    }
  }

  yesChangeConfigProfile() {
    let currentConfig = this.state.configurationProfileCurrent;
    this.setState(
      {
        configurationProfileChanged: false,
        configurationProfilePlaceholder: '',
        platformsListPlaceholder: '',
        currentConfigurationProfileToSend: currentConfig,
        openConfigPopup: false,
        dataSourcesTable: [],
        dataSourcesCopyTable: [],
        dataSourceDropdownListPlaceholder: 'New data source',
        currentDataSource: '',
        savedConfigProfile: true
      },
      () => this.getConfigData()
    );
  }

  noChangeConfigProfile() {
    this.setState({
      openConfigPopup: false
    });
  }

  checkPlatform(event) {
    const target = event.target;
    let platformList = { ...this.state.platformsList[target.name] };
    platformList['checked'] = target.checked;

    this.setState({
      platformsList: Object.assign({}, this.state.platformsList, {
        [target.name]: platformList
      })
    });
    this.forceUpdate();
  }

  selectDataSource(event) {
    const target = event.target;

    this.setState({
      dataSourceDropdownListPlaceholder: '',
      currentDataSource: target.value
    });
  }

  handleDeleteBtn() {
    this.setState({ openDeletePopup: true });
  }

  handleGameSlugDelete(event) {
    const target = event.target;
    let needUpdate = false;
    if (target.value === this.state.gameSlug) {
      needUpdate = true;
    }
    this.setState({
      gameSlugToDelete: target.value,
      deleteGameUpdateBtn: needUpdate
    });
  }

  noDeleteGameBtn() {
    this.setState({ openDeletePopup: false });
  }

  changeIsExternal(e) {
    if (this.state.gameExtInt === 'Internal') {
      this.setState({
        isExternal: true,
        gameExtInt: 'External'
      });
    } else {
      this.setState({
        isExternal: false,
        gameExtInt: 'Internal'
      });
    }
  }
  /** Data source management */
  //region Data source management
  addSourceToTable() {
    let currentSource = this.state.currentDataSource;
    if (currentSource !== false) {
      let sourceTable = this.state.dataSourcesTable;
      let copySourceTable = this.state.dataSourcesCopyTable;

      Object.entries(this.state.dataSourcesToAdd).map(([key, val]) => {
        if (val.slug === currentSource) {
          let valCopy = { ...val };
          let valCopy2 = { ...val };
          let paramsCopy = [];
          let paramsCopy2 = [];
          for (let i = 0; i < val.params.length; i++) {
            let newObj = { ...val.params[i] };
            let newObj2 = { ...val.params[i] };
            paramsCopy.push(newObj);
            paramsCopy2.push(newObj2);
          }
          valCopy.params = paramsCopy;
          valCopy2.params = paramsCopy2;

          let id_counter = 0;
          Object.entries(this.state.dataSourcesTable).map(([sk, sv]) => {
            if (sv.slug === currentSource) {
              id_counter++;
            }
          });

          valCopy.added_source = val.slug + id_counter;
          valCopy2.added_source = val.slug + id_counter;
          sourceTable.push(valCopy);
          copySourceTable.push(valCopy2);
        }
      });
      this.setState({
        dataSourcesTable: sourceTable,

        dataSourcesCopyTable: copySourceTable
      });
    }
  }

  openCloseEditDataSource(sourceId) {
    let sourceTable = this.state.dataSourcesTable;
    let val = sourceTable[sourceId].isEdited;
    sourceTable[sourceId].isEdited = !val;
    this.setState({ dataSourcesTable: sourceTable });
  }

  confirmEditSource(sourceId) {
    let sourceTable = this.state.dataSourcesTable;
    let val = sourceTable[sourceId].isEdited;
    sourceTable[sourceId].isEdited = false;
    let copySourceTable = this.state.dataSourcesCopyTable;
    let objectToCopy = JSON.parse(JSON.stringify(sourceTable[sourceId]));
    copySourceTable[sourceId] = objectToCopy;
    this.setState({ dataSourcesTable: sourceTable, dataSourcesCopyTable: copySourceTable });
  }

  cancelEditSource(sourceId) {
    let sourceTable = this.state.dataSourcesTable;
    let copySourceTable = this.state.dataSourcesCopyTable;
    let objectToCopy = JSON.parse(JSON.stringify(copySourceTable[sourceId]));
    objectToCopy.isEdited = false;

    sourceTable[sourceId] = objectToCopy;
    this.setState({ dataSourcesTable: sourceTable });
  }

  deleteDataSource(sourceId) {
    let sourceTable = this.state.dataSourcesTable;
    let removedItems = this.state.removedData;

    Object.entries(this.state.dataSourcesTable).map(([key, val]) => {
      Object.entries(this.state.dataSourcesSaved).map(([k, v]) => {
        if (val.data_id === sourceTable[sourceId].data_id && v.data_id === val.data_id) {
          removedItems.push(v);
        }
      });
    });
    sourceTable.splice(sourceId, 1);

    let copySourceTable = this.state.dataSourcesCopyTable;
    copySourceTable.splice(sourceId, 1);
    this.setState(
      {
        dataSourcesTable: sourceTable,
        removedData: removedItems,
        dataSourcesCopyTable: copySourceTable
      },
      () => {
        this.checkUpdate();
      }
    );
  }
  editSourceValues(event, sourceId, key) {
    const target = event.target;
    let sourceTable = this.state.dataSourcesTable;

    sourceTable[sourceId].params[key].value = target.value;
    this.setState({ dataSourcesTable: sourceTable }, () => {
      this.checkUpdate();
    });
  }
  //endregion

  deleteGame() {
    if (this.state.gameSlug !== this.state.gameSlugToDelete) {
      return;
    }
    this.setState({ isFetching: true });
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/games/game/${this.state.gameSlug}/delete`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let payload = {
      data: {
        slug: this.state.gameSlug
      }
    };

    return fetch(url, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status === 200) {
          this.props.getIcons(true);
          return json;
        } else {
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        this.props.history.push({
          pathname: '/gms/games'
        });
        return json;
      })
      .catch(error => {
        this.setState({ isFetching: false });
        console.error(error);
      });
  }

  addDeveloper() {
    if (!this.state.addDeveloperUpdateBtn) {
      return;
    }
    this.setState({ isFetching: true });
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/developers/add`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let payload = {
      data: [
        {
          name: this.state.addDeveloperName
        }
      ]
    };

    return fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status === 200) return json;
        else {
          this.setState({ isFetching: false });
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        //json.developers[0].name json.developers[0].slug
        let list = { ...this.state.developersList };
        list[json.message.developers[0].slug] = json.message.developers[0].name;
        this.setState(
          {
            developersList: list,
            openAddDeveloperPopup: false,
            developerCurrent: json.message.developers[0].slug,
            developerPlaceholder: '',
            isFetching: false
          },
          () => {
            this.checkUpdate();
          }
        );
        /* this.props.history.push({
               pathname: '/gms/games'
             });*/
        return json;
      })
      .catch(error => {
        console.error(error);
      });
  }

  handleEditGame() {
    if (!this.state.needSave) {
      return;
    } else {
      this.setState({ isFetching: true });
      let newGameName;
      if (this.state.gameNameEdit) {
        newGameName = this.state.newGameName;
      } else {
        newGameName = this.state.gameName;
      }
      let removed_platforms = [];
      let added_platforms = [];
      Object.entries(this.state.platformsList).map(([key, val]) => {
        if (Object.keys(this.state.savedOriginalPlatformsList).length == 0) {
          if (val.checked === true) {
            added_platforms.push(key);
          }
        } else {
          Object.entries(this.state.savedOriginalPlatformsList).map(([k, v]) => {
            if (key == k && val.checked !== v.checked) {
              removed_platforms.push(key);
            }
            if (key !== k && val.checked == true) {
              added_platforms.push(key);
            }
          });
        }
      });

      let added_game_params = [];
      Object.entries(this.state.dataSourcesTable).map(([key, val]) => {
        if (val.added_source !== undefined) {
          let item = {};
          item['slug'] = val.slug;
          Object.entries(val.params).map(([m, n]) => {
            item[n.slug] = n.value;
          });
          added_game_params.push(item);
        }
      });

      let removed_data = [];
      Object.entries(this.state.removedData).map(([key, val]) => {
        let removed_item = {};
        removed_item['slug'] = val.slug;
        Object.entries(val.params).map(([k, v]) => {
          removed_item[v.name] = v.value;
        });
        removed_data.push(removed_item);
      });

      let modified_data = [];
      Object.entries(this.state.dataSourcesSaved).map(([key, val]) => {
        Object.entries(this.state.dataSourcesTable).map(([k, v]) => {
          if (val.data_id === v.data_id) {
            Object.entries(val.params).map(([sk, sv]) => {
              Object.entries(v.params).map(([dk, dv]) => {
                if (sv.slug === dv.slug && sv.value !== dv.value) {
                  modified_data.push(v);
                }
              });
            });
          }
        });
      });

      let send_modify_data = [];

      Object.entries(this.state.dataSourcesSaved).map(([key, val]) => {
        Object.entries(modified_data).map(([k, v]) => {
          if (val.data_id === v.data_id) {
            let modify_item = {};
            modify_item['old'] = {};
            modify_item['new'] = {};
            modify_item['old']['slug'] = val.slug;
            modify_item['new']['slug'] = v.slug;
            Object.entries(val.params).map(([opk, opv]) => {
              modify_item['old'][opv.name] = opv.value;
            });
            Object.entries(v.params).map(([npk, npv]) => {
              modify_item['new'][npv.slug] = npv.value;
            });
            send_modify_data.push(modify_item);
          }
        });
      });

      let current_config;
      if (this.state.currentConfigurationProfileToSend !== '') {
        current_config = this.state.currentConfigurationProfileToSend;
      } else {
        current_config = this.state.configurationProfileCurrent;
      }
      let payload = {
        data: {
          name: newGameName,
          status: this.state.statusCurrent,
          configuration_profile: current_config,
          icon: this.state.gameIcon,
          is_external: this.state.isExternal,
          developer: this.state.developerCurrent
        }
      };
      if (this.state.launchDate != '') {
        payload.data.launch_date = this.state.launchDate;
      }
      if (removed_platforms.length > 0) {
        payload.data.remove_platforms = removed_platforms;
      }

      if (added_platforms.length > 0) {
        payload.data.add_platforms = added_platforms;
      }

      if (added_game_params.length > 0) {
        payload.data.add_game_params = added_game_params;
      }

      if (removed_data.length > 0) {
        payload.data.remove_game_params = removed_data;
      }

      if (send_modify_data.length > 0) {
        payload.data.modify_game_params = send_modify_data;
      }

      const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
      const PATH_CALL = `/games/game/${this.state.gameSlug}/edit`;
      const url = `${PATH_BASE}${PATH_CALL}`;
      let headers = {
        'Access-Control-Origin': '*',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: storage.USER.token
      };

      return fetch(url, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(payload)
      })
        .then(response => {
          let status = response.status;
          let token = response.headers.get('token');
          let json = response.json();
          if (token != null) storage.USER.token = token;
          if (status === 200) {
            this.props.getIcons(true);
            window.sessionStorage.clear();
            return json;
          } else {
            return;
          }
        })
        .then(json => {
          if (json === undefined) return;
          this.props.history.push({
            pathname: '/gms/games'
          });
          return json;
        })
        .catch(error => {
          this.setState({ isFetching: false });
          console.error(error);
        });
    }
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onload = () => {
      this.setState({
        //gameIcon: file,
        gameIcon: reader.result
      });
    };
    reader.readAsDataURL(file);
  }

  selectOnFocus() {
    let newHeight = 100 + 30 * this.state.dropdownListSources.length;
    this.setState({ lastElementHeight: newHeight + 'px' });
  }

  selectOnBlur() {
    this.setState({ lastElementHeight: '0px' });
  }

  selectOnChange() {
    this.setState({ lastElementHeight: '0px' });
  }

  deleteAlert(alertId) {
    //let alertTable = this.state.alerts_list;
    //alertTable.splice(alertId, 1);
    //this.setState({ alerts_list: alertTable });
    this.setState({ isFetching: true, isAlertPopup: false });
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/games/game/${this.state.gameSlug}/resolve/${alertId}`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let payload = {
      data: {
        user_resolved: 'unknown'
      }
    };

    return fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status === 200) return json;
        else {
          this.setState({ isFetching: false });
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        this.fetchData();
        this.props.getIcons(true);
        return json;
      })
      .catch(error => {
        console.error(error);
      });
  }

  createAlert(comment, level) {
    this.setState({ isFetching: true, isAlertPopup: false });
    const PATH_BASE = storage.GAMES_SERVICE_URL[process.env.REACT_APP_BACKEND];
    const PATH_CALL = `/games/game/${this.state.gameSlug}/create_alert`;
    const url = `${PATH_BASE}${PATH_CALL}`;
    let headers = {
      'Access-Control-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: storage.USER.token
    };
    let payload = {
      data: {
        level: level,
        comment: comment
      }
    };

    return fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        let status = response.status;
        let json = response.json();
        if (status === 200) return json;
        else {
          this.setState({ isFetching: false });
          return;
        }
      })
      .then(json => {
        if (json === undefined) return;
        this.fetchData();
        this.props.getIcons(true);
        return json;
      })
      .catch(error => {
        console.error(error);
      });
  }

  addAlertPopup() {
    if (this.state.isAlertPopup) {
      this.setState({ isAlertPopup: false });
    } else {
      this.setState({ isAlertPopup: true });
    }
  }

  render() {
    if (!this.mounted) return;
    if (this.state.isFetching) {
      return <LoadingIndicator />;
    }

    let nameGame;
    if (this.state.gameNameEdit) {
      nameGame = (
        <GameNameEditContainer>
          <Textfield
            labelText="Name"
            id="addGameName"
            type="text"
            onChange={this.setNewGameName}
            value={this.state.newGameName}
          />
          <GameNameEditMode src={x_red} onClick={this.editName} />
        </GameNameEditContainer>
      );
    } else {
      nameGame = (
        <GameNameText>
          {this.state.gameName}
          <GameNameEdit src={edit_big} onClick={this.editName} />
        </GameNameText>
      );
    }

    let calendarBlock;
    if (this.state.statusCurrent == 'live-games') {
      calendarBlock = (
        <GameCalendar>
          <LabelForm htmlFor="gamesCalendar">Launch Date</LabelForm>
          <Calendar
            date={this.state.currentDate}
            type="oneDate"
            changeDate={this.setLaunchDate}
            name="addGamesCalendar"
            toRangeDate={moment().startOf('day')}
            placeholderVal={this.state.calendarPlaceholder}
          />
        </GameCalendar>
      );
    } else {
      calendarBlock = <div />;
    }
    Object.entries(storage.GAMES_DATA).map(([k, v]) => {
      let obj = {};
      obj['name'] = k;
      obj['value'] = v;
      //console.log('obj :',obj);
      //games_sort.push(obj);
    });
    return (
      <Fragment>
        <ViewTile>{'Edit Game'}</ViewTile>
        {this.state.openConfigPopup ? (
          <div>
            <BackgroundPopup />
            <PopupConfigurationProfile
              closeBtn={this.noChangeConfigProfile}
              yesBtn={this.yesChangeConfigProfile}
            />
          </div>
        ) : null}
        {this.state.openDeletePopup ? (
          <div>
            <BackgroundPopup />
            <PopupDelete
              closeBtn={this.noDeleteGameBtn}
              onChange={this.handleGameSlugDelete}
              yesBtn={this.deleteGame}
              slug={this.state.gameSlug}
              itemType={'game'}
              needUpdate={this.state.deleteGameUpdateBtn}
            />
          </div>
        ) : null}
        {this.state.openAddDeveloperPopup ? (
          <div>
            <BackgroundPopup />
            <PopupAddDeveloper
              closeBtn={() => this.setState({ openAddDeveloperPopup: false })}
              onChange={event => {
                const target = event.target;
                let needUpdate = false;
                if (target.value.length > 0) {
                  needUpdate = true;
                }
                this.setState({
                  addDeveloperUpdateBtn: needUpdate,
                  addDeveloperName: target.value
                });
              }}
              yesBtn={this.addDeveloper}
              slug={this.state.gameSlug}
              itemType={'game'}
              needUpdate={this.state.addDeveloperUpdateBtn}
            />
          </div>
        ) : null}
        <AddGameContainer>
          <IconContainer>
            <GameIcon src={this.state.gameIcon} />
            <ImgEditLabel htmlFor={'image-input'}>
              <img src={edit_big} />
            </ImgEditLabel>
            <EditImageInput
              id={'image-input'}
              ref={this.imageInputRef}
              type="file"
              onChange={this.handleImageChange}
            />
          </IconContainer>
          <OptionContainer>
            <GameName>{nameGame}</GameName>
            <DeleteGameContainer>
              <DelGame src={delete_btn} onClick={this.handleDeleteBtn} />
            </DeleteGameContainer>
            <Fragment>
              <GameDeveloper>
                <LabelForm htmlFor="addDeveloper">Developer</LabelForm>
                {/* <DropdownList
                  items={this.state.developersList}
                  name="addDeveloperList"
                  id="addDeveloper"
                  placeholder={this.state.developerPlaceholder}
                  uppercase={false}
                  handler={event => {
                    const target = event.target;

                    this.setState({
                      developerPlaceholder: '',
                      developerCurrent: target.value
                    });
                  }}
                  currentOpt={this.state.developerCurrent}
                  compareItemsKey={true}
                /> */}
                <DropdownList2
                  items={this.state.developersList}
                  name="addDeveloperList"
                  id="addDeveloper"
                  placeholder={this.state.developerPlaceholder}
                  handler={event => {
                    const target = event.target;

                    this.setState({
                      developerPlaceholder: '',
                      developerCurrent: target.value
                    });
                  }}
                  currentOption={this.state.developerCurrent}
                />
              </GameDeveloper>
              <AddDeveloper>
                <AddSourceImg
                  src={add_btn}
                  alt="add developer"
                  onClick={() => this.setState({ openAddDeveloperPopup: true })}
                />
              </AddDeveloper>
            </Fragment>
            <SliderContainer>
              <SwitchButton
                items={['Internal', 'External']}
                isInternal={this.state.isInternal}
                handleChange={this.changeIsExternal}
                labelText={this.state.gameExtInt}
              />
            </SliderContainer>
            <GameStatus>
              <LabelForm htmlFor="addGameStatus">Status</LabelForm>
              {/* <DropdownList
                items={this.state.statusList}
                name="addGameStatusList"
                id="addGameStatus"
                currentOpt={this.state.statusCurrent}
                uppercase={false}
                handler={this.selectStatus}
                compareItemsKey={true}
                placeholder={this.state.statusPlaceholder}
              /> */}
              <DropdownList2
                items={this.state.statusList}
                name="addGameStatusList"
                id="addGameStatus"
                currentOption={this.state.statusCurrent}
                handler={this.selectStatus}
                placeholder={this.state.statusPlaceholder}
              />
            </GameStatus>
            <GameConfProfile>
              <LabelForm htmlFor="addGamesConfProf">Configuration Profile</LabelForm>
              {/* <DropdownList
                items={this.state.configurationProfileList}
                name="addGamesConfProfList"
                id="addGamesConfProf"
                placeholder={this.state.configurationProfilePlaceholder}
                uppercase={false}
                currentOpt={this.state.configurationProfileCurrent}
                handler={this.selectConfigurationProfile}
                compareItemsKey={true}
              /> */}
              <DropdownList2
                items={this.state.configurationProfileList}
                name="addGamesConfProfList"
                id="addGamesConfProf"
                placeholder={this.state.configurationProfilePlaceholder}
                currentOption={this.state.configurationProfileCurrent}
                handler={this.selectConfigurationProfile}
              />
            </GameConfProfile>
            <ButtonChangeContainer>
              <Button
                needUpdate={this.state.configurationProfileChanged}
                onClick={this.openPopupChange}
              >
                CHANGE
              </Button>
            </ButtonChangeContainer>
            <GamePlatforms>
              <LabelForm htmlFor="addGamesPlatforms">Platforms</LabelForm>
              <CheckboxList
                withProperties={true}
                items={this.state.platformsList}
                id="addGamesPlatformsList"
                placeholder={this.state.platformsListPlaceholder}
                handleItems={this.checkPlatform}
                checkboxWidth={225}
              />
            </GamePlatforms>

            <GameDataSource>
              <LabelForm htmlFor="addGamesDataSource">Data Source</LabelForm>
              {/* <DropdownList
                items={this.state.dropdownListSources}
                name="addGamesDataSourceList"
                id="addGamesDataSource"
                placeholder={this.state.dataSourceDropdownListPlaceholder}
                uppercase={false}
                handler={this.selectDataSource}
                currentOpt={this.state.currentDataSource}
                onFocusHandler={() => {
                  this.selectOnFocus();
                }}
                onBlurHandler={() => {
                  this.selectOnBlur();
                }}
                onChangeHandler={() => {
                  this.selectOnChange();
                }}
                kvPairs={true}
              /> */}
              <DropdownList2
                items={this.state.dropdownListSources}
                name="addGamesDataSourceList"
                id="addGamesDataSource"
                placeholder={this.state.dataSourceDropdownListPlaceholder}
                handler={this.selectDataSource}
                currentOption={this.state.currentDataSource}
                onFocusHandler={() => {
                  this.selectOnFocus();
                }}
                onBlurHandler={() => {
                  this.selectOnBlur();
                }}
                onChangeHandler={() => {
                  this.selectOnChange();
                }}
              />
            </GameDataSource>
            {calendarBlock}
            <GameSelectedSources>
              {this.state.dataSourcesTable ? (
                <FilterList
                  items={this.state.dataSourcesTable}
                  deleteSource={this.deleteDataSource}
                  openCloseEdit={this.openCloseEditDataSource}
                  editText={this.editSourceValues}
                  confirmEdit={this.confirmEditSource}
                  cancelEdit={this.cancelEditSource}
                />
              ) : null}
            </GameSelectedSources>
            <AddSource>
              <AddSourceImg src={add_btn} alt="add source" onClick={this.addSourceToTable} />
            </AddSource>
            <ButtonContainer>
              <Button needUpdate={this.state.needSave} onClick={this.handleEditGame}>
                Save Changes
              </Button>
            </ButtonContainer>
            <EmptyDiv lastHeight={this.state.lastElementHeight} />
          </OptionContainer>
          <AlertContainer>
            <AlertPanels
              alerts_list={this.state.alerts_list}
              deleteAlert={this.deleteAlert}
              createAlert={this.createAlert}
              isAlertPopup={this.state.isAlertPopup}
              handleAlertPopup={this.addAlertPopup}
            />
          </AlertContainer>
        </AddGameContainer>
      </Fragment>
    );
  }
}

export default GmsEditGame;
//region Styles
const ButtonChangeContainer = styled.div`
  margin-top: 19px;
  grid-row: 4;
  grid-column: 2/3;
  width: 100px;
`;

const ViewTile = styled.h1`
  width: 95%;
  color: #fff;
  padding: 0rem 0rem 1%;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 4rem;
  margin-right: 3%;
  margin-left: 2%;
  margin-bottom: 1rem;
  z-index: 101;
  font-family: 'Titillium Web';
`;

const LabelForm = styled.label`
  font-size: 12px;
  color: #99999c;
  display: block;
  margin-bottom: 5px;
`;

const AddGameContainer = styled.div`
  display: grid;
  margin-top: 50px;
  grid-template-columns: 13% 20rem 55rem 14rem 50rem;
  grid-row-gap: 5rem;
`;

const IconContainer = styled.div`
  grid-row: 1;
  grid-column: 2/3;
  position: relative;
`;

const GameIcon = styled.img`
  border-radius: 30px;
  height: 130px;
  width: 130px;
`;

const OptionContainer = styled.div`
  grid-row: 1;
  grid-column: 3/4;
  display: grid;
  grid-template-columns: 25rem 25rem 10rem;
  grid-row-gap: 20px;
  grid-column-gap: 10px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  margin-bottom: 50px;
`;

const AlertContainer = styled.div`
  margin-top: 20px;
  grid-column: 5/6;
  grid-row: 1;
`;

const GameCalendar = styled.div`
  grid-row: 3;
`;
const DeleteGameContainer = styled.div`
  grid-row: 1;
  grid-column: 2/3;
`;

const SliderContainer = styled.div`
  grid-row: 2;
  grid-column: 2/3;
  display: flex;
  margin-left: 4rem;
  margin-top: 17px;
`;

const DelGame = styled.img`
  cursor: pointer;
`;

const GameName = styled.div`
  position: relative;

  grid-column: 1/2;
  grid-row: 1;
`;

const GameNameText = styled.div`
  font-size: 16px;
  color: rgb(225, 225, 229);
  letter-spacing: 0.05rem;
  font-weight: bolder;
  font-family: 'Titillium Web';
`;

const GameStatus = styled(GameName)`
  grid-row: 3;
`;

const GameConfProfile = styled(GameName)`
  grid-row: 4;
`;

const GamePlatforms = styled(GameName)`
  grid-row: 5;
`;

const GameDeveloper = styled(GameName)`
  grid-row: 2;
  grid-column: 1/2;
`;

const AddDeveloper = styled.div`
  margin-top: 24px;
  grid-row: 2;
  grid-column: 2/3;
`;

const GameDataSource = styled(GameName)`
  grid-row: 6;
`;

const AddSource = styled.div`
  margin-top: 24px;
  grid-row: 6;
  grid-column: 2/3;
`;

const AddSourceImg = styled.img`
  cursor: pointer;
`;

const GameSelectedSources = styled(GameName)`
  grid-row: 8;
  grid-column: 1/55;
`;

const ButtonContainer = styled.div`
  grid-row: 9;
  grid-column: 1/2;
  margin-top: 20px;
  height: 32px;
`;
const EmptyDiv = styled.div`
  grid-row: 10;
  grid-column: 1/2;
  height: ${props => (props.lastHeight ? props.lastHeight : '90px')};
  display: block;
`;

const ImgEditLabel = styled.label`
  cursor: pointer;
`;

const EditImageInput = styled.input`
  display: none;
`;

const GameNameEditContainer = styled.div`
  position: absolute;
  top: -21px;
  width: 90%;
`;

const GameNameEdit = styled.img`
  display: inline;
  margin-left: 10px;
  cursor: pointer;
`;

const GameNameEditMode = styled(GameNameEdit)`
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  top: 28px;
  left: 225px;
`;

const BackgroundPopup = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
  background-color: #282b30;
  opacity: 0.7;
  z-index: 100;
`;
//endregion
