import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import TitleBar from 'components/TitleBar';
import GmsGames from './GmsGames';
import GmsAddGame from './GmsAddGame';
import GmsAddData from './GmsAddData';
import GmsAddProfile from './GmsAddProfile';
import GmsAddPlatform from './GmsAddPlatform';
import GmsData from './GmsData';
import GmsProfiles from './GmsProfiles';
import GmsPlatforms from './GmsPlatforms';
import styled from 'styled-components';
import { Content } from 'views/App';
import GmsEditGame from './GmsEditGame';
import GmsEditData from './GmsEditData';
import GmsEditProfile from './GmsEditProfile';
import GmsEditPlatform from './GmsEditPlatform';
import { AppContext } from 'views/App';

//region Routes
const routes = [
  {
    path: '/gms',
    exact: true,
    view: GmsGames
  },
  {
    path: '/gms/games/',
    exact: true,
    view: GmsGames
  },
  {
    path: '/gms/profiles/',
    exact: true,
    view: GmsProfiles
  },
  {
    path: '/gms/platforms/',
    exact: true,
    view: GmsPlatforms
  },
  {
    path: '/gms/data/',
    exact: true,
    view: GmsData
  },
  {
    path: '/gms/games/add',
    exact: false,
    view: GmsAddGame
  },
  {
    path: '/gms/data/add',
    exact: false,
    view: GmsAddData
  },
  {
    path: '/gms/data/edit',
    exact: false,
    view: GmsEditData
  },
  {
    path: '/gms/profiles/add',
    exact: false,
    view: GmsAddProfile
  },
  {
    path: '/gms/platforms/add',
    exact: false,
    view: GmsAddPlatform
  },
  {
    path: '/gms/games/edit',
    exact: false,
    view: GmsEditGame
  },
  {
    path: '/gms/data/edit',
    exact: false,
    view: GmsEditData
  },
  {
    path: '/gms/profiles/edit',
    exact: false,
    view: GmsEditProfile
  },
  {
    path: '/gms/platforms/edit',
    exact: false,
    view: GmsEditPlatform
  }
];
//endregion
class GamesManagement extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <Fragment>
        <TitleBar
          name={this.props.user.first_name}
          title={this.props.title}
          color={this.props.color}
          logout={this.props.logoutCall}
          history={this.props.history}
        />
        <Content>
          <AppContext.Consumer>
            {({ getGamesIcons, logout }) => (
              <Switch>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    id={index}
                    path={route.path}
                    exact={route.exact}
                    render={props => (
                      <route.view
                        showGameDetails={this.showGameDetails}
                        updatePrivCall={this.props.updatePrivCall}
                        privilegesUpdated={this.props.privilegesUpdated}
                        getIcons={getGamesIcons}
                        {...props}
                      />
                    )}
                    //component={route.view}
                  />
                ))}
                <Route render={() => <Redirect to="/gms/games" />} />
              </Switch>
            )}
          </AppContext.Consumer>
        </Content>
      </Fragment>
    );
  }
}
export default GamesManagement;
