import React, { Component, Fragment } from 'react';
import TitleBar from 'components/TitleBar';
import './style.css';

class Dev extends Component {
  render() {
    return (
      <Fragment>
        <TitleBar title={this.props.title} color={this.props.color} />
      </Fragment>
    );
  }
}

export default Dev;
