import React, { Component, Fragment } from 'react';
import { AppContext } from 'views/App';
import styled from 'styled-components';
import { Content } from 'views/App';
import posed, { PoseGroup } from 'react-pose';

/**Animated Vieww*/
//region  Routes

//endregion

class AnimatedView extends Component {
  /**Admin Lifecycle Methods*/

  render() {
    return (
      <PoseGroup animateOnMount preenterpose={'initial'}>
        <Container
          onPoseComplete={() => {
            if (this.props.onPoseComplete) this.props.onPoseComplete();
          }}
          withParent={false}
          initialPose={'initial'}
          key={this.props.animKey ? this.props.animKey : 'randomViewKey'}
        >
          {this.props.children}
        </Container>
      </PoseGroup>
    );
  }
  //endregion
}
export default AnimatedView;

/**Animated View Styles*/
//region  Styles

const Container = styled(
  posed.div({
    enter: {
      opacity: 1,
      delay: 100,
      transition: { duration: 500 }
    },
    exit: { opacity: 0, transition: { duration: 500 } },
    initial: { opacity: 0 }
  })
)`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #2f3238;
  padding-top: 1rem;
  opacity: 0;
`;

const Container2 = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #2f3238;
  padding-top: 1rem;
`;

//endregion
