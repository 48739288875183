import React, { Component, PureComponent, Fragment } from 'react';
import { defaults, Line } from 'react-chartjs-2';
import styled from 'styled-components';
import './style.css';
const Canvas = styled.div`
  padding: 3px;
  margin: 0;
`;

const InfoPanel = styled.div`
  padding-top: 8px;
`;

const DetailContent = styled.div`
  // width: 135px;
`;

class DetailInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noValue: false
    };
    this.parseChartData = this.parseChartData.bind(this);
  }

  componentDidMount() {
    if (this.props.value.props === undefined) {
      this.setState({ noValue: false });
    } else if (
      this.props.value !== undefined &&
      this.props.value.props.children !== undefined &&
      this.props.value.props.children === 'No data'
    ) {
      this.setState({ noValue: true });
    } else {
      this.setState({ noValue: false });
    }
  }

  parseChartData(data, dates, color) {
    let labels_data = dates;
    return {
      labels: labels_data,
      datasets: [
        {
          label: 'd1',
          fill: true,
          borderWidth: 2,
          borderColor: 'rgba(' + color + ',0.8)',
          backgroundColor: 'rgba(' + color + ',0.45)',
          data: data,
          lineTension: 0
        }
      ]
    };
  }

  render() {
    if (!this.props.showData) return <div>...</div>;
    let data = this.parseChartData(this.props.values, this.props.dates, this.props.color);
    let dt;
    dt = Object.assign({}, data);

    return (
      <DetailContent>
        {this.state.noValue ? (
          <div>{this.props.value}</div>
        ) : (
          <Fragment>
            <InfoPanel>
              {this.props.value}
              {this.props.info}
            </InfoPanel>
            <Canvas>
              <Line
                width={140}
                height={25}
                ref="chart"
                data={dt}
                type="line"
                options={{
                  responsive: false,
                  legend: {
                    display: false
                  },
                  elements: {
                    line: {
                      tension: 0,
                      borderWidth: 1
                    },
                    point: { radius: 0, hitRadius: 0, hoverRadius: 0 }
                  },
                  tooltips: {
                    enabled: false
                  },
                  scales: {
                    yAxes: [
                      {
                        display: false
                      }
                    ],
                    xAxes: [
                      {
                        display: false
                      }
                    ]
                  }
                }}
              />
            </Canvas>
          </Fragment>
        )}
      </DetailContent>
    );
  }
}

export default DetailInfo;
