import React, { Component, Fragment } from 'react';

import styled from 'styled-components';
const OptionLabel = styled.label`
  padding: 0 0px 0 10px;
  min-height: 30px;
  display: flex;
  align-items: center;
  background: #282b30;
  border: #141617 solid 0.5px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 10px;
  opacity: 0;
  pointer-events: none;
  order: 2;
  color: ${props => (props.color ? props.color : 'white')};
  // z-index: 1;
  transition: background 0.4s ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 3px;
  &:hover {
    background: #1e2022;
    border-radius: 3px;
  }
  &:active {
    opacity: 0;
  }
`;

const Select = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 30px;
  color: rgb(225, 225, 229);
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  letter-spacing: 0.07em;
  border-radius: 3px;
  outline: none;
  background-color: #1e2022;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  cursor: pointer;
  &:focus ${OptionLabel} {
    position: relative;
    pointer-events: all;
    cursor: pointer;
    z-index: 30;
    opacity: 1;
  }
`;

const OptionInput = styled.input`
  opacity: 0;
  position: absolute;
  left: -99999px;
`;

const CurrentOptionInput = styled(OptionInput)`
  + ${OptionLabel} {
    order: 1;
    opacity: 1;
    height: 30px;
    padding-top: 2px;
    background-color: #1e2022;
    border-top: none;
    position: relative;
    top: 0px;
    border-radius: 3px;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.2), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  }

  + ${OptionLabel}:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #00d5a9;
    position: absolute !important;
    right: 10px;
    top: calc(50% - 2.5px);
    pointer-events: none;
    //z-index: 1;
  }

  + ${OptionLabel}:before {
    position: absolute !important;
    right: 0;
    height: 28px;
    width: 28px;
    content: '';
  }
`;

class DropdownList extends Component {
  constructor(props) {
    super(props);

    this.parseItems = this.parseItems.bind(this);
  }

  parseItems(items, name, current) {
    let itemList = [];
    let handler;
    if (this.props.param != undefined) {
      handler = e => {
        this.props.handler(e, this.props.param);
      };
    } else if (this.props.param == undefined) {
      handler = this.props.handler;
    }
    let i = 0;
    if (this.props.placeholder !== '' && this.props.placeholder !== undefined) {
      itemList.unshift(
        <CurrentOptionInput
          name="placeholder"
          type="radio"
          key={i + name + 'placeholder'}
          id={i + name + 'placeholder'}
          value="none"
          onClick={() => console.log('')}
        />,
        <OptionLabel key={i + 'placeholder'} htmlFor={i + name + 'placeholder'}>
          {this.props.placeholder}
        </OptionLabel>
      );
    }

    if (this.props.compareItemsKey) {
      //Compare current value with object key, display object value, take object key as value
      Object.entries(items).map(([key, value]) => {
        i++;
        if (current == key) {
          itemList.push(
            <CurrentOptionInput
              name={key}
              type="radio"
              key={key + i + 'current'}
              id={key + name}
              value={key}
              onClick={handler}
            />,
            <OptionLabel key={key + 'curr'} htmlFor={key + name}>
              {value}
            </OptionLabel>
          );
        } else {
          itemList.push(
            <OptionInput
              name={key}
              type="radio"
              key={key + i + name}
              id={key + name}
              value={key}
              onClick={handler}
            />,
            <OptionLabel key={key + i} htmlFor={key + name}>
              {value}
            </OptionLabel>
          );
        }
      });
    } else if (this.props.kvPairs) {
      //list of objects: [{key:slug, value: Displayed Name}, ...]
      Object.entries(items).map(([key, value]) => {
        i++;

        if (current == value.key) {
          itemList.push(
            <CurrentOptionInput
              name={value.value}
              type="radio"
              key={value.key + i + 'current'}
              id={value.key + name}
              value={value.key}
              onClick={handler}
            />,
            <OptionLabel
              color={value.color}
              key={value.key + i + 'curr'}
              htmlFor={value.key + name}
            >
              {value.value}
            </OptionLabel>
          );
        } else {
          itemList.push(
            <OptionInput
              name={value.value}
              type="radio"
              key={value.key.toString().concat(i) + name}
              id={value.key + name}
              value={value.key}
              onClick={handler}
            />,
            <OptionLabel
              color={value.color}
              key={value.key.toString().concat(i)}
              htmlFor={value.key + name}
            >
              {value.value}
            </OptionLabel>
          );
        }
      });
    } else {
      Object.entries(items).map(([key, value]) => {
        //Default behaviour - array as items argument, items value is displayed and taken as list value
        i++;
        if (current === value) {
          itemList.push(
            <CurrentOptionInput
              name={value}
              type="radio"
              key={value + i + name + 'current'}
              id={value + name}
              value={value}
              onClick={handler}
            />,
            <OptionLabel key={value + i + 'curr'} htmlFor={value + name}>
              {value}
            </OptionLabel>
          );
        } else {
          itemList.push(
            <OptionInput
              name={value}
              type="radio"
              key={value + i + name}
              id={value + name}
              value={value}
              onClick={handler}
            />,
            <OptionLabel key={value + i} htmlFor={value + name}>
              {value}
            </OptionLabel>
          );
        }
      });
    }
    return itemList;
  }

  render() {
    //console.log('DL', this.props);
    let list = this.parseItems(this.props.items, this.props.name, this.props.currentOpt);
    let select;
    if (this.props.uppercase === false) {
      select = (
        <Select
          onFocus={this.props.onFocusHandler}
          onBlur={this.props.onBlurHandler}
          onChange={this.props.onChangeHandler}
          tabIndex="1"
        >
          {list}
        </Select>
      );
    } else {
      select = (
        <Select
          uppercase
          onFocus={this.props.onFocusHandler}
          onBlur={this.props.onBlurHandler}
          onChange={this.props.onChangeHandler}
          tabIndex="1"
        >
          {list}
        </Select>
      );
    }
    return <Fragment>{select}</Fragment>;
  }
}

export default DropdownList;
