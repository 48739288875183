import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

export const Button = styled.button`
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(30, 32, 34);
  border-radius: 3px;
  font-size: 13px;
  font-family: 'Roboto';
  color: rgb(255, 255, 255);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  text-align: center;
  background-color: #555555;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.36), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
  cursor: ${props => (props.needUpdate ? 'pointer' : 'initial')};
  background-image: ${props =>
    props.needUpdate
      ? '-webkit-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%)'
      : '-webkit-linear-gradient(90deg, rgb(133,133,133) 0%, rgb(214,214,214) 100%)'};
  &:hover {
    background-image: ${props =>
      props.needUpdate
        ? '-webkit-linear-gradient(90deg, rgb(23, 132, 113) 0%, rgb(0, 213, 169) 100%)'
        : '-webkit-linear-gradient( 90deg, rgb(113,113,113) 0%, rgb(149,149,149) 100%)'};
  }
  &:focus {
    outline: none;
  }
`;

const CommonButtonStyle = styled.button`
  width: ${props => (props.width ? props.width : '100%')};
  height: 100%;
  max-width: 50rem;
  max-height: 15rem;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(30, 32, 34);
  border-radius: 3px;
  font-size: 1.3rem;
  line-height: 3rem;
  font-family: 'Roboto', sans-serif;
  color: rgb(255, 255, 255);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  text-align: center;

  background-image: -moz-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.36), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
  cursor: pointer;
  box-sizing: border-box;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

  &:hover {
    background-image: -moz-linear-gradient(90deg, rgb(23, 132, 113) 0%, rgb(0, 213, 169) 100%);
    background-image: -webkit-linear-gradient(90deg, rgb(23, 132, 113) 0%, rgb(0, 213, 169) 100%);
    background-image: -ms-linear-gradient(90deg, rgb(23, 132, 113) 0%, rgb(0, 213, 169) 100%);
  }

  &:focus {
    outline: none;
  }
`;

const CancelButtonStyle = styled(CommonButtonStyle)`
  background-image: -moz-linear-gradient(90deg, rgb(180, 55, 55) 0%, rgb(219, 70, 70) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(180, 55, 55) 0%, rgb(219, 70, 70) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(180, 55, 55) 0%, rgb(219, 70, 70) 100%);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.36), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
  &:hover {
    background-image: -moz-linear-gradient(90deg, rgb(146, 23, 23) 0%, rgb(195, 51, 51) 100%);
    background-image: -webkit-linear-gradient(90deg, rgb(146, 23, 23) 0%, rgb(195, 51, 51) 100%);
    background-image: -ms-linear-gradient(90deg, rgb(146, 23, 23) 0%, rgb(195, 51, 51) 100%);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.36), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
  }
`;

class CommonButton extends Component {
  render() {
    return (
      <Fragment>
        {this.props.cancel ? (
          <CancelButtonStyle
            type={this.props.type ? this.props.type : 'button'}
            style={{ background: this.props.disabled ? '#555555' : this.props.color }}
            onClick={this.props.disabled ? null : this.props.onClick}
            width={this.props.width}
          >
            {this.props.text}
          </CancelButtonStyle>
        ) : (
          <CommonButtonStyle
            type={this.props.type ? this.props.type : 'button'}
            style={{ background: this.props.disabled ? '#555555' : this.props.color }}
            onClick={this.props.disabled ? null : this.props.onClick}
            width={this.props.width}
          >
            {this.props.text}
          </CommonButtonStyle>
        )}
      </Fragment>
    );
  }
}

export default CommonButton;
