import React, { Component, PureComponent } from 'react';
import styled, { injectGlobal, keyframes } from 'styled-components';
import currency from 'utils/currencyFormatter';
import numberFormatter from 'utils/numberFormatter';
import translateName from 'utils/translateColumnName';

import { Scrollbars } from 'react-custom-scrollbars';

let color = '#ffffff';

class CampaignsTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      scrollTop: 0,
      scrollHeight: 0,
      clientHeight: 0
    };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.renderThumbHorizontal = this.renderThumbHorizontal.bind(this);
    this.renderTrackHorizontal = this.renderTrackHorizontal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.navigationCellHandler = this.navigationCellHandler.bind(this);
    this.scrollFinished = this.scrollFinished.bind(this);
    this.timer = -1;
  }

  componentDidMount() {
    window.removeEventListener('scroll', this.handleScroll, true);
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  navigationCellHandler(selectedItem, tab) {
    this.props.tableNavigationHandler(selectedItem, tab);
  }
  scrollFinished() {
    this.forceUpdate();
  }
  handleScroll(event) {
    if (this.tableScroll && this.tableScroll === event.target) {
      if (this.timer != -1) window.clearTimeout(this.timer);
      this.timer = window.setTimeout(this.scrollFinished, 250);
      //this.tableScroll.scrollLeft = 100;
    }
  }
  handleUpdate(values) {
    const { shadowTop, shadowBottom } = this.refs;
    const { scrollLeft, scrollWidth, clientWidth } = values;
    const shadowTopOpacity = (1 / 20) * Math.min(scrollLeft, 20);
    const bottomScrollTop = scrollWidth - clientWidth;
    const shadowBottomOpacity =
      (1 / 20) * (bottomScrollTop - Math.max(scrollLeft, bottomScrollTop - 20));
    shadowTop.setAttribute(
      'style',
      ` 
        opacity: ${shadowTopOpacity};  position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 5px;
      background-color: #05dd88;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0) 100%)
      `
    );

    shadowBottom.setAttribute(
      'style',
      ` 
        opacity: ${shadowBottomOpacity};  position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 5px;
      background-color: #05dd88;
      background: linear-gradient(to left, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0) 100%)
      `
    );

    // css(shadowTop, { opacity: shadowTopOpacity });
    //css(shadowBottom, { opacity: shadowBottomOpacity });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let updatedItems = this.props.items;

    updatedItems[value] = target.checked;
    this.props.changeHandler(this.props.id, updatedItems);
  }
  renderThumbHorizontal({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: '#00D5A9',
      borderRadius: '10px'
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  renderTrackHorizontal({ style, ...props }) {
    const trackStyle = {
      position: 'absolute',
      width: '100%',
      right: '2px',
      bottom: '2px',

      borderRadius: '3px',
      backgroundColor: 'rgba(0,0,0,0.05)'
    };
    return <div style={{ ...style, ...trackStyle }} {...props} />;
  }

  checkFilters(item) {
    let show = true;
    let filters = this.props.filters;
    for (let i = 0; i < this.props.filters.length; i++) {
      let name = translateName(filters[i].name);
      if (filters[i].modifier === '>=') {
        if (
          parseInt(this.props.acquisitionData.records[item].totals[name]) <
          parseInt(filters[i].value)
        ) {
          show = false;
          break;
        }
      } else if (filters[i].modifier === '<') {
        if (
          parseInt(this.props.acquisitionData.records[item].totals[name]) >=
          parseInt(filters[i].value)
        ) {
          show = false;
          break;
        }
      }
    }
    return show;
  }

  render() {
    const shadowTopStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      height: '100%',
      width: 10,
      backgroundColor: '#05dd88',
      background: 'linear-gradient(to left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)'
    };
    const shadowBottomStyle = {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      height: '100%',
      width: 10,
      backgroundColor: '#d6cc0d',
      background: 'linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)'
    };
    let dataToDisplayArr = [];
    Object.entries(this.props.checkboxItems).map(([key, val]) => {
      Object.entries(val).map(([k, v]) => {
        if (v === true) dataToDisplayArr[k] = v;
      });
    });

    let dataToDisplayTotalsArr = { ...dataToDisplayArr };
    if (dataToDisplayTotalsArr['Revenue to date']) delete dataToDisplayTotalsArr['Revenue to date'];

    let sourcesLink = null;
    let campaignsLink = null;
    let adsetsLink = null;
    let info = null;
    let title = 'Media Source';

    let navigationCells = [];

    let currentSort = translateName(this.props.currentSort);

    let dataLength = Object.keys(dataToDisplayArr).length;

    let scrollLeft = 0;
    if (this.tableScroll) {
      scrollLeft = this.tableScroll.scrollLeft;
    }

    const recordsSorted = Object.keys(this.props.acquisitionData.records).sort((a, b) => {
      if (
        this.props.acquisitionData.records[a].totals[currentSort] <
        this.props.acquisitionData.records[b].totals[currentSort]
      )
        if (this.props.sorting === 'asc') return 1;
        else return -1;
      if (
        this.props.acquisitionData.records[a].totals[currentSort] >
        this.props.acquisitionData.records[b].totals[currentSort]
      )
        if (this.props.sorting === 'asc') return -1;
        else return 1;
      return 0;
    });

    if (this.props.tabularGroup === 'source') {
      //source|campaign|adset
      title = 'Media Source';
      sourcesLink = (
        <InfoLink key="sourceInfoLinkKey" color={'#00d5a9'}>
          All Media Sources
        </InfoLink>
      );

      recordsSorted.map(item => {
        let elementKey = item + '-' + this.props.id;
        let name = item;
        if (item.length > 17) name = item.substr(0, 17) + '...';
        if (
          this.props.tabularGroup === 'source' &&
          this.props.filtersData.sources[item] === true &&
          this.checkFilters(item)
        )
          navigationCells.push(
            <NavigationPanelCell
              key={elementKey}
              onClick={() => {
                this.navigationCellHandler(item, 'campaign');
              }}
            >
              {name}
            </NavigationPanelCell>
          );
      });
    }

    if (this.props.tabularGroup === 'campaign') {
      //source|campaign|adset

      sourcesLink = (
        <InfoLink
          onClick={() => {
            this.navigationCellHandler('', 'source');
          }}
          key={'campaignInfoLinkKey'}
          color={'#99999c'}
        >
          All Media Sources
        </InfoLink>
      );
      campaignsLink = [
        <Separator key={'separatorInfoLinkKey'}>{'>'}</Separator>,
        <InfoLink key={'campaignInfoLinkKey'} color={'#00d5a9'}>{`${
          this.props.selectedSource
        }`}</InfoLink>
      ];
      title = 'Campaign';
      recordsSorted.map(item => {
        let elementKey = item + '-' + this.props.id;
        let name = item;
        if (item.length > 17) name = item.substr(0, 17) + '...';
        if (
          this.props.tabularGroup === 'campaign' &&
          this.props.filtersData.campaigns[item] === true &&
          this.checkFilters(item)
        )
          navigationCells.push(
            <NavigationPanelCell
              key={item}
              onClick={() => {
                this.navigationCellHandler(item, 'adset');
              }}
            >
              {name}
            </NavigationPanelCell>
          );
      });
    }
    if (this.props.tabularGroup === 'adset') {
      //source|campaign|adset
      title = 'Adset';

      sourcesLink = (
        <InfoLink
          onClick={() => {
            this.navigationCellHandler('', 'source');
          }}
          key={'adsetInfoLinkKey'}
          color={'#99999c'}
        >
          All Media Sources
        </InfoLink>
      );
      campaignsLink = [
        <Separator key={'separatorInfoLinkKey'}>{'>'}</Separator>,
        <InfoLink
          onClick={() => {
            this.navigationCellHandler('', 'campaign');
          }}
          key={'campaignInfoLinkKey'}
          color={'#99999c'}
        >{`${this.props.selectedSource}`}</InfoLink>
      ];
      adsetsLink = [
        <Separator key={'separatorInfoLinkKey'}>{'>'}</Separator>,
        <InfoLink key={'campaignInfoLinkKey'} color={'#00d5a9'}>{`${
          this.props.selectedCampaign
        }`}</InfoLink>
      ];
      recordsSorted.map(item => {
        let elementKey = item + '-' + this.props.id;
        let name = item;
        if (item.length > 17) name = item.substr(0, 17) + '...';
        if (this.props.tabularGroup === 'adset' && this.checkFilters(item))
          navigationCells.push(<NavigationPanelCell key={item}>{name}</NavigationPanelCell>);
      });
    }

    info = [sourcesLink, campaignsLink, adsetsLink];

    //Media Source
    return (
      <Container>
        <InfoPanel key={'infoPanelKey'}>{info}</InfoPanel>
        <NavigationPanel>
          <NavigationPanelTitle>{title}</NavigationPanelTitle>
          {navigationCells}
        </NavigationPanel>
        <Panel ref={el => (this.tableScroll = el)}>
          <DataPanel onUpdate={this.handleUpdate}>
            <Headers length={dataLength}>
              {this.props.acquisitionData.dates.map((item, index) => {
                let elementKey = 'DataHeader' + '-' + item;
                if (Math.abs(index * dataLength * 140 - scrollLeft) < 2240) {
                  return (
                    <DataHeader key={elementKey} title={item} checkboxItems={dataToDisplayArr} />
                  );
                } else return <DataHeaderEmpty title={item} key={elementKey} />;
              })}
              <DataHeader key={'totals'} title={'Totals'} checkboxItems={dataToDisplayTotalsArr} />
            </Headers>
            {recordsSorted.map(item => {
              let i = Math.floor(Math.random() * 9999);
              let elementKey = item + '-' + i;
              if (
                this.props.tabularGroup === 'source' &&
                this.props.filtersData.sources[item] === true &&
                this.checkFilters(item)
              ) {
                return (
                  <DataRow key={elementKey} length={dataLength}>
                    {this.props.acquisitionData.records[item].data_set.map((dataItem, index) => {
                      let elementKey = 'DataHeader' + '-' + dataItem.date;
                      if (Math.abs(index * dataLength * 140 - scrollLeft) < 2240) {
                        return (
                          <DataSet
                            key={elementKey}
                            checkboxItems={dataToDisplayArr}
                            data={dataItem}
                          />
                        );
                      } else return <EmptyDataCell key={elementKey} />;
                    })}
                    <DataSet
                      key={elementKey}
                      checkboxItems={dataToDisplayTotalsArr}
                      data={this.props.acquisitionData.records[item].totals}
                    />
                  </DataRow>
                );
              } else if (this.props.tabularGroup === 'campaign' && this.checkFilters(item)) {
                return (
                  <DataRow key={elementKey} length={dataLength}>
                    {this.props.acquisitionData.records[item].data_set.map((dataItem, index) => {
                      let elementKey = 'DataHeader' + '-' + dataItem.date;
                      if (Math.abs(index * dataLength * 140 - scrollLeft) < 2240) {
                        return (
                          <DataSet
                            key={elementKey}
                            checkboxItems={dataToDisplayArr}
                            data={dataItem}
                          />
                        );
                      } else return <EmptyDataCell key={elementKey} />;
                    })}
                    <DataSet
                      key={elementKey}
                      checkboxItems={dataToDisplayTotalsArr}
                      data={this.props.acquisitionData.records[item].totals}
                    />
                  </DataRow>
                );
              } else if (this.props.tabularGroup === 'adset' && this.checkFilters(item)) {
                return (
                  <DataRow key={elementKey} length={dataLength}>
                    {this.props.acquisitionData.records[item].data_set.map((dataItem, index) => {
                      let elementKey = 'DataHeader' + '-' + dataItem.date;
                      if (Math.abs(index * dataLength * 140 - scrollLeft) < 2240) {
                        return (
                          <DataSet
                            key={elementKey}
                            checkboxItems={dataToDisplayArr}
                            data={dataItem}
                          />
                        );
                      } else return <EmptyDataCell key={elementKey} />;
                    })}
                    <DataSet
                      key={elementKey}
                      checkboxItems={dataToDisplayTotalsArr}
                      data={this.props.acquisitionData.records[item].totals}
                    />
                  </DataRow>
                );
              }
            })}
          </DataPanel>
        </Panel>
        <UnreliableInfo>*-Unreliable</UnreliableInfo>
      </Container>
    );
  }
}

const DataHeaderEmpty = props => {
  const { title } = props;

  const Panel = styled.div`
    height: 6rem;
    width: 100%;

    :nth-child(even) {
      background-color: #1e2022;
    }
    :nth-child(odd) {
      background-color: #24272b;
    }
  `;

  const DateCell = styled.div`
    height: 3rem;
    color: #e1e1e5;
    // background-color: rgb(36, 39, 43);
    letter-spacing: 0.1rem;
    line-height: 3rem;
    border-radius: 3px;
    border-style: solid;
    border-top-width: 1px;
    border-right-width: 0px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-color: rgb(54, 57, 63);
    //background-color: rgb(48, 12, 24);
  `;

  return (
    <Panel>
      <DateCell>{title}</DateCell>
    </Panel>
  );
};
const DataHeader = props => {
  const { title, checkboxItems } = props;
  //region DataHeader Styles
  const Panel = styled.div`
    height: 6rem;
    //width: 100%;
    display: grid;
    grid-auto-columns: ${props => (props.length > 1 ? '140px' : '140px')};
    grid-template-rows: 3rem 3rem;
    :nth-child(even) {
      background-color: #1e2022;
    }
    :nth-child(odd) {
      background-color: #24272b;
    }
  `;

  const DateCell = styled.div`
    height: 3rem;
    color: #e1e1e5;
    // background-color: rgb(36, 39, 43);
    letter-spacing: 0.1rem;
    line-height: 3rem;
    grid-auto-columns: ${props => (props.length > 1 ? '140px' : '140px')};
    grid-column: 1 / span ${props => props.length};
    border-radius: 3px;
    border-style: solid;
    border-top-width: 1px;
    border-right-width: 0px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-color: rgb(54, 57, 63);
    //background-color: rgb(48, 12, 24);
  `;

  const Cells = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: ${props => (props.length > 1 ? '140px' : '140px')};

    grid-gap: 0px;
    //justify-items: start;
    height: 3rem;
    //width: 100%;
    grid-column: 1 / span ${props => props.length};

    // background-color: rgb(197,190,138);
  `;
  const DataCell = styled.div`
    height: 3rem;
    //width: 100%;
    color: #e1e1e5;
    //  background-color: rgb(36, 39, 43);
    letter-spacing: 0.075rem;
    line-height: 3rem;
    border-radius: 3px;
    border-style: solid;
    border-top-width: 0px;
    border-right-width: 0px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-color: rgb(54, 57, 63);
  `;
  //endregion
  return (
    <Panel length={Object.keys(checkboxItems).length}>
      <DateCell length={Object.keys(checkboxItems).length}>{title}</DateCell>
      <Cells length={Object.keys(checkboxItems).length}>
        {checkboxItems['Installs'] === true && <DataCell>Installs</DataCell>}
        {checkboxItems['Revenue'] === true && <DataCell>Revenue</DataCell>}
        {checkboxItems['Revenue to date'] === true && <DataCell>Revenue to date</DataCell>}
        {checkboxItems['7d ROI'] === true && <DataCell>7d ROI</DataCell>}
        {checkboxItems['1m ROI'] === true && <DataCell>1m ROI</DataCell>}
        {checkboxItems['3m ROI'] === true && <DataCell>3m ROI</DataCell>}
        {checkboxItems['6m ROI'] === true && <DataCell>6m ROI</DataCell>}
        {checkboxItems['9m ROI'] === true && <DataCell>9m ROI</DataCell>}
        {checkboxItems['12m ROI'] === true && <DataCell>12m ROI</DataCell>}
        {checkboxItems['CPI'] === true && <DataCell>CPI</DataCell>}
        {checkboxItems['7d LTV'] === true && <DataCell>7d LTV</DataCell>}
        {checkboxItems['1m LTV'] === true && <DataCell>1m LTV</DataCell>}
        {checkboxItems['3m LTV'] === true && <DataCell>3m LTV</DataCell>}
        {checkboxItems['6m LTV'] === true && <DataCell>6m LTV</DataCell>}
        {checkboxItems['9m LTV'] === true && <DataCell>9m LTV</DataCell>}
        {checkboxItems['12m LTV'] === true && <DataCell>12m LTV</DataCell>}
        {checkboxItems['Impressions'] === true && <DataCell>Impressions</DataCell>}
        {checkboxItems['Clicks'] === true && <DataCell>Clicks</DataCell>}
        {checkboxItems['Conversion rate'] === true && <DataCell>Conversion rate</DataCell>}
      </Cells>
    </Panel>
  );
};

const DataSet = props => {
  const { checkboxItems, data } = props;
  //region DataSet Styles
  const DataCells = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: ${props => (props.length > 1 ? '140px' : '140px')};

    grid-gap: 0px;
    //justify-items: start;
    height: 4.5rem;
    //width: 100%;
    :nth-child(even) {
      background-color: #24272b;
    }
    :nth-child(odd) {
      background-color: #282b30;
    }
    // background-color: rgb(197,190,138);
  `;

  const DataCell = styled.div`
    height: 4.5rem;
    //width: 100%;
    color: #e1e1e5;
    // background-color: #282b30;
    letter-spacing: 0.075rem;
    line-height: 4.5rem;
    border-radius: 3px;
    border-style: solid;
    border-top-width: 0px;
    border-right-width: 0px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-color: rgb(54, 57, 63);

    // background-color: rgb(197,190,138);
  `;
  //endregion
  const ifReliable = (value, name = '') => {
    if (data.predictions_reliable) {
      return value;
    } else {
      return `${value}*`;
    }
  };
  /** Data Sets */
  //region Data Sets
  let installs =
    data.installs != 'No data' && data.installs != 'N/A'
      ? `${numberFormatter(data.installs)}`
      : data.installs;
  let revenue =
    data.revenue != 'No data' && data.revenue != 'N/A' ? `${currency(data.revenue)}` : data.revenue;

  let revenueToDate =
    data.revenue_to_date != 'No data' && data.revenue_to_date != 'N/A'
      ? `${currency(data.revenue_to_date)}`
      : data.revenue_to_date;

  let roi_7d =
    data['7d_roi'] !== undefined && data.roi_usable
      ? data['7d_roi'] != 'No data' && data['7d_roi'] != 'N/A'
        ? ifReliable(`${Number(data['7d_roi']).toFixed(0)} %`, '7d_roi')
        : data['7d_roi']
      : 'N/A';
  let roi_1 =
    data['1m_roi'] !== undefined && data.roi_usable
      ? data['1m_roi'] != 'No data' && data['1m_roi'] != 'N/A'
        ? ifReliable(`${Number(data['1m_roi']).toFixed(0)} %`)
        : data['1m_roi']
      : 'N/A';
  let roi_3 =
    data['3m_roi'] !== undefined && data.roi_usable
      ? data['3m_roi'] != 'No data' && data['3m_roi'] != 'N/A'
        ? ifReliable(`${Number(data['3m_roi']).toFixed(0)} %`)
        : data['3m_roi']
      : 'N/A';
  let roi_6 =
    data['6m_roi'] !== undefined && data.roi_usable
      ? data['6m_roi'] != 'No data' && data['6m_roi'] != 'N/A'
        ? ifReliable(`${Number(data['6m_roi']).toFixed(0)} %`)
        : data['6m_roi']
      : 'N/A';
  let roi_9 =
    data['9m_roi'] !== undefined && data.roi_usable
      ? data['9m_roi'] != 'No data' && data['9m_roi'] != 'N/A'
        ? ifReliable(`${Number(data['9m_roi']).toFixed(0)} %`)
        : data['9m_roi']
      : 'N/A';
  let roi_12 =
    data['12m_roi'] !== undefined && data.roi_usable
      ? data['12m_roi'] != 'No data' && data['12m_roi'] != 'N/A'
        ? ifReliable(`${Number(data['12m_roi']).toFixed(0)} %`)
        : data['12m_roi']
      : 'N/A';
  let ltv_7d =
    data['7d_ltv'] !== undefined && data.ltv_usable
      ? data['7d_ltv'] != 'No data' && data['7d_ltv'] != 'N/A'
        ? ifReliable(`${currency(data['7d_ltv'])}`)
        : data['7d_ltv']
      : 'N/A';
  let ltv_1 =
    data['1m_ltv'] !== undefined && data.ltv_usable
      ? data['1m_ltv'] != 'No data' && data['1m_ltv'] != 'N/A'
        ? ifReliable(`${currency(data['1m_ltv'])}`)
        : data['1m_ltv']
      : 'N/A';
  let ltv_3 =
    data['3m_ltv'] !== undefined && data.ltv_usable
      ? data['3m_ltv'] != 'No data' && data['3m_ltv'] != 'N/A'
        ? ifReliable(`${currency(data['3m_ltv'])}`)
        : data['3m_ltv']
      : 'N/A';
  let ltv_6 =
    data['6m_ltv'] !== undefined && data.ltv_usable
      ? data['6m_ltv'] != 'No data' && data['6m_ltv'] != 'N/A'
        ? ifReliable(`${currency(data['6m_ltv'])}`)
        : data['6m_ltv']
      : 'N/A';

  let ltv_9 =
    data['9m_ltv'] !== undefined && data.ltv_usable
      ? data['9m_ltv'] != 'No data' && data['9m_ltv'] != 'N/A'
        ? ifReliable(`${currency(data['9m_ltv'])}`)
        : data['9m_ltv']
      : 'N/A';

  let ltv_12 =
    data['12m_ltv'] !== undefined && data.ltv_usable
      ? data['12m_ltv'] != 'No data' && data['12m_ltv'] != 'N/A'
        ? ifReliable(`${currency(data['12m_ltv'])}`)
        : data['12m_ltv']
      : 'N/A';

  let cpi = data.cpi != 'No data' && data.cpi != 'N/A' ? `${currency(data.cpi)}` : data.cpi;
  let impressions =
    data.impressions != 'No data' && data.impressions != 'N/A'
      ? `${numberFormatter(data.impressions)}`
      : data.impressions;
  let clicks =
    data.clicks != 'No data' && data.clicks != 'N/A'
      ? `${numberFormatter(data.clicks)}`
      : data.clicks;
  let conversion_rate =
    data.conversion_rate != 'No data' && data.conversion_rate != 'N/A'
      ? `${Number(data.conversion_rate).toFixed(2)} %`
      : data.conversion_rate;
  //endregion
  return (
    <DataCells length={Object.keys(checkboxItems).length}>
      {checkboxItems['Installs'] === true && <DataCell>{installs}</DataCell>}
      {checkboxItems['Revenue'] === true && <DataCell>{revenue}</DataCell>}
      {checkboxItems['Revenue to date'] === true && <DataCell>{revenueToDate}</DataCell>}
      {checkboxItems['7d ROI'] === true && <DataCell>{roi_7d}</DataCell>}
      {checkboxItems['1m ROI'] === true && <DataCell>{roi_1}</DataCell>}
      {checkboxItems['3m ROI'] === true && <DataCell>{roi_3}</DataCell>}
      {checkboxItems['6m ROI'] === true && <DataCell>{roi_6}</DataCell>}
      {checkboxItems['9m ROI'] === true && <DataCell>{roi_9}</DataCell>}
      {checkboxItems['12m ROI'] === true && <DataCell>{roi_12}</DataCell>}
      {checkboxItems['CPI'] === true && <DataCell>{cpi}</DataCell>}
      {checkboxItems['7d LTV'] === true && <DataCell>{ltv_7d}</DataCell>}
      {checkboxItems['1m LTV'] === true && <DataCell>{ltv_1}</DataCell>}
      {checkboxItems['3m LTV'] === true && <DataCell>{ltv_3}</DataCell>}
      {checkboxItems['6m LTV'] === true && <DataCell>{ltv_6}</DataCell>}
      {checkboxItems['9m LTV'] === true && <DataCell>{ltv_9}</DataCell>}
      {checkboxItems['12m LTV'] === true && <DataCell>{ltv_12}</DataCell>}
      {checkboxItems['Impressions'] === true && <DataCell>{impressions}</DataCell>}
      {checkboxItems['Clicks'] === true && <DataCell>{clicks}</DataCell>}
      {checkboxItems['Conversion rate'] === true && <DataCell>{conversion_rate}</DataCell>}
    </DataCells>
  );
};

export default CampaignsTable;
//region Styles
const Container = styled.div`
  grid-column: 1/5;
  grid-row: 2;
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 18rem 1fr;
  grid-template-rows: 4rem 1fr;
  font-size: 1.2rem;
  // background-color: #a2acbf;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  //justify-items:center;
  //align-items:center;
`;

const InfoPanel = styled.div`
  height: 4rem;
  width: 100%;
  grid-column: 1 / 4;
  line-height: 4rem;
  background-color: rgb(54, 57, 63, 0.25);
  justify-self: start;
  padding-left: 1rem;
  display: flex;
`;

const InfoLink = styled.span`
  width: auto;
  height: 4rem;
  cursor: pointer;
  letter-spacing: 0.075em;
  color: ${props => props.color};
`;

const Separator = styled.span`
  width: auto;
  height: 4rem;
  margin-top: 0;
  margin-right: 12px;
  margin-left: 12px;
  margin-bottom: 0;
  letter-spacing: 0.075em;
  color: #99999c;
`;

const NavigationPanel = styled.div`
  text-align: center;
  font-weight: 600;
`;

const NavigationPanelTitle = styled.div`
  border-style: solid;
  border-top-width: 1px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-color: rgb(54, 57, 63);
  border-radius: 3px;
  height: 6rem;
  line-height: 6rem;
  background-color: #1e2022;
  color: #e1e1e5;
  letter-spacing: 0.1rem;
`;
const NavigationPanelCell = styled.div`
  border-style: solid;
  border-top-width: 0px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-color: rgb(54, 57, 63);
  border-radius: 3px;
  height: 4.5rem;
  line-height: 4.5rem;
  background-color: #1e2022;
  color: #99999c;
  overflow: hidden;
  letter-spacing: 0.075rem;
  cursor: pointer;
`;

const Panel = styled.div`
  overflow-x: auto;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  height: 100%;
  position: relative;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #242629;
    height: 1px;
  }
  ::-webkit-scrollbar {
    height: 8px;
    background-color: #242629;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #00d5a9;
  }
`;

const Cells = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0px;
  justify-items: stretch;
  height: 100%;
  // width: 100%;
  // background-color: rgb(197,190,138);
`;

const Headers = styled.div`
  display: grid;

  grid-auto-flow: column;
  grid-gap: 0px;
  justify-items: start;
  height: 6rem;
  //width: 100%;
  grid-auto-columns: ${props => (props.length > 1 ? props.length * 140 + 'px' : '140px')};
  //  background-color: rgb(197,190,138);
`;

const DataPanel = styled.div`
  position: relative;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  background-color: rgb(54, 57, 63);
  //width: 100%;
  text-align: center;
`;

const DataRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0px;
  justify-items: start;
  height: 4.5rem;
  width: 100%;
  grid-auto-columns: ${props => (props.length > 1 ? props.length * 140 + 'px' : '140px')};
`;

const EmptyDataHeader = styled.div`
  height: 6rem;
  width: 100%;

  :nth-child(even) {
    background-color: #1e2022;
  }
  :nth-child(odd) {
    background-color: #24272b;
  }
`;

const EmptyDataCell = styled.div`
  height: 4.5rem;
  width: 100%;
  background-color: #292c2f;
  :nth-child(even) {
    background-color: #1e2022;
  }
  :nth-child(odd) {
    background-color: #24272b;
  }
`;

const UnreliableInfo = styled.div`
  grid-column: 1;
  grid-row: 3;
  justify-self: start;
  padding-left: 1rem;
  font-size: 10px;
  color: #99999c;
  padding-top: 5px;
`;

//endregion
